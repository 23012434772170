import {
  clone,
  deepMix,
  each,
  filter,
  find,
  flatten,
  get,
  isBoolean,
  isFunction,
  isNil,
  isObject,
  isString,
  isUndefined,
  mix,
  remove,
  set,
  size,
  uniqueId,
  isEqual,
  isPlainObject,
  reduce,
} from '@antv/util';
import { Attribute, Coordinate, Event as GEvent, GroupComponent, ICanvas, IGroup, IShape, Scale } from '../dependents';
import {
  AxisOption,
  ComponentOption,
  CoordinateCfg,
  CoordinateOption,
  Data,
  Datum,
  FacetCfgMap,
  FilterCondition,
  GeometryOption,
  LegendOption,
  LooseObject,
  Options,
  Point,
  Region,
  ScaleOption,
  TooltipOption,
  ViewCfg,
  ViewPadding,
  ViewAppendPadding,
  EventPayload,
  Padding,
} from '../interface';
import { GROUP_Z_INDEX, LAYER, PLOT_EVENTS, VIEW_LIFE_CIRCLE } from '../constant';
import Base from '../base';
import { Facet, getFacet } from '../facet';
import Geometry from '../geometry/base';
import Element from '../geometry/element';
import { createInteraction, Interaction } from '../interaction';
import { getTheme } from '../theme';
import { BBox } from '../util/bbox';
import { getCoordinateClipCfg, isPointInCoordinate } from '../util/coordinate';
import { uniq } from '../util/helper';
import { findDataByPoint } from '../util/tooltip';
import { parsePadding } from '../util/padding';
import { getDefaultCategoryScaleRange } from '../util/scale';
import { createTheme } from '../theme/util';
import Chart from './chart';
import { getComponentController, getComponentControllerNames } from './controller';
import Annotation from './controller/annotation';
import { Controller } from './controller/base';
import CoordinateController from './controller/coordinate';
import Tooltip from './controller/tooltip';
import Slider from './controller/slider';
import Scrollbar from './controller/scrollbar';
import Axis from './controller/axis';
import Gesture from './controller/gesture';
import Legend from './controller/legend';
import Event from './event';
import defaultLayout, { Layout } from './layout';
import { ScalePool } from './util/scale-pool';
import { PaddingCal } from './layout/padding-cal';
import { calculatePadding } from './layout/auto';
import { defaultSyncViewPadding } from './util/sync-view-padding';

/**
 * G2 è§å¾ View ç±»
 */
export class View extends Base {
  /** view idï¼å¨å±å¯ä¸ã */
  public id: string;
  /** ç¶çº§ viewï¼å¦ææ²¡æç¶çº§ï¼åä¸ºç©ºã */
  public parent: View;
  /** ææçå­ viewã */
  public views: View[] = [];
  /** ææç geometry å®ä¾ã */
  public geometries: Geometry[] = [];
  /** ææçç»ä»¶ controllersã */
  public controllers: Controller[] = [];
  /** ææç Interaction å®ä¾ã */
  public interactions: Record<string, Interaction> = {};

  /** view åºåç©ºé´ã */
  public viewBBox: BBox;
  /** åæ ç³»çä½ç½®å¤§å°ï¼ViewBBox - padding = coordinateBBoxã */
  public coordinateBBox: BBox;
  /** view ç padding å¤§å°ï¼ä¼ å¥çéç½®ï¼ä¸æ¯è§£æä¹åçå¼ï¼ã */
  public padding: ViewPadding;
  /** paddingçåºç¡ä¸å¢å çè°æ´å¼ */
  public appendPadding: ViewAppendPadding;
  /** G.Canvas å®ä¾ã */
  public canvas: ICanvas;
  /** å­å¨æç»è®¡ç®ç padding ç»æ */
  public autoPadding: PaddingCal;

  /** ä¸å± Group å¾å½¢ä¸­çèæ¯å±ã */
  public backgroundGroup: IGroup;
  /** ä¸å± Group å¾å½¢ä¸­çä¸­é´å±ã */
  public middleGroup: IGroup;
  /** ä¸å± Group å¾å½¢ä¸­çåæ¯å±ã */
  public foregroundGroup: IGroup;
  /** æ¯å¦å¯¹è¶åºåæ ç³»èå´ç Geometry è¿è¡åªå */
  public limitInPlot: boolean = false;

  /**
   * æ è®° view çå¤§å°ä½ç½®èå´ï¼åæ¯ 0 ~ 1 èå´ï¼ä¾¿äºå¼åèä½¿ç¨ï¼èµ·å§ç¹ä¸ºå·¦ä¸è§ã
   */
  protected region: Region;
  /** ä¸»é¢éç½®ï¼å­å¨å½åä¸»é¢éç½®ã */
  protected themeObject: LooseObject;

  // éç½®ä¿¡æ¯å­å¨
  protected options: Options = {
    data: [],
    animate: true, // é»è®¤å¼å¯å¨ç»
  }; // åå§åä¸ºç©º

  /** è¿æ»¤ä¹åçæ°æ® */
  protected filteredData: Data;

  /** éç½®å¼å¯çç»ä»¶æä»¶ï¼é»è®¤ä¸ºå¨å±éç½®çç»ä»¶ã */
  private usedControllers: string[] = getComponentControllerNames();

  /** ææç scales */
  private scalePool: ScalePool = new ScalePool();

  /** å¸å±å½æ° */
  protected layoutFunc: Layout = defaultLayout;
  /** çæçåæ ç³»å®ä¾ï¼{@link https://github.com/antvis/coord/blob/master/src/coord/base.ts|Coordinate} */
  protected coordinateInstance: Coordinate;
  /** Coordinate ç¸å³çæ§å¶å¨ç±»ï¼è´è´£åæ ç³»å®ä¾çåå»ºãæ´æ°ãåæ¢ç­ */
  protected coordinateController: CoordinateController;
  /** åé¢ç±»å®ä¾ */
  protected facetInstance: Facet;

  /** å½åé¼ æ æ¯å¦å¨ plot åï¼CoordinateBBoxï¼ */
  private isPreMouseInPlot: boolean = false;
  /** é»è®¤æ è¯ä½ï¼ç¨äºå¤å®æ°æ®æ¯å¦æ´æ° */
  private isDataChanged: boolean = false;
  /** ç¨äºå¤æ­åæ ç³»èå´æ¯å¦åçååçæ å¿ä½ */
  private isCoordinateChanged: boolean = false;
  /** ä»å½åè¿ä¸ª view åå»ºç scale key */
  private createdScaleKeys = new Map<string, boolean>();
  /** èæ¯è²æ ·å¼ç shape */
  private backgroundStyleRectShape;
  /** æ¯å¦åæ­¥å­ view ç padding */
  private syncViewPadding;

  constructor(props: ViewCfg) {
    super({ visible: props.visible });

    const {
      id = uniqueId('view'),
      parent,
      canvas,
      backgroundGroup,
      middleGroup,
      foregroundGroup,
      region = { start: { x: 0, y: 0 }, end: { x: 1, y: 1 } },
      padding,
      appendPadding,
      theme,
      options,
      limitInPlot,
      syncViewPadding,
    } = props;

    this.parent = parent;
    this.canvas = canvas;
    this.backgroundGroup = backgroundGroup;
    this.middleGroup = middleGroup;
    this.foregroundGroup = foregroundGroup;
    this.region = region;
    this.padding = padding;
    this.appendPadding = appendPadding;
    // æ¥åç¶ view ä¼ å¥çåæ°
    this.options = { ...this.options, ...options };
    this.limitInPlot = limitInPlot;
    this.id = id;
    this.syncViewPadding = syncViewPadding;

    // åå§å theme
    this.themeObject = isObject(theme) ? deepMix({}, getTheme('default'), createTheme(theme)) : getTheme(theme);
    this.init();
  }

  /**
   * è®¾ç½® layout å¸å±å½æ°
   * @param layout å¸å±å½æ°
   * @returns void
   */
  public setLayout(layout: Layout) {
    this.layoutFunc = layout;
  }

  /**
   * çå½å¨æï¼åå§å
   * @returns voids
   */
  public init() {
    // è®¡ç®ç»å¸ç viewBBox
    this.calculateViewBBox();

    // äºä»¶å§ææºå¶
    this.initEvents();

    // åå§åç»ä»¶ controller
    this.initComponentController();

    this.initOptions();
  }

  /**
   * çå½å¨æï¼æ¸²ææµç¨ï¼æ¸²æè¿ç¨éè¦å¤çæ°æ®æ´æ°çæåµã
   * render å½æ°ä»ä»ä¼å¤ç view åå­ viewã
   * @param isUpdate æ¯å¦è§¦åæ´æ°æµç¨ã
   * @param params render äºä»¶åæ°
   */
  public render(isUpdate: boolean = false, payload?: EventPayload) {
    this.emit(VIEW_LIFE_CIRCLE.BEFORE_RENDER, Event.fromData(this, VIEW_LIFE_CIRCLE.BEFORE_RENDER, payload));
    // éå½æ¸²æ
    this.paint(isUpdate);

    this.emit(VIEW_LIFE_CIRCLE.AFTER_RENDER, Event.fromData(this, VIEW_LIFE_CIRCLE.AFTER_RENDER, payload));

    if (this.visible === false) {
      // ç¨æ·å¨åå§åçæ¶åå£°æ visible: false
      this.changeVisible(false);
    }
  }

  /**
   * çå½å¨æï¼æ¸ç©ºå¾è¡¨ä¸ææçç»å¶åå®¹ï¼ä½æ¯ä¸éæ¯å¾è¡¨ï¼chart ä»å¯ä½¿ç¨ã
   * @returns void
   */
  public clear() {
    this.emit(VIEW_LIFE_CIRCLE.BEFORE_CLEAR);
    // 1. æ¸ç©ºç¼å­åè®¡ç®æ°æ®
    this.filteredData = [];
    this.coordinateInstance = undefined;
    this.isDataChanged = false; // å¤ä½
    this.isCoordinateChanged = false; // å¤ä½

    // 2. æ¸ç©º geometries
    const geometries = this.geometries;
    for (let i = 0; i < geometries.length; i++) {
      geometries[i].clear();
      // view ä¸­ä½¿ç¨ geometry çæ¶åï¼è¿éè¦æ¸ç©ºå®çå®¹å¨ï¼ä¸ç¶ä¸ä¸æ¬¡ chart.geometry() çæ¶åï¼ååå»ºäºä¸ä¸ªï¼å¯¼è´æ³é²ï¼ #2799ã
      geometries[i].container.remove(true);
      geometries[i].labelsContainer.remove(true);
    }
    this.geometries = [];

    // 3. æ¸ç©º controllers
    const controllers = this.controllers;
    for (let i = 0; i < controllers.length; i++) {
      if (controllers[i].name === 'annotation') {
        // éè¦æ¸ç©ºéç½®é¡¹
        (controllers[i] as Annotation).clear(true);
      } else {
        controllers[i].clear();
      }
    }

    // 4. å é¤ scale ç¼å­
    this.createdScaleKeys.forEach((v: boolean, k: string) => {
      this.getRootView().scalePool.deleteScale(k);
    });
    this.createdScaleKeys.clear();

    // éå½å¤çå­ view
    const views = this.views;
    for (let i = 0; i < views.length; i++) {
      views[i].clear();
    }

    this.emit(VIEW_LIFE_CIRCLE.AFTER_CLEAR);
  }

  /**
   * çå½å¨æï¼éæ¯ï¼å®å¨æ æ³ä½¿ç¨ã
   * @returns void
   */
  public destroy() {
    // éæ¯åäºä»¶ï¼éæ¯ä¹åå·²ç»æ²¡ææä¹äºï¼æä»¥ä¸æåºäºä»¶
    this.emit(VIEW_LIFE_CIRCLE.BEFORE_DESTROY);
    const interactions = this.interactions;
    // éæ¯ interactions
    each(interactions, (interaction) => {
      if (interaction) {
        // æå¯è½å·²ç»éæ¯ï¼è®¾ç½®äº undefined
        interaction.destroy();
      }
    });

    this.clear();

    // éæ¯ controller ä¸­çç»ä»¶
    const controllers = this.controllers;
    for (let i = 0, len = controllers.length; i < len; i++) {
      const controller = controllers[i];
      controller.destroy();
    }

    this.backgroundGroup.remove(true);
    this.middleGroup.remove(true);
    this.foregroundGroup.remove(true);

    super.destroy();
  }
  /* end çå½å¨æå½æ° */

  /**
   * æ¾ç¤ºæèéèæ´ä¸ª viewã
   * @param visible æ¯å¦å¯è§
   * @returns View
   */
  public changeVisible(visible: boolean): View {
    super.changeVisible(visible);

    const geometries = this.geometries;
    for (let i = 0, len = geometries.length; i < len; i++) {
      const geometry = geometries[i];
      geometry.changeVisible(visible);
    }

    const controllers = this.controllers;
    for (let i = 0, len = controllers.length; i < len; i++) {
      const controller = controllers[i];
      controller.changeVisible(visible);
    }

    this.foregroundGroup.set('visible', visible);
    this.middleGroup.set('visible', visible);
    this.backgroundGroup.set('visible', visible);

    // group.set('visible', visible) ä¸ä¼è§¦åèªå¨å·æ°
    this.getCanvas().draw();

    return this;
  }

  /**
   * è£è½½æ°æ®æºã
   *
   * ```ts
   * view.data([{ city: 'æ­å·', sale: 100 }, { city: 'ä¸æµ·', sale: 110 } ]);
   * ```
   *
   * @param data æ°æ®æºï¼json æ°ç»ã
   * @returns View
   */
  public data(data: Data): View {
    set(this.options, 'data', data);
    this.isDataChanged = true;
    return this;
  }

  /**
   * @deprecated
   * This method will be removed at G2 V4.1. Replaced by {@link #data(data)}
   */
  public source(data: Data): View {
    console.warn('This method will be removed at G2 V4.1. Please use chart.data() instead.');
    return this.data(data);
  }

  /**
   * è®¾ç½®æ°æ®ç­éè§åã
   *
   * ```ts
   * view.filter('city', (value: any, datum: Datum) => value !== 'æ­å·');
   *
   * // å é¤ 'city' å­æ®µå¯¹åºçç­éè§åã
   * view.filter('city', null);
   * ```
   *
   * @param field æ°æ®å­æ®µ
   * @param condition ç­éè§å
   * @returns View
   */
  public filter(field: string, condition: FilterCondition | null): View {
    if (isFunction(condition)) {
      set(this.options, ['filters', field], condition);
      return this;
    }
    // condition ä¸ºç©ºï¼åè¡¨ç¤ºå é¤è¿æ»¤æ¡ä»¶
    if (!condition && get(this.options, ['filters', field])) {
      delete this.options.filters[field];
    }

    return this;
  }

  /**
   * å¼å¯æèå³é­åæ è½´ã
   *
   * ```ts
   *  view.axis(false); // ä¸å±ç¤ºåæ è½´
   * ```
   * @param field åæ è½´å¼å³
   */
  public axis(field: boolean): View;
  /**
   * å¯¹ç¹å®çææ¡åæ è½´è¿è¡éç½®ã
   *
   * @example
   * ```ts
   * view.axis('city', false); // ä¸å±ç¤º 'city' å­æ®µå¯¹åºçåæ è½´
   *
   * // å° 'city' å­æ®µå¯¹åºçåæ è½´çæ é¢éè
   * view.axis('city', {
   *   title: null,
   * });
   * ```
   *
   * @param field è¦éç½®çåæ è½´å¯¹åºçå­æ®µåç§°
   * @param axisOption åæ è½´å·ä½éç½®ï¼æ´è¯¦ç»çéç½®é¡¹å¯ä»¥åèï¼https://github.com/antvis/component#axis
   */
  public axis(field: string, axisOption: AxisOption): View;
  public axis(field: string | boolean, axisOption?: AxisOption): View {
    if (isBoolean(field)) {
      set(this.options, ['axes'], field);
    } else {
      set(this.options, ['axes', field], axisOption);
    }

    return this;
  }

  /**
   * å¯¹å¾ä¾è¿è¡æ´ä½éç½®ã
   *
   * ```ts
   * view.legend(false); // å³é­å¾ä¾
   *
   * view.legend({
   *   position: 'right',
   * }); // å¾ä¾è¿è¡æ´ä½éç½®
   * ```
   * @param field
   * @returns View
   */
  public legend(field: LegendOption): View;
  /**
   * å¯¹ç¹å®çå¾ä¾è¿è¡éç½®ã
   *
   * @example
   * ```ts
   * view.legend('city', false); // å³é­æä¸ªå¾ä¾ï¼éè¿æ°æ®å­æ®µåè¿è¡å³è
   *
   * // å¯¹ç¹å®çå¾ä¾è¿è¡éç½®
   * view.legend('city', {
   *   position: 'right',
   * });
   * ```
   *
   * @param field å¾ä¾å¯¹åºçæ°æ®å­æ®µåç§°
   * @param legendOption å¾ä¾éç½®ï¼æ´è¯¦ç»çéç½®é¡¹å¯ä»¥åèï¼https://github.com/antvis/component#axis
   * @returns View
   */
  public legend(field: string, legendOption: LegendOption): View;
  public legend(field: string | LegendOption, legendOption?: LegendOption): View {
    if (isBoolean(field)) {
      set(this.options, ['legends'], field);
    } else if (isString(field)) {
      set(this.options, ['legends', field], legendOption);
      if (isPlainObject(legendOption) && legendOption?.selected) {
        set(this.options, ['filters', field], (name: string) => {
          return legendOption?.selected[name] ?? true;
        });
      }
    } else {
      // è®¾ç½®å¨å±ç legend éç½®
      set(this.options, ['legends'], field);
    }

    return this;
  }

  /**
   * æ¹éè®¾ç½® scale éç½®ã
   *
   * ```ts
   * view.scale({
   *   sale: {
   *     min: 0,
   *     max: 100,
   *   }
   * });
   * ```
   * Scale çè¯¦ç»éç½®é¡¹å¯ä»¥åèï¼https://github.com/antvis/scale#api
   * @returns View
   */
  public scale(field: Record<string, ScaleOption>): View;
  /**
   * ä¸ºç¹æ§çæ°æ®å­æ®µè¿è¡ scale éç½®ã
   *
   * ```ts
   * view.scale('sale', {
   *   min: 0,
   *   max: 100,
   * });
   * ```
   *
   * @returns View
   */
  public scale(field: string, scaleOption: ScaleOption): View;
  public scale(field: string | Record<string, ScaleOption>, scaleOption?: ScaleOption): View {
    if (isString(field)) {
      set(this.options, ['scales', field], scaleOption);
    } else if (isObject(field)) {
      each(field, (v: ScaleOption, k: string) => {
        set(this.options, ['scales', k], v);
      });
    }

    return this;
  }

  /**
   * tooltip æç¤ºä¿¡æ¯éç½®ã
   *
   * ```ts
   * view.tooltip(false); // å³é­ tooltip
   *
   * view.tooltip({
   *   shared: true
   * });
   * ```
   *
   * @param cfg Tooltip éç½®ï¼æ´è¯¦ç»çéç½®é¡¹åèï¼https://github.com/antvis/component#tooltip
   * @returns View
   */
  public tooltip(cfg: boolean | TooltipOption): View {
    set(this.options, 'tooltip', cfg);

    return this;
  }

  /**
   * è¾å©æ è®°éç½®ã
   *
   * ```ts
   * view.annotation().line({
   *   start: ['min', 85],
   *   end: ['max', 85],
   *   style: {
   *     stroke: '#595959',
   *     lineWidth: 1,
   *     lineDash: [3, 3],
   *   },
   * });
   * ```
   * æ´è¯¦ç»çéç½®é¡¹ï¼https://github.com/antvis/component#annotation
   * @returns [[Annotation]]
   */
  public annotation(): Annotation {
    return this.getController('annotation');
  }

  /**
   * @deprecated
   * This method will be removed at G2 V4.1. Replaced by {@link #guide()}
   */
  public guide(): Annotation {
    console.warn('This method will be removed at G2 V4.1. Please use chart.annotation() instead.');
    return this.annotation();
  }

  /**
   * åæ ç³»éç½®ã
   *
   * @example
   * ```ts
   * view.coordinate({
   *   type: 'polar',
   *   cfg: {
   *     radius: 0.85,
   *   },
   *   actions: [
   *     [ 'transpose' ],
   *   ],
   * });
   * ```
   *
   * @param option
   * @returns
   */
  public coordinate(option?: CoordinateOption): CoordinateController;
  /**
   * å£°æåæ ç³»ç±»åï¼å¹¶è¿è¡éç½®ã
   *
   * ```ts
   * // ç´è§åæ ç³»ï¼å¹¶è¿è¡è½¬ç½®åæ¢
   * view.coordinate('rect').transpose();
   *
   * // é»è®¤åå»ºç´è§åæ ç³»
   * view.coordinate();
   * ```
   *
   * @param type åæ ç³»ç±»å
   * @param [coordinateCfg] åæ ç³»éç½®
   * @returns
   */
  public coordinate(type: string, coordinateCfg?: CoordinateCfg): CoordinateController;
  public coordinate(type: string | CoordinateOption, coordinateCfg?: CoordinateCfg): CoordinateController {
    // æä¾è¯­æ³ç³ï¼ä½¿ç¨æ´ç®å
    if (isString(type)) {
      set(this.options, 'coordinate', { type, cfg: coordinateCfg } as CoordinateOption);
    } else {
      set(this.options, 'coordinate', type);
    }

    // æ´æ° coordinate éç½®
    this.coordinateController.update(this.options.coordinate);

    return this.coordinateController;
  }

  /**
   * @deprecated
   * This method will be removed at G2 V4.1. Replaced by {@link #coordinate()}
   */
  public coord(type: string | CoordinateOption, coordinateCfg?: CoordinateCfg): CoordinateController {
    console.warn('This method will be removed at G2 V4.1. Please use chart.coordinate() instead.');
    // @ts-ignore
    return this.coordinate(type, coordinateCfg);
  }

  /**
   * view åé¢ç»å¶ã
   *
   * ```ts
   * view.facet('rect', {
   *   rowField: 'province',
   *   columnField: 'category',
   *   eachView: (innerView: View, facet?: FacetData) => {
   *     innerView.line().position('city*sale');
   *   },
   * });
   * ```
   *
   * @param type åé¢ç±»å
   * @param cfg åé¢éç½®ï¼ [[FacetCfgMap]]
   * @returns View
   */
  public facet<T extends keyof FacetCfgMap>(type: T, cfg: FacetCfgMap[T]): View {
    // åéæ¯æä¹åçåé¢
    if (this.facetInstance) {
      this.facetInstance.destroy();
    }

    // åå»ºæ°çåé¢
    const Ctor = getFacet(type);

    if (!Ctor) {
      throw new Error(`facet '${type}' is not exist!`);
    }

    this.facetInstance = new Ctor(this, { ...cfg, type });

    return this;
  }

  /*
   * å¼å¯æèå³é­å¨ç»ã
   *
   * ```ts
   * view.animate(false);
   * ```
   *
   * @param status å¨ç»ç¶æï¼true è¡¨ç¤ºå¼å§ï¼false è¡¨ç¤ºå³é­
   * @returns View
   */
  public animate(status: boolean): View {
    set(this.options, 'animate', status);
    return this;
  }

  /**
   * æ´æ°éç½®é¡¹ï¼ç¨äºéç½®é¡¹å¼å£°æã
   * @param options éç½®é¡¹
   */
  public updateOptions(options: Options) {
    this.clear(); // æ¸ç©º
    mix(this.options, options);

    // éè¦æå·²å­å¨ç view éæ¯ï¼å¦åä¼éå¤åå»º
    // ç®åéå¯¹éç½®é¡¹è¿æ²¡æç¹å«å¥½ç view æ´æ°æºå¶ï¼ä¸ºäºä¸å½±åä¸»æµæµç¨ï¼æä»¥å¨è¿éç´æ¥éæ¯
    this.views.forEach((view) => view.destroy());
    this.views = [];

    this.initOptions();
    // åå§ååæ ç³»å¤§å°ï¼ä¿è¯ padding è®¡ç®æ­£ç¡®
    this.coordinateBBox = this.viewBBox;
    return this;
  }

  /**
   * å¾ `view.options` å±æ§ä¸­å­å¨éç½®é¡¹ã
   * @param name å±æ§åç§°
   * @param opt å±æ§å¼
   * @returns view
   */
  public option(name: string, opt: any): View {
    // å¯¹äºåç½®ç optionï¼é¿åè¦çã
    // name å¨ååä¸ï¼è¯´æå¯è½æ¯åç½® APIï¼å­å¨ option è¢«è¦ççé£é©ï¼ä¸å¤ç
    if (View.prototype[name]) {
      throw new Error(`Can't use built in variable name "${name}", please change another one.`);
    }

    // å­å¥å° option ä¸­
    set(this.options, name, opt);
    return this;
  }

  /**
   * è®¾ç½®ä¸»é¢ã
   *
   * ```ts
   * view.theme('dark'); // 'dark' éè¦äºåéè¿ `registerTheme()` æ¥å£æ³¨åå®æ
   *
   * view.theme({ defaultColor: 'red' });
   * ```
   *
   * @param theme ä¸»é¢åæèä¸»é¢éç½®
   * @returns View
   */
  public theme(theme: string | LooseObject): View {
    this.themeObject = isObject(theme) ? deepMix({}, this.themeObject, createTheme(theme)) : getTheme(theme);

    return this;
  }

  /* end ä¸ç³»åä¼ å¥éç½®ç API */

  /**
   * Call the interaction based on the interaction name
   *
   * ```ts
   * view.interaction('my-interaction', { extra: 'hello world' });
   * ```
   * è¯¦ç»ææ¡£å¯ä»¥åèï¼https://g2.antv.vision/zh/docs/api/general/interaction
   * @param name interaction name
   * @param cfg interaction config
   * @returns
   */
  public interaction(name: string, cfg?: LooseObject): View {
    const existInteraction = this.interactions[name];
    // å­å¨ååéæ¯å·²æç
    if (existInteraction) {
      existInteraction.destroy();
    }

    // æ°å»ºäº¤äºå®ä¾
    const interaction = createInteraction(name, this, cfg);
    if (interaction) {
      interaction.init();
      this.interactions[name] = interaction;
    }
    return this;
  }

  /**
   * ç§»é¤å½å View ç interaction
   * ```ts
   * view.removeInteraction('my-interaction');
   * ```
   * @param name interaction name
   */
  public removeInteraction(name: string) {
    const existInteraction = this.interactions[name];
    // å­å¨ååéæ¯å·²æç
    if (existInteraction) {
      existInteraction.destroy();
      this.interactions[name] = undefined;
    }
  }

  /**
   * ä¿®æ¹æ°æ®ï¼æ°æ®æ´æ°é»è¾ï¼æ°æ®æ´æ°ä»ä»å½±åå½åè¿ä¸å±ç view
   *
   * ```ts
   * view.changeData([{ city: 'åäº¬', sale: '200' }]);
   * ```
   *
   * @param data
   * @returns void
   */
  public changeData(data: Data) {
    this.isDataChanged = true;
    this.emit(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, Event.fromData(this, VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, null));
    // 1. ä¿å­æ°æ®
    this.data(data);

    // 2. æ¸²æ
    this.paint(true);

    // 3. éåå­ view è¿è¡ change data
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      // FIXME å­ view æèªå·±çæ°æ®çæåµï¼è¯¥å¦ä½å¤çï¼
      view.changeData(data);
    }

    this.emit(VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, Event.fromData(this, VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, null));
  }

  /* View ç®¡çç¸å³ç API */

  /**
   * åå»ºå­ view
   *
   * ```ts
   * const innerView = view.createView({
   *   start: { x: 0, y: 0 },
   *   end: { x: 0.5, y: 0.5 },
   *   padding: 8,
   * });
   * ```
   *
   * @param cfg
   * @returns View
   */
  public createView(cfg?: Partial<ViewCfg>): View {
    // å°ä¼å¨ 4.1 çæ¬ä¸­ç§»é¤éå½åµå¥ viewï¼ä»ä»åªåè®¸ chart - view ä¸¤å±ã
    // è¿ä¸ª API çè®ºä¸ç¨æ·éä¸å¤ï¼æä»¥ææ¶ä¸åå¤§çæ¬ï¼æä»¥åææ¶æä¸ä¸ª warningã
    if (this.parent && this.parent.parent) {
      // å­å¨ 3 å± ç»æäº
      console.warn('The view nesting recursive feature will be removed at G2 V4.1. Please avoid to use it.');
    }

    // å­ view å±äº« options éç½®æ°æ®
    const sharedOptions = {
      data: this.options.data,
      scales: clone(this.options.scales),
      axes: clone(this.options.axes),
      coordinate: clone(this.coordinateController.getOption()),
      tooltip: clone(this.options.tooltip),
      legends: clone(this.options.legends),
      animate: this.options.animate,
      visible: this.visible,
    };

    const v = new View({
      parent: this,
      canvas: this.canvas,
      // å­ view å±ç¨ä¸å± group
      backgroundGroup: this.backgroundGroup.addGroup({ zIndex: GROUP_Z_INDEX.BG }),
      middleGroup: this.middleGroup.addGroup({ zIndex: GROUP_Z_INDEX.MID }),
      foregroundGroup: this.foregroundGroup.addGroup({ zIndex: GROUP_Z_INDEX.FORE }),
      theme: this.themeObject,
      padding: this.padding,
      ...cfg,
      options: {
        ...sharedOptions,
        ...get(cfg, 'options', {}),
      },
    });

    this.views.push(v);

    return v;
  }

  /**
   * @deprecated
   * This method will be removed at G2 V4.1. Replaced by {@link #createView()}
   */
  public view(cfg?: Partial<ViewCfg>) {
    console.warn('This method will be removed at G2 V4.1. Please use chart.createView() instead.');
    return this.createView(cfg);
  }

  /**
   * å é¤ä¸ä¸ªå­ view
   * @param view
   * @return removedView
   */
  public removeView(view: View): View {
    const removedView = remove(this.views, (v: View) => v === view)[0];

    if (removedView) {
      removedView.destroy();
    }

    return removedView;
  }
  /* end View ç®¡çç¸å³ç API */

  // ä¸äº get æ¹æ³

  /**
   * è·åå½ååæ ç³»å®ä¾ã
   * @returns [[Coordinate]]
   */
  public getCoordinate() {
    return this.coordinateInstance;
  }

  /**
   * è·åå½å view çä¸»é¢éç½®ã
   * @returns themeObject
   */
  public getTheme(): LooseObject {
    return this.themeObject;
  }

  /**
   * è·å¾ x è½´å­æ®µç scale å®ä¾ã
   * @returns view ä¸­ Geometry å¯¹äºç x scale
   */
  public getXScale(): Scale {
    // æ¿ç¬¬ä¸ä¸ª Geometry ç X scale
    // éèé»è¾ï¼ä¸ä¸ª view ä¸­ç Geometry å¿é¡» x å­æ®µä¸è´
    const g = this.geometries[0];
    return g ? g.getXScale() : null;
  }

  /**
   * è·å y è½´å­æ®µç scales å®ä¾ã
   * @returns view ä¸­ Geometry å¯¹äºç y scale æ°ç»
   */
  public getYScales(): Scale[] {
    // æ¿å°ææç Geometry ç Y scaleï¼ç¶åå»é
    const tmpMap = {};
    const yScales = [];
    this.geometries.forEach((g: Geometry) => {
      const yScale = g.getYScale();
      const field = yScale.field;
      if (!tmpMap[field]) {
        tmpMap[field] = true;
        yScales.push(yScale);
      }
    });
    return yScales;
  }

  /**
   * è·å x è½´æè y è½´å¯¹åºçææ scale å®ä¾ã
   * @param dimType x | y
   * @returns x è½´æè y è½´å¯¹åºçææ scale å®ä¾ã
   */
  public getScalesByDim(dimType: 'x' | 'y'): Record<string, Scale> {
    const geometries = this.geometries;
    const scales = {};

    for (let i = 0, len = geometries.length; i < len; i++) {
      const geometry = geometries[i];
      const scale = dimType === 'x' ? geometry.getXScale() : geometry.getYScale();
      if (scale && !scales[scale.field]) {
        scales[scale.field] = scale;
      }
    }

    return scales;
  }

  /**
   * æ ¹æ®å­æ®µåå»è·å scale å®ä¾ã
   * @param field æ°æ®å­æ®µåç§°
   * @param key id
   */
  public getScale(field: string, key?: string): Scale {
    const defaultKey = key ? key : this.getScaleKey(field);
    // è°ç¨æ ¹èç¹ view çæ¹æ³è·å
    return this.getRootView().scalePool.getScale(defaultKey);
  }

  /**
   * @deprecated
   * This method will be removed at G2 V4.1. Please use `getScale`.
   */
  public getScaleByField(field: string, key?: string): Scale {
    return this.getScale(field, key);
  }

  /**
   * è¿åææéç½®ä¿¡æ¯ã
   * @returns ææç view API éç½®ã
   */
  public getOptions(): Options {
    return this.options;
  }

  /**
   * è·å view çæ°æ®ï¼è¿æ»¤åçæ°æ®ï¼ã
   * @returns å¤çè¿æ»¤å¨ä¹åçæ°æ®ã
   */
  public getData() {
    return this.filteredData;
  }

  /**
   * è·ååå§æ°æ®
   * @returns ä¼ å¥ G2 çåå§æ°æ®
   */
  public getOriginalData() {
    return this.options.data;
  }

  /**
   * è·åå¸å±åçè¾¹è· padding
   * @returns
   */
  public getPadding(): Padding {
    return this.autoPadding.getPadding();
  }

  /**
   * è·åå½å view æç geometries
   * @returns
   */
  public getGeometries() {
    return this.geometries;
  }

  /**
   * è·å view ä¸­çææ geome
   */
  public getElements(): Element[] {
    return reduce(
      this.geometries,
      (elements: Element[], geometry: Geometry) => {
        return elements.concat(geometry.getElements());
      },
      []
    );
  }

  /**
   * æ ¹æ®ä¸å®çè§åæ¥æ¾ Geometry ç Elementsã
   *
   * ```typescript
   * getElementsBy((element) => {
   *   const data = element.getData();
   *
   *   return data.a === 'a';
   * });
   * ```
   *
   * @param condition å®ä¹æ¥æ¾è§åçåè°å½æ°ã
   * @returns
   */
  public getElementsBy(condition: (element: Element) => boolean): Element[] {
    return this.getElements().filter((el) => condition(el));
  }

  /**
   * è·å¾ç»å¶çå±çº§ groupã
   * @param layer å±çº§åç§°ã
   * @returns å¯¹åºå±çº§ç Groupã
   */
  public getLayer(layer: LAYER): IGroup {
    return layer === LAYER.BG
      ? this.backgroundGroup
      : layer === LAYER.MID
      ? this.middleGroup
      : layer === LAYER.FORE
      ? this.foregroundGroup
      : this.foregroundGroup;
  }

  /**
   * å¯¹å¤æ´é²æ¹æ³ï¼å¤æ­ä¸ä¸ªç¹æ¯å¦å¨ç»å¾åºåï¼å³åæ ç³»èå´ï¼åé¨ã
   * @param point åæ ç¹
   */
  public isPointInPlot(point: Point): boolean {
    return isPointInCoordinate(this.getCoordinate(), point);
  }

  /**
   * è·å¾ææç legend å¯¹åºç attribute å®ä¾ã
   * @returns ç»´åº¦å­æ®µç Attribute æ°ç»
   */
  public getLegendAttributes(): Attribute[] {
    return flatten(this.geometries.map((g: Geometry) => g.getGroupAttributes())) as unknown as Attribute[];
  }

  /**
   * è·åææçåç»å­æ®µç scale å®ä¾ã
   * @returns è·å¾åç»å­æ®µç scale å®ä¾æ°ç»ã
   */
  public getGroupScales(): Scale[] {
    // æ¿å°ææç Geometry ç åç»å­æ®µ scaleï¼ç¶åæå¹³å»é
    const scales = this.geometries.map((g: Geometry) => g.getGroupScales());
    return uniq(flatten(scales));
  }

  /**
   * è·å G.Canvas å®ä¾ã
   * @returns G.Canvas ç»å¸å®ä¾ã
   */
  public getCanvas(): ICanvas {
    return (this.getRootView() as unknown as Chart).canvas;
  }

  /**
   * è·å¾æ ¹èç¹ viewã
   */
  public getRootView(): View {
    let v = this as View;

    while (true) {
      if (v.parent) {
        v = v.parent;
        continue;
      }
      break;
    }
    return v;
  }

  /**
   * è·åè¯¥æ°æ®å¨å¯è§ååï¼å¯¹åºçç»å¸åæ ç¹ã
   * @param data åå§æ°æ®è®°å½
   * @returns å¯¹åºçç»å¸åæ ç¹
   */
  public getXY(data: Datum): Point {
    const coordinate = this.getCoordinate();
    const xScales = this.getScalesByDim('x');
    const yScales = this.getScalesByDim('y');
    let x;
    let y;

    each(data, (value, key) => {
      if (xScales[key]) {
        x = xScales[key].scale(value);
      }
      if (yScales[key]) {
        y = yScales[key].scale(value);
      }
    });

    if (!isNil(x) && !isNil(y)) {
      return coordinate.convert({ x, y });
    }
  }

  public getController(name: 'tooltip'): Tooltip;
  public getController(name: 'axis'): Axis;
  public getController(name: 'legend'): Legend;
  public getController(name: 'scrollbar'): Scrollbar;
  public getController(name: 'slider'): Slider;
  public getController(name: 'annotation'): Annotation;
  public getController(name: 'gestucre'): Gesture;
  public getController(name: string): Controller;
  /**
   * è·å name å¯¹åºç controller å®ä¾
   * @param name
   */
  public getController(name: string): Controller {
    return find(this.controllers, (c: Controller) => c.name === name);
  }

  /**
   * æ¾ç¤º point åæ ç¹å¯¹åºç tooltipã
   * @param point ç»å¸åæ ç¹
   * @returns View
   */
  public showTooltip(point: Point): View {
    const tooltip = this.getController('tooltip');
    if (tooltip) {
      tooltip.showTooltip(point);
    }
    return this;
  }

  /**
   * éè tooltipã
   * @returns View
   */
  public hideTooltip(): View {
    const tooltip = this.getController('tooltip');
    if (tooltip) {
      tooltip.hideTooltip();
    }
    return this;
  }

  /**
   * å° tooltip éå®å°å½åä½ç½®ä¸è½ç§»å¨ã
   * @returns View
   */
  public lockTooltip(): View {
    const tooltip = this.getController('tooltip');
    if (tooltip) {
      tooltip.lockTooltip();
    }
    return this;
  }

  /**
   * å° tooltip éå®è§£é¤ã
   * @returns View
   */
  public unlockTooltip(): View {
    const tooltip = this.getController('tooltip');
    if (tooltip) {
      tooltip.unlockTooltip();
    }
    return this;
  }

  /**
   * æ¯å¦éå® tooltipã
   * @returns æ¯å¦éå®
   */
  public isTooltipLocked() {
    const tooltip = this.getController('tooltip');
    return tooltip && tooltip.isTooltipLocked();
  }

  /**
   * è·åå½å point å¯¹åºç tooltip æ°æ®é¡¹ã
   * @param point åæ ç¹
   * @returns tooltip æ°æ®é¡¹
   */
  public getTooltipItems(point: Point) {
    const tooltip = this.getController('tooltip');

    return tooltip ? tooltip.getTooltipItems(point) : [];
  }

  /**
   * è·åé¼è¿çç¹çæ°æ®éå
   * @param point å½ååæ ç¹
   * @returns  æ°æ®
   */
  public getSnapRecords(point: Point) {
    const geometries = this.geometries;
    let rst = [];
    for (let i = 0, len = geometries.length; i < len; i++) {
      const geom = geometries[i];
      const dataArray = geom.dataArray;
      geom.sort(dataArray); // åè¿è¡æåºï¼ä¾¿äº tooltip æ¥æ¾
      let record;
      for (let j = 0, dataLen = dataArray.length; j < dataLen; j++) {
        const data = dataArray[j];
        record = findDataByPoint(point, data, geom);
        if (record) {
          rst.push(record);
        }
      }
    }

    // åæ ·éå½å¤çå­ views
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      const snapRecords = view.getSnapRecords(point);
      rst = rst.concat(snapRecords);
    }

    return rst;
  }

  /**
   * è·åææç pure component ç»ä»¶ï¼ç¨äºå¸å±ã
   */
  public getComponents(): ComponentOption[] {
    let components = [];
    const controllers = this.controllers;
    for (let i = 0, len = controllers.length; i < len; i++) {
      const controller = controllers[i];
      components = components.concat(controller.getComponents());
    }

    return components;
  }

  /**
   * å° data æ°æ®è¿è¡è¿æ»¤ã
   * @param data
   * @returns è¿æ»¤ä¹åçæ°æ®
   */
  public filterData(data: Data): Data {
    const { filters } = this.options;
    // ä¸å­å¨ filtersï¼åä¸éè¦è¿è¡æ°æ®è¿æ»¤
    if (size(filters) === 0) {
      return data;
    }

    // å­å¨è¿æ»¤å¨ï¼åéä¸ªæ§è¡è¿æ»¤ï¼è¿æ»¤å¨ä¹é´æ¯ ä¸ çå³ç³»
    return filter(data, (datum: Datum, idx: number) => {
      // ææç filter å­æ®µ
      const fields = Object.keys(filters);

      // ææçæ¡ä»¶é½éè¿ï¼æç®éè¿
      return fields.every((field: string) => {
        const condition = filters[field];

        // condition è¿å trueï¼åä¿ç
        return condition(datum[field], datum, idx);
      });
    });
  }

  /**
   * å¯¹æä¸ä¸ªå­æ®µè¿è¡è¿æ»¤
   * @param field
   * @param data
   */
  public filterFieldData(field: string, data: Data): Data {
    const { filters } = this.options;
    const condition = get(filters, field);

    if (isUndefined(condition)) {
      return data;
    }
    return data.filter((datum: Datum, idx: number) => condition(datum[field], datum, idx));
  }

  /**
   * è°æ´ coordinate çåæ èå´ã
   */
  public adjustCoordinate() {
    const { start: curStart, end: curEnd } = this.getCoordinate();
    const start = this.coordinateBBox.bl;
    const end = this.coordinateBBox.tr;

    // å¨ defaultLayoutFn ä¸­åªä¼å¨ coordinateBBox åçååçæ¶åä¼è°ç¨ adjustCoordinate()ï¼æä»¥ä¸ç¨æå¿è¢«ç½®ä½
    if (isEqual(curStart, start) && isEqual(curEnd, end)) {
      this.isCoordinateChanged = false;
      // å¦æå¤§å°æ²¡æåååä¸æ´æ°
      return;
    }
    this.isCoordinateChanged = true;
    this.coordinateInstance = this.coordinateController.adjust(start, end);
  }

  protected paint(isUpdate: boolean) {
    this.renderDataRecursive(isUpdate);

    // å¤ç sync scale çé»è¾
    this.syncScale();

    this.emit(VIEW_LIFE_CIRCLE.BEFORE_PAINT);

    // åå§åå¾å½¢ãç»ä»¶ä½ç½®ï¼è®¡ç® padding
    this.renderPaddingRecursive(isUpdate);
    // å¸å±å¾å½¢ãç»ä»¶
    this.renderLayoutRecursive(isUpdate);
    // èæ¯è² shape
    this.renderBackgroundStyleShape();
    // æç»çç»å¶ render
    this.renderPaintRecursive(isUpdate);

    this.emit(VIEW_LIFE_CIRCLE.AFTER_PAINT);

    this.isDataChanged = false; // æ¸²æå®æ¯å¤ä½
  }

  /**
   * æ¸²æèæ¯æ ·å¼ç shapeã
   * æ¾å° view ä¸­åå»ºçåå æ¯è®©ä½¿ç¨ view ç»å¶å¾å½¢çæ¶åï¼ä¹è½å¤å¤çèæ¯è²
   */
  private renderBackgroundStyleShape() {
    // åªææ ¹èç¹æå¤ç
    if (this.parent) {
      return;
    }
    const background = get(this.themeObject, 'background');
    // éç½®äºèæ¯è²
    if (background) {
      // 1. ä¸å­å¨ååå»º
      if (!this.backgroundStyleRectShape) {
        this.backgroundStyleRectShape = this.backgroundGroup.addShape('rect', {
          attrs: {},
          zIndex: -1,
          // èæ¯è² shape ä¸è®¾ç½®äºä»¶æè·
          capture: false,
        });
        this.backgroundStyleRectShape.toBack();
      }

      // 2. æäº shape ä¹åè®¾ç½®èæ¯ï¼ä½ç½®ï¼æ´æ°çæ¶åï¼
      const { x, y, width, height } = this.viewBBox;
      this.backgroundStyleRectShape.attr({
        fill: background,
        x,
        y,
        width,
        height,
      });
    } else {
      // æ²¡æéç½®èæ¯è²
      if (this.backgroundStyleRectShape) {
        this.backgroundStyleRectShape.remove(true);
        this.backgroundStyleRectShape = undefined;
      }
    }
  }

  /**
   * éå½è®¡ç®æ¯ä¸ª view ç padding å¼ï¼coordinateBBox å coordinateInstance
   * @param isUpdate
   */
  protected renderPaddingRecursive(isUpdate: boolean) {
    // 1. å­ view å¤§å°ç¸å¯¹ coordinateBBoxï¼changeSize çæ¶åéè¦éæ°è®¡ç®
    this.calculateViewBBox();
    // 2. æ´æ° coordinate
    this.adjustCoordinate();
    // 3. åå§åç»ä»¶ component
    this.initComponents(isUpdate);
    // 4. å¸å±è®¡ç®æ¯é view ç padding å¼
    // 4.1. èªå¨å  auto padding -> absolute paddingï¼å¹¶ä¸å¢å  appendPadding
    this.autoPadding = calculatePadding(this).shrink(parsePadding(this.appendPadding));
    // 4.2. è®¡ç®åºæ°ç coordinateBBoxï¼æ´æ° Coordinate
    // è¿éå¿é¡»ä¿çï¼åå æ¯åé¢å­ view ç viewBBox ææ ¹æ® parent ç coordinateBBox
    this.coordinateBBox = this.viewBBox.shrink(this.autoPadding.getPadding());
    this.adjustCoordinate();

    // åæ ·éå½å¤çå­ views
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      view.renderPaddingRecursive(isUpdate);
    }
  }

  /**
   * éå½å¤ç view çå¸å±ï¼æç»æ¯è®¡ç®åä¸ª view ç coordinateBBox å coordinateInstance
   * @param isUpdate
   */
  protected renderLayoutRecursive(isUpdate: boolean) {
    // 1. åæ­¥å­ view padding
    // æ ¹æ®éç½®è·å padding
    const syncViewPaddingFn =
      this.syncViewPadding === true
        ? defaultSyncViewPadding
        : isFunction(this.syncViewPadding)
        ? this.syncViewPadding
        : undefined;

    if (syncViewPaddingFn) {
      syncViewPaddingFn(this, this.views, PaddingCal);
      // åæ­¥ padding ä¹åï¼æ´æ° coordinate
      this.views.forEach((v: View) => {
        v.coordinateBBox = v.viewBBox.shrink(v.autoPadding.getPadding());
        v.adjustCoordinate();
      });
    }

    // 3. å° view ä¸­çç»ä»¶æç§ view padding ç§»å¨å°å¯¹åºçä½ç½®
    this.doLayout();

    // åæ ·éå½å¤çå­ views
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      view.renderLayoutRecursive(isUpdate);
    }
  }

  /**
   * æç»éå½ç»å¶ç»ä»¶åå¾å½¢
   * @param isUpdate
   */
  protected renderPaintRecursive(isUpdate: boolean) {
    const middleGroup = this.middleGroup;
    if (this.limitInPlot) {
      const { type, attrs } = getCoordinateClipCfg(this.coordinateInstance);
      middleGroup.setClip({
        type,
        attrs,
      });
    } else {
      // æ¸é¤å·²æç clip
      middleGroup.setClip(undefined);
    }

    // 1. æ¸²æå ä½æ è®°
    this.paintGeometries(isUpdate);
    // 2. ç»å¶ç»ä»¶
    this.renderComponents(isUpdate);

    // åæ ·éå½å¤çå­ views
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      view.renderPaintRecursive(isUpdate);
    }
  }

  // end Get æ¹æ³

  /**
   * åå»º scaleï¼éå½å°é¡¶å± view å»åå»ºåç¼å­ scale
   * @param field
   * @param data
   * @param scaleDef
   * @param key
   */
  protected createScale(field: string, data: Data, scaleDef: ScaleOption, key: string): Scale {
    // 1. åå¹¶ field å¯¹åºç scaleDefï¼åå¹¶ååæ¯åºå±è¦çé¡¶å±ï¼å°±è¿ååï¼
    const currentScaleDef = get(this.options.scales, [field]);
    const mergedScaleDef = { ...currentScaleDef, ...scaleDef };

    // 2. æ¯å¦å­å¨ç¶ viewï¼å¨åéå½ï¼å¦ååå»º
    if (this.parent) {
      return this.parent.createScale(field, data, mergedScaleDef, key);
    }

    // 3. å¨æ ¹èç¹ view éè¿ scalePool åå»º
    return this.scalePool.createScale(field, data, mergedScaleDef, key);
  }

  /**
   * éå½æ¸²æä¸­çæ°æ®å¤ç
   * @param isUpdate
   */
  private renderDataRecursive(isUpdate: boolean) {
    // 1. å¤çæ°æ®
    this.doFilterData();
    // 2. åå»ºå®ä¾
    this.createCoordinate();
    // 3. åå§å Geometry
    this.initGeometries(isUpdate);
    // 4. å¤çåé¢é»è¾ï¼æç»é½æ¯çæå­ view å geometry
    this.renderFacet(isUpdate);

    // åæ ·éå½å¤çå­ views
    const views = this.views;
    for (let i = 0, len = views.length; i < len; i++) {
      const view = views[i];
      view.renderDataRecursive(isUpdate);
    }
  }

  /**
   * è®¡ç® regionï¼è®¡ç®å®éçåç´ èå´åæ 
   * @private
   */
  private calculateViewBBox() {
    let x;
    let y;
    let width;
    let height;

    if (this.parent) {
      const bbox = this.parent.coordinateBBox;
      // å­å¨ parentï¼ é£ä¹å°±æ¯éè¿ç¶å®¹å¨å¤§å°è®¡ç®
      x = bbox.x;
      y = bbox.y;
      width = bbox.width;
      height = bbox.height;
    } else {
      // é¡¶å±å®¹å¨ï¼ä» canvas ä¸­åå¼ å®½é«
      x = 0;
      y = 0;
      width = this.canvas.get('width');
      height = this.canvas.get('height');
    }

    const { start, end } = this.region;

    // æ ¹æ® region è®¡ç®å½å view ç bbox å¤§å°ã
    const viewBBox = new BBox(
      x + width * start.x,
      y + height * start.y,
      width * (end.x - start.x),
      height * (end.y - start.y)
    );

    if (!this.viewBBox || !this.viewBBox.isEqual(viewBBox)) {
      // viewBBox åçååçæ¶åè¿è¡æ´æ°
      this.viewBBox = new BBox(
        x + width * start.x,
        y + height * start.y,
        width * (end.x - start.x),
        height * (end.y - start.y)
      );
    }

    // åå§ç coordinate bbox å¤§å°
    this.coordinateBBox = this.viewBBox;
  }

  /**
   * åå§åäºä»¶æºå¶ï¼G 4.0 åºå±åç½®æ¯æ name:event çæºå¶ï¼é£ä¹åªè¦ææç»ä»¶é½æèªå·±ç name å³å¯ã
   *
   * G2 çäºä»¶åªæ¯è·åäºä»¶å§æï¼ç¶åå¨ view åµå¥ç»æä¸­ï¼å½¢æäºä»¶åæ³¡æºå¶ã
   * å½å view åªå§æèªå·± view ä¸­ç Component å Geometry äºä»¶ï¼å¹¶åä¸åæ³¡
   * @private
   */
  private initEvents() {
    // ä¸å± group ä¸­ç shape äºä»¶é½ä¼éè¿ G åæ³¡ä¸æ¥ç
    this.foregroundGroup.on('*', this.onDelegateEvents);
    this.middleGroup.on('*', this.onDelegateEvents);
    this.backgroundGroup.on('*', this.onDelegateEvents);

    this.canvas.on('*', this.onCanvasEvent);
  }

  private onCanvasEvent = (evt: GEvent): void => {
    const name = evt.name;
    if (!name.includes(':')) {
      // éå§æäºä»¶
      const e = this.createViewEvent(evt);
      // å¤ç plot äºä»¶
      this.doPlotEvent(e);
      this.emit(name, e);
    }
  };

  /**
   * åå§åæä»¶
   */
  private initComponentController() {
    const usedControllers = this.usedControllers;
    for (let i = 0, len = usedControllers.length; i < len; i++) {
      const controllerName = usedControllers[i];
      const Ctor = getComponentController(controllerName);
      if (Ctor) {
        this.controllers.push(new Ctor(this));
      }
    }
  }

  private createViewEvent(evt: GEvent) {
    const { shape, name } = evt;

    const data = shape ? shape.get('origin') : null;
    // äºä»¶å¨ view åµå¥ä¸­åæ³¡ï¼æä¸æä¾é»æ­¢åæ³¡çæºå¶ï¼
    const e = new Event(this, evt, data);
    e.type = name;
    return e;
  }
  /**
   * è§¦åäºä»¶ä¹å
   * @param evt
   */
  private onDelegateEvents = (evt: GEvent): void => {
    // é»æ­¢ç»§ç»­åæ³¡ï¼é²æ­¢éå¤äºä»¶è§¦å
    // evt.preventDefault();
    const { name } = evt;
    if (!name.includes(':')) {
      return;
    }
    // äºä»¶å¨ view åµå¥ä¸­åæ³¡ï¼æä¸æä¾é»æ­¢åæ³¡çæºå¶ï¼
    const e = this.createViewEvent(evt);

    // åå«æåºæ¬äºä»¶ãç»åäºä»¶
    this.emit(name, e);
    // const currentTarget = evt.currentTarget as IShape;
    // const inheritNames = currentTarget.get('inheritNames');
    // if (evt.delegateObject || inheritNames) {
    //   const events = this.getEvents();
    //   each(inheritNames, (subName) => {
    //     const eventName = `${subName}:${type}`;
    //     if (events[eventName]) {
    //       this.emit(eventName, e);
    //     }
    //   });
    // }
  };

  /**
   * å¤ç PLOT_EVENTS
   * plot event éè¦å¤çææçåºç¡äºä»¶ï¼å¹¶å¤æ­æ¯å¦å¨ç»å¸ä¸­ï¼ç¶ååå³å®æ¯å¦è¦ emitã
   * å¯¹äº mouseenterãmouseleave æ¯è¾ç¹æ®ï¼éè¦åä¸ä¸æ°å­¦æ¯è¾ã
   * @param e
   */
  private doPlotEvent(e: Event) {
    const { type, x, y } = e;

    const point = { x, y };

    const ALL_EVENTS = [
      'mousedown',
      'mouseup',
      'mousemove',
      'mouseleave',
      'mousewheel',
      'touchstart',
      'touchmove',
      'touchend',
      'touchcancel',
      'click',
      'dblclick',
      'contextmenu',
    ];

    if (ALL_EVENTS.includes(type)) {
      const currentInPlot = this.isPointInPlot(point);
      const newEvent = e.clone();

      if (currentInPlot) {
        const TYPE = `plot:${type}`; // ç»å plot äºä»¶
        newEvent.type = TYPE;
        this.emit(TYPE, newEvent);
        if (type === 'mouseleave' || type === 'touchend') {
          // å¨plot åé¨å´ç¦»å¼ç»å¸
          this.isPreMouseInPlot = false;
        }
      }

      // å¯¹äº mouseenter, mouseleave çè®¡ç®å¤ç
      if (type === 'mousemove' || type === 'touchmove') {
        if (this.isPreMouseInPlot && !currentInPlot) {
          if (type === 'mousemove') {
            newEvent.type = PLOT_EVENTS.MOUSE_LEAVE;
            this.emit(PLOT_EVENTS.MOUSE_LEAVE, newEvent);
          }
          newEvent.type = PLOT_EVENTS.LEAVE;
          this.emit(PLOT_EVENTS.LEAVE, newEvent);
        } else if (!this.isPreMouseInPlot && currentInPlot) {
          if (type === 'mousemove') {
            newEvent.type = PLOT_EVENTS.MOUSE_ENTER;
            this.emit(PLOT_EVENTS.MOUSE_ENTER, newEvent);
          }
          newEvent.type = PLOT_EVENTS.ENTER;
          this.emit(PLOT_EVENTS.ENTER, newEvent);
        }
        // èµæ°çç¶æå¼
        this.isPreMouseInPlot = currentInPlot;
      } else if (type === 'mouseleave' || type === 'touchend') {
        // å¯è½ä¸å¨ currentInPlot ä¸­
        if (this.isPreMouseInPlot) {
          if (type === 'mouseleave') {
            newEvent.type = PLOT_EVENTS.MOUSE_LEAVE;
            this.emit(PLOT_EVENTS.MOUSE_LEAVE, newEvent);
          }
          newEvent.type = PLOT_EVENTS.LEAVE;
          this.emit(PLOT_EVENTS.LEAVE, newEvent);

          this.isPreMouseInPlot = false;
        }
      }
    }
  }

  // view çå½å¨æ ââ æ¸²ææµç¨

  /**
   * å¤çç­éå¨ï¼ç­éæ°æ®
   * @private
   */
  private doFilterData() {
    const { data } = this.options;
    this.filteredData = this.filterData(data);
  }

  /**
   * åå§å Geometries
   * @private
   */
  private initGeometries(isUpdate: boolean) {
    // åå§åå¾å½¢çä¹åï¼ååå»º / æ´æ° scales
    this.createOrUpdateScales();
    // å®ä¾å Geometryï¼ç¶å view å°ææç scale ç®¡çèµ·æ¥
    const coordinate = this.getCoordinate();
    const scaleDefs = get(this.options, 'scales', {});
    const geometries = this.geometries;
    for (let i = 0, len = geometries.length; i < len; i++) {
      const geometry = geometries[i];
      // ä¿æ scales å¼ç¨ä¸è¦åå
      geometry.scales = this.getGeometryScales();
      const cfg = {
        coordinate, // ä½¿ç¨ coordinate å¼ç¨ï¼å¯ä»¥ä¿æ coordinate çåæ­¥æ´æ°
        scaleDefs,
        data: this.filteredData,
        theme: this.themeObject,
        isDataChanged: this.isDataChanged,
        isCoordinateChanged: this.isCoordinateChanged,
      };

      if (isUpdate) {
        // æ°æ®åçæ´æ°
        geometry.update(cfg);
      } else {
        geometry.init(cfg);
      }
    }

    // Geometry åå§åä¹åï¼çæäº scaleï¼ç¶åè¿è¡è°æ´ scale éç½®
    this.adjustScales();
  }

  /**
   * æ ¹æ® Geometry çææå­æ®µåå»º scales
   * å¦æå­å¨ï¼åæ´æ°ï¼ä¸å­å¨ååå»º
   */
  private createOrUpdateScales() {
    const fields = this.getScaleFields();
    const groupedFields = this.getGroupedFields();

    const { data, scales = {} } = this.getOptions();
    const filteredData = this.filteredData;

    for (let i = 0, len = fields.length; i < len; i++) {
      const field = fields[i];
      const scaleDef = scales[field];

      // è°ç¨æ¹æ³ï¼éå½å»åå»º
      const key = this.getScaleKey(field);
      this.createScale(
        field,
        // åç»å­æ®µç scale ä½¿ç¨æªè¿æ»¤çæ°æ®åå»º
        groupedFields.includes(field) ? data : filteredData,
        scaleDef,
        key
      );

      // ç¼å­ä»å½å view åå»ºç scale key
      this.createdScaleKeys.set(key, true);
    }
  }

  /**
   * å¤ç scale åæ­¥é»è¾
   */
  private syncScale() {
    // æç»è°ç¨ root view ç
    this.getRootView().scalePool.sync(this.getCoordinate(), this.theme);
  }

  /**
   * è·å¾ Geometry ä¸­ç scale å¯¹è±¡
   */
  private getGeometryScales(): Record<string, Scale> {
    const fields = this.getScaleFields();

    const scales = {};
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      scales[field] = this.getScaleByField(field);
    }

    return scales;
  }

  private getScaleFields() {
    const fields = [];
    const tmpMap = new Map();
    const geometries = this.geometries;
    for (let i = 0; i < geometries.length; i++) {
      const geometry = geometries[i];
      const geometryScales = geometry.getScaleFields();
      uniq(geometryScales, fields, tmpMap);
    }
    return fields;
  }

  private getGroupedFields() {
    const fields = [];
    const tmpMap = new Map();
    const geometries = this.geometries;
    for (let i = 0; i < geometries.length; i++) {
      const geometry = geometries[i];
      const groupFields = geometry.getGroupFields();
      uniq(groupFields, fields, tmpMap);
    }
    return fields;
  }

  /**
   * è°æ´ scale éç½®
   * @private
   */
  private adjustScales() {
    // è°æ´ç®ååæ¬ï¼
    // åç±» scaleï¼è°æ´ range èå´
    this.adjustCategoryScaleRange();
  }

  /**
   * è°æ´åç±» scale ç rangeï¼é²æ­¢è¶åºåæ ç³»å¤é¢
   * @private
   */
  private adjustCategoryScaleRange() {
    const xyScales = [this.getXScale(), ...this.getYScales()].filter((e) => !!e);
    const coordinate = this.getCoordinate();
    const scaleOptions = this.options.scales;

    each(xyScales, (scale: Scale) => {
      const { field, values, isCategory, isIdentity } = scale;

      // åç±»æè identity ç scale æè¿è¡å¤ç
      if (isCategory || isIdentity) {
        // å­å¨ value å¼ï¼ä¸ç¨æ·æ²¡æéç½® range éç½®
        if (values && !get(scaleOptions, [field, 'range'])) {
          // æ´æ° range
          scale.range = getDefaultCategoryScaleRange(scale, coordinate, this.theme);
        }
      }
    });
  }

  /**
   * æ ¹æ® options éç½®ãGeometry å­æ®µéç½®ï¼èªå¨çæ components
   * @param isUpdate æ¯å¦æ¯æ´æ°
   * @private
   */
  private initComponents(isUpdate: boolean) {
    // åå¨é¨æ¸ç©ºï¼ç¶å render
    const controllers = this.controllers;
    for (let i = 0; i < controllers.length; i++) {
      const controller = controllers[i];
      // æ´æ°åèµ°æ´æ°é»è¾ï¼å¦åæ¸ç©ºè½½éç»
      if (isUpdate) {
        controller.update();
      } else {
        controller.clear();
        controller.render();
      }
    }
  }

  private doLayout() {
    this.layoutFunc(this);
  }

  /**
   * åå»ºåæ ç³»
   * @private
   */
  private createCoordinate() {
    const start = this.coordinateBBox.bl;
    const end = this.coordinateBBox.tr;
    this.coordinateInstance = this.coordinateController.create(start, end);
  }

  /**
   * æ ¹æ® options éç½®èªå¨æ¸²æ geometry
   * @private
   */
  private paintGeometries(isUpdate: boolean) {
    const doAnimation = this.options.animate;
    // geometry ç paint é¶æ®µ
    const coordinate = this.getCoordinate();
    const canvasRegion = {
      x: this.viewBBox.x,
      y: this.viewBBox.y,
      minX: this.viewBBox.minX,
      minY: this.viewBBox.minY,
      maxX: this.viewBBox.maxX,
      maxY: this.viewBBox.maxY,
      width: this.viewBBox.width,
      height: this.viewBBox.height,
    };
    const geometries = this.geometries;
    for (let i = 0; i < geometries.length; i++) {
      const geometry = geometries[i];
      geometry.coordinate = coordinate;
      geometry.canvasRegion = canvasRegion;
      if (!doAnimation) {
        // å¦æ view ä¸æ§è¡å¨ç»ï¼é£ä¹ view ä¸ææç geometry é½ä¸æ§è¡å¨ç»
        geometry.animate(false);
      }
      geometry.paint(isUpdate);
    }
  }

  /**
   * æåçç»å¶ç»ä»¶
   * @param isUpdate
   */
  private renderComponents(isUpdate: boolean) {
    // åå¨é¨æ¸ç©ºï¼ç¶å render
    for (let i = 0; i < this.getComponents().length; i++) {
      const co = this.getComponents()[i];
      (co.component as GroupComponent).render();
    }
  }

  /**
   * æ¸²æåé¢ï¼ä¼å¨å¶ä¸­è¿è¡æ°æ®åé¢ï¼ç¶åè¿è¡å­ view åå»º
   * @param isUpdate
   */
  private renderFacet(isUpdate: boolean) {
    if (this.facetInstance) {
      if (isUpdate) {
        this.facetInstance.update();
      } else {
        this.facetInstance.clear();
        // è®¡ç®åé¢æ°æ®
        this.facetInstance.init();
        // æ¸²æç»ä»¶å views
        this.facetInstance.render();
      }
    }
  }

  private initOptions() {
    const {
      geometries = [],
      interactions = [],
      views = [],
      annotations = [],
      coordinate,
      events,
      facets,
    } = this.options;

    // è®¾ç½®åæ ç³»
    if (this.coordinateController) {
      // æ´æ° coordinate controller
      coordinate && this.coordinateController.update(coordinate);
    } else {
      // åå»º coordinate controller
      this.coordinateController = new CoordinateController(coordinate);
    }

    // åå»º geometry å®ä¾
    for (let i = 0; i < geometries.length; i++) {
      const geometryOption = geometries[i];
      this.createGeometry(geometryOption);
    }

    // åå»º interactions å®ä¾
    for (let j = 0; j < interactions.length; j++) {
      const interactionOption = interactions[j];
      const { type, cfg } = interactionOption;
      this.interaction(type, cfg);
    }

    // åå»º view å®ä¾
    for (let k = 0; k < views.length; k++) {
      const viewOption = views[k];
      this.createView(viewOption);
    }

    // è®¾ç½® annotation
    const annotationComponent = this.getController('annotation');
    for (let l = 0; l < annotations.length; l++) {
      const annotationOption = annotations[l];
      annotationComponent.annotation(annotationOption);
    }

    // è®¾ç½® events
    if (events) {
      each(events, (eventCallback, eventName) => {
        this.on(eventName, eventCallback);
      });
    }

    if (facets) {
      each(facets, (facet) => {
        const { type, ...rest } = facet;

        this.facet(type, rest);
      });
    }
  }

  private createGeometry(geometryOption: GeometryOption) {
    const { type, cfg = {} } = geometryOption;
    if (this[type]) {
      const geometry = this[type](cfg);
      each(geometryOption, (v, k) => {
        if (isFunction(geometry[k])) {
          geometry[k](v);
        }
      });
    }
  }

  /**
   * scale key çåå»ºæ¹å¼
   * @param field
   */
  private getScaleKey(field: string): string {
    return `${this.id}-${field}`;
  }
}

/**
 * æ³¨å geometry ç»ä»¶
 * @param name
 * @param Ctor
 * @returns Geometry
 */
export function registerGeometry(name: string, Ctor: any) {
  // è¯­æ³ç³ï¼å¨ view API ä¸å¢å ååæ¹æ³
  View.prototype[name.toLowerCase()] = function (cfg: any = {}) {
    const props = {
      /** å¾å½¢å®¹å¨ */
      container: this.middleGroup.addGroup(),
      labelsContainer: this.foregroundGroup.addGroup(),
      ...cfg,
    };

    const geometry = new Ctor(props);
    this.geometries.push(geometry);

    return geometry;
  };
}

export default View;
