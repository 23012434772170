import { each, throttle } from '@antv/util';
import TransformAction from './scale-transform';

/**
 * ç¼©æ¾ Scale ç Action
 * @ignore
 */
class ScaleTranslate extends TransformAction {
  private zoomRatio = 0.05;
  /**
   * ç¼©å°
   */
  public zoomIn() {
    this.zoom(this.zoomRatio);
  }

  private zoom(scale) {
    const dims = this.dims;
    each(dims, (dim) => {
      this.zoomDim(dim, scale);
    });
    this.context.view.render(true);
  }

  /**
   * æ¾å¤§
   */
  public zoomOut() {
    this.zoom(-1 * this.zoomRatio);
  }

  // ç¼©æ¾åº¦é
  private zoomDim(dim, dRatio) {
    if (this.hasDim(dim)) {
      const scale = this.getScale(dim);
      if (scale.isLinear) {
        this.zoomLinear(dim, scale, dRatio);
      }
      //  else { // ææ¶ä»å¤çè¿ç»­å­æ®µ
      // this.zoomCategory(dim, scale, normalPoint);
      // }
    }
  }
  // linear åº¦éå¹³ç§»
  private zoomLinear(dim, scale, dRatio) {
    const view = this.context.view;
    // åªæç¬¬ä¸æ¬¡ç¼å­ï¼å¦åæ æ³åæ»
    if (!this.cacheScaleDefs[dim]) {
      this.cacheScaleDefs[dim] = {
        // @ts-ignore
        nice: scale.nice,
        min: scale.min,
        max: scale.max,
      };
    }
    // ä½¿ç¨ä½¿ç¨åå§åº¦éä½ä¸ºç¼©æ¾æ å
    const scaleDef = this.cacheScaleDefs[dim];
    const range = scaleDef.max - scaleDef.min;
    const { min, max } = scale;
    const d = dRatio * range;
    const toMin = min - d;
    const toMax = max + d;
    const curRange = toMax - toMin;
    const scaled = curRange / range;
    if (toMax > toMin && scaled < 100 && scaled > 0.01) {
      view.scale(scale.field, {
        // @ts-ignore
        nice: false,
        min: min - d,
        max: max + d,
      });
    }
  }

  // å¹³ç§»åç±»çåº¦é
  // private translateCategory(dim, scale, normalPoint) {
  // }
}

export default ScaleTranslate;
