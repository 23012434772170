import { View } from '@antv/g2';

/**
 * å¨ Chart ä¸­æ¥æ¾ç¹å® id çå­ View
 * @param chart
 * @param id
 */
export function findViewById(chart: View, id: string): View {
  return chart.views.find((view: View) => view.id === id);
}

/**
 * è·åå view åä¸çº§çææ views
 * @param view å½å view
 * @returns åä¸çº§ç views
 * @ignore
 */
export function getViews(view: View): View[] {
  const parent = view.parent;
  return parent ? parent.views : [];
}

/**
 * è·åå view åä¸çº§ç viewsï¼ä¸åæ¬èªèº«
 * @param view å½å view
 * @returns åä¸çº§ç views
 * @ignore
 */
export function getSiblingViews(view: View): View[] {
  return getViews(view).filter((sub) => sub !== view);
}
