import { LooseObject } from '../interface';
import { getXDimensionLength } from '../util/coordinate';
import Geometry from './base';
/** å¼å¥å¯¹åºç ShapeFactory */
import './shape/schema';
import { getDefaultSize } from './util/shape-size';

/**
 * Schema å ä½æ è®°ï¼ç¨äºä¸äºèªå®ä¹å¾å½¢çç»å¶ï¼æ¯å¦ç®±åå¾ãè¡ç¥¨å¾ç­ã
 */
export default class Schema extends Geometry {
  public readonly type: string = 'schema';
  public readonly shapeType: string = 'schema';
  protected generatePoints: boolean = true;

  /**
   * è·å Shape çå³é®ç¹æ°æ®ã
   * @param record
   * @returns
   */
  protected createShapePointsCfg(record: LooseObject) {
    const cfg = super.createShapePointsCfg(record);

    // è®¡ç®æ¯ä¸ª shape ç size
    let size;
    const sizeAttr = this.getAttribute('size');
    if (sizeAttr) {
      size = this.getAttributeValues(sizeAttr, record)[0];
      // å½ä¸å
      const coordinate = this.coordinate;
      const coordinateWidth = getXDimensionLength(coordinate);
      size = size / coordinateWidth;
    } else {
      if (!this.defaultSize) {
        this.defaultSize = getDefaultSize(this);
      }
      size = this.defaultSize;
    }
    cfg.size = size;

    return cfg;
  }
}
