import ListState from './list-state';

const STATUS_UNCHECKED = 'unchecked';

class ListFocus extends ListState {
  public toggle() {
    const triggerInfo = this.getTriggerListInfo();
    if (triggerInfo?.item) {
      const { list, item: clickedItem } = triggerInfo;
      const items = list.getItems();
      const checkedItems = items.filter((t) => !list.hasState(t, STATUS_UNCHECKED));
      const uncheckedItems = items.filter((t) => list.hasState(t, STATUS_UNCHECKED));
      const checkedItem = checkedItems[0]
      /**
       * 1. åå§åï¼å¨é¨ checkedãæ­¤æ¶ï¼ç¹å» radio, clickItem è¿å¥èç¦
       * 2. å½ååªéä¸­ä¸ä¸ª
       *    2.1 ä¸éä¸­ item ç­äº clickItemï¼éåºèç¦ï¼å¨é¨éæ°éä¸­
       *    2.2 æ¿æ¢èç¦ç item
       * 3. å¶å®ï¼å 2.2
       */
      if (items.length === checkedItems.length) {
        for (const item of items) list.setItemState(item, STATUS_UNCHECKED, item.id !== clickedItem.id);
      } else if (items.length - uncheckedItems.length === 1) {
        if (checkedItem.id === clickedItem.id) {
          for (const item of items) list.setItemState(item, STATUS_UNCHECKED, false);
        } else {
          for (const item of items) list.setItemState(item, STATUS_UNCHECKED, item.id !== clickedItem.id);
        }
      } else {
        for (const item of items) list.setItemState(item, STATUS_UNCHECKED, item.id !== clickedItem.id);
      }
    }
  }
}

export default ListFocus;
