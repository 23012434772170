import ElementRangeState from './range-state';

/**
 * @ignore
 * åºåéä¸­ç Action
 */
class ElementRangeSelected extends ElementRangeState {
  protected stateName: string = 'selected';
  /**
   * éä¸­
   */
  public selected() {
    this.setState();
  }
}

export default ElementRangeSelected;
