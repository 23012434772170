import { BRUSH_FILTER_EVENTS, VIEW_LIFE_CIRCLE } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { deepAssign } from '../../utils';
import { ScatterOptions } from './types';
import { adaptor, transformOptions, meta } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import './interactions';

export type { ScatterOptions };

export class Scatter extends Plot<ScatterOptions> {
  /**
   * è·å æ£ç¹å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<ScatterOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'scatter';

  constructor(container: string | HTMLElement, options: ScatterOptions) {
    super(container, options);

    // çå¬ brush äºä»¶ï¼å¤ç meta
    this.on(VIEW_LIFE_CIRCLE.BEFORE_RENDER, (evt) => {
      // è¿è¡æ¶ï¼è¯»å option
      const { options, chart } = this;
      if (evt.data?.source === BRUSH_FILTER_EVENTS.FILTER) {
        const filteredData = this.chart.filterData(this.chart.getData());
        meta({ chart, options: { ...options, data: filteredData } });
      }

      if (evt.data?.source === BRUSH_FILTER_EVENTS.RESET) {
        meta({ chart, options });
      }
    });
  }

  /**
   * @override
   * @param data
   */
  public changeData(data: ScatterOptions['data']) {
    this.updateOption(transformOptions(deepAssign({}, this.options, { data })));
    const { options, chart } = this;
    meta({ chart, options });
    this.chart.changeData(data);
  }

  /**
   * è·å æ£ç¹å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<ScatterOptions> {
    return adaptor;
  }

  protected getDefaultOptions() {
    return Scatter.getDefaultOptions();
  }
}
