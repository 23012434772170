import { getActionClass } from '@antv/g2';
import { placeElementsOrdered } from '../util';

const ElementHighlightAction: any = getActionClass('element-highlight');

export class VennElementHighlight extends ElementHighlightAction {
  /**
   * åæ­¥ææåç´ çä½ç½®
   */
  protected syncElementsPos() {
    placeElementsOrdered(this.context.view);
  }

  /** é«äº®å¾å½¢åç´  */
  public highlight() {
    super.highlight();
    this.syncElementsPos();
  }

  /** toggle å¾å½¢åç´ é«äº®ç¶æ */
  public toggle() {
    super.toggle();
    this.syncElementsPos();
  }

  /** æ¸æ¥ */
  public clear() {
    super.clear();
    this.syncElementsPos();
  }

  /** éç½® */
  public reset() {
    super.reset();
    this.syncElementsPos();
  }
}
