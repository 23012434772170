import Element from '../../../geometry/element/';
import { getElements } from '../util';
import { clearHighlight, setHighlightBy } from './highlight-util';
import ElementSingleState from './single-state';

/**
 * @ignore
 * åä¸ª Element Highlight ç Action
 */
class ElementSingleHighlight extends ElementSingleState {
  protected stateName: string = 'active';

  /**
   * Element Highlight
   */
  public highlight() {
    this.setState();
  }

  protected setElementState(element: Element, enable: boolean) {
    const view = this.context.view;
    const elements = getElements(view);
    setHighlightBy(elements, (el) => element === el, enable);
  }

  // æ¸çæææç activeï¼ unactive ç¶æ
  public clear() {
    const view = this.context.view;
    clearHighlight(view);
  }
}

export default ElementSingleHighlight;
