import { FIELD_ORIGIN } from '../constant';
import { MappingDatum } from '../interface';
import Path, { PathCfg } from './path';
import './shape/area';

/** Area å ä½æ è®°æé å½æ°åæ° */
export interface AreaCfg extends PathCfg {
  /**
   * é¢ç§¯å¾æ¯å¦ä» 0 åºåçº¿å¼å§å¡«åã
   * 1. é»è®¤å¼ä¸º `true`ï¼è¡¨ç°å¦ä¸ï¼
   * ![image](https://gw.alipayobjects.com/zos/rmsportal/ZQqwUCczalrKqGgagOVp.png)
   * 2. å½å¼ä¸º `false` æ¶ï¼è¡¨ç°å¦ä¸ï¼
   * ![image](https://gw.alipayobjects.com/zos/rmsportal/yPswkaXvUpCYOdhocGwB.png)
   */
  startOnZero?: boolean;
}

/**
 * Area å ä½æ è®°ç±»ã
 * å¸¸ç¨äºç»å¶é¢ç§¯å¾ã
 */
export default class Area extends Path {
  public readonly type: string = 'area';
  public readonly shapeType: string = 'area';
  /** çæå¾å½¢å³é®ç¹ */
  public readonly generatePoints: boolean = true;
  /**
   * é¢ç§¯å¾æ¯å¦ä» 0 åºåçº¿å¼å§å¡«åã
   * 1. é»è®¤å¼ä¸º `true`ï¼è¡¨ç°å¦ä¸ï¼
   * ![image](https://gw.alipayobjects.com/zos/rmsportal/ZQqwUCczalrKqGgagOVp.png)
   * 2. å½å¼ä¸º `false` æ¶ï¼è¡¨ç°å¦ä¸ï¼
   * ![image](https://gw.alipayobjects.com/zos/rmsportal/yPswkaXvUpCYOdhocGwB.png)
   */
  public readonly startOnZero: boolean = true;

  constructor(cfg: AreaCfg) {
    super(cfg);

    const { startOnZero = true, sortable = false, showSinglePoint = false } = cfg;
    this.startOnZero = startOnZero; // é»è®¤ä¸º true
    this.sortable = sortable; // å³é­é»è®¤ç X è½´æ°æ®æåº
    this.showSinglePoint = showSinglePoint;
  }

  /**
   * è·åå¾å½¢ç»å¶çå³é®ç¹ä»¥åæ°æ®
   * @param mappingData æ å°åçæ°æ®
   */
  protected getPointsAndData(mappingData: MappingDatum[]) {
    const points = [];
    const data = [];

    for (let i = 0, len = mappingData.length; i < len; i++) {
      const obj = mappingData[i];
      points.push(obj.points);
      data.push(obj[FIELD_ORIGIN]);
    }

    return {
      points,
      data,
    };
  }

  /**
   * è·å Y è½´ä¸çæå°å¼
   * @returns y å­æ®µæå°å¼
   */
  protected getYMinValue(): number {
    if (this.startOnZero) {
      return super.getYMinValue();
    }
    const yScale = this.getYScale();
    return yScale.min;
  }
}
