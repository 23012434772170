import { MappingDatum, ShapeInfo } from '../interface';
import Geometry from './base';
/** å¼å¥ Point å¯¹åºç ShapeFactory */
import './shape/point';

/**
 * Point å ä½æ è®°ã
 * å¸¸ç¨äºç»å¶ç¹å¾ã
 */
export default class Point extends Geometry {
  public readonly type: string = 'point';
  public readonly shapeType: string = 'point';
  protected generatePoints: boolean = true;

  /**
   * è·åä¸ä¸ªç¹çç»å¶ä¿¡æ¯ã
   * @param mappingDatum
   * @returns draw cfg
   */
  protected getDrawCfg(mappingDatum: MappingDatum): ShapeInfo {
    const shapeCfg = super.getDrawCfg(mappingDatum);

    return {
      ...shapeCfg,
      isStack: !!this.getAdjust('stack'), // å±å ç¹å¾
    };
  }
}
