export const DEFAULT_OPTIONS = {
  // é»è®¤æç§ name å­æ®µå¯¹é¢è²è¿è¡åç±»
  colorField: 'name',
  rectStyle: {
    lineWidth: 1,
    stroke: '#fff',
  },
  hierarchyConfig: {
    tile: 'treemapSquarify' as const,
  },
  label: {
    fields: ['name'],
    layout: {
      type: 'limit-in-shape',
    },
  },
  tooltip: {
    showMarkers: false,
    showTitle: false,
  },
  // ä¸é»äº¤äºéç½®ï¼é»è®¤ä¸å¼å¯
  drilldown: {
    enabled: false,
    breadCrumb: {
      position: 'bottom-left' as const,
      rootText: 'åå§',
      dividerText: '/',
      textStyle: {
        fontSize: 12,
        fill: 'rgba(0, 0, 0, 0.65)',
        cursor: 'pointer',
      },
      activeTextStyle: {
        fill: '#87B5FF',
      },
    },
  },
};
