import { map, reduce } from '@antv/util';
import { Data, Datum } from '../../types';
import { isRealNumber } from '../number';

/**
 * å¯¹æ°æ®è¿è¡ç¾åæ¯å
 * @param data
 * @param measure
 * @param groupField
 * @param as
 */
export function percent(data: Data, measure: string, groupField: string, as: string) {
  // 1. åè®¡ç®æ¯ä¸ä¸ªåç»ç max å¼
  const sumMap = reduce(
    data,
    (map, datum: Datum) => {
      const groupValue = datum[groupField];
      let sum = map.has(groupValue) ? map.get(groupValue) : 0;

      const v = datum[measure];

      sum = isRealNumber(v) ? sum + v : sum;
      map.set(groupValue, sum);

      return map;
    },
    new Map<string, number>()
  );

  // 2. å¾ªç¯æ°ç»ï¼è®¡ç®å æ¯
  return map(data, (datum: Datum) => {
    const v = datum[measure];
    const groupValue = datum[groupField];
    const percentage = isRealNumber(v) && sumMap.get(groupValue) !== 0 ? v / sumMap.get(groupValue) : 0;

    return {
      ...datum,
      [as]: percentage,
    };
  });
}

/**
 * å¯¹æ°æ®è¿è¡æ·±å±ç¾åæ¯å
 * @param data
 * @param measure  // æ°å¼
 * @param fields // éè¦åç»ç fieldå¼
 * @param as // å­å¨percent ç¾åæ¯çå¼
 */
export function getDeepPercent(data: Record<string, any>[], measure: string, fields: string[], percent: string) {
  const sumMap = reduce(
    data,
    (map, datum: Datum) => {
      // è·ååç»å¾å°çæä¸¾keyå¼
      const groupValue = reduce(fields, (value, field) => `${value}${datum[field]}`, '');

      let sum = map.has(groupValue) ? map.get(groupValue) : 0;

      const v = datum[measure];

      sum = isRealNumber(v) ? sum + v : sum;
      map.set(groupValue, sum);

      return map;
    },
    new Map<string, number>()
  );

  // 2. å¾ªç¯æ°ç»ï¼è®¡ç®å æ¯
  return map(data, (datum: Datum) => {
    const v = datum[measure];
    // è·ååç»å¾å°çæä¸¾keyå¼
    const groupValue = reduce(fields, (value, field) => `${value}${datum[field]}`, '');

    const percentage = isRealNumber(v) && sumMap.get(groupValue) !== 0 ? v / sumMap.get(groupValue) : 0;

    return {
      ...datum,
      [percent]: percentage,
    };
  });
}

/**
 * è·åæ°æ®ï¼å¦ææ¯ç¾åæ¯ï¼è¿è¡æ°æ®è½¬æ¢ (éç¨äºé¢ç§¯å¾ãæ±ç¶å¾ãæ¡å½¢å¾)
 * @param isPercent æ¯å¦ç¾åæ¯
 */
export function getDataWhetherPecentage(
  data: Record<string, any>[],
  yField: string,
  groupField: string,
  asField: string,
  isPercent?: boolean
) {
  return !isPercent ? data : percent(data, yField, groupField, asField);
}
