import { ControllerCtor } from './base';

const LOAD_COMPONENT_CONTROLLERS: Record<string, ControllerCtor> = {};

/**
 * å¨å±æ³¨åç»ä»¶ã
 * @param name ç»ä»¶åç§°
 * @param plugin æ³¨åçç»ä»¶ç±»
 * @returns void
 */
export function registerComponentController(name: string, plugin: ControllerCtor) {
  LOAD_COMPONENT_CONTROLLERS[name] = plugin;
}

/**
 * å é¤å¨å±ç»ä»¶ã
 * @param name ç»ä»¶å
 * @returns void
 */
export function unregisterComponentController(name: string) {
  delete LOAD_COMPONENT_CONTROLLERS[name];
}

/**
 * è·åä»¥æ³¨åçç»ä»¶åã
 * @returns string[] è¿åå·²æ³¨åçç»ä»¶åç§°
 */
export function getComponentControllerNames(): string[] {
  return Object.keys(LOAD_COMPONENT_CONTROLLERS);
}

/**
 * æ ¹æ®ç»ä»¶åè·åç»ä»¶ç±»ã
 * @param name ç»ä»¶å
 * @returns è¿åç»ä»¶ç±»
 */
export function getComponentController(name: string): ControllerCtor {
  return LOAD_COMPONENT_CONTROLLERS[name];
}
