import { DIRECTION } from '../constant';
import { Point } from '../dependents';
import { FacetTitle } from '../interface';

/**
 * @ignore
 * è·å facet title çæä½³é»è®¤éç½®ï¼é²æ­¢
 */
export function getFactTitleConfig(direction: DIRECTION): FacetTitle {
  if ([DIRECTION.TOP, DIRECTION.BOTTOM].includes(direction)) {
    return {
      offsetX: 0,
      offsetY: direction === DIRECTION.TOP ? -8 : 8,
      style: {
        textAlign: 'center',
        textBaseline: direction === DIRECTION.TOP ? 'bottom' : 'top',
      },
    };
  }

  if ([DIRECTION.LEFT, DIRECTION.RIGHT].includes(direction)) {
    return {
      offsetX: direction === DIRECTION.LEFT ? -8 : 8,
      offsetY: 0,
      style: {
        textAlign: direction === DIRECTION.LEFT ? 'right' : 'left',
        textBaseline: 'middle',
        rotate: Math.PI / 2, // ææ¬éè¯»ä¹ æ¯ä»ä¸å¾ä¸
      },
    };
  }

  return {};
}

/**
 * @ignore
 * æ ¹æ®è§åº¦ï¼è·å â ä¸çç¹
 * @param center
 * @param r
 * @param angle
 */
export function getAnglePoint(center: Point, r: number, angle: number): Point {
  return {
    x: center.x + r * Math.cos(angle),
    y: center.y + r * Math.sin(angle),
  };
}
