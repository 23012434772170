import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { deepAssign } from '../../utils';
import { DualAxesOptions } from './types';
import { adaptor } from './adaptor';

export type { DualAxesOptions };

export class DualAxes extends Plot<DualAxesOptions> {
  /** å¾è¡¨ç±»å: åè½´å¾ */
  public type: string = 'dual-axes';

  /**
   * è·å åè½´å¾ é»è®¤éç½®
   */
  protected getDefaultOptions(): Partial<DualAxesOptions> {
    return deepAssign({}, super.getDefaultOptions(), {
      yAxis: [],
      syncViewPadding: true,
    });
  }

  /**
   * è·ååè½´å¾çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<DualAxesOptions> {
    return adaptor;
  }
}
