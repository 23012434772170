import { get } from '@antv/util';
import { polygon as basePolygon } from '../../adaptor/geometries/polygon';
import { Params } from '../../core/adaptor';
import { interaction as commonInteraction, animation, theme, legend, annotation, tooltip } from '../../adaptor/common';
import { pattern } from '../../adaptor/pattern';
import { flow, deepAssign } from '../../utils';
import { getAdjustAppendPadding } from '../../utils/padding';
import { transformData, findInteraction, enableDrillInteraction } from './utils';
import { TreemapOptions } from './types';

/**
 * è·åé»è®¤ option
 * @param params
 */
function defaultOptions(params: Params<TreemapOptions>): Params<TreemapOptions> {
  const { options } = params;
  const { colorField } = options;

  return deepAssign(
    {
      options: {
        rawFields: ['value'],
        tooltip: {
          fields: ['name', 'value', colorField, 'path'],
          formatter: (data) => {
            return {
              name: data.name,
              value: data.value,
            };
          },
        },
      },
    },
    params
  );
}

/**
 * å­æ®µ
 * @param params
 */
function geometry(params: Params<TreemapOptions>): Params<TreemapOptions> {
  const { chart, options } = params;
  const { color, colorField, rectStyle, hierarchyConfig, rawFields } = options;

  const data = transformData({
    data: options.data,
    colorField: options.colorField,
    enableDrillDown: enableDrillInteraction(options),
    hierarchyConfig,
  });

  chart.data(data);

  // geometry
  basePolygon(
    deepAssign({}, params, {
      options: {
        xField: 'x',
        yField: 'y',
        seriesField: colorField,
        rawFields: rawFields,
        polygon: {
          color,
          style: rectStyle,
        },
      },
    })
  );

  // åä¸ä¸ªåè½¬ï¼è¿æ ·éåæåºï¼å¯ä»¥å°æå¤§å¼æ¾å°å·¦ä¸è§ï¼æå°å¼æ¾å°å³ä¸è§
  chart.coordinate().reflect('y');

  return params;
}

/**
 * åæ è½´
 * @param params
 */
function axis(params: Params<TreemapOptions>): Params<TreemapOptions> {
  const { chart } = params;
  chart.axis(false);
  return params;
}

function adaptorInteraction(options: TreemapOptions): TreemapOptions {
  const { drilldown, interactions = [] } = options;

  const enableDrillDown = enableDrillInteraction(options);
  if (enableDrillDown) {
    return deepAssign({}, options, {
      interactions: [
        ...interactions,
        {
          type: 'drill-down',
          // ð è¿ä¸æ¯ä¸ä¸ªè§èç APIï¼åç»­ä¼åæ´ãæéåè
          cfg: { drillDownConfig: drilldown, transformData },
        },
      ],
    });
  }
  return options;
}

/**
 * Interaction éç½®
 * @param params
 */
export function interaction(params: Params<TreemapOptions>): Params<TreemapOptions> {
  const { chart, options } = params;
  const { interactions, drilldown } = options;

  commonInteraction({
    chart,
    options: adaptorInteraction(options),
  });

  // éé view-zoom
  const viewZoomInteraction = findInteraction(interactions, 'view-zoom');

  if (viewZoomInteraction) {
    // å¼å¯ç¼©æ¾ interaction åï¼åé»æ­¢é»è®¤æ»å¨äºä»¶ï¼é¿åæ´ä¸ªçªå£çæ»å¨
    if (viewZoomInteraction.enable !== false) {
      chart.getCanvas().on('mousewheel', (ev) => {
        ev.preventDefault();
      });
    } else {
      // æå¨å³é­åï¼æ¸é¤ãä»å¯¹å£°æ viewZoomInteraction çæ¸é¤ã
      chart.getCanvas().off('mousewheel');
    }
  }

  // éåºä¸é»äº¤äºé¢åå±
  const enableDrillDown = enableDrillInteraction(options);
  if (enableDrillDown) {
    // ä¸ºé¢åå±å¨åºé¨çåº 25px çç©ºé´
    chart.appendPadding = getAdjustAppendPadding(chart.appendPadding, get(drilldown, ['breadCrumb', 'position']));
  }
  return params;
}

/**
 * ç©å½¢æ å¾
 * @param chart
 * @param options
 */
export function adaptor(params: Params<TreemapOptions>) {
  return flow(
    defaultOptions,
    theme,
    pattern('rectStyle'),
    geometry,
    axis,
    legend,
    tooltip,
    interaction,
    animation,
    annotation()
  )(params);
}
