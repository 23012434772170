export default {
  base: {
    remove: 'Ð£Ð´Ð°Ð»Ð¸ÑÑ',
    cancel: 'ÐÑÐ¼ÐµÐ½Ð°',
    confirm: 'ÐÐ¾Ð´ÑÐ²ÐµÑÐ´Ð¸ÑÑ',
    insert: 'ÐÑÑÐ°Ð²Ð¸ÑÑ',
    width: 'Ð¨Ð¸ÑÐ¸Ð½Ð°',
    height: 'ÐÑÑÐ¾ÑÐ°'
  },
  controls: {
    clear: 'ÐÑÐ¸ÑÑÐ¸ÑÑ',
    undo: 'ÐÑÐ¼ÐµÐ½Ð¸ÑÑ',
    redo: 'ÐÐ¾Ð²ÑÐ¾ÑÐ¸ÑÑ',
    fontSize: 'Ð Ð°Ð·Ð¼ÐµÑ ÑÑÐ¸ÑÑÐ°',
    color: 'Ð¦Ð²ÐµÑ',
    textColor: 'Ð¦Ð²ÐµÑ ÑÐµÐºÑÑÐ°',
    tempColors: 'Temp Colors',
    backgroundColor: 'Ð¦Ð²ÐµÑ ÑÐ¾Ð½Ð°',
    bold: 'ÐÐ¸ÑÐ½ÑÐ¹',
    lineHeight: 'ÐÐµÐ¶ÑÑÑÐ¾ÑÐ½ÑÐ¹ Ð¸Ð½ÑÐµÑÐ²Ð°Ð»',
    letterSpacing: 'ÐÐµÐ¶Ð±ÑÐºÐ²ÐµÐ½Ð½ÑÐ¹ Ð¸Ð½ÑÐµÑÐ²Ð°Ð»',
    textIndent: 'ÐÑÑÑÑÐ¿',
    increaseIndent: 'Ð£Ð²ÐµÐ»Ð¸ÑÐ¸ÑÑ Ð¾ÑÑÑÑÐ¿',
    decreaseIndent: 'Ð£Ð¼ÐµÐ½ÑÑÐ¸ÑÑ Ð¾ÑÑÑÑÐ¿',
    italic: 'ÐÑÑÑÐ¸Ð²',
    underline: 'ÐÐ¾Ð´ÑÐµÑÐºÐ½ÑÑÑÐ¹',
    strikeThrough: 'ÐÐµÑÐµÑÐµÑÐºÐ½ÑÑÑÐ¹',
    fontFamily: 'Ð¨ÑÐ¸ÑÑ',
    textAlign: 'Ð Ð°ÑÐ¿Ð¾Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ ÑÐµÐºÑÑÐ°',
    alignLeft: 'ÐÐ¾ Ð»ÐµÐ²Ð¾Ð¼Ñ ÐºÑÐ°Ñ',
    alignCenter: 'ÐÐ¾ ÑÐµÐ½ÑÑÑ',
    alignRight: 'ÐÐ¾ Ð¿ÑÐ°Ð²Ð¾Ð¼Ñ ÐºÑÐ°Ñ',
    alignJustify: 'ÐÐ¾ ÑÐ¸ÑÐ¸Ð½Ðµ',
    floatLeft: 'ÐÐ±ÑÐµÐºÐ°Ð½Ð¸Ðµ ÑÐ»ÐµÐ²Ð°',
    floatRight: 'ÐÐ±ÑÐµÐºÐ°Ð½Ð¸Ðµ ÑÐ¿ÑÐ°Ð²Ð°',
    superScript: 'ÐÐ°Ð´ÑÑÑÐ¾ÑÐ½ÑÐ¹ Ð¸Ð½Ð´ÐµÐºÑ',
    subScript: 'ÐÐ¾Ð´ÑÑÑÐ¾ÑÐ½ÑÐ¹ Ð¸Ð½Ð´ÐµÐºÑ',
    removeStyles: 'Ð£Ð±ÑÐ°ÑÑ ÑÑÐ¸Ð»Ð¸',
    headings: 'ÐÐ°Ð³Ð¾Ð»Ð¾Ð²ÐºÐ¸',
    header: 'ÐÐ°Ð³Ð¾Ð»Ð¾Ð²Ð¾Ðº',
    normal: 'ÐÐ±ÑÑÐ½ÑÐ¹',
    orderedList: 'Ð£Ð¿Ð¾ÑÑÐ´Ð¾ÑÐµÐ½Ð½ÑÐ¹ ÑÐ¿Ð¸ÑÐ¾Ðº',
    unorderedList: 'ÐÐµÑÐ¿Ð¾ÑÑÐ´Ð¾ÑÐµÐ½Ð½ÑÐ¹ ÑÐ¿Ð¸ÑÐ¾Ðº',
    blockQuote: 'Ð¦Ð¸ÑÐ°ÑÐ°',
    code: 'ÐÐ¾Ð´',
    link: 'ÐÑÑÐ°Ð²Ð¸ÑÑ ÑÑÑÐ»ÐºÑ',
    unlink: 'Ð£Ð±ÑÐ°ÑÑ ÑÑÑÐ»ÐºÑ',
    hr: 'ÐÐ¾ÑÐ¸Ð·Ð¾Ð½ÑÐ°Ð»ÑÐ½Ð°Ñ Ð»Ð¸Ð½Ð¸Ñ',
    media: 'ÐÐµÐ´Ð¸Ð°',
    mediaLibirary: 'ÐÐµÐ´Ð¸Ð° Ð±Ð¸Ð±Ð»Ð¸Ð¾ÑÐµÐºÐ°',
    emoji: 'Emoji',
    fullscreen: 'ÐÐ¾Ð»Ð½Ð¾ÑÐºÑÐ°Ð½Ð½ÑÐ¹ ÑÐµÐ¶Ð¸Ð¼',
    exitFullscreen: 'ÐÑÐ¹ÑÐ¸ Ð¸Ð· Ð¿Ð¾Ð»Ð½Ð¾ÑÐºÑÐ°Ð½Ð½Ð¾Ð³Ð¾ ÑÐµÐ¶Ð¸Ð¼Ð°'
  },
  linkEditor: {
    textInputPlaceHolder: 'ÐÐ²ÐµÐ´Ð¸ÑÐµ ÑÐµÐºÑÑ ÑÑÑÐ»ÐºÐ¸',
    linkInputPlaceHolder: 'ÐÑÑÐ°Ð²Ð¸ÑÑ ÑÑÑÐ»ÐºÑ',
    inputWithEnterPlaceHolder: 'ÐÑÑÐ°Ð²Ð¸ÑÑ ÑÑÑÐ»ÐºÑ Ð¸ Ð½Ð°Ð¶Ð°ÑÑ Enter',
    openInNewWindow: 'ÐÑÐºÑÑÑÑ Ð² Ð½Ð¾Ð²Ð¾Ð¼ Ð¾ÐºÐ½Ðµ',
    removeLink: 'Ð£Ð±ÑÐ°ÑÑ ÑÑÑÐ»ÐºÑ'
  },
  audioPlayer: {
    title: 'ÐÐ¾ÑÐ¿ÑÐ¾Ð¸Ð·Ð²ÐµÑÑÐ¸ Ð°ÑÐ´Ð¸Ð¾ÑÐ°Ð¹Ð»'
  },
  videoPlayer: {
    title: 'ÐÐ¾ÑÐ¿ÑÐ¾Ð¸Ð·Ð²ÐµÑÑÐ¸ Ð²Ð¸Ð´ÐµÐ¾ÑÐ°Ð¹Ð»',
    embedTitle: 'Embed Media'
  },
  media: {
    image: 'ÐÐ°ÑÑÐ¸Ð½ÐºÐ°',
    video: 'ÐÐ¸Ð´ÐµÐ¾',
    audio: 'ÐÑÐ´Ð¸Ð¾',
    embed: 'ÐÑÑÑÐ¾ÐµÐ½Ð½Ð¾Ðµ'
  }
}
