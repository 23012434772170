import { each, isNil, get } from '@antv/util';
import { ListItem } from '../../../dependents';
import Element from '../../../geometry/element/';
import {
  getCurrentElement,
  getDelegationObject,
  getElements,
  getElementValue,
  getScaleByField,
  isElementChange,
  isList,
} from '../util';
import StateBase from './state-base';

function getItem(shape) {
  return get(shape.get('delegateObject'), 'item');
}

/**
 * ç¶æé Action çåºç±»ï¼åè®¸å¤ä¸ª Element åæ¶æ¥ææä¸ªç¶æ
 * @class
 * @ignore
 */
class ElementState extends StateBase {
  protected ignoreListItemStates = ['unchecked'];

  // æ¯å¦å¿½ç¥è§¦åçåè¡¨é¡¹
  private isItemIgnore(item: ListItem, list) {
    const states = this.ignoreListItemStates;
    const filtered = states.filter((state) => {
      return list.hasState(item, state);
    });
    return !!filtered.length;
  }

  // è®¾ç½®ç±ç»ä»¶éé¡¹å¯¼è´çç¶æåå
  private setStateByComponent(component, item: ListItem, enable: boolean) {
    const view = this.context.view;
    const field = component.get('field');
    const elements = getElements(view);
    this.setElementsStateByItem(elements, field, item, enable);
  }

  // å¤çè§¦åæºç± element å¯¼è´çç¶æåå
  protected setStateByElement(element: Element, enable: boolean) {
    this.setElementState(element, enable);
  }

  /** ç»ä»¶çéé¡¹æ¯å¦å element å¹é */
  protected isMathItem(element: Element, field: string, item: ListItem) {
    const view = this.context.view;
    const scale = getScaleByField(view, field);
    const value = getElementValue(element, field);
    return !isNil(value) && item.name === scale.getText(value);
  }

  protected setElementsStateByItem(elements: Element[], field: string, item: ListItem, enable: boolean) {
    each(elements, (el) => {
      if (this.isMathItem(el, field, item)) {
        el.setState(this.stateName, enable);
      }
    });
  }

  /** è®¾ç½®ç¶ææ¯å¦æ¿æ´» */
  protected setStateEnable(enable: boolean) {
    const element = getCurrentElement(this.context);
    if (element) {
      // è§¦åæºç±äº element å¯¼è´
      if (isElementChange(this.context)) {
        this.setStateByElement(element, enable);
      }
    } else {
      // è§¦åæºç±ç»ä»¶å¯¼è´
      const delegateObject = getDelegationObject(this.context);
      // å¦æè§¦åæºæ¶åè¡¨ï¼å¾ä¾ãåæ è½´
      if (isList(delegateObject)) {
        const { item, component } = delegateObject;
        if (item && component && !this.isItemIgnore(item, component)) {
          const event = this.context.event.gEvent;
          // é²æ­¢éªç
          if (event && event.fromShape && event.toShape && getItem(event.fromShape) === getItem(event.toShape)) {
            return;
          }
          this.setStateByComponent(component, item, enable);
        }
      }
    }
  }

  /**
   * åæ¢ç¶æ
   */
  public toggle() {
    const element = getCurrentElement(this.context);
    if (element) {
      const hasState = element.hasState(this.stateName);
      this.setElementState(element, !hasState);
    }
  }

  /**
   * åæ¶å½åæ¶é´å½±åçç¶æ
   */
  public reset() {
    this.setStateEnable(false);
  }
}

export default ElementState;
