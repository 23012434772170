import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { getDataWhetherPecentage } from '../../utils/transform/percent';
import { BarOptions } from './types';
import { adaptor, meta } from './adaptor';
import { DEFAULT_OPTIONS } from './constants';

export type { BarOptions };

/**
 * æ¡å½¢å¾
 */
export class Bar extends Plot<BarOptions> {
  /**
   * è·å æ¡å½¢å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<BarOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public readonly type: string = 'bar';

  /**
   * @override
   */
  public changeData(data: BarOptions['data']) {
    this.updateOption({ data });
    const { chart, options } = this;
    const { xField, yField, isPercent } = options;
    const switchedFieldOptions = { ...options, xField: yField, yField: xField };
    meta({ chart, options: switchedFieldOptions });
    chart.changeData(getDataWhetherPecentage(data, xField, yField, xField, isPercent));
  }

  /**
   * è·å æ¡å½¢å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Bar.getDefaultOptions();
  }

  /**
   * è·å æ¡å½¢å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<BarOptions> {
    return adaptor;
  }
}
