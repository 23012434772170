import { get, isNumber, filter } from '@antv/util';
import { Data, Datum, Meta, Options } from '../types';
import { Node, NodeLinkData } from '../types/relation-data';
import { LEVEL, log } from './invariant';
import { pick } from './pick';

/**
 * æ¥çæ°æ®æ¯å¦æ¯å¨è´æ°ãæèå¨æ­£æ°
 * @param data
 * @param field
 */
export function adjustYMetaByZero(data: Data, field: string): Meta {
  if (!data) return {};
  // è¿æ»¤åºæ°å­æ°æ®
  const numberData = data.filter((datum: Datum) => {
    const v = get(datum, [field]);
    return isNumber(v) && !isNaN(v);
  });

  const gtZero = numberData.every((datum: Datum) => get(datum, [field]) >= 0);
  const ltZero = numberData.every((datum: Datum) => get(datum, [field]) <= 0);

  // ç®åæ¯å¢éæ´æ°ï¼å¯¹ { min: 0, max: undefined } è¿è¡ update({ max: 0 }) ä¼å¾å° { min: 0, max: 0 }
  if (gtZero) {
    return { min: 0 };
  }
  if (ltZero) {
    return { max: 0 };
  }
  return {};
}

/**
 * è½¬æ¢æ°æ®æ ¼å¼ä¸ºå¸¦æèç¹ä¸è¾¹çæ°æ®æ ¼å¼
 * @param data
 * @param sourceField
 * @param targetField
 * @param weightField
 * @param rawFields å­æ¾ä¸äºåæ°æ®
 */
export function transformDataToNodeLinkData(
  data: Data,
  sourceField: string,
  targetField: string,
  weightField: string,
  rawFields: string[] = []
): NodeLinkData {
  if (!Array.isArray(data)) {
    return {
      nodes: [],
      links: [],
    };
  }

  //   const nodes = [];
  const links = [];
  // åä½¿ç¨å¯¹è±¡æ¹å¼å­å¨
  const nodesMap: Record<string, Node> = {};
  let nodesIndex = -1;
  // æ°ç»åæ¢æ chord layout çæ°æ®ç»æ
  data.forEach((datum: Datum) => {
    const source = datum[sourceField];
    const target = datum[targetField];
    const weight = datum[weightField];

    const rawData = pick(datum, rawFields);

    // source node
    if (!nodesMap[source]) {
      nodesMap[source] = {
        id: ++nodesIndex,
        name: source,
        ...rawData,
      };
    }
    if (!nodesMap[target]) {
      nodesMap[target] = {
        id: ++nodesIndex,
        name: target,
        ...rawData,
      };
    }
    // links
    links.push({
      source: nodesMap[source].id,
      target: nodesMap[target].id,
      // sourceName: source,
      // targetName: target,
      value: weight,
      ...rawData,
    });
  });
  return {
    // éè¦æç§ id çé¡ºåº
    nodes: Object.values(nodesMap).sort((a, b) => a.id - b.id),
    links,
  };
}

/**
 * å¤çä¸åæ³çæ°æ®(è¿æ»¤ éæ°å¼å å NaNï¼ä¿ç null)
 * @param data
 * @param angleField
 */
export function processIllegalData(data: Options['data'], field: string) {
  const processData = filter(data, (d) => {
    const v = d[field];
    return v === null || (typeof v === 'number' && !isNaN(v));
  });

  // æå°å¼å¸¸æ°æ®æåµ
  log(LEVEL.WARN, processData.length === data.length, 'illegal data existed in chart data.');

  return processData;
}
