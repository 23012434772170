import { isArray, get } from '@antv/util';
import { View } from '@antv/g2';
import { HIERARCHY_DATA_TRANSFORM_PARAMS } from '../../interactions/actions/drill-down';
import { Interaction } from '../../types/interaction';
import { treemap } from '../../utils/hierarchy/treemap';
import { TreemapOptions } from './types';

export function findInteraction(
  interactions: TreemapOptions['interactions'],
  interactionType: string
): undefined | Interaction {
  if (!isArray(interactions)) return undefined;
  return interactions.find((i) => i.type === interactionType);
}

export function enableInteraction(interactions: TreemapOptions['interactions'], interactionType: string): boolean {
  const interaction = findInteraction(interactions, interactionType);
  return interaction && interaction.enable !== false;
}

/**
 * æ¯å¦åè®¸ä¸é»äº¤äº
 * @param interactions
 * @param interactionType
 * @returns
 */
export function enableDrillInteraction(options: TreemapOptions): boolean {
  const { interactions, drilldown } = options;
  // å¼å®¹æ§çæ¬, treemap-drill-down
  return get(drilldown, 'enabled') || enableInteraction(interactions, 'treemap-drill-down');
}

export function resetDrillDown(chart: View) {
  const drillDownInteraction = chart.interactions['drill-down'];

  if (!drillDownInteraction) return;

  // @ts-ignore
  const drillDownAction = drillDownInteraction.context.actions.find((i) => i.name === 'drill-down-action');

  drillDownAction.reset();
}

interface TransformDataOptions {
  data: TreemapOptions['data'];
  colorField: TreemapOptions['colorField'];
  enableDrillDown: boolean;
  hierarchyConfig: TreemapOptions['hierarchyConfig'];
}

export function transformData(options: TransformDataOptions) {
  const { data, colorField, enableDrillDown, hierarchyConfig } = options;

  const nodes = treemap(data, {
    ...hierarchyConfig,
    // @ts-ignore
    type: 'hierarchy.treemap',
    field: 'value',
    as: ['x', 'y'],
  });

  const result = [];
  nodes.forEach((node) => {
    if (node.depth === 0) {
      return null;
    }

    // å¼å¯ä¸é»ï¼ä»å è½½ depth === 1 çæ°æ®
    if (enableDrillDown && node.depth !== 1) {
      return null;
    }

    // ä¸å¼å¯ä¸é»ï¼å è½½ææå¶å­èç¹
    if (!enableDrillDown && node.children) {
      return null;
    }

    // path ä¿¡æ¯ä»æéå¿è¦ç¥ååç´ å±æ§ï¼å ä¸ºå¨æäºå±æ§æ¯ä¸å¿è¦(x, y), ææ¯ä¸åç¡®ç(ä¸é»æ¶ç depth)ï¼ä¸å¯¹å¤éåº
    const curPath = node.ancestors().map((n) => ({
      data: n.data,
      height: n.height,
      value: n.value,
    }));
    // å¨ä¸é»æ å¾ä¸­ï¼æ¯æ¬¡ç»å¶çæ¯å½åå±çº§ä¿¡æ¯ï¼å°ç¶åç´ çå±çº§ä¿¡æ¯ï¼data.path) åä¸å±æ¼æ¥ã
    const path = enableDrillDown && isArray(data.path) ? curPath.concat(data.path.slice(1)) : curPath;

    const nodeInfo = Object.assign({}, node.data, {
      x: node.x,
      y: node.y,
      depth: node.depth,
      value: node.value,
      path,
      ...node,
    });
    if (!node.data[colorField] && node.parent) {
      const ancestorNode = node.ancestors().find((n) => n.data[colorField]);
      nodeInfo[colorField] = ancestorNode?.data[colorField];
    } else {
      nodeInfo[colorField] = node.data[colorField];
    }

    nodeInfo[HIERARCHY_DATA_TRANSFORM_PARAMS] = { hierarchyConfig, colorField, enableDrillDown };
    result.push(nodeInfo);
  });
  return result;
}
