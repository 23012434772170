import { isArray } from '@antv/util';
import { Point, RangePoint } from '../../../interface';

/**
 * @ignore
 * æåç¹æ°æ®
 * @example
 * // result: [{x: 20, y: 20}, {x: 20, y: 30}]
 * splitPoints({x: 20,y: [20, 30]});
 * @example
 * // result: [{x: 20, y: 20}, {x: 30, y: 30}]
 * splitPoints({x: [20, 30],y: [20, 30]});
 * @param obj
 */
export function splitPoints(obj: RangePoint): Point[] {
  // y æå¯è½æ¯æ°ç»ï¼å¯¹åºåå§æ°æ®ä¸­ y ä¸ºä¸ä¸ªåºé´æ°æ®ï¼å¦ [19, 30]ï¼ä¸ºäºç»ä¸ä¹å° x è½¬æ¢ä¸ºæ°ç»
  const x = obj.x;
  const y = isArray(obj.y) ? obj.y : [obj.y];

  return y.map((eachY, index) => {
    return {
      x: isArray(x) ? x[index] : x,
      y: eachY,
    };
  });
}
