import { debounce, each, isString } from '@antv/util';
import { ChartCfg } from '../interface';
import { GROUP_Z_INDEX, VIEW_LIFE_CIRCLE } from '../constant';
import { getEngine } from '../engine';
import { createDom, getChartSize, removeDom, modifyCSS } from '../util/dom';
import View from './view';
import { AriaOption } from '../interface';

/**
 * Chart ç±»ï¼æ¯ä½¿ç¨ G2 è¿è¡ç»å¾çå¥å£ã
 */
export default class Chart extends View {
  /** Chart ç DOM å®¹å¨ */
  public ele: HTMLElement;

  /** å¾è¡¨å®½åº¦ */
  public width: number;
  /** å¾è¡¨é«åº¦ */
  public height: number;
  /** æ¯å¦å¼å¯å±é¨å·æ° */
  public localRefresh: boolean;
  /** æ¯å¦èªéåº DOM å®¹å¨å®½é«ï¼é»è®¤ä¸º falseï¼éè¦ç¨æ·æå¨æå®å®½é« */
  public autoFit: boolean;
  /** å¾è¡¨æ¸²æå¼æ */
  public renderer: 'canvas' | 'svg';

  private wrapperElement: HTMLElement;

  // @ts-ignore
  constructor(props: ChartCfg) {
    const {
      container,
      width,
      height,
      autoFit = false,
      padding,
      appendPadding,
      renderer = 'canvas',
      pixelRatio,
      localRefresh = true,
      visible = true,
      supportCSSTransform = false,
      defaultInteractions = ['tooltip', 'legend-filter', 'legend-active', 'continuous-filter', 'ellipsis-text'],
      options,
      limitInPlot,
      theme,
      syncViewPadding,
    } = props;

    const ele: HTMLElement = isString(container) ? document.getElementById(container) : container;

    // çæåé¨æ­£å¼ç»å¶ç div åç´ 
    const wrapperElement = createDom('<div style="position:relative;"></div>');
    ele.appendChild(wrapperElement);

    // if autoFit, use the container size, to avoid the graph render twice.
    const size = getChartSize(ele, autoFit, width, height);

    const G = getEngine(renderer);

    const canvas = new G.Canvas({
      container: wrapperElement,
      pixelRatio,
      localRefresh,
      supportCSSTransform,
      ...size,
    });

    // è°ç¨ view çåå»º
    super({
      parent: null,
      canvas,
      // create 3 group layers for views.
      backgroundGroup: canvas.addGroup({ zIndex: GROUP_Z_INDEX.BG }),
      middleGroup: canvas.addGroup({ zIndex: GROUP_Z_INDEX.MID }),
      foregroundGroup: canvas.addGroup({ zIndex: GROUP_Z_INDEX.FORE }),
      padding,
      appendPadding,
      visible,
      options,
      limitInPlot,
      theme,
      syncViewPadding,
    });

    this.ele = ele;
    this.canvas = canvas;
    this.width = size.width;
    this.height = size.height;
    this.autoFit = autoFit;
    this.localRefresh = localRefresh;
    this.renderer = renderer;
    this.wrapperElement = wrapperElement;

    // èªéåºå¤§å°
    this.updateCanvasStyle();
    this.bindAutoFit();
    this.initDefaultInteractions(defaultInteractions);
  }

  private initDefaultInteractions(interactions) {
    each(interactions, (interaction) => {
      this.interaction(interaction);
    });
  }

  /**
   * è®¾ç½® WAI-ARIA æ éç¢æ ç­¾ãå¦ä½æ ¹æ®å¾å½¢è¯­æ³èªå¨çæ arial åå®¹ï¼
   * @param ariaOption
   */
  public aria(ariaOption: AriaOption) {
    const ATTR = 'aria-label';
    if (ariaOption === false) {
      this.ele.removeAttribute(ATTR);
    } else {
      this.ele.setAttribute(ATTR, ariaOption.label);
    }
  }

  /**
   * æ¹åå¾è¡¨å¤§å°ï¼åæ¶éæ°æ¸²æã
   * @param width å¾è¡¨å®½åº¦
   * @param height å¾è¡¨é«åº¦
   * @returns
   */
  public changeSize(width: number, height: number) {
    // å¦æå®½é«ä¸è´ï¼é£ä¹ changeSize ä¸æ§è¡ä»»ä½æä½
    if (this.width === width && this.height === height) {
      return this;
    }

    this.emit(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_SIZE);

    this.width = width;
    this.height = height;
    this.canvas.changeSize(width, height);

    // éæ°æ¸²æ
    this.render(true);

    this.emit(VIEW_LIFE_CIRCLE.AFTER_CHANGE_SIZE);

    return this;
  }

  /**
   * æ¸ç©ºå¾è¡¨ï¼åæ¶æ¸é¤æ aria éç½®
   */
  public clear() {
    super.clear();

    this.aria(false);
  }

  /**
   * éæ¯å¾è¡¨ï¼åæ¶è§£ç»äºä»¶ï¼éæ¯åå»ºç G.Canvas å®ä¾ã
   * @returns void
   */
  public destroy() {
    super.destroy();

    this.unbindAutoFit();
    this.canvas.destroy();

    removeDom(this.wrapperElement);
    this.wrapperElement = null;
  }

  /**
   * æ¾ç¤ºæéèå¾è¡¨
   * @param visible æ¯å¦å¯è§ï¼true è¡¨ç¤ºæ¾ç¤ºï¼false è¡¨ç¤ºéè
   * @returns
   */
  public changeVisible(visible: boolean) {
    super.changeVisible(visible); // éè¦æ´æ° visible åé
    this.wrapperElement.style.display = visible ? '' : 'none';

    return this;
  }

  /**
   * èªå¨æ ¹æ®å®¹å¨å¤§å° resize ç»å¸
   */
  public forceFit() {
    // skip if already destroyed
    if (!this.destroyed) {
      // æ³¨æç¬¬äºåæ°ç¨ trueï¼æææ¯å³æ¶ autoFit = falseï¼forceFit() è°ç¨ä¹åä¸æ ·æ¯ééå®¹å¨
      const { width, height } = getChartSize(this.ele, true, this.width, this.height);
      this.changeSize(width, height);
    }
  }

  private updateCanvasStyle() {
    modifyCSS(this.canvas.get('el'), {
      display: 'inline-block',
      verticalAlign: 'middle',
    });
  }

  private bindAutoFit() {
    if (this.autoFit) {
      window.addEventListener('resize', this.onResize);
    }
  }

  private unbindAutoFit() {
    if (this.autoFit) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  /**
   * when container size changed, change chart size props, and re-render.
   */
  private onResize = debounce(() => {
    this.forceFit();
  }, 300);
}
