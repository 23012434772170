import { each } from '@antv/util';
import { View } from '../../../chart';
import { FilterCondition } from '../../../interface';
import RangeFilter from './range-filter';

import { getSilbings } from '../util';

/**
 * æ°æ®èå´è¿æ»¤ï¼ä½ä¸å¨å½åç view ä¸çæï¼èå¨å½åç view åä¸å±çº§çå¶ä» views ä¸çæï¼ç¨äºå®ç°èå¨è¿æ»¤ã
 * @ignore
 */
class SiblingFilter extends RangeFilter {
  /**
   * å¯¹ view è¿è¡è¿æ»¤
   * @param view
   * @param field
   * @param filter
   */
  protected filterView(view: View, field: string, filter: FilterCondition) {
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      sibling.filter(field, filter);
    });
  }

  /**
   * éæ°æ¸²æ
   * @param view
   */
  protected reRender(view: View) {
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      sibling.render(true);
    });
  }
}

export default SiblingFilter;
