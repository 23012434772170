import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';

/** ç´æ¹å¾ xField */
export const HISTOGRAM_X_FIELD = 'range';
/** ç´æ¹å¾ yField */
export const HISTOGRAM_Y_FIELD = 'count';

/**
 * é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
  columnStyle: {
    stroke: '#FFFFFF',
  },
  tooltip: {
    shared: true,
    showMarkers: false,
  },
  interactions: [{ type: 'active-region' }],
});
