import { Geometry } from '@antv/g2';
import { each, isNil, isObject } from '@antv/util';
import { Params } from '../core/adaptor';
import { Options } from '../types';
import { Interaction } from '../types/interaction';
import { Axis } from '../types/axis';
import { AXIS_META_CONFIG_KEYS } from '../constant';
import { pick, deepAssign } from '../utils';

/**
 * éç¨ legend éç½®, éç¨äºå¸¦ colorField æ seriesField çå¾è¡¨
 * @param params
 */
export function legend<O extends Pick<Options, 'legend'> & { colorField?: string; seriesField?: string }>(
  params: Params<O>
): Params<O> {
  const { chart, options } = params;
  const { legend, colorField, seriesField } = options;

  if (legend === false) {
    chart.legend(false);
  } else if (colorField || seriesField) {
    chart.legend(colorField || seriesField, legend);
  }

  return params;
}

/**
 * éç¨ tooltip éç½®
 * @param params
 */
export function tooltip<O extends Pick<Options, 'tooltip'>>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const { tooltip } = options;

  if (tooltip !== undefined) {
    chart.tooltip(tooltip);
  }

  return params;
}

/**
 * Interaction éç½®
 * @param params
 */
export function interaction<O extends Pick<Options, 'interactions'>>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const { interactions } = options;

  each(interactions, (i: Interaction) => {
    if (i.enable === false) {
      chart.removeInteraction(i.type);
    } else {
      chart.interaction(i.type, i.cfg || {});
    }
  });

  return params;
}

/**
 * å¨ç»
 * @param params
 */
export function animation<O extends Pick<Options, 'animation'>>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const { animation } = options;

  // åæ¶è®¾ç½®æ´ä¸ª view å¨ç»éé¡¹
  if (typeof animation === 'boolean') {
    chart.animate(animation);
  } else {
    chart.animate(true);
  }

  // ææç Geometry é½ä½¿ç¨åä¸å¨ç»ï¼åä¸ªå¾å½¢å¦æåºå«ï¼èªè¡è¦çï¼
  each(chart.geometries, (g: Geometry) => {
    g.animate(animation);
  });

  return params;
}

/**
 * è®¾ç½®å¨å±ä¸»é¢éç½®
 * @param params
 */
export function theme<O extends Pick<Options, 'theme'>>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const { theme } = options;

  // å­å¨ä¸»é¢æè®¾ç½®ä¸»é¢
  if (theme) {
    chart.theme(theme);
  }
  return params;
}

/**
 * ç¶æ state éç½®
 * @param params
 */
export function state<O extends Options>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const { state } = options;

  if (state) {
    each(chart.geometries, (geometry: Geometry) => {
      geometry.state(state);
    });
  }

  return params;
}

/**
 * å¤çç¼©ç¥è½´ç adaptor
 * @param params
 */
export function slider(params: Params<Options>): Params<Options> {
  const { chart, options } = params;
  const { slider } = options;

  chart.option('slider', slider);

  return params;
}

/**
 * å¤çç¼©ç¥è½´ç adaptor
 * @param params
 */
export function scrollbar(params: Params<Options>): Params<Options> {
  const { chart, options } = params;
  const { scrollbar } = options;

  chart.option('scrollbar', scrollbar);

  return params;
}

/**
 * scale ç adaptor
 * @param axes
 */
export function scale(axes: Record<string, Axis>, meta?: Options['meta']) {
  return function <O extends Pick<Options, 'meta'>>(params: Params<O>): Params<O> {
    const { chart, options } = params;

    // 1. è½´éç½®ä¸­ç scale ä¿¡æ¯
    let scales: Record<string, any> = {};
    each(axes, (axis: Axis, field: string) => {
      scales[field] = pick(axis, AXIS_META_CONFIG_KEYS);
    });

    // 2. meta ç´æ¥æ¯ scale çä¿¡æ¯
    scales = deepAssign({}, meta, options.meta, scales);

    chart.scale(scales);

    return params;
  };
}

/**
 * annotation éç½®
 * @param params
 */
export function annotation(annotationOptions?: Options['annotations']) {
  return function <O extends Pick<Options, 'annotations'>>(params: Params<O>): Params<O> {
    const { chart, options } = params;

    const annotationController = chart.getController('annotation');

    /** èªå®ä¹ annotation */
    each([...(options.annotations || []), ...(annotationOptions || [])], (annotationOption) => {
      // @ts-ignore
      annotationController.annotation(annotationOption);
    });

    return params;
  };
}

/**
 * èªå¨è®¾ç½® limitInPlot
 * @param params
 */
export function limitInPlot(params: Params<Options>): Params<Options> {
  const { chart, options } = params;
  const { yAxis, limitInPlot } = options;

  let value = limitInPlot;

  // ç¨æ·æ²¡æè®¾ç½® limitInPlotï¼åèªå¨æ ¹æ® yAxis æ¯å¦æ min/max æ¥è®¾ç½® limitInPlot
  if (isObject(yAxis) && isNil(limitInPlot)) {
    if (Object.values(pick(yAxis, ['min', 'max', 'minLimit', 'maxLimit'])).some((value) => !isNil(value))) {
      value = true;
    } else {
      value = false;
    }
  }
  chart.limitInPlot = value;

  return params;
}

export { pattern } from './pattern';
