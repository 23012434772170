import { VIEW_LIFE_CIRCLE, Event } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { LiquidOptions } from './types';
import { adaptor, statistic } from './adaptor';
import { DEFAULT_OPTIONS } from './constants';
import { getLiquidData } from './utils';
// register liquid shape
import './shapes/liquid';

export type { LiquidOptions };

/**
 * ä¼ è¯´ä¸­çæ°´æ³¢å¾
 */
export class Liquid extends Plot<LiquidOptions> {
  /**
   * è·å é¥¼å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<LiquidOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'liquid';

  /**
   * è·å æ°´æ³¢å¾ é»è®¤éç½®é¡¹, ä¾ base è·å
   */
  protected getDefaultOptions(): Partial<LiquidOptions> {
    return Liquid.getDefaultOptions();
  }

  /**
   * æ´æ°æ°æ®
   * @param percent
   */
  public changeData(percent: number) {
    this.chart.emit(
      VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, null)
    );
    this.updateOption({ percent });

    this.chart.data(getLiquidData(percent));
    statistic({ chart: this.chart, options: this.options }, true);

    this.chart.emit(
      VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, null)
    );
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<LiquidOptions> {
    return adaptor;
  }
}
