import { FIELD_ORIGIN } from '../constant';
import { MappingDatum, ShapeInfo } from '../interface';
import Geometry, { GeometryCfg } from './base';
import Element from './element';
/** å¼å¥å¯¹åºç ShapeFactory */
import './shape/line';
import { isModelChange } from './util/is-model-change';
import { diff } from './util/diff';

/** Path æé å½æ°åæ°ç±»å */
export interface PathCfg extends GeometryCfg {
  /** æ¯å¦è¿æ¥ç©ºå¼ */
  connectNulls?: boolean;
  /** åä¸ªå­¤ç«æ°æ®ç¹æ¯å¦å±ç¤º */
  showSinglePoint?: boolean;
}

/**
 * Path å ä½æ è®°ã
 * ç¨äºç»å¶è·¯å¾å¾ç­ã
 */
export default class Path extends Geometry {
  public readonly type: string = 'path';
  public readonly shapeType: string = 'line';
  /** æ¯å¦è¿æ¥ç©ºå¼ */
  public connectNulls: boolean;
  /** åä¸ªå­¤ç«æ°æ®ç¹æ¯å¦å±ç¤º */
  public showSinglePoint: boolean;

  constructor(cfg: PathCfg) {
    super(cfg);

    const { connectNulls = false, showSinglePoint = true } = cfg;
    this.connectNulls = connectNulls;
    this.showSinglePoint = showSinglePoint;
  }

  /**
   * åå»ºææç Element å®ä¾ï¼å¯¹äº PathãLineãAreaï¼ä¸ç»æ°æ®å¯¹åºä¸ä¸ª Elementã
   * @param mappingData
   * @param [isUpdate]
   * @returns elements
   */
  protected updateElements(mappingDataArray: MappingDatum[][], isUpdate: boolean = false) {
    // Path çæ¯ä¸ª element å¯¹åºä¸ç»æ°æ®
    const keyData = new Map<string, MappingDatum[]>();
    const keyIndex = new Map<string, number>();
    const keys: string[] = [];

    let index = 0;
    for (let i = 0; i < mappingDataArray.length; i++) {
      const mappingData = mappingDataArray[i];
      const key = this.getElementId(mappingData);
      keys.push(key);
      keyData.set(key, mappingData);
      keyIndex.set(key, index);
      index++;
    }

    this.elements = new Array(index);

    const { added, updated, removed } = diff(this.lastElementsMap, keys);

    for (const key of added) {
      const mappingData = keyData.get(key);
      const shapeFactory = this.getShapeFactory();
      const shapeCfg = this.getShapeInfo(mappingData);
      const i = keyIndex.get(key);
      const element = new Element({
        shapeFactory,
        container: this.container,
        offscreenGroup: this.getOffscreenGroup(),
        elementIndex: i,
      });
      element.geometry = this;
      element.animate = this.animateOption;
      element.draw(shapeCfg, isUpdate); // ç»å¶ shape
      this.elementsMap[key] = element;
      this.elements[i] = element;
    }

    for (const key of updated) {
      const mappingData = keyData.get(key);
      const element = this.lastElementsMap[key];
      const i = keyIndex.get(key);
      const shapeCfg = this.getShapeInfo(mappingData);
      const preShapeCfg = element.getModel();
      if (this.isCoordinateChanged || isModelChange(preShapeCfg, shapeCfg)) {
        element.animate = this.animateOption;
        // éè¿ç»å¶æ°æ®çåæ´æ¥å¤æ­æ¯å¦éè¦æ´æ°ï¼å ä¸ºç¨æ·æå¯è½ä¼ä¿®æ¹å¾å½¢å±æ§æ å°
        element.update(shapeCfg); // æ´æ°å¯¹åºç element
      }
      this.elementsMap[key] = element;
      this.elements[i] = element;
    }

    for (const key of removed) {
      const element = this.lastElementsMap[key];
      // æ´æ°å¨ç»éç½®ï¼ç¨æ·æå¯è½å¨æ´æ°ä¹åæå¯¹å¨ç»è¿è¡éç½®æä½
      element.animate = this.animateOption;
      element.destroy();
    }
  }

  /**
   * è·åç»æä¸æ¡çº¿ï¼ä¸ç»æ°æ®ï¼çææç¹ä»¥åæ°æ®
   * @param mappingData æ å°åçæ°ç»
   */
  protected getPointsAndData(mappingData: MappingDatum[]) {
    const points = [];
    const data = [];

    for (let i = 0, len = mappingData.length; i < len; i++) {
      const obj = mappingData[i];
      points.push({
        x: obj.x,
        y: obj.y,
      });
      data.push(obj[FIELD_ORIGIN]);
    }

    return {
      points,
      data,
    };
  }

  private getShapeInfo(mappingData: MappingDatum[]): ShapeInfo {
    const shapeCfg = this.getDrawCfg(mappingData[0]);
    const { points, data } = this.getPointsAndData(mappingData);
    shapeCfg.mappingData = mappingData;
    shapeCfg.data = data;
    shapeCfg.isStack = !!this.getAdjust('stack');
    shapeCfg.points = points;
    shapeCfg.connectNulls = this.connectNulls;
    shapeCfg.showSinglePoint = this.showSinglePoint;

    return shapeCfg;
  }
}
