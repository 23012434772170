import { LooseObject } from '@antv/g-svg';
import { parsePathString } from '@antv/path-util';
import { deepMix, get, upperFirst } from '@antv/util';
import { IGroup, IShape, PathCommand } from '../../dependents';
import {
  Point,
  RegisterShape,
  RegisterShapeFactory,
  Shape,
  ShapeFactory,
  ShapeInfo,
  ShapeMarkerAttrs,
  ShapeMarkerCfg,
  ShapePoint,
} from '../../interface';

import { convertNormalPath, convertPolarPath } from './util/path';

/** ShapeFactory åºç±» */
const ShapeFactoryBase = {
  /** åæ ç³»å¯¹è±¡ */
  coordinate: null,
  /** é»è®¤ç»å¶ç Shape ç±»å */
  defaultShapeType: null,
  /** ä¸»é¢æ ·å¼ */
  theme: null,
  /**
   * è·å shape ç»å¶éè¦çå³é®ç¹
   * @param shapeType shape ç±»å
   * @param shapePoint æ¯æ¡æ°æ®æ å°åçåæ ç¹ä»¥å size æ°å¼
   * @returns å¾å½¢å³é®ç¹ä¿¡æ¯
   */
  getShapePoints(shapeType: string, shapePoint: ShapePoint) {
    const shape = this.getShape(shapeType);
    if (shape.getPoints) {
      return shape.getPoints(shapePoint);
    }

    return this.getDefaultPoints(shapePoint);
  },
  /**
   * æ ¹æ® shape ç±»åè·åå·ä½ç shape å®ä¾
   * @param shapeType string shape çç±»å
   * @returns
   */
  getShape(shapeType: string): Shape {
    const shape = this[shapeType] || this[this.defaultShapeType];
    shape.coordinate = this.coordinate;

    return shape;
  },
  /**
   * è·å shape çé»è®¤å³é®ç¹
   * @override
   */
  getDefaultPoints() {
    return [];
  },
  /**
   * è·å shape çé»è®¤ç»å¶æ ·å¼ (åç½®ç shapeFactory åææ³¨åé»è®¤æ ·å¼)
   */
  getDefaultStyle(geometryTheme: LooseObject): LooseObject {
    return get(geometryTheme, [this.defaultShapeType, 'default', 'style'], {});
  },
  /**
   * è·å shape å¯¹åºçç¼©ç¥å¾éç½®ä¿¡æ¯ã
   * @param shapeType shape ç±»å
   * @param color é¢è²
   * @param isInPolar æ¯å¦å¨æåæ ç³»ä¸
   * @returns è¿åç¼©ç¥å¾ marker éç½®ã
   */
  getMarker(shapeType: string, markerCfg: ShapeMarkerCfg): ShapeMarkerAttrs {
    let shape = this.getShape(shapeType);

    if (!shape.getMarker) {
      const defaultShapeType = this.defaultShapeType;
      shape = this.getShape(defaultShapeType);
    }

    const theme = this.theme;
    const shapeStyle = get(theme, [shapeType, 'default'], {});
    const markerStyle = shape.getMarker(markerCfg);

    return deepMix({}, shapeStyle, markerStyle);
  },
  /**
   * ç»å¶ shape
   * @override
   * @param shapeType ç»å¶ç shape ç±»å
   * @param cfg ç»å¶ shape éè¦çä¿¡æ¯
   * @param element Element å®ä¾
   * @returns
   */
  drawShape(shapeType: string, cfg: ShapeInfo, container: IGroup): IShape | IGroup {
    const shape = this.getShape(shapeType);
    return shape.draw(cfg, container);
  },
};

/** Shape åºç±» */
const ShapeBase = {
  /** åæ ç³»å¯¹è±¡ */
  coordinate: null,
  /**
   * å°å½ä¸åç path è½¬æ¢æåæ ç³»ä¸ç path
   * @param path å½ä¸åçè·¯å¾
   * @returns
   */
  parsePath(path: string): PathCommand[] {
    const coordinate = this.coordinate;
    let parsedPath = parsePathString(path);
    if (coordinate.isPolar) {
      parsedPath = convertPolarPath(coordinate, parsedPath);
    } else {
      parsedPath = convertNormalPath(coordinate, parsedPath);
    }
    return parsedPath;
  },
  /**
   * å°å½ä¸åçåæ è½¬æ¢æç»å¸åæ 
   * @param point å½ä¸åçåæ ç¹æ°æ®
   * @returns
   */
  parsePoint(point: Point): Point {
    const coordinate = this.coordinate;
    return coordinate.convert(point);
  },
  /**
   * 0ï½1 points è½¬ ç»å¸ points
   * @param points èç¹éå
   * @returns
   */
  parsePoints(points: Point[]): Point[] {
    const coordinate = this.coordinate;
    return points.map((point) => {
      return coordinate.convert(point);
    });
  },
  /**
   * ç»å¶ shape
   * @override
   */
  draw(cfg: ShapeInfo, container: IGroup) {},
};

const ShapeFactoryMap = {};

/**
 * æ³¨å ShapeFactoryã
 * @param factoryName  ShapeFactory åç§°ï¼å¯¹åº Geometry å ä½æ è®°åç§°ã
 * @param cfg æ³¨å ShapeFactory éè¦è¦åå®ä¹çå±æ§ã
 * @returns è¿å ShapeFactory å¯¹è±¡ã
 */
export function registerShapeFactory(factoryName: string, cfg: RegisterShapeFactory): ShapeFactory {
  const className = upperFirst(factoryName);
  const geomObj = {
    ...ShapeFactoryBase,
    ...cfg,
    geometryType: factoryName,
  };
  ShapeFactoryMap[className] = geomObj;
  return geomObj;
}

/**
 * æ³¨å Shapeã
 * @param factoryName å¯¹åºç ShapeFactory åç§°ã
 * @param shapeType æ³¨åç shape åç§°ã
 * @param cfg æ³¨å Shape éè¦è¦åå®ä¹çå±æ§ã
 * @returns shape è¿åæ³¨åç shape å¯¹è±¡ã
 */
export function registerShape(factoryName: string, shapeType: string, cfg: RegisterShape): Shape {
  const className = upperFirst(factoryName);
  const factory = ShapeFactoryMap[className];
  const shapeObj = {
    ...ShapeBase,
    ...cfg,
  };
  factory[shapeType] = shapeObj;
  return shapeObj;
}

/**
 * è·å factoryName å¯¹åºç shapeFactory
 * @param factoryName
 * @returns shape factory
 */
export function getShapeFactory(factoryName: string): ShapeFactory {
  const className = upperFirst(factoryName);
  return ShapeFactoryMap[className];
}
