import { get, deepMix, isArray } from '@antv/util';
import { Writeable } from '../../util/types';
import { MappingDatum, Point } from '../../interface';
import GeometryLabel from './base';
import { LabelCfg, LabelItem, LabelPointCfg, TextAlign } from './interface';

/**
 * æ±ç¶å¾ label
 */
export default class IntervalLabel extends GeometryLabel {
  /**
   * è·å interval label çæ¹åï¼åå³äº value çå¼æ¯æ­£è¿æ¯è´
   * @param labelCfg
   */
  private getLabelValueDir(mappingData: MappingDatum) {
    // points ä¸­ç x/y å transpose æ å³
    const dim = 'y';
    const { points } = mappingData;

    return points[0][dim] <= points[2][dim] ? 1 : -1;
  }

  /**
   * éè½½ï¼æ ¹æ® interval å¼çæ­£è´æ¥è°æ´ label åç§»é
   * @param labelCfg
   * @param index
   * @param total
   */
  protected getLabelOffsetPoint(labelCfg: LabelCfg, index: number, total: number, position?: string) {
    let point = super.getLabelOffsetPoint(labelCfg, index, total);
    const coordinate = this.getCoordinate();
    const transposed = coordinate.isTransposed;
    const dim = transposed ? 'x' : 'y';
    const dir = this.getLabelValueDir(labelCfg.mappingData);
    point = { ...point, [dim]: point[dim] * dir };

    if (coordinate.isReflect('x')) {
      point = {
        ...point,
        x: point.x * -1,
      };
    }
    if (coordinate.isReflect('y')) {
      point = {
        ...point,
        y: point.y * -1,
      };
    }

    return point;
  }

  /**
   * éè½½ï¼å®å¶ interval label çé»è®¤ä¸»é¢éç½®
   * @param labelCfg
   */
  protected getThemedLabelCfg(labelCfg: LabelCfg) {
    const geometry = this.geometry;
    const defaultLabelCfg = this.getDefaultLabelCfg();
    const { theme } = geometry;

    // å¦æ interval label position è®¾ç½®ä¸º middleï¼åå°ä¸»é¢ä¸­ç offset è¦çä¸º 0
    return deepMix({}, defaultLabelCfg, theme.labels, labelCfg.position === 'middle' ? { offset: 0 } : {}, labelCfg);
  }

  protected setLabelPosition(
    labelPointCfg: Writeable<LabelPointCfg>,
    mappingData: MappingDatum,
    index: number,
    position: string
  ) {
    const coordinate = this.getCoordinate();
    const transposed = coordinate.isTransposed;
    const shapePoints = mappingData.points as Point[];
    const point0 = coordinate.convert(shapePoints[0]);
    const point2 = coordinate.convert(shapePoints[2]);
    const dir = this.getLabelValueDir(mappingData);

    let top;
    let right;
    let bottom;
    let left;

    const shape = isArray(mappingData.shape) ? mappingData.shape[0] : mappingData.shape;
    if (shape === 'funnel' || shape === 'pyramid') {
      // å¤çæ¼æå¾
      const nextPoints = get(mappingData, 'nextPoints');
      const points = get(mappingData, 'points');
      if (nextPoints) {
        // éæ¼æå¾åºé¨
        const p0 = coordinate.convert(points[0] as Point);
        const p1 = coordinate.convert(points[1] as Point);
        const nextP0 = coordinate.convert(nextPoints[0] as Point);
        const nextP1 = coordinate.convert(nextPoints[1] as Point);

        // TODO: ä½¿ç¨åå´ççè®¡ç®æ¹æ³
        if (transposed) {
          top = Math.min(nextP0.y, p0.y);
          bottom = Math.max(nextP0.y, p0.y);
          right = (p1.x + nextP1.x) / 2;
          left = (p0.x + nextP0.x) / 2;
        } else {
          top = Math.min((p1.y + nextP1.y) / 2, (p0.y + nextP0.y) / 2);
          bottom = Math.max((p1.y + nextP1.y) / 2, (p0.y + nextP0.y) / 2);
          right = nextP1.x;
          left = p0.x;
        }
      } else {
        top = Math.min(point2.y, point0.y);
        bottom = Math.max(point2.y, point0.y);
        right = point2.x;
        left = point0.x;
      }
    } else {
      top = Math.min(point2.y, point0.y);
      bottom = Math.max(point2.y, point0.y);
      right = point2.x;
      left = point0.x;
    }

    switch (position) {
      case 'right':
        labelPointCfg.x = right;
        labelPointCfg.y = (top + bottom) / 2;
        labelPointCfg.textAlign = get(labelPointCfg, 'textAlign', dir > 0 ? 'left' : 'right');
        break;
      case 'left':
        labelPointCfg.x = left;
        labelPointCfg.y = (top + bottom) / 2;
        labelPointCfg.textAlign = get(labelPointCfg, 'textAlign', dir > 0 ? 'left' : 'right');
        break;
      case 'bottom':
        if (transposed) {
          labelPointCfg.x = (right + left) / 2;
        }
        labelPointCfg.y = bottom;
        labelPointCfg.textAlign = get(labelPointCfg, 'textAlign', 'center');
        labelPointCfg.textBaseline = get(labelPointCfg, 'textBaseline', dir > 0 ? 'bottom' : 'top');
        break;
      case 'middle':
        if (transposed) {
          labelPointCfg.x = (right + left) / 2;
        }
        labelPointCfg.y = (top + bottom) / 2;
        labelPointCfg.textAlign = get(labelPointCfg, 'textAlign', 'center');
        labelPointCfg.textBaseline = get(labelPointCfg, 'textBaseline', 'middle');
        break;
      case 'top':
        if (transposed) {
          labelPointCfg.x = (right + left) / 2;
        }
        labelPointCfg.y = top;
        labelPointCfg.textAlign = get(labelPointCfg, 'textAlign', 'center');
        labelPointCfg.textBaseline = get(labelPointCfg, 'textBaseline', dir > 0 ? 'bottom' : 'top');
        break;
      default:
        break;
    }
  }
}
