import {
  contains,
  deepMix,
  each,
  get,
  isArray,
  isFunction,
  isNil,
  isString,
  keys,
  upperFirst,
  find,
  includes,
} from '@antv/util';
import { Annotation as AnnotationComponent, IElement, IGroup } from '../../dependents';
import {
  AnnotationBaseOption as BaseOption,
  AnnotationPosition as Position,
  ArcOption,
  ComponentOption,
  ShapeAnnotationOption,
  Data,
  DataMarkerOption,
  DataRegionOption,
  Datum,
  HtmlAnnotationOption,
  ImageOption,
  LineOption,
  Point,
  RegionFilterOption,
  RegionOption,
  RegionPositionBaseOption,
  TextOption,
} from '../../interface';

import { DEFAULT_ANIMATE_CFG } from '../../animate/';
import { COMPONENT_TYPE, DIRECTION, GEOMETRY_LIFE_CIRCLE, LAYER, VIEW_LIFE_CIRCLE } from '../../constant';

import Geometry from '../../geometry/base';
import Element from '../../geometry/element';
import { getAngleByPoint, getDistanceToCenter } from '../../util/coordinate';
import { omit } from '../../util/helper';
import { getNormalizedValue } from '../../util/annotation';
import View from '../view';
import { Controller } from './base';
import { Scale } from '@antv/attr';

/** éè¦å¨å¾å½¢ç»å¶å®æåææ¸²æçè¾å©ç»ä»¶ç±»ååè¡¨ */
const ANNOTATIONS_AFTER_RENDER = ['regionFilter', 'shape'];

/**
 * Annotation controller, ä¸»è¦ä½ç¨:
 * 1. åå»º Annotation: lineãtextãarc ...
 * 2. çå½å¨æ: initãlayoutãrenderãclearãdestroy
 */
export default class Annotation extends Controller<BaseOption[]> {
  private foregroundContainer: IGroup;
  private backgroundContainer: IGroup;

  /* ç»ä»¶æ´æ°ç cacheï¼ç»ä»¶éç½® object : ç»ä»¶ */
  private cache = new Map<BaseOption, ComponentOption>();

  constructor(view: View) {
    super(view);

    this.foregroundContainer = this.view.getLayer(LAYER.FORE).addGroup();
    this.backgroundContainer = this.view.getLayer(LAYER.BG).addGroup();

    this.option = [];
  }

  public get name(): string {
    return 'annotation';
  }

  public init() { }

  /**
   * å ä¸º annotation éè¦ä¾èµåæ ç³»ä¿¡æ¯ï¼æä»¥ render é¶æ®µä¸ºç©ºæ¹æ³ï¼å®éçåå»ºé»è¾é½å¨ layout ä¸­
   */
  public layout() {
    this.update();
  }

  // å ä¸º Annotation ä¸åä¸å¸å±ï¼ä½æ¯æ¸²æçä½ç½®ä¾èµäºåæ ç³»ï¼æä»¥å¯ä»¥å°ç»å¶é¶æ®µå»¶è¿å° layout() è¿è¡
  public render() { }

  /**
   * æ´æ°
   */
  public update() {
    // 1. åå¤çéè¦å¨å¾å½¢æ¸²æä¹åçè¾å©ç»ä»¶ éè¦å¨ Geometry å®æä¹åï¼æ¿å°å¾å½¢ä¿¡æ¯
    this.onAfterRender(() => {
      const updated = new Map<BaseOption, ComponentOption>();
      // åçæ¯å¦æ regionFilter/shape è¦æ´æ°
      each(this.option, (option: BaseOption) => {
        if (includes(ANNOTATIONS_AFTER_RENDER, option.type)) {
          const co = this.updateOrCreate(option);
          // å­å¨å·²ç»å¤çè¿ç
          if (co) {
            updated.set(this.getCacheKey(option), co);
          }
        }
      });

      // å¤çå®æä¹åï¼æ´æ° cache
      // å¤çå®æä¹åï¼éæ¯å é¤ç
      this.cache = this.syncCache(updated);
    });

    // 2. å¤çé regionFilter
    const updateCache = new Map<BaseOption, ComponentOption>();
    each(this.option, (option: BaseOption) => {
      if (!includes(ANNOTATIONS_AFTER_RENDER, option.type)) {
        const co = this.updateOrCreate(option);
        // å­å¨å·²ç»å¤çè¿ç
        if (co) {
          updateCache.set(this.getCacheKey(option), co);
        }
      }
    });
    this.cache = this.syncCache(updateCache);
  }

  /**
   * æ¸ç©º
   * @param includeOption æ¯å¦æ¸ç©º option éç½®é¡¹
   */
  public clear(includeOption = false) {
    super.clear();

    this.clearComponents();
    this.foregroundContainer.clear();
    this.backgroundContainer.clear();

    // clear all option
    if (includeOption) {
      this.option = [];
    }
  }

  public destroy() {
    this.clear(true);

    this.foregroundContainer.remove(true);
    this.backgroundContainer.remove(true);
  }

  /**
   * å¤ååºç±»çæ¹æ³
   */
  public getComponents(): ComponentOption[] {
    const co = [];

    this.cache.forEach((value: ComponentOption) => {
      co.push(value);
    });

    return co;
  }

  /**
   * æ¸é¤å½åçç»ä»¶
   */
  private clearComponents() {
    this.getComponents().forEach((co) => {
      co.component.destroy();
    });

    this.cache.clear();
  }

  /**
   * region filter æ¯è¾ç¹æ®çæ¸²ææ¶æº
   * @param doWhat
   */
  private onAfterRender(doWhat: () => void) {
    if (this.view.getOptions().animate) {
      this.view.geometries.forEach((g: Geometry) => {
        // å¦æ geometry å¼å¯ï¼åçå¬
        if (g.animateOption) {
          g.once(GEOMETRY_LIFE_CIRCLE.AFTER_DRAW_ANIMATE, () => {
            doWhat();
          });
        }
      });
    } else {
      this.view.getRootView().once(VIEW_LIFE_CIRCLE.AFTER_RENDER, () => {
        doWhat();
      });
    }
  }

  private createAnnotation(option: BaseOption) {
    const { type } = option;

    const Ctor = AnnotationComponent[upperFirst(type)];
    if (Ctor) {
      const theme = this.getAnnotationTheme(type);
      const cfg = this.getAnnotationCfg(type, option, theme);
      // ä¸åå»º
      if (!cfg) {
        return null;
      }
      const annotation = new Ctor(cfg);

      return {
        component: annotation,
        layer: this.isTop(cfg) ? LAYER.FORE : LAYER.BG,
        direction: DIRECTION.NONE,
        type: COMPONENT_TYPE.ANNOTATION,
        extra: option,
      };
    }
  }

  // APIs for creating annotation component
  public annotation(option: any) {
    this.option.push(option);
  }

  /**
   * åå»º Arc
   * @param option
   * @returns AnnotationController
   */
  public arc(option: ArcOption) {
    this.annotation({
      type: 'arc',
      ...option,
    });

    return this;
  }

  /**
   * åå»º image
   * @param option
   * @returns AnnotationController
   */
  public image(option: ImageOption) {
    this.annotation({
      type: 'image',
      ...option,
    });

    return this;
  }

  /**
   * åå»º Line
   * @param option
   * @returns AnnotationController
   */
  public line(option: LineOption) {
    this.annotation({
      type: 'line',
      ...option,
    });

    return this;
  }

  /**
   * åå»º Region
   * @param option
   * @returns AnnotationController
   */
  public region(option: RegionOption) {
    this.annotation({
      type: 'region',
      ...option,
    });

    return this;
  }

  /**
   * åå»º Text
   * @param option
   * @returns AnnotationController
   */
  public text(option: TextOption) {
    this.annotation({
      type: 'text',
      ...option,
    });

    return this;
  }

  /**
   * åå»º DataMarker
   * @param option
   * @returns AnnotationController
   */
  public dataMarker(option: DataMarkerOption) {
    this.annotation({
      type: 'dataMarker',
      ...option,
    });

    return this;
  }

  /**
   * åå»º DataRegion
   * @param option
   * @returns AnnotationController
   */
  public dataRegion(option: DataRegionOption) {
    this.annotation({
      type: 'dataRegion',
      ...option,
    });
  }

  /**
   * åå»º RegionFilter
   * @param option
   * @returns AnnotationController
   */
  public regionFilter(option: RegionFilterOption) {
    this.annotation({
      type: 'regionFilter',
      ...option,
    });
  }

  /**
   * åå»º ShapeAnnotation
   * @param option
   */
  public shape(option: ShapeAnnotationOption) {
    this.annotation({
      type: 'shape',
      ...option,
    });
  }

  /**
   * åå»º HtmlAnnotation
   * @param option
   */
  public html(option: HtmlAnnotationOption) {
    this.annotation({
      type: 'html',
      ...option,
    });
  }
  // end API

  /**
   * parse the point position to [x, y]
   * @param p Position
   * @returns { x, y }
   */
  private parsePosition(
    p:
      | [string | number, string | number]
      | Datum
      | ((xScale: Scale, yScale: Scale) => [string | number, string | number] | number | Datum)
  ): Point {
    const xScale = this.view.getXScale();
    // è½¬æ object
    const yScales = this.view.getScalesByDim('y');

    const position: Position = isFunction(p) ? p.call(null, xScale, yScales) : p;

    let x = 0;
    let y = 0;

    // å¥åæ¯ [24, 24] è¿ç±»æ¶
    if (isArray(position)) {
      const [xPos, yPos] = position;
      // å¦ææ°æ®æ ¼å¼æ¯ ['50%', '50%'] çæ ¼å¼
      // fix: åå§æ°æ®ä¸­å¯è½ä¼åå« 'xxx5%xxx' è¿æ ·çæ°æ®ï¼éè¦å¤æ­ä¸ https://github.com/antvis/f2/issues/590
      // @ts-ignore
      if (isString(xPos) && xPos.indexOf('%') !== -1 && !isNaN(xPos.slice(0, -1))) {
        return this.parsePercentPosition(position as [string, string]);
      }

      x = getNormalizedValue(xPos, xScale);
      y = getNormalizedValue(yPos, Object.values(yScales)[0]);
    } else if (!isNil(position)) {
      // å¥åæ¯ object ç»æï¼æ°æ®ç¹
      for (const key of keys(position)) {
        const value = position[key];
        if (key === xScale.field) {
          x = getNormalizedValue(value, xScale);
        }
        if (yScales[key]) {
          y = getNormalizedValue(value, yScales[key]);
        }
      }
    }

    if (isNaN(x) || isNaN(y)) {
      return null;
    }

    return this.view.getCoordinate().convert({ x, y });
  }

  /**
   * parse all the points between start and end
   * @param start
   * @param end
   * @return Point[]
   */
  private getRegionPoints(start: Position | Data, end: Position | Data): Point[] {
    const xScale = this.view.getXScale();
    const yScales = this.view.getScalesByDim('y');
    const yScale = Object.values(yScales)[0];
    const xField = xScale.field;
    const viewData = this.view.getData();
    const startXValue = isArray(start) ? start[0] : start[xField];
    const endXValue = isArray(end) ? end[0] : end[xField];
    const arr = [];

    let startIndex;
    each(viewData, (item, idx) => {
      if (item[xField] === startXValue) {
        startIndex = idx;
      }
      if (idx >= startIndex) {
        const point = this.parsePosition([item[xField], item[yScale.field]]);
        if (point) {
          arr.push(point);
        }
      }
      if (item[xField] === endXValue) {
        return false;
      }
    });

    return arr;
  }

  /**
   * parse percent position
   * @param position
   */
  private parsePercentPosition(position: [string, string]): Point {
    const xPercent = parseFloat(position[0]) / 100;
    const yPercent = parseFloat(position[1]) / 100;
    const coordinate = this.view.getCoordinate();
    const { start, end } = coordinate;

    const topLeft = {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
    };
    const x = coordinate.getWidth() * xPercent + topLeft.x;
    const y = coordinate.getHeight() * yPercent + topLeft.y;
    return { x, y };
  }

  /**
   * get coordinate bbox
   */
  private getCoordinateBBox() {
    const coordinate = this.view.getCoordinate();
    const { start, end } = coordinate;

    const width = coordinate.getWidth();
    const height = coordinate.getHeight();
    const topLeft = {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
    };

    return {
      x: topLeft.x,
      y: topLeft.y,
      minX: topLeft.x,
      minY: topLeft.y,
      maxX: topLeft.x + width,
      maxY: topLeft.y + height,
      width,
      height,
    };
  }

  /**
   * get annotation component config by different type
   * @param type
   * @param option ç¨æ·çéç½®
   * @param theme
   */
  private getAnnotationCfg(type: string, option: any, theme: object): object | null {
    const coordinate = this.view.getCoordinate();
    const canvas = this.view.getCanvas();
    let o = {};

    if (isNil(option)) {
      return null;
    }
    const { start, end, position } = option;;
    const sp = this.parsePosition(start);
    const ep = this.parsePosition(end);
    const textPoint = this.parsePosition(position);
    if (['arc', 'image', 'line', 'region', 'regionFilter'].includes(type) && (!sp || !ep)) {
      return null;
    } else if (['text', 'dataMarker', 'html'].includes(type) && !textPoint) {
      return null;
    }

    if (type === 'arc') {
      const { start, end, ...rest } = option as ArcOption;
      const startAngle = getAngleByPoint(coordinate, sp);
      let endAngle = getAngleByPoint(coordinate, ep);
      if (startAngle > endAngle) {
        endAngle = Math.PI * 2 + endAngle;
      }

      o = {
        ...rest,
        center: coordinate.getCenter(),
        radius: getDistanceToCenter(coordinate, sp),
        startAngle,
        endAngle,
      };
    } else if (type === 'image') {
      const { start, end, ...rest } = option as ImageOption;
      o = {
        ...rest,
        start: sp,
        end: ep,
        src: option.src,
      };
    } else if (type === 'line') {
      const { start, end, ...rest } = option as LineOption;
      o = {
        ...rest,
        start: sp,
        end: ep,
        text: get(option, 'text', null),
      };
    } else if (type === 'region') {
      const { start, end, ...rest } = option as RegionPositionBaseOption;
      o = {
        ...rest,
        start: sp,
        end: ep,
      };
    } else if (type === 'text') {
      const filteredData = this.view.getData();
      const { position, content, ...rest } = option as TextOption;
      let textContent = content;
      if (isFunction(content)) {
        textContent = content(filteredData);
      }
      o = {
        ...textPoint,
        ...rest,
        content: textContent,
      };
    } else if (type === 'dataMarker') {
      const { position, point, line, text, autoAdjust, direction, ...rest } = option as DataMarkerOption;
      o = {
        ...rest,
        ...textPoint,
        coordinateBBox: this.getCoordinateBBox(),
        point,
        line,
        text,
        autoAdjust,
        direction,
      };
    } else if (type === 'dataRegion') {
      const { start, end, region, text, lineLength, ...rest } = option as DataRegionOption;
      o = {
        ...rest,
        points: this.getRegionPoints(start, end),
        region,
        text,
        lineLength,
      };
    } else if (type === 'regionFilter') {
      const { start, end, apply, color, ...rest } = option as RegionFilterOption;
      const geometries: Geometry[] = this.view.geometries;
      const shapes = [];
      const addShapes = (item?: IElement) => {
        if (!item) {
          return;
        }
        if (item.isGroup()) {
          (item as IGroup).getChildren().forEach((child) => addShapes(child));
        } else {
          shapes.push(item);
        }
      };
      each(geometries, (geom: Geometry) => {
        if (apply) {
          if (contains(apply, geom.type)) {
            each(geom.elements, (elem: Element) => {
              addShapes(elem.shape);
            });
          }
        } else {
          each(geom.elements, (elem: Element) => {
            addShapes(elem.shape);
          });
        }
      });
      o = {
        ...rest,
        color,
        shapes,
        start: sp,
        end: ep,
      };
    } else if (type === 'shape') {
      const { render, ...restOptions } = option as ShapeAnnotationOption;
      const wrappedRender = (container: IGroup) => {
        if (isFunction(option.render)) {
          return render(container, this.view, { parsePosition: this.parsePosition.bind(this) });
        }
      };
      o = {
        ...restOptions,
        render: wrappedRender,
      };
    } else if (type === 'html') {
      const { html, position, ...restOptions } = option as HtmlAnnotationOption;
      const wrappedHtml = (container: HTMLElement) => {
        if (isFunction(html)) {
          return html(container, this.view);
        }
        return html;
      };
      o = {
        ...restOptions,
        ...textPoint,
        // html ç»ä»¶éè¦æå® parent
        parent: canvas.get('el').parentNode,
        html: wrappedHtml,
      };
    }
    // åå¹¶ä¸»é¢ï¼ç¨æ·éç½®ä¼åçº§é«äºé»è®¤ä¸»é¢
    const cfg = deepMix({}, theme, {
      ...o,
      top: option.top,
      style: option.style,
      offsetX: option.offsetX,
      offsetY: option.offsetY,
    });
    if (type !== 'html') {
      // html ç±»åä¸ä½¿ç¨ G container
      cfg.container = this.getComponentContainer(cfg);
    }
    cfg.animate = this.view.getOptions().animate && cfg.animate && get(option, 'animate', cfg.animate); // å¦æ view å³é­å¨ç»ï¼åä¸æ§è¡
    cfg.animateOption = deepMix({}, DEFAULT_ANIMATE_CFG, cfg.animateOption, option.animateOption);

    return cfg;
  }

  /**
   * is annotation render on top
   * @param option
   * @return whethe on top
   */
  private isTop(option: any): boolean {
    return get(option, 'top', true);
  }

  /**
   * get the container by option.top
   * default is on top
   * @param option
   * @returns the container
   */
  private getComponentContainer(option: any) {
    return this.isTop(option) ? this.foregroundContainer : this.backgroundContainer;
  }

  private getAnnotationTheme(type: string) {
    return get(this.view.getTheme(), ['components', 'annotation', type], {});
  }

  /**
   * åå»ºæèæ´æ° annotation
   * @param option
   */
  private updateOrCreate(option: BaseOption) {
    // æ¿å°ç¼å­çåå®¹
    let co = this.cache.get(this.getCacheKey(option));

    // å­å¨åæ´æ°ï¼ä¸å­å¨å¨åå»º
    if (co) {
      const { type } = option;
      const theme = this.getAnnotationTheme(type);
      const cfg = this.getAnnotationCfg(type, option, theme);

      // å¿½ç¥æä¸äºéç½®
      if (cfg) {
        omit(cfg, ['container']);
      }
      co.component.update({ ...(cfg || {}), visible: !!cfg });
      // å¯¹äº regionFilter/shapeï¼å ä¸ºçå½å¨æçåå ï¼éè¦é¢å¤ render
      if (includes(ANNOTATIONS_AFTER_RENDER, option.type)) {
        co.component.render();
      }
    } else {
      // ä¸å­å¨ï¼åå»º
      co = this.createAnnotation(option);
      if (co) {
        co.component.init();
        // Noteï¼regionFilter/shape ç¹æ®å¤çï¼regionFilter/shape éè¦åå° Geometry ä¸­ç Shapeï¼éè¦å¨ view render ä¹åå¤ç
        // å¶ä»ç»ä»¶ä½¿ç¨å¤å±çç»ä¸ render é»è¾
        if (includes(ANNOTATIONS_AFTER_RENDER, option.type)) {
          co.component.render();
        }
      }
    }
    return co;
  }

  /**
   * æ´æ°ç¼å­ï¼ä»¥åéæ¯ç»ä»¶
   * @param updated æ´æ°æèåå»ºçç»ä»¶
   */
  private syncCache(updated: Map<BaseOption, ComponentOption>) {
    const newCache = new Map(this.cache); // clone ä¸ä»½

    // å° update æ´æ°å° cache
    updated.forEach((co: ComponentOption, key: BaseOption) => {
      newCache.set(key, co);
    });

    // å¦å¤å options è¿è¡å¯¹æ¯ï¼å é¤
    newCache.forEach((co: ComponentOption, key: BaseOption) => {
      // option ä¸­å·²ç»æ¾ä¸å°ï¼é£ä¹å°±æ¯å é¤ç
      if (
        !find(this.option, (option: BaseOption) => {
          return key === this.getCacheKey(option);
        })
      ) {
        co.component.destroy();
        newCache.delete(key);
      }
    });

    return newCache;
  }

  /**
   * è·å¾ç¼å­ç»ä»¶ç key
   * @param option
   */
  private getCacheKey(option: BaseOption) {
    // å¦æå­å¨ idï¼åä½¿ç¨ id stringï¼å¦åç´æ¥ä½¿ç¨ option å¼ç¨ä½ä¸º key
    return option;
    // åç»­æ©å± id ç¨
    // const id = get(option, 'id');
    // return id ? id : option;
  }
}
