import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { WaterfallOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { transformData } from './utils';

export type { WaterfallOptions };

/**
 * çå¸å¾
 */
export class Waterfall extends Plot<WaterfallOptions> {
  /**
   * è·å çå¸å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<WaterfallOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public readonly type: string = 'waterfall';

  /**
   * @override
   * @param data
   */
  public changeData(data) {
    const { xField, yField, total } = this.options;
    this.updateOption({ data });
    this.chart.changeData(transformData(data, xField, yField, total));
  }

  /**
   * è·å çå¸å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<WaterfallOptions> {
    return adaptor;
  }

  /**
   * è·å çå¸å¾ çé»è®¤éç½®
   */
  protected getDefaultOptions(): Partial<WaterfallOptions> {
    return Waterfall.getDefaultOptions();
  }
}
