import { BulletOptions } from './types';

type TransformData = {
  min: number;
  max: number;
  ds: any[];
};
/**
 * bullet å¤çæ°æ®
 * @param options
 */
export function transformData(options: BulletOptions): TransformData {
  const { data, xField, measureField, rangeField, targetField, layout } = options;
  const ds: any[] = [];
  const scales: number[] = [];
  data.forEach((item: any, index: number) => {
    // æå»º title * range
    item[rangeField].sort((a: number, b: number) => a - b);
    item[rangeField].forEach((d: number, i: number) => {
      const range = i === 0 ? d : item[rangeField][i] - item[rangeField][i - 1];
      ds.push({
        rKey: `${rangeField}_${i}`,
        [xField]: xField ? item[xField] : String(index), // æ²¡æxFieldå°±ç¨ç´¢å¼
        [rangeField]: range,
      });
    });
    // æå»º title * measure
    item[measureField].forEach((d: number, i: number) => {
      ds.push({
        mKey: item[measureField].length > 1 ? `${measureField}_${i}` : `${measureField}`, // ä¸ä¸ªæ°æ®å°±ä¸å¸¦ç´¢å¼äº
        [xField]: xField ? item[xField] : String(index),
        [measureField]: d,
      });
    });
    // æå»º title * target
    ds.push({
      tKey: `${targetField}`,
      [xField]: xField ? item[xField] : String(index),
      [targetField]: item[targetField],
    });
    // ä¸ºäºåæå¤§å¼åæå°å¼ï¼åå­å¨
    scales.push(item[rangeField], item[measureField], item[targetField]);
  });
  // scales æ¯åµå¥çéè¦æå¹³
  let min = Math.min(...scales.flat(Infinity));
  const max = Math.max(...scales.flat(Infinity));
  // min å¤§äº 0 ä» 0 å¼å§
  min = min > 0 ? 0 : min;

  // åç´æåµï¼éè¦åè½¬æ°æ®
  if (layout === 'vertical') {
    ds.reverse();
  }
  return { min, max, ds };
}
