import { isArray } from '@antv/util';
import { Datum } from '../interface';
import Geometry from './base';
/** å¼å¥ Path å¯¹åºç ShapeFactory */
import './shape/polygon';

/**
 * Polygon å ä½æ è®°ã
 * å¸¸ç¨äºç»å¶è²åå¾ãæ¥åå¾ç­ã
 */
export default class Polygon extends Geometry {
  public readonly type: string = 'polygon';
  public readonly shapeType: string = 'polygon';
  protected generatePoints: boolean = true;

  /**
   * è·å Shape çå³é®ç¹æ°æ®ã
   * @param obj
   * @returns
   */
  protected createShapePointsCfg(obj: Datum) {
    const cfg: any = super.createShapePointsCfg(obj);
    let x = cfg.x;
    let y = cfg.y;
    let temp;
    // x y é½æ¯æ°ç»æ¶ï¼ä¸åå¤ç
    if (!(isArray(x) && isArray(y))) {
      const xScale = this.getXScale();
      const yScale = this.getYScale();
      const xCount = xScale.values.length;
      const yCount = yScale.values.length;
      const xOffset = (0.5 * 1) / xCount;
      const yOffset = (0.5 * 1) / yCount;

      if (xScale.isCategory && yScale.isCategory) {
        // å¦æx,yé½æ¯åç±»
        x = [x - xOffset, x - xOffset, x + xOffset, x + xOffset];
        y = [y - yOffset, y + yOffset, y + yOffset, y - yOffset];
      } else if (isArray(x)) {
        // x æ¯æ°ç»
        temp = x;
        x = [temp[0], temp[0], temp[1], temp[1]];
        y = [y - yOffset / 2, y + yOffset / 2, y + yOffset / 2, y - yOffset / 2];
      } else if (isArray(y)) {
        // y æ¯æ°ç»
        temp = y;
        y = [temp[0], temp[1], temp[1], temp[0]];
        x = [x - xOffset / 2, x - xOffset / 2, x + xOffset / 2, x + xOffset / 2];
      }
      cfg.x = x;
      cfg.y = y;
    }
    return cfg;
  }
}
