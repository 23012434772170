import { View } from '@antv/g2';
import { size, valuesOfKey } from '@antv/util';
import { isBetween } from '../../../utils';

/**
 * å³ä¾§ View è¿è¡ slider è¿æ»¤
 * ç±äºåè½´å¾æ¯å¤ View , éè¦çå¬å·¦ä¾§ Slider ç change äºä»¶æ¥åæ­¥å³ä¾§ View
 * @param { View } view å³ä¾§è§å¾
 * @param { number[] } sliderValue æ»åå½åå¼
 * @returns void
 */
export const doSliderFilter = (view: View, sliderValue: [number, number]) => {
  const [min, max] = sliderValue;
  const data = view.getOptions().data;
  const xScale = view.getXScale();
  const dataSize = size(data);
  if (!xScale || !dataSize) {
    return;
  }
  const isHorizontal = true;
  const values = valuesOfKey(data, xScale.field);
  const xValues = isHorizontal ? values : values.reverse();
  const xTickCount = size(xValues);
  const minIndex = Math.floor(min * (xTickCount - 1));
  const maxIndex = Math.floor(max * (xTickCount - 1));

  // å¢å  x è½´çè¿æ»¤å¨
  view.filter(xScale.field, (value: any) => {
    const idx: number = xValues.indexOf(value);
    return idx > -1 ? isBetween(idx, minIndex, maxIndex) : true;
  });
  view.getRootView().render(true);
};
