import { each } from '@antv/util';
import { Geometry } from '@antv/g2';
import { geometry as geometryAdaptor } from '../../adaptor/geometries/base';
import { interaction, animation, theme, tooltip } from '../../adaptor/common';
import { Params } from '../../core/adaptor';
import { PLOT_CONTAINER_OPTIONS } from '../../core/plot';
import { AXIS_META_CONFIG_KEYS } from '../../constant';
import { deepAssign, flow, pick } from '../../utils';
import { Axis } from '../../types/axis';
import { Legend } from '../../types/legend';
import { Interaction } from '../../types/interaction';
import { MixOptions, IView, IGeometry } from './types';
import { execPlotAdaptor } from './utils';

/**
 * geometry å¤ç
 * @param params
 */
function multiView(params: Params<MixOptions>): Params<MixOptions> {
  const { chart, options } = params;
  const { views, legend } = options;

  each(views, (v: IView) => {
    const { region, data, meta, axes, coordinate, interactions, annotations, tooltip, geometries } = v;

    // 1. åå»º view
    const viewOfG2 = chart.createView({
      region,
    });

    // 2. data
    viewOfG2.data(data);

    // 3. meta
    let scales: Record<string, any> = {};
    if (axes) {
      each(axes, (axis: Axis, field: string) => {
        scales[field] = pick(axis, AXIS_META_CONFIG_KEYS);
      });
    }

    scales = deepAssign({}, meta, scales);
    viewOfG2.scale(scales);

    // 4. x y axis
    if (!axes) {
      viewOfG2.axis(false);
    } else {
      each(axes, (axis: Axis, field: string) => {
        viewOfG2.axis(field, axis);
      });
    }

    // 5. coordinate
    viewOfG2.coordinate(coordinate);

    // 6. geometry
    each(geometries, (geometry: IGeometry) => {
      const { ext } = geometryAdaptor({
        chart: viewOfG2,
        options: geometry,
      });

      // adjust
      const { adjust } = geometry;
      if (adjust) {
        ext.geometry.adjust(adjust);
      }
    });

    // 7. interactions
    each(interactions, (interaction: Interaction) => {
      if (interaction.enable === false) {
        viewOfG2.removeInteraction(interaction.type);
      } else {
        viewOfG2.interaction(interaction.type, interaction.cfg);
      }
    });

    // 8. annotations
    each(annotations, (annotation) => {
      viewOfG2.annotation()[annotation.type]({
        ...annotation,
      });
    });

    // 9. animation (ååå¨ç»)
    if (typeof v.animation === 'boolean') {
      viewOfG2.animate(false);
    } else {
      viewOfG2.animate(true);
      // 9.1 ææç Geometry é½ä½¿ç¨åä¸å¨ç»ï¼åä¸ªå¾å½¢å¦æåºå«ï¼todo èªè¡è¦çï¼
      each(viewOfG2.geometries, (g: Geometry) => {
        g.animate(v.animation);
      });
    }

    if (tooltip) {
      // 10. tooltip
      viewOfG2.interaction('tooltip');
      viewOfG2.tooltip(tooltip);
    }
  });

  // legend
  if (!legend) {
    chart.legend(false);
  } else {
    each(legend, (l: Legend, field: string) => {
      chart.legend(field, l);
    });
  }

  // tooltip
  chart.tooltip(options.tooltip);
  return params;
}

/**
 * æ¯æåµå¥ä½¿ç¨ g2plot åç½®å¾è¡¨
 * @param params
 */
function multiPlot(params: Params<MixOptions>): Params<MixOptions> {
  const { chart, options } = params;
  const { plots } = options;

  each(plots, (plot) => {
    const { type, region, options = {} } = plot;
    const { tooltip } = options;

    const viewOfG2 = chart.createView({ region, ...pick(options, PLOT_CONTAINER_OPTIONS) });
    if (tooltip) {
      // éç½® tooltip äº¤äº
      viewOfG2.interaction('tooltip');
    }

    execPlotAdaptor(type, viewOfG2, options);
  });

  return params;
}

/**
 * å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<MixOptions>) {
  return flow(
    animation, // å¤ view çå¾ï¼å¨ç»éç½®æ¾å°æåé¢
    multiView,
    multiPlot,
    interaction,
    animation,
    theme,
    tooltip
    // ... å¶ä»ç adaptor flow
  )(params);
}
