import { Chart, Event, Element } from '@antv/g2';
import { each } from '@antv/util';
import EE from '@antv/event-emitter';
import { bind } from 'size-sensor';
import { Options, StateName, StateCondition, Size, StateObject, Annotation } from '../types';
import { getContainerSize, getAllElementsRecursively, deepAssign, pick } from '../utils';
import { Adaptor } from './adaptor';

/** åç¬ pick åºæ¥çç¨äºåºç±»çç±»åå®ä¹ */
export type PickOptions = Pick<
  Options,
  | 'width'
  | 'height'
  | 'padding'
  | 'appendPadding'
  | 'renderer'
  | 'pixelRatio'
  | 'autoFit'
  | 'syncViewPadding'
  | 'supportCSSTransform'
  | 'limitInPlot'
  | 'locale'
  | 'defaultInteractions'
>;

const SOURCE_ATTRIBUTE_NAME = 'data-chart-source-type';

/** plot å¾è¡¨å®¹å¨çéç½® */
export const PLOT_CONTAINER_OPTIONS = [
  'padding',
  'appendPadding',
  'renderer',
  'pixelRatio',
  'syncViewPadding',
  'supportCSSTransform',
  'limitInPlot',
];

/**
 * ææ plot çåºç±»
 */
export abstract class Plot<O extends PickOptions> extends EE {
  /**
   * è·åé»è®¤ç options éç½®é¡¹
   * æ¯ä¸ªç»ä»¶é½å¯ä»¥å¤å
   */
  static getDefaultOptions(): any {
    return {
      renderer: 'canvas',
      xAxis: {
        nice: true,
        label: {
          autoRotate: false,
          autoHide: { type: 'equidistance', cfg: { minGap: 6 } },
        },
      },
      yAxis: {
        nice: true,
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      animation: true,
    };
  }

  /** plot ç±»ååç§° */
  public abstract readonly type: string;
  /** plot ç schema éç½® */
  public options: O;
  /** plot ç»å¶ç dom */
  public readonly container: HTMLElement;
  /** G2 chart å®ä¾ */
  public chart: Chart;
  /** resizer unbind  */
  private unbind: () => void;

  constructor(container: string | HTMLElement, options: O) {
    super();
    this.container = typeof container === 'string' ? document.getElementById(container) : container;

    this.options = deepAssign({}, this.getDefaultOptions(), options);

    this.createG2();

    this.bindEvents();
  }

  /**
   * åå»º G2 å®ä¾
   */
  private createG2() {
    const { width, height, defaultInteractions } = this.options;

    this.chart = new Chart({
      container: this.container,
      autoFit: false, // G2Plot ä½¿ç¨ size-sensor è¿è¡ autoFit
      ...this.getChartSize(width, height),
      localRefresh: false, // é»è®¤å³é­ï¼ç®å G è¿æä¸äºä½ç½®é®é¢ï¼é¾ä»¥ææ¥ï¼
      ...pick(this.options, PLOT_CONTAINER_OPTIONS),
      defaultInteractions,
    });

    // ç»å®¹å¨å¢å æ è¯ï¼ç¥éå¾è¡¨çæ¥æºåºå«äº G2
    this.container.setAttribute(SOURCE_ATTRIBUTE_NAME, 'G2Plot');
  }

  /**
   * è®¡ç®é»è®¤ç chart å¤§å°ãé»è¾ç®åï¼å¦æå­å¨ width æ heightï¼åç´æ¥ä½¿ç¨ï¼å¦åä½¿ç¨å®¹å¨å¤§å°
   * @param width
   * @param height
   */
  private getChartSize(width: number, height: number): Size {
    const chartSize = getContainerSize(this.container);
    return { width: width || chartSize.width || 400, height: height || chartSize.height || 400 };
  }

  /**
   * ç»å®ä»£çææ G2 çäºä»¶
   */
  private bindEvents() {
    if (this.chart) {
      this.chart.on('*', (e: Event) => {
        if (e?.type) {
          this.emit(e.type, e);
        }
      });
    }
  }

  /**
   * è·åé»è®¤ç options éç½®é¡¹
   * æ¯ä¸ªç»ä»¶é½å¯ä»¥å¤å
   */
  protected getDefaultOptions(): any {
    return Plot.getDefaultOptions();
  }

  /**
   * æ¯ä¸ªç»ä»¶æèªå·±ç schema adaptor
   */
  protected abstract getSchemaAdaptor(): Adaptor<O>;

  /**
   * ç»å¶
   */
  public render() {
    // æ´åå¤çï¼åæ¸ç©ºåæ¸²æï¼éè¦ G2 å±èªè¡åå¥½æ´æ°æ¸²æ
    this.chart.clear();
    // å ä¸ºå­ view ä¼ç»§æ¿ç¶ view ç options éç½®ï¼åæ¬ legendï¼æä»¥ä¼å¯¼è´ legend éå¤åå»ºï¼
    // æä»¥è¿éç» chart å®ä¾ç options éç½®æ¸ç©º
    // æå¥½çè§£æ³æ¯å¨ G2 view.clear æ¹æ³çæ¶åï¼éç½® options éç½®ãæèæä¾æ¹æ³å» resetOptions
    // #1684 çè®ºä¸å¨å¤ view å¾å½¢ä¸ï¼åªè¦å­å¨ custom legendï¼é½å­å¨ç±»ä¼¼é®é¢ï¼å­å¼¹å¾ãåè½´å¾ï¼
    // @ts-ignore
    this.chart.options = {
      data: [],
      animate: true,
    };
    this.chart.views = []; // å é¤å·²æç views
    // æ§è¡ adaptor
    this.execAdaptor();
    // æ¸²æ
    this.chart.render();
    // ç»å®
    this.bindSizeSensor();
  }

  /**
   * æ´æ°: æ´æ°éç½®ä¸éæ°æ¸²æ
   * @param options
   */
  public update(options: Partial<O>) {
    this.updateOption(options);
    this.render();
  }

  /**
   * æ´æ°éç½®
   * @param options
   */
  protected updateOption(options: Partial<O>) {
    this.options = deepAssign({}, this.options, options);
  }

  /**
   * è®¾ç½®ç¶æ
   * @param type ç¶æç±»åï¼æ¯æ 'active' | 'inactive' | 'selected' ä¸ç§
   * @param conditions æ¡ä»¶ï¼æ¯ææ°ç»
   * @param status æ¯å¦æ¿æ´»ï¼é»è®¤ true
   */
  public setState(type: StateName, condition: StateCondition, status: boolean = true) {
    const elements = getAllElementsRecursively(this.chart);

    each(elements, (ele: Element) => {
      if (condition(ele.getData())) {
        ele.setState(type, status);
      }
    });
  }

  /**
   * è·åç¶æ
   */
  public getStates(): StateObject[] {
    const elements = getAllElementsRecursively(this.chart);

    const stateObjects: StateObject[] = [];
    each(elements, (element: Element) => {
      const data = element.getData();
      const states = element.getStates();
      each(states, (state) => {
        stateObjects.push({ data, state, geometry: element.geometry, element });
      });
    });

    return stateObjects;
  }

  /**
   * æ´æ°æ°æ®
   * @override
   * @param options
   */
  public changeData(data: any) {
    // @ts-ignore
    this.update({ data });
    // TODO: ä¸´æ¶æ¹æ¡ï¼æå¥½ä½¿ç¨ä¸é¢çæ¹å¼å»æ´æ°æ°æ®
    // this.chart.changeData(data);
  }

  /**
   * ä¿®æ¹ç»å¸å¤§å°
   * @param width
   * @param height
   */
  public changeSize(width: number, height: number) {
    this.chart.changeSize(width, height);
  }

  /**
   * å¢å å¾è¡¨æ æ³¨ãéè¿ id æ è¯ï¼å¦æå¹éå°ï¼å°±åæ´æ°
   */
  public addAnnotations(annotations: Annotation[]): void {
    const incoming = [...annotations];
    const controller = this.chart.getController('annotation');
    const current = controller.getComponents().map((co) => co.extra);

    controller.clear(true);
    for (let i = 0; i < current.length; i++) {
      let annotation = current[i];

      const findIndex = incoming.findIndex((item) => item.id && item.id === annotation.id);
      if (findIndex !== -1) {
        annotation = deepAssign({}, annotation, incoming[findIndex]);
        incoming.splice(findIndex, 1);
      }
      controller.annotation(annotation);
    }

    incoming.forEach((annotation) => controller.annotation(annotation));
    this.chart.render(true);
  }

  /**
   * å é¤å¾è¡¨æ æ³¨ãéè¿ id æ è¯ï¼å¦æå¹éå°ï¼å°±åå é¤
   */
  public removeAnnotations(annotations: Array<{ id: string } & Partial<Annotation>>): void {
    const controller = this.chart.getController('annotation');
    const current = controller.getComponents().map((co) => co.extra);

    controller.clear(true);
    for (let i = 0; i < current.length; i++) {
      const annotation = current[i];

      if (!annotations.find((item) => item.id && item.id === annotation.id)) {
        controller.annotation(annotation);
      }
    }

    this.chart.render(true);
  }
  /**
   * éæ¯
   */
  public destroy() {
    // åæ¶ size-sensor çç»å®
    this.unbindSizeSensor();
    // G2 çéæ¯
    this.chart.destroy();
    // æ¸ç©ºå·²ç»ç»å®çäºä»¶
    this.off();

    this.container.removeAttribute(SOURCE_ATTRIBUTE_NAME);
  }

  /**
   * æ§è¡ adaptor æä½
   */
  protected execAdaptor() {
    const adaptor = this.getSchemaAdaptor();

    const { padding, appendPadding } = this.options;
    // æ´æ° padding
    this.chart.padding = padding;
    // æ´æ° appendPadding
    this.chart.appendPadding = appendPadding;

    // è½¬åæ G2 API
    adaptor({
      chart: this.chart,
      options: this.options,
    });
  }

  /**
   * å½å¾è¡¨å®¹å¨å¤§å°ååçæ¶åï¼æ§è¡çå½æ°
   */
  protected triggerResize() {
    this.chart.forceFit();
  }

  /**
   * ç»å® dom å®¹å¨å¤§å°ååçäºä»¶
   */
  private bindSizeSensor() {
    if (this.unbind) {
      return;
    }

    const { autoFit = true } = this.options;
    if (autoFit) {
      this.unbind = bind(this.container, () => {
        // è·åææ°çå®½é«ä¿¡æ¯
        const { width, height } = getContainerSize(this.container);

        // ä¸»è¦æ¯é²æ­¢ç»å®çæ¶åè§¦å resize åè°
        if (width !== this.chart.width || height !== this.chart.height) {
          this.triggerResize();
        }
      });
    }
  }

  /**
   * åæ¶ç»å®
   */
  private unbindSizeSensor() {
    if (this.unbind) {
      this.unbind();
      this.unbind = undefined;
    }
  }
}
