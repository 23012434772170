/**
 * åé¢å¾ é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = {
  title: {
    style: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.65)',
    },
  },
  rowTitle: {
    style: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.65)',
    },
  },
  columnTitle: {
    style: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.65)',
    },
  },
};
