import { each } from '@antv/util';
import { ListItem } from '../../../dependents';
import Element from '../../../geometry/element/';
import { getElements, getElementsByState } from '../util';
import { clearHighlight } from './highlight-util';
import StateAction from './state';

import { ELEMENT_STATE } from '../../../constant';

export const STATUS_UNACTIVE = ELEMENT_STATE.INACTIVE;
export const STATUS_ACTIVE = ELEMENT_STATE.ACTIVE;
export type Callback = (el) => boolean;

/**
 * @ignore
 * highlightï¼æå®å¾å½¢é«äº®ï¼å¶ä»å¾å½¢åæ
 */
class ElementHighlight extends StateAction {
  protected stateName: string = STATUS_ACTIVE;

  // å¤ä¸ªåç´ è®¾ç½®ãåæ¶ highlight
  protected setElementsStateByItem(elements: Element[], field: string, item: ListItem, enable: boolean) {
    const callback = (el) => this.isMathItem(el, field, item);
    this.setHighlightBy(elements, callback, enable);
  }

  // è®¾ç½®åç´ ç highlight
  protected setElementHighlight(el: Element, callback: Callback) {
    if (callback(el)) {
      if (el.hasState(STATUS_UNACTIVE)) {
        el.setState(STATUS_UNACTIVE, false);
      }
      el.setState(STATUS_ACTIVE, true);
    } else if (!el.hasState(STATUS_ACTIVE)) {
      el.setState(STATUS_UNACTIVE, true);
    }
  }

  protected setHighlightBy(elements: Element[], callback: Callback, enable: boolean) {
    if (enable) {
      // å¦ææ¯è®¾ç½® highlight ï¼åå°å¹éç element è®¾ç½®æ activeï¼
      // å¶ä»å¦æä¸æ¯ activeï¼åè®¾ç½®æ unactive
      each(elements, (el) => {
        this.setElementHighlight(el, callback);
      });
    } else {
      // å¦æåæ¶ highlightï¼åè¦æ£æµæ¯å¦å¨é¨åæ¶ highlight
      const activeElements = getElementsByState(this.context.view, STATUS_ACTIVE);
      let allCancel = true;
      // æ£æµææ activeElements é½è¦åæ¶ highlight
      each(activeElements, (el) => {
        if (!callback(el)) {
          allCancel = false;
          return false;
        }
      });
      if (allCancel) {
        // é½è¦åæ¶ï¼ååæ¶ææç activeï¼unactive ç¶æ
        this.clear();
      } else {
        // å¦æä¸æ¯é½è¦åæ¶ highlight, åè®¾ç½®å¹éç element çç¶æä¸º unactive
        // å¶ä» element ç¶æä¸å
        each(elements, (el) => {
          if (callback(el)) {
            if (el.hasState(STATUS_ACTIVE)) {
              el.setState(STATUS_ACTIVE, false);
            }
            el.setState(STATUS_UNACTIVE, true);
          }
        });
      }
    }
  }

  // åä¸ªåç´ è®¾ç½®ååæ¶ highlight
  protected setElementState(element: Element, enable: boolean) {
    const view = this.context.view;
    const elements = getElements(view);
    this.setHighlightBy(elements, (el) => element === el, enable);
  }

  public highlight() {
    this.setState();
  }

  // æ¸çæææç activeï¼ unactive ç¶æ
  public clear() {
    const view = this.context.view;
    clearHighlight(view);
  }
}

export default ElementHighlight;
