import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { AnimateExtraCfg } from '../interface';

/** @ignore */
interface AnimationMap {
  [key: string]: Animation;
}

type Animation = (element: IGroup | IShape, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) => void;

const ANIMATIONS_MAP: AnimationMap = {};

/**
 * æ ¹æ®åç§°è·åå¯¹åºçå¨ç»æ§è¡å½æ°
 * @param type å¨ç»å½æ°åç§°
 */
export function getAnimation(type: string) {
  return ANIMATIONS_MAP[type.toLowerCase()];
}

/**
 * æ³¨åå¨ç»æ§è¡å½æ°
 * @param type å¨ç»æ§è¡å½æ°åç§°
 * @param animation å¨ç»æ§è¡å½æ°
 */
export function registerAnimation(type: string, animation: Animation) {
  ANIMATIONS_MAP[type.toLowerCase()] = animation;
}
