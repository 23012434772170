import { DEFAULT_TOOLTIP_OPTIONS } from '../tiny-line/constants';

/**
 * é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = {
  appendPadding: 2,
  tooltip: {
    ...DEFAULT_TOOLTIP_OPTIONS,
  },
  // é»è®¤æ ·å¼
  color: 'l(90) 0:#E5EDFE 1:#ffffff',
  areaStyle: {
    fillOpacity: 0.6,
  },
  line: {
    size: 1,
    color: '#5B8FF9',
  },
  animation: {},
};
