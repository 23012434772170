import { deepMix, each, get, isArray, isEmpty, isEqual, isFunction, isString } from '@antv/util';
// ææªåå
// @ts-ignore
import { propagationDelegate } from '@antv/component';
import { doAnimate } from '../../animate';
import Base from '../../base';
import { BBox, IGroup, IShape } from '../../dependents';
import { AnimateOption, Datum, ShapeFactory, ShapeInfo, StateCfg } from '../../interface';
import { getReplaceAttrs } from '../../util/graphics';
import Geometry from '../base';
import { GEOMETRY_LIFE_CIRCLE } from '../../constant';
import { BACKGROUND_SHAPE } from '../shape/constant';

/** Element æé å½æ°ä¼ å¥åæ°ç±»å */
interface ElementCfg {
  /** ç¨äºåå»ºåç§ shape çå·¥åå¯¹è±¡ */
  shapeFactory: ShapeFactory;
  /** shape å®¹å¨ */
  container: IGroup;
  /** element çç´¢å¼ */
  elementIndex?: number;
  /** èæ groupï¼ç¨æ·å¯ä»¥ä¸ä¼ å¥ */
  offscreenGroup?: IGroup;
  /** æ¯å¦å¯è§ */
  visible?: boolean;
}

/**
 * Element å¾å½¢åç´ ã
 * å®ä¹ï¼å¨ G2 ä¸­ï¼æä»¬ä¼å°æ°æ®éè¿å¾å½¢è¯­æ³æ å°æä¸åçå¾å½¢ï¼æ¯å¦ç¹å¾ï¼æ°æ®éä¸­çæ¯æ¡æ°æ®ä¼å¯¹åºä¸ä¸ªç¹ï¼æ±ç¶å¾æ¯æ¡æ°æ®å¯¹åºä¸ä¸ªæ±å­ï¼çº¿å¾åæ¯ä¸ç»æ°æ®å¯¹åºä¸æ¡æçº¿ï¼Element å³ä¸æ¡/ä¸ç»æ°æ®å¯¹åºçå¾å½¢åç´ ï¼å®ä»£è¡¨ä¸æ¡æ°æ®æèä¸ä¸ªæ°æ®éï¼å¨å¾å½¢å±é¢ï¼å®å¯ä»¥æ¯åä¸ª Shape ä¹å¯ä»¥æ¯å¤ä¸ª Shapeï¼æä»¬ç§°ä¹ä¸ºå¾å½¢åç´ ã
 */
export default class Element extends Base {
  /** ç¨äºåå»ºåç§ shape çå·¥åå¯¹è±¡ */
  public shapeFactory: ShapeFactory;
  /** shape å®¹å¨ */
  public container: IGroup;
  /** element ç´¢å¼ */
  public elementIndex: number;
  /** æååå»ºçå¾å½¢å¯¹è±¡ */
  public shape: IShape | IGroup;
  /** shape çå¨ç»éç½® */
  public animate: AnimateOption | boolean;

  // éæé å½æ°å±æ§ï¼éè¦å¤é¨èµå¼
  /** element å¯¹åºç Geometry å®ä¾ */
  public geometry: Geometry;
  /** ä¿å­ shape å¯¹åºç label */
  public labelShape: IGroup[];

  /** ç»å¶ç shape ç±»å */
  private shapeType: string;

  /** shape ç»å¶éè¦çæ°æ® */
  private model: ShapeInfo;
  /** åå§æ°æ® */
  private data: Datum;
  // å­å¨å½åå¼å¯çç¶æ
  private states: string[] = [];
  private statesStyle;
  // èæ Group
  private offscreenGroup: IGroup;

  constructor(cfg: ElementCfg) {
    super(cfg);

    const { shapeFactory, container, offscreenGroup, elementIndex, visible = true } = cfg;
    this.shapeFactory = shapeFactory;
    this.container = container;
    this.offscreenGroup = offscreenGroup;
    this.visible = visible;
    this.elementIndex = elementIndex;
  }

  /**
   * ç»å¶å¾å½¢ã
   * @param model ç»å¶æ°æ®ã
   * @param isUpdate å¯éï¼æ¯å¦æ¯æ´æ°åçåçç»å¶ã
   */
  public draw(model: ShapeInfo, isUpdate: boolean = false) {
    this.model = model;
    this.data = model.data; // å­å¨åå§æ°æ®
    this.shapeType = this.getShapeType(model);

    // ç»å¶å¾å½¢
    this.drawShape(model, isUpdate);

    if (this.visible === false) {
      // ç¨æ·å¨åå§åçæ¶åå£°æ visible: false
      this.changeVisible(false);
    }
  }

  /**
   * æ´æ°å¾å½¢ã
   * @param model æ´æ°çç»å¶æ°æ®ã
   */
  public update(model: ShapeInfo) {
    const { shapeFactory, shape } = this;
    if (!shape) {
      return;
    }

    // æ´æ°æ°æ®
    this.model = model;
    this.data = model.data;
    this.shapeType = this.getShapeType(model);

    // step 1: æ´æ° shape æºå¸¦çä¿¡æ¯
    this.setShapeInfo(shape, model);

    // step 2: ä½¿ç¨èæ Group éæ°ç»å¶ shapeï¼ç¶åæ´æ°å½å shape
    const offscreenGroup = this.getOffscreenGroup();
    const newShape = shapeFactory.drawShape(this.shapeType, model, offscreenGroup);
    // @ts-ignore
    newShape.cfg.data = this.data;
    // @ts-ignore
    newShape.cfg.origin = model;
    // label éè¦ä½¿ç¨
    newShape.cfg.element = this;

    // step 3: åæ­¥ shape æ ·å¼
    this.syncShapeStyle(shape, newShape, this.getStates(), this.getAnimateCfg('update'));
  }

  /**
   * éæ¯ element å®ä¾ã
   */
  public destroy() {
    const { shapeFactory, shape } = this;

    if (shape) {
      const animateCfg = this.getAnimateCfg('leave');
      if (animateCfg) {
        // æå®äºå¨ç»éç½®åæ§è¡éæ¯å¨ç»
        doAnimate(shape, animateCfg, {
          coordinate: shapeFactory.coordinate,
          toAttrs: {
            ...shape.attr(),
          },
        });
      } else {
        // å¦åç´æ¥éæ¯
        shape.remove(true);
      }
    }

    // reset
    this.states = [];
    this.shapeFactory = undefined;
    this.container = undefined;
    this.shape = undefined;
    this.animate = undefined;
    this.geometry = undefined;
    this.labelShape = undefined;
    this.model = undefined;
    this.data = undefined;
    this.offscreenGroup = undefined;
    this.statesStyle = undefined;

    super.destroy();
  }

  /**
   * æ¾ç¤ºæèéè elementã
   * @param visible æ¯å¦å¯è§ã
   */
  public changeVisible(visible: boolean) {
    super.changeVisible(visible);

    if (visible) {
      if (this.shape) {
        this.shape.show();
      }
      if (this.labelShape) {
        this.labelShape.forEach((label: IGroup) => {
          label.show();
        });
      }
    } else {
      if (this.shape) {
        this.shape.hide();
      }
      if (this.labelShape) {
        this.labelShape.forEach((label: IGroup) => {
          label.hide();
        });
      }
    }
  }

  /**
   * è®¾ç½® Element çç¶æã
   *
   * ç®å Element å¼æ¾ä¸ç§ç¶æï¼
   * 1. active
   * 2. selected
   * 3. inactive
   *
   * è¿ä¸ç§ç¶æç¸äºç¬ç«ï¼å¯ä»¥è¿è¡å å ã
   *
   * è¿ä¸ç§ç¶æçæ ·å¼å¯å¨ [[Theme]] ä¸»é¢ä¸­æèéè¿ `geometry.state()` æ¥å£è¿è¡éç½®ã
   *
   * ```ts
   * // æ¿æ´» active ç¶æ
   * setState('active', true);
   * ```
   *
   * @param stateName ç¶æå
   * @param stateStatus æ¯å¦å¼å¯ç¶æ
   */
  public setState(stateName: string, stateStatus: boolean) {
    const { states, shapeFactory, model, shape, shapeType } = this;

    const index = states.indexOf(stateName);
    if (stateStatus) {
      // å¼å¯ç¶æ
      if (index > -1) {
        // è¯¥ç¶æå·²ç»å¼å¯ï¼åè¿å
        return;
      }
      states.push(stateName);
      if (stateName === 'active' || stateName === 'selected') {
        shape?.toFront();
      }
    } else {
      if (index === -1) {
        // å³é­ç¶æï¼ä½æ¯ç¶ææªè®¾ç½®è¿
        return;
      }
      states.splice(index, 1);
      if (stateName === 'active' || stateName === 'selected') {
        const { sortZIndex, zIndexReversed } = this.geometry;
        const idx = zIndexReversed ? this.geometry.elements.length - this.elementIndex : this.elementIndex;
        sortZIndex ? shape.setZIndex(idx) : shape.set('zIndex', idx);
      }
    }

    // ä½¿ç¨èæ group éæ°ç»å¶ shapeï¼ç¶åå¯¹è¿ä¸ª shape åºç¨ç¶ææ ·å¼åï¼æ´æ°å½å shapeã
    const offscreenShape = shapeFactory.drawShape(shapeType, model, this.getOffscreenGroup());
    if (states.length) {
      // åºç¨å½åç¶æ
      this.syncShapeStyle(shape, offscreenShape, states, null);
    } else {
      // å¦ææ²¡æç¶æï¼åéè¦æ¢å¤è³åå§ç¶æ
      this.syncShapeStyle(shape, offscreenShape, ['reset'], null);
    }

    offscreenShape.remove(true); // éæ¯ï¼åå°åå­å ç¨

    const eventObject = {
      state: stateName,
      stateStatus,
      element: this,
      target: this.container,
    };
    this.container.emit('statechange', eventObject);
    // @ts-ignore
    propagationDelegate(this.shape, 'statechange', eventObject);
  }

  /**
   * æ¸ç©ºç¶éæï¼æ¢å¤è³åå§ç¶æã
   */
  public clearStates() {
    const states = this.states;

    each(states, (state) => {
      this.setState(state, false);
    });

    this.states = [];
  }

  /**
   * æ¥è¯¢å½å Element ä¸æ¯å¦å·²è®¾ç½® `stateName` å¯¹åºçç¶æã
   * @param stateName ç¶æåç§°ã
   * @returns true è¡¨ç¤ºå­å¨ï¼false è¡¨ç¤ºä¸å­å¨ã
   */
  public hasState(stateName: string): boolean {
    return this.states.includes(stateName);
  }

  /**
   * è·åå½å Element ä¸ææçç¶æã
   * @returns å½å Element ä¸ææçç¶ææ°ç»ã
   */
  public getStates(): string[] {
    return this.states;
  }

  /**
   * è·å Element å¯¹åºçåå§æ°æ®ã
   * @returns åå§æ°æ®ã
   */
  public getData(): Datum {
    return this.data;
  }

  /**
   * è·å Element å¯¹åºçå¾å½¢ç»å¶æ°æ®ã
   * @returns å¾å½¢ç»å¶æ°æ®ã
   */
  public getModel(): ShapeInfo {
    return this.model;
  }

  /**
   * è¿å Element åç´ æ´ä½ç bboxï¼åå«ææ¬åææ¬è¿çº¿ï¼æçè¯ï¼ã
   * @returns æ´ä½åå´çã
   */
  public getBBox(): BBox {
    const { shape, labelShape } = this;
    let bbox = {
      x: 0,
      y: 0,
      minX: 0,
      minY: 0,
      maxX: 0,
      maxY: 0,
      width: 0,
      height: 0,
    };
    if (shape) {
      bbox = shape.getCanvasBBox();
    }
    if (labelShape) {
      labelShape.forEach((label: IGroup) => {
        const labelBBox = label.getCanvasBBox();
        bbox.x = Math.min(labelBBox.x, bbox.x);
        bbox.y = Math.min(labelBBox.y, bbox.y);
        bbox.minX = Math.min(labelBBox.minX, bbox.minX);
        bbox.minY = Math.min(labelBBox.minY, bbox.minY);
        bbox.maxX = Math.max(labelBBox.maxX, bbox.maxX);
        bbox.maxY = Math.max(labelBBox.maxY, bbox.maxY);
      });
    }

    bbox.width = bbox.maxX - bbox.minX;
    bbox.height = bbox.maxY - bbox.minY;

    return bbox;
  }

  private getStatesStyle() {
    if (!this.statesStyle) {
      const { shapeType, geometry, shapeFactory } = this;
      const stateOption = geometry.stateOption;
      const defaultShapeType = shapeFactory.defaultShapeType;
      const stateTheme = shapeFactory.theme[shapeType] || shapeFactory.theme[defaultShapeType];
      this.statesStyle = deepMix({}, stateTheme, stateOption);
    }

    return this.statesStyle;
  }

  // ä»ä¸»é¢ä¸­è·åå¯¹åºç¶æéçæ ·å¼
  private getStateStyle(stateName: string, shapeKey?: string): StateCfg {
    const statesStyle = this.getStatesStyle();
    const stateCfg = get(statesStyle, [stateName, 'style'], {});
    const shapeStyle = stateCfg[shapeKey] || stateCfg;
    if (isFunction(shapeStyle)) {
      return shapeStyle(this);
    }

    return shapeStyle;
  }

  // è·åå¨ç»éç½®
  private getAnimateCfg(animateType: string) {
    const animate = this.animate;
    if (animate) {
      const cfg = animate[animateType];

      if (cfg) {
        // å¢å å¨ç»çåè°å½æ°ï¼å¦æå¤é¨ä¼ å¥äºï¼ååæ§è¡å¤é¨ï¼ç¶ååå° geometry ç animate äºä»¶
        return {
          ...cfg,
          callback: () => {
            isFunction(cfg.callback) && cfg.callback();
            this.geometry?.emit(GEOMETRY_LIFE_CIRCLE.AFTER_DRAW_ANIMATE);
          },
        };
      }
      return cfg;
    }

    return null;
  }

  // ç»å¶å¾å½¢
  private drawShape(model: ShapeInfo, isUpdate: boolean = false) {
    const { shapeFactory, container, shapeType } = this;

    // èªå®ä¹ shape æå¯è½è¿åç©º shape
    this.shape = shapeFactory.drawShape(shapeType, model, container);

    if (this.shape) {
      this.setShapeInfo(this.shape, model); // å­å¨ç»å¾æ°æ®
      // @ts-ignore
      const name = this.shape.cfg.name;
      // éå  element ç name, name ç°å¨æ¯ææ°ç»äºï¼å¾å¥½ç¨äº
      if (!name) {
        // è¿ä¸ªå°æ¹å¦æç¨æ·æ·»å äº name, åéå  name ï¼å¦åå°±æ·»å èªå·±ç name
        // @ts-ignore
        this.shape.cfg.name = ['element', this.shapeFactory.geometryType];
      } else if (isString(name)) {
        // @ts-ignore
        this.shape.cfg.name = ['element', name];
      }
      // æ§è¡å¥åºå¨ç»
      const animateType = isUpdate ? 'enter' : 'appear';
      const animateCfg = this.getAnimateCfg(animateType);
      if (animateCfg) {
        // å¼å§æ§è¡å¨ç»ççå½å¨æ
        this.geometry?.emit(GEOMETRY_LIFE_CIRCLE.BEFORE_DRAW_ANIMATE);

        doAnimate(this.shape, animateCfg, {
          coordinate: shapeFactory.coordinate,
          toAttrs: {
            ...this.shape.attr(),
          },
        });
      }
    }
  }

  // è·åèæ Group
  private getOffscreenGroup() {
    if (!this.offscreenGroup) {
      const GroupCtor = this.container.getGroupBase(); // è·ååç»çæé å½æ°
      this.offscreenGroup = new GroupCtor({});
    }

    return this.offscreenGroup;
  }

  // è®¾ç½® shape ä¸éè¦æºå¸¦çä¿¡æ¯
  private setShapeInfo(shape: IShape | IGroup, data: ShapeInfo) {
    // @ts-ignore
    shape.cfg.origin = data;
    // @ts-ignore
    shape.cfg.element = this;
    if (shape.isGroup()) {
      const children = shape.get('children');
      children.forEach((child) => {
        this.setShapeInfo(child, data);
      });
    }
  }

  // æ´æ°å½å shape çæ ·å¼
  private syncShapeStyle(
    sourceShape: IGroup | IShape,
    targetShape: IGroup | IShape,
    states: string[] = [],
    animateCfg,
    index: number = 0
  ) {
    if (!sourceShape || !targetShape) {
      return;
    }
    // ææç shape é½éè¦åæ­¥ clip
    const clip = sourceShape.get('clipShape');
    const newClip = targetShape.get('clipShape');

    this.syncShapeStyle(clip, newClip, states, animateCfg);

    if (sourceShape.isGroup()) {
      const children = sourceShape.get('children');
      const newChildren = targetShape.get('children');
      for (let i = 0; i < children.length; i++) {
        this.syncShapeStyle(children[i], newChildren[i], states, animateCfg, index + i);
      }
    } else {
      if (!isEmpty(states) && !isEqual(states, ['reset'])) {
        let name = sourceShape.get('name');
        if (isArray(name)) {
          // ä¼éå  element ç name
          name = name[1];
        }

        each(states, (state) => {
          // background shape ä¸è¿è¡ç¶ææ ·å¼è®¾ç½®
          if (targetShape.get('name') !== BACKGROUND_SHAPE) {
            const style = this.getStateStyle(state, name || index); // å¦æç¨æ·æ²¡æè®¾ç½® nameï¼åé»è®¤æ ¹æ®ç´¢å¼å¼
            targetShape.attr(style);
          }
        });
      }
      const newAttrs = getReplaceAttrs(sourceShape as IShape, targetShape as IShape);

      if (this.animate) {
        if (animateCfg) {
          this.geometry?.emit(GEOMETRY_LIFE_CIRCLE.BEFORE_DRAW_ANIMATE);
          // éè¦è¿è¡å¨ç»
          doAnimate(sourceShape, animateCfg, {
            coordinate: this.shapeFactory.coordinate,
            toAttrs: newAttrs,
            shapeModel: this.model,
          });
        } else if (!isEmpty(states)) {
          sourceShape.stopAnimate();
          sourceShape.animate(newAttrs, {
            duration: 300,
          });
        } else {
          sourceShape.attr(newAttrs);
        }
      } else {
        sourceShape.attr(newAttrs);
      }
    }
  }

  private getShapeType(model: ShapeInfo) {
    const shape = get(model, 'shape');
    return isArray(shape) ? shape[0] : shape;
  }
}
