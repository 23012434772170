import { clamp, get, size } from '@antv/util';
import { Data, Datum } from '../../types';
import { RANGE_VALUE, RANGE_TYPE, PERCENT } from './constants';
import { GaugeOptions, GaugeRangeData } from './types';

/**
 * å° range çæä¸º data æ°æ®
 * @param range
 * @param key
 * @returns {GaugeRangeData}
 */
export function processRangeData(range: number[], percent: GaugeOptions['percent']): GaugeRangeData {
  return (
    range
      // æ å°ä¸º stack çæ°æ®
      .map((r: number, idx: number) => {
        return { [RANGE_VALUE]: r - (range[idx - 1] || 0), [RANGE_TYPE]: `${idx}`, [PERCENT]: percent };
      })
      // å»æ 0 çæ°æ®
      .filter((d: Datum) => !!d[RANGE_VALUE])
  );
}

/**
 * è·å ä»ªè¡¨ç æéæ°æ®
 * @param percent
 */
export function getIndicatorData(percent: GaugeOptions['percent']): Data {
  return [{ [PERCENT]: clamp(percent, 0, 1) }];
}

/**
 * è·åä»ªè¡¨ç è¡¨çå¼§å½¢æ°æ®
 * @param percent
 * @param range
 */
export function getRangeData(percent: GaugeOptions['percent'], range?: GaugeOptions['range']): GaugeRangeData {
  const ticks = get(range, ['ticks'], []);

  const clampTicks = size(ticks) ? ticks : [0, clamp(percent, 0, 1), 1];
  return processRangeData(clampTicks as number[], percent);
}
