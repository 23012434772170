import { each } from '@antv/util';
import { Point } from '../../../dependents';
import TransformAction from './scale-transform';

/**
 * ææ½ Scale ç Action
 * @ignore
 */
class ScaleTranslate extends TransformAction {
  protected startPoint: Point = null;
  protected starting = false;
  private startCache = {};
  /**
   * å¼å§
   */
  public start() {
    this.startPoint = this.context.getCurrentPoint();
    this.starting = true;
    const dims = this.dims;
    each(dims, (dim) => {
      const scale = this.getScale(dim);
      const { min, max, values } = scale;
      this.startCache[dim] = { min, max, values };
    });
  }

  // å¹³ç§»åç±»çåº¦é
  // private translateCategory(dim, scale, normalPoint) {
  // }

  /**
   * ç»æ
   */
  public end() {
    this.startPoint = null;
    this.starting = false;
    this.startCache = {};
  }

  /**
   * å¹³ç§»
   */
  public translate() {
    if (!this.starting) {
      return;
    }
    const startPoint = this.startPoint;
    const coord = this.context.view.getCoordinate();
    const currentPoint = this.context.getCurrentPoint();
    const normalStart = coord.invert(startPoint);
    const noramlCurrent = coord.invert(currentPoint);
    const dx = noramlCurrent.x - normalStart.x;
    const dy = noramlCurrent.y - normalStart.y;
    const view = this.context.view;
    const dims = this.dims;
    each(dims, (dim) => {
      this.translateDim(dim, { x: dx * -1, y: dy * -1 });
    });
    view.render(true);
  }

  // å¹³ç§»åº¦é
  private translateDim(dim, normalPoint) {
    if (this.hasDim(dim)) {
      const scale = this.getScale(dim);
      if (scale.isLinear) {
        this.translateLinear(dim, scale, normalPoint);
      }
      //  else { // ææ¶ä»å¤çè¿ç»­å­æ®µ
      // this.translateCategory(dim, scale, normalPoint);
      // }
    }
  }
  // linear åº¦éå¹³ç§»
  private translateLinear(dim, scale, normalPoint) {
    const view = this.context.view;
    const { min, max } = this.startCache[dim];
    const range = max - min;
    const d = normalPoint[dim] * range;
    // åªæç¬¬ä¸æ¬¡ç¼å­ï¼å¦åæ æ³åæ»
    if (!this.cacheScaleDefs[dim]) {
      this.cacheScaleDefs[dim] = {
        // @ts-ignore
        nice: scale.nice,
        min,
        max,
      };
    }
    view.scale(scale.field, {
      // @ts-ignore
      nice: false,
      min: min + d,
      max: max + d,
    });
  }

  // å¹³ç§»åç±»çåº¦é
  // private translateCategory(dim, scale, normalPoint) {
  // }

  /**
   * åæ»
   */
  public reset() {
    super.reset();
    this.startPoint = null;
    this.starting = false;
  }
}

export default ScaleTranslate;
