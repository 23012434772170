import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { RadialBarOptions } from './types';
import { adaptor, meta } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';

export type { RadialBarOptions };

/**
 * ççå¾
 */
export class RadialBar extends Plot<RadialBarOptions> {
  static getDefaultOptions(): Partial<RadialBarOptions> {
    return DEFAULT_OPTIONS;
  }
  /** å¾è¡¨ç±»å */
  public type: string = 'radial-bar';

  /**
   * @override
   * @param data
   */
  public changeData(data) {
    this.updateOption({ data });
    // æ´æ°ççå¾ç scale
    meta({ chart: this.chart, options: this.options });
    this.chart.changeData(data);
  }

  /**
   * è·åé»è®¤éç½®
   */
  protected getDefaultOptions(): Partial<RadialBarOptions> {
    return RadialBar.getDefaultOptions();
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<RadialBarOptions> {
    return adaptor;
  }
}
