import { Types } from '@antv/g2';
import { pack } from '../../utils/hierarchy/pack';
import { deepAssign, pick } from '../../utils';
import { HIERARCHY_DATA_TRANSFORM_PARAMS } from '../../interactions/actions/drill-down';
import { resolveAllPadding } from '../../utils/padding';
import { CirclePackingOptions } from './types';

interface TransformDataOptions {
  data: CirclePackingOptions['data'];
  rawFields: CirclePackingOptions['rawFields'];
  enableDrillDown: boolean;
  hierarchyConfig: CirclePackingOptions['hierarchyConfig'];
}

/**
 * circle-packing æ°æ®è½¬æ¢
 * @param options
 */
export function transformData(options: TransformDataOptions) {
  const { data, hierarchyConfig, rawFields = [], enableDrillDown } = options;

  const nodes = pack(data, {
    ...hierarchyConfig,
    field: 'value',
    as: ['x', 'y', 'r'],
  });

  const result = [];
  nodes.forEach((node) => {
    let path = node.data.name;
    let ancestorNode = { ...node };
    while (ancestorNode.depth > 1) {
      path = `${ancestorNode.parent.data?.name} / ${path}`;
      ancestorNode = ancestorNode.parent;
    }

    // å¼å¯ä¸é»ï¼ä»å è½½ depth <= 2 çæ°æ® (å è½½ä¸¤å±)
    if (enableDrillDown && node.depth > 2) {
      return null;
    }

    const nodeInfo = deepAssign({}, node.data, {
      ...pick(node.data, rawFields),
      path,
      // ä»¥ä¸å­æ®µï¼å¿å¤: x, y, r, name, depth, height
      ...node,
    });

    nodeInfo.ext = hierarchyConfig;
    nodeInfo[HIERARCHY_DATA_TRANSFORM_PARAMS] = { hierarchyConfig, rawFields, enableDrillDown };

    result.push(nodeInfo);
  });

  return result;
}

/**
 * æ ¹æ®ä¼ å¥ç padding å ç°æç ç»å¸å¤§å°ï¼ è¾åºéå¯¹åå½¢è§å¾å¸å±éè¦ç finalPadding ä»¥å finalSize
 * @param params
 */
export function resolvePaddingForCircle(
  padding: Types.ViewPadding,
  appendPadding: Types.ViewAppendPadding,
  containerSize: { width: number; height: number }
) {
  const tempPadding = resolveAllPadding([padding, appendPadding]);
  const [top, right, bottom, left] = tempPadding; // æ²¡è®¾å®ï¼é»è®¤æ¯ [0, 0, 0, 0]
  const { width, height } = containerSize;

  // æäº tempPadding ä»å¥ä»¥åï¼è®¡ç®åºcoordinateèå´å®½é«çæå°å¼ minSize = circle-packingçç´å¾
  const wSize = width - (left + right);
  const hSize = height - (top + bottom);
  const minSize = Math.min(wSize, hSize); // circle-packingçç´å¾

  // å¾å°å±ä¸­ååæ¹åå©ä½ç padding
  const restWidthPadding = (wSize - minSize) / 2;
  const restHeightPadding = (hSize - minSize) / 2;

  const finalTop = top + restHeightPadding;
  const finalRight = right + restWidthPadding;
  const finalBottom = bottom + restHeightPadding;
  const finalLeft = left + restWidthPadding;

  const finalPadding = [finalTop, finalRight, finalBottom, finalLeft];
  const finalSize = minSize < 0 ? 0 : minSize; // é²æ­¢ä¸ºè´æ°

  return { finalPadding, finalSize };
}
