import { Params } from '../../core/adaptor';
import { tooltip } from '../../adaptor/common';
import { deepAssign, flow } from '../../utils';
import { Axis } from '../../types';
import { adaptor as columnAdaptor } from '../column/adaptor';
export { meta } from '../column/adaptor';
import { BarOptions } from './types';

/**
 * å¤çé»è®¤éç½®é¡¹
 * 1. switch xFieldã yField
 * 2. switch xAxisã yAxis and adjust axis.position configuration
 */
function defaultOptions(params: Params<BarOptions>): Params<BarOptions> {
  const { options } = params;
  const { xField, yField, xAxis, yAxis } = options;

  const position = {
    left: 'bottom',
    right: 'top',
    top: 'left',
    bottom: 'right',
  };

  const verticalAxis =
    yAxis !== false
      ? {
          position: position[yAxis?.position || 'left'],
          ...yAxis,
        }
      : false;
  const horizontalAxis =
    xAxis !== false
      ? {
          position: position[xAxis?.position || 'bottom'],
          ...xAxis,
        }
      : false;

  return {
    ...params,
    options: {
      ...options,
      xField: yField,
      yField: xField,
      // æ¡å½¢å¾ xAxisï¼yAxis ä¸å¯ä»¥å deepAssign
      xAxis: verticalAxis as Axis,
      yAxis: horizontalAxis as Axis,
    },
  };
}

/**
 * label ééå¨
 * @param params
 */
function label(params: Params<BarOptions>): Params<BarOptions> {
  const { options } = params;
  const { label } = options;
  // label of bar charts default position is left, if plot has label
  if (label && !label.position) {
    label.position = 'left';
    // éç½®é»è®¤ç label layoutï¼ å¦æç¨æ·æ²¡ææå® layout å positionï¼ åèªå¨éç½® layout
    if (!label.layout) {
      label.layout = [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
        { type: 'limit-in-plot', cfg: { action: 'hide' } },
      ];
    }
  }

  return deepAssign({}, params, { options: { label } });
}

/**
 * legend ééå¨
 * @param params
 */
function legend(params: Params<BarOptions>): Params<BarOptions> {
  const { options } = params;

  // é»è®¤ legend ä½ç½®
  const { seriesField, isStack } = options;
  let { legend } = options;
  if (seriesField) {
    if (legend !== false) {
      legend = {
        position: isStack ? 'top-left' : 'right-top',
        ...(legend || {}),
      };
    }
  } else {
    legend = false;
  }

  return deepAssign({}, params, { options: { legend } });
}

/**
 * coordinate ééå¨
 * @param params
 */
function coordinate(params: Params<BarOptions>): Params<BarOptions> {
  const { chart } = params;
  // transpose column to bar å¯¹è§åæ¢ & y æ¹åéååæ¢
  chart.coordinate({ actions: [['transpose'], ['reflect', 'y']] });
  return params;
}

/**
 * æ±å½¢å¾ééå¨
 * @param params
 */
export function geometry(params: Params<BarOptions>) {
  const { chart, options } = params;

  const { barStyle, barWidthRatio, minBarWidth, maxBarWidth, barBackground } = options;

  return columnAdaptor(
    {
      chart,
      options: {
        ...options,
        // rename attrs as column
        columnStyle: barStyle,
        columnWidthRatio: barWidthRatio,
        minColumnWidth: minBarWidth,
        maxColumnWidth: maxBarWidth,
        columnBackground: barBackground,
      },
    },
    true
  ) as Params<BarOptions>;
}

/**
 * @param chart
 * @param options
 */
export function adaptor(params: Params<BarOptions>): Params<BarOptions> {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow<Params<BarOptions>>(defaultOptions, label, legend, tooltip, coordinate, geometry)(params);
}
