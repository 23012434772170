import { IList } from '@antv/component';
import { each } from '@antv/util';

const STATUS_UNACTIVE = 'inactive';
const STATUS_ACTIVE = 'active';

/**
 * æ¸çå¾ä¾ç Highlight ææ
 * @param list åè¡¨ç»ä»¶ï¼å¾ä¾æèåæ è½´
 * @ignore
 */
export function clearList(list: IList) {
  const items = list.getItems();
  each(items, (item) => {
    if (list.hasState(item, STATUS_ACTIVE)) {
      list.setItemState(item, STATUS_ACTIVE, false);
    }
    if (list.hasState(item, STATUS_UNACTIVE)) {
      list.setItemState(item, STATUS_UNACTIVE, false);
    }
  });
}
