import { Point } from '../../../dependents';
import Action from '../base';
import { distance } from '../util';

const DISTANCE = 4; // ç§»å¨çæå°è·ç¦»

/**
 * @ignore
 * View æ¯æ Drag ç Action
 */
class Drag extends Action {
  // Action å¼å§ï¼ä¸ç­åäº ææ½å¼å§ï¼éè¦å¤å®ç§»å¨çèå´
  protected starting = false;
  // ææ½å¼å§
  protected dragStart = false;
  // å¼å§çèç¹
  protected startPoint: Point;

  /**
   * å¼å§
   */
  public start() {
    this.starting = true;
    this.startPoint = this.context.getCurrentPoint();
  }

  /**
   * ææ½
   */
  public drag() {
    if (!this.startPoint) {
      return;
    }
    const current = this.context.getCurrentPoint();
    const view = this.context.view;
    const event = this.context.event;
    if (!this.dragStart) {
      if (distance(current, this.startPoint) > DISTANCE) {
        view.emit('dragstart', {
          target: event.target,
          x: event.x,
          y: event.y,
        });
        this.dragStart = true;
      }
    } else {
      view.emit('drag', {
        target: event.target,
        x: event.x,
        y: event.y,
      });
    }
  }

  /**
   * ç»æ
   */
  public end() {
    if (this.dragStart) {
      const view = this.context.view;
      const event = this.context.event;
      view.emit('dragend', {
        target: event.target,
        x: event.x,
        y: event.y,
      });
    }
    this.starting = false;
    this.dragStart = false;
  }
}

export default Drag;
