import { each } from '@antv/util';
import { IList, ListItem } from '../../../dependents';
import { clearList } from './list-highlight-util';
import ListState from './list-state';
const STATUS_UNACTIVE = 'inactive';
const STATUS_ACTIVE = 'active';
type MatchCallback = (item: ListItem) => boolean;

/**
 * highlight Action çæææ¯ active å inactive ä¸¤ä¸ªç¶æçç»å
 * @class
 * @ignore
 */
class ListHighlight extends ListState {
  protected stateName: string = STATUS_ACTIVE;
  protected ignoreItemStates = ['unchecked']; // å½å­å¨ unchecked ç¶ææ¶ä¸è§¦å
  // å¦æ item.name å¹éï¼åè®¾ç½® highlight ä»¥ååæ¶
  protected setItemsState(list: IList, name: string, enable: boolean) {
    this.setHighlightBy(list, (item) => item.name === name, enable);
  }

  // åä¸ª item è®¾ç½®ç¶æ
  protected setItemState(list: IList, item: ListItem, enable: boolean) {
    const items = list.getItems();
    this.setHighlightBy(list, (el) => el === item, enable);
  }

  // æ ¹æ®æ¡ä»¶è®¾ç½® highlight
  private setHighlightBy(list: IList, callback: MatchCallback, enable: boolean) {
    const items = list.getItems();
    if (enable) {
      // è®¾ç½® highlight æ¶ï¼ä¿çä¹åå·²ç» Highlight çé¡¹
      each(items, (item) => {
        if (callback(item)) {
          if (list.hasState(item, STATUS_UNACTIVE)) {
            list.setItemState(item, STATUS_UNACTIVE, false);
          }
          list.setItemState(item, STATUS_ACTIVE, true);
        } else if (!list.hasState(item, STATUS_ACTIVE)) {
          list.setItemState(item, STATUS_UNACTIVE, true);
        }
      });
    } else {
      const activeItems = list.getItemsByState(STATUS_ACTIVE);
      let allCancel = true;
      // æ£æµ activeItems æ¯å¦è¦å¨é¨åæ¶
      each(activeItems, (item) => {
        if (!callback(item)) {
          allCancel = false;
          return false;
        }
      });
      if (allCancel) {
        this.clear();
      } else {
        // å¦æä¸æ¯é½è¦åæ¶ highlight, åè®¾ç½®å¹éç element çç¶æä¸º unactive
        // å¶ä» element ç¶æä¸å
        each(items, (item) => {
          if (callback(item)) {
            if (list.hasState(item, STATUS_ACTIVE)) {
              list.setItemState(item, STATUS_ACTIVE, false);
            }
            list.setItemState(item, STATUS_UNACTIVE, true);
          }
        });
      }
    }
  }

  /**
   * highlight å¾ä¾é¡¹ï¼åæ è½´ææ¬ï¼
   */
  public highlight() {
    this.setState();
  }

  // éè¦å¨é¨æ¸ç active å unactive
  public clear() {
    const triggerInfo = this.getTriggerListInfo();
    if (triggerInfo) {
      clearList(triggerInfo.list);
    } else {
      // å¦æä¸æ¯ component çäºä»¶è§¦åï¼åæææ»¡è¶³è§¦åæ¡ä»¶çç»ä»¶é½æ¸é¤è¯¥ç¶æ
      const components = this.getAllowComponents();
      each(components, (component) => {
        component.clearItemsState(STATUS_ACTIVE);
        component.clearItemsState(STATUS_UNACTIVE);
      });
    }
  }
}

export default ListHighlight;
