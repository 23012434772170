import { ext } from '@antv/matrix-util';
import { each } from '@antv/util';
import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { AnimateExtraCfg } from '../interface';

function doShapeZoom(shape: IShape | IGroup, animateCfg: GAnimateCfg, type: 'zoomIn' | 'zoomOut') {
  if (shape.isGroup()) {
    each((shape as IGroup).getChildren(), (child) => {
      doShapeZoom(child, animateCfg, type);
    });
  } else {
    const bbox = shape.getBBox();
    const x = (bbox.minX + bbox.maxX) / 2;
    const y = (bbox.minY + bbox.maxY) / 2;
    shape.applyToMatrix([x, y, 1]);

    if (type === 'zoomIn') {
      // æ¾å¤§
      const matrix = ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 0.01, 0.01],
        ['t', x, y],
      ]);
      shape.setMatrix(matrix);
      shape.animate(
        {
          matrix: ext.transform(shape.getMatrix(), [
            ['t', -x, -y],
            ['s', 100, 100],
            ['t', x, y],
          ]),
        },
        animateCfg
      );
    } else {
      shape.animate(
        {
          matrix: ext.transform(shape.getMatrix(), [
            ['t', -x, -y],
            ['s', 0.01, 0.01],
            ['t', x, y],
          ]),
        },
        {
          ...animateCfg,
          callback: () => {
            shape.remove(true);
          },
        }
      );
    }
  }
}

/**
 * @ignore
 * åä¸ª shape å¨ç»
 * shape ä»¥èªèº«ä¸­å¿ç¹éæ¸æ¾å¤§çè¿å¥å¨ç»
 * @param shape åä¸å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function zoomIn(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  doShapeZoom(shape, animateCfg, 'zoomIn');
}

/**
 * @ignore
 * åä¸ª shape å¨ç»
 * æ¶å¤±å¨ç»ï¼shape ä»¥èªèº«ä¸ºä¸­å¿ç¹çéæ¸ç¼©å°
 * @param shape åä¸å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function zoomOut(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  doShapeZoom(shape, animateCfg, 'zoomOut');
}
