export default {
  base: {
    remove: 'åé¤',
    cancel: 'ã­ã£ã³ã»ã«',
    confirm: 'æ±ºå®',
    inert: 'æ¿å¥',
    width: 'å¹',
    height: 'é«ã'
  },
  controls: {
    clear: 'ã¯ãªã¢ã³ã³ãã³ã',
    undo: 'ã­ã£ã³ã»ã«',
    redo: 'ã­ã£ã³ã»ã«',
    fontSize: 'ãã©ã³ããµã¤ãº',
    lineHeight: 'ãã©ã³ããµã¤ãº',
    letterSpacing: 'ã¯ã¼ãéé',
    textIndent: 'æ®µè½ã®ã¤ã³ãã³ã',
    increaseIndent: 'ã¤ã³ãã³ããå¢ãã',
    decreaseIndent: 'ã¤ã³ãã³ããæ¸ãã',
    border: 'å½å¢',
    color: 'è²',
    textColor: 'ãã­ã¹ãã®è²',
    backgroundColor: 'èæ¯è²',
    tempColors: 'ä»®è²',
    bold: 'å¤ªå­',
    italic: 'ã¤ã¿ãªãã¯ä½',
    underline: 'ä¸ç·',
    strikeThrough: 'åãæ¶ãç·',
    fontFamily: 'ãã©ã³ã',
    textAlign: 'ãã­ã¹ãã®éç½®',
    alignLeft: 'å·¦',
    alignCenter: 'ä¸­å¤®æã',
    alignRight: 'å³ã«ç«ã¤',
    alignJustify: 'ä¸¡ç«¯',
    floatLeft: 'å·¦ãã­ã¼ãã£ã³ã°',
    floatRight: 'å³ãã­ã¼ãã£ã³ã°',
    superScript: 'ä¸ä»ã',
    subScript: 'ä¸ä»ãæå­',
    removeStyles: 'ã¯ãªã¢ã¹ã¿ã¤ã«',
    headings: 'å½¹è·',
    header: 'å½¹è·',
    normal: 'å¾æ¥ã®',
    orderedList: 'é åºä»ããªã¹ã',
    unorderedList: 'çªå·ãªããªã¹ã',
    blockQuote: 'åç§',
    code: 'ã³ã¼ã',
    link: 'ãªã³ã¯',
    unlink: 'ãªã³ã¯ãè§£é¤',
    hr: 'æ¨ªç·',
    media: 'ã¡ãã£ã¢',
    mediaLibirary: 'ã¡ãã£ã¢ã©ã¤ãã©ãªã¼',
    emoji: 'å°ããªè¡¨ç¾',
    fullscreen: 'å¨ç»é¢',
    exitFullscreen: 'å¨ç»é¢ãéã',
  },
  linkEditor: {
    textInputPlaceHolder: 'ãªã³ã¯ãã­ã¹ããå¥å',
    linkInputPlaceHolder: 'ãªã³ã¯ã¢ãã¬ã¹ãå¥å',
    inputWithEnterPlaceHolder: 'ãªã³ã¯ã¢ãã¬ã¹ãå¥åãã¦æ»ãã¾ã',
    openInNewWindow: 'æ°ããã¦ã£ã³ãã¦ã§éã',
    removeLink: 'æ°ããã¦ã£ã³ãã¦ã§éã'
  },
  audioPlayer: {
    title: 'ãªã¼ãã£ãªãã¡ã¤ã«ãåçãã'
  },
  videoPlayer: {
    title: 'ãããªãã¡ã¤ã«ãåçãã',
    embedTitle: 'åãè¾¼ã¿ã¡ãã£ã¢'
  },
  media: {
    image: 'ç»å',
    video: 'ãããª',
    audio: 'é³å£°',
    embed: 'åãè¾¼ã¿ã¡ãã£ã¢'
  }
}