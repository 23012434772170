import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { RoseOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';

export type { RoseOptions };

export class Rose extends Plot<RoseOptions> {
  /**
   * è·å ç«ç°å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<RoseOptions> {
    return DEFAULT_OPTIONS;
  }

  /** ç«ç°å¾ */
  public type: string = 'rose';

  /**
   * @override
   * @param data
   */
  public changeData(data) {
    this.updateOption({ data });
    this.chart.changeData(data);
  }

  /**
   * è·åé»è®¤ç options éç½®é¡¹
   */
  protected getDefaultOptions(): Partial<RoseOptions> {
    return Rose.getDefaultOptions();
  }

  /**
   * è·å ç«ç°å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<RoseOptions> {
    return adaptor;
  }
}
