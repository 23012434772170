import { Util } from '@antv/g2';
import { get } from '@antv/util';
import { getCanvasPattern } from '../utils/pattern';
import { Params } from '../core/adaptor';
import { Datum, Options, StyleAttr } from '../types';
import { deepAssign } from '../utils';

/**
 * ä½¿ç¨ Pattern ééç optionsï¼è¦æ±æ colorField/seriesField/stackField ä½ä¸ºåç±»å­æ®µï¼è¿è¡é¢è²æ å°ï¼
 */
type OptionsRequiredInPattern = Omit<Options, 'data'>;

/**
 * Pattern ééï¼å¤çå¾æ¡å¡«å
 * ð ç®åæ¯æå¾è¡¨ç±»åï¼é¥¼å¾ãæ±ç¶å¾ãæ¡å½¢å¾ãççå¾ç­ï¼ä¸æ¯æå¨å¤ view å¾è¡¨ä¸­ï¼åç»­æéæ©å±ï¼
 *
 * @param key key of style property
 * @returns
 */
export function pattern(key: string) {
  return <O extends OptionsRequiredInPattern = OptionsRequiredInPattern>(params: Params<O>): Params<O> => {
    const { options, chart } = params;
    const { pattern: patternOption } = options;

    // æ²¡æ pattern éç½®ï¼åç´æ¥è¿å
    if (!patternOption) {
      return params;
    }

    /** ~~~~~~~ è¿è¡è´´å¾å¾æ¡å¤ç ~~~~~~~ */

    const style: StyleAttr = (datum?: Datum, ...args: any[]) => {
      const { defaultColor } = chart.getTheme();
      let color = defaultColor;

      const colorAttribute = chart.geometries?.[0]?.getAttribute('color');
      if (colorAttribute) {
        const colorField = colorAttribute.getFields()[0];
        const seriesValue = get(datum, colorField);
        color = Util.getMappingValue(colorAttribute, seriesValue, colorAttribute.values?.[0] || defaultColor);
      }

      let pattern: CanvasPattern = patternOption as CanvasPattern;

      // 1. å¦æ patternOption æ¯ä¸ä¸ªåè°ï¼åè·ååè°ç»æã`(datum: Datum, color: string) => CanvasPattern`
      if (typeof patternOption === 'function') {
        pattern = patternOption.call(this, datum, color);
      }

      // 2. å¦æ pattern ä¸æ¯ CanvasPatternï¼åè¿ä¸æ­¥å¤çï¼å¦åç´æ¥èµäºç» fill
      if (pattern instanceof CanvasPattern === false) {
        // éè¿ createPattern(PatternStyle) è½¬æ¢ä¸º CanvasPattern
        pattern = getCanvasPattern(deepAssign({}, { cfg: { backgroundColor: color } }, pattern));
      }

      const styleOption = options[key] as StyleAttr;

      return {
        ...(typeof styleOption === 'function' ? styleOption.call(this, datum, ...args) : styleOption || {}),
        fill: pattern || color,
      };
    };

    return deepAssign({}, params, { options: { [key]: style } });
  };
}
