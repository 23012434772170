import { ext } from '@antv/matrix-util';
import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg, Point } from '../../interface';
import { AnimateExtraCfg } from '../interface';

/**
 * @ignore
 * æ²¿ç x æ¹åæ¾å¤§çå¨ç»
 * @param shape
 * @param animateCfg
 * @param shapeModel
 */
export function scaleInX(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const box = shape.getBBox();
  const { mappingData } = shape.get('origin');
  const points = mappingData.points as Point[];
  // x æ°å¼å¦æä¸ºè´å¼ï¼é£ä¹åºè¯¥ä»å³å¾å·¦çé¿
  const x = points[0].y - points[1].y > 0 ? box.maxX : box.minX;
  const y = (box.minY + box.maxY) / 2;

  shape.applyToMatrix([x, y, 1]);

  const matrix = ext.transform(shape.getMatrix(), [
    ['t', -x, -y],
    ['s', 0.01, 1],
    ['t', x, y],
  ]);
  shape.setMatrix(matrix);

  shape.animate(
    {
      matrix: ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 100, 1],
        ['t', x, y],
      ]),
    },
    animateCfg
  );
}

/**
 * @ignore
 * æ²¿ç y æ¹åæ¾å¤§çå¨ç»
 * @param shape
 * @param animateCfg
 * @param shapeModel
 */
export function scaleInY(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const box = shape.getBBox();
  const { mappingData } = shape.get('origin');
  const x = (box.minX + box.maxX) / 2;
  const points = mappingData.points as Point[];
  // æ°å¼å¦æä¸ºè´å¼ï¼é£ä¹åºè¯¥ä»ä¸å¾ä¸çé¿ï¼éè¿ shape çå³é®ç¹è¿è¡å¤æ­
  const y = points[0].y - points[1].y <= 0 ? box.maxY : box.minY;

  shape.applyToMatrix([x, y, 1]);
  const matrix = ext.transform(shape.getMatrix(), [
    ['t', -x, -y],
    ['s', 1, 0.01],
    ['t', x, y],
  ]);
  shape.setMatrix(matrix);

  shape.animate(
    {
      matrix: ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 1, 100],
        ['t', x, y],
      ]),
    },
    animateCfg
  );
}
