import { getActionClass } from '@antv/g2';
import { placeElementsOrdered } from '../util';

const ElementActiveAction: any = getActionClass('element-active');

export class VennElementActive extends ElementActiveAction {
  /**
   * åæ­¥ææåç´ çä½ç½®
   */
  protected syncElementsPos() {
    placeElementsOrdered(this.context.view);
  }

  /** æ¿æ´»å¾å½¢åç´  */
  public active() {
    super.active();
    this.syncElementsPos();
  }

  /** toggle å¾å½¢åç´ æ¿æ´»ç¶æ */
  public toggle() {
    super.toggle();
    this.syncElementsPos();
  }

  /** éç½® */
  public reset() {
    super.reset();
    this.syncElementsPos();
  }
}
