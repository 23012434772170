import { map } from '@antv/util';
import { BOX_RANGE } from './constant';
import { BoxOptions } from './types';

/**
 * @desc å°æ°æ®è½¬æ¢ä¸º box éè¦ççå¾è¡¨æ°æ®,å¦æyFieldä¸ºæ°ç»,ä»dataä¸­è§£æåºå¯¹åºæ°ç»å¼å¹¶åå¥data,å¦åç´æ¥è¿ådata
 * @param data
 * @param yField
 */
export const transformData = (data: BoxOptions['data'], yField: BoxOptions['yField']) => {
  let newData = data;
  // formate data when `yField` is Array
  if (Array.isArray(yField)) {
    const [low, q1, median, q3, high] = yField;
    newData = map(data, (obj) => {
      obj[BOX_RANGE] = [obj[low], obj[q1], obj[median], obj[q3], obj[high]];
      return obj;
    });
  }

  return newData;
};
