import { isNumber } from '@antv/util';
import { Params } from '../../core/adaptor';
import { flow, deepAssign, pick } from '../../utils';
import { point } from '../../adaptor/geometries';
import { brushInteraction } from '../../adaptor/brush';
import { interaction, animation, theme, scale, annotation, slider, scrollbar } from '../../adaptor/common';
import { findGeometry, transformLabel } from '../../utils';
import { getQuadrantDefaultConfig, getPath, getMeta } from './util';
import { ScatterOptions } from './types';

/**
 * æ£ç¹å¾é»è®¤ç¾è§
 * â  data.length === 1 â¡ æææ°æ® y å¼ç¸ç­ â¢ æææ°æ® x å¼ç¸ç­
 * @param params
 * @returns params
 */
export function transformOptions(options: ScatterOptions): ScatterOptions {
  const { data = [], xField, yField } = options;

  if (data.length) {
    // x y å­æ®µç¥å¦åªæä¸ä¸ªå¼ï¼å¦æåªæä¸ä¸ªå¼ï¼åè¿è¡ä¼å
    let isOneX = true;
    let isOneY = true;

    let prev = data[0];
    let curr;

    for (let i = 1; i < data.length; i++) {
      curr = data[i];

      if (prev[xField] !== curr[xField]) {
        isOneX = false;
      }

      if (prev[yField] !== curr[yField]) {
        isOneY = false;
      }

      // å¦æé½ä¸æ¯ oneValueï¼é£ä¹å¯æåè·³åºå¾ªç¯
      if (!isOneX && !isOneY) {
        break;
      }

      prev = curr;
    }

    const keys = [];
    isOneX && keys.push(xField);
    isOneY && keys.push(yField);

    const meta = pick(getMeta(options), keys);

    return deepAssign({}, options, { meta });
  }

  return options;
}

/**
 * å­æ®µ
 * @param params
 */
function geometry(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { chart, options } = params;
  const { data, type, color, shape, pointStyle, shapeField, colorField, xField, yField, sizeField } = options;
  let { size } = options;

  let { tooltip } = options;

  if (sizeField) {
    if (!size) {
      size = [2, 8];
    }
    if (isNumber(size)) {
      size = [size, size];
    }
  }

  if (tooltip && !tooltip.fields) {
    tooltip = {
      ...tooltip,
      fields: [xField, yField, colorField, sizeField, shapeField],
    };
  }
  // æ°æ®
  chart.data(data);

  // geometry
  point(
    deepAssign({}, params, {
      options: {
        seriesField: colorField,
        point: {
          color,
          shape,
          size,
          style: pointStyle,
        },
        tooltip,
      },
    })
  );

  const geometry = findGeometry(chart, 'point');

  // æ°æ®è°æ´
  if (type) {
    geometry.adjust(type);
  }

  return params;
}

/**
 * meta éç½®
 * @param params
 */
export function meta(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { options } = params;
  const { xAxis, yAxis, xField, yField } = options;

  const newOptions = transformOptions(options);
  return flow(
    scale({
      [xField]: xAxis,
      [yField]: yAxis,
    })
  )(deepAssign({}, params, { options: newOptions }));
}

/**
 * axis éç½®
 * @param params
 */
function axis(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { chart, options } = params;
  const { xAxis, yAxis, xField, yField } = options;

  chart.axis(xField, xAxis);
  chart.axis(yField, yAxis);

  return params;
}

/**
 * legend éç½®
 * @param params
 */
function legend(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { chart, options } = params;
  const { legend, colorField, shapeField, sizeField, shapeLegend, sizeLegend } = options;

  /** legend ä¸ä¸º false, åå±ç¤ºå¾ä¾, ä¼åå±ç¤º color åç±»å¾ä¾ */
  const showLegend = legend !== false;

  if (colorField) {
    chart.legend(colorField, showLegend ? legend : false);
  }

  // ä¼åå shapeLegend, å¦åå legend
  if (shapeField) {
    if (shapeLegend) {
      chart.legend(shapeField, shapeLegend);
    } else {
      chart.legend(shapeField, shapeLegend === false ? false : legend);
    }
  }

  if (sizeField) {
    chart.legend(sizeField, sizeLegend ? sizeLegend : false);
  }

  /** é»è®¤ä¸å±ç¤º shape å¾ä¾ï¼å½ shapeLegend ä¸º undefined ä¹ä¸å±ç¤ºå¾ä¾ */
  /** é»è®¤æ²¡æ sizeFieldï¼åéèè¿ç»­å¾ä¾ */
  if (!showLegend && !shapeLegend && !sizeLegend) {
    chart.legend(false);
  }

  return params;
}

/**
 * æ°æ®æ ç­¾
 * @param params
 */
function label(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { chart, options } = params;
  const { label, yField } = options;

  const scatterGeometry = findGeometry(chart, 'point');

  // label ä¸º false, ç©º åä¸æ¾ç¤º label
  if (!label) {
    scatterGeometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    scatterGeometry.label({
      fields: [yField],
      callback,
      cfg: transformLabel(cfg),
    });
  }

  return params;
}

/**
 * annotation éç½®
 * - ç¹æ® annotation: quadrant(åè±¡é)
 * @param params
 */
function scatterAnnotation(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { options } = params;
  const { quadrant } = options;

  const annotationOptions = [];

  if (quadrant) {
    const { xBaseline = 0, yBaseline = 0, labels, regionStyle, lineStyle } = quadrant;
    const defaultConfig = getQuadrantDefaultConfig(xBaseline, yBaseline);
    // ä»æ¯æåè±¡é
    const quadrants = new Array(4).join(',').split(',');
    quadrants.forEach((_: string, index: number) => {
      annotationOptions.push(
        {
          type: 'region',
          top: false,
          ...defaultConfig.regionStyle[index].position,
          style: deepAssign({}, defaultConfig.regionStyle[index].style, regionStyle?.[index]),
        },
        {
          type: 'text',
          top: true,
          ...deepAssign({}, defaultConfig.labelStyle[index], labels?.[index]),
        }
      );
    });
    // çæåæ è½´
    annotationOptions.push(
      {
        type: 'line',
        top: false,
        start: ['min', yBaseline],
        end: ['max', yBaseline],
        style: deepAssign({}, defaultConfig.lineStyle, lineStyle),
      },
      {
        type: 'line',
        top: false,
        start: [xBaseline, 'min'],
        end: [xBaseline, 'max'],
        style: deepAssign({}, defaultConfig.lineStyle, lineStyle),
      }
    );
  }

  return flow(annotation(annotationOptions))(params);
}

// è¶å¿çº¿
function regressionLine(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { options, chart } = params;
  const { regressionLine } = options;
  if (regressionLine) {
    const { style, top = false } = regressionLine;
    const defaultStyle = {
      stroke: '#9ba29a',
      lineWidth: 2,
      opacity: 0.5,
    };
    chart.annotation().shape({
      top,
      render: (container, view) => {
        const group = container.addGroup({
          id: `${chart.id}-regression-line`,
          name: 'regression-line-group',
        });
        const path = getPath({
          view,
          options,
        });
        group.addShape('path', {
          name: 'regression-line',
          attrs: {
            path,
            ...defaultStyle,
            ...style,
          },
        });
      },
    });
  }

  return params;
}

/**
 * tooltip éç½®
 * @param params
 */
export function tooltip(params: Params<ScatterOptions>): Params<ScatterOptions> {
  const { chart, options } = params;
  const { tooltip } = options;

  if (tooltip) {
    chart.tooltip(tooltip);
  } else if (tooltip === false) {
    chart.tooltip(false);
  }

  return params;
}

/**
 * æ£ç¹å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<ScatterOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow(
    geometry,
    meta,
    axis,
    legend,
    tooltip,
    label,
    // éè¦å¨ interaction åé¢
    brushInteraction,
    slider,
    scrollbar,
    interaction,
    scatterAnnotation,
    animation,
    theme,
    regressionLine
  )(params);
}
