import { Action, IGroup, Util } from '@antv/g2';
import { get, last, isNil, size } from '@antv/util';
import { Data } from '../../types';
import { DrillDownCfg } from '../../types/drill-down';
import { deepAssign } from '../../utils/deep-assign';

// é¢åå±æå­ååå²ç¬¦'/'ä¹é´çè·ç¦»
const PADDING = 4;
// é¢åå±ä½ç½®è·ç¦»æ å¾çè·ç¦»
const PADDING_LEFT = 0;
// é¢åå±ä½ç½®è·ç¦»æ å¾çé¡¶é¨è·ç¦»
export const PADDING_TOP = 5;

/** Group name of breadCrumb: é¢åå± */
export const BREAD_CRUMB_NAME = 'drilldown-bread-crumb';

// é¢åå±é»è®¤éç½®
export const DEFAULT_BREAD_CRUMB_CONFIG: DrillDownCfg['breadCrumb'] = {
  /** ä½ç½®ï¼é»è®¤ï¼å·¦ä¸è§ */
  position: 'top-left',
  dividerText: '/',
  textStyle: {
    fontSize: 12,
    fill: 'rgba(0, 0, 0, 0.65)',
    cursor: 'pointer',
  },
  activeTextStyle: {
    fill: '#87B5FF',
  },
};

/**
 * hierarchy æ°æ®è½¬æ¢çåæ°
 */
export const HIERARCHY_DATA_TRANSFORM_PARAMS = 'hierarchy-data-transform-params';

/**
 * Hierarchy plot èç¹çæ°æ®
 */
export type HierarchyNode<N = any /** èç¹ */> = {
  /** èç¹çåå§æ°æ®ï¼æ åç»æï¼todo æ¯å¦æ´æ­£ key ä¸º originï¼ */
  data: { name: string; value?: any; children: { name: string; value?: any }[] };
  /** å¨æå»ºèç¹æ°æ®æ¶åï¼å¢å çæ©å±éç½®, ç¨äºå­å¨ transformData çå¥åéç½® */
  [HIERARCHY_DATA_TRANSFORM_PARAMS]: object;
  /** å½åçå±çº§ç»æï¼æ¯ä¸æ¬¡ä¸é»é½ä¼æ´æ°. ä¸æ¯ unique */
  depth: number;
  /** å½åæå¤é«åº¦ï¼depth + height = æ»çå±çº§ */
  height: number;
  parent: N;
  children: N[];
};

type HistoryCache = {
  name: string;
  id: string;
  children: Data;
}[];

/**
 * @description ä¸é»äº¤äºç action
 * @author liuzhenying
 *
 * éç¨äºï¼hierarchy plot
 */
export class DrillDownAction extends Action {
  /** Action name */
  public name = 'drill-down';

  // å­å¨åå²ä¸é»æ°æ®
  protected historyCache: HistoryCache = [];
  // é¢åå± group
  private breadCrumbGroup: IGroup = null;
  // é¢åå±åºç¡éç½®
  private breadCrumbCfg: DrillDownCfg['breadCrumb'] = DEFAULT_BREAD_CRUMB_CONFIG;

  /**
   * ç¹å»äºä»¶, ä¸é»æ°æ®ï¼å¹¶ç»å¶é¢åå±
   */
  public click() {
    const data = get(this.context, ['event', 'data', 'data']);
    if (!data) return false;

    this.drill(data);
    this.drawBreadCrumb();
  }

  /**
   * éç½®ä½ç½®ï¼åå§ååè§¦å chart  afterchangesize åè°æ¶ä½¿ç¨
   */
  public resetPosition() {
    // å½å¨ç¬¬ä¸å±çº§æªç»å¶é¢åå±ï¼æ­¤æ¶ changedata è§¦å resetPosition å½æ°ï¼éå¤æ­ this.breadCrumbGroup æ¯å¦å­å¨
    if (!this.breadCrumbGroup) return;
    const coordinate = this.context.view.getCoordinate();
    const breadCrumbGroup = this.breadCrumbGroup;
    const bbox = breadCrumbGroup.getBBox();

    const { position } = this.getButtonCfg();

    // @todo åç»­æ½åä¸ä¸ªå½æ°æ¥å¤çï¼ä»¥åå¢å  margin æè padding çè®¾ç½®
    // é polar çï¼éè¦ä½¿ç¨ coordinateï¼é¤å´å¾è¡¨ç»ä»¶
    let point = { x: coordinate.start.x, y: coordinate.end.y - (bbox.height + PADDING_TOP * 2) };
    if (coordinate.isPolar) {
      // é»è®¤ï¼å·¦ä¸è§ç´æ¥åºå
      point = { x: 0, y: 0 };
    }
    if (position === 'bottom-left') {
      // æ¶åå°åæ åè½¬çé®é¢
      point = { x: coordinate.start.x, y: coordinate.start.y };
    }
    /** PADDING_LEFT, PADDING_TOP ä¸ç»å¸è¾¹ç¼çè·ç¦» */
    const matrix = Util.transform(null, [['t', point.x + PADDING_LEFT, point.y + bbox.height + PADDING_TOP]]);
    breadCrumbGroup.setMatrix(matrix);
  }

  /**
   * è¿åä¸ä¸å±
   */
  public back(): void {
    if (size(this.historyCache)) {
      this.backTo(this.historyCache.slice(0, -1));
    }
  }

  /**
   * éç½®
   */
  public reset(): void {
    if (this.historyCache[0]) {
      this.backTo(this.historyCache.slice(0, 1));
    }
    // æ¸ç©º
    this.historyCache = [];
    this.hideCrumbGroup();
  }

  /**
   * ä¸é»æ°æ®å¹¶æ´æ° view æ¾ç¤ºå±
   * @param nodeInfo ä¸é»æ°æ®
   */
  protected drill(nodeInfo: HierarchyNode) {
    const { view } = this.context;
    const transformData = get(view, ['interactions', 'drill-down', 'cfg', 'transformData'], (v) => v);

    // éæ° update æ°æ®
    const drillData = transformData({ data: nodeInfo.data, ...nodeInfo[HIERARCHY_DATA_TRANSFORM_PARAMS] });
    view.changeData(drillData);

    // å­å¨åå²è®°å½
    const historyCache: HistoryCache = [];

    let node = nodeInfo;
    while (node) {
      const nodeData = node.data;
      historyCache.unshift({
        id: `${nodeData.name}_${node.height}_${node.depth}`,
        name: nodeData.name,
        // children æ¯å®éæ°æ®
        children: transformData({ data: nodeData, ...nodeInfo[HIERARCHY_DATA_TRANSFORM_PARAMS] }),
      });
      node = node.parent;
    }

    this.historyCache = (this.historyCache || []).slice(0, -1).concat(historyCache);
  }

  /**
   * åéäºä»¶ï¼ç¹å»é¢åå±æ¶è§¦å
   * @param historyCache å½åè¦åéå°çåå²
   */
  protected backTo(historyCache: HistoryCache) {
    if (!historyCache || historyCache.length <= 0) {
      return;
    }

    const { view } = this.context;
    const data = last(historyCache).children; // å¤çåçæ°ç»
    view.changeData(data);

    if (historyCache.length > 1) {
      this.historyCache = historyCache;
      this.drawBreadCrumb();
    } else {
      // æ¸ç©º
      this.historyCache = [];
      this.hideCrumbGroup();
    }
  }

  /**
   * è·å mix é»è®¤çéç½®åç¨æ·éç½®
   */
  private getButtonCfg() {
    const { view } = this.context;
    const drillDownConfig: DrillDownCfg = get(view, ['interactions', 'drill-down', 'cfg', 'drillDownConfig']);

    return deepAssign(this.breadCrumbCfg, drillDownConfig?.breadCrumb, this.cfg);
  }

  /**
   * æ¾ç¤ºé¢åå±
   */
  private drawBreadCrumb() {
    this.drawBreadCrumbGroup();
    this.resetPosition();
    this.breadCrumbGroup.show();
  }

  /**
   * ç»å¶ Button å ææ¬
   */
  private drawBreadCrumbGroup() {
    const config = this.getButtonCfg();
    const cache = this.historyCache;

    // åå§åé¢åå± group
    if (!this.breadCrumbGroup) {
      this.breadCrumbGroup = this.context.view.foregroundGroup.addGroup({
        name: BREAD_CRUMB_NAME,
      });
    } else {
      this.breadCrumbGroup.clear();
    }

    // ç»å¶é¢åå±
    let left = 0;
    cache.forEach((record, index) => {
      // æ·»å ææ¬
      const textShape = this.breadCrumbGroup.addShape({
        type: 'text',
        id: record.id,
        name: `${BREAD_CRUMB_NAME}_${record.name}_text`,
        attrs: {
          text: index === 0 && !isNil(config.rootText) ? config.rootText : record.name,
          ...config.textStyle,
          x: left,
          y: 0,
        },
      });

      const textShapeBox = textShape.getBBox();
      left += textShapeBox.width + PADDING;

      // å¢å ææ¬äºä»¶
      textShape.on('click', (event) => {
        const targetId = event.target.get('id');
        if (targetId !== last(cache)?.id) {
          const newHistoryCache = cache.slice(0, cache.findIndex((d) => d.id === targetId) + 1);
          this.backTo(newHistoryCache);
        }
      });
      // active ææåç½®
      textShape.on('mouseenter', (event) => {
        const targetId = event.target.get('id');
        if (targetId !== last(cache)?.id) {
          textShape.attr(config.activeTextStyle);
        } else {
          textShape.attr({ cursor: 'default' });
        }
      });
      textShape.on('mouseleave', () => {
        textShape.attr(config.textStyle);
      });

      if (index < cache.length - 1) {
        // æ·»å åææ 
        const dividerShape = this.breadCrumbGroup.addShape({
          type: 'text',
          name: `${config.name}_${record.name}_divider`,
          attrs: {
            text: config.dividerText,
            ...config.textStyle,
            x: left,
            y: 0,
          },
        });

        const dividerBox = dividerShape.getBBox();
        left += dividerBox.width + PADDING;
      }
    });
  }

  /**
   * éèé¢åå±
   */
  private hideCrumbGroup() {
    if (this.breadCrumbGroup) {
      this.breadCrumbGroup.hide();
    }
  }

  /**
   * @override
   * destroy: éæ¯èµæº
   */
  public destroy() {
    if (this.breadCrumbGroup) {
      this.breadCrumbGroup.remove();
    }
    super.destroy();
  }
}
