import { each } from '@antv/util';
import { COMPONENT_TYPE } from '../../constant';
import { ComponentOption, Padding } from '../../interface';
import { BBox } from '../../util/bbox';
import { isAutoPadding, parsePadding } from '../../util/padding';
import View from '../view';
import { PaddingCal } from './padding-cal';

/**
 * @ignore
 * æ ¹æ® view ä¸­çç»ä»¶ï¼è®¡ç®å®éç padding æ°å¼
 * @param view
 */
export function calculatePadding(view: View): PaddingCal {
  const padding = view.padding;

  // å¦æä¸æ¯ auto paddingï¼é£ä¹ç´æ¥è§£æä¹åè¿å
  if (!isAutoPadding(padding)) {
    return new PaddingCal(...parsePadding(padding));
  }

  // æ¯ auto paddingï¼æ ¹æ®ç»ä»¶çæåµï¼æ¥è®¡ç® padding
  const { viewBBox } = view;

  const paddingCal = new PaddingCal();

  const axisComponents = [];
  const paddingComponents = [];
  const otherComponents = [];

  each(view.getComponents(), (co: ComponentOption) => {
    const { type } = co;
    if (type === COMPONENT_TYPE.AXIS) {
      axisComponents.push(co);
    } else if ([COMPONENT_TYPE.LEGEND, COMPONENT_TYPE.SLIDER, COMPONENT_TYPE.SCROLLBAR].includes(type)) {
      paddingComponents.push(co);
    } else if (type !== COMPONENT_TYPE.GRID && type !== COMPONENT_TYPE.TOOLTIP) {
      otherComponents.push(co);
    }
  });

  // è¿è¡åæ è½´å¸å±ï¼åºè¯¥æ¯å padding çå¹¶éï¼èä¸æ¯è¿è¡ç¸å 
  each(axisComponents, (co: ComponentOption) => {
    const { component } = co;
    const bboxObject = component.getLayoutBBox();
    const componentBBox = new BBox(bboxObject.x, bboxObject.y, bboxObject.width, bboxObject.height);

    const exceed = componentBBox.exceed(viewBBox);

    // å¨å¯¹ç»ä»¶åç»ä¹åï¼åå¯¹ axis è¿è¡å¤çï¼ç¶ååæå¤§çè¶åºå³å¯ã
    paddingCal.max(exceed);
  });

  // æ padding çç»ä»¶å¸å±
  each(paddingComponents, (co: ComponentOption) => {
    const { component, direction } = co;
    const bboxObject = component.getLayoutBBox();
    const componentPadding: Padding = component.get('padding');
    const componentBBox = new BBox(bboxObject.x, bboxObject.y, bboxObject.width, bboxObject.height).expand(
      componentPadding
    );
    // æç§æ¹åè®¡ç® padding
    paddingCal.inc(componentBBox, direction);
  });

  // å¶ä»ç»ä»¶å¸å±
  each(otherComponents, (co: ComponentOption) => {
    const { component, direction } = co;
    const bboxObject = component.getLayoutBBox();
    const componentBBox = new BBox(bboxObject.x, bboxObject.y, bboxObject.width, bboxObject.height);
    // æç§æ¹åè®¡ç® padding
    paddingCal.inc(componentBBox, direction);
  });

  return paddingCal;
}
