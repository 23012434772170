import { assign } from '@antv/util';
import { IAction, IInteractionContext, LooseObject } from '../../interface';

/**
 * Action çåºç±»
 */
abstract class Action<T = LooseObject> implements IAction {
  /** Action åå­ */
  public name;
  /** ä¸ä¸æå¯¹è±¡ */
  public context: IInteractionContext;
  /** Action éç½® */
  protected cfg: T;
  /** éç½®é¡¹çå­æ®µï¼èªå¨è´å¼å° this ä¸ */
  protected cfgFields: string[];

  constructor(context: IInteractionContext, cfg?: T) {
    this.context = context;
    this.cfg = cfg;
    context.addAction(this);
  }

  /**
   * è®¾ç½®éç½®é¡¹ä¼ å¥çå¼
   * @param cfg
   */
  protected applyCfg(cfg) {
    assign(this, cfg);
  }

  /**
   * Inits actionï¼æä¾ç»å­ç±»ç¨äºç»§æ¿
   */
  public init() {
    this.applyCfg(this.cfg);
  }

  /**
   * Destroys action
   */
  public destroy() {
    // ç§»é¤ action
    this.context.removeAction(this);
    // æ¸ç©º
    this.context = null;
  }
}

export default Action;
