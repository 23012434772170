import { deepMix, each } from '@antv/util';
import Action from '../base';
import { LooseObject } from '../../../interface';

/**
 * @ignore
 * è¾å©æ¡ Action çåºç±»
 */
abstract class MaskBase extends Action {
  // mask å¾å½¢
  protected maskShape = null;
  // ç»æ mask çåä¸ªç¹
  protected points = [];
  // å¼å§ mask çæ è®°
  protected starting = false;
  // å¼å§ç§»å¨çæ è®°
  protected moving = false;
  protected preMovePoint = null;

  protected shapeType = 'path';
  // è·åå½åçä½ç½®
  protected getCurrentPoint() {
    const event = this.context.event;
    return {
      x: event.x,
      y: event.y,
    };
  }
  // è§¦å mask çäºä»¶
  protected emitEvent(type) {
    const eventName = `mask:${type}`;
    const view = this.context.view;
    const event = this.context.event;
    view.emit(eventName, {
      target: this.maskShape,
      shape: this.maskShape,
      points: this.points,
      x: event.x,
      y: event.y,
    });
  }

  // åå»º mask
  private createMask() {
    const view = this.context.view;
    const maskAttrs = this.getMaskAttrs();
    const maskShape = view.foregroundGroup.addShape({
      type: this.shapeType,
      name: 'mask',
      draggable: true,
      attrs: {
        fill: '#C5D4EB',
        opacity: 0.3,
        ...maskAttrs,
      },
    });
    return maskShape;
  }

  protected abstract getMaskAttrs(): LooseObject;

  // çæ mask çè·¯å¾
  protected getMaskPath() {
    return [];
  }

  /**
   * æ¾ç¤º
   */
  public show() {
    if (this.maskShape) {
      this.maskShape.show();
      this.emitEvent('show');
    }
  }

  /**
   * å¼å§
   */
  public start(arg?: { maskStyle: LooseObject }) {
    this.starting = true;
    // å¼å§æ¶ï¼ä¿è¯ç§»å¨ç»æ
    this.moving = false;
    this.points = [this.getCurrentPoint()];
    if (!this.maskShape) {
      this.maskShape = this.createMask();
      // å¼å§æ¶è®¾ç½® capture: falseï¼å¯ä»¥é¿ååå»ºãresize æ¶è§¦åäºä»¶
      this.maskShape.set('capture', false);
    }
    this.updateMask(arg?.maskStyle);
    this.emitEvent('start');
  }

  /**
   * å¼å§ç§»å¨
   */
  public moveStart() {
    this.moving = true;
    this.preMovePoint = this.getCurrentPoint();
  }
  /**
   * ç§»å¨ mask
   */
  public move() {
    if (!this.moving || !this.maskShape) {
      return;
    }
    const currentPoint = this.getCurrentPoint();
    const preMovePoint = this.preMovePoint;
    const dx = currentPoint.x - preMovePoint.x;
    const dy = currentPoint.y - preMovePoint.y;
    const points = this.points;
    each(points, (point) => {
      point.x += dx;
      point.y += dy;
    });
    this.updateMask();
    this.emitEvent('change');
    this.preMovePoint = currentPoint;
  }

  protected updateMask(maskStyle?: LooseObject) {
    const attrs = deepMix({}, this.getMaskAttrs(), maskStyle);
    this.maskShape.attr(attrs);
  }

  /**
   * ç»æç§»å¨
   */
  public moveEnd() {
    this.moving = false;
    this.preMovePoint = null;
  }

  /**
   * ç»æ
   */
  public end() {
    this.starting = false;
    this.emitEvent('end');
    if (this.maskShape) {
      this.maskShape.set('capture', true);
    }
  }

  /**
   * éè
   */
  public hide() {
    if (this.maskShape) {
      this.maskShape.hide();
      this.emitEvent('hide');
    }
  }

  /**
   * å¤§å°åå
   */
  public resize() {
    // åªæè¿è¡ä¸­ï¼æä¼åè®¸å¤§å°åå
    if (this.starting && this.maskShape) {
      this.points.push(this.getCurrentPoint());
      this.updateMask();
      this.emitEvent('change');
    }
  }

  /**
   * éæ¯
   */
  public destroy() {
    this.points = [];
    if (this.maskShape) {
      this.maskShape.remove();
    }
    this.maskShape = null;
    this.preMovePoint = null;
    super.destroy();
  }
}

export default MaskBase;
