import { CirclePackingOptions } from './types';

/** é»è®¤çæºå­æ®µ */
export const RAW_FIELDS = ['x', 'y', 'r', 'name', 'value', 'path', 'depth'];

export const DEFAULT_OPTIONS: Partial<CirclePackingOptions> = {
  // é»è®¤æç§ name å­æ®µå¯¹é¢è²è¿è¡åç±»
  colorField: 'name',
  autoFit: true,
  pointStyle: {
    lineWidth: 0,
    stroke: '#fff',
  },
  // é»è®¤ä¸å¼å¯å¾ä¾
  legend: false,
  hierarchyConfig: {
    size: [1, 1] as [number, number], // width, height
    padding: 0,
  },
  label: {
    fields: ['name'],
    layout: {
      type: 'limit-in-shape',
    },
  },
  tooltip: {
    showMarkers: false,
    showTitle: false,
  },
  // é»è®¤ä¸å¯ä»¥ä¸é»
  drilldown: { enabled: false },
};
