import { registerShape, Types } from '@antv/g2';
import { Indicator } from '../types';

// èªå®ä¹Shape é¨å
registerShape('point', 'gauge-indicator', {
  draw(cfg: Types.ShapeInfo, container) {
    // ä½¿ç¨ customInfo ä¼ éåæ°
    const { indicator, defaultColor } = cfg.customInfo;
    const { pointer, pin } = indicator as Indicator;

    const group = container.addGroup();
    // è·åæåæ ç³»ä¸ç»å¸ä¸­å¿ç¹
    const center = this.parsePoint({ x: 0, y: 0 });
    // ç»å¶æé
    if (pointer) {
      // pointer
      group.addShape('line', {
        name: 'pointer',
        attrs: {
          x1: center.x,
          y1: center.y,
          x2: cfg.x,
          y2: cfg.y,
          stroke: defaultColor,
          ...pointer.style,
        },
      });
    }

    // pin
    if (pin) {
      group.addShape('circle', {
        name: 'pin',
        attrs: {
          x: center.x,
          y: center.y,
          stroke: defaultColor,
          ...pin.style,
        },
      });
    }
    return group;
  },
});
