import { LinePatternCfg } from '../../types/pattern';
import { deepAssign } from '../../utils';
import { initCanvas, drawBackground, transformMatrix, getPixelRatio } from './util';

/**
 * linePattern ç é»è®¤éç½®
 */
export const defaultLinePatternCfg = {
  rotation: 45,
  spacing: 5,
  opacity: 1,
  backgroundColor: 'transparent',
  strokeOpacity: 0.5,
  stroke: '#fff',
  lineWidth: 2,
};

/**
 * ç»å¶line
 *
 * @param context canvasContext
 * @param cfg linePattern çéç½®
 * @param d ç»å¶ path æéç d
 */
export function drawLine(context: CanvasRenderingContext2D, cfg: LinePatternCfg, d: string) {
  const { stroke, lineWidth, strokeOpacity } = cfg;
  const path = new Path2D(d);

  context.globalAlpha = strokeOpacity;
  context.lineCap = 'square';
  context.strokeStyle = lineWidth ? stroke : 'transparent';
  context.lineWidth = lineWidth;
  context.stroke(path);
}

/**
 * åå»º linePattern
 */
export function createLinePattern(cfg?: LinePatternCfg): CanvasPattern {
  const lineCfg = deepAssign({}, defaultLinePatternCfg, cfg);

  const { spacing, rotation, lineWidth } = lineCfg;

  // è®¡ç® pattern ç»å¸çå¤§å°ï¼ path æéç d
  const width = spacing + lineWidth || 1;
  const height = spacing + lineWidth || 1;
  const d = `
            M 0 0 L ${width} 0
            M 0 ${height} L ${width} ${height}
            `;

  // åå§å patternCanvas
  const canvas = initCanvas(width, height);
  const ctx = canvas.getContext('2d');

  // ç»å¶ backgroundï¼line
  drawBackground(ctx, lineCfg, width, height);
  drawLine(ctx, lineCfg, d);

  const pattern = ctx.createPattern(canvas, 'repeat');

  if (pattern) {
    const dpr = getPixelRatio();
    const matrix = transformMatrix(dpr, rotation);
    pattern.setTransform(matrix);
  }

  // è¿å Pattern å¯¹è±¡
  return pattern;
}
