import { isNil } from '@antv/util';
import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { AnimateExtraCfg } from '../interface';

/**
 * @ignore
 * åä¸ª shape å¨ç»
 * æ¸ç°å¨ç»
 * @param shape æ§è¡å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function fadeIn(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const endState = {
    fillOpacity: isNil(shape.attr('fillOpacity')) ? 1 : shape.attr('fillOpacity'),
    strokeOpacity: isNil(shape.attr('strokeOpacity')) ? 1 : shape.attr('strokeOpacity'),
    opacity: isNil(shape.attr('opacity')) ? 1 : shape.attr('opacity'),
  };
  shape.attr({
    fillOpacity: 0,
    strokeOpacity: 0,
    opacity: 0,
  });
  shape.animate(endState, animateCfg);
}

/**
 * @ignore
 * åä¸ª shape å¨ç»
 * æ¸éå¨ç»
 * @param shape æ§è¡å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function fadeOut(shape: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const endState = {
    fillOpacity: 0,
    strokeOpacity: 0,
    opacity: 0,
  };
  const { easing, duration, delay } = animateCfg;
  shape.animate(
    endState,
    duration,
    easing,
    () => {
      shape.remove(true);
    },
    delay
  );
}
