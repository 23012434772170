import { Params } from '../../core/adaptor';
import { getTooltipMapping } from '../../utils/tooltip';
import { deepAssign } from '../../utils';
import { geometry, MappingOptions, GeometryOptions } from './base';

export interface ViolinGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µï¼æå°æç´çYè½´ï¼å³æ¦çå¯åº¦ï¼ */
  readonly yField?: string;
  /** åç»å­æ®µ */
  readonly seriesField?: string;
  /** size æ å°å­æ®µ */
  readonly sizeField?: string;
  /** violin å¾å½¢æ å°è§å */
  readonly violin?: MappingOptions;
}

/**
 * violin è¾å©ç¹çéç½®å¤ç
 * @param params
 */
export function violin<O extends ViolinGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const { violin, xField, yField, seriesField, sizeField, tooltip } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField, sizeField]);

  return violin
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'violin',
            colorField: seriesField,
            tooltipFields: fields,
            mapping: {
              tooltip: formatter,
              ...violin,
            },
          },
        })
      )
    : params;
}
