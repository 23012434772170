import { each, get } from '@antv/util';
import { View } from '../chart';
import { BBox, IShape, Point } from '../dependents';
import { IAction, IInteractionContext, LooseObject } from '../interface';
import { getComponents, isInBox } from './action/util';

/**
 * äº¤äºçä¸ä¸æ
 */
export default class Context implements IInteractionContext {
  /** å½åææç Action */
  public actions: IAction[] = [];
  /** å½å View å®ä¾ */
  public view: View;
  /** å½åäºä»¶å¯¹è±¡ */
  public event: LooseObject = null;

  private cacheMap: LooseObject = {};

  constructor(view: View) {
    this.view = view;
  }

  /**
   * ç¼å­ä¿¡æ¯
   * @param params ç¼å­çå­æ®µ
   *  - å¦æä¸ä¸ªå­æ®µåè·åç¼å­
   *  - ä¸¤ä¸ªå­æ®µåè®¾ç½®ç¼å­
   */
  public cache(...params) {
    if (params.length === 1) {
      return this.cacheMap[params[0]];
    } else if (params.length === 2) {
      this.cacheMap[params[0]] = params[1];
    }
  }

  /**
   * è·å Action
   * @param name Action çåç§°
   */
  public getAction(name: string): IAction {
    return this.actions.find((action) => action.name === name);
  }

  /**
   * è·å Action
   * @param action Action å¯¹è±¡
   */
  public addAction(action: IAction) {
    this.actions.push(action);
  }

  /**
   * ç§»é¤ Action
   * @param action Action å¯¹è±¡
   */
  public removeAction(action: IAction) {
    const actions = this.actions;
    const index = this.actions.indexOf(action);
    if (index >= 0) {
      actions.splice(index, 1);
    }
  }

  /**
   * è·åå½åçç¹
   */
  public getCurrentPoint(): Point {
    const event = this.event;
    if (event) {
      if (event.target instanceof HTMLElement) {
        const canvas = this.view.getCanvas();
        const point = canvas.getPointByClient(event.clientX, event.clientY);
        return point;
      } else {
        return {
          x: event.x,
          y: event.y,
        };
      }
    }
    return null;
  }

  /**
   * è·åå½å shape
   * @returns current shape
   */
  public getCurrentShape(): IShape {
    return get(this.event, ['gEvent', 'shape']);
  }

  /**
   * å½åçè§¦åæ¯å¦å¨ View å
   */
  public isInPlot() {
    const point = this.getCurrentPoint();
    if (point) {
      return this.view.isPointInPlot(point);
    }
    return false;
  }

  /**
   * æ¯å¦å¨æå®çå¾å½¢å
   * @param name shape ç name
   */
  public isInShape(name) {
    const shape = this.getCurrentShape(); // ä¸åèèå¨ shape ç parent åçæåµ
    if (shape) {
      return shape.get('name') === name;
    }
    return false;
  }

  /**
   * å½åçè§¦åæ¯ç»ä»¶åé¨
   * @param name ç»ä»¶åï¼å¯ä»¥ä¸ºç©º
   */
  public isInComponent(name?: string) {
    const components = getComponents(this.view);
    const point = this.getCurrentPoint();
    if (point) {
      return !!components.find((component) => {
        const bbox = component.getBBox() as BBox;
        if (name) {
          return component.get('name') === name && isInBox(bbox, point);
        } else {
          return isInBox(bbox, point);
        }
      });
    }
    return false;
  }

  /**
   * éæ¯
   */
  public destroy() {
    // åéæ¯ action åæ¸ç©ºï¼ä¸è¾¹éåï¼ä¸è¾¹å é¤ï¼æä»¥æ°ç»éè¦æ´æ°å¼ç¨
    each(this.actions.slice(), (action) => {
      action.destroy();
    });
    this.view = null;
    this.event = null;
    this.actions = null;
    this.cacheMap = null;
  }
}
