import { Geometry } from '@antv/g2';
import { get, isNil } from '@antv/util';
import { interaction, animation, theme, scale, pattern } from '../../adaptor/common';
import { Params } from '../../core/adaptor';
import { flow, deepAssign, renderStatistic } from '../../utils';
import { interval } from '../../adaptor/geometries';
import { LiquidOptions, CustomInfo } from './types';
import { getLiquidData } from './utils';

/**
 * geometry å¤ç
 * @param params
 */
function geometry(params: Params<LiquidOptions>): Params<LiquidOptions> {
  const { chart, options } = params;
  const { percent, liquidStyle, radius, outline, wave, shape, animation } = options;

  chart.scale({
    percent: {
      min: 0,
      max: 1,
    },
  });

  chart.data(getLiquidData(percent));

  const color = options.color || chart.getTheme().defaultColor;

  const p = deepAssign({}, params, {
    options: {
      xField: 'type',
      yField: 'percent',
      // radius æ¾å° columnWidthRatio ä¸­ã
      // ä¿è¯æ¨ªåçå¤§å°æ¯æ ¹æ®  radius çæç
      widthRatio: radius,
      interval: {
        color,
        style: liquidStyle,
        shape: 'liquid-fill-gauge',
      },
    },
  });
  const { ext } = interval(p);
  const geometry = ext.geometry as Geometry;
  const { background } = chart.getTheme();
  const customInfo: CustomInfo = {
    radius,
    outline,
    wave,
    shape,
    background,
    animation,
  };

  // å° radius ä¼ å¥å°èªå®ä¹ shape ä¸­
  geometry.customInfo(customInfo);

  // å³é­ç»ä»¶
  chart.legend(false);
  chart.axis(false);
  chart.tooltip(false);

  return params;
}

/**
 * ç»è®¡ææ ææ¡£
 * @param params
 */
export function statistic(params: Params<LiquidOptions>, updated?: boolean): Params<LiquidOptions> {
  const { chart, options } = params;
  const { statistic, percent, meta } = options;

  // åæ¸ç©ºæ æ³¨ï¼åéæ°æ¸²æ
  chart.getController('annotation').clear(true);

  const metaFormatter = get(meta, ['percent', 'formatter']) || ((v) => `${(v * 100).toFixed(2)}%`);
  let contentOpt = statistic.content;
  if (contentOpt) {
    contentOpt = deepAssign({}, contentOpt, {
      content: !isNil(contentOpt.content) ? contentOpt.content : metaFormatter(percent),
    });
  }

  renderStatistic(chart, { statistic: { ...statistic, content: contentOpt }, plotType: 'liquid' }, { percent });

  if (updated) {
    chart.render(true);
  }

  return params;
}

/**
 * æ°´æ³¢å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<LiquidOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API (ä¸»é¢åç½®ï¼ä¼å½±åç»å¶çåè²)
  return flow(theme, pattern('liquidStyle'), geometry, statistic, scale({}), animation, interaction)(params);
}
