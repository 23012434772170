import { PatternCfg } from '../../types/pattern';

/**
 * è·åè®¾å¤åç´ æ¯
 */
export function getPixelRatio(): number {
  return typeof window === 'object' ? window?.devicePixelRatio : 2;
}

/**
 * åå§å cavnasï¼è®¾ç½®å®½é«ç­
 */
export function initCanvas(width: number, height: number = width): HTMLCanvasElement {
  const canvas = document.createElement('canvas');

  const pixelRatio = getPixelRatio();
  // ç»å¸å°ºå¯¸
  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;
  // æ¾ç¤ºå°ºå¯¸
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;

  const ctx = canvas.getContext('2d');
  ctx.scale(pixelRatio, pixelRatio);

  return canvas;
}

/**
 * ç»å¶èæ¯
 *
 * @param context
 * @param cfg
 * @param width
 * @param height
 */
export function drawBackground(
  context: CanvasRenderingContext2D,
  cfg: PatternCfg,
  width: number,
  height: number = width
) {
  const { backgroundColor, opacity } = cfg;

  context.globalAlpha = opacity;
  context.fillStyle = backgroundColor;

  context.beginPath();
  context.fillRect(0, 0, width, height);
  context.closePath();
}

/**
 * è®¡ç®è´´å¾ååå¤§å°
 *
 * @param size åç´ å¤§å°
 * @param padding åç¹é´é
 * @param isStagger æ¯å¦äº¤é
 * @reutrn è¿åè´´å¾ååå¤§å°
 */
export function getUnitPatternSize(size: number, padding: number, isStagger: boolean): number {
  // å¦æäº¤é, unitSize æ¾å¤§ä¸¤å
  const unitSize = size + padding;
  return isStagger ? unitSize * 2 : unitSize;
}

/**
 * è®¡ç®æäº¤éæåµçåç´ åæ 
 *
 * @param unitSize è´´å¾ååå¤§å°
 * @param isStagger æ¯å¦äº¤é
 * @reutrn åç´ ä¸­å¿åæ  x,y æ°ç»éå
 */
export function getSymbolsPosition(unitSize: number, isStagger: boolean): number[][] {
  // å¦æäº¤é, äº¤éç»å¶ dot
  const symbolsPos = isStagger
    ? [
        [unitSize * (1 / 4), unitSize * (1 / 4)],
        [unitSize * (3 / 4), unitSize * (3 / 4)],
      ]
    : [[unitSize * (1 / 2), unitSize * (1 / 2)]];
  return symbolsPos;
}

/**
 * ç»æ´ä¸ª patternè´´å¾ ååæ¢, ç®åæ¯ææè½¬
 *
 * @param pattern æ´ä¸ªè´´å¾
 * @param dpr  è®¾å¤åç´ æ¯
 * @param rotation æè½¬è§åº¦
 */
export function transformMatrix(dpr: number, rotation: number) {
  const radian = (rotation * Math.PI) / 180;
  const matrix = {
    a: Math.cos(radian) * (1 / dpr),
    b: Math.sin(radian) * (1 / dpr),
    c: -Math.sin(radian) * (1 / dpr),
    d: Math.cos(radian) * (1 / dpr),
    e: 0,
    f: 0,
  };
  return matrix;
}
