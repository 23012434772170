import { each, isArray, isFunction, isString, debounce, throttle } from '@antv/util';
import { View } from '../chart';
import { ActionCallback, IAction, IInteractionContext, LooseObject } from '../interface';
import { createAction, createCallbackAction } from './action/register';
import InteractionContext from './context';
import Interaction from './interaction';

// å°å­ç¬¦ä¸²è½¬æ¢æ action
export function parseAction(actionStr: string, context: IInteractionContext, arg?: any): ActionObject {
  const arr = actionStr.split(':');
  const actionName = arr[0];
  // å¦æå·²ç»åå§åè¿ action ï¼åç´æ¥å¼ç¨ä¹åç action
  const action = context.getAction(actionName) || createAction(actionName, context);
  if (!action) {
    throw new Error(`There is no action named ${actionName}`);
  }
  const methodName = arr[1];
  return {
    action,
    methodName,
    arg,
  };
}

// æ§è¡ Action
function executeAction(actionObject: ActionObject) {
  const { action, methodName, arg } = actionObject;
  if (action[methodName]) {
    action[methodName](arg);
  } else {
    throw new Error(`Action(${action.name}) doesn't have a method called ${methodName}`);
  }
}

const STEP_NAMES = {
  START: 'start',
  SHOW_ENABLE: 'showEnable',
  END: 'end',
  ROLLBACK: 'rollback',
  PROCESSING: 'processing',
};

/** äº¤äºç¯èçå®ä¹ */
export interface InteractionStep {
  /**
   * è§¦åäºä»¶ï¼æ¯æ viewï¼chart çåç§äºä»¶ï¼ä¹æ¯æ documentãwindow çäºä»¶
   */
  trigger: string;
  /**
   * æ¯å¦å¯ä»¥è§¦å action
   * @param context - äº¤äºçä¸ä¸æ
   */
  isEnable?: (context: IInteractionContext) => boolean;
  /**
   * åé¦ï¼æ¯æä¸ç§æ¹å¼ï¼
   * - action:method : action çåå­åæ¹æ³çç»å
   * - [âaction1:method1â, âaction2:methodâ]
   * - ActionCallback: åè°å½æ°
   */
  action: string | string[] | ActionCallback;
  /**
   * åé¦ï¼å·ä½ action method çåæ°ï¼
   * - å½ä¼ éå¤ä¸ª action æ¶ï¼args å¿é¡»æ¯ä¸ä¸ªæ°ç»
   */
  arg?: any | any[];
  /**
   * åè°å½æ°ï¼action æ§è¡åæ§è¡
   */
  callback?: (context: IInteractionContext) => void;
  /**
   * @private
   * ä¸éè¦ç¨æ·ä¼ å¥ï¼éè¿ä¸é¢çå±æ§è®¡ç®åºæ¥çå±æ§
   */
  actionObject?: ActionObject | ActionObject[];
  /**
   * å¨ä¸ä¸ªç¯èåæ¯å¦åªåè®¸æ§è¡ä¸æ¬¡
   */
  once?: boolean;
  /**
   * æ¯å¦å¢å èæµ
   */
  throttle?: ThrottleOption;
  /**
   * æ¯å¦å»¶è¿
   */
  debounce?: DebounceOption;
}

// action æ§è¡æ¶æ¯æ debounce å throttleï¼å¯ä»¥åèï¼https://css-tricks.com/debouncing-throttling-explained-examples/
/**
 * debounce çéç½®
 */
export interface DebounceOption {
  /**
   * ç­å¾æ¶é´
   */
  wait: number;
  /**
   * æ¯å¦é©¬ä¸æ§è¡
   */
  immediate?: boolean;
}

/**
 * throttle çéç½®
 */
export interface ThrottleOption {
  /**
   * ç­å¾æ¶é´
   */
  wait: number;
  /**
   * é©¬ä¸å°±æ§è¡
   */
  leading?: boolean;
  /**
   * æ§è¡å®æ¯ååæ§è¡ä¸æ¬¡
   */
  trailing?: boolean;
}

/** ç¼å­ action å¯¹è±¡ï¼ä»ç¨äºå½åæä»¶ */
interface ActionObject {
  /**
   * ç¼å­ç action
   */
  action: IAction;
  /**
   * action çæ¹æ³
   */
  methodName: string;
  /**
   * ç¨æ·ä¼ éç action æ¹æ³çåæ°
   */
  arg?: any;
}

/** äº¤äºçææç¯è */
export interface InteractionSteps {
  /**
   * æ¾ç¤ºäº¤äºå¯ä»¥è¿è¡
   */
  showEnable?: InteractionStep[];
  /**
   * äº¤äºå¼å§
   */
  start?: InteractionStep[];
  /**
   * äº¤äºæç»­
   */
  processing?: InteractionStep[];
  /**
   * äº¤äºç»æ
   */
  end?: InteractionStep[];
  /**
   * äº¤äºåæ»
   */
  rollback?: InteractionStep[];
}

/**
 * æ¯æè¯­æ³çäº¤äºç±»
 */
export default class GrammarInteraction extends Interaction {
  // å­å¨çäº¤äºç¯è
  private steps: InteractionSteps;
  /** å½åæ§è¡å°çé¶æ®µ */
  public currentStepName: string;
  /**
   * å½åäº¤äºçä¸ä¸æ
   */
  public context: IInteractionContext;

  private callbackCaches: LooseObject = {};
  // æä¸ªè§¦åååé¦å¨æ¬ç¯èæ¯å¦æ§è¡æ
  private emitCaches: LooseObject = {};

  constructor(view: View, steps: InteractionSteps) {
    super(view, steps);
    this.steps = steps;
  }

  /**
   * åå§å
   */
  public init() {
    this.initContext();
    super.init();
  }

  /**
   * æ¸çèµæº
   */
  public destroy() {
    super.destroy(); // åæ¸çäºä»¶
    this.steps = null;
    if (this.context) {
      this.context.destroy();
      this.context = null;
    }

    this.callbackCaches = null;
    this.view = null;
  }

  /**
   * ç»å®äºä»¶
   */
  protected initEvents() {
    each(this.steps, (stepArr, stepName) => {
      each(stepArr, (step) => {
        const callback = this.getActionCallback(stepName, step);
        if (callback) {
          // å¦æå­å¨ callbackï¼æç»å®ï¼ææ¶åä¼åºç°æ  callback çæåµ
          this.bindEvent(step.trigger, callback);
        }
      });
    });
  }

  /**
   * æ¸çç»å®çäºä»¶
   */
  protected clearEvents() {
    each(this.steps, (stepArr, stepName) => {
      each(stepArr, (step) => {
        const callback = this.getActionCallback(stepName, step);
        if (callback) {
          this.offEvent(step.trigger, callback);
        }
      });
    });
  }

  // åå§åä¸ä¸æï¼å¹¶åå§å action
  private initContext() {
    const view = this.view;
    const context = new InteractionContext(view);
    this.context = context;
    const steps = this.steps;
    // çæå·ä½ç Action
    each(steps, (subSteps: InteractionStep[]) => {
      each(subSteps, (step: InteractionStep) => {
        if (isFunction(step.action)) {
          // å¦æä¼ å¥åè°å½æ°ï¼åç´æ¥çæ CallbackAction
          step.actionObject = {
            action: createCallbackAction(step.action, context),
            methodName: 'execute',
          };
        } else if (isString(step.action)) {
          // å¦ææ¯å­ç¬¦ä¸²
          step.actionObject = parseAction(step.action, context, step.arg);
        } else if (isArray(step.action)) {
          // å¦ææ¯æ°ç»
          const actionArr = step.action;
          const argArr = isArray(step.arg) ? step.arg : [step.arg];
          step.actionObject = [];
          each(actionArr, (actionStr, idx) => {
            (step.actionObject as ActionObject[]).push(parseAction(actionStr, context, argArr[idx]));
          });
        }
        // å¦æ action æ¢ä¸æ¯å­ç¬¦ä¸²ï¼ä¹ä¸æ¯å½æ°ï¼åä¸ä¼çæ actionObject
      });
    });
  }

  // æ¯å¦åè®¸æå®é¶æ®µåç§°æ§è¡
  private isAllowStep(stepName: string): boolean {
    const currentStepName = this.currentStepName;
    const steps = this.steps;
    // ç¸åçé¶æ®µåè®¸åæ¶æ§è¡
    if (currentStepName === stepName) {
      return true;
    }

    if (stepName === STEP_NAMES.SHOW_ENABLE) {
      // ç¤ºè½å¨æ´ä¸ªè¿ç¨ä¸­é½å¯ç¨
      return true;
    }

    if (stepName === STEP_NAMES.PROCESSING) {
      // åªæå½åæ¯ start æ¶ï¼æåè®¸ processing
      return currentStepName === STEP_NAMES.START;
    }

    if (stepName === STEP_NAMES.START) {
      // å¦æå½åæ¯ processingï¼åæ æ³ startï¼å¿é¡»ç­å¾ end åæè½æ§è¡
      return currentStepName !== STEP_NAMES.PROCESSING;
    }

    if (stepName === STEP_NAMES.END) {
      return currentStepName === STEP_NAMES.PROCESSING || currentStepName === STEP_NAMES.START;
    }

    if (stepName === STEP_NAMES.ROLLBACK) {
      if (steps[STEP_NAMES.END]) {
        // å¦æå®ä¹äº end, åªæ end æ¶æåè®¸åæ»
        return currentStepName === STEP_NAMES.END;
      } else if (currentStepName === STEP_NAMES.START) {
        // å¦ææªå®ä¹ end, åå¤æ­æ¯å¦æ¯å¼å§
        return true;
      }
    }
    return false;
  }

  // å·ä½çæå®é¶æ®µæ¯å¦åè®¸æ§è¡
  private isAllowExecute(stepName: string, step: InteractionStep): boolean {
    if (this.isAllowStep(stepName)) {
      const key = this.getKey(stepName, step);
      // å¦ææ¯å¨æ¬ç¯èåä»åè®¸è§¦åä¸æ¬¡ï¼åæ¶å·²ç»è§¦åè¿ï¼åä¸åè®¸åè§¦å
      if (step.once && this.emitCaches[key]) {
        return false;
      }
      // å¦ææ¯åè®¸çé¶æ®µï¼åéªè¯ isEnable æ¹æ³
      if (step.isEnable) {
        return step.isEnable(this.context);
      }
      return true; // å¦ææ²¡æ isEnable ååè®¸æ§è¡
    }
    return false;
  }

  private enterStep(stepName: string) {
    this.currentStepName = stepName;
    this.emitCaches = {}; // æ¸é¤æææ¬ç¯èè§¦åçç¼å­
  }

  // æ§è¡å®æä¸ªè§¦åååé¦ï¼å­ç¯èï¼
  private afterExecute(stepName: string, step) {
    // show enable ä¸è®¡å¥æ­£å¸¸çæµç¨ï¼å¶ä»æåµåè®¾ç½®å½åç step
    if (stepName !== STEP_NAMES.SHOW_ENABLE && this.currentStepName !== stepName) {
      this.enterStep(stepName);
    }
    const key = this.getKey(stepName, step);
    // ä¸æ¦æ§è¡ï¼åç¼å­æ è®°ä¸ºï¼ä¸ç´ä¿æå°è·³åºæ¹ç¯è
    this.emitCaches[key] = true;
  }
  // è·åæä¸ªç¯èçå¯ä¸çé®å¼
  private getKey(stepName, step) {
    return stepName + step.trigger + step.action;
  }

  // è·å step çåè°å½æ°ï¼å¦æå·²ç»çæï¼åç´æ¥è¿åï¼å¦ææªçæï¼ååå»º
  private getActionCallback(stepName: string, step: InteractionStep): (e: object) => void {
    const context = this.context;
    const callbackCaches = this.callbackCaches;
    const actionObject = step.actionObject;
    if (step.action && actionObject) {
      const key = this.getKey(stepName, step);
      if (!callbackCaches[key]) {
        // å¨æçææ§è¡çæ¹æ³ï¼æ§è¡å¯¹åº action çåç§°
        const actionCallback = (event) => {
          context.event = event; // ä¿è¯æ£æµæ¶ç event
          if (this.isAllowExecute(stepName, step)) {
            // å¦ææ¯æ°ç»æ¶ï¼åä¾æ¬¡æ§è¡
            if (isArray(actionObject)) {
              each(actionObject, (obj: ActionObject) => {
                context.event = event; // å¯è½è§¦åæ°çäºä»¶ï¼ä¿è¯æ§è¡åç context.event æ¯æ­£ç¡®ç
                executeAction(obj);
              });
            } else {
              context.event = event; // ä¿è¯æ§è¡åç context.event æ¯æ­£ç¡®ç
              executeAction(actionObject);
            }
            this.afterExecute(stepName, step);
            if (step.callback) {
              context.event = event; // ä¿è¯æ§è¡åç context.event æ¯æ­£ç¡®ç
              step.callback(context);
            }
          } else {
            // å¦ææªéè¿éªè¯ï¼åäºä»¶ä¸è¦ç»å®å¨ä¸é¢
            context.event = null;
          }
        };
        // å¦æè®¾ç½®äº debounce
        if (step.debounce) {
          callbackCaches[key] = debounce(actionCallback, step.debounce.wait, step.debounce.immediate);
        } else if (step.throttle) {
          // è®¾ç½® throttle
          callbackCaches[key] = throttle(actionCallback, step.throttle.wait, {
            leading: step.throttle.leading,
            trailing: step.throttle.trailing,
          });
        } else {
          // ç´æ¥è®¾ç½®
          callbackCaches[key] = actionCallback;
        }
      }
      return callbackCaches[key];
    }
    return null;
  }

  private bindEvent(eventName, callback) {
    const nameArr = eventName.split(':');
    if (nameArr[0] === 'window') {
      window.addEventListener(nameArr[1], callback);
    } else if (nameArr[0] === 'document') {
      document.addEventListener(nameArr[1], callback);
    } else {
      this.view.on(eventName, callback);
    }
  }

  private offEvent(eventName, callback) {
    const nameArr = eventName.split(':');
    if (nameArr[0] === 'window') {
      window.removeEventListener(nameArr[1], callback);
    } else if (nameArr[0] === 'document') {
      document.removeEventListener(nameArr[1], callback);
    } else {
      this.view.off(eventName, callback);
    }
  }
}
