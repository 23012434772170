import ElementRangeState from './range-state';

/**
 * @ignore
 * å¾è¡¨åç´ åºå Active ç Action
 */
class ElementRangeActive extends ElementRangeState {
  protected stateName: string = 'active';
  /**
   * å¾è¡¨åç´  Active
   */
  public active() {
    this.setState();
  }
}

export default ElementRangeActive;
