import { isNumber } from '@antv/util';
import { vec2 } from '@antv/matrix-util';
import { BBox, Point } from '../dependents';

const { dot } = vec2;
type Vec2 = [number, number];

type Box = Pick<BBox, 'x' | 'y' | 'width' | 'height'> & { rotation?: number };

/**
 * å®ä¹æå½±å¯¹è±¡
 */
type Projection = { min: number; max: number };

/**
 * @private
 * 1. è·åæå½±è½´
 */
function getAxes(points: Point[] /** å¤è¾¹å½¢çå³é®ç¹ */): Vec2[] {
  // ç®ååå¤ç å¹³è¡ç©å½¢ çåºæ¯, å¶ä»å¤è¾¹å½¢ä¸å¤ç
  if (points.length > 4) {
    return [];
  }
  // è·ååé
  const vector = (start: Point, end: Point): Vec2 => {
    return [end.x - start.x, end.y - start.y];
  };

  // ç±äº ç©å½¢çå¹³è¡åçï¼æä»¥åªæ 2 æ¡æå½±è½´: A -> B, B -> C
  const AB = vector(points[0], points[1]);
  const BC = vector(points[1], points[2]);

  return [AB, BC];
}

/**
 * @private
 * ç»æå®ç¹é¡ºæ¶éæè½¬åçç¹åæ 
 * é»è®¤ç»åç¹æè½¬
 */
function rotateAtPoint(point: Point, deg = 0, origin = { x: 0, y: 0 }): Point {
  const { x, y } = point;
  return {
    x: (x - origin.x) * Math.cos(-deg) + (y - origin.y) * Math.sin(-deg) + origin.x,
    y: (origin.x - x) * Math.sin(-deg) + (y - origin.y) * Math.cos(-deg) + origin.y,
  };
}

/**
 * @private
 * è½¬åä¸ºé¡¶ç¹åæ æ°ç»
 *
 * @param {Object} box
 */
function getRectPoints(box: Box): Point[] {
  const points = [
    { x: box.x, y: box.y },
    { x: box.x + box.width, y: box.y },
    { x: box.x + box.width, y: box.y + box.height },
    { x: box.x, y: box.y + box.height },
  ];

  const rotation = box.rotation;
  if (rotation) {
    return [
      rotateAtPoint(points[0], rotation, points[0]),
      rotateAtPoint(points[1], rotation, points[0]),
      rotateAtPoint(points[2], rotation, points[0]),
      rotateAtPoint(points[3], rotation, points[0]),
    ];
  }

  return points;
}

/**
 * @private
 * 2. è·åå¤è¾¹å½¢å¨æå½±è½´ä¸çæå½±
 *
 * åéçç¹ç§¯çå¶ä¸­ä¸ä¸ªå ä½å«ä¹æ¯ï¼ä¸ä¸ªåéå¨å¹³è¡äºå¦ä¸ä¸ªåéæ¹åä¸çæå½±çæ°å¼ä¹ç§¯ã
 * ç±äºæå½±è½´æ¯åä½åéï¼é¿åº¦ä¸º1ï¼ï¼æå½±çé¿åº¦ä¸º x1 * x2 + y1 * y2
 */
function getProjection(points: Point[] /** å¤è¾¹å½¢çå³é®ç¹ */, axis: Vec2): Projection {
  // ç®ååå¤çç©å½¢çåºæ¯
  if (points.length > 4) {
    return { min: 0, max: 0 };
  }

  const scalars = [];
  points.forEach((point) => {
    scalars.push(dot([point.x, point.y], axis));
  });

  return { min: Math.min(...scalars), max: Math.max(...scalars) };
}

function isProjectionOverlap(projection1: Projection, projection2: Projection): boolean {
  return projection1.max > projection2.min && projection1.min < projection2.max;
}

function isValidNumber(d: number) {
  return isNumber(d) && !Number.isNaN(d) && d !== Infinity && d !== -Infinity;
}

function isValidBox(box: Box) {
  return Object.values(box).every(isValidNumber);
}

/**
 * å¿«éå¤æ­ä¸¤ä¸ªæ æè½¬ç©å½¢æ¯å¦é®æ¡
 */
export function isIntersectRect(box1: Box, box2: Box, margin: number = 0): boolean {
  return !(
    box2.x > box1.x + box1.width + margin ||
    box2.x + box2.width < box1.x - margin ||
    box2.y > box1.y + box1.height + margin ||
    box2.y + box2.height < box1.y - margin
  );
}

/**
 * detect whether two shape is intersected, useful when shape is been rotated
 * å¤æ­ä¸¤ä¸ªç©å½¢æ¯å¦éå ï¼ç¸äº¤ååå«, æ¯å¦æè½¬ï¼
 *
 * - åç: åç¦»è½´å®å¾
 */
export function isIntersect(box1: Box, box2: Box) {
  // å¦æä¸¤ä¸ª box ä¸­æä¸ä¸ªæ¯ä¸åæ³ç boxï¼ä¹å°±æ¯ä¸ä¼è¢«æ¸²æåºæ¥çï¼é£ä¹å®ä»¬å°±ä¸ç¸äº¤ã
  if (!isValidBox(box1) || !isValidBox(box2)) return false;

  // å¦æä¸¤ä¸ªç©å½¢æ²¡ææè½¬ï¼ä½¿ç¨å¿«éå¤æ­
  if (!box1.rotation && !box2.rotation) {
    return isIntersectRect(box1, box2);
  }

  // åå«è·å 4 ä¸ªå³é®ç¹
  const rect1Points = getRectPoints(box1);
  const rect2Points = getRectPoints(box2);

  // è·åæææå½±è½´
  const axes = [...getAxes(rect1Points), ...getAxes(rect2Points)];

  for (let i = 0; i < axes.length; i++) {
    const axis = axes[i];
    const projection1 = getProjection(rect1Points, axis);
    const projection2 = getProjection(rect2Points, axis);

    // å¤æ­æå½±è½´ä¸çæå½±æ¯å¦å­å¨éå ï¼è¥æ£æµå°å­å¨é´éåç«å»éåºå¤æ­ï¼æ¶é¤ä¸å¿è¦çè¿ç®ã
    if (!isProjectionOverlap(projection1, projection2)) return false;
  }

  return true;
}
