import { get, isArray } from '@antv/util';
import { Axis } from '../../../types/axis';
import { deepAssign } from '../../../utils';
import {
  DualAxesOptions,
  GeometryOption,
  DualAxesGeometry,
  GeometryLineOption,
  GeometryColumnOption,
  AxisType,
} from '../types';
import { DEFAULT_LEFT_YAXIS_CONFIG, DEFAULT_RIGHT_YAXIS_CONFIG } from '../constant';

/**
 * æ ¹æ® GeometryOption å¤æ­ geometry æ¯å¦ä¸º line
 */
export function isLine(geometryOption: GeometryOption): geometryOption is GeometryLineOption {
  return get(geometryOption, 'geometry') === DualAxesGeometry.Line;
}

/**
 * æ ¹æ® GeometryOption å¤æ­ geometry æ¯å¦ä¸º Column
 */
export function isColumn(geometryOption: GeometryOption): geometryOption is GeometryColumnOption {
  return get(geometryOption, 'geometry') === DualAxesGeometry.Column;
}

/**
 * è·å GeometryOption
 * @param geometryOption
 * @param axis
 */
export function getGeometryOption(xField: string, yField: string, geometryOption: GeometryOption): GeometryOption {
  // ç©ºé»è®¤ä¸ºçº¿
  return isColumn(geometryOption)
    ? deepAssign(
        {},
        {
          geometry: DualAxesGeometry.Column,
          label:
            geometryOption.label && geometryOption.isRange
              ? {
                  content: (item: object) => {
                    return item[yField]?.join('-');
                  },
                }
              : undefined,
        },
        geometryOption
      )
    : {
        geometry: DualAxesGeometry.Line,
        ...geometryOption,
      };
}

/**
 * å¼å®¹ä¸äºå±æ§ ä¸º arr å obj çä¸¤ç§æåµï¼ å¦ yAxisï¼annotations
 * ä¸ºäºé²æ­¢å·¦å³ yField ç¸åï¼å¯¼è´åæ object ä¹åè¢«è¦çï¼æä»¥é½è½¬åææ°ç»çå½¢å¼
 * @param yField
 * @param transformAttribute
 */
export function transformObjectToArray(
  yField: DualAxesOptions['yField'],
  transformAttribute: Record<string, any> | any[]
): any[] {
  const [y1, y2] = yField;

  if (isArray(transformAttribute)) {
    // å°æ°ç»è¡¥é½ä¸ºä¸¤ä¸ª
    const [a1, a2] = transformAttribute;
    return [a1, a2];
  }
  const a1 = get(transformAttribute, y1);
  const a2 = get(transformAttribute, y2);
  return [a1, a2];
}

/**
 * è·åé»è®¤å¼
 * @param yAxis
 * @param axisType
 */
export function getYAxisWithDefault(yAxis: Axis, axisType: AxisType): Axis {
  if (axisType === AxisType.Left) {
    return yAxis === false ? false : deepAssign({}, DEFAULT_LEFT_YAXIS_CONFIG, yAxis);
  } else if (axisType === AxisType.Right) {
    return yAxis === false ? false : deepAssign({}, DEFAULT_RIGHT_YAXIS_CONFIG, yAxis);
  }
  return yAxis;
}
