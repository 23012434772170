import { ext } from '@antv/matrix-util';
import { Coordinate, IGroup, IShape } from '../../dependents';
import { GAnimateCfg, Point } from '../../interface';

/**
 * @ignore
 * å¯¹å¾å½¢åç´ è¿è¡ç©éµåæ¢ï¼åæ¶è¿ååæ¢åçå¾å½¢ç©éµ
 * @param shape è¿è¡ç©éµåæ¢çå¾å½¢
 * @param vector ç©éµåæ¢çä¸­å¿ç¹
 * @param direct ç©éµåæ¢çç±»å
 */
export function transformShape(shape: IShape | IGroup, vector: [number, number], direct: string): number[] {
  let scaledMatrix;

  const [x, y] = vector;
  shape.applyToMatrix([x, y, 1]);
  if (direct === 'x') {
    shape.setMatrix(
      ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 0.01, 1],
        ['t', x, y],
      ])
    );
    scaledMatrix = ext.transform(shape.getMatrix(), [
      ['t', -x, -y],
      ['s', 100, 1],
      ['t', x, y],
    ]);
  } else if (direct === 'y') {
    shape.setMatrix(
      ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 1, 0.01],
        ['t', x, y],
      ])
    );
    scaledMatrix = ext.transform(shape.getMatrix(), [
      ['t', -x, -y],
      ['s', 1, 100],
      ['t', x, y],
    ]);
  } else if (direct === 'xy') {
    shape.setMatrix(
      ext.transform(shape.getMatrix(), [
        ['t', -x, -y],
        ['s', 0.01, 0.01],
        ['t', x, y],
      ])
    );
    scaledMatrix = ext.transform(shape.getMatrix(), [
      ['t', -x, -y],
      ['s', 100, 100],
      ['t', x, y],
    ]);
  }
  return scaledMatrix;
}

/**
 * å¯¹å¾å½¢åç´ è¿è¡åªåå¨ç»
 * @param element è¿è¡å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param coordinate å½ååæ ç³»
 * @param yMinPoint y è½´çæå°å¼å¯¹åºçå¾å½¢åæ ç¹
 * @param type åªåå¨ç»çç±»å
 */
export function doScaleAnimate(
  element: IGroup | IShape,
  animateCfg: GAnimateCfg,
  coordinate: Coordinate,
  yMinPoint: Point,
  type: string
) {
  const { start, end } = coordinate;
  const width = coordinate.getWidth();
  const height = coordinate.getHeight();
  let x: number;
  let y: number;

  if (type === 'y') {
    x = start.x + width / 2;
    y = yMinPoint.y < start.y ? yMinPoint.y : start.y;
  } else if (type === 'x') {
    x = yMinPoint.x > start.x ? yMinPoint.x : start.x;
    y = start.y + height / 2;
  } else if (type === 'xy') {
    if (coordinate.isPolar) {
      x = coordinate.getCenter().x;
      y = coordinate.getCenter().y;
    } else {
      x = (start.x + end.x) / 2;
      y = (start.y + end.y) / 2;
    }
  }

  const endMatrix = transformShape(element, [x, y], type);
  element.animate(
    {
      matrix: endMatrix,
    },
    animateCfg
  );
}
