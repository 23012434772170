import { deepAssign } from '../utils';
import { Plot, PickOptions } from '../core/plot';
import { Adaptor } from '../core/adaptor';
/**
 * ç» G2Plot æä¾éå¸¸ç®åçå¼æ¾å¼åçæºå¶ãç®çæ¯è½å¤è®©ç¤¾åºåä¸å¡ä¸èªå·±åºäº G2Plot å¼åèªå·±çå®å¶å¾è¡¨åºãä¸»è¦åæå ç±»å¾è¡¨ï¼
 * 1. é¢åä¸ä¸çå¾è¡¨ï¼åé¨åå­¦å ä¸ºæ²¡æåºæ¯ï¼ä¸ä¸å®è½åçå®åã
 * 2. å®å¶ä¸å¡çå¾è¡¨ï¼ä¸å·å¤éç¨æ§
 * 3. è¶£å³æ§çå¯è§åç»ä»¶
 * ç¶åå®æ¹å¯ä»¥æ ¹æ®ç¤¾åºçæåµï¼å¯ä»¥è¿è¡ä¸äºå®æ¹æ¨èåéçº³ã
 *
 * å¦æä½¿ç¨ï¼
 *
 * ```ts
 * import { P } from '@antv/g2plot';
 * import { GeoWorldMap, GeoWorldMapOptions } from 'g2plot-geo-world-map';
 *
 * const plot = new P('container', {
 *   geoJson: '',
 *   longitude: '',
 *   latitude: '',
 * }, GeoWorldMap, defaultOptions);
 *
 * plot.render();
 * ```
 */
export class P<O extends PickOptions> extends Plot<O> {
  /** ç»ä¸ä¸º any plot */
  public readonly type = 'g2-plot';

  /** å¤é¨ä¼ å¥ç defaultOptions */
  private defaultOptions: Partial<O>;

  /** å¤é¨ä¼ å¥ç adaptor å½æ° */
  private adaptor: Adaptor<O>;

  /**
   * ç¸æ¯æ®éå¾è¡¨å¢å  adaptor åæ°ã
   * @param container
   * @param options
   * @param adaptor
   * @param defaultOptions
   */
  constructor(container: string | HTMLElement, options: O, adaptor: Adaptor<O>, defaultOptions?: Partial<O>) {
    super(container, deepAssign({}, defaultOptions, options));

    this.defaultOptions = defaultOptions;
    this.adaptor = adaptor;
  }

  /**
   * å®ç°ç¶ç±»æ¹æ³ï¼ç´æ¥ä½¿ç¨ä¼ å¥ç
   */
  protected getDefaultOptions() {
    return this.defaultOptions;
  }

  /**
   * å®ç°ç¶ç±»æ¹æ³ï¼ç´æ¥ä½¿ç¨ä¼ å¥ç
   */
  protected getSchemaAdaptor(): Adaptor<O> {
    return this.adaptor;
  }
}
