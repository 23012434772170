import ListState from './list-state';

/**
 * å¾ä¾é¡¹ååæ è½´ææ¬éä¸­ç Action
 * @ignore
 */
class ListSelected extends ListState {
  protected stateName: string = 'selected';
  public selected() {
    this.setState();
  }
}

export default ListSelected;
