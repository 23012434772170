import { each } from '@antv/util';
import Action from '../base';
import { getElements, getMaskedElements, getSiblingMaskElements, getSilbings, isInRecords, isMask } from '../util';

/**
 * Sibling filter
 * @ignore
 */
class SiblingFilter extends Action {
  protected byRecord = false;
  /**
   * è¿æ»¤éèå¾å½¢
   */
  public filter() {
    // ä»èè mask å¯¼è´çè¿æ»¤
    if (isMask(this.context)) {
      if (this.byRecord) {
        this.filterByRecord();
      } else {
        this.filterByBBox();
      }
    }
  }
  // æ ¹æ®æ¡éçè®°å½æ¥åè¿æ»¤
  private filterByRecord() {
    const view = this.context.view;
    const maskElements = getMaskedElements(this.context, 10);
    if (!maskElements) {
      return;
    }
    const xFiled = view.getXScale().field;
    const yField = view.getYScales()[0].field;
    const records = maskElements.map((el) => {
      return el.getModel().data;
    });
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      const elements = getElements(sibling);
      each(elements, (el) => {
        const record = el.getModel().data;
        // records.includes(record) ä¸çæï¼åºè¯¥æ¯æ°æ®çå¼ç¨è¢«æ¹äº
        if (isInRecords(records, record, xFiled, yField)) {
          el.show();
        } else {
          el.hide();
        }
      });
    });
  }

  // æ ¹æ®è¢«æ¡éçåå´çåè¿æ»¤
  private filterByBBox() {
    const view = this.context.view;
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      const maskElements = getSiblingMaskElements(this.context, sibling, 10);
      const elements = getElements(sibling);
      if (maskElements) {
        // mask è¿å°æ¶è¿åä¸º nullï¼ä¸è½æ¯ç©ºæ°ç»ï¼å¦ååæªæ¡éå°æ··æ·
        each(elements, (el) => {
          if (maskElements.includes(el)) {
            el.show();
          } else {
            el.hide();
          }
        });
      }
    });
  }

  /**
   * æ¸çææéèçå¾å½¢
   */
  public reset() {
    const siblings = getSilbings(this.context.view);
    each(siblings, (sibling) => {
      const elements = getElements(sibling);
      each(elements, (el) => {
        el.show();
      });
    });
  }
}

export default SiblingFilter;
