import { BBox, IGroup, IShape } from '../../dependents';
import { LooseObject } from '../../interface';
import { GeometryLabelConstructor } from './base';
import { LabelItem } from './interface';

/**
 * label å¸å±å½æ°å®ä¹
 * @param items å­å¨æ¯ä¸ª label çè¯¦ç»ä¿¡æ¯
 * @param labels ææç labels å¾å½¢å®ä¾
 * @param shapes ææ label å¯¹åºçå¾å½¢åç´ å®ä¾
 * @param region ç»å¸åºå
 * @param cfg ç¨äºå­å¨åä¸ªå¸å±å½æ°å¼æ¾ç»ç¨æ·çéç½®æ°æ®
 */
type GeometryLabelsLayoutFn = (
  items: LabelItem[],
  labels: IGroup[],
  shapes: IShape[] | IGroup[],
  region: BBox,
  cfg?: LooseObject
) => void;

const GEOMETRY_LABELS_MAP: Record<string, GeometryLabelConstructor> = {};
const GEOMETRY_LABELS_LAYOUT_MAP: Record<string, GeometryLabelsLayoutFn> = {};

/**
 * è·å `type` å¯¹åºç [[GeometryLabel]] ç±»
 * @param type
 * @returns
 */
export function getGeometryLabel(type: string): GeometryLabelConstructor {
  return GEOMETRY_LABELS_MAP[type.toLowerCase()];
}

/**
 * æ³¨åå®ä¹ç GeometryLabel ç±»
 * @param type GeometryLabel ç±»ååç§°
 * @param ctor GeometryLabel ç±»
 */
export function registerGeometryLabel(type: string, ctor: GeometryLabelConstructor) {
  GEOMETRY_LABELS_MAP[type.toLowerCase()] = ctor;
}

/**
 * è·å `type` å¯¹åºç [[GeometryLabelsLayoutFn]] label å¸å±å½æ°
 * @param type å¸å±å½æ°åç§°
 * @returns
 */
export function getGeometryLabelLayout(type: string): GeometryLabelsLayoutFn {
  return GEOMETRY_LABELS_LAYOUT_MAP[type.toLowerCase()];
}

/**
 * æ³¨åå®ä¹ç label å¸å±å½æ°
 * @param type label å¸å±å½æ°åç§°
 * @param layoutFn label å¸å±å½æ°
 */
export function registerGeometryLabelLayout(type: string, layoutFn: GeometryLabelsLayoutFn) {
  GEOMETRY_LABELS_LAYOUT_MAP[type.toLowerCase()] = layoutFn;
}
