export default {
  base: {
    remove: 'åªé¤',
    cancel: 'åæ¶',
    confirm: 'ç¢ºå®',
    inert: 'æå¥',
    width: 'å®½åº¦',
    height: 'é«åº¦'
  },
  controls: {
    clear: 'æ¸é¤åå®¹',
    undo: 'æ¤é·',
    redo: 'éå',
    fontSize: 'å­è',
    color: 'é¡è²',
    textColor: 'æå­é¡è²',
    backgroundColor: 'èæ¯é¡è²',
    tempColors: 'è¨æé¡è²',
    bold: 'å ç²',
    lineHeight: 'è¡é«',
    letterSpacing: 'å­éè·',
    textIndent: 'æ®µè½ç¸®é²',
    increaseIndent: 'å¢å ç¸®é²',
    decreaseIndent: 'åå°ç¸®é²',
    border: 'éæ¡',
    italic: 'æé«',
    underline: 'ä¸åç·',
    strikeThrough: 'åªé¤ç·',
    fontFamily: 'å­é«',
    textAlign: 'ææ¬å°é½',
    alignLeft: 'å±å·¦',
    alignCenter: 'å±ä¸­',
    alignRight: 'å±å³',
    alignJustify: 'å©ç«¯å°é½',
    floatLeft: 'å·¦æµ®å',
    floatRight: 'å³æµ®å',
    superScript: 'ä¸æ¨',
    subScript: 'ä¸æ¨',
    removeStyles: 'æ¸é¤æ ·å¼',
    headings: 'æ¨é¡',
    header: 'æ¨é¡',
    normal: 'å¸¸è¦',
    orderedList: 'æåºåè¡¨',
    unorderedList: 'ç¡åºåè¡¨',
    blockQuote: 'å¼ç¨',
    code: 'ä»£ç¢¼',
    link: 'éæ¥',
    unlink: 'æ¸é¤éæ¥',
    hr: 'æ°´å¹³çº¿',
    media: 'åªé«',
    mediaLibirary: 'åªé«åº',
    emoji: 'å°è¡¨æ',
    fullscreen: 'å¨çå¹',
    exitFullscreen: 'éåºå¨çå¹',
  },
  linkEditor: {
    textInputPlaceHolder: 'è¼¸å¥éæ¥æå­',
    linkInputPlaceHolder: 'è¼¸å¥éæ¥å°å',
    inputWithEnterPlaceHolder: 'è¼¸å¥éæ¥å°åä¸¦åè»',
    openInNewWindow: 'å¨æ°çªå£æé',
    removeLink: 'ç§»é¤éæ¥'
  },
  audioPlayer: {
    title: 'æ­æ¾é³é »æä»¶'
  },
  videoPlayer: {
    title: 'æ­æ¾è¦é »æä»¶',
    embedTitle: 'åµå¥å¼åªé«'
  },
  media: {
    image: 'åå',
    video: 'è¦é »',
    audio: 'é³é »',
    embed: 'åµå¥å¼åªé«'
  }
}