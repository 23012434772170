import { Options, ShapeAttr, SizeAttr, StyleAttr } from '../../types';
import { LineOptions } from '../line/types';
import { ColumnOptions } from '../column/types';

export enum AxisType {
  Left = 'Left',
  Right = 'Right',
}

export enum DualAxesGeometry {
  Line = 'line',
  Column = 'column',
}

export interface PointConfig {
  readonly shape?: ShapeAttr;
  readonly size?: SizeAttr;
  readonly style?: StyleAttr;
}

type CommonGeometryOption = {
  // å¾å½¢ç±»å
  readonly geometry?: string;
  // label
  readonly label?: Options['label'];
  // legend
  readonly legend?: Options['legend'];
  // è²æ¿
  readonly color?: Options['color'];
  // æ¯å¦åç»
  readonly isGroup?: boolean;
  // åç»å­æ®µï¼ä¼åçº§é«äº seriesField
  readonly groupField?: string;
};

// æçº¿è®¾ç½®æ¥å£, ç´æ¥ç¨ LineOption å§
export type GeometryLineOption = Pick<
  LineOptions,
  'seriesField' | 'smooth' | 'connectNulls' | 'lineStyle' | 'point' | 'isStack' | 'stepType'
> &
  CommonGeometryOption;

// æ±è®¾ç½®æ¥å£
export type GeometryColumnOption = Pick<
  ColumnOptions,
  | 'seriesField'
  | 'isGroup'
  | 'isStack'
  | 'isRange'
  | 'isPercent'
  | 'columnWidthRatio'
  | 'marginRatio'
  | 'columnStyle'
  | 'pattern'
> &
  CommonGeometryOption;

export type GeometryOption = GeometryColumnOption | GeometryLineOption;

export type DualAxesOptions = Omit<Options, 'data' | 'yAxis' | 'color' | 'annotations'> & {
  /**
   * å·ä½çæ°æ®ï¼å·¦å³ä¸¤è¾¹çæ°æ®
   */
  readonly data: Array<Record<string, any>[]>;

  /**
   * åè½´å¾ç x å­æ®µï¼x å­æ®µåç§°éè¦ä¿æä¸è´
   */
  readonly xField: string;
  /**
   * åè½´å¾å·¦å³ y å­æ®µï¼éè¦ä¸ä¸è´
   */
  readonly yField: string[];

  /**
   * å·¦å³ä¸¤è¾¹ç yAxis éç½®ï¼ä½¿ç¨ object çæ¹å¼ï¼key ä¸º y å­æ®µåï¼æèæ°ç»åå«è¡¨ç¤ºå·¦å³
   */
  readonly yAxis?: Options['yAxis'][] | Record<string, Options['yAxis']>;

  /**
   * å·¦å³ä¸¤è¾¹çå¾å½¢éç½®
   */
  readonly geometryOptions?: GeometryOption[];

  /**
   * å·¦å³ä¸¤è¾¹ç annotation éç½®, åæ¶æ¯ææ°ç»åobject ä¸¤ç§å½¢å¼ï¼ä»¥é²åºç° field ç¸åæ æ³è®¾ç½®çæåµ
   */
  readonly annotations?: Options['annotations'][] | Record<string, Options['annotations']>;
};
