import ListState from './list-state';

/**
 * åè¡¨é¡¹ï¼å¾ä¾é¡¹ãåæ è½´ææ¬ï¼æ¿æ´»ç Action
 * @class
 * @ignore
 */
class ListActive extends ListState {
  protected stateName: string = 'active';
  /**
   * æ¿æ´»éé¡¹
   */
  public active() {
    this.setState();
  }
}

export default ListActive;
