import { each } from '@antv/util';
import Action from '../base';
import {
  getDelegationObject,
  getElements,
  getElementValue,
  getScaleByField,
  isList,
  isSlider,
  isMask,
  getMaskedElements,
} from '../util';
/**
 * åç´ è¿æ»¤ç Actionï¼æ§å¶åç´ çæ¾ç¤ºéè
 * @ignore
 */
class ElementFilter extends Action {
  /**
   * è¿æ»¤
   */
  public filter() {
    const delegateObject = getDelegationObject(this.context);
    const view = this.context.view;
    const elements = getElements(view);
    if (isMask(this.context)) {
      const maskElements = getMaskedElements(this.context, 10);
      if (maskElements) {
        each(elements, (el) => {
          if (maskElements.includes(el)) {
            el.show();
          } else {
            el.hide();
          }
        });
      }
    } else if (delegateObject) {
      const { component } = delegateObject;
      const field = component.get('field');
      // åè¡¨ç±»çç»ä»¶è½å¤è§¦å
      if (isList(delegateObject)) {
        if (field) {
          const unCheckedItems = component.getItemsByState('unchecked');
          const scale = getScaleByField(view, field);
          const names = unCheckedItems.map((item) => item.name);
          // ç´æ¥æ§å¶æ¾ç¤ºãéè
          each(elements, (el) => {
            const value = getElementValue(el, field);
            const text = scale.getText(value);
            if (names.indexOf(text) >= 0) {
              el.hide();
            } else {
              el.show();
            }
          });
        }
      } else if (isSlider(delegateObject)) {
        const range = component.getValue();
        const [min, max] = range;
        each(elements, (el) => {
          const value = getElementValue(el, field);
          if (value >= min && value <= max) {
            el.show();
          } else {
            el.hide();
          }
        });
      }
    }
  }
  /**
   * æ¸é¤è¿æ»¤
   */
  public clear() {
    const elements = getElements(this.context.view);
    each(elements, (el) => {
      el.show();
    });
  }

  /**
   * æ¢å¤åççè¿æ»¤ï¼ä¿æå data-filter å½åçä¸è´
   */
  public reset() {
    this.clear();
  }
}

export default ElementFilter;
