import { each } from '@antv/util';
import { BBox, IGroup, IShape } from '../../../dependents';
import { LabelItem } from '../interface';

/**
 * @ignore
 * æ ¹æ®å¾å½¢åç´ ä»¥å label ç bbox è¿è¡è°æ´ï¼å¦æ label è¶åºäº shape ç bbox åä¸å±ç¤º
 */
export function limitInShape(items: LabelItem[], labels: IGroup[], shapes: IShape[] | IGroup[], region: BBox) {
  each(labels, (label, index) => {
    const labelBBox = label.getCanvasBBox(); // ææ¬æå¯è½åçæè½¬
    const shapeBBox = shapes[index].getBBox();
    if (
      labelBBox.minX < shapeBBox.minX ||
      labelBBox.minY < shapeBBox.minY ||
      labelBBox.maxX > shapeBBox.maxX ||
      labelBBox.maxY > shapeBBox.maxY
    ) {
      label.remove(true); // è¶åºåä¸å±ç¤º
    }
  });
}
