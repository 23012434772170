import { deepMix, isNil, get } from '@antv/util';
import { ShapeInfo } from '../../../interface';

/**
 * @ignore
 * è·å Shape çå¾å½¢å±æ§
 * @param cfg
 * @param isStroke æ¯å¦éè¦æè¾¹
 * @param isFill æ¯å¦éè¦å¡«å
 * @param [sizeName] å¯éï¼è¡¨ç¤ºå¾å½¢å¤§å°çå±æ§ï¼lineWidth æè r
 * @returns
 */
export function getStyle(cfg: ShapeInfo, isStroke: boolean, isFill: boolean, sizeName: string = '') {
  const { style = {}, defaultStyle, color, size } = cfg;
  const attrs = {
    ...defaultStyle,
    ...style,
  };
  if (color) {
    if (isStroke) {
      if (!style.stroke) {
        // å¦æç¨æ·å¨ style() ä¸­éç½®äº strokeï¼åä»¥ç¨æ·éç½®çä¸ºå
        attrs.stroke = color;
      }
    }

    if (isFill) {
      if (!style.fill) {
        // å¦æç¨æ·å¨ style() ä¸­éç½®äº fill
        attrs.fill = color;
      }
    }
  }
  if (sizeName && isNil(style[sizeName]) && !isNil(size)) {
    // å¦æç¨æ·å¨ style() ä¸­éç½®äº lineWidth æè r å±æ§
    attrs[sizeName] = size;
  }

  return attrs;
}

/**
 * è·å ç©å½¢èæ¯ çæ ·å¼
 * @param cfg
 */
export function getBackgroundRectStyle(cfg?: ShapeInfo) {
  return deepMix(
    {},
    {
      // é»è®¤èæ¯è²ï¼copy from active-region
      fill: '#CCD6EC',
      fillOpacity: 0.3,
    },
    get(cfg, ['background', 'style'])
  );
}
