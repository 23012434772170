import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';
import { CHILD_NODE_COUNT, NODE_ANCESTORS_FIELD, NODE_INDEX_FIELD } from '../../utils/hierarchy/util';
import { SunburstOptions } from './types';

/**
 * ç¥åèç¹ï¼é root æ ¹èç¹
 */
export const SUNBURST_ANCESTOR_FIELD = 'ancestor-node';
export const SUNBURST_Y_FIELD = 'value';
export const SUNBURST_PATH_FIELD = 'path';

/** é»è®¤çæºå­æ®µ */
export const RAW_FIELDS = [
  SUNBURST_PATH_FIELD,
  NODE_INDEX_FIELD,
  NODE_ANCESTORS_FIELD,
  CHILD_NODE_COUNT,
  'name',
  'depth',
  'height',
];

/**
 * æ­æ¥å¾ é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS: Partial<SunburstOptions> = deepAssign({}, Plot.getDefaultOptions(), {
  innerRadius: 0,
  radius: 0.85,

  // åå±éç½®
  hierarchyConfig: {
    // æ°å¼å­æ®µï¼é»è®¤æ¯ valueï¼å¯éç½®ï¼
    field: 'value',
  },

  // ç»ä»¶
  tooltip: {
    shared: true,
    showMarkers: false,
    offset: 20,
    showTitle: false,
  },
  legend: false,

  // æ ·å¼è®¾ç½®
  sunburstStyle: {
    lineWidth: 0.5,
    stroke: '#FFF',
  },

  // é»è®¤å¼å¯äº¤äº
  drilldown: { enabled: true },
});
