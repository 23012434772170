import { get } from '@antv/util';
import { FIELD_ORIGIN } from '../constant';
import { Datum, ViolinShapePoint } from '../interface';
import { getXDimensionLength } from '../util/coordinate';
import { getDefaultSize } from './util/shape-size';
import Geometry from './base';
/** å¼å¥ Path å¯¹åºç ShapeFactory */
import './shape/violin';

/**
 * Violin å ä½æ è®°ã
 * ç¨äºç»å¶å°æç´å¾ã
 */
export default class Violin extends Geometry<ViolinShapePoint> {
  public readonly type: string = 'violin';
  public readonly shapeType: string = 'violin';
  protected generatePoints: boolean = true;
  /** size ç§ææ å°å­æ®µ */
  private _sizeField: string;

  /**
   * è·å Shape çå³é®ç¹æ°æ®ã
   * @param record
   * @returns
   */
  protected createShapePointsCfg(record: Datum) {
    const cfg = super.createShapePointsCfg(record);

    // è®¡ç®æ¯ä¸ª shape ç size
    let size;
    const sizeAttr = this.getAttribute('size');
    if (sizeAttr) {
      size = this.getAttributeValues(sizeAttr, record)[0];
      // å½ä¸å
      const coordinate = this.coordinate;
      const coordinateWidth = getXDimensionLength(coordinate);
      size = size / coordinateWidth;
    } else {
      if (!this.defaultSize) {
        this.defaultSize = getDefaultSize(this);
      }
      size = this.defaultSize;
    }
    cfg.size = size;
    cfg._size = get(record[FIELD_ORIGIN], [this._sizeField]);
    return cfg;
  }

  /**
   * @override
   */
  protected initAttributes() {
    const { attributeOption } = this;
    const sizeField = attributeOption.size
      ? attributeOption.size.fields[0]
      : this._sizeField
      ? this._sizeField
      : 'size';
    this._sizeField = sizeField;
    // fixme å¹²å¥è¦å æ
    delete attributeOption.size;
    super.initAttributes();
  }
}
