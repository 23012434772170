import { clone, sortBy, valuesOfKey, getRange, each, hasKey, groupBy, isEmpty } from '@antv/util';
import { Data } from '../../types';

type StatisticData = {
  range: Array<number>;
  count: number;
};
type StatisticBin = {
  [key: string]: StatisticData;
};

// è¿è¡è½¬æ¢å¾å°å¼æå¨ç range
function getBinKey(value: number, binWidth: number, binNumber?: number): [number, number] {
  // åä¸ç¹ç¹æ®å¤ç
  if (binNumber === 1) {
    return [0, binWidth];
  }
  const index = Math.floor(value / binWidth);
  return [binWidth * index, binWidth * (index + 1)];
}

// é»è®¤ sturges è½¬æ¢
function sturges(values: Array<number>): number {
  return Math.ceil(Math.log(values.length) / Math.LN2) + 1;
}
/**
 * å¯¹æ°æ®è¿è¡ç¾åæ¯å
 * @param data
 * @param binField
 * @param binWidth
 * @param binNumber
 * @param stackField
 */
export function binHistogram(data: Data, binField: string, binWidth: number, binNumber?: number, stackField?: string) {
  const originData_copy = clone(data);

  // æ ¹æ® binField å¯¹æºæ°æ®è¿è¡æåº
  sortBy(originData_copy, binField);

  // è·åæºæ°æ® binField ç range
  const values = valuesOfKey(originData_copy, binField);
  const range = getRange(values);
  const rangeWidth = range.max - range.min;

  // è®¡ç®åç®±ï¼ç´æ¹å¾åç®±çè®¡ç®åºäº binWidthï¼å¦éç½®äº binNumber åå°å¶è½¬ä¸º binWidth è¿è¡è®¡ç®
  let _binWidth = binWidth;
  if (!binWidth && binNumber) {
    _binWidth = binNumber > 1 ? rangeWidth / (binNumber - 1) : range.max;
  }
  // å½ binWidth å binNumber é½æ²¡ææå®çæåµï¼éç¨ Sturges formula èªå¨çæ binWidth
  if (!binWidth && !binNumber) {
    const _defaultBinNumber = sturges(values);
    _binWidth = rangeWidth / _defaultBinNumber;
  }
  // æå»º key - StatisticData ç»æ
  const bins: StatisticBin = {};
  const groups = groupBy(originData_copy, stackField);
  // å¤æ­åç»æ¯å¦ä¸ºç©ºï¼å¦æä¸ºç©ºï¼è¯´ææ²¡æ stackField å­æ®µ
  if (isEmpty(groups)) {
    each(originData_copy, (data: any) => {
      const value = data[binField];
      const bin = getBinKey(value, _binWidth, binNumber);
      const binKey = `${bin[0]}-${bin[1]}`;
      if (!hasKey(bins, binKey)) {
        bins[binKey] = { range: bin, count: 0 };
      }
      bins[binKey].count += 1;
    });
  } else {
    Object.keys(groups).forEach((groupKey: string) => {
      each(groups[groupKey], (data: any) => {
        const value = data[binField];
        const bin = getBinKey(value, _binWidth, binNumber);
        const binKey = `${bin[0]}-${bin[1]}`;
        const groupKeyBinKey = `${binKey}-${groupKey}`;
        if (!hasKey(bins, groupKeyBinKey)) {
          bins[groupKeyBinKey] = { range: bin, count: 0 };
          bins[groupKeyBinKey][stackField] = groupKey;
        }
        bins[groupKeyBinKey].count += 1;
      });
    });
  }
  // å°åç®±æ°æ®è½¬æ¢ä¸º plotData ææ¯å¾è¡¨æéè¦ç
  const plotData: Array<StatisticData> = [];
  each(bins, (bin: StatisticData) => {
    plotData.push(bin);
  });
  return plotData;
}
