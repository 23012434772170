import { ActionCallback } from '../../interface';
import Action from './base';

/** åè°å½æ°æå»ºç Action */
export default class CallbackAction extends Action {
  /**
   * åè°å½æ°
   */
  public callback: ActionCallback;
  /**
   * æ§è¡
   */
  public execute() {
    if (this.callback) {
      this.callback(this.context);
    }
  }
  /**
   * éæ¯
   */
  public destroy() {
    super.destroy();
    this.callback = null;
  }
}
