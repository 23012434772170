import { each, get, map, isArray } from '@antv/util';
import { registerAction, registerInteraction, View, Element, Action } from '@antv/g2';
import { getAllElements, getViews, getSiblingViews } from '../../../utils';
import { clearHighlight, getElementValue } from './utils';

type EventItem = { element: Element; view: View; active: boolean; inactive: boolean };
type ActionParams = { linkField?: string; dim?: 'x' | 'y' };
/**
 * å­å¨å¤ä¸ª view æ¶ï¼view ä¹é´çèå¨äº¤äº
 *
 * æä¾åä¸ªåé¦ actionï¼åæ¥ååæ°ï¼linkField å³èå­æ®µï¼dim ç»´åº¦
 * 1. showTooltip
 * 2. active
 * 3. highlight
 * 4. selected
 *
 * éå ï¼ä¸¤ä¸ªç»æåé¦ actionï¼
 * 1. hidetooltip
 * 2. reset æ¸é¤æ¿æ´»åé«äº®ç¶æ
 */
class Association extends Action {
  /**
   * è·åå³èç elements
   *
   * - å¦æ dim åæ°å­å¨ï¼æ ¹æ® dim è·åç¸åºç fieldãä¸ linkField ä¸å¹éå return
   * - å¦å dim åæ°ä¸å­å¨ï¼ä¸ linkField å­å¨ï¼åä½ä¸ºå³èå­æ®µ
   * - å¦åè¥ linkField ä¸å­å¨ï¼åè·åç¬¬ä¸ä¸ªåç±»å­æ®µ
   * @returns EventItem[]
   */
  private getAssociationItems(views: View[], params?: ActionParams): EventItem[] {
    const { event } = this.context;
    const { linkField, dim } = params || {};

    const items = [];

    if (event.data?.data) {
      const { data } = event.data;
      each(views, (v: View) => {
        let field = linkField;
        if (dim === 'x') {
          field = v.getXScale().field;
        } else if (dim === 'y') {
          field = v.getYScales().find((s) => s.field === field)?.field;
        } else if (!field) {
          field = v.getGroupScales()[0]?.field;
        }
        if (!field) {
          return;
        }
        const elements = map(getAllElements(v), (ele) => {
          let active = false;
          let inactive = false;
          const dataValue = isArray(data) ? get(data[0], field) : get(data, field);
          if (getElementValue(ele, field) === dataValue) {
            active = true;
          } else {
            inactive = true;
          }
          return { element: ele, view: v, active, inactive };
        });
        items.push(...elements);
      });
    }

    return items;
  }

  /**
   * ææåä¸å±çº§ç tooltip æ¾ç¤º
   */
  public showTooltip(params?: ActionParams) {
    const siblings = getSiblingViews(this.context.view);
    const elements = this.getAssociationItems(siblings, params);

    each(elements, (ele) => {
      if (ele.active) {
        const box = ele.element.shape.getCanvasBBox();
        ele.view.showTooltip({ x: box.minX + box.width / 2, y: box.minY + box.height / 2 });
      }
    });
  }

  /**
   * éèåä¸å±çº§ç tooltip
   */
  public hideTooltip() {
    const siblings = getSiblingViews(this.context.view);
    each(siblings, (sibling) => {
      sibling.hideTooltip();
    });
  }

  /**
   * è®¾ç½® active ç¶æ
   */
  public active(params?: ActionParams) {
    const views = getViews(this.context.view);
    const items = this.getAssociationItems(views, params);

    each(items, (item: EventItem) => {
      const { active, element } = item;
      if (active) {
        element.setState('active', true);
      }
    });
  }

  /**
   * è®¾ç½® selected ç¶æ
   */
  public selected(params?: ActionParams) {
    const views = getViews(this.context.view);
    const items = this.getAssociationItems(views, params);

    each(items, (item: EventItem) => {
      const { active, element } = item;
      if (active) {
        element.setState('selected', true);
      }
    });
  }

  /**
   * è¿è¡é«äº® => è®¾ç½® inactive ç¶æ
   */
  public highlight(params?: ActionParams) {
    const views = getViews(this.context.view);
    const items = this.getAssociationItems(views, params);

    each(items, (item: EventItem) => {
      const { inactive, element } = item;
      if (inactive) {
        element.setState('inactive', true);
      }
    });
  }

  public reset() {
    const views = getViews(this.context.view);
    each(views, (v) => {
      clearHighlight(v);
    });
  }
}

registerAction('association', Association);

/**
 * ç¸é» view ç active èå¨ï¼ç¸åç»´å¼ç tooltip èå¨ï¼
 */
registerInteraction('association-active', {
  start: [{ trigger: 'element:mouseenter', action: 'association:active' }],
  end: [{ trigger: 'element:mouseleave', action: 'association:reset' }],
});

/**
 * ç¸é» view ç active èå¨ï¼ç¸åç»´å¼ç tooltip èå¨ï¼
 */
registerInteraction('association-selected', {
  start: [{ trigger: 'element:mouseenter', action: 'association:selected' }],
  end: [{ trigger: 'element:mouseleave', action: 'association:reset' }],
});

/**
 * ç¸é» view ç highlight èå¨, çªåºå½å element
 */
registerInteraction('association-highlight', {
  start: [{ trigger: 'element:mouseenter', action: 'association:highlight' }],
  end: [{ trigger: 'element:mouseleave', action: 'association:reset' }],
});

/**
 * ç¸é» view ç tooltip èå¨ï¼æ ¹æ® groupField è¿è¡å³èï¼ç¸åç»´å¼ç tooltip èå¨ï¼
 */
registerInteraction('association-tooltip', {
  start: [{ trigger: 'element:mousemove', action: 'association:showTooltip' }],
  end: [{ trigger: 'element:mouseleave', action: 'association:hideTooltip' }],
});
