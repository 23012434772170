import { SquarePatternCfg } from '../../types/pattern';
import { deepAssign } from '../../utils';
import {
  getUnitPatternSize,
  initCanvas,
  drawBackground,
  getSymbolsPosition,
  transformMatrix,
  getPixelRatio,
} from './util';

/**
 * squarePattern ç é»è®¤éç½®
 */
export const defaultSquarePatternCfg = {
  size: 6,
  padding: 1,
  isStagger: true,
  backgroundColor: 'transparent',
  opacity: 1,
  rotation: 0,
  fill: '#fff',
  fillOpacity: 0.5,
  stroke: 'transparent',
  lineWidth: 0,
};

/**
 * ç»å¶square
 *
 * @param context canvasContext
 * @param cfg squarePattern çéç½®
 * @param xåy squareçä¸­å¿ä½ç½®
 */
export function drawSquare(context: CanvasRenderingContext2D, cfg: SquarePatternCfg, x: number, y: number) {
  const { stroke, size, fill, lineWidth, fillOpacity } = cfg;

  context.globalAlpha = fillOpacity;
  context.strokeStyle = stroke;
  context.lineWidth = lineWidth;
  context.fillStyle = fill;
  // å ä¸ºæ­£æ¹å½¢ç»å¶ä»å·¦ä¸è§å¼å§ï¼æä»¥xï¼yåä¸ªåç§»
  context.strokeRect(x - size / 2, y - size / 2, size, size);
  context.fillRect(x - size / 2, y - size / 2, size, size);
}

/**
 * åå»º squarePattern
 */
export function createSquarePattern(cfg?: SquarePatternCfg): CanvasPattern {
  const squareCfg = deepAssign({}, defaultSquarePatternCfg, cfg);

  const { size, padding, isStagger, rotation } = squareCfg;

  // è®¡ç® ç»å¸å¤§å°ï¼squaresçä½ç½®
  const unitSize = getUnitPatternSize(size, padding, isStagger);
  const squares = getSymbolsPosition(unitSize, isStagger); // è®¡ç®æ¹æ³ä¸ dots ä¸æ ·

  // åå§å patternCanvas
  const canvas = initCanvas(unitSize, unitSize);
  const ctx = canvas.getContext('2d');

  // ç»å¶ backgroundï¼squares
  drawBackground(ctx, squareCfg, unitSize);
  for (const [x, y] of squares) {
    drawSquare(ctx, squareCfg, x, y);
  }

  const pattern = ctx.createPattern(canvas, 'repeat');

  if (pattern) {
    const dpr = getPixelRatio();
    const matrix = transformMatrix(dpr, rotation);
    pattern.setTransform(matrix);
  }

  return pattern;
}
