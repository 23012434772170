import { deepMix, each, get, isArray, isNull } from '@antv/util';
import { BBox, Coordinate, IGroup, IShape } from '../dependents';
import { LabelItem } from '../geometry/label/interface';
import { AnimateOption, GeometryLabelLayoutCfg } from '../interface';
import { doAnimate } from '../animate';
import { getGeometryLabelLayout } from '../geometry/label';
import { getlLabelBackgroundInfo } from '../geometry/label/util';
import { polarToCartesian } from '../util/graphics';
import { rotate, translate } from '../util/transform';
import { FIELD_ORIGIN } from '../constant';
import { updateLabel } from './update-label';

/**
 * Labels å®ä¾åå»ºæ¶ï¼ä¼ å¥æé å½æ°çåæ°å®ä¹
 */
export interface LabelsGroupCfg {
  /** label å®¹å¨ */
  container: IGroup;
  /** label å¸å±éç½® */
  layout?: GeometryLabelLayoutCfg | GeometryLabelLayoutCfg[];
}

/**
 * Geometry labels æ¸²æç»ä»¶
 */
export default class Labels {
  /** ç¨äºæå® labels å¸å±çç±»å */
  public layout: GeometryLabelLayoutCfg | GeometryLabelLayoutCfg[];
  /** å¾å½¢å®¹å¨ */
  public container: IGroup;
  /** å¨ç»éç½® */
  public animate: AnimateOption | false;
  /** label ç»å¶çåºå */
  public region: BBox;

  /** å­å¨å½å shape çæ å°è¡¨ï¼é®å¼ä¸º shape id */
  public shapesMap: Record<string, IGroup> = {};
  private lastShapesMap: Record<string, IGroup> = {};

  constructor(cfg: LabelsGroupCfg) {
    const { layout, container } = cfg;

    this.layout = layout;
    this.container = container;
  }

  /**
   * æ¸²æææ¬
   */
  public render(items: LabelItem[], shapes: Record<string, IShape | IGroup>, isUpdate: boolean = false) {
    this.shapesMap = {};
    const container = this.container;
    const offscreenGroup = this.createOffscreenGroup(); // åå»ºèæåç»
    if (items.length) {
      // å¦æ items ç©ºçè¯å°±ä¸è¿è¡ç»å¶è°æ´æä½
      // step 1: å¨èæ group ä¸­åå»º shapes
      for (const item of items) {
        if (item) {
          this.renderLabel(item, offscreenGroup);
        }
      }
      // step 2: æ ¹æ®å¸å±ï¼è°æ´ labels
      this.doLayout(items, shapes);
      // step 3.1: ç»å¶ labelLine
      this.renderLabelLine(items);
      // step 3.2: ç»å¶ labelBackground
      this.renderLabelBackground(items);
      // step 4: æ ¹æ®ç¨æ·è®¾ç½®çåç§»éè°æ´ label
      this.adjustLabel(items);
    }

    // è¿è¡æ·»å ãæ´æ°ãéæ¯æä½
    const lastShapesMap = this.lastShapesMap;
    const shapesMap = this.shapesMap;
    each(shapesMap, (shape, id) => {
      if (shape.destroyed) {
        // label å¨å¸å±è°æ´ç¯èè¢«å é¤äºï¼doLayoutï¼
        delete shapesMap[id];
      } else {
        if (lastShapesMap[id]) {
          // å¾å½¢åçæ´æ°
          const data = shape.get('data');
          const origin = shape.get('origin');
          const coordinate = shape.get('coordinate');
          const currentAnimateCfg = shape.get('animateCfg');

          const currentShape = lastShapesMap[id]; // å·²ç»å¨æ¸²ææ ä¸ç shape
          updateLabel(currentShape, shapesMap[id], {
            data,
            origin,
            animateCfg: currentAnimateCfg,
            coordinate,
          });

          this.shapesMap[id] = currentShape; // ä¿å­å¼ç¨
        } else {
          // æ°çæç shape
          container.add(shape);

          const animateCfg = get(shape.get('animateCfg'), isUpdate ? 'enter' : 'appear');
          if (animateCfg) {
            doAnimate(shape, animateCfg, {
              toAttrs: {
                ...shape.attr(),
              },
              coordinate: shape.get('coordinate'),
            });
          }
        }
        delete lastShapesMap[id];
      }
    });

    // ç§»é¤
    each(lastShapesMap, (deleteShape) => {
      const animateCfg = get(deleteShape.get('animateCfg'), 'leave');
      if (animateCfg) {
        doAnimate(deleteShape, animateCfg, {
          toAttrs: null,
          coordinate: deleteShape.get('coordinate'),
        });
      } else {
        deleteShape.remove(true); // ç§»é¤
      }
    });

    this.lastShapesMap = shapesMap;
    offscreenGroup.destroy();
  }

  /** æ¸é¤å½å labels */
  public clear() {
    this.container.clear();
    this.shapesMap = {};
    this.lastShapesMap = {};
  }

  /** éæ¯ */
  public destroy() {
    this.container.destroy();
    this.shapesMap = null;
    this.lastShapesMap = null;
  }

  private renderLabel(cfg: LabelItem, container: IGroup) {
    const { id, elementId, data, mappingData, coordinate, animate, content } = cfg;
    const shapeAppendCfg = {
      id,
      elementId,
      data,
      origin: {
        ...mappingData,
        data: mappingData[FIELD_ORIGIN],
      },
      coordinate,
    };
    const labelGroup = container.addGroup({
      name: 'label',
      // å¦æ this.animate === false æè cfg.animate === false/null åä¸è¿è¡å¨ç»ï¼å¦åè¿è¡å¨ç»éç½®çåå¹¶
      animateCfg:
        this.animate === false || animate === null || animate === false ? false : deepMix({}, this.animate, animate),
      ...shapeAppendCfg,
    });
    let labelShape;
    if ((content.isGroup && content.isGroup()) || (content.isShape && content.isShape())) {
      // å¦æ content æ¯ Group æè Shapeï¼æ ¹æ® textAlign è°æ´ä½ç½®åï¼ç´æ¥å°å¶å å¥ labelGroup
      const { width, height } = content.getCanvasBBox();
      const textAlign = get(cfg, 'textAlign', 'left');

      let x = cfg.x;
      const y = cfg.y - height / 2;

      if (textAlign === 'center') {
        x = x - width / 2;
      } else if (textAlign === 'right' || textAlign === 'end') {
        x = x - width;
      }

      translate(content, x, y); // å° label å¹³ç§»è³ x, y æå®çä½ç½®
      labelShape = content;
      labelGroup.add(content);
    } else {
      const fill = get(cfg, ['style', 'fill']);
      labelShape = labelGroup.addShape('text', {
        attrs: {
          x: cfg.x,
          y: cfg.y,
          textAlign: cfg.textAlign,
          textBaseline: get(cfg, 'textBaseline', 'middle'),
          text: cfg.content,
          ...cfg.style,
          fill: isNull(fill) ? cfg.color : fill,
        },
        ...shapeAppendCfg,
      });
    }

    if (cfg.rotate) {
      rotate(labelShape, cfg.rotate);
    }
    this.shapesMap[id] = labelGroup;
  }

  // æ ¹æ®typeå¯¹labelå¸å±
  private doLayout(items: LabelItem[], shapes: Record<string, IShape | IGroup>) {
    if (this.layout) {
      const layouts = isArray(this.layout) ? this.layout : [this.layout];
      each(layouts, (layout: GeometryLabelLayoutCfg) => {
        const layoutFn = getGeometryLabelLayout(get(layout, 'type', ''));
        if (layoutFn) {
          const labelShapes = [];
          const geometryShapes = [];
          each(this.shapesMap, (labelShape, id) => {
            labelShapes.push(labelShape);
            geometryShapes.push(shapes[labelShape.get('elementId')]);
          });

          layoutFn(items, labelShapes, geometryShapes, this.region, layout.cfg);
        }
      });
    }
  }

  private renderLabelLine(labelItems: LabelItem[]) {
    each(labelItems, (labelItem) => {
      const coordinate: Coordinate = get(labelItem, 'coordinate');
      if (!labelItem || !coordinate) {
        return;
      }
      const center = coordinate.getCenter();
      const radius = coordinate.getRadius();
      if (!labelItem.labelLine) {
        // labelLine: null | falseï¼å³é­ label å¯¹åºç labelLine
        return;
      }
      const labelLineCfg = get(labelItem, 'labelLine', {});
      const id = labelItem.id;
      let path = labelLineCfg.path;
      if (!path) {
        const start = polarToCartesian(center.x, center.y, radius, labelItem.angle);
        path = [
          ['M', start.x, start.y],
          ['L', labelItem.x, labelItem.y],
        ];
      }
      const labelGroup = this.shapesMap[id];
      if (!labelGroup.destroyed) {
        labelGroup.addShape('path', {
          capture: false, // labelLine é»è®¤ä¸åä¸äºä»¶æè·
          attrs: {
            path,
            stroke: labelItem.color ? labelItem.color : get(labelItem, ['style', 'fill'], '#000'),
            fill: null,
            ...labelLineCfg.style,
          },
          id,
          origin: labelItem.mappingData,
          data: labelItem.data,
          coordinate: labelItem.coordinate,
        });
      }
    });
  }

  /**
   * ç»å¶æ ç­¾èæ¯
   * @param labelItems
   */
  private renderLabelBackground(labelItems: LabelItem[]) {
    each(labelItems, (labelItem) => {
      const coordinate: Coordinate = get(labelItem, 'coordinate');
      const background: LabelItem['background'] = get(labelItem, 'background');
      if (!background || !coordinate) {
        return;
      }

      const id = labelItem.id;
      const labelGroup = this.shapesMap[id];
      if (!labelGroup.destroyed) {
        const labelContentShape = labelGroup.getChildren()[0];
        if (labelContentShape) {
          const { rotation, ...box } = getlLabelBackgroundInfo(labelGroup, labelItem, background.padding);
          const backgroundShape = labelGroup.addShape('rect', {
            attrs: {
              ...box,
              ...(background.style || {}),
            },
            id,
            origin: labelItem.mappingData,
            data: labelItem.data,
            coordinate: labelItem.coordinate,
          });
          backgroundShape.setZIndex(-1);

          if (rotation) {
            const matrix = labelContentShape.getMatrix();
            backgroundShape.setMatrix(matrix);
          }
        }
      }
    });
  }

  private createOffscreenGroup() {
    const container = this.container;
    const GroupClass = container.getGroupBase(); // è·ååç»çæé å½æ°
    const newGroup = new GroupClass({});
    return newGroup;
  }

  private adjustLabel(items: LabelItem[]) {
    each(items, (item) => {
      if (item) {
        const id = item.id;
        const labelGroup = this.shapesMap[id];
        if (!labelGroup.destroyed) {
          // fix: å¦æè¯´å¼åèç label content æ¯ä¸ä¸ª groupï¼æ­¤å¤çåç§»æ æ³å¯¹ æ´ä¸ª content group çæï¼åºæ¯ç±»ä¼¼ é¥¼å¾ spider label æ¯ä¸ä¸ªå« 2 ä¸ª textShape ç gorup
          const labelShapes = labelGroup.findAll((ele) => ele.get('type') !== 'path');
          each(labelShapes, (labelShape) => {
            if (labelShape) {
              if (item.offsetX) {
                labelShape.attr('x', labelShape.attr('x') + item.offsetX);
              }
              if (item.offsetY) {
                labelShape.attr('y', labelShape.attr('y') + item.offsetY);
              }
            }
          });
        }
      }
    });
  }
}
