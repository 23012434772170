import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { getCoordinateClipCfg } from '../../util/coordinate';
import { AnimateExtraCfg } from '../interface';

/**
 * @ignore
 * æ´ä½å¨ç»
 * åå¥å¥åºå¨ç»ææ
 * @todo æ¾ä¸¤å¼ ç´è§åæ ç³»åæåæ ç³»çå¾
 * @param element åä¸å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function waveIn(element: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const { type, startState, endState } = getCoordinateClipCfg(cfg.coordinate, 20); // æ ¹æ®åæ ç³»ç±»åè·åæ´ä½çåªååºåéç½®ä¿¡æ¯
  const clipShape = element.setClip({
    type,
    attrs: startState,
  }) as IShape; // ä¸º shape è®¾ç½®åªååºå

  // å¯¹åªåå¾å½¢åå¨ç»
  clipShape.animate(endState, {
    ...animateCfg,
    callback: () => {
      if (element && !element.get('destroyed')) {
        element.set('clipShape', null);
      }
      clipShape.remove(true); // å¨ç»ç»æéè¦å°åªåå¾å½¢éæ¯
    },
  });
}
