import { deepMix } from '@antv/util';
import { createThemeByStyleSheet } from './create-by-style-sheet';
import { createLightStyleSheet } from '../style-sheet/light';
import { LooseObject, StyleSheetCfg } from '../../interface';

interface ThemeCfg extends LooseObject {
  styleSheet?: StyleSheetCfg;
}

export function createTheme(themeCfg: ThemeCfg): LooseObject {
  const { styleSheet: styleSheetCfg = {}, ...themeObject } = themeCfg;

  // â  åå»ºæ ·å¼è¡¨ (é»è®¤åå»º light çæ ·å¼è¡¨)
  const styleSheet = createLightStyleSheet(styleSheetCfg);
  // â¡ åå»ºä¸»é¢
  return deepMix({}, createThemeByStyleSheet(styleSheet), themeObject);
}
