import { Geometry } from '@antv/g2';
import { isArray, get, deepMix, isEqual } from '@antv/util';
import { interaction, animation, theme, tooltip, scale } from '../../adaptor/common';
import { Params } from '../../core/adaptor';
import { schema as schemaGeometry } from '../../adaptor/geometries';
import {
  deepAssign,
  flow,
  findGeometry,
  transformLabel,
  getAdjustAppendPadding,
  normalPadding,
  resolveAllPadding,
} from '../../utils';
import { Datum } from '../../types';
import { log, LEVEL } from '../../utils';
import { getColorMap, layoutVennData, islegalSets } from './utils';
import { CustomInfo, VennData, VennOptions } from './types';
import { ID_FIELD } from './constant';
import './shape';
import './label';
import './interactions';

/** å¾ä¾é»è®¤é¢çç©ºé´ */
export const LEGEND_SPACE = 40;

/**
 * è·å color æ å°
 */
function colorMap(params: Params<VennOptions>, data: VennData, colorPalette?: string[]) {
  const { chart, options } = params;
  const { blendMode, setsField } = options;
  const { colors10, colors20 } = chart.getTheme();
  let palette = colorPalette;
  if (!isArray(palette)) {
    palette = data.filter((d) => d[setsField].length === 1).length <= 10 ? colors10 : colors20;
  }
  const map = getColorMap(palette, data, blendMode, setsField);

  return (id: string) => map.get(id) || palette[0];
}

/**
 * color options è½¬æ¢
 */
function transformColor(params: Params<VennOptions>, data: VennData): VennOptions['color'] {
  const { options } = params;
  const { color } = options;

  if (typeof color !== 'function') {
    const colorPalette = typeof color === 'string' ? [color] : color;
    const map = colorMap(params, data, colorPalette);
    return (datum: Datum) => map(datum[ID_FIELD]);
  }
  return color;
}

/**
 * å¤ç padding
 */
function padding(params: Params<VennOptions>): Params<VennOptions> {
  const { chart, options } = params;
  const { legend, appendPadding, padding } = options;

  // å¤ç legend çä½ç½®. é»è®¤é¢ç 40px, ä¸å¡ä¸å¯ä»¥éè¿ appendPadding å¢å 
  let tempPadding: number[] = normalPadding(appendPadding);
  if (legend !== false) {
    tempPadding = getAdjustAppendPadding(appendPadding, get(legend, 'position'), LEGEND_SPACE);
  }

  chart.appendPadding = resolveAllPadding([tempPadding, padding]);

  return params;
}

/**
 * å¤çéæ³æ°æ®
 * @param params
 */
function data(params: Params<VennOptions>): Params<VennOptions> {
  const { options } = params;

  /* å¦éå° äº¤é ä¸­å­å¨ éæ³åç´  çæåµï¼å°±è¿æ»¤æ
   * å¦ï¼
   * data = [
   *   { sets: ['A'], size: 3 }, // éå
   *   { sets: ['B'], size: 4 }, // éå
   *   { sets: ['A', 'B'], size: 2 }, // äº¤é
   *   { sets: ['A', 'B', 'C'], size: 2 }, // äº¤é (å­å¨éæ³ Cï¼è¿æ»¤è¯¥æ¡æ°æ®)
   *   ...
   * ]
   */

  let data = options['data'];
  if (!data) {
    log(LEVEL.WARN, false, 'warn: %s', 'æ°æ®ä¸è½ä¸ºç©º');
    data = [];
  }

  // åæ³åç´ çéåï¼['A', 'B']
  const currSets = data.filter((datum) => datum.sets.length === 1).map((datum) => datum.sets[0]);
  // è¿æ»¤ data
  const filterSets = data.filter((datum) => {
    const sets = datum.sets;
    // å­å¨éæ³åç´ ï¼å°±è¿æ»¤è¿æ¡æ°æ®
    return islegalSets(currSets, sets);
  });

  if (!isEqual(filterSets, data)) log(LEVEL.WARN, false, 'warn: %s', 'äº¤éä¸­ä¸è½åºç°ä¸å­å¨çéå, è¯·è¾å¥åæ³æ°æ®');

  return deepMix({}, params, {
    options: {
      data: filterSets,
    },
  });
}

/**
 * geometry å¤ç
 * @param params
 */
function geometry(params: Params<VennOptions>): Params<VennOptions> {
  const { chart, options } = params;
  const { pointStyle, setsField, sizeField } = options;

  // è·åå®¹å¨å¤§å°
  const [t, r, b, l] = normalPadding(chart.appendPadding);
  // å¤ç legend çä½ç½®. é»è®¤é¢ç 40px, ä¸å¡ä¸å¯ä»¥éè¿ appendPadding å¢å 
  const customInfo: CustomInfo = { offsetX: l, offsetY: t };
  // coordinateBBox + appendPadding = viewBBox, ä¸éè¦åè®¡ç® appendPadding é¨åï¼å æ­¤ç´æ¥ä½¿ç¨ viewBBox
  const { width, height } = chart.viewBBox;
  // å¤çpaddingè¾å¥ä¸åççæåµï¼ w å h ä¸è½ä¸ºè´æ°
  const vennData: VennData = layoutVennData(options, Math.max(width - (r + l), 0), Math.max(height - (t + b), 0), 0);
  chart.data(vennData);

  const { ext } = schemaGeometry(
    deepAssign({}, params, {
      options: {
        xField: 'x',
        yField: 'y',
        sizeField: sizeField,
        seriesField: ID_FIELD,
        rawFields: [setsField, sizeField],
        schema: {
          shape: 'venn',
          style: pointStyle,
        },
      },
    })
  );

  const geometry = ext.geometry as Geometry;
  geometry.customInfo(customInfo);

  const colorOptions = transformColor(params, vennData);
  // é¦æ©å¾è¯ç¹, color ééåªè½æ å°ä¸ä¸ªå­æ®µ. éè¿å¤é¨æ¥æ¾è·å datum
  if (typeof colorOptions === 'function') {
    geometry.color(ID_FIELD, (id) => {
      const datum = vennData.find((d) => d[ID_FIELD] === id);
      const defaultColor = colorMap(params, vennData)(id);
      return colorOptions(datum, defaultColor);
    });
  }

  return params;
}

/**
 * å¤ç label
 * @param params
 */
function label(params: Params<VennOptions>): Params<VennOptions> {
  const { chart, options } = params;
  const { label } = options;

  // è·åå®¹å¨å¤§å°
  const [t, , , l] = normalPadding(chart.appendPadding);
  // ä¼ å¥ label å¸å±å½æ°æéç èªå®ä¹åæ°
  const customLabelInfo = { offsetX: l, offsetY: t };

  const geometry = findGeometry(chart, 'schema');

  if (!label) {
    geometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    geometry.label({
      fields: ['id'],
      callback,
      cfg: deepMix({}, transformLabel(cfg), {
        // ä½¿ç¨ G2 ç èªå®ä¹label ä¿®æ¹ä½ç½®
        type: 'venn',
        customLabelInfo,
      }),
    });
  }

  return params;
}

/**
 * legend éç½®
 * @param params
 */
export function legend(params: Params<VennOptions>): Params<VennOptions> {
  const { chart, options } = params;
  const { legend, sizeField } = options;

  chart.legend(ID_FIELD, legend);
  // å¼ºå¶ä¸å¼å¯ è¿ç»­å¾ä¾
  chart.legend(sizeField, false);

  return params;
}

/**
 * é»è®¤å³é­åæ è½´
 * @param params
 */
export function axis(params: Params<VennOptions>): Params<VennOptions> {
  const { chart } = params;
  chart.axis(false);

  return params;
}

/**
 * é¦æ©å¾ interaction äº¤äºééå¨
 */
function vennInteraction(params: Params<VennOptions>): Params<VennOptions> {
  const { options, chart } = params;
  const { interactions } = options;

  if (interactions) {
    const MAP = {
      'legend-active': 'venn-legend-active',
      'legend-highlight': 'venn-legend-highlight',
    };
    interaction(
      deepAssign({}, params, {
        options: {
          interactions: interactions.map((i) => ({
            ...i,
            type: MAP[i.type] || i.type,
          })),
        },
      })
    );
  }

  chart.removeInteraction('legend-active');
  chart.removeInteraction('legend-highlight');
  return params;
}

/**
 * å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<VennOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow(
    padding,
    theme,
    data,
    geometry,
    label,
    scale({}),
    legend,
    axis,
    tooltip,
    vennInteraction,
    animation
    // ... å¶ä»ç adaptor flow
  )(params);
}
