import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { getDataWhetherPecentage } from '../../utils/transform/percent';
import { AreaOptions } from './types';
import { adaptor, meta } from './adaptor';
import { DEFAULT_OPTIONS } from './constants';

export type { AreaOptions };

export class Area extends Plot<AreaOptions> {
  /**
   * è·å é¢ç§¯å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<AreaOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'area';

  /**
   * è·å é¢ç§¯å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Area.getDefaultOptions();
  }

  /**
   * @override
   * @param data
   */
  public changeData(data: AreaOptions['data']) {
    this.updateOption({ data });
    const { isPercent, xField, yField } = this.options;
    const { chart, options } = this;
    meta({ chart, options });
    this.chart.changeData(getDataWhetherPecentage(data, yField, xField, yField, isPercent));
  }

  /**
   * è·å é¢ç§¯å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<AreaOptions> {
    return adaptor;
  }
}
