import { isFunction, clone } from '@antv/util';
import { Params } from '../../core/adaptor';
import { interaction, animation, theme, scale, annotation, tooltip } from '../../adaptor/common';
import { getLocale } from '../../core/locale';
import { Datum } from '../../types';
import { flow, deepAssign } from '../../utils';
import { conversionTagFormatter } from '../../utils/conversion';
import { FunnelOptions } from './types';
import { basicFunnel } from './geometries/basic';
import { compareFunnel } from './geometries/compare';
import { facetFunnel } from './geometries/facet';
import { dynamicHeightFunnel } from './geometries/dynamic-height';
import { FUNNEL_CONVERSATION, FUNNEL_PERCENT } from './constant';

/**
 *
 * åå¼æ¼æå¾geometryå®ç°ç»èæè¾å¤§ä¸å,
 * 1. æ®éæ¼æå¾ï¼interval.shape('funnel')
 * 2. å¯¹æ¯æ¼æå¾ï¼åé¢
 * 3. å¨æé«åº¦æ¼æå¾ï¼polypon
 * 4. åé¢æ¼æå¾ï¼æ®é + list åé¢
* /

/**
 * options å¤ç
 * @param params
 */
function defaultOptions(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { options } = params;
  const { compareField, xField, yField, locale, funnelStyle, data } = options;
  const i18n = getLocale(locale);

  const defaultOption = {
    label: compareField
      ? {
          fields: [xField, yField, compareField, FUNNEL_PERCENT, FUNNEL_CONVERSATION],
          formatter: (datum) => `${datum[yField]}`,
        }
      : {
          fields: [xField, yField, FUNNEL_PERCENT, FUNNEL_CONVERSATION],
          offset: 0,
          position: 'middle',
          formatter: (datum) => `${datum[xField]} ${datum[yField]}`,
        },
    tooltip: {
      title: xField,
      formatter: (datum) => {
        return { name: datum[xField], value: datum[yField] };
      },
    },
    conversionTag: {
      // conversionTag çè®¡ç®åæ¾ç¤ºé»è¾ç»ä¸ä¿æä¸è´
      formatter: (datum) =>
        `${i18n.get(['conversionTag', 'label'])}: ${conversionTagFormatter(
          ...(datum[FUNNEL_CONVERSATION] as [number, number])
        )}`,
    },
  };

  // æ¼æå¾æ ·å¼
  let style;
  if (compareField || funnelStyle) {
    style = (datum: Datum) => {
      return deepAssign(
        {},
        // å¯¹æ¯æ¼æå¾é»è®¤æè¾¹
        compareField && { lineWidth: 1, stroke: '#fff' },
        isFunction(funnelStyle) ? funnelStyle(datum) : funnelStyle
      );
    };
  }

  return deepAssign({ options: defaultOption }, params, { options: { funnelStyle: style, data: clone(data) } });
}

/**
 * geometryå¤ç
 * @param params
 */
function geometry(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { options } = params;
  const { compareField, dynamicHeight, seriesField } = options;
  if (seriesField) {
    return facetFunnel(params);
  }
  if (compareField) {
    return compareFunnel(params);
  }
  if (dynamicHeight) {
    return dynamicHeightFunnel(params);
  }

  return basicFunnel(params);
}

/**
 * meta éç½®
 * @param params
 */
export function meta(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { options } = params;
  const { xAxis, yAxis, xField, yField } = options;

  return flow(
    scale({
      [xField]: xAxis,
      [yField]: yAxis,
    })
  )(params);
}

/**
 * åæ è½´
 * @param params
 */
function axis(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { chart } = params;
  chart.axis(false);
  return params;
}

/**
 * legend éç½®
 * @param params
 */
function legend(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { chart, options } = params;
  const { legend } = options;

  if (legend === false) {
    chart.legend(false);
  } else {
    chart.legend(legend);
    // TODO FIX: legend-click æ¶é´åè½¬åçç»ä»¶ä¹é´çå³è
  }

  return params;
}

/**
 * æ¼æå¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<FunnelOptions>) {
  return flow(
    defaultOptions,
    geometry,
    meta,
    axis,
    tooltip,
    interaction,
    legend,
    animation,
    theme,
    annotation()
  )(params);
}
