import Path, { PathCfg } from './path';
/** å¼å¥ Path å¯¹åºç ShapeFactory */
import './shape/line';

/**
 * Line å ä½æ è®°ã
 * å¸¸ç¨äºæçº¿å¾çç»å¶ã
 */
export default class Line extends Path {
  public type: string = 'line';

  constructor(cfg: PathCfg) {
    super(cfg);

    const { sortable = false } = cfg; // å³é­é»è®¤ç X è½´æ°æ®æåº
    this.sortable = sortable;
  }
}
