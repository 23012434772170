import { lowerCase } from '@antv/util';
import { FacetCtor } from '../interface';
export { Facet } from './facet';

/**
 * ææç Facet ç±»
 */
const Facets: Record<string, FacetCtor> = {};

/**
 * æ ¹æ® type è·å facet ç±»
 * @param type åé¢ç±»å
 */
export const getFacet = (type: string): FacetCtor => {
  return Facets[lowerCase(type)];
};

/**
 * æ³¨åä¸ä¸ª Facet ç±»
 * @param type åé¢ç±»å
 * @param ctor åé¢ç±»
 */
export const registerFacet = (type: string, ctor: FacetCtor) => {
  Facets[lowerCase(type)] = ctor;
};
