import { each } from '@antv/util';
/**
 * @file å¨å±çä¸äºåéå®ä¹ï¼å«å½éåãä¸»é¢...
 */
export const GLOBAL = {
  /** å¨å±è¯­è¨ */
  locale: 'en-US',
};

/**
 * å¨å±åéè®¾ç½®
 * @param key
 * @param value
 */
export function setGlobal(datum: Record<string, any>): void {
  each(datum, (v, k) => (GLOBAL[k] = v));
}
