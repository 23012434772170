import { each } from '@antv/util';
import { View } from '../../../../chart';
import { Point } from '../../../../interface';
import { getSiblingPoint, getSilbings } from '../../util';
import TooltipAction from './geometry';

/**
 * å­å¨å¤ä¸ª view æ¶ï¼æ§å¶å¶ä» view ä¸ç tooltip æ¾ç¤º
 * @ignore
 */
class SiblingTooltip extends TooltipAction {
  /**
   * ææåä¸å±çº§ç tooltip æ¾ç¤º
   * @param view
   * @param point
   */
  protected showTooltip(view: View, point: Point) {
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      const siblingPoint = getSiblingPoint(view, sibling, point);
      sibling.showTooltip(siblingPoint);
    });
  }
  /**
   * éèåä¸å±çº§ç tooltip
   * @param view
   */
  protected hideTooltip(view) {
    const siblings = getSilbings(view);
    each(siblings, (sibling) => {
      sibling.hideTooltip();
    });
  }
}

export default SiblingTooltip;
