import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { WordCloudOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { processImageMask, transform } from './utils';
// æ³¨åçshape
import './shapes/word-cloud';

export type { WordCloudOptions };

export class WordCloud extends Plot<WordCloudOptions> {
  /**
   * è·å è¯äºå¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<WordCloudOptions> {
    return DEFAULT_OPTIONS;
  }

  /** è¯äºå¾ */
  public type: string = 'word-cloud';

  /**
   * @override
   * @param data
   */
  public changeData(data) {
    this.updateOption({ data });
    if (this.options.imageMask) {
      this.render();
    } else {
      this.chart.changeData(transform({ chart: this.chart, options: this.options }));
    }
  }

  /**
   * è·åé»è®¤ç options éç½®é¡¹
   */
  protected getDefaultOptions(): Partial<WordCloudOptions> {
    return WordCloud.getDefaultOptions();
  }

  /**
   * è¦åç¶ç±»æ¹æ³ï¼è¯äºå¾éè¦å è½½å¾çèµæºï¼æä»¥éè¦å¼æ­¥æ¸²æ
   */
  public render() {
    return new Promise<void>((res) => {
      const { imageMask } = this.options;

      if (!imageMask) {
        // è°ç¨ç¶ç±»æ¸²æå½æ°
        super.render();
        res();
        return;
      }

      const handler = (img: HTMLImageElement) => {
        this.options = {
          ...this.options,
          imageMask: img || null,
        };

        // è°ç¨ç¶ç±»æ¸²æå½æ°
        super.render();
        res();
      };

      processImageMask(imageMask).then(handler).catch(handler);
    });
  }

  /**
   * è·å è¯äºå¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<WordCloudOptions> {
    return adaptor;
  }

  /**
   * è¦åç¶ç±»çæ¹æ³ï¼å ä¸ºè¯äºå¾ä½¿ç¨ åç¬çå½æ° è¿è¡å¸å±ï¼åçä¸æäºä¸ä¸æ ·
   */
  protected triggerResize() {
    if (!this.chart.destroyed) {
      // å½æ´ä¸ªè¯äºå¾å¾è¡¨çå®½é«ä¿¡æ¯åçååæ¶ï¼æ¯ä¸ªè¯è¯­çåæ 
      // éè¦éæ°æ§è¡ adaptorï¼ä¸ç¶ä¼åºç°å¸å±éä¹±ï¼
      // å¦ç¸é»è¯è¯­éå çæåµã
      this.execAdaptor();

      // å»¶è¿æ§è¡ï¼æå©äºå¨ç»æ´æµç
      // TODO: å¨å¤æ¬¡æ´æ¹ç»å¸å°ºå¯¸æ¶ï¼å¨ç»ä¼è¶æ¥è¶å¡é¡¿ï¼åå æªç¥
      window.setTimeout(() => {
        // æ§è¡ç¶ç±»çæ¹æ³
        super.triggerResize();
      });
    }
  }
}
