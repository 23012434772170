import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { MixOptions } from './types';
import { adaptor } from './adaptor';
import './interactions';

export type { MixOptions };

/**
 * å¤å¾å±å¾å½¢ï¼éæ¾ G2 80% çåè½ï¼å¯ä»¥ç¨æ¥åï¼
 * 1. å¾å±å å çå¾ï¼
 *   - æçº¿ + ç½®ä¿¡åº¦åºé´è¿­ä»£
 *   - åµå¥é¥¼å¾
 *   - ...
 * 2. å¾å±ååçå¾
 *   - å¤ç»´å¾
 *   - æ±é¥¼ç»åå¾
 *   - ...
 */
export class Mix extends Plot<MixOptions> {
  /** å¾è¡¨ç±»å */
  public type: string = 'mix';

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<MixOptions> {
    return adaptor;
  }
}
