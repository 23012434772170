import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { VennOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';

export type { VennOptions };

/**
 * è¿ä¸ªæ¯ä¸ä¸ªå¾è¡¨å¼åç æ¨¡æ¿ä»£ç ï¼
 */
export class Venn extends Plot<VennOptions> {
  /** å¾è¡¨ç±»å */
  public type: string = 'venn';

  static getDefaultOptions() {
    return DEFAULT_OPTIONS;
  }

  /**
   * è·å é¦æ©å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Venn.getDefaultOptions();
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<VennOptions> {
    return adaptor;
  }

  /**
   * è¦åç¶ç±»çæ¹æ³
   */
  protected triggerResize() {
    if (!this.chart.destroyed) {
      // é¦åèªéåºå®¹å¨çå®½é«
      this.chart.forceFit(); // g2 åé¨æ§è¡ changeSizeï¼changeSize ä¸­æ§è¡ render(true)
      this.chart.clear();
      this.execAdaptor(); // æ ¸å¿ï¼å®½é«æ´æ°ä¹åè®¡ç®å¸å±
      // æ¸²æ
      this.chart.render(true);
    }
  }
}
