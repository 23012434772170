import { PaddingCalCtor } from '../layout/padding-cal';
import { View } from '../view';

/**
 * é»è®¤ç syncViewPadding é»è¾
 * @param chart
 * @param views
 * @param PC: PaddingCalCtor
 */
export function defaultSyncViewPadding(chart: View, views: View[], PC: PaddingCalCtor) {
  const syncPadding = PC.instance();

  // ææç view ç autoPadding æååä¸ä¸ªå¼ç¨
  views.forEach((v: View) => {
    v.autoPadding = syncPadding.max(v.autoPadding.getPadding());
  });
}
