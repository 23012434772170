export default {
  base: {
    remove: 'UsuÅ',
    cancel: 'Anuluj',
    confirm: 'PotwierdÅº',
    inert: 'Wstaw',
    width: 'SzerokoÅÄ',
    height: 'WysokoÅÄ'
  },
  controls: {
    clear: 'WyczyÅÄ',
    undo: 'Cofnij',
    redo: 'PrzywrÃ³Ä',
    fontSize: 'WielkoÅÄ',
    color: 'Kolor',
    textColor: 'Kolor tekstu',
    tempColors: 'Kolory',
    backgroundColor: 'TÅo',
    bold: 'Pogrubienie',
    lineHeight:'WysokoÅÄ linii',
    letterSpacing:'OdstÄp znakÃ³w',
    textIndent:'WciÄcie tekstu',
    increaseIndent: 'ZwiÄksz wciÄcie',
    decreaseIndent: 'Zmniejsz wciÄcie',
    italic: 'Italiki',
    underline: 'PodkreÅlenie',
    strikeThrough: 'PrzekreÅlenie',
    fontFamily: 'Czcionka',
    textAlign: 'WyrÃ³wnanie tekstu',
    alignLeft: 'Do lewej',
    alignCenter: 'Wycentruj',
    alignRight: 'Do prawej',
    alignJustify: 'Wyjustuj',
    floatLeft: 'Do lewej',
    floatRight: 'Do prawej',
    superScript: 'Superskrypt',
    subScript: 'Subskrypt',
    removeStyles: 'UsuÅ stylowanie',
    headings: 'NagÅÃ³wki',
    header: 'NagÅÃ³wek',
    normal: 'Normalny',
    orderedList: 'Lista uporzÄdkowana',
    unorderedList: 'Lista nieuporzÄdkowana',
    blockQuote: 'Cytat',
    code: 'Kod',
    link: 'Link',
    unlink: 'UsuÅ link',
    hr: 'Linia pozioma',
    media: 'Media',
    mediaLibirary: 'Biblioteka mediÃ³w',
    emoji: 'Emoji'
  },
  linkEditor: {
    textInputPlaceHolder: 'Wpisz tekst linku',
    linkInputPlaceHolder: 'Wpisz Adres URL',
    inputWithEnterPlaceHolder: 'Wpisz adres URL i naciÅnij Enter',
    openInNewWindow: 'OtwÃ³rz w nowym oknie',
    removeLink: 'UsuÅ link'
  },
  audioPlayer: {
    title: 'OdtwÃ³rz audio'
  },
  videoPlayer: {
    title: 'OdtwÃ³rz wideo',
    embedTitle: 'TytuÅ'
  },
  media: {
    image: 'Obraz',
    video: 'Wideo',
    audio: 'Audio',
    embed: 'Obiekt osadzony'
  }
}