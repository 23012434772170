import { each, isArray } from '@antv/util';
import { View } from '../../chart';
import { BBox, PathCommand, Point } from '../../dependents';
import Geometry from '../../geometry/base';
import Element from '../../geometry/element/';
import { catmullRom2bezier, getLinePath } from '../../geometry/shape/util/path';
import { toPoints } from '../../util/bbox';
import { isPolygonsIntersect } from '@antv/path-util';
import { ComponentOption, IInteractionContext, LooseObject } from '../../interface';

function getMaskBBox(context: IInteractionContext, tolerance: number) {
  const event = context.event;
  const maskShape = event.target;
  const maskBBox = maskShape.getCanvasBBox();
  // å¦æ bbox è¿å°åä¸è¿å
  if (!(maskBBox.width >= tolerance || maskBBox.height >= tolerance)) {
    return null;
  }
  return maskBBox;
}

function getMaskPath(context: IInteractionContext, tolerance: number) {
  const event = context.event;
  const maskShape = event.target;
  const maskBBox = maskShape.getCanvasBBox();
  // å¦æ bbox è¿å°åä¸è¿å
  if (!(maskBBox.width >= tolerance || maskBBox.height >= tolerance)) {
    return null;
  }
  return maskShape.attr('path');
}

/**
 * è·åå½åäºä»¶ç¸å³çå¾è¡¨åç´ 
 * @param context äº¤äºçä¸ä¸æ
 * @ignore
 */
export function getCurrentElement(context: IInteractionContext): Element {
  const event = context.event;
  let element;
  const target = event.target;
  if (target) {
    element = target.get('element');
  }
  return element;
}

/**
 * è·åå§æå¯¹è±¡
 * @param context ä¸ä¸æ
 * @ignore
 */
export function getDelegationObject(context: IInteractionContext): LooseObject {
  const event = context.event;
  const target = event.target;
  let delegateObject;
  if (target) {
    delegateObject = target.get('delegateObject');
  }
  return delegateObject;
}

export function isElementChange(context: IInteractionContext) {
  const event = context.event.gEvent;
  // å¨åä¸ä¸ª element åé¨ç§»å¨ï¼label å shape ä¹é´
  if (event && event.fromShape && event.toShape && event.fromShape.get('element') === event.toShape.get('element')) {
    return false;
  }
  return true;
}

/**
 * æ¯å¦æ¯åè¡¨ç»ä»¶
 * @param delegateObject å§æå¯¹è±¡
 * @ignore
 */
export function isList(delegateObject: LooseObject): boolean {
  return delegateObject && delegateObject.component && delegateObject.component.isList();
}

/**
 * æ¯å¦æ¯æ»åç»ä»¶
 * @param delegateObject å§æå¯¹è±¡
 * @ignore
 */
export function isSlider(delegateObject: LooseObject): boolean {
  return delegateObject && delegateObject.component && delegateObject.component.isSlider();
}

/**
 * æ¯å¦ç± mask è§¦å
 * @param context ä¸ä¸æ
 * @ignore
 */
export function isMask(context: IInteractionContext): boolean {
  const event = context.event;
  const target = event.target;
  return target && target.get('name') === 'mask';
}

/**
 * è·åè¢«é®æ¡ç elements
 * @param context ä¸ä¸æ
 * @ignore
 */
export function getMaskedElements(context: IInteractionContext, tolerance: number): Element[] {
  const target = context.event.target;
  if (target.get('type') === 'path') {
    const maskPath = getMaskPath(context, tolerance);
    if (!maskPath) {
      return;
    }
    return getElementsByPath(context.view, maskPath);
  }
  const maskBBox = getMaskBBox(context, tolerance);
  // å¦æ bbox è¿å°åä¸è¿å
  if (!maskBBox) {
    return null;
  }
  return getIntersectElements(context.view, maskBBox);
}

/**
 * @ignore
 */
export function getSiblingMaskElements(context: IInteractionContext, sibling: View, tolerance: number) {
  const maskBBox = getMaskBBox(context, tolerance);
  // å¦æ bbox è¿å°åä¸è¿å
  if (!maskBBox) {
    return null;
  }
  const view = context.view;
  const start = getSiblingPoint(view, sibling, { x: maskBBox.x, y: maskBBox.y });
  const end = getSiblingPoint(view, sibling, { x: maskBBox.maxX, y: maskBBox.maxY });
  const box = {
    minX: start.x,
    minY: start.y,
    maxX: end.x,
    maxY: end.y,
  };
  return getIntersectElements(sibling, box);
}

/**
 * è·åææçå¾è¡¨åç´ 
 * @param view View/Chart
 * @ignore
 */
export function getElements(view: View): Element[] {
  const geometries = view.geometries;
  let rst: Element[] = [];
  each(geometries, (geom: Geometry) => {
    const elements = geom.elements;
    rst = rst.concat(elements);
  });
  if (view.views && view.views.length) {
    each(view.views, (subView) => {
      rst = rst.concat(getElements(subView));
    });
  }
  return rst;
}

/**
 * è·åææçå¾è¡¨åç´ 
 * @param view View/Chart
 * @param field å­æ®µå
 * @param value å­æ®µå¼
 * @ignore
 */
export function getElementsByField(view: View, field: string, value: any) {
  const elements = getElements(view);
  return elements.filter((el) => {
    return getElementValue(el, field) === value;
  });
}

/**
 * æ ¹æ®ç¶æåè·åå¾è¡¨åç´ 
 * @param view View/Chart
 * @param stateName ç¶æå
 * @ignore
 */
export function getElementsByState(view: View, stateName: string): Element[] {
  const geometries = view.geometries;
  let rst: Element[] = [];
  each(geometries, (geom: Geometry) => {
    const elements = geom.getElementsBy((el) => el.hasState(stateName));
    rst = rst.concat(elements);
  });
  return rst;
}

/**
 * è·åå¾è¡¨åç´ å¯¹åºå­æ®µçå¼
 * @param element å¾è¡¨åç´ 
 * @param field å­æ®µå
 * @ignore
 */
export function getElementValue(element: Element, field) {
  const model = element.getModel();
  const record = model.data;
  let value;
  if (isArray(record)) {
    value = record[0][field];
  } else {
    value = record[field];
  }
  return value;
}

/**
 * ä¸¤ä¸ªåå´çæ¯å¦ç¸äº¤
 * @param box1 åå´ç1
 * @param box2 åå´ç2
 * @ignore
 */
export function intersectRect(box1, box2) {
  return !(box2.minX > box1.maxX || box2.maxX < box1.minX || box2.minY > box1.maxY || box2.maxY < box1.minY);
}

/**
 * è·ååå´çåçå¾è¡¨åç´ 
 * @param view View/Chart
 * @param box åå´ç
 * @ignore
 */
export function getIntersectElements(view: View, box) {
  const elements = getElements(view);
  const rst = [];
  each(elements, (el) => {
    const shape = el.shape;
    const shapeBBox = shape.getCanvasBBox();
    if (intersectRect(box, shapeBBox)) {
      rst.push(el);
    }
  });
  return rst;
}
function pathToPoints(path: any[]) {
  const points = [];
  each(path, (seg) => {
    const command = seg[0];
    if (command !== 'A') {
      for (let i = 1; i < seg.length; i = i + 2) {
        points.push([seg[i], seg[i + 1]]);
      }
    } else {
      const length = seg.length;
      points.push([seg[length - 2], seg[length - 1]]);
    }
  });
  return points;
}
/**
 * è·ååå´çåçå¾è¡¨åç´ 
 * @param view View/Chart
 * @param path è·¯å¾
 * @ignore
 */
export function getElementsByPath(view: View, path: any[]) {
  const elements = getElements(view);
  const points = pathToPoints(path);
  const rst = elements.filter((el: Element) => {
    const shape = el.shape;
    let shapePoints;
    if (shape.get('type') === 'path') {
      shapePoints = pathToPoints(shape.attr('path'));
    } else {
      const shapeBBox = shape.getCanvasBBox();
      shapePoints = toPoints(shapeBBox);
    }
    return isPolygonsIntersect(points, shapePoints);
  });
  return rst;
}

/**
 * è·åå½å View çææç»ä»¶
 * @param view View/Chart
 * @ignore
 */
export function getComponents(view) {
  return view.getComponents().map((co: ComponentOption) => co.component);
}

/** @ignore */
export function distance(p1: Point, p2: Point) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

/** @ignore */
export function getSpline(points: Point[], z: boolean): PathCommand[] {
  if (points.length <= 2) {
    return getLinePath(points, false);
  }
  const first = points[0];
  const arr = [];
  each(points, (point) => {
    arr.push(point.x);
    arr.push(point.y);
  });
  const path = catmullRom2bezier(arr, z, null);
  path.unshift(['M', first.x, first.y]);
  return path;
}

/**
 * æ£æµç¹æ¯å¦å¨åå´çå
 * @param box åå´ç
 * @param point ç¹
 * @ignore
 */
export function isInBox(box: BBox, point: Point) {
  return box.x <= point.x && box.maxX >= point.x && box.y <= point.y && box.maxY > point.y;
}

/**
 * è·åå view åä¸çº§ç views
 * @param view å½å view
 * @returns åä¸çº§ç views
 * @ignore
 */
export function getSilbings(view: View): View[] {
  const parent = view.parent;
  let siblings = null;
  if (parent) {
    siblings = parent.views.filter((sub) => sub !== view);
  }
  return siblings;
}

function point2Normalize(view: View, point: Point): Point {
  const coord = view.getCoordinate();
  return coord.invert(point);
}
/**
 * å° view ä¸çä¸ç¹è½¬æ¢æå¦ä¸ä¸ª view çç¹
 * @param view å½åç view
 * @param sibling åä¸å±çº§ç view
 * @param point æå®ç¹
 * @ignore
 */
export function getSiblingPoint(view: View, sibling: View, point: Point): Point {
  const normalPoint = point2Normalize(view, point);
  return sibling.getCoordinate().convert(normalPoint);
}

/**
 * æ¯å¦å¨è®°å½ä¸­ï¼ä¸´æ¶å ä¸ºææç view ä¸­çæ°æ®ä¸æ¯å¼ç¨ï¼èä½¿ç¨çæ¹æ³
 * ä¸å view ä¸å¯¹æ°æ®çå¼ç¨ä¸ç¸ç­ï¼å¯¼è´æ æ³ç´æ¥ç¨ includes
 * åè®¾ x, y å¼ç¸ç­æ¶æ¯åä¸æ¡æ°æ®ï¼è¿ä¸ªåè®¾ä¸å®å¨æ­£ç¡®ï¼èæ¹æ isEqual åææ¬å¤ªé«
 * åé¢æ¹æåä¸ä¸ªå¼ç¨æ¶å¯ä»¥ä¿®æ¹åæ¥
 * @param records
 * @param record
 * @param xFiled
 * @param yField
 * @returns
 * @ignore
 */
export function isInRecords(records: object[], record: object, xFiled: string, yField: string) {
  let isIn = false;
  each(records, (r) => {
    if (r[xFiled] === record[xFiled] && r[yField] === record[yField]) {
      isIn = true;
      return false;
    }
  });
  return isIn;
}

// çº§èè·å field å¯¹åºç scaleï¼å¦æ view ä¸æ²¡æï¼éåå­ view
export function getScaleByField(view: View, field: string) {
  let scale = view.getScaleByField(field);
  if (!scale && view.views) {
    each(view.views, (subView) => {
      scale = getScaleByField(subView, field);
      if (scale) {
        return false; // ç»æ­¢å¾ªç¯
      }
    });
  }
  return scale;
}
