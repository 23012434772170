import { get, lowerCase } from '@antv/util';
import { LooseObject } from '../interface';

import { createTheme } from './util';

const defaultTheme = createTheme({});

// ææå·²ç»å­å¨çä¸»é¢
const Themes: Record<string, LooseObject> = {
  default: defaultTheme,
};

/**
 * è·åä¸»é¢éç½®ä¿¡æ¯ã
 * @param theme ä¸»é¢å
 */
export function getTheme(theme?: string): LooseObject {
  return get(Themes, lowerCase(theme), Themes.default);
}

/**
 * æ³¨åæ°çä¸»é¢éç½®ä¿¡æ¯ã
 * @param theme ä¸»é¢åã
 * @param value å·ä½çä¸»é¢éç½®ã
 */
export function registerTheme(theme: string, value: LooseObject) {
  Themes[lowerCase(theme)] = createTheme(value);
}
