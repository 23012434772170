import { ActionCallback, IInteractionContext, LooseObject } from '../../interface';
import Action from './base';
import CallbackAction from './callback';
import { get } from '@antv/util';

/** Action æé å½æ° */
type ActionConstructor = new (context: IInteractionContext, cfg?: LooseObject) => Action;

/** @ignore */
interface ActionOption {
  ActionClass: ActionConstructor;
  cfg: LooseObject;
}

// Action ç±»çç¼å­
const ActionCache: Record<string, ActionOption> = {};

/**
 * æ ¹æ®åç§°è·å Action å®ä¾
 * @param actionName - action çåç§°
 * @param context ä¸ä¸æ
 * @returns Action å®ä¾
 */
export function createAction(actionName: string, context: IInteractionContext): Action {
  const actionOption = ActionCache[actionName];
  let action = null;
  if (actionOption) {
    const { ActionClass, cfg } = actionOption;
    action = new ActionClass(context, cfg);
    action.name = actionName;
    action.init();
  }
  return action;
}

/**
 * æ ¹æ® action ç name è·åå®ä¹çç±»
 * @param actionName action ç name
 */
export function getActionClass(actionName: string): ActionConstructor {
  const actionOption = ActionCache[actionName];
  return get(actionOption, 'ActionClass');
}

/**
 * æ³¨å Action
 * @param actionName - action çåç§°
 * @param ActionClass - ç»§æ¿èª action çç±»
 */
export function registerAction(actionName: string, ActionClass: ActionConstructor, cfg?: LooseObject) {
  ActionCache[actionName] = {
    ActionClass,
    cfg,
  };
}

/**
 * åæ¶æ³¨å Action
 * @param actionName action åç§°
 */
export function unregisterAction(actionName: string) {
  delete ActionCache[actionName];
}

/**
 * æ ¹æ®åè°å½æ°è·å Action å®ä¾
 * @param callback - action çåè°å½æ°
 * @param context ä¸ä¸æ
 * @returns Action å®ä¾
 */
export function createCallbackAction(callback: ActionCallback, context: IInteractionContext): CallbackAction {
  const action = new CallbackAction(context);
  action.callback = callback;
  action.name = 'callback';
  return action;
}
