/**
 * @fileoverview è®¾ç½®ç»å¸çç®­å¤´ï¼åçï¼https://www.w3school.com.cn/jsref/prop_style_cursor.asp
 * @author dxq613
 */
import Action from './base';

/**
 * é¼ æ å½¢ç¶ç Action
 * @ignore
 */
class CursorAction extends Action {
  private setCursor(cursor) {
    const view = this.context.view;
    view.getCanvas().setCursor(cursor);
  }

  /**
   * é»è®¤åæ ï¼éå¸¸æ¯ä¸ä¸ªç®­å¤´ï¼
   */
  public default() {
    this.setCursor('default');
  }

  /** åæ åç°ä¸ºæç¤ºé¾æ¥çæéï¼ä¸åªæï¼ */
  public pointer() {
    this.setCursor('pointer');
  }
  /** æ­¤åæ æç¤ºæå¯¹è±¡å¯è¢«ç§»å¨ã */
  public move() {
    this.setCursor('move');
  }
  /** åæ åç°ä¸ºåå­çº¿ã */
  public crosshair() {
    this.setCursor('crosshair');
  }
  /** æ­¤åæ æç¤ºç¨åºæ­£å¿ï¼éå¸¸æ¯ä¸åªè¡¨ææ²æ¼ï¼ã */
  public wait() {
    this.setCursor('wait');
  }

  /** æ­¤åæ æç¤ºå¯ç¨çå¸®å©ï¼éå¸¸æ¯ä¸ä¸ªé®å·æä¸ä¸ªæ°çï¼ã */
  public help() {
    this.setCursor('help');
  }

  /** æ­¤åæ æç¤ºææ¬ã */
  public text() {
    this.setCursor('text');
  }

  /**
   * æ­¤åæ æç¤ºç©å½¢æ¡çè¾¹ç¼å¯è¢«åå³ï¼ä¸ï¼ç§»å¨ã
   */
  public eResize() {
    this.setCursor('e-resize');
  }

  /**
   * æ­¤åæ æç¤ºç©å½¢æ¡çè¾¹ç¼å¯è¢«åå·¦ï¼è¥¿ï¼ç§»å¨ã
   */
  public wResize() {
    this.setCursor('w-resize');
  }

  /**
   * æ­¤åæ æç¤ºç©å½¢æ¡çè¾¹ç¼å¯è¢«åä¸ï¼åï¼ç§»å¨ã
   */
  public nResize() {
    this.setCursor('n-resize');
  }

  /**
   * æ­¤åæ æç¤ºç©å½¢æ¡çè¾¹ç¼å¯è¢«åä¸ï¼åï¼ç§»å¨ã
   */
  public sResize() {
    this.setCursor('s-resize');
  }
  /**
   * åæ æç¤ºå¯ç§»å¨çæ¹å å³ä¸æ¹ï¼ä¸åï¼
   */
  public neResize() {
    this.setCursor('ne-resize');
  }
  /**
   * åæ æç¤ºå¯ç§»å¨çæ¹å å·¦ä¸æ¹ï¼è¥¿åï¼
   */
  public nwResize() {
    this.setCursor('nw-resize');
  }
  /**
   * åæ æç¤ºå¯ç§»å¨çæ¹åå³ä¸æ¹ï¼ä¸åï¼
   */
  public seResize() {
    this.setCursor('se-resize');
  }
  /**
   * åæ æç¤ºå¯ç§»å¨çæ¹åå·¦ä¸æ¹ï¼è¥¿åï¼
   */
  public swResize() {
    this.setCursor('sw-resize');
  }

  /**
   * åæ æç¤ºå¯ä»¥å¨ä¸ä¸æ¹åç§»å¨
   */
  public nsResize() {
    this.setCursor('ns-resize');
  }
  /**
   * åæ æç¤ºå¯ä»¥å¨å·¦å³æ¹åç§»å¨
   */
  public ewResize() {
    this.setCursor('ew-resize');
  }
}

export default CursorAction;
