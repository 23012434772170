import { Params } from '../../core/adaptor';
import { getTooltipMapping } from '../../utils/tooltip';
import { deepAssign } from '../../utils';
import { geometry, MappingOptions, GeometryOptions } from './base';

export interface EdgeGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µ */
  readonly yField?: string;
  /** åç»é¢è²å­æ®µ */
  readonly seriesField?: string;
  /** edge å¾å½¢æ å°è§å */
  readonly edge?: MappingOptions;
}

/**
 * edge çéç½®å¤ç
 * @param params
 */
export function edge<O extends EdgeGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const { edge, xField, yField, seriesField, tooltip } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField]);

  return edge
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'edge',
            colorField: seriesField,
            tooltipFields: fields,
            mapping: {
              tooltip: formatter,
              ...edge,
            },
          },
        })
      )
    : params;
}
