/**
 * å¯¹æ¯å½ååç´ åä¹åçåç´ ï¼è¿å added, updated, removed
 * @param keyItem ä¹åçåç´ çï¼æç§ key-item ç object çå½¢å¼å­å¨
 * @param keys ç°å¨çåç´ ï¼æç§ array çå½¢å¼å­å¨
 * @returns ç± added, updated, removed array ææç object
 */
export function diff(keyItem: Record<string, any>, keys: string[]) {
  const added = [];
  const updated = [];
  const removed = [];
  const keyIncluded = new Map<string, boolean>();

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (keyItem[key]) updated.push(key);
    else added.push(key);
    keyIncluded.set(key, true);
  }

  Object.keys(keyItem).forEach((key) => {
    if (!keyIncluded.has(key)) removed.push(key);
  });

  return {
    added,
    updated,
    removed,
  };
}
