import { Locale } from '../types/locale';

export const ZH_CN_LOCALE: Locale = {
  locale: 'zh-CN',

  // éç¨
  general: {
    increase: 'å¢å ',
    decrease: 'åå°',
    root: 'åå§',
  },

  // æç§å¾è¡¨ç»ä»¶
  /** ä¸­å¿ææ¬ */
  statistic: {
    total: 'æ»è®¡',
  },
  /** è½¬åçç»ä»¶ */
  conversionTag: {
    label: 'è½¬åç',
  },
  legend: {},
  tooltip: {},
  slider: {},
  scrollbar: {},

  // æç§å¾è¡¨ç±»å
  waterfall: {
    total: 'æ»è®¡',
  },
};
