import { isFunction, isString, isNil, get, isArray, isNumber, each, toString, isEmpty } from '@antv/util';
import { Params } from '../../core/adaptor';
import { legend, animation, theme, state, annotation } from '../../adaptor/common';
import { getMappingFunction } from '../../adaptor/geometries/base';
import { interval } from '../../adaptor/geometries';
import { pattern } from '../../adaptor/pattern';
import { getLocale } from '../../core/locale';
import { Interaction } from '../../types/interaction';
import { flow, template, transformLabel, deepAssign, renderStatistic, processIllegalData } from '../../utils';
import { Data, Datum } from '../../types';
import { DEFAULT_OPTIONS } from './contants';
import { adaptOffset, getTotalValue, isAllZero } from './utils';
import { PIE_STATISTIC } from './interactions';
import { PieOptions } from './types';

/**
 * å­æ®µ
 * @param params
 */
function geometry(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { data, angleField, colorField, color, pieStyle } = options;

  // å¤çä¸åæ³çæ°æ®
  let processData = processIllegalData(data, angleField);

  if (isAllZero(processData, angleField)) {
    // æ°æ®å¨ 0 å¤çï¼è°æ´ position æ å°
    const percentageField = '$$percentage$$';
    processData = processData.map((d) => ({ ...d, [percentageField]: 1 / processData.length }));
    chart.data(processData);

    const p = deepAssign({}, params, {
      options: {
        xField: '1',
        yField: percentageField,
        seriesField: colorField,
        isStack: true,
        interval: {
          color,
          style: pieStyle,
        },
        args: {
          zIndexReversed: true,
          sortZIndex: true,
        },
      },
    });

    interval(p);
  } else {
    chart.data(processData);

    const p = deepAssign({}, params, {
      options: {
        xField: '1',
        yField: angleField,
        seriesField: colorField,
        isStack: true,
        interval: {
          color,
          style: pieStyle,
        },
        args: {
          zIndexReversed: true,
          sortZIndex: true,
        },
      },
    });

    interval(p);
  }

  return params;
}

/**
 * meta éç½®
 * @param params
 */
function meta(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { meta, colorField } = options;

  // meta ç´æ¥æ¯ scale çä¿¡æ¯
  const scales = deepAssign({}, meta);
  chart.scale(scales, {
    [colorField]: { type: 'cat' },
  });

  return params;
}

/**
 * coord éç½®
 * @param params
 */
function coordinate(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { radius, innerRadius, startAngle, endAngle } = options;

  chart.coordinate({
    type: 'theta',
    cfg: {
      radius,
      innerRadius,
      startAngle,
      endAngle,
    },
  });

  return params;
}

/**
 * label éç½®
 * @param params
 */
function label(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { label, colorField, angleField } = options;

  const geometry = chart.geometries[0];
  // label ä¸º false, ç©º åä¸æ¾ç¤º label
  if (!label) {
    geometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    const labelCfg = transformLabel(cfg);

    // â  æä¾æ¨¡æ¿å­ç¬¦ä¸²ç label content éç½®
    if (labelCfg.content) {
      const { content } = labelCfg;
      labelCfg.content = (data: object, dataum: any, index: number) => {
        const name = data[colorField];
        const value = data[angleField];
        // dymatic get scale, scale is ready this time
        const angleScale = chart.getScaleByField(angleField);
        const percent = angleScale?.scale(value);
        return isFunction(content)
          ? // append pecent (number) to data, users can get origin data from `dataum._origin`
            content({ ...data, percent }, dataum, index)
          : isString(content)
          ? template(content as string, {
              value,
              name,
              // percentage (string), default keep 2
              percentage: isNumber(percent) && !isNil(value) ? `${(percent * 100).toFixed(2)}%` : null,
            })
          : content;
      };
    }

    const LABEL_LAYOUT_TYPE_MAP = {
      inner: '',
      outer: 'pie-outer',
      spider: 'pie-spider',
    };
    const labelLayoutType = labelCfg.type ? LABEL_LAYOUT_TYPE_MAP[labelCfg.type] : 'pie-outer';
    const labelLayoutCfg = labelCfg.layout ? (!isArray(labelCfg.layout) ? [labelCfg.layout] : labelCfg.layout) : [];
    labelCfg.layout = (labelLayoutType ? [{ type: labelLayoutType }] : []).concat(labelLayoutCfg);

    geometry.label({
      // fix: could not create scale, when field is undefinedï¼attributes ä¸­ç fields å®ä¹é½ä¼è¢«ç¨æ¥åå»º scaleï¼
      fields: colorField ? [angleField, colorField] : [angleField],
      callback,
      cfg: {
        ...labelCfg,
        offset: adaptOffset(labelCfg.type, labelCfg.offset),
        type: 'pie',
      },
    });
  }
  return params;
}

/**
 * statistic options å¤ç
 * 1. é»è®¤ç»§æ¿ default options çæ ·å¼
 * 2. é»è®¤ä½¿ç¨ meta ç formatter
 */
export function transformStatisticOptions(options: PieOptions): PieOptions {
  const { innerRadius, statistic, angleField, colorField, meta, locale } = options;

  const i18n = getLocale(locale);

  if (innerRadius && statistic) {
    let { title: titleOpt, content: contentOpt } = deepAssign({}, DEFAULT_OPTIONS.statistic, statistic);
    if (titleOpt !== false) {
      titleOpt = deepAssign(
        {},
        {
          formatter: (datum: Datum) => {
            // äº¤äºä¸­
            if (datum) {
              return datum[colorField];
            }
            return !isNil(titleOpt.content) ? titleOpt.content : i18n.get(['statistic', 'total']);
          },
        },
        titleOpt
      );
    }
    if (contentOpt !== false) {
      contentOpt = deepAssign(
        {},
        {
          formatter: (datum: Datum, data: Data) => {
            const dataValue = datum ? datum[angleField] : getTotalValue(data, angleField);
            const metaFormatter = get(meta, [angleField, 'formatter']) || ((v) => v);
            // äº¤äºä¸­
            if (datum) {
              return metaFormatter(dataValue);
            }
            return !isNil(contentOpt.content) ? contentOpt.content : metaFormatter(dataValue);
          },
        },
        contentOpt
      );
    }

    return deepAssign({}, { statistic: { title: titleOpt, content: contentOpt } }, options);
  }
  return options;
}

/**
 * statistic ä¸­å¿ææ¬éç½®
 * @param params
 */
export function pieAnnotation(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { innerRadius, statistic } = transformStatisticOptions(options);
  // åæ¸ç©ºæ æ³¨ï¼åéæ°æ¸²æ
  chart.getController('annotation').clear(true);

  // åè¿è¡å¶ä» annotationsï¼åå»æ¸²æç»è®¡ææ¬
  flow(annotation())(params);

  /** ä¸­å¿ææ¬ ææ å¡ */
  if (innerRadius && statistic) {
    renderStatistic(chart, { statistic, plotType: 'pie' });
  }

  return params;
}

/**
 * é¥¼å¾ tooltip éç½®
 * 1. å¼ºå¶ tooltip.shared ä¸º false
 * @param params
 */
function tooltip(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { tooltip, colorField, angleField, data } = options;

  if (tooltip === false) {
    chart.tooltip(tooltip);
  } else {
    chart.tooltip(deepAssign({}, tooltip, { shared: false }));

    // ä¸»è¦è§£å³ all zeroï¼ å¯¹äºé all zero ä¸åéç¨
    if (isAllZero(data, angleField)) {
      let fields = get(tooltip, 'fields');
      let formatter = get(tooltip, 'formatter');

      if (isEmpty(get(tooltip, 'fields'))) {
        fields = [colorField, angleField];
        formatter = formatter || ((datum) => ({ name: datum[colorField], value: toString(datum[angleField]) }));
      }
      chart.geometries[0].tooltip(fields.join('*'), getMappingFunction(fields, formatter));
    }
  }

  return params;
}

/**
 * Interaction éç½® (é¥¼å¾ç¹æ®ç interaction, ä¸­å¿ææ¬åæ´çæ¶åï¼éè¦å°ä¸äºéç½®åæ°ä¼ è¿å»ï¼
 * @param params
 */
export function interaction(params: Params<PieOptions>): Params<PieOptions> {
  const { chart, options } = params;
  const { interactions, statistic, annotations } = transformStatisticOptions(options);

  each(interactions, (i: Interaction) => {
    if (i.enable === false) {
      chart.removeInteraction(i.type);
    } else if (i.type === 'pie-statistic-active') {
      // åªéå¯¹ start é¶æ®µçéç½®ï¼è¿è¡æ·»å åæ°ä¿¡æ¯
      let startStages = [];
      if (!i.cfg?.start) {
        startStages = [
          {
            trigger: 'element:mouseenter',
            action: `${PIE_STATISTIC}:change`,
            arg: { statistic, annotations },
          },
        ];
      }
      each(i.cfg?.start, (stage) => {
        startStages.push({ ...stage, arg: { statistic, annotations } });
      });
      chart.interaction(i.type, deepAssign({}, i.cfg, { start: startStages }));
    } else {
      chart.interaction(i.type, i.cfg || {});
    }
  });

  return params;
}

/**
 * é¥¼å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<PieOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow<Params<PieOptions>>(
    pattern('pieStyle'),
    geometry,
    meta,
    theme,
    coordinate,
    legend,
    tooltip,
    label,
    state,
    /** ææ å¡ä¸­å¿ææ¬ æ¾å¨ä¸å± */
    pieAnnotation,
    interaction,
    animation
  )(params);
}
