import { Controller } from '../controller/base';
import View from '../view';

// å¸å±å½æ°çå®ä¹
// å¸å±å½æ°çèè´£ï¼æ ¹æ® view ä¸­ç»ä»¶ä¿¡æ¯ï¼è®¡ç®åºæç»çå¾å½¢ padding æ°å¼ï¼ä»¥åæç»åä¸ªç»ä»¶çå¸å±åä½ç½®
export type Layout = (view: View) => void;

/**
 * @ignore
 * G2 é»è®¤æä¾ç layout å½æ°
 * åç½®å¸å±å½æ°å¤ççé»è¾ï¼
 *
 * 1. å¦æ padding = 'auto'ï¼é£ä¹èªå¨æ ¹æ®ç»ä»¶ç direction æ¥è®¡ç® padding æ°ç»
 * 2. æ ¹æ® padding å direction å»åéå¯¹åºæ¹åç padding æ°å¼
 * 3. ç§»å¨ç»ä»¶ä½ç½®
 *
 * åé¢ 1ï¼2 æ­¥éª¤å¨ view ä¸­å·²ç»åæäºãå¯¹äºç»ä»¶ååºå¼å¸å±ï¼å¯ä»¥å°è¯ä½¿ç¨çº¦æå¸å±çæ¹å¼å»æ±è§£ä½ç½®ä¿¡æ¯ã
 * @param view
 */
export default function defaultLayout(view: View): void {
  const axis = view.getController('axis');
  const legend = view.getController('legend');
  const annotation = view.getController('annotation');
  const slider = view.getController('slider');
  const scrollbar = view.getController('scrollbar');

  // æ ¹æ®ææ°ç coordinate éæ°å¸å±ç»ä»¶
  [axis, slider, scrollbar, legend, annotation].forEach((controller: Controller) => {
    if (controller) {
      controller.layout();
    }
  });
}
