import { Action, Element } from '@antv/g2';
import { get } from '@antv/util';
import { Datum, Point } from '../../../../types';
import { findViewById } from '../../../../utils';
import { EDGES_VIEW_ID, NODES_VIEW_ID } from '../../constant';

export class SankeyNodeDragAction extends Action {
  /**
   * æ¯å¦å¨ææ½ä¸­çæ è®°
   */
  private isDragging = false;

  /**
   * é¼ æ ä¸ä¸æ¬¡çä½ç½®çåæ ç¹
   */
  private prevPoint: Point;
  /**
   * ä¹åçèç¹å¨ç»éç½®
   */
  private prevNodeAnimateCfg: any;
  /**
   * ä¹åçè¾¹å¨ç»éç½®
   */
  private prevEdgeAnimateCfg: any;
  /**
   * å½åææ½ç element ç´¢å¼
   */
  private currentElementIdx: number;

  /**
   * å½åæä½çæ¯å¦æ¯ element
   */
  private isNodeElement() {
    const shape = get(this.context, 'event.target');
    if (shape) {
      const element = shape.get('element');
      return element && element.getModel().data.isNode;
    }
    return false;
  }

  private getNodeView() {
    return findViewById(this.context.view, NODES_VIEW_ID);
  }

  private getEdgeView() {
    return findViewById(this.context.view, EDGES_VIEW_ID);
  }

  /**
   * è·åå½åæä½ç index
   * @param element
   */
  private getCurrentDatumIdx(element: Element) {
    return this.getNodeView().geometries[0].elements.indexOf(element);
  }

  /**
   * ç¹å»ä¸å»ï¼å¼å§
   */
  public start() {
    // è®°å½å¼å§äºçç¶æ
    if (this.isNodeElement()) {
      this.prevPoint = {
        x: get(this.context, 'event.x'),
        y: get(this.context, 'event.y'),
      };

      const element = this.context.event.target.get('element');
      const idx = this.getCurrentDatumIdx(element);

      if (idx === -1) {
        return;
      }

      this.currentElementIdx = idx;
      this.context.isDragging = true;
      this.isDragging = true;

      // å³é­å¨ç»å¹¶æå­éç½®
      this.prevNodeAnimateCfg = this.getNodeView().getOptions().animate;
      this.prevEdgeAnimateCfg = this.getEdgeView().getOptions().animate;
      this.getNodeView().animate(false);
      this.getEdgeView().animate(false);
    }
  }

  /**
   * ç§»å¨è¿ç¨ä¸­ï¼å¹³ç§»
   */
  public translate() {
    if (this.isDragging) {
      const chart = this.context.view;

      const currentPoint = {
        x: get(this.context, 'event.x'),
        y: get(this.context, 'event.y'),
      };

      const x = currentPoint.x - this.prevPoint.x;
      const y = currentPoint.y - this.prevPoint.y;

      const nodeView = this.getNodeView();
      const element = nodeView.geometries[0].elements[this.currentElementIdx];

      // ä¿®æ¹æ°æ®
      if (element && element.getModel()) {
        const prevDatum: Datum = element.getModel().data;
        const data = nodeView.getOptions().data;
        const coordinate = nodeView.getCoordinate();

        const datumGap = {
          x: x / coordinate.getWidth(),
          y: y / coordinate.getHeight(),
        };

        const nextDatum = {
          ...prevDatum,
          x: prevDatum.x.map((x: number) => (x += datumGap.x)),
          y: prevDatum.y.map((y: number) => (y += datumGap.y)),
        };
        // å¤çä¸ä¸å¨ [0, 1] èå´

        // 1. æ´æ° node æ°æ®
        const newData = [...data];
        newData[this.currentElementIdx] = nextDatum;
        nodeView.data(newData);

        // 2. æ´æ° edge æ°æ®
        const name = prevDatum.name;
        const edgeView = this.getEdgeView();
        const edgeData = edgeView.getOptions().data;

        edgeData.forEach((datum) => {
          // 2.1 ä»¥è¯¥ node ä¸º source çè¾¹ï¼ä¿®æ¹ [x0, x1, x2, x3] ä¸­ç x0, x1
          if (datum.source === name) {
            datum.x[0] += datumGap.x;
            datum.x[1] += datumGap.x;
            datum.y[0] += datumGap.y;
            datum.y[1] += datumGap.y;
          }

          // 2.2 ä»¥è¯¥ node ä¸º target çè¾¹ï¼ä¿®æ¹ [x0, x1, x2, x3] ä¸­ç x2, x3
          if (datum.target === name) {
            datum.x[2] += datumGap.x;
            datum.x[3] += datumGap.x;
            datum.y[2] += datumGap.y;
            datum.y[3] += datumGap.y;
          }
        });
        edgeView.data(edgeData);

        // 3. æ´æ°ææ°ä½ç½®
        this.prevPoint = currentPoint;

        // node edge é½æ¹åäºï¼æä»¥è¦ä»åºå± render
        chart.render(true);
      }
    }
  }

  /**
   * ç»è®ºï¼æ¸é¤ç¶æ
   */
  public end() {
    this.isDragging = false;
    this.context.isDragging = false;
    this.prevPoint = null;
    this.currentElementIdx = null;

    // è¿åå¨ç»
    this.getNodeView().animate(this.prevNodeAnimateCfg);
    this.getEdgeView().animate(this.prevEdgeAnimateCfg);
  }
}
