import { deepMix, find, get, isEqual, isFunction, mix, isString, isBoolean, flatten, isArray } from '@antv/util';
import { Crosshair, HtmlTooltip, IGroup } from '../../dependents';
import { Point, TooltipItem, TooltipOption } from '../../interface';
import { getAngleByPoint, getDistanceToCenter, isPointInCoordinate, getCoordinateClipCfg } from '../../util/coordinate';
import { polarToCartesian } from '../../util/graphics';
import { findItemsFromView } from '../../util/tooltip';
import { BBox } from '../../util/bbox';
import { Controller } from './base';
import Event from '../event';
import View from '../view';

// Filter duplicates, use `name`, `color`, `value` and `title` property values as condition
function uniq(items) {
  const uniqItems = [];
  for (let index = 0; index < items.length; index++) {
    const item = items[index];
    const result = find(uniqItems, (subItem) => {
      return (
        subItem.color === item.color &&
        subItem.name === item.name &&
        subItem.value === item.value &&
        subItem.title === item.title
      );
    });
    if (!result) {
      uniqItems.push(item);
    }
  }
  return uniqItems;
}

/** @ignore */
export default class Tooltip extends Controller<TooltipOption> {
  private tooltip;
  private tooltipMarkersGroup: IGroup;
  private tooltipCrosshairsGroup: IGroup;
  private xCrosshair;
  private yCrosshair;
  private guideGroup: IGroup;

  private isLocked: boolean = false;
  private items;
  private title: string;
  private point: Point;

  public get name(): string {
    return 'tooltip';
  }

  public init() { }

  private isVisible() {
    const option = this.view.getOptions().tooltip;
    return option !== false;
  }

  public render() { }

  /**
   * Shows tooltip
   * @param point
   */
  public showTooltip(point: Point) {
    this.point = point;
    if (!this.isVisible()) {
      // å¦æè®¾ç½® tooltip(false) åå§ç»ä¸æ¾ç¤º
      return;
    }
    const view = this.view;
    const items = this.getTooltipItems(point);
    if (!items.length) {
      // æ åå®¹åä¸å±ç¤ºï¼åæ¶ tooltip éè¦éè
      this.hideTooltip();
      return;
    }
    const title = this.getTitle(items);
    const dataPoint = {
      x: items[0].x,
      y: items[0].y,
    }; // æ°æ®ç¹ä½ç½®

    view.emit(
      'tooltip:show',
      Event.fromData(view, 'tooltip:show', {
        items,
        title,
        ...point,
      })
    );

    const cfg = this.getTooltipCfg();
    const { follow, showMarkers, showCrosshairs, showContent, marker } = cfg;
    const lastItems = this.items;
    const lastTitle = this.title;
    if (!isEqual(lastTitle, title) || !isEqual(lastItems, items)) {
      // åå®¹åçååäºæ´æ° tooltip
      view.emit(
        'tooltip:change',
        Event.fromData(view, 'tooltip:change', {
          items,
          title,
          ...point,
        })
      );

      if (isFunction(showContent) ? showContent(items) : showContent) {
        // å±ç¤º tooltip åå®¹æ¡ææ¸²æ tooltip
        if (!this.tooltip) {
          // å»¶è¿çæ
          this.renderTooltip();
        }
        this.tooltip.update(
          mix(
            {},
            cfg,
            {
              items: this.getItemsAfterProcess(items),
              title,
            },
            follow ? point : {}
          )
        );
        this.tooltip.show();
      }

      if (showMarkers) {
        // å±ç¤º tooltipMarkersï¼tooltipMarkers è·éæ°æ®
        this.renderTooltipMarkers(items, marker);
      }
    } else {
      // åå®¹æªåçååï¼åæ´æ°ä½ç½®
      if (this.tooltip && follow) {
        this.tooltip.update(point);
        this.tooltip.show(); // tooltip æå¯è½è¢«éèï¼éè¦ä¿è¯æ¾ç¤ºç¶æ
      }

      if (this.tooltipMarkersGroup) {
        this.tooltipMarkersGroup.show();
      }
    }

    this.items = items;
    this.title = title;

    if (showCrosshairs) {
      // å±ç¤º tooltip è¾å©çº¿
      const isCrosshairsFollowCursor = get(cfg, ['crosshairs', 'follow'], false); // è¾å©çº¿æ¯å¦è¦è·éé¼ æ 
      this.renderCrosshairs(isCrosshairsFollowCursor ? point : dataPoint, cfg);
    }
  }

  public hideTooltip() {
    const { follow } = this.getTooltipCfg();
    if (!follow) {
      this.point = null;
      return;
    }
    // hide the tooltipMarkers
    const tooltipMarkersGroup = this.tooltipMarkersGroup;
    if (tooltipMarkersGroup) {
      tooltipMarkersGroup.hide();
    }

    // hide crosshairs
    const xCrosshair = this.xCrosshair;
    const yCrosshair = this.yCrosshair;
    if (xCrosshair) {
      xCrosshair.hide();
    }
    if (yCrosshair) {
      yCrosshair.hide();
    }

    const tooltip = this.tooltip;
    if (tooltip) {
      tooltip.hide();
    }

    this.view.emit('tooltip:hide', Event.fromData(this.view, 'tooltip:hide', {}));

    this.point = null;
  }

  /**
   * lockTooltip
   */
  public lockTooltip() {
    this.isLocked = true;
    if (this.tooltip) {
      // tooltip contianer å¯æè·äºä»¶
      this.tooltip.setCapture(true);
    }
  }

  /**
   * unlockTooltip
   */
  public unlockTooltip() {
    this.isLocked = false;
    const cfg = this.getTooltipCfg();
    if (this.tooltip) {
      // éç½® capture å±æ§
      this.tooltip.setCapture(cfg.capture);
    }
  }

  /**
   * isTooltipLocked
   */
  public isTooltipLocked() {
    return this.isLocked;
  }

  public clear() {
    const { tooltip, xCrosshair, yCrosshair, tooltipMarkersGroup } = this;
    if (tooltip) {
      tooltip.hide();
      tooltip.clear();
    }

    if (xCrosshair) {
      xCrosshair.clear();
    }

    if (yCrosshair) {
      yCrosshair.clear();
    }

    if (tooltipMarkersGroup) {
      tooltipMarkersGroup.clear();
    }

    // å¦æ customContent ä¸ä¸ºç©ºï¼å°±éæ°çæ tooltip
    if (tooltip?.get('customContent')) {
      this.tooltip.destroy();
      this.tooltip = null;
    }

    // title å items éè¦æ¸ç©º, å¦å tooltip åå®¹ä¼åºç°ç½®ç©ºçæåµ
    // å³ï¼éè¦èµ°è¿ !isEqual(lastTitle, title) || !isEqual(lastItems, items) çé»è¾ï¼æ´æ° tooltip çåå®¹
    this.title = null;
    this.items = null;
  }

  public destroy() {
    if (this.tooltip) {
      this.tooltip.destroy();
    }
    if (this.xCrosshair) {
      this.xCrosshair.destroy();
    }
    if (this.yCrosshair) {
      this.yCrosshair.destroy();
    }

    if (this.guideGroup) {
      this.guideGroup.remove(true);
    }

    this.reset();
  }

  public reset() {
    this.items = null;
    this.title = null;
    this.tooltipMarkersGroup = null;
    this.tooltipCrosshairsGroup = null;
    this.xCrosshair = null;
    this.yCrosshair = null;
    this.tooltip = null;
    this.guideGroup = null;
    this.isLocked = false;
    this.point = null;
  }

  public changeVisible(visible: boolean) {
    if (this.visible === visible) {
      return;
    }
    const { tooltip, tooltipMarkersGroup, xCrosshair, yCrosshair } = this;
    if (visible) {
      if (tooltip) {
        tooltip.show();
      }
      if (tooltipMarkersGroup) {
        tooltipMarkersGroup.show();
      }
      if (xCrosshair) {
        xCrosshair.show();
      }
      if (yCrosshair) {
        yCrosshair.show();
      }
    } else {
      if (tooltip) {
        tooltip.hide();
      }
      if (tooltipMarkersGroup) {
        tooltipMarkersGroup.hide();
      }
      if (xCrosshair) {
        xCrosshair.hide();
      }
      if (yCrosshair) {
        yCrosshair.hide();
      }
    }
    this.visible = visible;
  }

  public getTooltipItems(point: Point) {
    let items = this.findItemsFromView(this.view, point);
    if (items.length) {
      // ä¸å±
      items = flatten(items);
      for (const itemArr of items) {
        for (const item of itemArr) {
          const { x, y } = item.mappingData;
          item.x = isArray(x) ? x[x.length - 1] : x;
          item.y = isArray(y) ? y[y.length - 1] : y;
        }
      }

      const { shared } = this.getTooltipCfg();
      // shared: false ä»£è¡¨åªæ¾ç¤ºå½åæ¾åå°ç shape çæ°æ®ï¼ä½æ¯ä¸ä¸ª view ä¼æå¤ä¸ª Geometryï¼æä»¥æå¯è½ä¼æ¾åå°å¤ä¸ª shape
      if (shared === false && items.length > 1) {
        let snapItem = items[0];
        let min = Math.abs(point.y - snapItem[0].y);
        for (const aItem of items) {
          const yDistance = Math.abs(point.y - aItem[0].y);
          if (yDistance <= min) {
            snapItem = aItem;
            min = yDistance;
          }
        }
        items = [snapItem];
      }

      return uniq(flatten(items));
    }

    return [];
  }

  public layout() { }

  public update() {
    if (this.point) {
      this.showTooltip(this.point);
    }

    if (this.tooltip) {
      // #2279 ä¿®å¤resizeä¹åtooltipè¶ççé®é¢
      // ç¡®ä¿tooltipå·²ç»åå»ºçæåµä¸
      const canvas = this.view.getCanvas();
      // TODO éä¸º tooltip çåºåä¸åºè¯¥æ¯ canvasï¼èåºè¯¥æ¯æ´ä¸ª ç¹å«æ¯å¨å¾æ¯è¾å°çæ¶å
      // æ´æ° region
      this.tooltip.set('region', {
        start: { x: 0, y: 0 },
        end: { x: canvas.get('width'), y: canvas.get('height') },
      });
    }
  }

  /**
   * å½åé¼ æ ç¹æ¯å¨ enter tooltip ä¸­
   * @param point
   */
  public isCursorEntered(point: Point) {
    // æ¯å¯æè·çï¼å¹¶ä¸ç¹å¨ tooltip dom ä¸
    if (this.tooltip) {
      const el: HTMLElement = this.tooltip.getContainer();
      const capture = this.tooltip.get('capture');

      if (el && capture) {
        const { x, y, width, height } = el.getBoundingClientRect();
        return new BBox(x, y, width, height).isPointIn(point);
      }
    }

    return false;
  }

  // è·å tooltip éç½®ï¼å ä¸ºç¨æ·å¯è½ä¼éè¿ view.tooltip() éæ°éç½® tooltipï¼æä»¥å°±ä¸åç¼å­ï¼æ¯æ¬¡ç´æ¥è¯»å
  public getTooltipCfg() {
    const view = this.view;
    const option = view.getOptions().tooltip;
    const processOption = this.processCustomContent(option);
    const theme = view.getTheme();
    const defaultCfg = get(theme, ['components', 'tooltip'], {});
    const enterable = get(processOption, 'enterable', defaultCfg.enterable);
    return deepMix({}, defaultCfg, processOption, {
      capture: enterable || this.isLocked ? true : false,
    });
  }

  // process customContent
  protected processCustomContent(option: TooltipOption) {
    if (isBoolean(option) || !get(option, 'customContent')) {
      return option;
    }
    const currentCustomContent = option.customContent;
    const customContent = (title: string, items: any[]) => {
      const content = currentCustomContent(title, items) || '';
      return isString(content) ? '<div class="g2-tooltip">' + content + '</div>' : content;
    };
    return {
      ...option,
      customContent,
    };
  }

  private getTitle(items) {
    const title = items[0].title || items[0].name;
    this.title = title;

    return title;
  }

  private renderTooltip() {
    const canvas = this.view.getCanvas();
    const region = {
      start: { x: 0, y: 0 },
      end: { x: canvas.get('width'), y: canvas.get('height') },
    };

    const cfg = this.getTooltipCfg();
    const tooltip = new HtmlTooltip({
      parent: canvas.get('el').parentNode,
      region,
      ...cfg,
      visible: false,
      crosshairs: null,
    });

    tooltip.init();
    this.tooltip = tooltip;
  }

  private renderTooltipMarkers(items, marker) {
    const tooltipMarkersGroup = this.getTooltipMarkersGroup();
    const rootView = this.view.getRootView();
    const { limitInPlot } = rootView;
    for (const item of items) {
      const { x, y } = item;

      // æè£åªå°±åªå
      if (limitInPlot || tooltipMarkersGroup?.getClip()) {
        const { type, attrs } = getCoordinateClipCfg(rootView.getCoordinate());
        tooltipMarkersGroup?.setClip({
          type,
          attrs,
        });
      } else {
        // æ¸é¤å·²æç clip
        tooltipMarkersGroup?.setClip(undefined);
      }

      const attrs = {
        fill: item.color,
        symbol: 'circle',
        shadowColor: item.color,
        ...marker,
        x,
        y,
      };

      tooltipMarkersGroup.addShape('marker', {
        attrs,
      });
    }
  }

  private renderCrosshairs(point: Point, cfg) {
    const crosshairsType = get(cfg, ['crosshairs', 'type'], 'x'); // é»è®¤å±ç¤º x è½´ä¸çè¾å©çº¿
    if (crosshairsType === 'x') {
      if (this.yCrosshair) {
        this.yCrosshair.hide();
      }
      this.renderXCrosshairs(point, cfg);
    } else if (crosshairsType === 'y') {
      if (this.xCrosshair) {
        this.xCrosshair.hide();
      }
      this.renderYCrosshairs(point, cfg);
    } else if (crosshairsType === 'xy') {
      this.renderXCrosshairs(point, cfg);
      this.renderYCrosshairs(point, cfg);
    }
  }

  // æ¸²æ x è½´ä¸ç tooltip è¾å©çº¿
  private renderXCrosshairs(point: Point, tooltipCfg) {
    const coordinate = this.getViewWithGeometry(this.view).getCoordinate();
    if (!isPointInCoordinate(coordinate, point)) {
      return;
    }
    let start;
    let end;
    if (coordinate.isRect) {
      if (coordinate.isTransposed) {
        start = {
          x: coordinate.start.x,
          y: point.y,
        };
        end = {
          x: coordinate.end.x,
          y: point.y,
        };
      } else {
        start = {
          x: point.x,
          y: coordinate.end.y,
        };
        end = {
          x: point.x,
          y: coordinate.start.y,
        };
      }
    } else {
      // æåæ ä¸ x è½´ä¸ç crosshairs è¡¨ç°ä¸ºåå¾
      const angle = getAngleByPoint(coordinate, point);
      const center = coordinate.getCenter();
      const radius = coordinate.getRadius();
      end = polarToCartesian(center.x, center.y, radius, angle);
      start = center;
    }

    const cfg = deepMix(
      {
        start,
        end,
        container: this.getTooltipCrosshairsGroup(),
      },
      get(tooltipCfg, 'crosshairs', {}),
      this.getCrosshairsText('x', point, tooltipCfg)
    );
    delete cfg.type; // ä¸ Crosshairs ç»ä»¶ç type å²çªæå é¤

    let xCrosshair = this.xCrosshair;
    if (xCrosshair) {
      xCrosshair.update(cfg);
    } else {
      xCrosshair = new Crosshair.Line(cfg);
      xCrosshair.init();
    }
    xCrosshair.render();
    xCrosshair.show();
    this.xCrosshair = xCrosshair;
  }

  // æ¸²æ y è½´ä¸çè¾å©çº¿
  private renderYCrosshairs(point: Point, tooltipCfg) {
    const coordinate = this.getViewWithGeometry(this.view).getCoordinate();
    if (!isPointInCoordinate(coordinate, point)) {
      return;
    }
    let cfg;
    let type;
    if (coordinate.isRect) {
      let start;
      let end;
      if (coordinate.isTransposed) {
        start = {
          x: point.x,
          y: coordinate.end.y,
        };
        end = {
          x: point.x,
          y: coordinate.start.y,
        };
      } else {
        start = {
          x: coordinate.start.x,
          y: point.y,
        };
        end = {
          x: coordinate.end.x,
          y: point.y,
        };
      }
      cfg = {
        start,
        end,
      };
      type = 'Line';
    } else {
      // æåæ ä¸ y è½´ä¸ç crosshairs è¡¨ç°ä¸ºåå¼§
      cfg = {
        center: coordinate.getCenter(),
        // @ts-ignore
        radius: getDistanceToCenter(coordinate, point),
        startAngle: coordinate.startAngle,
        endAngle: coordinate.endAngle,
      };
      type = 'Circle';
    }

    cfg = deepMix(
      {
        container: this.getTooltipCrosshairsGroup(),
      },
      cfg,
      get(tooltipCfg, 'crosshairs', {}),
      this.getCrosshairsText('y', point, tooltipCfg)
    );
    delete cfg.type; // ä¸ Crosshairs ç»ä»¶ç type å²çªæå é¤

    let yCrosshair = this.yCrosshair;
    if (yCrosshair) {
      // å¦æåæ ç³»åçç´è§åæ ç³»ä¸æåæ çåæ¢æä½
      if (
        (coordinate.isRect && yCrosshair.get('type') === 'circle') ||
        (!coordinate.isRect && yCrosshair.get('type') === 'line')
      ) {
        yCrosshair = new Crosshair[type](cfg);
        yCrosshair.init();
      } else {
        yCrosshair.update(cfg);
      }
    } else {
      yCrosshair = new Crosshair[type](cfg);
      yCrosshair.init();
    }
    yCrosshair.render();
    yCrosshair.show();
    this.yCrosshair = yCrosshair;
  }

  private getCrosshairsText(type, point: Point, tooltipCfg) {
    let textCfg = get(tooltipCfg, ['crosshairs', 'text']);
    const follow = get(tooltipCfg, ['crosshairs', 'follow']);
    const items = this.items;

    if (textCfg) {
      const view = this.getViewWithGeometry(this.view);
      // éè¦å±ç¤ºææ¬
      const firstItem = items[0];
      const xScale = view.getXScale();
      const yScale = view.getYScales()[0];
      let xValue;
      let yValue;
      if (follow) {
        // å¦æéè¦è·éé¼ æ ç§»å¨ï¼å°±éè¦å°å½åé¼ æ åæ ç¹è½¬æ¢ä¸ºå¯¹åºçæ°å¼
        const invertPoint = this.view.getCoordinate().invert(point);
        xValue = xScale.invert(invertPoint.x); // è½¬æ¢ä¸ºåå§å¼
        yValue = yScale.invert(invertPoint.y); // è½¬æ¢ä¸ºåå§å¼
      } else {
        xValue = firstItem.data[xScale.field];
        yValue = firstItem.data[yScale.field];
      }

      const content = type === 'x' ? xValue : yValue;
      if (isFunction(textCfg)) {
        textCfg = textCfg(type, content, items, point);
      } else {
        textCfg.content = content;
      }

      return {
        text: textCfg,
      };
    }
  }

  // è·åå­å¨ tooltipMarkers å crosshairs çå®¹å¨
  private getGuideGroup() {
    if (!this.guideGroup) {
      const foregroundGroup = this.view.foregroundGroup;
      this.guideGroup = foregroundGroup.addGroup({
        name: 'tooltipGuide',
        capture: false,
      });
    }

    return this.guideGroup;
  }

  // è·å tooltipMarkers å­å¨çå®¹å¨
  private getTooltipMarkersGroup() {
    let tooltipMarkersGroup = this.tooltipMarkersGroup;
    if (tooltipMarkersGroup && !tooltipMarkersGroup.destroyed) {
      tooltipMarkersGroup.clear();
      tooltipMarkersGroup.show();
    } else {
      tooltipMarkersGroup = this.getGuideGroup().addGroup({
        name: 'tooltipMarkersGroup',
      });
      tooltipMarkersGroup.toFront();
      this.tooltipMarkersGroup = tooltipMarkersGroup;
    }
    return tooltipMarkersGroup;
  }

  // è·å tooltip crosshairs å­å¨çå®¹å¨
  private getTooltipCrosshairsGroup() {
    let tooltipCrosshairsGroup = this.tooltipCrosshairsGroup;
    if (!tooltipCrosshairsGroup) {
      tooltipCrosshairsGroup = this.getGuideGroup().addGroup({
        name: 'tooltipCrosshairsGroup',
        capture: false,
      });
      tooltipCrosshairsGroup.toBack();
      this.tooltipCrosshairsGroup = tooltipCrosshairsGroup;
    }
    return tooltipCrosshairsGroup;
  }

  private findItemsFromView(view: View, point: Point) {
    if (view.getOptions().tooltip === false) {
      // å¦æ view å³é­äº tooltip
      return [];
    }

    const tooltipCfg = this.getTooltipCfg();
    let result = findItemsFromView(view, point, tooltipCfg);
    // éå½æ¥æ¾ï¼å¹¶åå¹¶ç»æ
    for (const childView of view.views) {
      result = result.concat(this.findItemsFromView(childView, point));
    }

    return result;
  }

  // FIXME: hack æ¹æ³
  // å ä¸º tooltip çäº¤äºæ¯æè½½å¨ Chart ä¸ï¼æä»¥å½chart ä¸æ²¡æç»å¶ Geometry çæ¶åï¼å°±æ¥æ¾ä¸å°æ°æ®ï¼å¹¶ä¸ç»å¾åºååå­ View çåºåä¸å
  private getViewWithGeometry(view) {
    if (view.geometries.length) {
      return view;
    }

    return find(view.views, (childView) => this.getViewWithGeometry(childView));
  }

  /**
   * æ ¹æ®ç¨æ·éç½®ç items éç½®ï¼æ¥è¿è¡ç¨æ·èªå®ä¹çå¤çï¼å¹¶è¿åæç»ç items
   * é»è®¤ä¸åä»»ä½å¤ç
   */
  private getItemsAfterProcess(originalItems: TooltipItem[]): TooltipItem[] {
    const { customItems } = this.getTooltipCfg();
    const fn = customItems ? customItems : (v) => v;

    return fn(originalItems);
  }
}
