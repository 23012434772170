import { Point, Scale } from '../../../dependents';
import { FilterCondition, EventPayload } from '../../../interface';
import { View, Event } from '../../../chart';
import Action from '../base';
import { isMask } from '../util';

// è·åå¯¹åºç scale
function getFilter(scale: Scale, dim: string, point1: Point, point2: Point): FilterCondition {
  let min = Math.min(point1[dim], point2[dim]);
  let max = Math.max(point1[dim], point2[dim]);
  const [rangeMin, rangeMax] = scale.range;
  // çº¦æå¼å¨ scale ç range ä¹é´
  if (min < rangeMin) {
    min = rangeMin;
  }
  if (max > rangeMax) {
    max = rangeMax;
  }
  // èå´å¤§äºæ´ä¸ª view çèå´ï¼åè¿å null
  if (min === rangeMax && max === rangeMax) {
    return null;
  }
  const minValue = scale.invert(min);
  const maxValue = scale.invert(max);
  if (scale.isCategory) {
    const minIndex = scale.values.indexOf(minValue);
    const maxIndex = scale.values.indexOf(maxValue);
    const arr = scale.values.slice(minIndex, maxIndex + 1);
    return (value) => {
      return arr.includes(value);
    };
  } else {
    return (value) => {
      return value >= minValue && value <= maxValue;
    };
  }
}

/** range-filter åªç¨äºï¼brush-filter, brush-x-filter, brush-y-filter */
enum EVENTS {
  FILTER = 'brush-filter-processing',
  RESET = 'brush-filter-reset',
  BEFORE_FILTER = 'brush-filter:beforefilter',
  AFTER_FILTER = 'brush-filter:afterfilter',
  BEFORE_RESET = 'brush-filter:beforereset',
  AFTER_RESET = 'brush-filter:afterreset',
}

export { EVENTS as BRUSH_FILTER_EVENTS };

/**
 * èå´è¿æ»¤ç Action
 * @ignore
 */
class RangeFilter extends Action {
  /** åè®¸å¤é¨ä¼ å¥ dims */
  protected cfgFields: ['dims'];
  /**
   * èå´è¿æ»¤çæçå­æ®µ/ç»´åº¦ï¼å¯ä»¥æ¯ x, y
   */
  protected dims: string[] = ['x', 'y'];
  /** èµ·å§ç¹ */
  protected startPoint: Point = null;

  private isStarted: boolean = false;

  // x,y æ¯å¦çæ
  private hasDim(dim: string) {
    return this.dims.includes(dim);
  }

  /**
   * å¼å§èå´è¿æ»¤ï¼è®°å½èå´è¿æ»¤çèµ·ç¹
   */
  public start() {
    const context = this.context;
    this.isStarted = true;
    this.startPoint = context.getCurrentPoint();
  }

  /**
   * è¿æ»¤ï¼ä»¥å¼å§çç¹åå½åç¹å¯¹æ°æ®è¿è¡è¿æ»¤
   */
  public filter() {
    let startPoint;
    let currentPoint;
    if (isMask(this.context)) {
      const maskShape = this.context.event.target;
      const bbox = maskShape.getCanvasBBox();
      startPoint = { x: bbox.x, y: bbox.y };
      currentPoint = { x: bbox.maxX, y: bbox.maxY };
    } else {
      if (!this.isStarted) {
        // å¦ææ²¡æå¼å§ï¼åä¸æ§è¡è¿æ»¤
        return;
      }
      startPoint = this.startPoint;
      currentPoint = this.context.getCurrentPoint();
    }
    if (Math.abs(startPoint.x - currentPoint.x) < 5 || Math.abs(startPoint.x - currentPoint.y) < 5) {
      // è·ç¦»è¿å°ä¹ä¸çæ
      return;
    }
    const { view, event } = this.context;
    const payload = { view, event, dims: this.dims };
    view.emit(EVENTS.BEFORE_FILTER, Event.fromData(view, EVENTS.BEFORE_FILTER, payload));

    const coord = view.getCoordinate();
    const normalCurrent = coord.invert(currentPoint);
    const normalStart = coord.invert(startPoint);
    // è®¾ç½® x æ¹åç filter
    if (this.hasDim('x')) {
      const xScale = view.getXScale();
      const filter = getFilter(xScale, 'x', normalCurrent, normalStart);
      this.filterView(view, xScale.field, filter);
    }
    // è®¾ç½® y æ¹åç filter
    if (this.hasDim('y')) {
      const yScale = view.getYScales()[0];
      const filter = getFilter(yScale, 'y', normalCurrent, normalStart);
      this.filterView(view, yScale.field, filter);
    }
    this.reRender(view, { source: EVENTS.FILTER });

    view.emit(EVENTS.AFTER_FILTER, Event.fromData(view, EVENTS.AFTER_FILTER, payload));
  }

  /**
   * ç»æ
   */
  public end() {
    this.isStarted = false;
  }

  /**
   * åæ¶åå½å Action ç¸å³çè¿æ»¤ï¼æå®ç x,y
   */
  public reset() {
    const view = this.context.view;
    view.emit(EVENTS.BEFORE_RESET, Event.fromData(view, EVENTS.BEFORE_RESET, {}));

    this.isStarted = false;
    if (this.hasDim('x')) {
      const xScale = view.getXScale();
      this.filterView(view, xScale.field, null); // åæ¶è¿æ»¤
    }
    if (this.hasDim('y')) {
      // y è½´è¿æ»¤ä»åç¬¬ä¸ä¸ª yScale
      const yScale = view.getYScales()[0];
      this.filterView(view, yScale.field, null); // åæ¶è¿æ»¤
    }
    this.reRender(view, { source: EVENTS.RESET });

    view.emit(EVENTS.AFTER_RESET, Event.fromData(view, EVENTS.AFTER_RESET, {}));
  }

  /**
   * å¯¹ view è¿è¡è¿æ»¤
   */
  protected filterView(view: View, field: string, filter: FilterCondition) {
    view.filter(field, filter);
  }

  /**
   * éæ°æ¸²æ
   * @param view
   */
  protected reRender(view: View, payload?: EventPayload) {
    view.render(true, payload);
  }
}

export default RangeFilter;
