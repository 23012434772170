import {
  contains,
  filter,
  find,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  isNumberEqual,
  isObject,
  memoize,
  get,
  values,
} from '@antv/util';
import { View } from '../chart';
import { FIELD_ORIGIN, GROUP_ATTRS } from '../constant';
import { Attribute, Scale } from '../dependents';
import Geometry from '../geometry/base';
import { Data, Datum, MappingDatum, Point, TooltipCfg, TooltipTitle } from '../interface';
import { getName, inferScaleType } from './scale';

function snapEqual(v1: any, v2: any, scale: Scale) {
  const value1 = scale.translate(v1);
  const value2 = scale.translate(v2);

  return isNumberEqual(value1, value2);
}

function getXValueByPoint(point: Point, geometry: Geometry): number {
  const coordinate = geometry.coordinate;
  const xScale = geometry.getXScale();
  const range = xScale.range;
  const rangeMax = range[range.length - 1];
  const rangeMin = range[0];

  const invertPoint = coordinate.invert(point);

  let xValue = invertPoint.x;
  if (coordinate.isPolar && xValue > (1 + rangeMax) / 2) {
    xValue = rangeMin; // æåæ ä¸ï¼scale ç range è¢«åè¿ç¹æ®å¤ç
  }
  return xScale.translate(xScale.invert(xValue));
}

function filterYValue(data: Data, point: Point, geometry: Geometry) {
  const coordinate = geometry.coordinate;
  const yScale = geometry.getYScale();
  const yField = yScale.field;
  const invertPoint = coordinate.invert(point);
  const yValue = yScale.invert(invertPoint.y);

  const result = find(data, (obj: Datum) => {
    const originData = obj[FIELD_ORIGIN];
    return originData[yField][0] <= yValue && originData[yField][1] >= yValue;
  });
  return result || data[data.length - 1];
}

const getXDistance = memoize((scale: Scale) => {
  if (scale.isCategory) {
    return 1;
  }
  const scaleValues = scale.values; // values æ¯æ åºç
  const length = scaleValues.length;
  let min = scale.translate(scaleValues[0]);
  let max = min;

  for (let index = 0; index < length; index++) {
    const value = scaleValues[index];
    // æ¶é´ç±»åéè¦ translate
    const numericValue = scale.translate(value);
    if (numericValue < min) {
      min = numericValue;
    }
    if (numericValue > max) {
      max = numericValue;
    }
  }
  return (max - min) / (length - 1);
});

/**
 * è·å¾ tooltip ç title
 * @param originData
 * @param geometry
 * @param title
 */
function getTooltipTitle(originData: Datum, geometry: Geometry, title: TooltipTitle): string {
  const positionAttr = geometry.getAttribute('position');
  const fields = positionAttr.getFields();
  const scales = geometry.scales;

  const titleField = isFunction(title) || !title ? fields[0] : title;
  const titleScale = scales[titleField];

  // å¦æåå»ºäºè¯¥å­æ®µå¯¹åºç scaleï¼åéè¿ scale.getText() æ¹å¼åå¼ï¼å ä¸ºç¨æ·å¯è½å¯¹æ°æ®è¿è¡äºæ ¼å¼å
  // å¦ææ²¡æå¯¹åºç scaleï¼åä»åå§æ°æ®ä¸­åå¼ï¼å¦æåå§æ°æ®ä¸­ä»ä¸å­å¨ï¼åç´æ¥æ¾å title å¼
  const tooltipTitle = titleScale ? titleScale.getText(originData[titleField]) : originData[titleField] || titleField;

  return isFunction(title) ? title(tooltipTitle, originData) : tooltipTitle;
}

function getAttributesForLegend(geometry: Geometry) {
  const attributes = values(geometry.attributes);
  return filter(attributes, (attribute: Attribute) => contains(GROUP_ATTRS, attribute.type));
}

function getTooltipValueScale(geometry: Geometry) {
  const attributes = getAttributesForLegend(geometry);
  let scale;
  for (const attribute of attributes) {
    const tmpScale = attribute.getScale(attribute.type);
    if (tmpScale && tmpScale.isLinear) {
      const tmpScaleDef = get(geometry.scaleDefs, tmpScale.field);
      const inferedScaleType = inferScaleType(tmpScale, tmpScaleDef, attribute.type, geometry.type);
      if (inferedScaleType !== 'cat') {
        // å¦ææå®å­æ®µæ¯é position çï¼åæ¶æ¯è¿ç»­ç
        scale = tmpScale;
        break;
      }
    }
  }

  const xScale = geometry.getXScale();
  const yScale = geometry.getYScale();

  return scale || yScale || xScale;
}

function getTooltipValue(originData: Datum, valueScale: Scale) {
  const field = valueScale.field;
  const value = originData[field];

  if (isArray(value)) {
    const texts = value.map((eachValue) => {
      return valueScale.getText(eachValue);
    });
    return texts.join('-');
  }
  return valueScale.getText(value);
}

// æ ¹æ®åå§æ°æ®è·å tooltip item ä¸­ name å¼
function getTooltipName(originData: Datum, geometry: Geometry) {
  let nameScale: Scale;
  const groupScales = geometry.getGroupScales();
  if (groupScales.length) {
    // å¦æå­å¨åç»ç±»åï¼åç¬¬ä¸ä¸ªåç»ç±»å
    nameScale = groupScales[0];
  }
  if (nameScale) {
    const field = nameScale.field;
    return nameScale.getText(originData[field]);
  }

  const valueScale = getTooltipValueScale(geometry);
  return getName(valueScale);
}

/**
 * @ignore
 * Finds data from geometry by point
 * @param point canvas point
 * @param data an item of geometry.dataArray
 * @param geometry
 * @returns
 */
export function findDataByPoint(point: Point, data: MappingDatum[], geometry: Geometry) {
  if (data.length === 0) {
    return null;
  }

  const geometryType = geometry.type;
  const xScale = geometry.getXScale();
  const yScale = geometry.getYScale();

  const xField = xScale.field;
  const yField = yScale.field;

  let rst = null;

  // ç­åå¾éç¨æå°é¼è¿ç­ç¥æ¥æ¾ point å»ä¸­çæ°æ®
  if (geometryType === 'heatmap' || geometryType === 'point') {
    // å° point ç»å¸åæ è½¬æ¢ä¸ºåå§æ°æ®å¼
    const coordinate = geometry.coordinate;
    const invertPoint = coordinate.invert(point); // è½¬æ¢æå½ä¸åçæ°æ®
    const x = xScale.invert(invertPoint.x); // è½¬æ¢ä¸ºåå§å¼
    const y = yScale.invert(invertPoint.y); // è½¬æ¢ä¸ºåå§å¼

    let min = Infinity;
    for (let index = 0; index < data.length; index++) {
      const obj = data[index];
      const originData = obj[FIELD_ORIGIN];
      const range = (originData[xField] - x) ** 2 + (originData[yField] - y) ** 2;
      if (range < min) {
        min = range;
        rst = obj;
      }
    }

    return rst;
  }

  // å¶ä» Geometry ç±»åæç§ x å­æ®µæ°æ®è¿è¡æ¥æ¾
  const first = data[0];
  let last = data[data.length - 1];
  const xValue = getXValueByPoint(point, geometry);
  const firstXValue = first[FIELD_ORIGIN][xField];
  const firstYValue = first[FIELD_ORIGIN][yField];
  const lastXValue = last[FIELD_ORIGIN][xField];
  const isYArray = yScale.isLinear && isArray(firstYValue); // èè x ç»´åº¦ç¸åï¼y æ¯æ°ç»åºé´çæåµ

  // å¦æ x çå¼æ¯æ°ç»
  if (isArray(firstXValue)) {
    for (let index = 0; index < data.length; index++) {
      const record = data[index];
      const originData = record[FIELD_ORIGIN];
      // xValue å¨ originData[xField] çæ°å¼åºé´å
      if (xScale.translate(originData[xField][0]) <= xValue && xScale.translate(originData[xField][1]) >= xValue) {
        if (isYArray) {
          // å±å ç´æ¹å¾åºæ¯ï¼x å y é½æ¯æ°ç»åºé´
          if (!isArray(rst)) {
            rst = [];
          }
          rst.push(record);
        } else {
          rst = record;
          break;
        }
      }
    }
    if (isArray(rst)) {
      rst = filterYValue(rst, point, geometry);
    }
  } else {
    let next;
    if (!xScale.isLinear && xScale.type !== 'timeCat') {
      // x è½´å¯¹åºçæ°æ®ä¸ºéçº¿æ§ä»¥åéæ¶é´ç±»åçæ°æ®éç¨éåæ¥æ¾
      for (let index = 0; index < data.length; index++) {
        const record = data[index];
        const originData = record[FIELD_ORIGIN];
        if (snapEqual(originData[xField], xValue, xScale)) {
          if (isYArray) {
            if (!isArray(rst)) {
              rst = [];
            }
            rst.push(record);
          } else {
            rst = record;
            break;
          }
        } else if (xScale.translate(originData[xField]) <= xValue) {
          last = record;
          next = data[index + 1];
        }
      }

      if (isArray(rst)) {
        rst = filterYValue(rst, point, geometry);
      }
    } else {
      // x è½´å¯¹åºçæ°æ®ä¸ºçº¿æ§ä»¥åæ¶é´ç±»åï¼è¿è¡äºåæ¥æ¾ï¼æ§è½æ´å¥½
      if (
        (xValue > xScale.translate(lastXValue) || xValue < xScale.translate(firstXValue)) &&
        (xValue > xScale.max || xValue < xScale.min)
      ) {
        // ä¸å¨æ°æ®èå´å
        return null;
      }

      let firstIdx = 0;
      let lastIdx = data.length - 1;
      let middleIdx;
      while (firstIdx <= lastIdx) {
        middleIdx = Math.floor((firstIdx + lastIdx) / 2);
        const item = data[middleIdx][FIELD_ORIGIN][xField];
        if (snapEqual(item, xValue, xScale)) {
          return data[middleIdx];
        }

        if (xScale.translate(item) <= xScale.translate(xValue)) {
          firstIdx = middleIdx + 1;
          last = data[middleIdx];
          next = data[middleIdx + 1];
        } else {
          if (lastIdx === 0) {
            last = data[0];
          }
          lastIdx = middleIdx - 1;
        }
      }
    }

    if (last && next) {
      // è®¡ç®æé¼è¿ç
      if (
        Math.abs(xScale.translate(last[FIELD_ORIGIN][xField]) - xValue) >
        Math.abs(xScale.translate(next[FIELD_ORIGIN][xField]) - xValue)
      ) {
        last = next;
      }
    }
  }

  const distance = getXDistance(geometry.getXScale()); // æ¯ä¸ªåç±»é´çå¹³åé´è·
  if (!rst && Math.abs(xScale.translate(last[FIELD_ORIGIN][xField]) - xValue) <= distance / 2) {
    rst = last;
  }

  return rst;
}

/**
 * @ignore
 * Gets tooltip items
 * @param data
 * @param geometry
 * @param [title]
 * @returns
 */
export function getTooltipItems(
  data: MappingDatum,
  geometry: Geometry,
  title: TooltipTitle = '',
  showNil: boolean = false
) {
  const originData = data[FIELD_ORIGIN];
  const tooltipTitle = getTooltipTitle(originData, geometry, title);
  const tooltipOption = geometry.tooltipOption;
  const { defaultColor } = geometry.theme;
  const items = [];
  let name;
  let value;

  function addItem(itemName, itemValue) {
    if (showNil || (!isNil(itemValue) && itemValue !== '')) {
      // å¼ä¸º nullçæ¶åï¼å¿½è§
      const item = {
        title: tooltipTitle,
        data: originData, // åå§æ°æ®
        mappingData: data, // æ å°åçæ°æ®
        name: itemName,
        value: itemValue,
        color: data.color || defaultColor,
        marker: true,
      };

      items.push(item);
    }
  }

  if (isObject(tooltipOption)) {
    const { fields, callback } = tooltipOption;
    if (callback) {
      // ç¨æ·å®ä¹äºåè°å½æ°
      const callbackParams = fields.map((field: string) => {
        return data[FIELD_ORIGIN][field];
      });
      const cfg = callback(...callbackParams);
      const itemCfg = {
        data: data[FIELD_ORIGIN], // åå§æ°æ®
        mappingData: data, // æ å°åçæ°æ®
        title: tooltipTitle,
        color: data.color || defaultColor,
        marker: true, // é»è®¤å±ç¤º marker
        ...cfg,
      };

      items.push(itemCfg);
    } else {
      const scales = geometry.scales;
      for (const field of fields) {
        if (!isNil(originData[field])) {
          // å­æ®µæ°æ®ä¸ºnull, undefined æ¶ä¸æ¾ç¤º
          const scale = scales[field];
          name = getName(scale);
          value = scale.getText(originData[field]);
          addItem(name, value);
        }
      }
    }
  } else {
    const valueScale = getTooltipValueScale(geometry);
    // å­æ®µæ°æ®ä¸ºnull ,undefinedæ¶ä¸æ¾ç¤º
    value = getTooltipValue(originData, valueScale);
    name = getTooltipName(originData, geometry);
    addItem(name, value);
  }
  return items;
}

function getTooltipItemsByFindData(geometry: Geometry, point, title, tooltipCfg: TooltipCfg) {
  const { showNil } = tooltipCfg;
  const result = [];
  const dataArray = geometry.dataArray;
  if (!isEmpty(dataArray)) {
    geometry.sort(dataArray); // åè¿è¡æåºï¼ä¾¿äº tooltip æ¥æ¾
    for (const data of dataArray) {
      const record = findDataByPoint(point, data, geometry);
      if (record) {
        const elementId = geometry.getElementId(record);
        const element = geometry.elementsMap[elementId];
        if (geometry.type === 'heatmap' || element.visible) {
          // Heatmap æ²¡æ Element
          // å¦æå¾å½¢åç´ éèäºï¼æä¸å tooltip ä¸å±ç¤ºç¸å³æ°æ®
          const items = getTooltipItems(record, geometry, title, showNil);
          if (items.length) {
            result.push(items);
          }
        }
      }
    }
  }

  return result;
}

function getTooltipItemsByHitShape(geometry, point, title, tooltipCfg: TooltipCfg) {
  const { showNil } = tooltipCfg;
  const result = [];
  const container = geometry.container;
  const shape = container.getShape(point.x, point.y);
  if (shape && shape.get('visible') && shape.get('origin')) {
    const mappingData = shape.get('origin').mappingData;
    const items = getTooltipItems(mappingData, geometry, title, showNil);
    if (items.length) {
      result.push(items);
    }
  }

  return result;
}

/**
 * ä¸è¿è¡éå½æ¥æ¾
 */
export function findItemsFromView(view: View, point: Point, tooltipCfg: TooltipCfg) {
  const result = [];
  // åä» view æ¬èº«æ¥æ¾
  const geometries = view.geometries;
  const { shared, title, reversed } = tooltipCfg;
  for (const geometry of geometries) {
    if (geometry.visible && geometry.tooltipOption !== false) {
      // geometry å¯è§åæ¶æªå³é­ tooltip
      const geometryType = geometry.type;
      let tooltipItems;
      if (['point', 'edge', 'polygon'].includes(geometryType)) {
        // å§ç»éè¿å¾å½¢æ¾å
        tooltipItems = getTooltipItemsByHitShape(geometry, point, title, tooltipCfg);
      } else if (['area', 'line', 'path', 'heatmap'].includes(geometryType)) {
        // å¦ææ¯ 'area', 'line', 'path'ï¼å§ç»éè¿æ°æ®æ¥æ¾æ¹æ³æ¥æ¾ tooltip
        tooltipItems = getTooltipItemsByFindData(geometry, point, title, tooltipCfg);
      } else {
        if (shared !== false) {
          tooltipItems = getTooltipItemsByFindData(geometry, point, title, tooltipCfg);
        } else {
          tooltipItems = getTooltipItemsByHitShape(geometry, point, title, tooltipCfg);
        }
      }
      if (tooltipItems.length) {
        if (reversed) {
          tooltipItems.reverse();
        }
        // geometry æå¯è½ä¼æå¤ä¸ª itemï¼å ä¸ºç¨æ·å¯ä»¥è®¾ç½® geometry.tooltip('x*y*z')
        result.push(tooltipItems);
      }
    }
  }

  return result;
}

export function findItemsFromViewRecurisive(view: View, point: Point, tooltipCfg: TooltipCfg) {
  let result = findItemsFromView(view, point, tooltipCfg);

  // éå½æ¥æ¾ï¼å¹¶åå¹¶ç»æ
  for (const childView of view.views) {
    result = result.concat(findItemsFromView(childView, point, tooltipCfg));
  }

  return result;
}
