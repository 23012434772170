import * as d3Hierarchy from 'd3-hierarchy';
import { assign, isArray, reduce, size } from '@antv/util';
import { getField, getAllNodes } from './util';

const DEFAULT_OPTIONS: Options = {
  field: 'value',
  size: [1, 1], // width, height
  round: false,
  padding: 0,
  // é»è®¤éåº
  sort: (a, b) => b.value - a.value,
  as: ['x', 'y'],
  // æ¯å¦å¿½ç¥ parentValue, å½è®¾ç½®ä¸º true æ¶ï¼ç¶èç¹çæéç±å­åç´ å³å®
  ignoreParentValue: true,
};

export interface Options {
  field: string;
  size?: [number, number];
  round?: boolean;
  ratio?: number;
  padding?: number;
  sort?: Function;
  as?: [string, string];

  ignoreParentValue?: boolean;
}

export function partition(data: any, options: Options): any[] {
  options = assign({} as Options, DEFAULT_OPTIONS, options);
  const as = options.as;
  if (!isArray(as) || as.length !== 2) {
    throw new TypeError('Invalid as: it must be an array with 2 strings (e.g. [ "x", "y" ])!');
  }

  let field;
  try {
    field = getField(options);
  } catch (e) {
    console.warn(e);
  }

  const partition = (data) =>
    d3Hierarchy.partition().size(options.size).round(options.round).padding(options.padding)(
      /**
       * d3Hierarchy å¸å±ä¸­éæå® sum å½æ°è®¡ç® node å¼ï¼è§åæ¯ï¼ä»å½å node å¼å§ä»¥ post-order traversal çæ¬¡åºä¸ºå½åèç¹ä»¥åæ¯ä¸ªåä»£èç¹è°ç¨æå®ç value å½æ°ï¼å¹¶è¿åå½å nodeã
       * for example:
       * { node: 'parent', value: 10, children: [{node: 'child1', value: 5}, {node: 'child2', value: 5}, ]}
       * parent æå¾çè®¡ç®å¼æ¯ sum(node(parent)) + sum(node(child1)) + sum(node(child2))
       * sum å½æ°ä¸­ï¼d ä¸ºç¨æ·ä¼ å¥ç data, children ä¸ºä¿çå­æ®µ
       */
      d3Hierarchy
        .hierarchy(data)
        .sum((d) =>
          size(d.children)
            ? options.ignoreParentValue
              ? 0
              : d[field] - reduce(d.children, (a, b) => a + b[field], 0)
            : d[field]
        )
        .sort(options.sort)
    );
  const root = partition(data);

  /*
   * points:
   *   3  2
   *   0  1
   */
  const x = as[0];
  const y = as[1];

  root.each((node) => {
    node[x] = [node.x0, node.x1, node.x1, node.x0];
    node[y] = [node.y1, node.y1, node.y0, node.y0];
    // æ­æ¥å¾å¼å®¹ä¸ æ§çæ¬
    node.name = node.name || node.data?.name || node.data?.label;
    node.data.name = node.name;

    ['x0', 'x1', 'y0', 'y1'].forEach((prop) => {
      if (as.indexOf(prop) === -1) {
        delete node[prop];
      }
    });
  });

  return getAllNodes(root);
}
