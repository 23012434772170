import { registerInteraction, registerAction } from '@antv/g2';
import { VennElementActive } from './actions/active';
import { VennElementHighlight } from './actions/highlight';
import { VennElementSelected, VennElementSingleSelected } from './actions/selected';

/** ================== æ³¨åäº¤äºåé¦ aciton ================== */

registerAction('venn-element-active', VennElementActive as any);
registerAction('venn-element-highlight', VennElementHighlight as any);
registerAction('venn-element-selected', VennElementSelected as any);
registerAction('venn-element-single-selected', VennElementSingleSelected as any);

/** ================== æ³¨åäº¤äº ================== */

// ========= Active äº¤äº =========
registerInteraction('venn-element-active', {
  start: [{ trigger: 'element:mouseenter', action: 'venn-element-active:active' }],
  end: [{ trigger: 'element:mouseleave', action: 'venn-element-active:reset' }],
});

// ========= é«äº® äº¤äº =========
registerInteraction('venn-element-highlight', {
  start: [{ trigger: 'element:mouseenter', action: 'venn-element-highlight:highlight' }],
  end: [{ trigger: 'element:mouseleave', action: 'venn-element-highlight:reset' }],
});

// ========= Selected äº¤äº =========
// ç¹å» venn element ï¼å¯å¤éï¼
registerInteraction('venn-element-selected', {
  start: [{ trigger: 'element:click', action: 'venn-element-selected:toggle' }],
  rollback: [{ trigger: 'dblclick', action: ['venn-element-selected:reset'] }],
});
// ç¹å» venn element ï¼åéï¼
registerInteraction('venn-element-single-selected', {
  start: [{ trigger: 'element:click', action: 'venn-element-single-selected:toggle' }],
  rollback: [{ trigger: 'dblclick', action: ['venn-element-single-selected:reset'] }],
});

// ========= é¦æ©å¾çå¾ä¾äºä»¶ï¼åç¬æ³¨å =========
// legend hoverï¼element active
registerInteraction('venn-legend-active', {
  start: [{ trigger: 'legend-item:mouseenter', action: ['list-active:active', 'venn-element-active:active'] }],
  end: [{ trigger: 'legend-item:mouseleave', action: ['list-active:reset', 'venn-element-active:reset'] }],
});

// legend hoverï¼element active
registerInteraction('venn-legend-highlight', {
  start: [
    {
      trigger: 'legend-item:mouseenter',
      action: ['legend-item-highlight:highlight', 'venn-element-highlight:highlight'],
    },
  ],
  end: [{ trigger: 'legend-item:mouseleave', action: ['legend-item-highlight:reset', 'venn-element-highlight:reset'] }],
});
