import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { CirclePackingOptions } from './types';
import './interactions';

export type { CirclePackingOptions };

/**
 *  CirclePacking
 * @usage hierarchy, proportions
 */
export class CirclePacking extends Plot<CirclePackingOptions> {
  /**
   * è·å é¢ç§¯å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<CirclePackingOptions> {
    return DEFAULT_OPTIONS;
  }
  /** å¾è¡¨ç±»å */
  public type: string = 'circle-packing';

  protected getDefaultOptions() {
    return CirclePacking.getDefaultOptions();
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<CirclePackingOptions> {
    return adaptor;
  }

  /**
   * è¦åç¶ç±»çæ¹æ³
   */
  protected triggerResize() {
    if (!this.chart.destroyed) {
      // é¦åèªéåºå®¹å¨çå®½é«
      this.chart.forceFit(); // g2 åé¨æ§è¡ changeSizeï¼changeSize ä¸­æ§è¡ render(true)
      this.chart.clear();
      this.execAdaptor(); // æ ¸å¿ï¼å®½é«æ´æ°ä¹åè®¡ç®padding
      // æ¸²æ
      this.chart.render(true);
    }
  }
}
