import { Mix } from './plots/mix';

/** å®éªå®¤å¾è¡¨æå¤çé¶æ®µ */
export enum Stage {
  DEV = 'DEV',
  BETA = 'BETA',
  STABLE = 'STABLE',
}

/**
 * ä¸åé¶æ®µæå°ä¸äºæ¶æ¯ç»å¼åè
 * @param stage
 */
export function notice(stage: Stage, plotType: string) {
  console.warn(
    stage === Stage.DEV
      ? `Plot '${plotType}' is in DEV stage, just give us issues.`
      : stage === Stage.BETA
      ? `Plot '${plotType}' is in BETA stage, DO NOT use it in production env.`
      : stage === Stage.STABLE
      ? `Plot '${plotType}' is in STABLE stage, import it by "import { ${plotType} } from '@antv/g2plot'".`
      : 'invalid Stage type.'
  );
}

/**
 * å®éªå®¤å¾è¡¨ï¼å®éªå®¤ä¸­çå¾è¡¨åæä¸åçé¶æ®µã
 */
export class Lab {
  static get MultiView() {
    notice(Stage.STABLE, 'MultiView');
    return Mix;
  }
}
