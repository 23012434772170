import { deepMix } from '@antv/util';
import { Params } from '../../core/adaptor';
import { getTooltipMapping } from '../../utils/tooltip';
import { deepAssign } from '../../utils';
import { GeometryOptions, geometry, MappingOptions } from './base';

export interface LineGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µ */
  readonly yField?: string;
  /** åç»å­æ®µ */
  readonly seriesField?: string;
  /** æ¯å¦å¹³æ» */
  readonly smooth?: boolean;
  /** æ¯å¦è¿æ¥ç©ºæ°æ® */
  readonly connectNulls?: boolean;
  /** line æ å°éç½® */
  readonly line?: MappingOptions;
  /** é¶æ¢¯æçº¿å¾ç±»å */
  readonly stepType?: string;
}

/**
 * line è¾å©ç¹çéç½®å¤ç
 * @param params
 */
export function line<O extends LineGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const { line, stepType, xField, yField, seriesField, smooth, connectNulls, tooltip } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField]);

  // å¦æå­å¨æå¤ç
  return line
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'line',
            colorField: seriesField,
            tooltipFields: fields,
            mapping: deepMix(
              {
                shape: stepType || (smooth ? 'smooth' : 'line'),
                tooltip: formatter,
              },
              line
            ),
            args: { connectNulls },
          },
        })
      )
    : params;
}
