import { Chart } from '@antv/g2';
import { Types } from '@antv/g2';
import { isArray, isFunction, isString } from '@antv/util';
import { normalPadding } from '../../utils/padding';
import { Params } from '../../core/adaptor';
import { Datum } from '../../types';
import { log, LEVEL, getContainerSize } from '../../utils';
import { functor, wordCloud } from '../../utils/transform/word-cloud';
import { Tag, Word, WordCloudOptions, WordStyle } from './types';

/**
 * ç¨ DataSet è½¬æ¢è¯äºå¾æ°æ®
 * @param params
 */
export function transform(params: Params<WordCloudOptions>): Tag[] {
  const { options: rawOptions, chart } = params;
  const { width, height, padding: chartPadding, appendPadding, ele } = chart as Chart;
  const {
    data,
    imageMask,
    wordField,
    weightField,
    colorField,
    wordStyle,
    timeInterval,
    random,
    spiral,
    autoFit = true,
    placementStrategy,
  } = rawOptions;
  if (!data || !data.length) {
    return [];
  }
  const { fontFamily, fontWeight, padding, fontSize } = wordStyle;
  const arr = getSingleKeyValues(data, weightField);
  const range = [min(arr), max(arr)] as [number, number];

  // åæ¢åº text å value å­æ®µ
  const words = data.map(
    (datum: Datum): Word => ({
      text: datum[wordField],
      value: datum[weightField],
      color: datum[colorField],
      datum, // å­ä¸ä¸åå§æ°æ®
    })
  );

  const options = {
    imageMask: imageMask as HTMLImageElement,
    font: fontFamily,
    fontSize: getFontSizeMapping(fontSize, range),
    fontWeight: fontWeight,
    // å¾è¡¨å®½é«åå» padding ä¹åçå®½é«
    size: getSize({
      width,
      height,
      padding: chartPadding,
      appendPadding,
      autoFit,
      container: ele,
    }),
    padding: padding,
    timeInterval,
    random,
    spiral,
    rotate: getRotate(rawOptions),
  };

  // èªå®ä¹å¸å±å½æ°
  if (isFunction(placementStrategy)) {
    const result = words.map((word: Word, index: number, words: Word[]) => ({
      ...word,
      hasText: !!word.text,
      font: functor(options.font)(word, index, words),
      weight: functor(options.fontWeight)(word, index, words),
      rotate: functor(options.rotate)(word, index, words),
      size: functor(options.fontSize)(word, index, words),
      style: 'normal',
      ...placementStrategy.call(chart, word, index, words),
    }));

    // æ·»å ä¸¤ä¸ªåç§æ°æ®ï¼åå«è¡¨ç¤ºå·¦ä¸è§åå³ä¸è§
    result.push({
      text: '',
      value: 0,
      x: 0,
      y: 0,
      opacity: 0,
    });
    result.push({
      text: '',
      value: 0,
      x: options.size[0],
      y: options.size[1],
      opacity: 0,
    });

    return result;
  }

  // æ°æ®åå¤å¨å¤é¨åï¼wordCloud åçº¯å°±æ¯åå¸å±
  return wordCloud(words, options);
}

/**
 * è·åæç»çå®éç»å¾å°ºå¯¸ï¼[width, height]
 * @param chart
 */
export function getSize(options: {
  width: number;
  height: number;
  padding: Types.ViewPadding;
  appendPadding: Types.ViewAppendPadding;
  autoFit: boolean;
  container: HTMLElement;
}): [number, number] {
  let { width, height } = options;
  const { container, autoFit, padding, appendPadding } = options;

  // ç±äºè¯äºå¾æ¯ä¸ªè¯è¯­çåæ é½æ¯åéè¿ DataSet æ ¹æ®å¾è¡¨å®½é«è®¡ç®åºæ¥çï¼
  // ä¹å°±æ¯è¯´ï¼å¦æä¸å¼å§æä¾ç» DataSet çå®½é«ä¿¡æ¯åæç»æ¾ç¤ºçå®½é«ä¸ç¸åï¼
  // é£ä¹å°±ä¼åºç°å¸å±éä¹±çæåµï¼æä»¥è¿éå¤ççç®çå°±æ¯è®©ä¸å¼å§æä¾ç» DataSet ç
  // å®½é«ä¿¡æ¯ä¸æç»æ¾ç¤ºçå®½é«ä¿¡æ¯ç¸åï¼é¿åæ¾ç¤ºéä¹±ã
  if (autoFit) {
    const containerSize = getContainerSize(container);
    width = containerSize.width;
    height = containerSize.height;
  }

  // å®½é«ä¸è½ä¸º 0ï¼å¦åä¼é ææ­»å¾ªç¯
  width = width || 400;
  height = height || 400;

  const [top, right, bottom, left] = resolvePadding({ padding, appendPadding });
  const result = [width - (left + right), height - (top + bottom)];

  return result as [number, number];
}

/**
 * æ ¹æ®å¾è¡¨ç padding å appendPadding è®¡ç®åºå¾è¡¨çæç» padding
 * @param chart
 */
function resolvePadding(options: { padding: Types.ViewPadding; appendPadding: Types.ViewAppendPadding }) {
  const padding = normalPadding(options.padding);
  const appendPadding = normalPadding(options.appendPadding);
  const top = padding[0] + appendPadding[0];
  const right = padding[1] + appendPadding[1];
  const bottom = padding[2] + appendPadding[2];
  const left = padding[3] + appendPadding[3];

  return [top, right, bottom, left];
}

/**
 * å¤ç imageMask å¯è½ä¸º url å­ç¬¦ä¸²çæåµ
 * @param  {HTMLImageElement | string} img
 * @return {Promise}
 */
export function processImageMask(img: HTMLImageElement | string): Promise<HTMLImageElement> {
  return new Promise((res, rej) => {
    if (img instanceof HTMLImageElement) {
      res(img);
      return;
    }
    if (isString(img)) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = img;
      image.onload = () => {
        res(image);
      };
      image.onerror = () => {
        log(LEVEL.ERROR, false, 'image %s load failed !!!', img);
        rej();
      };
      return;
    }
    log(LEVEL.WARN, img === undefined, 'The type of imageMask option must be String or HTMLImageElement.');
    rej();
  });
}

/**
 * æç¨æ·æä¾ç fontSize å¼è½¬æ¢æç¬¦å DataSet è¦æ±çå¼
 * @param options
 * @param range
 */
export function getFontSizeMapping(fontSize: WordStyle['fontSize'], range?: [number, number]) {
  if (isFunction(fontSize)) {
    return fontSize;
  }
  if (isArray(fontSize)) {
    const [fMin, fMax] = fontSize;
    if (!range) {
      return () => (fMax + fMin) / 2;
    }
    const [min, max] = range;
    if (max === min) {
      return () => (fMax + fMin) / 2;
    }
    return function fontSize({ value }) {
      return ((fMax - fMin) / (max - min)) * (value - min) + fMin;
    };
  }
  return () => fontSize;
}

export function getSingleKeyValues(data: Datum[], key: string) {
  return data
    .map((v) => v[key])
    .filter((v) => {
      // è¿æ»¤é number
      if (typeof v === 'number' && !isNaN(v)) return true;
      return false;
    });
}

/**
 * æç¨æ·æä¾çå³äºæè½¬è§åº¦çå­æ®µå¼è½¬æ¢æç¬¦å DataSet è¦æ±çå¼
 * @param options
 */
function getRotate(options: WordCloudOptions) {
  const { rotation, rotationSteps } = resolveRotate(options);
  if (!isArray(rotation)) return rotation;
  const min = rotation[0];
  const max = rotation[1];
  // ç­äº 1 æ¶ä¸æè½¬ï¼æä»¥ææ¯ä»½å¤§å°è®¾ä¸º 0
  const perSize = rotationSteps === 1 ? 0 : (max - min) / (rotationSteps - 1);
  return function rotate() {
    if (max === min) return max;
    return Math.floor(Math.random() * rotationSteps) * perSize;
  };
}

/**
 * ç¡®ä¿å¼å¨è¦æ±èå´å
 * @param options
 */
function resolveRotate(options: WordCloudOptions) {
  let { rotationSteps } = options.wordStyle;
  if (rotationSteps < 1) {
    log(LEVEL.WARN, false, 'The rotationSteps option must be greater than or equal to 1.');
    rotationSteps = 1;
  }
  return {
    rotation: options.wordStyle.rotation,
    rotationSteps,
  };
}

/**
 * ä¼ å¥ä¸ä¸ªåç´ ä¸ºæ°å­çæ°ç»ï¼
 * è¿åè¯¥æ°ç»ä¸­å¼æå°çæ°å­ã
 * @param numbers
 */
function min(numbers: number[]) {
  return Math.min(...numbers);
}

/**
 * ä¼ å¥ä¸ä¸ªåç´ ä¸ºæ°å­çæ°ç»ï¼
 * è¿åè¯¥æ°ç»ä¸­å¼æå¤§çæ°å­ã
 * @param numbers
 */
function max(numbers: number[]) {
  return Math.max(...numbers);
}
