import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { NODE_ANCESTORS_FIELD } from '../../utils/hierarchy/util';
import { SunburstOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS, SUNBURST_ANCESTOR_FIELD, SUNBURST_PATH_FIELD } from './constant';
import './interactions';

export type { SunburstOptions };

export class Sunburst extends Plot<SunburstOptions> {
  /**
   * è·å æ­æ¥å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<SunburstOptions> {
    return DEFAULT_OPTIONS;
  }

  /** æ­æ¥å¾ èç¹çç¥åèç¹ */
  static SUNBURST_ANCESTOR_FIELD = SUNBURST_ANCESTOR_FIELD;
  /** æ­æ¥å¾ èç¹çè·¯å¾ */
  static SUNBURST_PATH_FIELD = SUNBURST_PATH_FIELD;
  /** èç¹çç¥åèç¹ */
  static NODE_ANCESTORS_FIELD = NODE_ANCESTORS_FIELD;

  /** å¾è¡¨ç±»å */
  public type: string = 'sunburst';

  /**
   * è·å æ­æ¥å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Sunburst.getDefaultOptions();
  }

  /**
   * è·åæ­æ¥å¾çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<SunburstOptions> {
    return adaptor;
  }
}
