export default {
  base: {
    remove: 'å é¤',
    cancel: 'åæ¶',
    confirm: 'ç¡®å®',
    inert: 'æå¥',
    width: 'å®½åº¦',
    height: 'é«åº¦'
  },
  controls: {
    clear: 'æ¸é¤åå®¹',
    undo: 'æ¤é',
    redo: 'éå',
    fontSize: 'å­å·',
    lineHeight: 'è¡é«',
    letterSpacing: 'å­é´è·',
    textIndent: 'æ®µè½ç¼©è¿',
    increaseIndent: 'å¢å ç¼©è¿',
    decreaseIndent: 'åå°ç¼©è¿',
    border: 'è¾¹æ¡',
    color: 'é¢è²',
    textColor: 'æå­é¢è²',
    backgroundColor: 'èæ¯é¢è²',
    tempColors: 'ä¸´æ¶é¢è²',
    bold: 'å ç²',
    italic: 'æä½',
    underline: 'ä¸åçº¿',
    strikeThrough: 'å é¤çº¿',
    fontFamily: 'å­ä½',
    textAlign: 'ææ¬å¯¹é½',
    alignLeft: 'å±å·¦',
    alignCenter: 'å±ä¸­',
    alignRight: 'å±å³',
    alignJustify: 'ä¸¤ç«¯',
    floatLeft: 'å·¦æµ®å¨',
    floatRight: 'å³æµ®å¨',
    superScript: 'ä¸æ ',
    subScript: 'ä¸æ ',
    removeStyles: 'æ¸é¤æ ·å¼',
    headings: 'æ é¢',
    header: 'æ é¢',
    normal: 'å¸¸è§',
    orderedList: 'æåºåè¡¨',
    unorderedList: 'æ åºåè¡¨',
    blockQuote: 'å¼ç¨',
    code: 'ä»£ç ',
    link: 'é¾æ¥',
    unlink: 'æ¸é¤é¾æ¥',
    hr: 'æ°´å¹³çº¿',
    media: 'åªä½',
    mediaLibirary: 'åªä½åº',
    emoji: 'å°è¡¨æ',
    fullscreen: 'å¨å±',
    exitFullscreen: 'éåºå¨å±',
  },
  linkEditor: {
    textInputPlaceHolder: 'è¾å¥é¾æ¥æå­',
    linkInputPlaceHolder: 'è¾å¥é¾æ¥å°å',
    inputWithEnterPlaceHolder: 'è¾å¥é¾æ¥å°åå¹¶åè½¦',
    openInNewWindow: 'å¨æ°çªå£æå¼',
    removeLink: 'ç§»é¤é¾æ¥'
  },
  audioPlayer: {
    title: 'æ­æ¾é³é¢æä»¶'
  },
  videoPlayer: {
    title: 'æ­æ¾è§é¢æä»¶',
    embedTitle: 'åµå¥å¼åªä½'
  },
  media: {
    image: 'å¾å',
    video: 'è§é¢',
    audio: 'é³é¢',
    embed: 'åµå¥å¼åªä½'
  }
}