import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';

/**
 * é¢ç§¯å¾é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
  tooltip: {
    shared: true,
    showMarkers: true,
    showCrosshairs: true,
    crosshairs: {
      type: 'x',
    },
  },
  isStack: true,
  // é»è®¤å¼å¯
  line: {},
  legend: {
    position: 'top-left',
    radio: {},
  },
});
