import StateAction from './state';

/**
 * åç´  active ç Actionï¼åè®¸å¤ä¸ªåç´ åæ¶ active
 * @class
 * @ignore
 */
class ElementActive extends StateAction {
  protected stateName: string = 'active';
  /**
   * Active Element
   */
  public active() {
    this.setState();
  }
}

export default ElementActive;
