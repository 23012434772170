import { VIEW_LIFE_CIRCLE, Event } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { GaugeOptions } from './types';
import { adaptor, statistic } from './adaptor';
import { INDICATEOR_VIEW_ID, RANGE_VIEW_ID, DEFAULT_OPTIONS } from './constants';
import { getIndicatorData, getRangeData } from './utils';

// æ³¨å shape
import './shapes/indicator';
import './shapes/meter-gauge';

export type { GaugeOptions };

/**
 * ä»ªè¡¨ç
 */
export class Gauge extends Plot<GaugeOptions> {
  /**
   * è·å ä»ªè¡¨ç é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<GaugeOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'gauge';

  /**
   * æ´æ°æ°æ®
   * @param percent
   */
  public changeData(percent: number) {
    this.chart.emit(
      VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, null)
    );

    this.updateOption({ percent });
    const indicatorView = this.chart.views.find((v) => v.id === INDICATEOR_VIEW_ID);
    if (indicatorView) {
      indicatorView.data(getIndicatorData(percent));
    }

    const rangeView = this.chart.views.find((v) => v.id === RANGE_VIEW_ID);
    if (rangeView) {
      rangeView.data(getRangeData(percent, this.options.range));
    }
    // todo åç»­è®© G2 å±å¨ afterrender ä¹åï¼æ¥éç» annotations
    statistic({ chart: this.chart, options: this.options }, true);

    this.chart.emit(
      VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, null)
    );
  }

  /**
   * è·åé»è®¤éç½®
   * ä¾ base ä½¿ç¨
   */
  protected getDefaultOptions() {
    return Gauge.getDefaultOptions();
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<GaugeOptions> {
    return adaptor;
  }
}
