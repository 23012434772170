import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';

/**
 * é¥¼å¾é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
  legend: {
    position: 'right',
    radio: {},
  },
  tooltip: {
    shared: false,
    showTitle: false,
    showMarkers: false,
  },
  label: {
    layout: { type: 'limit-in-plot', cfg: { action: 'ellipsis' } },
  },
  /** é¥¼å¾æ ·å¼, ä¸å½±åæé»ä¸»é¢ */
  pieStyle: {
    stroke: 'white',
    lineWidth: 1,
  },
  /** é¥¼å¾ä¸­å¿ææ¬é»è®¤æ ·å¼ */
  statistic: {
    title: {
      style: { fontWeight: 300, color: '#4B535E', textAlign: 'center', fontSize: '20px', lineHeight: 1 },
    },
    content: {
      style: {
        fontWeight: 'bold',
        color: 'rgba(44,53,66,0.85)',
        textAlign: 'center',
        fontSize: '32px',
        lineHeight: 1,
      },
    },
  },
  /** é»è®¤å³é­ text-annotation å¨ç» */
  theme: {
    components: {
      annotation: {
        text: {
          animate: false,
        },
      },
    },
  },
});
