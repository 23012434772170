// æ¼æå æ¯: data[n][yField] / data[0][yField]
export const FUNNEL_PERCENT = '$$percentage$$';
// æ¼ææ å°å¼
export const FUNNEL_MAPPING_VALUE = '$$mappingValue$$';
// æ¼æè½¬åç: data[n][yField] / data[n-1][yField];
export const FUNNEL_CONVERSATION = '$$conversion$$';
// æ¼æåé¡¹å æ»ä½åçç¾åæ¯ï¼ç¨äºå¨ææ¼æå¾è®¡ç®é«åº¦ï¼
// data[n][yField] / sum(data[0-n][yField])
export const FUNNEL_TOTAL_PERCENT = '$$totalPercentage$$';
// æ¼æå¤è¾¹å x åæ 
export const PLOYGON_X = '$$x$$';
export const PLOYGON_Y = '$$y$$';

/**
 * æ¼æå¾ é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = {
  appendPadding: [0, 80],
  minSize: 0,
  maxSize: 1,
  meta: {
    [FUNNEL_MAPPING_VALUE]: {
      min: 0,
      max: 1,
      nice: false,
    },
  },
  label: {
    style: {
      fill: '#fff',
      fontSize: 12,
    },
  },
  tooltip: {
    showTitle: false,
    showMarkers: false,
    shared: false,
  },
  conversionTag: {
    offsetX: 10,
    offsetY: 0,
    style: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.45)',
    },
  },
};
