import { Event as GEvent, IShape } from '../dependents';
import { Datum } from '../interface';
import View from './view';

/**
 * @todo Whether it can(or necessary to) keep consistent with the structure of G.Event or directly use the structure of G.Event
 * G2 äºä»¶çäºä»¶åè£ç±»ï¼åºäº G.Event
 */
export default class Event {
  /** å½å target å½å±ç view å®ä¾ */
  public view: View;
  /** è¢«åè£çåç G äºä»¶ */
  public gEvent: GEvent;
  /** åå§æ°æ® */
  public data?: Datum;
  /** äºä»¶ç±»å */
  public type: string;

  constructor(view: View, gEvent: GEvent, data?: Datum) {
    this.view = view;
    this.gEvent = gEvent;
    this.data = data;
    this.type = gEvent.type;
  }

  /**
   * éäº¤äºäº§ççäºä»¶
   * @param view
   * @param type
   * @param data
   */
  public static fromData(view: View, type: string, data: Datum) {
    return new Event(view, new GEvent(type, {}), data);
  }

  // below props are proxy props of G.event convenient

  /** the real trigger shape of the event */
  public get target(): IShape {
    // @todo G ä¸­äºä»¶å®ä¹ä¸º object ä¸æ­£ç¡®ï¼è¿éå ignore
    // @ts-ignore
    return this.gEvent.target;
  }

  /** è·åå¯¹åºç dom åçæ¶é´ */
  public get event(): any {
    return this.gEvent.originalEvent;
  }

  /** x ç»å¸åæ  */
  public get x(): number {
    return this.gEvent.x;
  }

  /** y ç»å¸åæ  */
  public get y(): number {
    return this.gEvent.y;
  }

  /** x çªå£åæ  */
  public get clientX(): number {
    return this.gEvent.clientX;
  }

  /** y çªå£åæ  */
  public get clientY(): number {
    return this.gEvent.clientY;
  }
  // end for proxy events

  /**
   * event string
   * @returns string
   */
  public toString(): string {
    return `[Event (type=${this.type})]`;
  }

  /**
   * clone a new event with same attributes
   * @returns [[Event]]
   */
  public clone(): Event {
    return new Event(this.view, this.gEvent, this.data);
  }
}
