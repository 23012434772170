import * as d3Hierarchy from 'd3-hierarchy';
import { assign, isArray } from '@antv/util';
import { HierarchyOption } from './types';
import { getField, getAllNodes } from './util';

const DEFAULT_OPTIONS: HierarchyOption = {
  field: 'value',
  tile: 'treemapSquarify', // treemapBinary, treemapDice, treemapSlice, treemapSliceDice, treemapSquarify, treemapResquarify
  size: [1, 1], // width, height
  round: false,
  ignoreParentValue: true,
  padding: 0,
  paddingInner: 0,
  paddingOuter: 0,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  as: ['x', 'y'],
  // é»è®¤éåº
  sort: (a, b) => b.value - a.value,
  // çºµæ¨ªæ¯, treemapSquarify å¸å±æ¶å¯ç¨ï¼é»è®¤é»éåå²æ¯ä¾
  ratio: 0.5 * (1 + Math.sqrt(5)),
};

export function getTileMethod(tile: string, ratio: number) {
  return tile === 'treemapSquarify' ? d3Hierarchy[tile].ratio(ratio) : d3Hierarchy[tile];
}

export function treemap(data: any, options: HierarchyOption): any[] {
  options = assign({} as HierarchyOption, DEFAULT_OPTIONS, options);
  const as = options.as;
  if (!isArray(as) || as.length !== 2) {
    throw new TypeError('Invalid as: it must be an array with 2 strings (e.g. [ "x", "y" ])!');
  }

  let field;
  try {
    field = getField(options);
  } catch (e) {
    console.warn(e);
  }

  const tileMethod = getTileMethod(options.tile, options.ratio);

  const partition = (data) =>
    d3Hierarchy
      .treemap()
      .tile(tileMethod)
      .size(options.size)
      .round(options.round)
      .padding(options.padding)
      .paddingInner(options.paddingInner)
      .paddingOuter(options.paddingOuter)
      .paddingTop(options.paddingTop)
      .paddingRight(options.paddingRight)
      .paddingBottom(options.paddingBottom)
      .paddingLeft(options.paddingLeft)(
      /**
       * d3Hierarchy å¸å±ä¸­éæå® sum å½æ°è®¡ç® node å¼ï¼è§åæ¯ï¼ä»å½å node å¼å§ä»¥ post-order traversal çæ¬¡åºä¸ºå½åèç¹ä»¥åæ¯ä¸ªåä»£èç¹è°ç¨æå®ç value å½æ°ï¼å¹¶è¿åå½å nodeã
       * for example:
       * { node: 'parent', value: 10, children: [{node: 'child1', value: 5}, {node: 'child2', value: 5}, ]}
       * parent æå¾çè®¡ç®å¼æ¯ sum(node(parent)) + sum(node(child1)) + sum(node(child2))
       * ignoreParentValue ä¸º true(é»è®¤) æ¶ï¼ç¶åç´ çå¼ç±å­åç´ ç´¯å èæ¥ï¼è¯¥å¼ä¸º 0 + 5 + 5 = 10
       * ignoreParentValue ä¸º false æ¶ï¼ç¶åç´ çå¼ç±å½åèç¹ åå­åç´ ç´¯å èæ¥ï¼è¯¥å¼ä¸º 10 + 5 + 5 = 20
       * sum å½æ°ä¸­ï¼d ä¸ºç¨æ·ä¼ å¥ç data, children ä¸ºä¿çå­æ®µ
       */
      d3Hierarchy
        .hierarchy(data)
        .sum((d) => (options.ignoreParentValue && d.children ? 0 : d[field]))
        .sort(options.sort)
    );
  const root = partition(data);

  /*
   * points:
   *   3  2
   *   0  1
   */
  const x = as[0];
  const y = as[1];
  root.each((node) => {
    node[x] = [node.x0, node.x1, node.x1, node.x0];
    node[y] = [node.y1, node.y1, node.y0, node.y0];
    ['x0', 'x1', 'y0', 'y1'].forEach((prop) => {
      if (as.indexOf(prop) === -1) {
        delete node[prop];
      }
    });
  });

  return getAllNodes(root);
}
