import { get } from '@antv/util';
import { BBox, IGroup, IShape } from '../../../dependents';
import { isIntersect } from '../../../util/collision-detect';
import { LabelItem } from '../interface';
import { getlLabelBackgroundInfo } from '../util';

/**
 * label é²é®æ¡å¸å±ï¼å¨ä¸æ¹å label ä½ç½®çæåµä¸å¯¹ç¸äºéå ç label è¿è¡éèï¼éç§»é¤ï¼
 * ä¸åäº 'overlap' ç±»åçå¸å±ï¼è¯¥å¸å±ä¸ä¼å¯¹ label çä½ç½®è¿è¡åç§»è°æ´ã
 * @param labels åä¸å¸å±è°æ´ç label æ°ç»éå
 */
export function hideOverlap(items: LabelItem[], labels: IGroup[], shapes: IShape[] | IGroup[], region: BBox) {
  // todo æ·»å  labelrank
  // each label will hide the next one because the next one always has lower rank.

  // Detect overlapping labels
  for (let i = 0; i < labels.length; i++) {
    const label1 = labels[i];
    if (labels[i].get('visible')) {
      for (let j = i + 1; j < labels.length; j++) {
        const label2 = labels[j];
        if (label1 && label2 && label1 !== label2 && label2.get('visible')) {
          const box1 = getlLabelBackgroundInfo(label1, items[i], get(items[i], 'background.padding'));
          const box2 = getlLabelBackgroundInfo(label2, items[j], get(items[j], 'background.padding'));

          if (isIntersect(box1, box2)) {
            labels[j].set('visible', false);
          }
        }
      }
    }
  }
}
