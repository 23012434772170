import { registerGeometryLabel, GeometryLabel } from '@antv/g2';

// Step 1
// èªå®ä¹ Label ç±»
// éè¦ç»§æ¿ GeometryLabel åºç±»
class VennLabel extends GeometryLabel {
  /**
   * è·åæ¯ä¸ª label çä½ç½®
   * @param labelCfg
   * @param mappingData
   * @param index
   * @returns label point
   */
  protected getLabelPoint(labelCfg, mappingData, index: number) {
    const { x, y } = labelCfg.data;
    const { offsetX, offsetY } = labelCfg.customLabelInfo;
    return {
      content: labelCfg.content[index],
      x: x + offsetX,
      y: y + offsetY,
    };
  }
}

// Step 2: æ³¨å CustomLabel
registerGeometryLabel('venn', VennLabel);
