import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { ViolinOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { transformViolinData } from './utils';
export type { ViolinOptions };

export class Violin extends Plot<ViolinOptions> {
  /**
   * è·å é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<ViolinOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'violin';

  /**
   * @override
   */
  public changeData(data: ViolinOptions['data']) {
    this.updateOption({ data });
    this.chart.changeData(transformViolinData(this.options));
  }

  /**
   * è·å å°æç´å¾ é»è®¤éç½®é¡¹
   */
  protected getDefaultOptions(): Partial<ViolinOptions> {
    return Violin.getDefaultOptions();
  }

  /**
   * è·å å°æç´å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<ViolinOptions> {
    return adaptor;
  }
}
