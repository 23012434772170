import { ext } from '@antv/matrix-util';
import { IElement, IGroup, IShape } from '../dependents';

const transform: (m: number[], actions: any[][]) => number[] = ext.transform;

export { transform };

/**
 * å¯¹åç´ è¿è¡å¹³ç§»æä½ã
 * @param element è¿è¡åæ¢çåç´ 
 * @param x x æ¹åä½ç§»
 * @param y y æ¹åä½ç§»
 */
export function translate(element: IGroup | IShape, x: number, y: number) {
  const matrix = transform(element.getMatrix(), [['t', x, y]]);
  element.setMatrix(matrix);
}

/**
 * è·ååç´ æè½¬ç©éµ (ä»¥åç´ çå·¦ä¸è§ä¸ºæè½¬ç¹)
 * @param element è¿è¡åæ¢çåç´ 
 * @param rotateRadian æè½¬å¼§åº¦
 */
export function getRotateMatrix(element: IElement, rotateRadian: number) {
  const { x, y } = element.attr();
  const matrix = transform(element.getMatrix(), [
    ['t', -x, -y],
    ['r', rotateRadian],
    ['t', x, y],
  ]);
  return matrix;
}

/**
 * å¯¹åç´ è¿è¡æè½¬æä½ã
 * @param element è¿è¡åæ¢çåç´ 
 * @param rotateRadian æè½¬å¼§åº¦
 */
export function rotate(element: IGroup | IShape, rotateRadian: number) {
  const matrix = getRotateMatrix(element, rotateRadian);
  element.setMatrix(matrix);
}

/**
 * è·ååç©éµã
 * @returns identity matrix
 */
export function getIdentityMatrix(): number[] {
  return [1, 0, 0, 0, 1, 0, 0, 0, 1];
}

/**
 * å´ç»å¾å½¢ä¸­å¿ç¹è¿è¡ç¼©æ¾
 * @param element è¿è¡ç¼©æ¾çå¾å½¢åç´ 
 * @param ratio ç¼©æ¾æ¯ä¾
 */
export function zoom(element: IGroup | IShape, ratio: number) {
  const bbox = element.getBBox();
  const x = (bbox.minX + bbox.maxX) / 2;
  const y = (bbox.minY + bbox.maxY) / 2;
  element.applyToMatrix([x, y, 1]);

  const matrix = transform(element.getMatrix(), [
    ['t', -x, -y],
    ['s', ratio, ratio],
    ['t', x, y],
  ]);
  element.setMatrix(matrix);
}
