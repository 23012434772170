import { Coordinate } from '@antv/coord';
import { IGroup, IShape } from '@antv/g-base';
import { each, get } from '@antv/util';
import { doAnimate } from '../animate';
import { getReplaceAttrs } from '../util/graphics';

/** label çå¿è¦éç½® */
type Cfg = {
  data: any;
  origin: any;
  animateCfg: any;
  coordinate: Coordinate;
};

/**
 * @desc æ´æ° label (ç®åæ²¡ææ ¹æ® id ç´¢å¼ï¼è¿æ¯ä¼å­å¨ä¸ç¹å°é®é¢çï¼åªè½æ ¹æ® idx ç´¢å¼)
 * @done shape å±æ§æ´æ°
 * @done shape delete
 * @done shape append
 *
 * @param fromShape old labelShape
 * @param toShape new labelShape
 * @param cfg
 */
export function updateLabel(fromShape: IGroup, toShape: IGroup, cfg: Cfg): void {
  const { data, origin, animateCfg, coordinate } = cfg;
  const updateAnimateCfg = get(animateCfg, 'update');

  fromShape.set('data', data);
  fromShape.set('origin', origin);
  fromShape.set('animateCfg', animateCfg);
  fromShape.set('coordinate', coordinate);
  fromShape.set('visible', toShape.get('visible'));

  fromShape.getChildren().forEach((fromChild, idx) => {
    const toChild = toShape.getChildByIndex(idx) as IShape;
    if (!toChild) {
      fromShape.removeChild(fromChild);
      fromChild.remove(true);
    } else {
      fromChild.set('data', data);
      fromChild.set('origin', origin);
      fromChild.set('animateCfg', animateCfg);
      fromChild.set('coordinate', coordinate);

      const newAttrs = getReplaceAttrs(fromChild as IShape, toChild);
      if (updateAnimateCfg) {
        doAnimate(fromChild as IShape, updateAnimateCfg, {
          toAttrs: newAttrs,
          coordinate,
        });
      } else {
        fromChild.attr(newAttrs);
      }
      if (toChild.isGroup()) {
        updateLabel(fromChild as any, toChild as any, cfg);
      }
    }
  });

  // append
  each(toShape.getChildren(), (child, idx) => {
    if (idx >= fromShape.getCount()) {
      if (!child.destroyed) {
        fromShape.add(child);
      }
    }
  });
}
