import { Coordinate } from '@antv/coord';
import { isArray, isNil, get } from '@antv/util';
import { getAngle, getSectorPath } from '../../../util/graphics';
import { PathCommand } from '../../../dependents';
import { Point, ShapeInfo, ShapePoint } from '../../../interface';

/**
 * @ignore
 * æ ¹æ®æ°æ®ç¹çæç©å½¢çåä¸ªå³é®ç¹
 * @param pointInfo æ°æ®ç¹ä¿¡æ¯
 * @param [isPyramid] æ¯å¦ä¸ºå°åºæ¼æå¾
 * @returns rect points è¿åç©å½¢åä¸ªé¡¶ç¹ä¿¡æ¯
 */
export function getRectPoints(pointInfo: ShapePoint): Point[] {
  const { x, y, y0, size } = pointInfo;
  // æ 4 ç§æåµï¼
  // 1. x, y é½ä¸æ¯æ°ç»
  // 2. yæ¯æ°ç»ï¼xä¸æ¯
  // 3. xæ¯æ°ç»ï¼yä¸æ¯
  // 4. x, y é½æ¯æ°ç»
  let yMin;
  let yMax;
  if (isArray(y)) {
    [yMin, yMax] = y;
  } else {
    yMin = y0;
    yMax = y;
  }

  let xMin;
  let xMax;
  if (isArray(x)) {
    [xMin, xMax] = x;
  } else {
    xMin = x - size / 2;
    xMax = x + size / 2;
  }

  const points = [
    { x: xMin, y: yMin },
    { x: xMin, y: yMax },
  ];

  // ç©å½¢çåä¸ªå³é®ç¹ï¼ç»æå¦ä¸ï¼å·¦ä¸è§é¡ºæ¶éè¿æ¥ï¼
  // 1 ---- 2
  // |      |
  // 0 ---- 3
  points.push({ x: xMax, y: yMax }, { x: xMax, y: yMin });

  return points;
}

/**
 * @ignore
 * æ ¹æ®ç©å½¢å³é®ç¹ç»å¶ path
 * @param points å³é®ç¹æ°ç»
 * @param isClosed path æ¯å¦éè¦é­å
 * @returns è¿åç©å½¢ç path
 */
export function getRectPath(points: Point[], isClosed: boolean = true): PathCommand[] {
  const path = [];
  const firstPoint = points[0];
  path.push(['M', firstPoint.x, firstPoint.y]);
  for (let i = 1, len = points.length; i < len; i++) {
    path.push(['L', points[i].x, points[i].y]);
  }
  // å¯¹äº shape="line" path ä¸åºè¯¥é­åï¼å¦åä¼é æ lineCap ç»å¾å±æ§å¤±æ
  if (isClosed) {
    path.push(['L', firstPoint.x, firstPoint.y]); // éè¦é­å
    path.push(['z']);
  }
  return path;
}

/**
 * å¤ç rect path ç radius
 * @returns è¿åç©å½¢ path çåä¸ªè§ç arc åå¾
 */
export function parseRadius(radius: number | number[], minLength: number): number[] {
  let r1 = 0;
  let r2 = 0;
  let r3 = 0;
  let r4 = 0;
  if (isArray(radius)) {
    if (radius.length === 1) {
      r1 = r2 = r3 = r4 = radius[0];
    } else if (radius.length === 2) {
      r1 = r3 = radius[0];
      r2 = r4 = radius[1];
    } else if (radius.length === 3) {
      r1 = radius[0];
      r2 = r4 = radius[1];
      r3 = radius[2];
    } else {
      r1 = radius[0];
      r2 = radius[1];
      r3 = radius[2];
      r4 = radius[3];
    }
  } else {
    r1 = r2 = r3 = r4 = radius;
  }

  // å¤ç è¾¹çå¼
  if (r1 + r2 > minLength) {
    r1 = r1 ? minLength / (1 + r2 / r1) : 0;
    r2 = minLength - r1;
  }

  if (r3 + r4 > minLength) {
    r3 = r3 ? minLength / (1 + r4 / r3) : 0;
    r4 = minLength - r3;
  }

  return [r1 || 0, r2 || 0, r3 || 0, r4 || 0];
}

/**
 * è·å interval ç©å½¢èæ¯ç path
 * @param cfg å³é®ç¹çä¿¡æ¯
 * @param points å·²è½¬åä¸ºç»å¸åæ ç 4 ä¸ªå³é®ç¹
 * @param coordinate åæ ç³»
 * @returns è¿åç©å½¢èæ¯ç path
 */
export function getBackgroundRectPath(cfg: ShapeInfo, points: Point[], coordinate: Coordinate): PathCommand[] {
  let path = [];
  if (coordinate.isRect) {
    const p0 = coordinate.isTransposed
      ? { x: coordinate.start.x, y: points[0].y }
      : { x: points[0].x, y: coordinate.start.y };
    const p1 = coordinate.isTransposed
      ? { x: coordinate.end.x, y: points[2].y }
      : { x: points[3].x, y: coordinate.end.y };

    // corner radius of background shape works only in ç¬å¡å°åæ ç³»
    const radius = get(cfg, ['background', 'style', 'radius']);
    if (radius) {
      const width = coordinate.isTransposed ? Math.abs(points[0].y - points[2].y) : points[2].x - points[1].x;
      const height = coordinate.isTransposed ? coordinate.getWidth() : coordinate.getHeight();
      const [r1, r2, r3, r4] = parseRadius(radius, Math.min(width, height));

      path.push(['M', p0.x, p1.y + r1]);
      r1 !== 0 && path.push(['A', r1, r1, 0, 0, 1, p0.x + r1, p1.y]);
      path.push(['L', p1.x - r2, p1.y]);
      r2 !== 0 && path.push(['A', r2, r2, 0, 0, 1, p1.x, p1.y + r2]);
      path.push(['L', p1.x, p0.y - r3]);
      r3 !== 0 && path.push(['A', r3, r3, 0, 0, 1, p1.x - r3, p0.y]);
      path.push(['L', p0.x + r4, p0.y]);
      r4 !== 0 && path.push(['A', r4, r4, 0, 0, 1, p0.x, p0.y - r4]);
    } else {
      path.push(['M', p0.x, p0.y]);
      path.push(['L', p1.x, p0.y]);
      path.push(['L', p1.x, p1.y]);
      path.push(['L', p0.x, p1.y]);
      path.push(['L', p0.x, p0.y]);
    }

    path.push(['z']);
  }

  if (coordinate.isPolar) {
    const center = coordinate.getCenter();
    const { startAngle, endAngle } = getAngle(cfg, coordinate);
    if (coordinate.type !== 'theta' && !coordinate.isTransposed) {
      // è·åæå½¢ path
      path = getSectorPath(center.x, center.y, coordinate.getRadius(), startAngle, endAngle);
    } else {
      const pow = (v) => Math.pow(v, 2);
      const r1 = Math.sqrt(pow(center.x - points[0].x) + pow(center.y - points[0].y));
      const r2 = Math.sqrt(pow(center.x - points[2].x) + pow(center.y - points[2].y));
      // è·åæå½¢ pathï¼å¶å®æ¯ä¸ä¸ªåç¯ï¼ä» coordinate çèµ·å§è§åº¦å°ç»æè§åº¦ï¼
      path = getSectorPath(center.x, center.y, r1, coordinate.startAngle, coordinate.endAngle, r2);
    }
  }
  return path;
}

/**
 * @ignore
 * æ ¹æ®ç©å½¢å³é®ç¹ç»å¶ path
 * @param points å³é®ç¹æ°ç»
 * @param lineCap 'round'åè§æ ·å¼
 * @param coor åæ 
 * @returns è¿åç©å½¢ç path
 */
export function getIntervalRectPath(points: Point[], lineCap: CanvasLineCap, coor: Coordinate): PathCommand[] {
  const width = coor.getWidth();
  const height = coor.getHeight();
  const isRect = coor.type === 'rect';
  let path = [];
  const r = (points[2].x - points[1].x) / 2;
  const ry = coor.isTransposed ? (r * height) / width : (r * width) / height;
  if (lineCap === 'round') {
    if (isRect) {
      path.push(['M', points[0].x, points[0].y + ry]);
      path.push(['L', points[1].x, points[1].y - ry]);
      path.push(['A', r, r, 0, 0, 1, points[2].x, points[2].y - ry]);
      path.push(['L', points[3].x, points[3].y + ry]);
      path.push(['A', r, r, 0, 0, 1, points[0].x, points[0].y + ry]);
    } else {
      path.push(['M', points[0].x, points[0].y]);
      path.push(['L', points[1].x, points[1].y]);
      path.push(['A', r, r, 0, 0, 1, points[2].x, points[2].y]);
      path.push(['L', points[3].x, points[3].y]);
      path.push(['A', r, r, 0, 0, 1, points[0].x, points[0].y]);
    }
    path.push(['z']);
  } else {
    path = getRectPath(points);
  }
  return path;
}

/**
 * @ignore
 * æ ¹æ® funnel å³é®ç¹ç»å¶æ¼æå¾ç path
 * @param points å¾å½¢å³é®ç¹ä¿¡æ¯
 * @param nextPoints ä¸ä¸ä¸ªæ°æ®çå¾å½¢å³é®ç¹ä¿¡æ¯
 * @param isPyramid æ¯å¦ä¸ºå°åºæ¼æå¾
 * @returns è¿åæ¼æå¾çå¾å½¢ path
 */
export function getFunnelPath(points: Point[], nextPoints: Point[], isPyramid: boolean) {
  const path = [];
  if (!isNil(nextPoints)) {
    path.push(
      ['M', points[0].x, points[0].y],
      ['L', points[1].x, points[1].y],
      ['L', nextPoints[1].x, nextPoints[1].y],
      ['L', nextPoints[0].x, nextPoints[0].y],
      ['Z']
    );
  } else if (isPyramid) {
    // éå­å¡æåºé¨
    path.push(
      ['M', points[0].x, points[0].y],
      ['L', points[1].x, points[1].y],
      ['L', (points[2].x + points[3].x) / 2, (points[2].y + points[3].y) / 2],
      ['Z']
    );
  } else {
    // æ¼æå¾æåºé¨
    path.push(
      ['M', points[0].x, points[0].y],
      ['L', points[1].x, points[1].y],
      ['L', points[2].x, points[2].y],
      ['L', points[3].x, points[3].y],
      ['Z']
    );
  }

  return path;
}

/**
 * äº¤æ¢ä¸¤ä¸ªå¯¹è±¡
 */
function swap<T>(p0: T, p1: T) {
  return [p1, p0];
}

/**
 * è·å åè§ ç©å½¢
 * - ç®ååªéç¨äºç¬å¡å°åæ ç³»ä¸
 */
export function getRectWithCornerRadius(points: Point[], coordinate: Coordinate, radius?: number | number[]) {
  // è·å åä¸ªå³é®ç¹
  let [p0, p1, p2, p3] = [...points];
  let [r1, r2, r3, r4] = typeof radius === 'number' ? Array(4).fill(radius) : radius;

  if (coordinate.isTransposed) {
    [p1, p3] = swap(p1, p3);
    [r1, r2, r3, r4] = [r4, r1, r2, r3]
  }

  /**
   * å­å¨éå
   */
  if (coordinate.isReflect('y')) {
    [p0, p1] = swap(p0, p1);
    [p2, p3] = swap(p2, p3);
  }
  if (coordinate.isReflect('x')) {
    [p0, p3] = swap(p0, p3);
    [p1, p2] = swap(p1, p2);
  }

  const path = [];


  /**
   *  p1 â p2
   *  â    â
   *  p0 â p3
   *
   *  è´æ°çæåµï¼å³é®ç¹ä¼åæä¸é¢çå½¢å¼
   *
   *  p0 â p3               p2 â p1
   *  â    â                â     â
   *  p1 â p2  --> (è½¬ç½®ä¸)  p3 â p0
   */
  const abs = v => Math.abs(v);
  [r1, r2, r3, r4] = parseRadius([r1, r2, r3, r4], Math.min(abs(p3.x - p0.x), abs(p1.y - p0.y))).map(d => abs(d));

  if (p0.y < p1.y /** è´æ°æåµ */) {
    path.push(['M', p3.x, p3.y + r3]);
    r3 !== 0 && path.push(['A', r3, r3, 0, 0, 0, p3.x - r3, p3.y]);
    path.push(['L', p0.x + r4, p0.y]);
    r4 !== 0 && path.push(['A', r4, r4, 0, 0, 0, p0.x, p0.y + r4]);
    path.push(['L', p1.x, p1.y - r1]);
    r1 !== 0 && path.push(['A', r1, r1, 0, 0, 0/** éæ¶é */, p1.x + r1, p1.y]);
    path.push(['L', p2.x - r2, p2.y]);
    r2 !== 0 && path.push(['A', r2, r2, 0, 0, 0, p2.x, p2.y - r2]);
    path.push(['L', p3.x, p3.y + r3]);
    path.push(['z']);
  } else if (p3.x < p0.x) {
    path.push(['M', p2.x + r2, p2.y]);
    r2 !== 0 && path.push(['A', r2, r2, 0, 0, 0, p2.x, p2.y + r2]);
    path.push(['L', p3.x, p3.y - r3]);
    r3 !== 0 && path.push(['A', r3, r3, 0, 0, 0, p3.x + r3, p3.y]);
    path.push(['L', p0.x - r4, p0.y]);
    r4 !== 0 && path.push(['A', r4, r4, 0, 0, 0, p0.x, p0.y - r4]);
    path.push(['L', p1.x, p1.y + r1]);
    r1 !== 0 && path.push(['A', r1, r1, 0, 0, 0, p1.x - r1, p1.y]);
    path.push(['L', p2.x + r2, p2.y]);
    path.push(['z']);
  } else {
    path.push(['M', p1.x, p1.y + r1]);
    r1 !== 0 && path.push(['A', r1, r1, 0, 0, 1, p1.x + r1, p1.y]);
    path.push(['L', p2.x - r2, p2.y]);
    r2 !== 0 && path.push(['A', r2, r2, 0, 0, 1, p2.x, p2.y + r2]);
    path.push(['L', p3.x, p3.y - r3]);
    r3 !== 0 && path.push(['A', r3, r3, 0, 0, 1, p3.x - r3, p3.y]);
    path.push(['L', p0.x + r4, p0.y]);
    r4 !== 0 && path.push(['A', r4, r4, 0, 0, 1, p0.x, p0.y - r4]);
    path.push(['L', p1.x, p1.y + r1]);
    path.push(['z']);
  }

  return path;
}
