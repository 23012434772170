import { registerAction, registerInteraction } from '@antv/g2';
import { BrushCfg } from '../types';
import { ButtonAction } from './actions/reset-button';

registerAction('brush-reset-button', ButtonAction, {
  name: 'brush-reset-button',
});

registerInteraction('filter-action', {});

/**
 * G2 å·²ç»åç½®äº brushãbrush-xãbrush-y ç­äº¤äºï¼å¶å®ï¼
 *
 * 1. element-range-highlight æ¯å¦å¯ç¨éå½åä¸º brush-highlightï¼(mask å¯ä»¥ç§»å¨)
 * 2. brush-visible ä¸ brush çåºå«æ¯ï¼
 */

function isPointInView(context) {
  return context.isInPlot();
}

/**
 * è·å äº¤äº start é¶æ®µçç¸å³éç½®
 */
export function getInteractionCfg(interactionType: string, brushType?: string, mask?: BrushCfg['mask']) {
  const maskType = brushType || 'rect';

  switch (interactionType) {
    case 'brush':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
        ],
        start: [
          {
            trigger: 'mousedown',
            isEnable: isPointInView,
            action: ['brush:start', `${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬äºä¸ªactionçåæ°
            arg: [null, { maskStyle: mask?.style }],
          },
        ],
        processing: [
          {
            trigger: 'mousemove',
            isEnable: isPointInView,
            action: [`${maskType}-mask:resize`],
          },
        ],
        end: [
          {
            trigger: 'mouseup',
            isEnable: isPointInView,
            action: [
              'brush:filter',
              'brush:end',
              `${maskType}-mask:end`,
              `${maskType}-mask:hide`,
              'brush-reset-button:show',
            ],
          },
        ],
        rollback: [
          {
            trigger: 'brush-reset-button:click',
            action: ['brush:reset', 'brush-reset-button:hide', 'cursor:crosshair'],
          },
        ],
      };
    case 'brush-highlight':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'mask:mouseenter', action: 'cursor:move' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
          { trigger: 'mask:mouseleave', action: 'cursor:crosshair' },
        ],
        start: [
          {
            trigger: 'plot:mousedown',
            isEnable(context) {
              // ä¸è¦ç¹å»å¨ mask ä¸éæ°å¼å§
              return !context.isInShape('mask');
            },
            action: [`${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬ 1 ä¸ªactionçåæ°
            arg: [{ maskStyle: mask?.style }],
          },
          {
            trigger: 'mask:dragstart',
            action: [`${maskType}-mask:moveStart`],
          },
        ],
        processing: [
          {
            trigger: 'plot:mousemove',
            action: [`${maskType}-mask:resize`],
          },
          {
            trigger: 'mask:drag',
            action: [`${maskType}-mask:move`],
          },
          {
            trigger: 'mask:change',
            action: ['element-range-highlight:highlight'],
          },
        ],
        end: [
          { trigger: 'plot:mouseup', action: [`${maskType}-mask:end`] },
          { trigger: 'mask:dragend', action: [`${maskType}-mask:moveEnd`] },
          {
            trigger: 'document:mouseup',
            isEnable(context) {
              return !context.isInPlot();
            },
            action: ['element-range-highlight:clear', `${maskType}-mask:end`, `${maskType}-mask:hide`],
          },
        ],
        rollback: [{ trigger: 'dblclick', action: ['element-range-highlight:clear', `${maskType}-mask:hide`] }],
      };
    case 'brush-x':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
        ],
        start: [
          {
            trigger: 'mousedown',
            isEnable: isPointInView,
            action: ['brush-x:start', `${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬äºä¸ªactionçåæ°
            arg: [null, { maskStyle: mask?.style }],
          },
        ],
        processing: [
          {
            trigger: 'mousemove',
            isEnable: isPointInView,
            action: [`${maskType}-mask:resize`],
          },
        ],
        end: [
          {
            trigger: 'mouseup',
            isEnable: isPointInView,
            action: ['brush-x:filter', 'brush-x:end', `${maskType}-mask:end`, `${maskType}-mask:hide`],
          },
        ],
        rollback: [{ trigger: 'dblclick', action: ['brush-x:reset'] }],
      };
    case 'brush-x-highlight':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'mask:mouseenter', action: 'cursor:move' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
          { trigger: 'mask:mouseleave', action: 'cursor:crosshair' },
        ],
        start: [
          {
            trigger: 'plot:mousedown',
            isEnable(context) {
              // ä¸è¦ç¹å»å¨ mask ä¸éæ°å¼å§
              return !context.isInShape('mask');
            },
            action: [`${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬ 1 ä¸ªactionçåæ°
            arg: [{ maskStyle: mask?.style }],
          },
          {
            trigger: 'mask:dragstart',
            action: [`${maskType}-mask:moveStart`],
          },
        ],
        processing: [
          {
            trigger: 'plot:mousemove',
            action: [`${maskType}-mask:resize`],
          },
          {
            trigger: 'mask:drag',
            action: [`${maskType}-mask:move`],
          },
          {
            trigger: 'mask:change',
            action: ['element-range-highlight:highlight'],
          },
        ],
        end: [
          { trigger: 'plot:mouseup', action: [`${maskType}-mask:end`] },
          { trigger: 'mask:dragend', action: [`${maskType}-mask:moveEnd`] },
          {
            trigger: 'document:mouseup',
            isEnable(context) {
              return !context.isInPlot();
            },
            action: ['element-range-highlight:clear', `${maskType}-mask:end`, `${maskType}-mask:hide`],
          },
        ],
        rollback: [{ trigger: 'dblclick', action: ['element-range-highlight:clear', `${maskType}-mask:hide`] }],
      };
    case 'brush-y':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
        ],
        start: [
          {
            trigger: 'mousedown',
            isEnable: isPointInView,
            action: ['brush-y:start', `${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬äºä¸ªactionçåæ°
            arg: [null, { maskStyle: mask?.style }],
          },
        ],
        processing: [
          {
            trigger: 'mousemove',
            isEnable: isPointInView,
            action: [`${maskType}-mask:resize`],
          },
        ],
        end: [
          {
            trigger: 'mouseup',
            isEnable: isPointInView,
            action: ['brush-y:filter', 'brush-y:end', `${maskType}-mask:end`, `${maskType}-mask:hide`],
          },
        ],
        rollback: [{ trigger: 'dblclick', action: ['brush-y:reset'] }],
      };
    case 'brush-y-highlight':
      return {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
          { trigger: 'mask:mouseenter', action: 'cursor:move' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' },
          { trigger: 'mask:mouseleave', action: 'cursor:crosshair' },
        ],
        start: [
          {
            trigger: 'plot:mousedown',
            isEnable(context) {
              // ä¸è¦ç¹å»å¨ mask ä¸éæ°å¼å§
              return !context.isInShape('mask');
            },
            action: [`${maskType}-mask:start`, `${maskType}-mask:show`],
            // å¯¹åºç¬¬ 1 ä¸ªactionçåæ°
            arg: [{ maskStyle: mask?.style }],
          },
          {
            trigger: 'mask:dragstart',
            action: [`${maskType}-mask:moveStart`],
          },
        ],
        processing: [
          {
            trigger: 'plot:mousemove',
            action: [`${maskType}-mask:resize`],
          },
          {
            trigger: 'mask:drag',
            action: [`${maskType}-mask:move`],
          },
          {
            trigger: 'mask:change',
            action: ['element-range-highlight:highlight'],
          },
        ],
        end: [
          { trigger: 'plot:mouseup', action: [`${maskType}-mask:end`] },
          { trigger: 'mask:dragend', action: [`${maskType}-mask:moveEnd`] },
          {
            trigger: 'document:mouseup',
            isEnable(context) {
              return !context.isInPlot();
            },
            action: ['element-range-highlight:clear', `${maskType}-mask:end`, `${maskType}-mask:hide`],
          },
        ],
        rollback: [{ trigger: 'dblclick', action: ['element-range-highlight:clear', `${maskType}-mask:hide`] }],
      };

    default:
      return {};
  }
}

// ç´æ¥æ·è´è¿æ¥ç
registerInteraction('brush', getInteractionCfg('brush'));
// å¤å element-range-highlight interaction
registerInteraction('brush-highlight', getInteractionCfg('brush-highlight'));
// å¤å
registerInteraction('brush-x', getInteractionCfg('brush-x', 'x-rect'));
// å¤å
registerInteraction('brush-y', getInteractionCfg('brush-y', 'y-rect'));
// æ°å¢, x æ¡éé«äº®
registerInteraction('brush-x-highlight', getInteractionCfg('brush-x-highlight', 'x-rect'));
// æ°å¢, y æ¡éé«äº®
registerInteraction('brush-y-highlight', getInteractionCfg('brush-y-highlight', 'y-rect'));
