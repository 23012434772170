import { IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { AnimateExtraCfg } from '../interface';

/**
 * @ignore
 * å¥åºå¨ç»
 * path çå¥åºå¨ç»
 * @param element æ§è¡å¨ç»çåç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function pathIn(element: IShape, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  // @ts-ignore
  const length = element.getTotalLength();
  // è®¾ç½®èçº¿æ ·å¼
  element.attr('lineDash', [length]);
  element.animate((ratio: number) => {
    return {
      // å¯¹èçº¿åç§»éåå¨ç»
      lineDashOffset: (1 - ratio) * length,
    };
  }, animateCfg);
}
