import { isEqual, get } from '@antv/util';
import { View } from '../../../../chart';
import { Point } from '../../../../interface';
import Action from '../../base';
import Tooltip from '../../../../chart/controller/tooltip';

/**
 * Tooltip å±ç¤ºéèç Action
 * @ignore
 */
class TooltipAction extends Action {
  private timeStamp: number = 0;
  private location: Point;

  /**
   * æ¾ç¤º Tooltip
   * @returns
   */
  public show() {
    const context = this.context;
    const ev = context.event;
    const view = context.view;
    const isTooltipLocked = view.isTooltipLocked();
    if (isTooltipLocked) {
      // éå®æ¶ä¸ç§»å¨ tooltip
      return;
    }
    const lastTimeStamp = this.timeStamp;
    const timeStamp = +new Date();

    // å¨ showDelay æ¯«ç§ï¼é»è®¤ 16msï¼åå° tooltip ä¸å¯ä»¥å®ç° enterableï¼è°åå·¥ç¨å¸ï¼
    const showDelay = get(context.view.getOptions(), 'tooltip.showDelay', 16);
    if (timeStamp - lastTimeStamp > showDelay) {
      const preLoc = this.location;
      const curLoc = { x: ev.x, y: ev.y };
      if (!preLoc || !isEqual(preLoc, curLoc)) {
        this.showTooltip(view, curLoc);
      }
      this.timeStamp = timeStamp;
      this.location = curLoc;
    }
  }

  /**
   * éè Tooltipã
   * @returns
   */
  public hide() {
    const view = this.context.view;

    const tooltip = view.getController('tooltip');
    const { clientX, clientY } = this.context.event;

    // å¦æå·²ç» enterable + å·²ç»å¨ tooltip ä¸ï¼é£ä¹ä¸éè
    if (tooltip.isCursorEntered({ x: clientX, y: clientY })) {
      return;
    }

    // éå® tooltip æ¶ä¸éè
    if (view.isTooltipLocked()) {
      return;
    }
    this.hideTooltip(view);
    this.location = null;
  }

  protected showTooltip(view: View, point: Point) {
    // ç¸åä½ç½®ä¸éå¤å±ç¤º
    view.showTooltip(point);
  }

  protected hideTooltip(view) {
    view.hideTooltip();
  }
}

export default TooltipAction;
