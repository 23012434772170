import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { FacetOptions } from './types';

export type { FacetOptions };

export class Facet extends Plot<FacetOptions> {
  /**
   * è·å åé¢å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<FacetOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'area';

  /**
   * è·å åé¢å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Facet.getDefaultOptions();
  }

  /**
   * è·å åé¢å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<FacetOptions> {
    return adaptor;
  }
}
