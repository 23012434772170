import { get } from '@antv/util';
import { Types } from '@antv/g2';
import { point } from '../../adaptor/geometries/point';
import { Params } from '../../core/adaptor';
import {
  interaction as baseInteraction,
  animation,
  theme,
  legend,
  annotation,
  scale,
  pattern,
} from '../../adaptor/common';
import { flow, deepAssign } from '../../utils';
import { getAdjustAppendPadding, resolveAllPadding } from '../../utils/padding';
import { transformData, resolvePaddingForCircle } from './utils';
import { CirclePackingOptions } from './types';
import { RAW_FIELDS } from './constant';

/**
 * è·åé»è®¤ option
 * @param params
 */
function defaultOptions(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { chart } = params;
  const diameter = Math.min(chart.viewBBox.width, chart.viewBBox.height);

  return deepAssign(
    {
      options: {
        size: ({ r }) => r * diameter, // å½autofitï¼falseæ¶ï¼é»è®¤ç»åºå®åå¾
      },
    },
    params
  );
}

/**
 * padding éç½®
 * @param params
 */
function padding(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { options, chart } = params;
  // éè¿æ¹å paddingï¼ä¿®æ¹ coordinate çç»å¶åºå
  const containerSize = chart.viewBBox;
  const { padding, appendPadding, drilldown } = options;

  let tempAppendPadding = appendPadding;
  if (drilldown?.enabled) {
    const appendPaddingByDrilldown = getAdjustAppendPadding(
      chart.appendPadding,
      get(drilldown, ['breadCrumb', 'position'])
    );
    tempAppendPadding = resolveAllPadding([appendPaddingByDrilldown, appendPadding]);
  }

  const { finalPadding } = resolvePaddingForCircle(padding, tempAppendPadding, containerSize);
  chart.padding = finalPadding;
  chart.appendPadding = 0;

  return params;
}

/**
 * å­æ®µ
 * @param params
 */
function geometry(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { chart, options } = params;
  const { padding, appendPadding } = chart;
  const { color, colorField, pointStyle, hierarchyConfig, sizeField, rawFields = [], drilldown } = options;

  const data = transformData({
    data: options.data,
    hierarchyConfig,
    enableDrillDown: drilldown?.enabled,
    rawFields,
  });
  chart.data(data);

  const containerSize = chart.viewBBox;
  const { finalSize } = resolvePaddingForCircle(padding, appendPadding, containerSize);
  // æsizeFieldçæ¶åï¼ä¾å¦ value ï¼å¯ä»¥éæ©æ å° size å½æ°ï¼èªå·±è®¡ç®åºæ å°çåå¾
  let circleSize = ({ r }) => r * finalSize; // é»è®¤éç½®

  if (sizeField) {
    circleSize = (d) => d[sizeField] * finalSize; // ç®ååªæ r ééæ å°ææä¼æ­£å¸¸
  }

  // geometry
  point(
    deepAssign({}, params, {
      options: {
        xField: 'x',
        yField: 'y',
        seriesField: colorField,
        sizeField,
        rawFields: [...RAW_FIELDS, ...rawFields],
        point: {
          color,
          style: pointStyle,
          shape: 'circle',
          size: circleSize,
        },
      },
    })
  );

  return params;
}

/**
 * meta éç½®
 * @param params
 */
export function meta(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  return flow(
    scale(
      {},
      {
        // å¿é¡»å¼ºå¶ä¸º nice
        x: { min: 0, max: 1, minLimit: 0, maxLimit: 1, nice: true },
        y: { min: 0, max: 1, minLimit: 0, maxLimit: 1, nice: true },
      }
    )
  )(params);
}

/**
 * tooltip éç½®
 * @param params
 */
function tooltip(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { chart, options } = params;
  const { tooltip } = options;

  if (tooltip === false) {
    chart.tooltip(false);
  } else {
    let tooltipOptions = tooltip;
    // è®¾ç½®äº fieldsï¼å°±ä¸è¿è¡ customItems äº; è®¾ç½® formatter æ¶ï¼éè¦æ­é fields
    if (!get(tooltip, 'fields')) {
      tooltipOptions = deepAssign(
        {},
        {
          customItems: (items: Types.TooltipItem[]) =>
            items.map((item) => {
              const scales = get(chart.getOptions(), 'scales');
              const nameFormatter = get(scales, ['name', 'formatter'], (v) => v);
              const valueFormatter = get(scales, ['value', 'formatter'], (v) => v);
              return {
                ...item,
                name: nameFormatter(item.data.name),
                value: valueFormatter(item.data.value),
              };
            }),
        },
        tooltipOptions
      );
    }
    chart.tooltip(tooltipOptions);
  }

  return params;
}

/**
 * åæ è½´, é»è®¤å³é­
 * @param params
 */
function axis(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { chart } = params;
  chart.axis(false);
  return params;
}

function adaptorInteraction(options: CirclePackingOptions): CirclePackingOptions {
  const { drilldown, interactions = [] } = options;

  if (drilldown?.enabled) {
    return deepAssign({}, options, {
      interactions: [
        ...interactions,
        {
          type: 'drill-down',
          cfg: { drillDownConfig: drilldown, transformData, enableDrillDown: true },
        },
      ],
    });
  }
  return options;
}

/**
 * äº¤äºéç½®
 * @param params
 * @returns
 */
function interaction(params: Params<CirclePackingOptions>): Params<CirclePackingOptions> {
  const { chart, options } = params;

  baseInteraction({
    chart,
    options: adaptorInteraction(options),
  });

  return params;
}

/**
 * ç©å½¢æ å¾
 * @param chart
 * @param options
 */
export function adaptor(params: Params<CirclePackingOptions>) {
  return flow(
    pattern('pointStyle'),
    defaultOptions,
    padding,
    theme,
    meta,
    geometry,
    axis,
    legend,
    tooltip,
    interaction,
    animation,
    annotation()
  )(params);
}
