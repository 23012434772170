import { Params } from '../../core/adaptor';
import { getTooltipMapping } from '../../utils/tooltip';
import { deepAssign } from '../../utils';
import { geometry, MappingOptions, GeometryOptions } from './base';

export interface PointGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µ */
  readonly yField?: string;
  /** åç»å­æ®µ */
  readonly seriesField?: string;
  /** size æ å°å­æ®µ */
  readonly sizeField?: string;
  /** shape çæ å°å­æ®µ */
  readonly shapeField?: string;
  /** point å¾å½¢æ å°è§å */
  readonly point?: MappingOptions;
}

/**
 * point è¾å©ç¹çéç½®å¤ç
 * @param params
 */
export function point<O extends PointGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const { point, xField, yField, seriesField, sizeField, shapeField, tooltip } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField, sizeField, shapeField]);

  return point
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'point',
            colorField: seriesField,
            shapeField: shapeField,
            tooltipFields: fields,
            mapping: {
              tooltip: formatter,
              ...point,
            },
          },
        })
      )
    : params;
}
