import { each } from '@antv/util';
import Element from '../../../geometry/element/';
import {
  getElements,
  getIntersectElements,
  getMaskedElements,
  getSiblingMaskElements,
  getSilbings,
  isInRecords,
  isMask,
} from '../util';
import StateBase from './state-base';

/**
 * @ignore
 * åºåè®¾ç½®ç¶æçåºç¡ Action
 */
class ElementRangeState extends StateBase {
  private startPoint = null;
  private endPoint = null;
  private isStarted: boolean = false;
  /**
   * æ¯å¦ä½ç¨äºå½å view ç siblingsï¼é»è®¤æ¯ false ä»ä½ç¨äºèªå·±
   */
  protected effectSiblings = false;
  /**
   * æ¯å¦å element çæ°æ®å½±åï¼è¿æ¯ååå´ççå½±å
   */
  protected effectByRecord = false;
  // è·åå½åçä½ç½®
  private getCurrentPoint() {
    const event = this.context.event;
    return {
      x: event.x,
      y: event.y,
    };
  }

  /**
   * å¼å§ï¼è®°å½å¼å§éä¸­çä½ç½®
   */
  public start() {
    this.clear(); // å¼å§çæ¶åæ¸çä¹åçç¶æ
    this.startPoint = this.getCurrentPoint();
    this.isStarted = true;
  }

  protected getIntersectElements() {
    let elements = null;
    if (isMask(this.context)) {
      elements = getMaskedElements(this.context, 10);
    } else {
      const startPoint = this.startPoint;
      const endPoint = this.isStarted ? this.getCurrentPoint() : this.endPoint;
      // å¦ææ²¡æå¼å§ï¼åä¸åè®¸èå´è®¾ç½®ç¶æï¼ä¿æ¤æ§è´¨
      if (!startPoint || !endPoint) {
        return;
      }
      // è®¡ç®æ¡éåºå
      const box = {
        minX: Math.min(startPoint.x, endPoint.x),
        minY: Math.min(startPoint.y, endPoint.y),
        maxX: Math.max(startPoint.x, endPoint.x),
        maxY: Math.max(startPoint.y, endPoint.y),
      };
      // this.clear(); // ä¸å¨é¨æ¸çï¼ä¼å¯¼è´éªç
      const view = this.context.view;
      elements = getIntersectElements(view, box);
    }
    return elements;
  }
  /**
   * éä¸­
   */
  public setStateEnable(enable: boolean) {
    if (this.effectSiblings && !this.effectByRecord) {
      this.setSiblingsState(enable);
    } else {
      const allElements = getElements(this.context.view);
      const elements = this.getIntersectElements();
      if (elements && elements.length) {
        if (this.effectByRecord) {
          this.setSiblingsStateByRecord(elements, enable);
        } else {
          this.setElementsState(elements, enable, allElements);
        }
      } else {
        this.clear();
      }
    }
  }
  // æ ¹æ®éä¸­ç element çæ°æ®è¿è¡è®¾ç½®ç¶æ
  private setSiblingsStateByRecord(elements, enable) {
    const view = this.context.view;
    const siblings = getSilbings(view);
    const records = elements.map((el) => {
      return el.getModel().data;
    });
    const xFiled = view.getXScale().field;
    const yField = view.getYScales()[0].field;
    each(siblings, (sibling) => {
      const allElements = getElements(sibling);
      const effectElements = allElements.filter((el) => {
        const record = el.getModel().data;
        return isInRecords(records, record, xFiled, yField);
      });
      this.setElementsState(effectElements, enable, allElements);
    });
  }

  // è®¾ç½®åå¼ view çç¶æ
  private setSiblingsState(enable: boolean) {
    const view = this.context.view;
    const siblings = getSilbings(view);
    if (isMask(this.context)) {
      // å mask å½±å
      each(siblings, (sibling) => {
        const allElements = getElements(sibling);
        const effectElements = getSiblingMaskElements(this.context, sibling, 10);
        if (effectElements && effectElements.length) {
          this.setElementsState(effectElements, enable, allElements);
        } else {
          this.clearViewState(sibling);
        }
      });
    }
  }

  protected setElementsState(elements: Element[], enable, allElements: Element[]) {
    each(allElements, (el) => {
      if (!elements.includes(el)) {
        this.setElementState(el, false);
      } else {
        this.setElementState(el, enable);
      }
    });
  }

  /**
   * ç»æ
   */
  public end() {
    this.isStarted = false;
    this.endPoint = this.getCurrentPoint();
  }

  // å¤å clear
  public clear() {
    const view = this.context.view;
    // å¤æ­æ¯å¦å½±å siblings
    if (this.effectSiblings) {
      const siblings = getSilbings(view);
      each(siblings, (sibling) => {
        this.clearViewState(sibling);
      });
    } else {
      this.clearViewState(view);
    }
  }
}

export default ElementRangeState;
