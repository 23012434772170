import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { StockOptions } from './types';
import { adaptor } from './adaptor';
import { getStockData } from './utils';
import { DEFAULT_OPTIONS } from './constant';

export type { StockOptions };

export class Stock extends Plot<StockOptions> {
  /**
   * è·å æ£ç¹å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<StockOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'stock';

  /**
   * é»è®¤éç½®
   *  g2/g2ploté» è®¤ é ç½® -->  å¾ è¡¨ é» è®¤ é ç½®  --> å¼ å è èª å® ä¹ é ç½®  --> æ ç» ç» å¾ é ç½®
   */
  protected getDefaultOptions(): Partial<StockOptions> {
    return Stock.getDefaultOptions();
  }

  /**
   * è·å è¡çå¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<StockOptions> {
    return adaptor;
  }

  /**
   * @override
   * @param data
   */
  public changeData(data: StockOptions['data']) {
    this.updateOption({ data });
    const { yField } = this.options;
    this.chart.changeData(getStockData(data, yField));
  }
}
