import { View, Element } from '@antv/g2';
import { each, isArray } from '@antv/util';
import { getAllElements } from '../../../utils';

/**
 * è·åå¾è¡¨åç´ å¯¹åºå­æ®µçå¼
 * @param element å¾è¡¨åç´ 
 * @param field å­æ®µå
 * @ignore
 */
export function getElementValue(element: Element, field) {
  const model = element.getModel();
  const record = model.data;
  let value;
  if (isArray(record)) {
    value = record[0][field];
  } else {
    value = record[field];
  }
  return value;
}

/**
 * @ignore
 * æ¸ç highlight ææ
 * @param view View æè Chart
 */
export function clearHighlight(view: View) {
  const elements = getAllElements(view);
  each(elements, (el) => {
    if (el.hasState('active')) {
      el.setState('active', false);
    }
    if (el.hasState('selected')) {
      el.setState('selected', false);
    }
    if (el.hasState('inactive')) {
      el.setState('inactive', false);
    }
  });
}
