import { groupToMap } from '@antv/util';
import { Data } from '../../interface';

/** @ignore */
export function group(data: Data, fields: string[], appendConditions: Record<string, any[]> = {}) {
  if (!fields) {
    return [data];
  }
  const groups = groupToMap(data, fields);
  const array = [];
  if (fields.length === 1 && appendConditions[fields[0]]) {
    const values = appendConditions[fields[0]];
    for (const value of values) {
      const arr = groups[`_${value}`];
      if (arr) {
        // å¯è½å­å¨ç¨æ·è®¾ç½® values ï¼ä½æ¯æ°æ®ä¸­æ²¡æå¯¹åºçå­æ®µï¼åè¿æ¶å arr å°±ä¸º null
        array.push(arr);
      }
    }
  } else {
    for (const k in groups) {
      if (groups.hasOwnProperty(k)) {
        const eachGroup = groups[k];
        array.push(eachGroup);
      }
    }
  }

  return array;
}
