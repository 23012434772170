import { VIEW_LIFE_CIRCLE, Event } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { deepAssign, findViewById } from '../../utils';
import { BidirectionalBarOptions } from './types';
import { adaptor } from './adaptor';
import { syncViewPadding, transformData, isHorizontal } from './utils';
import { SERIES_FIELD_KEY, FIRST_AXES_VIEW, SECOND_AXES_VIEW } from './constant';

export type { BidirectionalBarOptions };

export class BidirectionalBar extends Plot<BidirectionalBarOptions> {
  /**
   * è·å é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<BidirectionalBarOptions> {
    return deepAssign({}, super.getDefaultOptions(), {
      syncViewPadding,
    });
  }

  /** å¯¹ç§°æ¡å½¢å¾åç±»å­æ®µ */
  static SERIES_FIELD_KEY = SERIES_FIELD_KEY;

  /** å¾è¡¨ç±»å */
  public type: string = 'bidirectional-bar';

  /**
   * @override
   */
  public changeData(data = []) {
    this.chart.emit(
      VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, null)
    );

    // æ´æ°options
    this.updateOption({ data });
    const { xField, yField, layout } = this.options;
    // å¤çæ°æ®
    const groupData: any[] = transformData(xField, yField, SERIES_FIELD_KEY, data, isHorizontal(layout));
    const [firstViewData, secondViewData] = groupData;
    const firstView = findViewById(this.chart, FIRST_AXES_VIEW);
    const secondView = findViewById(this.chart, SECOND_AXES_VIEW);
    // æ´æ°å¯¹åºviewçdata
    firstView.data(firstViewData);
    secondView.data(secondViewData);
    // éæ°æ¸²æ
    this.chart.render(true);

    this.chart.emit(
      VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, null)
    );
  }

  protected getDefaultOptions() {
    return BidirectionalBar.getDefaultOptions();
  }

  /**
   * è·åå¯¹ç§°æ¡å½¢å¾çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<BidirectionalBarOptions> {
    return adaptor;
  }
}
