import { Params } from '../../core/adaptor';
import { getTooltipMapping } from '../../utils/tooltip';
import { deepAssign } from '../../utils';
import { GeometryOptions, geometry, MappingOptions } from './base';

export interface AreaGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µ */
  readonly yField?: string;
  /** åç»å­æ®µ */
  readonly seriesField?: string;
  /** æ¯å¦å¹³æ» */
  readonly smooth?: boolean;
  /** area å¾å½¢çæ ·å¼è®¾ç½® */
  readonly area?: MappingOptions;
}

/**
 * area geometry çéç½®å¤ç
 * @param params
 */
export function area<O extends AreaGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const { area, xField, yField, seriesField, smooth, tooltip } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField]);

  // å¦æå­å¨æå¤ç
  return area
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'area',
            colorField: seriesField,
            tooltipFields: fields,
            mapping: {
              shape: smooth ? 'smooth' : 'area',
              tooltip: formatter,
              ...area,
            },
          },
        })
      )
    : params;
}
