import { deepMix, get, isFunction } from '@antv/util';
import { FIELD_ORIGIN } from '../constant';
import { Coordinate, IGroup, IShape } from '../dependents';
import { AnimateCfg, Data, Datum, GAnimateCfg, Point } from '../interface';
import { AnimateExtraCfg } from './interface';

import { getAnimation } from './animation';

// é»è®¤çå¨ç»åæ°éç½®
export const DEFAULT_ANIMATE_CFG = {
  appear: {
    duration: 450,
    easing: 'easeQuadOut',
  }, // åå§å¥åºå¨ç»éç½®
  update: {
    duration: 400,
    easing: 'easeQuadInOut',
  }, // æ´æ°æ¶åçåæ´çå¨ç»éç½®
  enter: {
    duration: 400,
    easing: 'easeQuadInOut',
  }, // æ´æ°æ¶æ°å¢åç´ çå¥åºå¨ç»éç½®
  leave: {
    duration: 350,
    easing: 'easeQuadIn',
  }, // æ´æ°æ¶éæ¯å¨ç»éç½®
};

// åä¸ª Geometry é»è®¤çå¨ç»æ§è¡å½æ°
const GEOMETRY_ANIMATE_CFG = {
  interval: (coordinate: Coordinate) => {
    return {
      enter: {
        animation: coordinate.isRect ? (coordinate.isTransposed ? 'scale-in-x' : 'scale-in-y') : 'fade-in',
      },
      update: {
        animation: coordinate.isPolar && coordinate.isTransposed ? 'sector-path-update' : null,
      },
      leave: {
        animation: 'fade-out',
      },
    };
  },
  line: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  path: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  point: {
    appear: {
      animation: 'zoom-in',
    },
    enter: {
      animation: 'zoom-in',
    },
    leave: {
      animation: 'zoom-out',
    },
  },
  area: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  polygon: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  schema: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  edge: {
    enter: {
      animation: 'fade-in',
    },
    leave: {
      animation: 'fade-out',
    },
  },
  label: {
    appear: {
      animation: 'fade-in',
      delay: 450,
    },
    enter: {
      animation: 'fade-in',
    },
    update: {
      animation: 'position-update',
    },
    leave: {
      animation: 'fade-out',
    },
  },
};

// åä¸ª Geometry é»è®¤çç¾¤ç»åºåºå¨ç»
const GEOMETRY_GROUP_APPEAR_ANIMATION = {
  line: () => {
    return {
      animation: 'wave-in',
    };
  },
  area: () => {
    return {
      animation: 'wave-in',
    };
  },
  path: () => {
    return {
      animation: 'fade-in',
    };
  },
  interval(coordinate: Coordinate) {
    let animation;

    if (coordinate.isRect) {
      animation = coordinate.isTransposed ? 'grow-in-x' : 'grow-in-y';
    } else {
      animation = 'grow-in-xy';
      if (coordinate.isPolar && coordinate.isTransposed) {
        // pie chart
        animation = 'wave-in';
      }
    }
    return {
      animation,
    };
  },
  schema: (coordinate) => {
    let animation;
    if (coordinate.isRect) {
      animation = coordinate.isTransposed ? 'grow-in-x' : 'grow-in-y';
    } else {
      animation = 'grow-in-xy';
    }
    return {
      animation,
    };
  },
  polygon: () => {
    return {
      animation: 'fade-in',
      duration: 500,
    };
  },
  edge: () => {
    return {
      animation: 'fade-in',
    };
  },
};

// è§£æç¨æ·çå¨ç»éç½®
function parseAnimateConfig(animateCfg: AnimateCfg, data: Data | Datum): GAnimateCfg {
  return {
    delay: isFunction(animateCfg.delay) ? animateCfg.delay(data) : animateCfg.delay,
    easing: isFunction(animateCfg.easing) ? animateCfg.easing(data) : animateCfg.easing,
    duration: isFunction(animateCfg.duration) ? animateCfg.duration(data) : animateCfg.duration,
    callback: animateCfg.callback,
    repeat: animateCfg.repeat,
  };
}

/**
 * @ignore
 * è·å elementName å¯¹åºçå¨ç»éç½®ï¼å½å£°æäº `animateType`ï¼åè¿å `animateType` å¯¹åºçå¨ç»éç½®
 * @param elementName åç´ åç§°
 * @param coordinate åè¡¨å¼ç±»å
 * @param animateType å¯éï¼å¨ç»ç±»å
 */
export function getDefaultAnimateCfg(elementName: string, coordinate: Coordinate, animateType?: string) {
  let animateCfg = GEOMETRY_ANIMATE_CFG[elementName];

  if (animateCfg) {
    if (isFunction(animateCfg)) {
      animateCfg = animateCfg(coordinate);
    }
    animateCfg = deepMix({}, DEFAULT_ANIMATE_CFG, animateCfg);

    if (animateType) {
      return animateCfg[animateType];
    }
  }
  return animateCfg;
}

/**
 * @ignore
 * å·¥å·å½æ°
 * æ ¹æ®ç¨æ·ä¼ å¥çéç½®ä¸º shape æ§è¡å¨ç»
 * @param shape æ§è¡å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤çä¿¡æ¯
 */
export function doAnimate(shape: IGroup | IShape, animateCfg: AnimateCfg, cfg: AnimateExtraCfg) {
  const data = get(shape.get('origin'), 'data', FIELD_ORIGIN);
  const animation = animateCfg.animation; // è·åå¨ç»æ§è¡å½æ°
  const parsedAnimateCfg = parseAnimateConfig(animateCfg, data);
  if (animation) {
    // ç¨æ·å£°æäºå¨ç»æ§è¡å½æ°
    const animateFunction = getAnimation(animation);
    if (animateFunction) {
      animateFunction(shape, parsedAnimateCfg, cfg);
    }
  } else {
    // æ²¡æå£°æï¼åæ ¹æ® toAttrs åå·®å¼å¨ç»
    shape.animate(cfg.toAttrs, parsedAnimateCfg);
  }
}

/**
 * @ignore
 * æ§è¡ Geometry ç¾¤ç»å¥åºå¨ç»
 * @param container æ§è¡ç¾¤ç»å¨ç»çå¾å½¢åç´ 
 * @param animateCfg å¨ç»éç½®
 * @param geometryType geometry ç±»å
 * @param coordinate åæ ç³»å¯¹è±¡
 * @param minYPoint y è½´æå°å¼å¯¹åºçç»å¸åæ ç¹
 */
export function doGroupAppearAnimate(
  container: IGroup,
  animateCfg: AnimateCfg,
  geometryType: string,
  coordinate: Coordinate,
  minYPoint: Point
) {
  if (GEOMETRY_GROUP_APPEAR_ANIMATION[geometryType]) {
    const defaultCfg = GEOMETRY_GROUP_APPEAR_ANIMATION[geometryType](coordinate);
    const animation = getAnimation(get(defaultCfg, 'animation', ''));
    if (animation) {
      const cfg = {
        ...DEFAULT_ANIMATE_CFG.appear,
        ...defaultCfg,
        ...animateCfg,
      };
      container.stopAnimate(); // åç»æå½å container å¨ç»
      animation(container, cfg, {
        coordinate,
        minYPoint,
        toAttrs: null,
      });
    }
  }
}
