import StateAction from './state';

/**
 * @ignore
 * åè®¸å¤éç Action
 * @class
 */
class ElementMultipleSelected extends StateAction {
  protected stateName: string = 'selected';
  /**
   * éä¸­èç¹ï¼åè®¸å¤é
   */
  public selected() {
    this.setState();
  }
}

export default ElementMultipleSelected;
