import { each, findIndex, get, find, isObject, every, isEqual, isBoolean } from '@antv/util';
import { Scale, Types, Event } from '@antv/g2';
import {
  theme as commonTheme,
  animation as commonAnimation,
  scale,
  interaction as commonInteraction,
  annotation as commonAnnotation,
  limitInPlot as commonLimitInPlot,
} from '../../adaptor/common';
import { percent } from '../../utils/transform/percent';
import { Params } from '../../core/adaptor';
import { Datum } from '../../types';
import { flow, deepAssign } from '../../utils';
import { findViewById } from '../../utils/view';
import { isColumn, getYAxisWithDefault, getGeometryOption, transformObjectToArray } from './util/option';
import { getViewLegendItems } from './util/legend';
import { drawSingleGeometry } from './util/geometry';
import { doSliderFilter } from './util/render-sider';
import { DualAxesOptions, AxisType, DualAxesGeometry } from './types';
import { LEFT_AXES_VIEW, RIGHT_AXES_VIEW } from './constant';

/**
 * transformOptionsï¼åè½´å¾æ´ä½çååé»è¾å¦ä¸
 * 1. get index getOptions: å¯¹åºçæ¯é»è®¤çå¾è¡¨åæ°ï¼å¦ appendPaddingï¼syncView ç­
 * 2. get adpator transformOption: å¯¹åºçæ¯åè½´å¾çé»è®¤åæ°ï¼deepAssign ä¼åçº§ä»ä½å°é«å¦ä¸
 *    2.1 defaultoptionï¼å¦ tooltipï¼legend
 *    2.2 ç¨æ·å¡«å options
 *    2.3 æ ¹æ®ç¨æ·å¡«åç options è¡¥åçæ°ç»å optionsï¼å¦ yaxisï¼GeometryOptionï¼å ä¸º deepAssign æ æ³ assign æ°ç»
 *
 * @param params
 */
export function transformOptions(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { options } = params;
  const { geometryOptions = [], xField, yField } = options;
  const allLine = every(
    geometryOptions,
    ({ geometry }) => geometry === DualAxesGeometry.Line || geometry === undefined
  );
  return deepAssign(
    {},
    {
      options: {
        geometryOptions: [],
        meta: {
          [xField]: {
            // é»è®¤ä¸º cat ç±»å
            type: 'cat',
            // x è½´ä¸å®æ¯åæ­¥ scale ç
            sync: true,
            // å¦æææ²¡ææ±å­ï¼å
            range: allLine ? [0, 1] : undefined,
          },
        },
        tooltip: {
          showMarkers: allLine,
          // å­å¨æ±ç¶å¾ï¼ä¸æ¾ç¤º crosshairs
          showCrosshairs: allLine,
          shared: true,
          crosshairs: {
            type: 'x',
          },
        },
        interactions: !allLine
          ? [{ type: 'legend-visible-filter' }, { type: 'active-region' }]
          : [{ type: 'legend-visible-filter' }],
        legend: {
          position: 'top-left',
        },
      },
    },
    params,
    {
      options: {
        // yAxis
        yAxis: transformObjectToArray(yField, options.yAxis),
        // geometryOptions
        geometryOptions: [
          getGeometryOption(xField, yField[0], geometryOptions[0]),
          getGeometryOption(xField, yField[1], geometryOptions[1]),
        ],
        // annotations
        annotations: transformObjectToArray(yField, options.annotations),
      },
    }
  );
}

/**
 * åå»º åè½´å¾ ä¸­ç»å¶å¾å½¢ç viewï¼æååå»ºæ¯å ä¸º theme ééå¨çéè¦
 * @param params
 */
function createViews(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { geometryOptions } = options;

  const SORT_MAP = { line: 0, column: 1 };

  // åå«éç½®ï¼idï¼æ°æ®çç»æ
  const geometries = [
    { type: geometryOptions[0]?.geometry, id: LEFT_AXES_VIEW },
    { type: geometryOptions[1]?.geometry, id: RIGHT_AXES_VIEW },
  ];

  // å°çº¿ç view æ¾ç½®å¨æ´ä¸ä¸å±ï¼é²æ­¢çº¿æ±é®æ¡ãåæ±åå
  geometries.sort((a, b) => -SORT_MAP[a.type] + SORT_MAP[b.type]).forEach((g) => chart.createView({ id: g.id }));

  return params;
}

/**
 * ç»å¶å¾å½¢
 * @param params
 */
function geometry(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { xField, yField, geometryOptions, data, tooltip } = options;

  // åå«éç½®ï¼idï¼æ°æ®çç»æ
  const geometries = [
    { ...geometryOptions[0], id: LEFT_AXES_VIEW, data: data[0], yField: yField[0] },
    { ...geometryOptions[1], id: RIGHT_AXES_VIEW, data: data[1], yField: yField[1] },
  ];

  geometries.forEach((geometry) => {
    const { id, data, yField } = geometry;
    // ç¾åæ¯æ±ç¶å¾éè¦é¢å¤å¤çä¸æ¬¡æ°æ®
    const isPercent = isColumn(geometry) && geometry.isPercent;
    const formatData = isPercent ? percent(data, yField, xField, yField) : data;
    const view = findViewById(chart, id).data(formatData);

    const tooltipOptions = isPercent
      ? {
          formatter: (datum: Datum) => ({
            name: datum[geometry.seriesField] || yField,
            value: (Number(datum[yField]) * 100).toFixed(2) + '%',
          }),
          ...tooltip,
        }
      : tooltip;

    // ç»å¶å¾å½¢
    drawSingleGeometry({
      chart: view,
      options: {
        xField,
        yField,
        tooltip: tooltipOptions,
        geometryOption: geometry,
      },
    });
  });
  return params;
}

export function color(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { geometryOptions } = options;
  const themeColor = chart.getTheme()?.colors10 || [];

  let start = 0;
  /* ä¸º geometry æ·»å é»è®¤ colorã
   * 1. è¥ geometryOptions å­å¨ colorï¼åå¨ drawGeometry æ¶å·²å¤ç
   * 2. è¥ ä¸å­å¨ colorï¼è·å Geometry group scalesä¸ªæ°ï¼å¨ theme color 10 ä¸­æå
   * 3. ä¸ºé²æ­¢ group è¿å¤å¯¼è´å³è²æ¿æ å¼æå¼å¾å°ï¼å³ view é¢æ¿å¨ä¾æ¬¡æåå©ä¸ç N ä¸ª åå concat ä¸æ¬¡ themeColor
   * 4. ä¸ºç®ä¾¿è·å Geometry group scalesä¸ªæ°ï¼å¨ç»å¶å®ååæ§è¡ color
   * 5. èèä¹åå°ä¸å view ä½¿ç¨åä¸ä¸ªè²æ¿çéæ±æ²æ·å° g2
   */
  chart.once('beforepaint', () => {
    each(geometryOptions, (geometryOption, index) => {
      const view = findViewById(chart, index === 0 ? LEFT_AXES_VIEW : RIGHT_AXES_VIEW);
      if (geometryOption.color) return;
      const groupScale = view.getGroupScales();
      const count = get(groupScale, [0, 'values', 'length'], 1);
      const color = themeColor.slice(start, start + count).concat(index === 0 ? [] : themeColor);
      view.geometries.forEach((geometry) => {
        if (geometryOption.seriesField) {
          geometry.color(geometryOption.seriesField, color);
        } else {
          geometry.color(color[0]);
        }
      });
      start += count;
    });
    chart.render(true);
  });

  return params;
}

/**
 * meta éç½®
 * @param params
 */
export function meta(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { xAxis, yAxis, xField, yField } = options;

  scale({
    [xField]: xAxis,
    [yField[0]]: yAxis[0],
  })(deepAssign({}, params, { chart: findViewById(chart, LEFT_AXES_VIEW) }));

  scale({
    [xField]: xAxis,
    [yField[1]]: yAxis[1],
  })(deepAssign({}, params, { chart: findViewById(chart, RIGHT_AXES_VIEW) }));

  return params;
}

/**
 * axis éç½®
 * @param params
 */
export function axis(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const leftView = findViewById(chart, LEFT_AXES_VIEW);
  const rightView = findViewById(chart, RIGHT_AXES_VIEW);
  const { xField, yField, xAxis, yAxis } = options;

  chart.axis(xField, false);
  chart.axis(yField[0], false);
  chart.axis(yField[1], false);

  // å·¦ View
  leftView.axis(xField, xAxis);
  leftView.axis(yField[0], getYAxisWithDefault(yAxis[0], AxisType.Left));

  // å³ Y è½´
  rightView.axis(xField, false);
  rightView.axis(yField[1], getYAxisWithDefault(yAxis[1], AxisType.Right));

  return params;
}

/**
 * tooltip éç½®
 * @param params
 */
export function tooltip(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { tooltip } = options;
  const leftView = findViewById(chart, LEFT_AXES_VIEW);
  const rightView = findViewById(chart, RIGHT_AXES_VIEW);
  // tooltip ç»è¿ getDefaultOption å¤çåï¼ä¸å®ä¸ä¸º undefined
  chart.tooltip(tooltip);
  // å¨ view ä¸æ·»å  tooltipï¼ä½¿å¾ shared å interaction active-region èµ·ä½ç¨
  // view åºè¯¥ç»§æ¿ chart éç sharedï¼ä½æ¯ä»è¡¨ç°çæ¥ï¼ç»§æ¿æç¹é®é¢
  leftView.tooltip({
    shared: true,
  });
  rightView.tooltip({
    shared: true,
  });
  return params;
}

/**
 * interaction éç½®
 * @param params
 */
export function interaction(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart } = params;

  commonInteraction(deepAssign({}, params, { chart: findViewById(chart, LEFT_AXES_VIEW) }));
  commonInteraction(deepAssign({}, params, { chart: findViewById(chart, RIGHT_AXES_VIEW) }));

  return params;
}

/**
 * annotation éç½®
 * @param params
 */
export function annotation(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { annotations } = options;

  const a1 = get(annotations, [0]);
  const a2 = get(annotations, [1]);

  commonAnnotation(a1)(
    deepAssign({}, params, {
      chart: findViewById(chart, LEFT_AXES_VIEW),
      options: {
        annotations: a1,
      },
    })
  );
  commonAnnotation(a2)(
    deepAssign({}, params, {
      chart: findViewById(chart, RIGHT_AXES_VIEW),
      options: {
        annotations: a2,
      },
    })
  );
  return params;
}

export function theme(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart } = params;

  /*
   * åè½´å¾ä¸­ï¼é¨åç»ä»¶æ¯ç»å¶å¨å­ view å±ï¼ä¾å¦ axisï¼lineï¼ï¼é¨åç»ä»¶æ¯ç»å¶å¨ chart ï¼ä¾å¦ legend)
   * ä¸º chart å å­ view åæ³¨å themeï¼ä½¿å¶èªè¡éµå¾ª G2 theme geometry > view > chart è¿è¡æ¸²æã
   */
  commonTheme(deepAssign({}, params, { chart: findViewById(chart, LEFT_AXES_VIEW) }));
  commonTheme(deepAssign({}, params, { chart: findViewById(chart, RIGHT_AXES_VIEW) }));
  commonTheme(params);

  return params;
}

export function animation(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart } = params;

  commonAnimation(deepAssign({}, params, { chart: findViewById(chart, LEFT_AXES_VIEW) }));
  commonAnimation(deepAssign({}, params, { chart: findViewById(chart, RIGHT_AXES_VIEW) }));

  return params;
}

/**
 * åè½´å¾ limitInPlot
 * @param params
 */
export function limitInPlot(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { yAxis } = options;

  commonLimitInPlot(
    deepAssign({}, params, {
      chart: findViewById(chart, LEFT_AXES_VIEW),
      options: {
        yAxis: yAxis[0],
      },
    })
  );

  commonLimitInPlot(
    deepAssign({}, params, {
      chart: findViewById(chart, RIGHT_AXES_VIEW),
      options: {
        yAxis: yAxis[1],
      },
    })
  );

  return params;
}

/**
 * legend éç½®
 * ä½¿ç¨ customï¼ä¾¿äºåç±»ä¼¼äºåç»æ±ç¶å¾-åæçº¿å¾çé»è¾ç»ä¸
 * @param params
 */
export function legend(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { legend, geometryOptions, yField, data } = options;
  const leftView = findViewById(chart, LEFT_AXES_VIEW);
  const rightView = findViewById(chart, RIGHT_AXES_VIEW);

  if (legend === false) {
    chart.legend(false);
  } else if (isObject(legend) && legend.custom === true) {
    chart.legend(legend);
  } else {
    const leftLegend = get(geometryOptions, [0, 'legend'], legend);
    const rightLegend = get(geometryOptions, [1, 'legend'], legend);
    // åä½¿ç¨èªå®ä¹å¾ä¾
    chart.once('beforepaint', () => {
      const leftItems = data[0].length
        ? getViewLegendItems({
            view: leftView,
            geometryOption: geometryOptions[0],
            yField: yField[0],
            legend: leftLegend,
          })
        : [];

      const rightItems = data[1].length
        ? getViewLegendItems({
            view: rightView,
            geometryOption: geometryOptions[1],
            yField: yField[1],
            legend: rightLegend,
          })
        : [];

      chart.legend(
        deepAssign({}, legend, {
          custom: true,
          // todo ä¿®æ¹ç±»åå®ä¹
          // @ts-ignore
          items: leftItems.concat(rightItems),
        })
      );
    });

    if (geometryOptions[0].seriesField) {
      leftView.legend(geometryOptions[0].seriesField, leftLegend);
    }
    if (geometryOptions[1].seriesField) {
      rightView.legend(geometryOptions[1].seriesField, rightLegend);
    }

    // èªå®ä¹å¾ä¾äº¤äº
    chart.on('legend-item:click', (evt) => {
      const delegateObject = get(evt, 'gEvent.delegateObject', {});
      if (delegateObject && delegateObject.item) {
        const { value: field, isGeometry, viewId } = delegateObject.item;
        // geometry çæ¶åï¼ç´æ¥ä½¿ç¨ view.changeVisible
        if (isGeometry) {
          const idx = findIndex(yField, (yF: string) => yF === field);
          if (idx > -1) {
            const geometries = get(findViewById(chart, viewId), 'geometries');
            each(geometries, (g) => {
              g.changeVisible(!delegateObject.item.unchecked);
            });
          }
        } else {
          const legendItem = get(chart.getController('legend'), 'option.items', []);
          // åç»æ±çº¿å¾
          each(chart.views, (view) => {
            // åææ±å¾
            const groupScale = view.getGroupScales();
            each(groupScale, (scale: Scale) => {
              if (scale.values && scale.values.indexOf(field) > -1) {
                view.filter(scale.field, (value) => {
                  const curLegendItem: Types.LegendItem = find(
                    legendItem,
                    (item: Types.LegendItem) => item.value === value
                  );
                  // ä½¿ç¨ legend ä¸­ç unchecked æ¥å¤æ­ï¼ä½¿å¾æ¯æå³é­å¤ä¸ªå¾ä¾
                  return !curLegendItem.unchecked;
                });
              }
            });
            chart.render(true);
          });
        }
      }
    });
  }

  return params;
}

/**
 * åè½´å¾ slider ééå¨
 * @param params
 */
export function slider(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  const { chart, options } = params;
  const { slider } = options;
  const leftView = findViewById(chart, LEFT_AXES_VIEW);
  const rightView = findViewById(chart, RIGHT_AXES_VIEW);
  if (slider) {
    // å·¦ View
    leftView.option('slider', slider);
    // çå¬å·¦ä¾§ slider æ¹åäºä»¶ï¼ åæ­¥å³ä¾§ View è§å¾
    leftView.on('slider:valuechanged', (evt: Event) => {
      const {
        event: { value, originValue },
      } = evt;
      if (isEqual(value, originValue)) {
        return;
      }
      doSliderFilter(rightView, value);
    });
    chart.once('afterpaint', () => {
      // åå§åæ°æ®ï¼éç½®é»è®¤å¼æ¶éè¦åæ­¥
      if (!isBoolean(slider)) {
        const { start, end } = slider;
        if (start || end) {
          doSliderFilter(rightView, [start, end]);
        }
      }
    });
  }

  return params;
}

/**
 * åæçº¿å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<DualAxesOptions>): Params<DualAxesOptions> {
  // transformOptions ä¸å®å¨æåé¢å¤çï¼color legend ä½¿ç¨äº beforepaintï¼ä¸ºä¾¿äºçè§£æ¾å¨æåé¢
  return flow(
    transformOptions,
    createViews,
    // ä¸»é¢é åè®¾ç½®ï¼ä½ä¸ºæä½ä¼åçº§
    theme,
    geometry,
    meta,
    axis,
    limitInPlot,
    tooltip,
    interaction,
    annotation,
    animation,
    color,
    legend,
    slider
  )(params);
}
