import { isArray } from '@antv/util';
import { Data } from '../../types';

/**
 * æ ¹æ® edges è·åå¯¹åºç node ç»æ
 */
export function getNodes(edges: Data, sourceField: string, targetField: string): string[] {
  const nodes = [];
  edges.forEach((e) => {
    const source = e[sourceField] as string;
    const target = e[targetField] as string;
    if (!nodes.includes(source)) {
      nodes.push(source);
    }
    if (!nodes.includes(target)) {
      nodes.push(target);
    }
  });
  return nodes;
}

/**
 * æ ¹æ® edges è·åå¯¹åºç dfs é»æ¥ç©éµ
 */
export function getMatrix(
  edges: Data,
  nodes: string[],
  sourceField: string,
  targetField: string
): Record<string, Record<string, number>> {
  const graphMatrix = {};

  nodes.forEach((pre) => {
    graphMatrix[pre] = {};
    nodes.forEach((next) => {
      graphMatrix[pre][next] = 0;
    });
  });

  edges.forEach((edge) => {
    graphMatrix[edge[sourceField]][edge[targetField]] = 1;
  });

  return graphMatrix;
}

/**
 * ä½¿ç¨ DFS æè·¯åæ­æ¡åºå¾æ°æ®ä¸­çç¯ï¼ä¼ä¸¢å¤±æ°æ®ï¼ï¼ä¿è¯é¡ºåº
 * @param data
 * @param sourceField
 * @param targetField
 */
export function cutoffCircle(edges: Data, sourceField: string, targetField: string): Data {
  if (!isArray(edges)) return [];

  // å¾å é¤çç¯ç¶ç»æ
  const removedData = [];

  // è·åææçèç¹
  const nodes = getNodes(edges, sourceField, targetField);
  // è·åèç¹ä¸è¾¹çé»æ¥ç©éµ
  const graphMatrix = getMatrix(edges, nodes, sourceField, targetField);

  // visitedï¼æ è®°èç¹è®¿é®ç¶æ, 0ï¼æªè®¿é®,1ï¼è®¿é®ä¸­, -1ï¼å·²è®¿é®
  const visited = {};
  // åå§åvisited
  nodes.forEach((node) => {
    visited[node] = 0;
  });

  // å¾çæ·±åº¦éåå½æ°
  function DFS(dfsNode) {
    // èç¹ç¶æç½®ä¸ºæ­£å¨è®¿é®
    visited[dfsNode] = 1;
    nodes.forEach((node) => {
      if (graphMatrix[dfsNode][node] != 0) {
        // å½åèç¹å¨è®¿é®ä¸­ï¼åæ¬¡è¢«è®¿é®ï¼è¯ææç¯ï¼ç§»å¨å° removeData
        if (visited[node] == 1) {
          // æ¼æ¥ä¸ºå­ç¬¦ä¸²ï¼æ¹ä¾¿æåè¿æ»¤
          removedData.push(`${dfsNode}_${node}`);
        } else if (visited[node] == -1) {
          // å½åç»ç¹ååè¾¹çç»ç¹é½è¢«è®¿é®è¿ï¼ç´æ¥è·³è³ä¸ä¸ä¸ªç»ç¹
          return;
        } else {
          DFS(node); // å¦åéå½è®¿é®
        }
      }
    });
    //éåè¿ææç¸è¿çç»ç¹åï¼ææ¬èç¹æ è®°ä¸º-1
    visited[dfsNode] = -1;
  }

  // å¯¹æ¯ä¸ªèç¹æ§è¡ dfs æä½
  nodes.forEach((node) => {
    //è¯¥ç»ç¹åè¾¹çç»ç¹é½è¢«è®¿é®è¿äºï¼è·³è¿å®
    if (visited[node] == -1) {
      return;
    }
    DFS(node);
  });

  if (removedData.length !== 0) {
    console.warn(`sankey data contains circle, ${removedData.length} records removed.`, removedData);
  }

  // è¿æ»¤ remove è·¯å¾
  return edges.filter((edge) => removedData.findIndex((i) => i === `${edge[sourceField]}_${edge[targetField]}`) < 0);
}
