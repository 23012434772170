import en from './en'
import zh from './zh'
import zhHant from './zh-hant'
import pl from './pl'
import kr from './kr'
import tr from './tr'
import jpn from './jpn'
import ru from './ru'

export default {
  'en': en,
  'zh': zh,
  'zh-hant': zhHant,
  'pl': pl,
  'kr': kr,
  'tr': tr,
  'jpn': jpn,
  'ru': ru
}
