import Geometry from './base';
import './shape/edge';

/**
 * Edge å ä½æ è®°ï¼ç¨äºç»å¶å³ç³»å¾ä¸­ç**è¾¹**å¾å½¢ï¼å¦ï¼
 * 1. æµç¨å¾
 * 2. æ 
 * 3. å¼§é¿è¿æ¥å¾
 * 4. åå¼¦å¾
 * 5. æ¡åºå¾
 */
export default class Edge extends Geometry {
  public readonly type: string = 'edge';
  public readonly shapeType: string = 'edge';
  protected generatePoints: boolean = true;
}
