import { VIEW_LIFE_CIRCLE, Event } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { getProgressData } from '../progress/utils';
import { RingProgressOptions } from './types';
import { adaptor, statistic } from './adaptor';
import { DEFAULT_OPTIONS } from './constants';

export type { RingProgressOptions };

export class RingProgress extends Plot<RingProgressOptions> {
  /**
   * è·åé»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<RingProgressOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'ring-process';

  /**
   * æ´æ°æ°æ®
   * @param percent
   */
  public changeData(percent: number) {
    this.chart.emit(
      VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, null)
    );
    this.updateOption({ percent });

    this.chart.data(getProgressData(percent));
    // todo åç»­è®© G2 å±å¨ afterrender ä¹åï¼æ¥éç» annotations
    statistic({ chart: this.chart, options: this.options }, true);

    this.chart.emit(
      VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA,
      Event.fromData(this.chart, VIEW_LIFE_CIRCLE.AFTER_CHANGE_DATA, null)
    );
  }

  protected getDefaultOptions() {
    return RingProgress.getDefaultOptions();
  }

  /**
   * è·å ç¯å½¢è¿åº¦å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<RingProgressOptions> {
    return adaptor;
  }
}
