import { groupBy } from '@antv/util';
import { Types } from '@antv/g2';
import { Datum } from '../../types';
import { BidirectionalBarOptions } from './types';

type TransformData = {
  [key: string]: string | number;
}[];

/**
 * bidirectional-bar å¤çæ°æ®, éè¿ SERIES_FIELD_KEY å­æ®µåæå·¦å³æ°æ®
 * @param xField
 * @param yField
 * @param data
 */
export function transformData(
  xField: string,
  yField: string[],
  seriesField: string,
  data: Datum,
  reverse?: boolean
): Types.Data[] {
  const hopeData: TransformData = [];
  yField.forEach((d: string) => {
    data.forEach((k: any) => {
      const obj = {
        [xField]: k[xField],
        [seriesField]: d,
        [d]: k[d],
      };
      hopeData.push(obj);
    });
  });
  const groupData = Object.values(groupBy(hopeData, seriesField));
  const [data1 = [], data2 = []] = groupData;
  return reverse ? [data1.reverse(), data2.reverse()] : [data1, data2];
}

/**
 * æ¯å¦æ¨ªåï¼é»è®¤ç©ºä¸ºæ¨ªå
 * @param layout
 */
export function isHorizontal(layout: BidirectionalBarOptions['layout']) {
  return layout !== 'vertical';
}

/**
 * å¤ view è¿è¡åæ­¥ padding çèªå®ä¹é»è¾
 * @param chart
 * @param views
 * @param p
 */
export function syncViewPadding(chart: any, views: any, p: any) {
  const [v1, v2] = views;
  const p1 = v1.autoPadding;
  const p2 = v2.autoPadding;
  const { layout, position } = chart.__axisPosition;
  // ç®ååªè½æ ¹æ®å¸å±çæ¯ä¾æ¥å¤æ­ layout
  if (isHorizontal(layout) && position === 'top') {
    /**
     * ä¿è¯ v1 ç left å v2 right çé´éç¸ç­ï¼å ä¸º v1 æè½´
     * position top å³ä¸º v1 å·¦è¾¹ï¼ä¸­é´é´è·è®¾ç½®å°±ä¸º 0
     */
    v1.autoPadding = p.instance(p1.top, 0, p1.bottom, p1.left);
    v2.autoPadding = p.instance(p2.top, p1.left, p2.bottom, 0);
  }
  if (isHorizontal(layout) && position === 'bottom') {
    /**
     * ä¿è¯ v1 ç left å v2 right çé´éç¸ç­ï¼å ä¸º v1 æè½´
     * position bottom å³ä¸º v1 çå³è¾¹ï¼v1 right = right / 2  v2 left = right / 2
     * + 5 æ¯ä¸ºäº è®©é£ä¸ªè½´ä¸è¦å¤ªè´´è¿äºï¼æ´å¥½ç
     */
    v1.autoPadding = p.instance(p1.top, p1.right / 2 + 5, p1.bottom, p1.left);
    v2.autoPadding = p.instance(p2.top, p2.right, p2.bottom, p1.right / 2 + 5);
  }
  if (!isHorizontal(layout) && position === 'bottom') {
    /**
     * ä¿è¯ v1 ç left å v2 left çé´éç¸ç­ left åæå¤§å¼
     * position bottom å³ä¸º v1 ä¸è¾¹ï¼v1 bottom = bottom / 2  v2 top = bottom / 2
     * + 5 æ¯ä¸ºäº è®©é£ä¸ªè½´ä¸è¦å¤ªè´´è¿äºï¼æ´å¥½ç
     */
    const left = p1.left >= p2.left ? p1.left : p2.left;
    v1.autoPadding = p.instance(p1.top, p1.right, p1.bottom / 2 + 5, left);
    v2.autoPadding = p.instance(p1.bottom / 2 + 5, p2.right, p2.bottom, left);
  }
  // åç´ç¶æï¼ä¸å»ºè®®è®¾ç½®position ä¸º topï¼ è¿æ¯åä¸ªå¼å®¹å¤ç
  if (!isHorizontal(layout) && position === 'top') {
    const left = p1.left >= p2.left ? p1.left : p2.left;
    v1.autoPadding = p.instance(p1.top, p1.right, 0, left);
    v2.autoPadding = p.instance(0, p2.right, p1.top, left);
  }
}
