import { get } from '@antv/util';
import { Datum, MappingDatum, ShapeInfo, LooseObject } from '../interface';
import { ShapeAttrs } from '../dependents';
import { getXDimensionLength } from '../util/coordinate';
import Geometry, { GeometryCfg } from './base';
/** å¼å¥å¯¹åºç ShapeFactory */
import './shape/interval';
import { getDefaultSize } from './util/shape-size';
import { getMaxScale } from '../util/scale';

/** Path æé å½æ°åæ°ç±»å */
export interface IntervalCfg extends GeometryCfg {
  /** shape èæ¯ï¼åªå¯¹ Interval Geometry çæï¼ç®ååªå¯¹ interval-rect shape çæã */
  background?: { style?: ShapeAttrs };
}

/**
 * Interval å ä½æ è®°ã
 * ç¨äºç»å¶æ±ç¶å¾ãé¥¼å¾ãæ¡å½¢å¾ãç«ç°å¾ç­ã
 */
export default class Interval extends Geometry {
  public readonly type: string = 'interval';
  public readonly shapeType: string = 'interval';
  /** shape èæ¯ãç®ååªå¯¹ interval-rect shape çæã */
  protected background?: { style?: ShapeAttrs };
  protected generatePoints: boolean = true;

  constructor(cfg: IntervalCfg) {
    super(cfg);

    const { background } = cfg;
    this.background = background;
  }

  /**
   * è·åæ¯æ¡æ°æ®ç Shape ç»å¶ä¿¡æ¯
   * @param obj ç»è¿åç» -> æ°å­å -> adjust è°æ´åçæ°æ®è®°å½
   * @returns
   */
  protected createShapePointsCfg(obj: Datum) {
    const cfg = super.createShapePointsCfg(obj);

    // è®¡ç®æ¯ä¸ª shape ç size
    let size;
    const sizeAttr = this.getAttribute('size');
    if (sizeAttr) {
      size = this.getAttributeValues(sizeAttr, obj)[0];
      // å½ä¸å
      const coordinate = this.coordinate;
      const coordinateWidth = getXDimensionLength(coordinate);
      size = size / coordinateWidth;
    } else {
      if (!this.defaultSize) {
        this.defaultSize = getDefaultSize(this);
      }
      size = this.defaultSize;
    }
    cfg.size = size;

    return cfg;
  }

  /**
   * è°æ´ y è½´ç scale èå´ã
   * å¯¹äº Y è½´ä¸ºæ°å¼è½´æ±ç¶å¾ï¼é»è®¤ä» 0 å¼å§ çé¿ã
   */
  protected adjustScale() {
    super.adjustScale();
    const yScale = this.getYScale();
    // ç¹æ®é»è¾ï¼é¥¼å¾éè¦å¡«åæ»¡æ´ä¸ªç©ºé´
    if (this.coordinate.type === 'theta') {
      yScale.change({
        nice: false,
        min: 0,
        // åçè¿ stack è°æ´ï¼yScale ç max è¢«è°æ´è¿ï¼this.updateStackRange()
        max: getMaxScale(yScale),
      });
    } else {
      // æ±ç¶å¾æ°å¼è½´é»è®¤ä» 0 å¼å§
      const scaleDefs = this.scaleDefs;
      const { field, min, max, type } = yScale;
      if (type !== 'time') {
        // time ç±»åä¸åè°æ´
        // æ±ç¶å¾ç Y è½´è¦ä» 0 å¼å§çé¿ï¼ä½æ¯å¦æç¨æ·è®¾ç½®äºåä»¥ç¨æ·çä¸ºå
        if (min > 0 && !get(scaleDefs, [field, 'min'])) {
          yScale.change({
            min: 0,
          });
        }
        // æ±å½æ±ç¶å¾å¨ä¸ºè´å¼æ¶ä¹éè¦ä» 0 å¼å§çé¿ï¼ä½æ¯å¦æç¨æ·è®¾ç½®äºåä»¥ç¨æ·çä¸ºå
        if (max <= 0 && !get(scaleDefs, [field, 'max'])) {
          yScale.change({
            max: 0,
          });
        }
      }
    }
  }

  /**
   * @override
   */
  protected getDrawCfg(mappingData: MappingDatum): ShapeInfo {
    const shapeCfg = super.getDrawCfg(mappingData);
    shapeCfg.background = this.background;

    return shapeCfg;
  }
}
