import { each, isNil, some } from '@antv/util';
import { Coordinate, getCoordinate, Point } from '../../dependents';
import { CoordinateOption } from '../../interface';

/**
 * coordinate controllerï¼èè´£ï¼
 * 1. åå»ºå®ä¾
 * 2. æå­éç½®
 */
export default class CoordinateController {
  private option: CoordinateOption;
  private coordinate: Coordinate;

  constructor(option?: CoordinateOption) {
    // è®¾ç½®é»è®¤å¼ï¼å¹¶å­å¨éç½®
    this.option = this.wrapperOption(option);
  }

  /**
   * æ´æ°éç½®
   * @param option
   */
  public update(option: CoordinateOption) {
    this.option = this.wrapperOption(option);
    return this;
  }

  /**
   * æ¯å¦å­å¨æä¸ä¸ª action
   * @param actionName
   */
  public hasAction(actionName: string) {
    const { actions } = this.option;

    return some(actions, (action) => action[0] === actionName);
  }
  /**
   * åå»ºåæ ç³»å¯¹è±¡
   * @param start èµ·å§ä½ç½®
   * @param end   ç»æä½ç½®
   * @return åæ ç³»å®ä¾
   */
  public create(start: Point, end: Point) {
    const { type, cfg } = this.option;
    const isTheta = type === 'theta';

    // 1. èµ·å§ä½ç½®
    const props = {
      start,
      end,
      ...cfg,
    };

    // 2. åå»ºå®ä¾
    const C = getCoordinate(isTheta ? 'polar' : type);

    this.coordinate = new C(props);

    // @ts-ignore FIXME coordinate åé®é¢å¯¼è´ type ä¸æ­£ç¡®
    this.coordinate.type = type;

    // 3. æ·»å é»è®¤ action
    if (isTheta) {
      // ä¸å­å¨ transposeï¼ä¸ºå¶èªå¨è®¾ç½®ä¸ä¸ª action
      if (!this.hasAction('transpose')) {
        this.transpose();
      }
    }

    // 4. æ§è¡ action
    this.execActions();

    return this.coordinate;
  }

  /**
   * æ´æ°åæ ç³»å¯¹è±¡
   * @param start èµ·å§ä½ç½®
   * @param end   ç»æä½ç½®
   * @return åæ ç³»å®ä¾
   */
  public adjust(start: Point, end: Point) {
    this.coordinate.update({
      start,
      end,
    });

    // æ´æ°åæ ç³»å¤§å°çæ¶åï¼éè¦ï¼
    // 1. éç½® matrix
    // 2. éæ°æ§è¡ä½ç¨äº matrix ç action
    this.coordinate.resetMatrix();
    this.execActions(['scale', 'rotate', 'translate']);

    return this.coordinate;
  }

  /**
   * æè½¬å¼§åº¦
   * @param angle
   */
  public rotate(angle: number) {
    this.option.actions.push(['rotate', angle]);
    return this;
  }

  /**
   * éå
   * @param dim
   */
  public reflect(dim: 'x' | 'y') {
    this.option.actions.push(['reflect', dim]);
    return this;
  }

  /**
   * scale
   * @param sx
   * @param sy
   */
  public scale(sx: number, sy: number) {
    this.option.actions.push(['scale', sx, sy]);
    return this;
  }

  /**
   * å¯¹è§åæ¢
   */
  public transpose() {
    this.option.actions.push(['transpose']);
    return this;
  }

  /**
   * è·åéç½®
   */
  public getOption(): CoordinateOption {
    return this.option;
  }

  /**
   * è·å¾ coordinate å®ä¾
   */
  public getCoordinate() {
    return this.coordinate;
  }

  /**
   * åè£éç½®çé»è®¤å¼
   * @param option
   */
  private wrapperOption(option: CoordinateOption): CoordinateOption {
    return {
      type: 'rect',
      actions: [],
      cfg: {},
      ...option,
    };
  }

  /**
   * coordinate å®ä¾æ§è¡ actions
   * @params includeActions å¦ææ²¡ææå®ï¼åæ§è¡å¨é¨ï¼å¦åï¼æ§è¡æå®ç action
   */
  private execActions(includeActions?: string[]) {
    const { actions } = this.option;

    each(actions, (action) => {
      const [actionName, ...args] = action;

      const shouldExec = isNil(includeActions) ? true : includeActions.includes(actionName);

      if (shouldExec) {
        this.coordinate[actionName](...args);
      }
    });
  }
}
