import { Adjust, getAdjust as getAdjustClass } from '@antv/adjust';
import { Attribute, getAttribute as getAttributeClass } from '@antv/attr';
import {
  clone,
  deepMix,
  each,
  flatten,
  get,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  isObject,
  isPlainObject,
  isString,
  set,
} from '@antv/util';
import { doGroupAppearAnimate, getDefaultAnimateCfg } from '../animate';
import Base from '../base';
import { FIELD_ORIGIN, GROUP_ATTRS } from '../constant';
import { BBox, Coordinate, IGroup, IShape, Scale } from '../dependents';
import {
  AdjustOption,
  AdjustType,
  AnimateOption,
  AttributeOption,
  ColorAttrCallback,
  Data,
  Datum,
  GeometryLabelCfg,
  GeometryTooltipOption,
  LabelCallback,
  LabelOption,
  LooseObject,
  MappingDatum,
  ScaleOption,
  ShapeAttrCallback,
  ShapeFactory,
  ShapeInfo,
  ShapeMarkerCfg,
  ShapeMarkerAttrs,
  ShapePoint,
  SizeAttrCallback,
  StateOption,
  StyleCallback,
  StyleOption,
  TooltipCallback,
  CustomOption,
} from '../interface';
import { uniq } from '../util/helper';
import Element from './element';
import { getGeometryLabel } from './label';
import GeometryLabel from './label/base';
import { getShapeFactory } from './shape/base';
import { group } from './util/group-data';
import { isModelChange } from './util/is-model-change';
import { parseFields } from './util/parse-fields';
import { diff } from './util/diff';
import { inferScaleType } from '../util/scale';
import { getXDimensionLength } from '../util/coordinate';

/** @ignore */
interface AttributeInstanceCfg {
  fields?: string[];
  callback?: (...args) => any;
  values?: string[] | number[];
  scales?: Scale[];
}
interface DimValuesMapType {
  [dim: string]: number[];
}
/** @ignore */
interface AdjustInstanceCfg {
  type: AdjustType;
  adjustNames?: string[];
  xField?: string;
  yField?: string;

  dodgeBy?: string;
  marginRatio?: number;
  dodgeRatio?: number;

  size?: number;
  height?: number;
  reverseOrder?: boolean;

  /** åç´ çº§æ±é´å®½åº¦ï¼è°æ´offset */
  intervalPadding?: number;
  dodgePadding?: number;
  /** xç»´åº¦é¿åº¦ï¼è®¡ç®å½ä¸åpaddingä½¿ç¨ */
  xDimensionLength?: number;
  /** åç»æ°ï¼è®¡ç®offset */
  groupNum?: number;
  /** ç¨æ·éç½®å®½åº¦ size */
  defaultSize?: number;
  /** æå¤§æå°å®½åº¦çº¦æ */
  maxColumnWidth?: number;
  minColumnWidth?: number;
  /** æ±å®½æ¯ä¾ */
  columnWidthRatio?: number;
  /** ç¨æ·èªå®ä¹çdimValuesMap */
  dimValuesMap?: DimValuesMapType;
}

/** geometry.init() ä¼ å¥åæ° */
export interface InitCfg {
  /** åæ ç³» */
  coordinate?: Coordinate;
  /** æ°æ® */
  data?: Data;
  /** ä¸»é¢å¯¹è±¡ */
  theme?: LooseObject;
  /** åå®ä¹ */
  scaleDefs?: Record<string, ScaleOption>;
  /** å ä¸ºæ°æ®ä½¿ç¨çå¼ç¨ï¼æä»¥éè¦æä¸ä¸ªæ è¯ä½æ è¯æ°æ®æ¯å¦åçäºæ´æ° */
  isDataChanged?: boolean;
  isCoordinateChanged?: boolean;
}

/** Geometry æé å½æ°åæ° */
export interface GeometryCfg {
  /** Geometry shape çå®¹å¨ã */
  container: IGroup;
  /** ç»å¶çåæ ç³»å¯¹è±¡ã */
  coordinate?: Coordinate;
  /** ç»å¶æ°æ®ã */
  data?: Data;
  /** éè¦ç scalesã */
  scales?: Record<string, Scale>;
  /** åå®ä¹ */
  scaleDefs?: Record<string, ScaleOption>;
  /** Geometry labels çå®¹å¨ */
  labelsContainer?: IGroup;
  /** æ¯å¦å¯¹æ°æ®è¿è¡æåº */
  sortable?: boolean;
  /** elements ç zIndex é»è®¤æé¡ºåºæåï¼éè¿ zIndexReversed å¯ä»¥ååºï¼ä»èæ°æ®è¶åï¼å±çº§è¶é« */
  zIndexReversed?: boolean;
  /** æ¯å¦éè¦å¯¹ zIndex è¿è¡ sortãå ä¸ºèæ¶é¿ï¼ç±å·ä½åºæ¯èªè¡å³å® */
  sortZIndex?: boolean;
  /** æ¯å¦å¯è§ */
  visible?: boolean;
  /** ä¸»é¢éç½® */
  theme?: LooseObject;

  /** ç»é´è· */
  intervalPadding?: number;
  /** ç»åé´è· */
  dodgePadding?: number;
  /** æ±ç¶å¾æå¤§å®½åº¦ */
  maxColumnWidth?: number;
  /** æ±ç¶å¾æå°å®½åº¦ */
  minColumnWidth?: number;
  /** é»è®¤å®½åº¦å æ¯ï¼intervalç±»ååschemaç±»åéç¨ */
  columnWidthRatio?: number;
  /** ç«ç°å¾å æ¯ */
  roseWidthRatio?: number;
  /** å¤å±é¥¼å¾/ç¯å¾å æ¯ */
  multiplePieWidthRatio?: number;
}

// æ ¹æ® elementId æ¥æ¾å¯¹åºç labelï¼å ä¸ºæå¯è½ä¸ä¸ª element å¯¹åºå¤ä¸ª labelsï¼æä»¥å¨ç» labels ææ è¯æ¶åäºå¤ç
// ææ è§åè¯¦è§ ./label/base.ts#L263
function filterLabelsById(id: string, labelsMap: Record<string, IGroup>) {
  const labels = [];
  each(labelsMap, (label: IGroup, labelId: string) => {
    const elementId = labelId.split(' ')[0];
    if (elementId === id) {
      labels.push(label);
    }
  });

  return labels;
}

/**
 * Geometry å ä½æ è®°åºç±»ï¼ä¸»è¦è´è´£æ°æ®å°å¾å½¢å±æ§çæ å°ä»¥åç»å¶é»è¾ã
 */
export default class Geometry<S extends ShapePoint = ShapePoint> extends Base {
  /** Geometry å ä½æ è®°ç±»åã */
  public readonly type: string = 'base';
  /** ShapeFactory å¯¹åºçç±»åã */
  public readonly shapeType: string;

  // å¨åå»º Geometry å®ä¾æ¶å¯ä»¥ä¼ å¥çå±æ§
  /** Coordinate åæ ç³»å®ä¾ã */
  public coordinate: Coordinate;
  /** ç¨æ·ç»å¶æ°æ®ã */
  public data: Data;
  /** å¾å½¢ç»å¶å®¹å¨ã */
  public readonly container: IGroup;
  /** label ç»å¶å®¹å¨ã */
  public readonly labelsContainer: IGroup;
  /** æ¯å¦å¯¹æ°æ®è¿è¡æåºï¼é»è®¤ä¸º falseã  */
  public sortable: boolean;
  /** å½å Geometry å®ä¾ä¸»é¢ã  */
  public theme: LooseObject;
  /** å­å¨ geometry éè¦ç scalesï¼éè¦å¤é¨ä¼ å¥ã */
  public scales: Record<string, Scale>;
  /** scale å®ä¹ï¼éè¦å¤é¨ä¼ å¥ã */
  public scaleDefs: Record<string, ScaleOption>;
  /** ç»å¸åºåï¼ç¨äº label å¸å±ã */
  public canvasRegion: BBox;

  // åé¨äº§ççå±æ§
  /** Attribute map  */
  public attributes: Record<string, Attribute> = {};
  /** Element map */
  public elements: Element[] = [];
  /**
   * å­å¨å¤çåçæ°æ®ï¼
   * + init() å updateData() é»è¾å, ç»æä¸º Data[]ï¼
   * + paint() é»è¾åï¼ç»æä¸º MappingDatum[][]ã
   */
  public dataArray: MappingDatum[][];
  /** å­å¨ tooltip éç½®ä¿¡æ¯ã */
  public tooltipOption: GeometryTooltipOption | boolean;
  /** å­å¨ label éç½®ä¿¡æ¯ã */
  public labelOption: LabelOption | false;
  /** ç¶æéç¸å³çéç½®é¡¹ */
  public stateOption: StateOption;
  /** ä½¿ç¨ key-value ç»æå­å¨ Elementï¼key ä¸ºæ¯ä¸ª Element å®ä¾å¯¹åºçå¯ä¸ ID */
  public elementsMap: Record<string, Element> = {};
  /** animate éç½®é¡¹ */
  public animateOption: AnimateOption | boolean = true;
  /** å¾å½¢å±æ§æ å°éç½® */
  protected attributeOption: Record<string, AttributeOption> = {};
  /** adjust éç½®é¡¹ */
  protected adjustOption: AdjustOption[];
  /** style éç½®é¡¹ */
  protected styleOption: StyleOption;
  /** custom èªå®ä¹çéç½®é¡¹ */
  protected customOption: CustomOption;
  /** æ¯ä¸ª Geometry å¯¹åºç Shape å·¥åå®ä¾ï¼ç¨äºåå»ºåä¸ª Shape */
  protected shapeFactory: ShapeFactory;
  /** å­å¨ä¸ä¸æ¬¡æ¸²ææ¶ç element æ å°è¡¨ï¼ç¨äºæ´æ°é»è¾ */
  protected lastElementsMap: Record<string, Element> = {};
  /** æ¯å¦çæå¤ä¸ªç¹æ¥ç»å¶å¾å½¢ã */
  protected generatePoints: boolean = false;
  /** å­å¨åçå¾å½¢å±æ§æ å°åçæ°æ® */
  protected beforeMappingData: Data[] = null;
  /** å­å¨æ¯ä¸ª shape çé»è®¤ sizeï¼ç¨äº IntervalãSchema å ä½æ è®° */
  protected defaultSize: number;

  // ç¨æ·éè¿ geometry æé å½æ°è®¾ç½®çä¸»é¢
  private userTheme: LooseObject;
  private adjusts: Record<string, Adjust> = {};
  private lastAttributeOption;
  private idFields: string[] = [];
  private geometryLabel: GeometryLabel;

  // æ±ç¶å¾é´è·ç¸å³éç½®
  /** ç»é´è· */
  protected intervalPadding: number;
  /** ç»åé´è· */
  protected dodgePadding: number;
  /** æ±ç¶å¾æå¤§å®½åº¦ */
  protected maxColumnWidth: number;
  /** æ±ç¶å¾æå°å®½åº¦ */
  protected minColumnWidth: number;
  /** ä¸è¬æ±ç¶å¾å®½åº¦å æ¯ */
  protected columnWidthRatio: number;
  /** ç«ç°å¾å æ¯ */
  protected roseWidthRatio: number;
  /** å¤å±é¥¼å¾/ç¯å¾å æ¯ */
  protected multiplePieWidthRatio: number;
  /** elements ç zIndex é»è®¤æé¡ºåºæåï¼éè¿ zIndexReversed å¯ä»¥ååºï¼ä»èæ°æ®è¶åï¼å±çº§è¶é« */
  public zIndexReversed?: boolean;
  /** æ¯å¦éè¦å¯¹ zIndex è¿è¡ sortãå ä¸ºèæ¶é¿ï¼ç±å·ä½åºæ¯èªè¡å³å® */
  public sortZIndex?: boolean;

  /** èæ Groupï¼ç¨äºå¾å½¢æ´æ° */
  private offscreenGroup: IGroup;
  private groupScales: Scale[];
  private hasSorted: boolean = false;
  protected isCoordinateChanged: boolean = false;

  /**
   * åå»º Geometry å®ä¾ã
   * @param cfg
   */
  constructor(cfg: GeometryCfg) {
    super(cfg);

    const {
      container,
      labelsContainer,
      coordinate,
      data,
      sortable = false,
      visible = true,
      theme,
      scales = {},
      scaleDefs = {},
      // æ±ç¶å¾é´éä¸å®½åº¦ç¸å³éç½®
      intervalPadding,
      dodgePadding,
      maxColumnWidth,
      minColumnWidth,
      columnWidthRatio,
      roseWidthRatio,
      multiplePieWidthRatio,
      zIndexReversed,
      sortZIndex,
    } = cfg;

    this.container = container;
    this.labelsContainer = labelsContainer;
    this.coordinate = coordinate;
    this.data = data;
    this.sortable = sortable;
    this.visible = visible;
    this.userTheme = theme;
    this.scales = scales;
    this.scaleDefs = scaleDefs;
    // æ±ç¶å¾é´éä¸å®½åº¦ç¸å³éç½®
    this.intervalPadding = intervalPadding;
    this.dodgePadding = dodgePadding;
    this.maxColumnWidth = maxColumnWidth;
    this.minColumnWidth = minColumnWidth;
    this.columnWidthRatio = columnWidthRatio;
    this.roseWidthRatio = roseWidthRatio;
    this.multiplePieWidthRatio = multiplePieWidthRatio;
    this.zIndexReversed = zIndexReversed;
    this.sortZIndex = sortZIndex;
  }

  /**
   * éç½® position ééæ å°è§åã
   *
   * @example
   * ```typescript
   * // æ°æ®ç»æ: [{ x: 'A', y: 10, color: 'red' }]
   * geometry.position('x*y');
   * geometry.position([ 'x', 'y' ]);
   * geometry.position({
   *   fields: [ 'x', 'y' ],
   * });
   * ```
   *
   * @param cfg æ å°è§å
   * @returns
   */
  public position(cfg: string | string[] | AttributeOption): Geometry {
    let positionCfg = cfg;
    if (!isPlainObject(cfg)) {
      // å­ç¬¦ä¸²å­æ®µæèæ°ç»å­æ®µ
      positionCfg = {
        fields: parseFields(cfg),
      };
    }

    const fields = get(positionCfg, 'fields');
    if (fields.length === 1) {
      // é»è®¤å¡«åä¸ç»´ 1*xx
      fields.unshift('1');
      set(positionCfg, 'fields', fields);
    }
    set(this.attributeOption, 'position', positionCfg);

    return this;
  }

  /**
   * éç½® color ééæ å°è§åã
   *
   * @example
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   * geometry.color({
   *   fields: [ 'x' ],
   *   values: [ '#1890ff', '#5AD8A6' ],
   * });
   * ```
   *
   * @param field æ å°è§å
   * @returns
   */
  public color(field: AttributeOption): Geometry;
  /**
   * @example
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   *
   * // ä½¿ç¨ '#1890ff' é¢è²æ¸²æå¾å½¢
   * geometry.color('#1890ff');
   *
   * // æ ¹æ® x å­æ®µçæ°æ®å¼è¿è¡é¢è²çæ å°ï¼è¿æ¶å G2 ä¼å¨åé¨è°ç¨é»è®¤çåè°å½æ°ï¼è¯»åé»è®¤æä¾çé¢è²è¿è¡æ°æ®å¼å°é¢è²å¼çæ å°ã
   * geometry.color('x');
   *
   * // å° 'x' å­æ®µçæ°æ®å¼æ å°è³æå®çé¢è²å¼ colorsï¼å¯ä»¥æ¯å­ç¬¦ä¸²ä¹å¯ä»¥æ¯æ°ç»ï¼ï¼æ­¤æ¶ç¨äºéå¸¸æ å°åç±»æ°æ®
   * geometry.color('x', [ '#1890ff', '#5AD8A6' ]);
   *
   * // ä½¿ç¨åè°å½æ°è¿è¡é¢è²å¼çèªå®ä¹ï¼å¯ä»¥ä½¿ç¨å¤ä¸ªå­æ®µä½¿ç¨ã*å·è¿æ¥
   * geometry.color('x', (xVal) => {
   *   if (xVal === 'a') {
   *     return 'red';
   *   }
   *   return 'blue';
   * });
   *
   * // æå®é¢è²çæ¸åè·¯å¾ï¼ç¨äºæ å°è¿ç»­çæ°æ®
   * geometry.color('x', '#BAE7FF-#1890FF-#0050B3');
   * ```
   *
   * @param field åä¸é¢è²æ å°çæ°æ®å­æ®µï¼å¤ä¸ªå­æ®µä½¿ç¨ '*' è¿æ¥ç¬¦è¿è¡è¿æ¥ã
   * @param cfg Optional, color æ å°è§åã
   * @returns
   */
  public color(field: string, cfg?: string | string[] | ColorAttrCallback): Geometry;
  public color(field: AttributeOption | string, cfg?: string | string[] | ColorAttrCallback): Geometry {
    this.createAttrOption('color', field, cfg);

    return this;
  }

  /**
   * éç½® shape ééæ å°è§åã
   *
   * @example
   *
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   * geometry.shape({
   *   fields: [ 'x' ],
   * });
   * ```
   *
   * @param field æ å°è§åéç½®ã
   * @returns
   */
  public shape(field: AttributeOption): Geometry;
  /**
   *
   * @example
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   *
   * // æå®å¸¸éï¼å°æææ°æ®å¼æ å°å°åºå®ç shape
   * geometry.shape('circle');
   *
   * // å°æå®çå­æ®µæ å°å°åç½®ç shapes æ°ç»ä¸­
   * geometry.shape('x');
   *
   * // å°æå®çå­æ®µæ å°å°æå®ç shapes æ°ç»ä¸­
   * geometry.shape('x', [ 'circle', 'diamond', 'square' ]);
   *
   * // ä½¿ç¨åè°å½æ°è·å shapeï¼ç¨äºä¸ªæ§åç shape å®å¶ï¼å¯ä»¥æ ¹æ®åä¸ªæèå¤ä¸ªå­æ®µç¡®å®
   * geometry.shape('x', (xVal) => {
   *   if (xVal === 'a') {
   *     return 'circle';
   *   }
   *   return 'diamond';
   * });
   * ```
   *
   * @param field åä¸ shape æ å°çæ°æ®å­æ®µï¼å¤ä¸ªå­æ®µä½¿ç¨ '*' è¿æ¥ç¬¦è¿è¡è¿æ¥ã
   * @param cfg Optional, shape æ å°è§åã
   * @returns
   */
  public shape(field: string, cfg?: string[] | ShapeAttrCallback): Geometry;
  public shape(field: AttributeOption | string, cfg?: string[] | ShapeAttrCallback): Geometry {
    this.createAttrOption('shape', field, cfg);

    return this;
  }

  /**
   * éç½® size ééæ å°è§åã
   *
   * @example
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   * geometry.size({
   *   values: [ 10 ],
   * })
   * ```
   *
   * @param field æ å°è§åã
   * @returns
   */
  public size(field: AttributeOption): Geometry;
  /**
   *
   * @example
   * ```typescript
   * // data: [{ x: 'A', y: 10, color: 'red' }, { x: 'B', y: 30, color: 'yellow' }]
   *
   * // ç´æ¥æå®åç´ å¤§å°
   * geometry.size(10);
   *
   * // æå®æ å°å° size çå­æ®µï¼ä½¿ç¨åç½®çé»è®¤å¤§å°èå´ä¸º [1, 10]
   * geometry.size('x');
   *
   * // æå®æ å°å° size å­æ®µå¤ï¼è¿æä¾äº size çæå¤§å¼åæå°å¼èå´
   * geometry.size('x', [ 5, 30 ]);
   *
   * // ä½¿ç¨åè°å½æ°æ å° sizeï¼ç¨äºä¸ªæ§åç size å®å¶ï¼å¯ä»¥ä½¿ç¨å¤ä¸ªå­æ®µè¿è¡æ å°
   * geometry.size('x', (xVal) => {
   *   if (xVal === 'a') {
   *     return 10;
   *   }
   *   return 5;
   * });
   * ```
   *
   * @param field åä¸ size æ å°çæ°æ®å­æ®µï¼å¤ä¸ªå­æ®µä½¿ç¨ '*' è¿æ¥ç¬¦è¿è¡è¿æ¥ã
   * @param cfg Optional, size æ å°è§å
   * @returns
   */
  public size(field: number | string, cfg?: [number, number] | SizeAttrCallback): Geometry;
  public size(field: AttributeOption | number | string, cfg?: [number, number] | SizeAttrCallback): Geometry {
    this.createAttrOption('size', field, cfg);

    return this;
  }

  /**
   * è®¾ç½®æ°æ®è°æ´æ¹å¼ãG2 ç®ååç½®äºåç§ç±»åï¼
   * 1. dodge
   * 2. stack
   * 3. symmetric
   * 4. jitter
   *
   *
   * **Tip**
   * + å¯¹äº 'dodge' ç±»åï¼å¯ä»¥é¢å¤è¿è¡å¦ä¸å±æ§çéç½®:
   * ```typescript
   * geometry.adjust('dodge', {
   *   marginRatio: 0, // å 0 å° 1 èå´çå¼ï¼ç¸å¯¹äºæ¯ä¸ªæ±å­å®½åº¦ï¼ï¼ç¨äºæ§å¶ä¸ä¸ªåç»ä¸­æ±å­ä¹é´çé´è·
   *   dodgeBy: 'x', // è¯¥å±æ§åªå¯¹ 'dodge' ç±»åçæï¼å£°æä»¥åªä¸ªæ°æ®å­æ®µä¸ºåç»ä¾æ®
   * });
   * ```
   *
   * + å¯¹äº 'stack' ç±»åï¼å¯ä»¥é¢å¤è¿è¡å¦ä¸å±æ§çéç½®:
   * ```typescript
   * geometry.adjust('stack', {
   *   reverseOrder: false, // ç¨äºæ§å¶æ¯å¦å¯¹æ°æ®è¿è¡ååºæä½
   * });
   * ```
   *
   * @example
   * ```typescript
   * geometry.adjust('stack');
   *
   * geometry.adjust({
   *   type: 'stack',
   *   reverseOrder: false,
   * });
   *
   * // ç»åä½¿ç¨ adjust
   * geometry.adjust([ 'stack', 'dodge' ]);
   *
   * geometry.adjust([
   *   { type: 'stack' },
   *   { type: 'dodge', dodgeBy: 'x' },
   * ]);
   * ```
   *
   * @param adjustCfg æ°æ®è°æ´éç½®
   * @returns
   */
  public adjust(adjustCfg: string | string[] | AdjustOption | AdjustOption[]): Geometry {
    let adjusts: any = adjustCfg;
    if (isString(adjustCfg) || isPlainObject(adjustCfg)) {
      adjusts = [adjustCfg];
    }
    each(adjusts, (adjust, index) => {
      if (!isObject(adjust)) {
        adjusts[index] = { type: adjust };
      }
    });

    this.adjustOption = adjusts;
    return this;
  }

  /**
   * å¾å½¢æ ·å¼éç½®ã
   *
   * @example
   * ```typescript
   * // éç½®å¾å½¢æ ·å¼
   * style({
   *   lineWidth: 2,
   *   stroke: '#1890ff',
   * });
   *
   * // æ ¹æ®å·ä½çæ°æ®è¿è¡è¯¦ç»éç½®
   * style({
   *   fields: [ 'x', 'y' ], // æ°æ®å­æ®µ
   *   callback: (xVal, yVal) => {
   *     const style = { lineWidth: 2, stroke: '#1890ff' };
   *     if (xVal === 'a') {
   *       style.lineDash = [ 2, 2 ];
   *     }
   *     return style;
   *   },
   * });
   * ```
   *
   * @param field éç½®æ ·å¼å±æ§æèæ ·å¼è§åã
   * @returns
   */
  public style(field: StyleOption | LooseObject): Geometry;
  /**
   * @example
   * ```typescript
   * style('x*y', (xVal, yVal) => {
   *   const style = { lineWidth: 2, stroke: '#1890ff' };
   *   if (xVal === 'a') {
   *     style.lineDash = [ 2, 2 ];
   *   }
   *   return style;
   * });
   * ```
   *
   * @param field æ°æ®å­æ®µæèæ ·å¼éç½®è§åã
   * @param styleFunc Optional, æ ·å¼éç½®åè°å½æ°ã
   * @returns
   */
  public style(field: string, styleFunc: StyleCallback): Geometry;
  public style(field: StyleOption | LooseObject | string, styleFunc?: StyleCallback): Geometry {
    if (isString(field)) {
      const fields = parseFields(field);
      this.styleOption = {
        fields,
        callback: styleFunc,
      };
    } else {
      const { fields, callback, cfg } = field as StyleOption;
      if (fields || callback || cfg) {
        this.styleOption = field;
      } else {
        this.styleOption = {
          cfg: field,
        };
      }
    }

    return this;
  }

  /**
   * éç½® Geometry æ¾ç¤ºç tooltip åå®¹ã
   *
   * `tooltip(false)` ä»£è¡¨å³é­ tooltipã
   * `tooltip(true)` ä»£è¡¨å¼å¯ tooltipã
   *
   * Geometry é»è®¤åè®¸ tooltip å±ç¤ºï¼æä»¬å¯ä»¥ä½¿ç¨ä»¥ä¸æ¹æ³å¯¹ tooltip çå±ç¤ºåå®¹è¿è¡éç½®ï¼
   *
   * @example
   * ```typescript
   * // data: [{x: 'a', y: 10}]
   * tooltip({
   *   fields: [ 'x' ],
   * });
   * ```
   * ![](https://gw.alipayobjects.com/mdn/rms_2274c3/afts/img/A*268uQ50if60AAAAAAAAAAABkARQnAQ)
   *
   * ```typescript
   * tooltip({
   *   fields: [ 'x', 'y' ],
   * });
   * ```
   * ![](https://gw.alipayobjects.com/mdn/rms_2274c3/afts/img/A*A_ujSa8QhtcAAAAAAAAAAABkARQnAQ)
   *
   * tooltip() æ¹æ³åæ ·æ¯ææ°æ®æ å°ååè°ç¨æ³ï¼
   *
   * @example
   * ```typescript
   * chart.tooltip({
   *   itemTpl: '<li>{x}: {y}</li>',
   * });
   *
   * chart.line()
   *   .position('x*y')
   *   .tooltip({
   *     fields: [ 'x', 'y' ],
   *     callback: (x, y) => {
   *       return {
   *         x,
   *         y,
   *       };
   *     },
   *   });
   * ```
   *
   * å¶è¿åçå¼å¿é¡»ä¸ºå¯¹è±¡ï¼è¯¥å¼ä¸­çå±æ§å chart.tooltip() ç itemTpl æ¨¡æ¿ç¸å¯¹åºï¼è¿åçåéå¯ç¨äº itemTpl çå­ç¬¦ä¸²æ¨¡æ¿ã
   *
   * @param field tooltip éç½®ä¿¡æ¯ã
   * @returns
   */
  public tooltip(field: GeometryTooltipOption | boolean): Geometry;
  /**
   * @example
   * ```typescript
   * // data: [{x: 'a', y: 10}]
   *
   * // ç­åäº tooltip({ fields: [ 'x' ] })
   * tooltip('x');
   *
   * // ç­åäº tooltip({ fields: [ 'x', 'y' ] })
   * tooltip('x*y');
   *
   * // ç­åäº tooltip({ fields: [ 'x', 'y' ], callback: (x, y) => { x, y } })
   * tooltip('x*y', (x, y) => {
   *   return {
   *     x,
   *     y,
   *   };
   * });
   * ```
   *
   * @param field åä¸æ å°çå­æ®µã
   * @param cfg Optional, åè°å½æ°
   * @returns
   */
  public tooltip(field: string, cfg?: TooltipCallback): Geometry;
  public tooltip(field: GeometryTooltipOption | boolean | string, cfg?: TooltipCallback): Geometry {
    if (isString(field)) {
      const fields = parseFields(field);
      this.tooltipOption = {
        fields,
        callback: cfg,
      };
    } else {
      this.tooltipOption = field;
    }

    return this;
  }

  /**
   * Geometry å¨ç»éç½®ã
   *
   * + `animate(false)` å³é­å¨ç»
   * + `animate(true)` å¼å¯å¨ç»ï¼é»è®¤å¼å¯ã
   *
   * æä»¬å°å¨ç»åä¸ºåä¸ªåºæ¯ï¼
   * 1. appear: å¾è¡¨ç¬¬ä¸æ¬¡å è½½æ¶çå¥åºå¨ç»ï¼
   * 2. enter: å¾è¡¨ç»å¶å®æï¼åçæ´æ°åï¼äº§ççæ°å¾å½¢çè¿åºå¨ç»ï¼
   * 3. update: å¾è¡¨ç»å¶å®æï¼æ°æ®åçåæ´åï¼æç¶æåæ´çå¾å½¢çæ´æ°å¨ç»ï¼
   * 4. leave: å¾è¡¨ç»å¶å®æï¼æ°æ®åçåæ´åï¼è¢«éæ¯å¾å½¢çéæ¯å¨ç»ã
   *
   * @example
   * ```typescript
   * animate({
   *   enter: {
   *     duration: 1000, // enter å¨ç»æ§è¡æ¶é´
   *   },
   *   leave: false, // å³é­ leave éæ¯å¨ç»
   * });
   * ```
   *
   * @param cfg å¨ç»éç½®
   * @returns
   */
  public animate(cfg: AnimateOption | boolean): Geometry {
    this.animateOption = cfg;
    return this;
  }

  /**
   * Geometry label éç½®ã
   *
   * @example
   * ```ts
   * // data: [ {x: 1, y: 2, z: 'a'}, {x: 2, y: 2, z: 'b'} ]
   * // å¨æ¯ä¸ªå¾å½¢ä¸æ¾ç¤º z å­æ®µå¯¹åºçæ°å¼
   * label({
   *   fields: [ 'z' ]
   * });
   *
   * label(false); // ä¸å±ç¤º label
   *
   * // å¨æ¯ä¸ªå¾å½¢ä¸æ¾ç¤º x å­æ®µå¯¹åºçæ°å¼ï¼åæ¶éç½®ææ¬é¢è²ä¸ºçº¢è²
   * label('x', {
   *   style: {
   *     fill: 'red',
   *   },
   * })
   *
   * // ä»¥ type ç±»åç label æ¸²ææ¯ä¸ªå¾å½¢ä¸æ¾ç¤º x å­æ®µå¯¹åºçæ°å¼ï¼åæ¶æ ¼å¼åææ¬åå®¹
   * label('x', (xValue) => {
   *   return {
   *     content: xValue + '%',
   *   };
   * }, {
   *   type: 'base' // å£°æ label ç±»å
   * })
   * ```
   *
   * @param field
   * @returns label
   */
  public label(field: LabelOption | false | string): Geometry;
  public label(field: string, secondParam: GeometryLabelCfg | LabelCallback): Geometry;
  public label(field: string, secondParam: LabelCallback, thirdParam: GeometryLabelCfg): Geometry;
  public label(
    field: string | LabelOption | false,
    secondParam?: GeometryLabelCfg | LabelCallback,
    thirdParam?: GeometryLabelCfg
  ): Geometry {
    if (isString(field)) {
      const labelOption: LabelOption = {};
      const fields = parseFields(field);
      labelOption.fields = fields;
      if (isFunction(secondParam)) {
        labelOption.callback = secondParam;
      } else if (isPlainObject(secondParam)) {
        labelOption.cfg = secondParam;
      }

      if (thirdParam) {
        labelOption.cfg = thirdParam;
      }
      this.labelOption = labelOption;
    } else {
      this.labelOption = field;
    }

    return this;
  }

  /**
   * è®¾ç½®ç¶æå¯¹åºçæ ·å¼ã
   *
   * @example
   * ```ts
   * chart.interval().state({
   *   selected: {
   *     animate: { duration: 100, easing: 'easeLinear' },
   *     style: {
   *       lineWidth: 2,
   *       stroke: '#000',
   *     },
   *   },
   * });
   * ```
   *
   * å¦æå¾å½¢ shape æ¯ç±å¤ä¸ª shape ç»æï¼å³ä¸ºä¸ä¸ª G.Group å¯¹è±¡ï¼é£ä¹éå¯¹ group ä¸­çæ¯ä¸ª shapeï¼æä»¬éè¦ä½¿ç¨ä¸åæ¹å¼è¿è¡ç¶ææ ·å¼è®¾ç½®ï¼
   * å¦ææä»¬ä¸º group ä¸­çæ¯ä¸ª shape è®¾ç½®äº 'name' å±æ§(shape.set('name', 'xx'))ï¼åä»¥ 'name' ä½ä¸º keyï¼å¦åé»è®¤ä»¥ç´¢å¼å¼ï¼å³ shape ç æ·»å é¡ºåºï¼ä¸º keyã
   *
   * ```ts
   * chart.interval().shape('groupShape').state({
   *   selected: {
   *     style: {
   *       0: { lineWidth: 2 },
   *       1: { fillOpacity: 1 },
   *     }
   *   }
   * });
   * ```
   *
   * @param cfg ç¶ææ ·å¼
   */
  public state(cfg: StateOption) {
    this.stateOption = cfg;
    return this;
  }

  /**
   * ç¨äºå shape ä¸­ä¼ å¥èªå®ä¹çæ°æ®ãç®åå¯è½ä»ä»å¯è½ç¨äºå¨èªå®ä¹ shape çæ¶åï¼åèªå®ä¹ shape ä¸­ä¼ å¥èªå®ä¹çæ°æ®ï¼æ¹ä¾¿å®ç°èªå®ä¹ shape çéç½®è½åã
   *
   * @example
   * ```ts
   * chart.interval().customInfo({ yourData: 'hello, g2!' });
   * ```
   *
   * ç¶åå¨èªå®ä¹ shape çæ¶åï¼å¯ä»¥æ¿å°è¿ä¸ªä¿¡æ¯ã
   *
   * ```ts
   * registerShape('interval', 'your-shape', {
   *   draw(shapeInfo, container) {
   *     const { customInfo } = shapeInfo;
   *     console.log(customInfo); // will log { yourData: 'hello, g2!' }.
   *   }
   * });
   * ```
   *
   * @param cfg
   */
  public customInfo(cfg: any) {
    this.customOption = cfg;
    return this;
  }

  /**
   * åå§å Geomtry å®ä¾ï¼
   * åå»º [[Attribute]] and [[Scale]] å®ä¾ï¼è¿è¡æ°æ®å¤çï¼åæ¬åç»ãæ°å¼åä»¥åæ°æ®è°æ´ã
   */
  public init(cfg: InitCfg = {}) {
    this.setCfg(cfg);
    this.initAttributes(); // åå»ºå¾å½¢å±æ§

    // æ°æ®å å·¥ï¼åç» -> æ°å­å -> adjust
    this.processData(this.data);

    // è°æ´ scale
    this.adjustScale();
  }

  /**
   * Geometry æ´æ°ã
   * @param [cfg] æ´æ°çéç½®
   */
  public update(cfg: InitCfg = {}) {
    const { data, isDataChanged, isCoordinateChanged } = cfg;
    const { attributeOption, lastAttributeOption } = this;

    if (!isEqual(attributeOption, lastAttributeOption)) {
      // æ å°åçæ¹åï¼åéæ°åå»ºå¾å½¢å±æ§
      this.init(cfg);
    } else if (data && (isDataChanged || !isEqual(data, this.data))) {
      // æ°æ®åçåå
      this.setCfg(cfg);
      this.initAttributes(); // åå»ºå¾å½¢å±æ§
      this.processData(data); // æ°æ®å å·¥ï¼åç» -> æ°å­å -> adjust
    } else {
      // æå¯è½ coordinate åå
      this.setCfg(cfg);
    }

    // è°æ´ scale
    this.adjustScale();
    this.isCoordinateChanged = isCoordinateChanged;
  }

  /**
   * å°åå§æ°æ®æ å°è³å¾å½¢ç©ºé´ï¼åæ¶åå»ºå¾å½¢å¯¹è±¡ã
   */
  public paint(isUpdate: boolean = false) {
    if (this.animateOption) {
      this.animateOption = deepMix({}, getDefaultAnimateCfg(this.type, this.coordinate), this.animateOption);
    }

    this.defaultSize = undefined;
    this.elementsMap = {};
    this.elements = [];
    const offscreenGroup = this.getOffscreenGroup();
    offscreenGroup.clear();

    const beforeMappingData = this.beforeMappingData;
    const dataArray = this.beforeMapping(beforeMappingData);

    this.dataArray = new Array(dataArray.length);
    for (let i = 0; i < dataArray.length; i++) {
      const data = dataArray[i];
      this.dataArray[i] = this.mapping(data);
    }
    this.updateElements(this.dataArray, isUpdate);
    this.lastElementsMap = this.elementsMap;

    if (this.canDoGroupAnimation(isUpdate)) {
      // å¦æç¨æ·æ²¡æéç½® appear.animationï¼å°±é»è®¤èµ°æ´ä½å¨ç»
      const container = this.container;
      const type = this.type;
      const coordinate = this.coordinate;
      const animateCfg = get(this.animateOption, 'appear');
      const yScale = this.getYScale();
      const yMinPoint = coordinate.convert({
        x: 0,
        y: yScale.scale(this.getYMinValue()),
      });
      doGroupAppearAnimate(container, animateCfg, type, coordinate, yMinPoint);
    }

    // æ·»å  label
    if (this.labelOption) {
      this.renderLabels(flatten(this.dataArray) as unknown as MappingDatum[], isUpdate);
    }

    // ç¼å­ï¼ç¨äºæ´æ°
    this.lastAttributeOption = {
      ...this.attributeOption,
    };

    if (this.visible === false) {
      // ç¨æ·å¨åå§åçæ¶åå£°æ visible: false
      this.changeVisible(false);
    }
  }

  /**
   * æ¸ç©ºå½å Geometryï¼éç½®é¡¹ä»ä¿çï¼ä½æ¯åé¨åå»ºçå¯¹è±¡å¨é¨æ¸ç©ºã
   * @override
   */
  public clear() {
    const { container, geometryLabel, offscreenGroup } = this;
    if (container) {
      container.clear();
    }

    if (geometryLabel) {
      geometryLabel.clear();
    }

    if (offscreenGroup) {
      offscreenGroup.clear();
    }

    // å±æ§æ¢å¤è³åºåç¶æ
    this.scaleDefs = undefined;
    this.attributes = {};
    this.scales = {};
    this.elementsMap = {};
    this.lastElementsMap = {};
    this.elements = [];
    this.adjusts = {};
    this.dataArray = null;
    this.beforeMappingData = null;
    this.lastAttributeOption = undefined;
    this.defaultSize = undefined;
    this.idFields = [];
    this.groupScales = undefined;
    this.hasSorted = false;
    this.isCoordinateChanged = false;
  }

  /**
   * éæ¯ Geometry å®ä¾ã
   */
  public destroy() {
    this.clear();
    const container = this.container;
    container.remove(true);

    if (this.offscreenGroup) {
      this.offscreenGroup.remove(true);
      this.offscreenGroup = null;
    }

    if (this.geometryLabel) {
      this.geometryLabel.destroy();
      this.geometryLabel = null;
    }
    this.theme = undefined;
    this.shapeFactory = undefined;

    super.destroy();
  }

  /**
   * è·åå³å®åç»çå¾å½¢å±æ§å¯¹åºç scale å®ä¾ã
   * @returns
   */
  public getGroupScales(): Scale[] {
    return this.groupScales;
  }

  /**
   * æ ¹æ®åå­è·åå¾å½¢å±æ§å®ä¾ã
   */
  public getAttribute(name: string): Attribute {
    return this.attributes[name];
  }

  /** è·å x è½´å¯¹åºç scale å®ä¾ã */
  public getXScale(): Scale {
    return this.getAttribute('position').scales[0];
  }

  /** è·å y è½´å¯¹åºç scale å®ä¾ã */
  public getYScale(): Scale {
    return this.getAttribute('position').scales[1];
  }

  /**
   * è·åå³å®åç»çå¾å½¢å±æ§å®ä¾ã
   */
  public getGroupAttributes(): Attribute[] {
    const rst = [];
    each(this.attributes, (attr: Attribute) => {
      if (GROUP_ATTRS.includes(attr.type)) {
        rst.push(attr);
      }
    });
    return rst;
  }

  /** è·åå¾å½¢å±æ§é»è®¤çæ å°å¼ã */
  public getDefaultValue(attrName: string) {
    let value: any;
    const attr = this.getAttribute(attrName);
    if (attr && isEmpty(attr.scales)) {
      // è·åæ å°è³å¸¸éçå¼
      value = attr.values[0];
    }
    return value;
  }

  /**
   * è·åè¯¥æ°æ®åçå¾å½¢æ å°åå¯¹åºç Attribute å¾å½¢ç©ºé´æ°æ®ã
   * @param attr Attribute å¾å½¢å±æ§å®ä¾ã
   * @param obj éè¦è¿è¡æ å°çåå§æ°æ®ã
   * @returns
   */
  public getAttributeValues(attr: Attribute, obj: Datum) {
    const params = [];
    const scales = attr.scales;
    for (let index = 0, length = scales.length; index < length; index++) {
      const scale = scales[index];
      const field = scale.field;
      if (scale.isIdentity) {
        params.push(scale.values);
      } else {
        params.push(obj[field]);
      }
    }

    return attr.mapping(...params);
  }

  /**
   * è·åå¯¹åºç adjust å®ä¾
   * @param adjustType
   * @returns
   */
  public getAdjust(adjustType: string) {
    return this.adjusts[adjustType];
  }

  /**
   * è·å¾ coordinate å®ä¾
   * @returns
   */
  public getCoordinate() {
    return this.coordinate;
  }

  public getData() {
    return this.data;
  }

  /**
   * è·å shape å¯¹åºç marker æ ·å¼ã
   * @param shapeName shape å·ä½åå­
   * @param cfg marker ä¿¡æ¯
   * @returns
   */
  public getShapeMarker(shapeName: string, cfg: ShapeMarkerCfg): ShapeMarkerAttrs {
    const shapeFactory = this.getShapeFactory();
    return shapeFactory.getMarker(shapeName, cfg);
  }

  /**
   * æ ¹æ®ä¸å®çè§åæ¥æ¾ Geometry ç Elementsã
   *
   * ```typescript
   * getElementsBy((element) => {
   *   const data = element.getData();
   *
   *   return data.a === 'a';
   * });
   * ```
   *
   * @param condition å®ä¹æ¥æ¾è§åçåè°å½æ°ã
   * @returns
   */
  public getElementsBy(condition: (element: Element) => boolean): Element[] {
    return this.elements.filter((element) => condition(element));
  }

  /**
   * è·å Geometry çææ Elementsã
   *
   * ```typescript
   * getElements();
   * ```
   */
  public getElements() {
    return this.elements;
  }

  /**
   * è·åæ°æ®å¯¹åºçå¯ä¸ idã
   * @param data Element å¯¹åºçç»å¶æ°æ®
   * @returns
   */
  public getElementId(data: MappingDatum | MappingDatum[]) {
    data = isArray(data) ? data[0] : data;
    const originData = data[FIELD_ORIGIN];

    // å¦æç¨æ·å£°æäºä½¿ç¨åªäºå­æ®µä½ä¸º id å¼
    if (this.idFields.length) {
      let elementId = originData[this.idFields[0]];
      for (let index = 1; index < this.idFields.length; index++) {
        elementId += '-' + originData[this.idFields[index]];
      }

      return elementId;
    }

    const type = this.type;
    const xScale = this.getXScale();
    const yScale = this.getYScale();
    const xField = xScale.field || 'x';
    const yField = yScale.field || 'y';
    const yVal = originData[yField];
    let xVal;
    if (xScale.type === 'identity') {
      xVal = xScale.values[0];
    } else {
      xVal = originData[xField];
    }

    let id: string;
    if (type === 'interval' || type === 'schema') {
      id = `${xVal}`;
    } else if (type === 'line' || type === 'area' || type === 'path') {
      id = type;
    } else {
      id = `${xVal}-${yVal}`;
    }

    const groupScales = this.groupScales;

    for (let index = 0, length = groupScales.length; index < length; index++) {
      const groupScale = groupScales[index];
      const field = groupScale.field;
      id = `${id}-${originData[field]}`;
    }

    // ç¨æ·å¨è¿è¡ dodge ç±»åç adjust è°æ´çæ¶åè®¾ç½®äº dodgeBy å±æ§
    const dodgeAdjust = this.getAdjust('dodge');
    if (dodgeAdjust) {
      const dodgeBy = dodgeAdjust.dodgeBy;
      if (dodgeBy) {
        id = `${id}-${originData[dodgeBy]}`;
      }
    }

    if (this.getAdjust('jitter')) {
      id = `${id}-${data.x}-${data.y}`;
    }

    return id;
  }

  /**
   * è·åææéè¦åå»º scale çå­æ®µåç§°ã
   */
  public getScaleFields(): string[] {
    const fields = [];
    const tmpMap = new Map();
    const { attributeOption, labelOption, tooltipOption } = this;
    // è·åå¾å½¢å±æ§ä¸ç fields
    for (const attributeType in attributeOption) {
      if (attributeOption.hasOwnProperty(attributeType)) {
        const eachOpt = attributeOption[attributeType];
        if (eachOpt.fields) {
          uniq(eachOpt.fields, fields, tmpMap);
        } else if (eachOpt.values) {
          // èè size(10), shape('circle') ç­åºæ¯
          uniq(eachOpt.values, fields, tmpMap);
        }
      }
    }
    // è·å label ä¸çå­æ®µ
    if (labelOption && labelOption.fields) {
      uniq(labelOption.fields, fields, tmpMap);
    }

    // è·å tooltip ä¸çå­æ®µ
    if (isObject(tooltipOption) && tooltipOption.fields) {
      uniq(tooltipOption.fields, fields, tmpMap);
    }

    return fields;
  }

  /**
   * æ¾ç¤ºæèéè geometryã
   * @param visible
   */
  public changeVisible(visible: boolean) {
    super.changeVisible(visible);
    const elements = this.elements;
    for (let index = 0, length = elements.length; index < length; index++) {
      const element = elements[index];
      element.changeVisible(visible);
    }
    if (visible) {
      if (this.container) {
        this.container.show();
      }
      if (this.labelsContainer) {
        this.labelsContainer.show();
      }
    } else {
      if (this.container) {
        this.container.hide();
      }
      if (this.labelsContainer) {
        this.labelsContainer.hide();
      }
    }
  }

  /**
   * è·å¾ææçå­æ®µ
   */
  public getFields() {
    const uniqMap = new Map<string, boolean>();
    const fields = [];

    Object.values(this.attributeOption).forEach((cfg) => {
      const fs = cfg?.fields || [];
      fs.forEach((f) => {
        if (!uniqMap.has(f)) {
          fields.push(f);
        }
        uniqMap.set(f, true);
      });
    }, []);

    return fields;
  }

  /**
   * è·åå½åéç½®ä¸­çææåç» & åç±»çå­æ®µã
   * @return fields string[]
   */
  public getGroupFields(): string[] {
    const groupFields = [];
    const tmpMap = new Map(); // ç¨äºå»éè¿æ»¤
    for (let index = 0, length = GROUP_ATTRS.length; index < length; index++) {
      const attributeName = GROUP_ATTRS[index];
      const cfg = this.attributeOption[attributeName];
      if (cfg && cfg.fields) {
        uniq(cfg.fields, groupFields, tmpMap);
      }
    }

    return groupFields;
  }

  /**
   * è·å¾å¾å½¢ç x y å­æ®µã
   */
  public getXYFields() {
    const [x, y] = this.attributeOption.position.fields;
    return [x, y];
  }

  /**
   * x å­æ®µ
   * @returns
   */
  public getXField(): string {
    return get(this.getXYFields(), [0]);
  }

  /**
   * y å­æ®µ
   * @returns
   */
  public getYField(): string {
    return get(this.getXYFields(), [1]);
  }

  /**
   * è·åè¯¥ Geometry ä¸ææçæç shapesã
   * @returns shapes
   */
  public getShapes(): (IShape | IGroup)[] {
    return this.elements.map((element: Element) => element.shape);
  }

  /**
   * è·åèæ Groupã
   * @returns
   */
  public getOffscreenGroup() {
    if (!this.offscreenGroup) {
      const GroupCtor = this.container.getGroupBase(); // è·ååç»çæé å½æ°
      this.offscreenGroup = new GroupCtor({});
    }
    return this.offscreenGroup;
  }

  // å¯¹æ°æ®è¿è¡æåº
  public sort(mappingArray: Data[]) {
    if (!this.hasSorted) {
      // æªåçè¿æåº
      const xScale = this.getXScale();
      const xField = xScale.field;
      for (let index = 0; index < mappingArray.length; index++) {
        const itemArr = mappingArray[index];
        itemArr.sort((obj1: Datum, obj2: Datum) => {
          return xScale.translate(obj1[FIELD_ORIGIN][xField]) - xScale.translate(obj2[FIELD_ORIGIN][xField]);
        });
      }
    }

    this.hasSorted = true;
  }

  /**
   * è°æ´åº¦éèå´ãä¸»è¦éå¯¹åçå±å ä»¥åä¸äºç¹æ®éæ±ç Geometryï¼æ¯å¦ Interval ä¸çæ±ç¶å¾ Y è½´é»è®¤ä» 0 å¼å§ã
   */
  protected adjustScale() {
    const yScale = this.getYScale();
    // å¦ææ°æ®åçè¿ stack adjustï¼éè¦è°æ´ä¸ yScale çæ°æ®èå´
    if (yScale && this.getAdjust('stack')) {
      this.updateStackRange(yScale, this.beforeMappingData);
    }
  }

  /**
   * è·åå½å Geometry å¯¹åºç Shape å·¥åå®ä¾ã
   */
  protected getShapeFactory() {
    const shapeType = this.shapeType;
    if (!getShapeFactory(shapeType)) {
      return;
    }
    if (!this.shapeFactory) {
      this.shapeFactory = clone(getShapeFactory(shapeType)); // é²æ­¢å¤ä¸ª view å±äº«ä¸ä¸ª shapeFactory å®ä¾ï¼å¯¼è´ coordinate è¢«ç¯¡æ¹
    }
    // å ä¸ºè¿éç¼å­äº shapeFactoryï¼ä½æ¯å¤é¨å¯è½ä¼åæ´ coordinateï¼å¯¼è´æ æ³éæ°è®¾ç½®å° shapeFactory ä¸­
    this.shapeFactory.coordinate = this.coordinate;
    // theme åå åä¸
    this.shapeFactory.theme = this.theme.geometries[shapeType] || {};

    return this.shapeFactory;
  }

  /**
   * è·åæ¯ä¸ª Shape å¯¹åºçå³é®ç¹æ°æ®ã
   * @param obj ç»è¿åç» -> æ°å­å -> adjust è°æ´åçæ°æ®è®°å½
   * @returns
   */
  protected createShapePointsCfg(obj: Datum): S {
    const xScale = this.getXScale();
    const yScale = this.getYScale();
    const x = this.normalizeValues(obj[xScale.field], xScale);
    let y; // å­å¨æ²¡æ y çæåµ

    if (yScale) {
      y = this.normalizeValues(obj[yScale.field], yScale);
    } else {
      y = obj.y ? obj.y : 0.1;
    }

    return {
      x,
      y,
      y0: yScale ? yScale.scale(this.getYMinValue()) : undefined,
    } as S;
  }

  /**
   * åå»º Element å®ä¾ã
   * @param mappingDatum Element å¯¹åºçç»å¶æ°æ®
   * @param [isUpdate] æ¯å¦å¤äºæ´æ°é¶æ®µ
   * @returns element è¿ååå»ºç Element å®ä¾
   */
  protected createElement(mappingDatum: MappingDatum, index: number, isUpdate: boolean = false): Element {
    const { container } = this;

    const shapeCfg = this.getDrawCfg(mappingDatum); // è·åç»å¶å¾å½¢çéç½®ä¿¡æ¯
    const shapeFactory = this.getShapeFactory();

    const element = new Element({
      shapeFactory,
      container,
      offscreenGroup: this.getOffscreenGroup(),
      elementIndex: index,
    });
    element.animate = this.animateOption;
    element.geometry = this;
    element.draw(shapeCfg, isUpdate); // ç»å¶

    return element;
  }

  /**
   * è·åæ¯æ¡æ°æ®å¯¹åºçå¾å½¢ç»å¶æ°æ®ã
   * @param mappingDatum æ å°åçæ°æ®
   * @returns draw cfg
   */
  protected getDrawCfg(mappingDatum: MappingDatum): ShapeInfo {
    const originData = mappingDatum[FIELD_ORIGIN]; // åå§æ°æ®
    const cfg: ShapeInfo = {
      mappingData: mappingDatum, // æ å°åçæ°æ®
      data: originData, // åå§æ°æ®
      x: mappingDatum.x,
      y: mappingDatum.y,
      color: mappingDatum.color,
      size: mappingDatum.size,
      isInCircle: this.coordinate.isPolar,
      customInfo: this.customOption,
    };

    let shapeName = mappingDatum.shape;
    if (!shapeName && this.getShapeFactory()) {
      shapeName = this.getShapeFactory().defaultShapeType;
    }
    cfg.shape = shapeName;
    // è·åé»è®¤æ ·å¼
    const theme = this.theme.geometries[this.shapeType];
    cfg.defaultStyle = get(theme, [shapeName, 'default'], {}).style;
    if (!cfg.defaultStyle && this.getShapeFactory()) {
      cfg.defaultStyle = this.getShapeFactory().getDefaultStyle(theme);
    }

    const styleOption = this.styleOption;
    if (styleOption) {
      cfg.style = this.getStyleCfg(styleOption, originData);
    }
    if (this.generatePoints) {
      cfg.points = mappingDatum.points;
      cfg.nextPoints = mappingDatum.nextPoints;
    }

    return cfg;
  }

  protected updateElements(mappingDataArray: MappingDatum[][], isUpdate: boolean = false): void {
    const keyDatum = new Map<string, MappingDatum>();
    const keys: string[] = [];

    // ç¨æ¥ä¿æ diff åç´ ä¹å added, updated çç¸å¯¹é¡ºåº
    const keyIndex = new Map<string, number>();
    let index = 0;

    // è·å¾æ´æ°æ°æ®ææç keys
    // å°æ´æ°çæ°æ®ç¨ key ç´¢å¼
    for (let i = 0; i < mappingDataArray.length; i++) {
      const mappingData = mappingDataArray[i];
      for (let j = 0; j < mappingData.length; j++) {
        const mappingDatum = mappingData[j];
        const key = this.getElementId(mappingDatum);
        const finalKey = keyDatum.has(key) ? `${key}-${i}-${j}` : key;
        keys.push(finalKey);
        keyDatum.set(finalKey, mappingDatum);
        keyIndex.set(finalKey, index);
        index++;
      }
    }

    this.elements = new Array(index);

    const { added, updated, removed } = diff(this.lastElementsMap, keys);

    // æ°å»º element
    for (const key of added) {
      const mappingDatum = keyDatum.get(key);
      const i = keyIndex.get(key);
      const element = this.createElement(mappingDatum, i, isUpdate);
      this.elements[i] = element;
      this.elementsMap[key] = element;
      if (element.shape) {
        element.shape.set('zIndex', this.zIndexReversed ? this.elements.length - i : i);
      }
    }

    // æ´æ° element
    for (const key of updated) {
      const element = this.lastElementsMap[key];
      const mappingDatum = keyDatum.get(key);
      const currentShapeCfg = this.getDrawCfg(mappingDatum);
      const preShapeCfg = element.getModel();
      const i = keyIndex.get(key);
      if (this.isCoordinateChanged || isModelChange(currentShapeCfg, preShapeCfg)) {
        element.animate = this.animateOption;
        // éè¿ç»å¶æ°æ®çåæ´æ¥å¤æ­æ¯å¦éè¦æ´æ°ï¼å ä¸ºç¨æ·æå¯è½ä¼ä¿®æ¹å¾å½¢å±æ§æ å°
        element.update(currentShapeCfg); // æ´æ°å¯¹åºç element
      }
      this.elements[i] = element;
      this.elementsMap[key] = element;
      if (element.shape) {
        element.shape.set('zIndex', this.zIndexReversed ? this.elements.length - i : i);
      }
    }

    // å¨é¨ setZIndex ä¹åï¼åæ§è¡ sort
    if (this.container) {
      this.container.sort();
    }

    // éæ¯è¢«å é¤ç elements
    for (const key of removed) {
      const element = this.lastElementsMap[key];
      // æ´æ°å¨ç»éç½®ï¼ç¨æ·æå¯è½å¨æ´æ°ä¹åæå¯¹å¨ç»è¿è¡éç½®æä½
      element.animate = this.animateOption;
      element.destroy();
    }
  }

  /**
   * è·åæ¸²æç label ç±»åã
   */
  protected getLabelType(): string {
    const { labelOption, coordinate, type } = this;
    const { type: coordinateType, isTransposed } = coordinate;
    let labelType = get(labelOption, ['cfg', 'type']);
    if (!labelType) {
      // ç¨æ·æªå®ä¹ï¼åè¿è¡é»è®¤çé»è¾
      if (coordinateType === 'polar') {
        // æåæ ä¸ä½¿ç¨éç¨çæåæ ææ¬ï¼è½¬ç½®åä½¿ç¨é¥¼å¾
        labelType = isTransposed ? 'pie' : 'polar';
      } else if (coordinateType === 'theta') {
        // theta åæ ç³»ä¸ä½¿ç¨é¥¼å¾ææ¬
        labelType = 'pie';
      } else if (type === 'interval' || type === 'polygon') {
        labelType = 'interval';
      } else {
        labelType = 'base';
      }
    }

    return labelType;
  }

  /**
   * è·å Y è½´ä¸çæå°å¼ã
   */
  protected getYMinValue(): number {
    const yScale = this.getYScale();
    const { min, max } = yScale;
    let value: number;

    if (min >= 0) {
      value = min;
    } else if (max <= 0) {
      // å½å¼å¨ä½äºè´åºé´æ¶ï¼éè¦ä¿è¯ ymin å¨åºååï¼ä¸å¯ä¸º 0
      value = max;
    } else {
      value = 0;
    }
    return value;
  }

  // åå»ºå¾å½¢å±æ§ç¸å³çéç½®é¡¹
  protected createAttrOption(attrName: string, field: AttributeOption | string | number, cfg?) {
    if (isNil(field) || isObject(field)) {
      if (isObject(field) && isEqual(Object.keys(field), ['values'])) {
        // shape({ values: [ 'funnel' ] })
        set(this.attributeOption, attrName, {
          fields: field.values,
        });
      } else {
        set(this.attributeOption, attrName, field);
      }
    } else {
      const attrCfg: AttributeOption = {};
      if (isNumber(field)) {
        // size(3)
        attrCfg.values = [field];
      } else {
        attrCfg.fields = parseFields(field);
      }

      if (cfg) {
        if (isFunction(cfg)) {
          attrCfg.callback = cfg;
        } else {
          attrCfg.values = cfg;
        }
      }

      set(this.attributeOption, attrName, attrCfg);
    }
  }

  protected initAttributes() {
    const { attributes, attributeOption, theme, shapeType } = this;
    this.groupScales = [];
    const tmpMap = {};

    // éåæ¯ä¸ä¸ª attrOptionï¼åèªåå»º Attribute å®ä¾
    for (const attrType in attributeOption) {
      if (attributeOption.hasOwnProperty(attrType)) {
        const option: AttributeOption = attributeOption[attrType];
        if (!option) {
          return;
        }
        const attrCfg: AttributeInstanceCfg = {
          ...option,
        };
        const { callback, values, fields = [] } = attrCfg;

        // è·åæ¯ä¸ä¸ªå­æ®µå¯¹åºç scale
        const scales = fields.map((field) => {
          const scale = this.scales[field];
          if (!tmpMap[field] && GROUP_ATTRS.includes(attrType)) {
            const inferedScaleType = inferScaleType(scale, get(this.scaleDefs, field), attrType, this.type);
            if (inferedScaleType === 'cat') {
              this.groupScales.push(scale);
              tmpMap[field] = true;
            }
          }
          return scale;
        });

        attrCfg.scales = scales;

        if (attrType !== 'position' && scales.length === 1 && scales[0].type === 'identity') {
          // ç¨æ·å¨å¾å½¢ééä¸å£°æäºå¸¸éå­æ®µ color('red'), size(5)
          attrCfg.values = scales[0].values;
        } else if (!callback && !values) {
          // ç¨æ·æ²¡ææå®ä»»ä½è§åï¼åä½¿ç¨é»è®¤çæ å°è§å
          if (attrType === 'size') {
            attrCfg.values = theme.sizes;
          } else if (attrType === 'shape') {
            attrCfg.values = theme.shapes[shapeType] || [];
          } else if (attrType === 'color') {
            if (scales.length) {
              // æ ¹æ®æ°å¼ä¸ªæ°ä½¿ç¨å¯¹åºçè²æ¿
              attrCfg.values = scales[0].values.length <= 10 ? theme.colors10 : theme.colors20;
            } else {
              attrCfg.values = theme.colors10;
            }
          }
        }
        const AttributeCtor = getAttributeClass(attrType);
        attributes[attrType] = new AttributeCtor(attrCfg);
      }
    }
  }

  // å¤çæ°æ®ï¼åç» -> æ°å­å -> adjust è°æ´
  private processData(data: Data) {
    this.hasSorted = false;
    const { scales } = this.getAttribute('position');
    const categoryScales = scales.filter((scale: Scale) => scale.isCategory);

    const groupedArray = this.groupData(data); // æ°æ®åç»
    const beforeAdjust = [];
    for (let i = 0, len = groupedArray.length; i < len; i++) {
      const subData = groupedArray[i];
      const arr = [];
      for (let j = 0, subLen = subData.length; j < subLen; j++) {
        const originData = subData[j];
        const item = {};
        // tslint:disable-next-line: forin
        for (const k in originData) {
          item[k] = originData[k];
        }
        item[FIELD_ORIGIN] = originData;

        // å°åç±»æ°æ®ç¿»è¯ææ°æ®, ä»å¯¹ä½ç½®ç¸å³çåº¦éè¿è¡æ°å­åå¤ç
        for (const scale of categoryScales) {
          const field = scale.field;
          item[field] = scale.translate(item[field]);
        }
        arr.push(item);
      }
      beforeAdjust.push(arr);
    }

    const dataArray = this.adjustData(beforeAdjust); // è¿è¡ adjust æ°æ®è°æ´
    this.beforeMappingData = dataArray;

    return dataArray;
  }

  // è°æ´æ°æ®
  private adjustData(dataArray: Data[]): Data[] {
    const adjustOption = this.adjustOption;
    const { intervalPadding, dodgePadding, theme } = this;
    // å¼å®¹themeéç½®
    const maxColumnWidth = this.maxColumnWidth || theme.maxColumnWidth;
    const minColumnWidth = this.minColumnWidth || theme.minColumnWidth;
    const columnWidthRatio = this.columnWidthRatio || theme.columnWidthRatio;
    let result = dataArray;

    if (adjustOption) {
      const xScale = this.getXScale();
      const yScale = this.getYScale();
      const xField = xScale.field;
      const yField = yScale ? yScale.field : null;
      const xDimensionLength = getXDimensionLength(this.coordinate);
      const groupNum = xScale.values.length;
      // ä¼ å¥sizeè®¡ç®ç¸å³åæ°ï¼é»è®¤å®½åº¦ãæå¤§æå°å®½åº¦çº¦æ
      const sizeAttr = this.getAttribute('size');
      let defaultSize;
      if (sizeAttr) {
        defaultSize = sizeAttr.values[0];
      }
      for (let i = 0, len = adjustOption.length; i < len; i++) {
        const adjust = adjustOption[i];
        const adjustCfg: AdjustInstanceCfg = {
          xField,
          yField,
          intervalPadding,
          dodgePadding,
          xDimensionLength,
          groupNum,
          defaultSize,
          maxColumnWidth,
          minColumnWidth,
          columnWidthRatio,
          ...adjust,
        };
        const type = adjust.type;
        if (type === 'dodge') {
          const adjustNames = [];
          if (xScale.isCategory || xScale.type === 'identity') {
            adjustNames.push('x');
          } else if (!yScale) {
            adjustNames.push('y');
          } else {
            throw new Error('dodge is not support linear attribute, please use category attribute!');
          }
          adjustCfg.adjustNames = adjustNames;
          // æ¯ä¸ªåç»åæ¯æ¡æ±å­çå®½åº¦å æ¯ï¼ç¨æ·ä¸å¯æå®ï¼ç¨æ·éè¦éè¿ columnWidthRatio æå®
          // å¼å®¹themeéç½®
          adjustCfg.dodgeRatio = columnWidthRatio;
        } else if (type === 'stack') {
          const coordinate = this.coordinate;
          if (!yScale) {
            // ä¸ç»´çæåµä¸è·åé«åº¦åé»è®¤size
            adjustCfg.height = coordinate.getHeight();
            const size = this.getDefaultValue('size') || 3;
            adjustCfg.size = size;
          }
          // ä¸è¿è¡ transpose æ¶ï¼ç¨æ·åæ²¡æè®¾ç½®è¿ä¸ªåæ°æ¶ï¼é»è®¤ä»ä¸åä¸
          if (!coordinate.isTransposed && isNil(adjustCfg.reverseOrder)) {
            adjustCfg.reverseOrder = true;
          }
        }
        const adjustCtor = getAdjustClass(type);
        adjustCfg.dimValuesMap = {};
        //çædimValuesMap
        if (xScale && xScale.values) {
          adjustCfg.dimValuesMap[xScale.field] = xScale.values.map((v) => xScale.translate(v));
        }
        const adjustInstance = new adjustCtor(adjustCfg);

        result = adjustInstance.process(result);

        this.adjusts[type] = adjustInstance;
      }
    }

    return result;
  }

  // å¯¹æ°æ®è¿è¡åç»
  private groupData(data: Data): Data[] {
    const groupScales = this.getGroupScales();
    const scaleDefs = this.scaleDefs;
    const appendConditions = {};
    const groupFields = [];
    for (let index = 0; index < groupScales.length; index++) {
      const scale = groupScales[index];
      const field = scale.field;
      groupFields.push(field);
      if (get(scaleDefs, [field, 'values'])) {
        // ç¨æ·éè¿ view.scale() æ¥å£æå®äº values å±æ§
        appendConditions[field] = scaleDefs[field].values;
      }
    }

    return group(data, groupFields, appendConditions);
  }

  // æ´æ°åçå±å åçæ°æ®å¯¹åºçåº¦éèå´
  private updateStackRange(scale: Scale, dataArray: Data[]) {
    const mergeArray = flatten(dataArray);
    const field = scale.field;
    let min = scale.min;
    let max = scale.max;
    for (let index = 0; index < mergeArray.length; index++) {
      const obj = mergeArray[index];
      const tmpMin = Math.min.apply(null, obj[field]);
      const tmpMax = Math.max.apply(null, obj[field]);
      if (tmpMin < min) {
        min = tmpMin;
      }
      if (tmpMax > max) {
        max = tmpMax;
      }
    }
    const scaleDefs = this.scaleDefs;
    const cfg: LooseObject = {};
    if (min < scale.min && !get(scaleDefs, [field, 'min'])) {
      // ç¨æ·å¦æå¨åå®ä¹ä¸­å®ä¹äº minï¼åä»¥ç¨æ·å®ä¹çä¸ºå
      cfg.min = min;
    }
    if (max > scale.max && !get(scaleDefs, [field, 'max'])) {
      // ç¨æ·å¦æå¨åå®ä¹ä¸­å®ä¹äº max
      cfg.max = max;
    }

    scale.change(cfg);
  }

  // å°æ°æ®æ å°è³å¾å½¢ç©ºé´åçæä½ï¼æåºä»¥åå³é®ç¹ççæ
  private beforeMapping(beforeMappingData: Data[]) {
    // å½åå  clone æ¯å ä¸º points çå¼ç¨å³ç³»ï¼å¯¼è´æ´æ°å¤±è´¥ï¼å¯æ¯ç°å¨è²ä¼¼å¤ç°ä¸åºæ¥äºï¼æä»¥ææ¶ä¸è¿è¡ clone
    // const source = clone(beforeMappingData);
    const source = beforeMappingData;
    if (this.sortable) {
      this.sort(source);
    }
    if (this.generatePoints) {
      // éè¦çæå³é®ç¹
      for (let index = 0, length = source.length; index < length; index++) {
        const currentData = source[index];
        this.generateShapePoints(currentData);
        const nextData = source[index + 1];
        if (nextData) {
          this.generateShapePoints(nextData);
          currentData[0].nextPoints = nextData[0].points;
        }
      }
    }

    return source;
  }

  // çæ shape çå³é®ç¹
  private generateShapePoints(data: Data) {
    const shapeFactory = this.getShapeFactory();
    const shapeAttr = this.getAttribute('shape');
    for (let index = 0; index < data.length; index++) {
      const obj = data[index];
      const cfg = this.createShapePointsCfg(obj);
      const shape = shapeAttr ? this.getAttributeValues(shapeAttr, obj) : null;
      const points = shapeFactory.getShapePoints(shape, cfg);
      obj.points = points;
    }
  }

  // å°æ°æ®å½ä¸å
  private normalizeValues(values, scale) {
    let rst = [];
    if (isArray(values)) {
      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        rst.push(scale.scale(value));
      }
    } else {
      rst = scale.scale(values);
    }
    return rst;
  }

  // å°æ°æ®æ å°è³å¾å½¢ç©ºé´
  private mapping(data: Data): MappingDatum[] {
    const attributes = this.attributes;
    const mappingData = [];
    for (let index = 0; index < data.length; index++) {
      const record = data[index];
      const newRecord: MappingDatum = {
        _origin: record[FIELD_ORIGIN],
        points: record.points,
        nextPoints: record.nextPoints,
      };
      for (const k in attributes) {
        if (attributes.hasOwnProperty(k)) {
          const attr = attributes[k];
          const names = attr.names;
          const values = this.getAttributeValues(attr, record);
          if (names.length > 1) {
            // position ä¹ç±»ççæå¤ä¸ªå­æ®µçå±æ§
            for (let j = 0; j < values.length; j += 1) {
              const val = values[j];
              const name = names[j];
              newRecord[name] = isArray(val) && val.length === 1 ? val[0] : val; // åªæä¸ä¸ªå¼æ¶è¿åç¬¬ä¸ä¸ªå±æ§å¼
            }
          } else {
            // values.length === 1 çå¤æ­æ¯ä»¥ä¸æåµï¼è·åç¨æ·è®¾ç½®çå¾å½¢å±æ§å¼
            // shape('a', ['dot', 'dash']), color('a', ['red', 'yellow'])
            newRecord[names[0]] = values.length === 1 ? values[0] : values;
          }
        }
      }

      this.convertPoint(newRecord); // å° xãy è½¬æ¢æç»å¸åæ 
      mappingData.push(newRecord);
    }

    return mappingData;
  }

  // å°å½ä¸åçåæ å¼è½¬æ¢æç»å¸åæ 
  private convertPoint(mappingRecord: MappingDatum) {
    const { x, y } = mappingRecord;

    let rstX;
    let rstY;
    let obj;
    const coordinate = this.coordinate;
    if (isArray(x) && isArray(y)) {
      rstX = [];
      rstY = [];
      for (let i = 0, j = 0, xLen = x.length, yLen = y.length; i < xLen && j < yLen; i += 1, j += 1) {
        obj = coordinate.convert({
          x: x[i],
          y: y[j],
        });
        rstX.push(obj.x);
        rstY.push(obj.y);
      }
    } else if (isArray(y)) {
      rstY = [];
      for (let index = 0; index < y.length; index++) {
        const yVal = y[index];
        obj = coordinate.convert({
          x: x as number,
          y: yVal,
        });
        if (rstX && rstX !== obj.x) {
          if (!isArray(rstX)) {
            rstX = [rstX];
          }
          rstX.push(obj.x);
        } else {
          rstX = obj.x;
        }
        rstY.push(obj.y);
      }
    } else if (isArray(x)) {
      rstX = [];
      for (let index = 0; index < x.length; index++) {
        const xVal = x[index];
        obj = coordinate.convert({
          x: xVal,
          y,
        });
        if (rstY && rstY !== obj.y) {
          if (!isArray(rstY)) {
            rstY = [rstY];
          }
          rstY.push(obj.y);
        } else {
          rstY = obj.y;
        }
        rstX.push(obj.x);
      }
    } else {
      const point = coordinate.convert({
        x,
        y,
      });
      rstX = point.x;
      rstY = point.y;
    }
    mappingRecord.x = rstX;
    mappingRecord.y = rstY;
  }

  // è·å style éç½®
  private getStyleCfg(styleOption: StyleOption, originData: Datum) {
    const { fields = [], callback, cfg } = styleOption;
    if (cfg) {
      // ç¨æ·ç´æ¥éç½®æ ·å¼å±æ§
      return cfg;
    }

    const params = fields.map((field) => {
      return originData[field];
    });

    return callback(...params);
  }

  private setCfg(cfg: InitCfg) {
    const { coordinate, data, theme, scaleDefs } = cfg;
    if (coordinate) {
      this.coordinate = coordinate;
    }
    if (data) {
      this.data = data;
    }
    if (scaleDefs) {
      this.scaleDefs = scaleDefs;
      this.idFields = [];
      each(scaleDefs, (scaleDef, field) => {
        if (scaleDef && scaleDef.key) {
          this.idFields.push(field);
        }
      });
    }
    if (theme) {
      this.theme = this.userTheme ? deepMix({}, theme, this.userTheme) : theme; // æ¯æ geometry å±çº§çä¸»é¢è®¾ç½®
    }
  }

  private renderLabels(mappingArray: MappingDatum[], isUpdate: boolean = false) {
    let geometryLabel = this.geometryLabel;

    if (!geometryLabel) {
      // åæ¬¡åå»º
      const labelType = this.getLabelType();
      const GeometryLabelsCtor = getGeometryLabel(labelType);
      geometryLabel = new GeometryLabelsCtor(this);
      this.geometryLabel = geometryLabel;
    }
    geometryLabel.render(mappingArray, isUpdate);

    // å° label å element è¿è¡å³è
    const labelsMap = geometryLabel.labelsRenderer.shapesMap;
    each(this.elementsMap, (element: Element, id) => {
      const labels = filterLabelsById(id, labelsMap); // element å®ä¾å label è¿è¡ç»å®
      if (labels.length) {
        element.labelShape = labels;
        for (let i = 0; i < labels.length; i++) {
          const label = labels[i];
          const labelChildren = label.getChildren();
          for (let j = 0; j < labelChildren.length; j++) {
            const child = labelChildren[j];
            child.cfg.name = ['element', 'label'];
            child.cfg.element = element;
          }
        }
      }
    });
  }
  /**
   * æ¯å¦éè¦è¿è¡ç¾¤ç»å¥åºå¨ç»
   * è§åï¼
   * 1. å¦æåçæ´æ°ï¼åä¸è¿è¡
   * 2. å¦æç¨æ·å³é­ geometry å¨ç»ï¼åä¸è¿è¡
   * 3. å¦æç¨æ·å³é­äº appear å¨ç»ï¼åä¸è¿è¡
   * 4. å¦æç¨æ·éç½®äº appear.animationï¼åä¸è¿è¡
   */
  private canDoGroupAnimation(isUpdate: boolean) {
    return (
      !isUpdate &&
      this.animateOption &&
      (get(this.animateOption, 'appear') === undefined ||
        (get(this.animateOption, 'appear') && get(this.animateOption, ['appear', 'animation']) === undefined))
    );
  }
}
