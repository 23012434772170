import ElementSingleState from './single-state';

/**
 * @ignore
 * ä»åè®¸åä¸ª Element Active ç Action
 */
class ElementSingleActive extends ElementSingleState {
  protected stateName: string = 'active';
  /**
   * å½åäºä»¶ç¸å³ç Element Active
   */
  public active() {
    this.setState();
  }
}

export default ElementSingleActive;
