import { getCurrentElement, isElementChange } from '../util';
import StateBase from './state-base';

/**
 * åç¶æéç Action åºç±»
 * @class
 * @ignore
 */
class ElementSingleState extends StateBase {
  protected setStateEnable(enable: boolean) {
    const element = getCurrentElement(this.context);
    if (element) {
      // å¨åä¸ä¸ª element åé¨ç§»å¨ï¼å¿½è§ label å shape ä¹é´
      if (!isElementChange(this.context)) {
        return;
      }
      // ä»æ¯æåä¸ªç¶æéçåç´ ï¼åªè½ç± element è§¦å
      if (enable) {
        this.clear();
        this.setElementState(element, true);
      } else if (this.hasState(element)) {
        this.setElementState(element, false);
      }
    }
  }

  /**
   * åæ¢éä¸­ï¼åªåè®¸éä¸­ä¸ä¸ª
   */
  public toggle() {
    const element = getCurrentElement(this.context);
    if (element) {
      const hasState = this.hasState(element); // æåè·åç¶æ
      if (!hasState) {
        this.clear();
      }
      this.setElementState(element, !hasState);
    }
  }

  /**
   * åæ¶å½åæ¶é´å½±åçç¶æ
   */
  public reset() {
    this.setStateEnable(false);
  }
}

export default ElementSingleState;
