import { getActionClass } from '@antv/g2';
import { placeElementsOrdered } from '../util';

const ElementSelectedAction: any = getActionClass('element-selected');
const ElementSingleSelectedAction: any = getActionClass('element-single-selected');

/**
 * é¦æ©å¾åç´  å¤éäº¤äº
 */
export class VennElementSelected extends ElementSelectedAction {
  /**
   * åæ­¥ææåç´ çä½ç½®
   */
  protected syncElementsPos() {
    placeElementsOrdered(this.context.view);
  }

  /** æ¿æ´»å¾å½¢åç´  */
  public selected() {
    super.selected();
    this.syncElementsPos();
  }

  /** toggle å¾å½¢åç´ æ¿æ´»ç¶æ */
  public toggle() {
    super.toggle();
    this.syncElementsPos();
  }

  /** éç½® */
  public reset() {
    super.reset();
    this.syncElementsPos();
  }
}

/**
 * é¦æ©å¾åç´  åéäº¤äº
 */
export class VennElementSingleSelected extends ElementSingleSelectedAction {
  /**
   * åæ­¥ææåç´ çä½ç½®
   */
  protected syncElementsPos() {
    placeElementsOrdered(this.context.view);
  }

  /** æ¿æ´»å¾å½¢åç´  */
  public selected() {
    super.selected();
    this.syncElementsPos();
  }

  /** toggle å¾å½¢åç´ æ¿æ´»ç¶æ */
  public toggle() {
    super.toggle();
    this.syncElementsPos();
  }

  /** éç½® */
  public reset() {
    super.reset();
    this.syncElementsPos();
  }
}
