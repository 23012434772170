import { uniq, isFunction, isObject, isString, isNumber, isEmpty } from '@antv/util';
import { Params } from '../../core/adaptor';
import { ColorAttr, ShapeAttr, SizeAttr, StyleAttr, TooltipAttr, Options, Datum } from '../../types';
import { Label } from '../../types/label';
import { State } from '../../types/state';
import { transformLabel } from '../../utils';

/**
 * å¾å½¢æ å°å±æ§ï¼æç§ä¼åçº§æ¥ç
 */
export type MappingOptions = {
  /** color æ å° */
  readonly color?: ColorAttr;
  /** shape æ å° */
  readonly shape?: ShapeAttr;
  /** å¤§å°æ å°, æä¾åè°çæ¹å¼ */
  readonly size?: SizeAttr;
  /** æ ·å¼æ å° */
  readonly style?: StyleAttr;
  /** tooltip æ å° */
  readonly tooltip?: TooltipAttr;
};

/**
 * ä¸ä¸ªå¾å½¢æ å°çé»è¾ï¼çº¯ç²¹çå¾å½¢è¯­æ³
 * // TODO åç»­éè¦å¤ç adjust çéç½®ï¼ç¶åéè¿ field ä¿¡æ¯ãæ¯å¦ styleFieldï¼labelField ç­ä¸å®æ¯ä¸ä¸ªæ°ç»å½¢å¼
 */
export type Geometry = {
  /** geometry ç±»å, 'line' | 'interval' | 'point' | 'area' | 'polygon' */
  readonly type?: string;
  /** x è½´å­æ®µ */
  readonly xField?: string;
  /** y è½´å­æ®µ */
  readonly yField?: string;
  /** åç»å­æ®µ */
  readonly colorField?: string;
  /** shape çæ å°å­æ®µ */
  readonly shapeField?: string;
  /** size æ å°å­æ®µ */
  readonly sizeField?: string;
  /** style çæ å°å­æ®µ */
  readonly styleField?: string;
  /** tooltip çæ å°å­æ®µ */
  readonly tooltipFields?: string[] | false;
  /** å¶ä»åå§å­æ®µ, ç¨äº mapping åè°åæ° */
  readonly rawFields?: string[];
  /** å¾å½¢æ å°è§å */
  readonly mapping?: MappingOptions;
  /** label æ å°ééï¼å ä¸ºåå²åå å¯¼è´å®ç°ç¥æåºå« */
  readonly label?: Label;
  /** ä¸åç¶æçæ ·å¼ */
  readonly state?: State;
  /** èªå®ä¹ä¿¡æ¯ï¼ä¸è¬å¨ registerShape ä¸­ä½¿ç¨ */
  readonly customInfo?: any;
  /** geometry params */
  readonly args?: any;
};

/**
 * geometry options
 */
export type GeometryOptions = Geometry & Partial<Options>;

/**
 * è·å¾æ å°çå­æ®µåè¡¨
 * @param options
 * @param field
 */
export function getMappingField(
  o: GeometryOptions,
  field: 'color' | 'shape' | 'size' | 'style'
): {
  mappingFields: string[];
  tileMappingField: string;
} {
  const { type, xField, yField, colorField, shapeField, sizeField, styleField, rawFields = [] } = o;

  let fields = [];

  // å ä¸º color ä¼å½±åå°æ°æ®åç»ï¼ä»¥åæåçå¾å½¢æ å°ãæä»¥å¯¼è´ bar å¾ä¸­ç widthRatio è®¾ç½®ä¸çæ
  // æä»¥å¯¹äº color å­æ®µï¼ä»ä»ä¿ç colorField å¥½äºï¼ + rawFields
  // shape, size åç
  if (field === 'color') {
    fields = [colorField || xField, ...rawFields];
  } else if (field === 'shape') {
    fields = [shapeField || xField, ...rawFields];
  } else if (field === 'size') {
    fields = [sizeField || xField, ...rawFields];
  } else {
    fields = [xField, yField, colorField, shapeField, sizeField, styleField, ...rawFields];

    // ä¸å®è½æ¾å°çï¼
    const idx = ['x', 'y', 'color', 'shape', 'size', 'style'].indexOf(field);

    const f = fields[idx];
    // å é¤å½åå­æ®µ
    fields.splice(idx, 1);
    // æå¥å°ç¬¬ä¸ä¸ª
    fields.unshift(f);
  }

  const mappingFields = uniq(fields.filter((f) => !!f));
  /**
   * ä¿®å¤ line geometry æ æåæ¶ color åè°éè¯¯
   * eg:
   *   geometry.color(xField, ()=> '#f24')
   */
  const tileMappingField =
    type === 'line' && [xField, yField].includes(mappingFields.join('*')) ? '' : mappingFields.join('*');
  return {
    mappingFields,
    tileMappingField,
  };
}

/**
 * è·å¾æ å°å½æ°
 * @param mappingFields
 * @param func
 */
export function getMappingFunction(mappingFields: string[], func: (datum: Datum) => any) {
  if (!func) return undefined;
  // è¿åå½æ°
  return (...args: any[]) => {
    const params: Datum = {};

    mappingFields.forEach((f: string, idx: number) => {
      params[f] = args[idx];
    });

    // å é¤ undefined
    delete params['undefined'];

    return func(params);
  };
}

/**
 * éç¨ geometry çéç½®å¤çç adaptor
 * @param params
 */
export function geometry<O extends GeometryOptions>(params: Params<O>): Params<O> {
  const { chart, options } = params;
  const {
    type,
    args,
    mapping,
    xField,
    yField,
    colorField,
    shapeField,
    sizeField,
    tooltipFields,
    label,
    state,
    customInfo,
  } = options;

  // å¦ææ²¡æ mapping ä¿¡æ¯ï¼é£ä¹ç´æ¥è¿å
  if (!mapping) {
    return params;
  }

  const { color, shape, size, style, tooltip } = mapping;

  // åå»º geometry
  const geometry = chart[type](args).position(`${xField}*${yField}`);

  /**
   * color çå ç§æåµ
   * g.color('red');
   * g.color('color', ['red', 'blue']);
   * g.color('x', (x, y) => 'red');
   * g.color('color', (color, x, y) => 'red');
   */
  if (isString(color)) {
    colorField ? geometry.color(colorField, color) : geometry.color(color);
  } else if (isFunction(color)) {
    const { mappingFields, tileMappingField } = getMappingField(options, 'color');
    geometry.color(tileMappingField, getMappingFunction(mappingFields, color));
  } else {
    colorField && geometry.color(colorField, color);
  }

  /**
   * shape çå ç§æåµ
   * g.shape('rect');
   * g.shape('shape', ['rect', 'circle']);
   * g.shape('x*y', (x, y) => 'rect');
   * g.shape('shape*x*y', (shape, x, y) => 'rect');
   */
  if (isString(shape)) {
    shapeField ? geometry.shape(shapeField, [shape]) : geometry.shape(shape); // [shape] éè¦å¨ G2 åæ
  } else if (isFunction(shape)) {
    const { mappingFields, tileMappingField } = getMappingField(options, 'shape');
    geometry.shape(tileMappingField, getMappingFunction(mappingFields, shape));
  } else {
    shapeField && geometry.shape(shapeField, shape);
  }

  /**
   * size çå ç§æåµ
   * g.size(10);
   * g.size('size', [10, 20]);
   * g.size('x*y', (x, y) => 10);
   * g.color('size*x*y', (size, x, y) => 1-);
   */
  if (isNumber(size)) {
    sizeField ? geometry.size(sizeField, size) : geometry.size(size);
  } else if (isFunction(size)) {
    const { mappingFields, tileMappingField } = getMappingField(options, 'size');
    geometry.size(tileMappingField, getMappingFunction(mappingFields, size));
  } else {
    sizeField && geometry.size(sizeField, size);
  }

  /**
   * style çå ç§æåµ
   * g.style({ fill: 'red' });
   * g.style('x*y*color', (x, y, color) => ({ fill: 'red' }));
   */
  if (isFunction(style)) {
    const { mappingFields, tileMappingField } = getMappingField(options, 'style');
    geometry.style(tileMappingField, getMappingFunction(mappingFields, style));
  } else if (isObject(style)) {
    geometry.style(style);
  }

  /**
   * tooltip ç API
   * g.tooltip('x*y*color', (x, y, color) => ({ name, value }));
   * g.tooltip(false);
   */
  if (tooltipFields === false) {
    geometry.tooltip(false);
  } else if (!isEmpty(tooltipFields)) {
    geometry.tooltip(tooltipFields.join('*'), getMappingFunction(tooltipFields, tooltip));
  }

  /**
   * label çæ å°
   */
  if (label === false) {
    geometry.label(false);
  } else if (label) {
    const { callback, fields, ...cfg } = label;
    geometry.label({
      fields: fields || [yField],
      callback,
      cfg: transformLabel(cfg),
    });
  }

  /**
   * state ç¶ææ ·å¼
   */
  if (state) {
    geometry.state(state);
  }

  /**
   * èªå®ä¹ä¿¡æ¯
   */
  if (customInfo) {
    geometry.customInfo(customInfo);
  }

  // é²æ­¢å ä¸º x y å­æ®µåäºééæ å°ï¼å¯¼è´çæå¾ä¾
  [xField, yField]
    .filter((f: string) => f !== colorField)
    .forEach((f: string) => {
      chart.legend(f, false);
    });
  return {
    ...params,
    // geometry adaptor é¢å¤éè¦åçäºæï¼å°±æ¯å°åå»ºå¥½ç geometry è¿åå°ä¸ä¸å± adaptorï¼é²æ­¢éè¿ type æ¥è¯¢çæ¶åå®¹æè¯¯å¤
    ext: { geometry },
  };
}
