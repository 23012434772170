import { firstValue, get, isEmpty, isNil, isNumber, isString, valuesOfKey } from '@antv/util';
import { GROUP_ATTRS } from '../constant';
import { getScale, Scale, Coordinate } from '../dependents';
import { LooseObject, ScaleOption, ViewCfg } from '../interface';
import { isFullCircle } from './coordinate';

const dateRegex =
  /^(?:(?!0000)[0-9]{4}([-/.]+)(?:(?:0?[1-9]|1[0-2])\1(?:0?[1-9]|1[0-9]|2[0-8])|(?:0?[13-9]|1[0-2])\1(?:29|30)|(?:0?[13578]|1[02])\1(?:31))|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)([-/.]+)0?2\2(?:29))(\s+([01]|([01][0-9]|2[0-3])):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9]))?$/;

/**
 * è·åå­æ®µå¯¹åºæ°æ®çç±»å
 * @param field æ°æ®å­æ®µå
 * @param data æ°æ®æº
 * @returns default type è¿åå¯¹åºçæ°æ®ç±»å
 */
function getDefaultType(value: any): string {
  let type = 'linear';
  if (dateRegex.test(value)) {
    type = 'timeCat';
  } else if (isString(value)) {
    type = 'cat';
  }
  return type;
}

/**
 * using the scale type if user specified, otherwise infer the type
 */
export function inferScaleType(scale: Scale, scaleDef: ScaleOption = {}, attrType: string, geometryType: string): string {
  if (scaleDef.type) return scaleDef.type;
  // identity scale ç´æ¥è¿å
  // geometry ç±»åæ: edge,heatmap,interval,line,path,point,polygon,schema,voilinç­ï¼çè®ºä¸ï¼interval ä¸ï¼å¯ä»¥ç¨ linear scale ä½ä¸ºåç»å­æ®µ
  if (scale.type !== 'identity' && GROUP_ATTRS.includes(attrType) && ['interval'].includes(geometryType)) {
    return 'cat';
  }
  return scale.isCategory ? 'cat' : scale.type;
}

/**
 * @ignore
 * ä¸ºæå®ç `field` å­æ®µæ°æ®åå»º scale
 * @param field å­æ®µå
 * @param [data] æ°æ®éï¼å¯ä¸ºç©º
 * @param [scaleDef] åå®ä¹ï¼å¯ä¸ºç©º
 * @returns scale è¿ååå»ºç Scale å®ä¾
 */
export function createScaleByField(field: string | number, data?: LooseObject[] | [], scaleDef?: ScaleOption): Scale {
  const validData = data || [];

  if (isNumber(field) || (isNil(firstValue(validData, field)) && isEmpty(scaleDef))) {
    const Identity = getScale('identity');
    return new Identity({
      field: field.toString(),
      values: [field],
    });
  }

  const values = valuesOfKey(validData, field);

  // å¦æå·²ç»å®ä¹è¿è¿ä¸ªåº¦é (fix-later åçº¯ä»æ°æ®ä¸­ï¼æ¨æ­ scale type æ¯ä¸ç²¾ç¡®ç)
  const type = get(scaleDef, 'type', getDefaultType(values[0]));
  const ScaleCtor = getScale(type);
  return new ScaleCtor({
    field,
    values,
    ...scaleDef,
  });
}

/**
 * @ignore
 * åæ­¥ scale
 * @todo æ¯å¦å¯ä»¥éè¿ scale.update() æ¹æ³è¿è¡æ´æ°
 * @param scale éè¦åæ­¥ç scale å®ä¾
 * @param newScale åæ­¥æº Scale
 */
export function syncScale(scale: Scale, newScale: Scale) {
  if (scale.type !== 'identity' && newScale.type !== 'identity') {
    const obj = {};
    for (const k in newScale) {
      if (Object.prototype.hasOwnProperty.call(newScale, k)) {
        obj[k] = newScale[k];
      }
    }

    scale.change(obj);
  }
}

/**
 * @ignore
 * get the scale name, if alias exist, return alias, or else field
 * @param scale
 * @returns the name of field
 */
export function getName(scale: Scale): string {
  return scale.alias || scale.field;
}

/**
 * æ ¹æ® scale values å coordinate è·ååç±»é»è®¤ range
 * @param scale éè¦è·åç scale å®ä¾
 * @param coordinate coordinate å®ä¾
 * @param theme theme
 */
export function getDefaultCategoryScaleRange(
  scale: Scale,
  coordinate: Coordinate,
  theme: ViewCfg['theme']
): Scale['range'] {
  const { values } = scale;
  const count = values.length;
  let range;

  if (count === 1) {
    range = [0.5, 1]; // åªæä¸ä¸ªåç±»æ¶,é²æ­¢è®¡ç®åºç° [0.5,0.5] çç¶æ
  } else {
    let widthRatio = 1;
    let offset = 0;

    if (isFullCircle(coordinate)) {
      if (!coordinate.isTransposed) {
        range = [0, 1 - 1 / count];
      } else {
        widthRatio = get(theme, 'widthRatio.multiplePie', 1 / 1.3);
        offset = (1 / count) * widthRatio;
        range = [offset / 2, 1 - offset / 2];
      }
    } else {
      offset = 1 / count / 2; // ä¸¤è¾¹çä¸åç±»ç©ºé´çä¸å
      range = [offset, 1 - offset]; // åæ è½´æåé¢åæåé¢çä¸ç©ºç½é²æ­¢ç»å¶æ±ç¶å¾æ¶
    }
  }
  return range;
}

/**
 * @function yè½´scaleçmax
 * @param {yScale}
 */
export function getMaxScale(scale: Scale) {
  // è¿æ»¤values[]ä¸­ NaN/undefined/null ç­
  const values = scale.values.filter((item) => !isNil(item) && !isNaN(item));

  return Math.max(...values, isNil(scale.max) ? -Infinity : scale.max);
}
