import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { HeatmapOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
// registered shapes
import './shapes/circle';
import './shapes/square';

export type { HeatmapOptions };

export class Heatmap extends Plot<HeatmapOptions> {
  /**
   * è·å æ±å½¢å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<HeatmapOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'heatmap';

  /**
   * è·åç´æ¹å¾çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<HeatmapOptions> {
    return adaptor;
  }

  /**
   * è·å è²åå¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Heatmap.getDefaultOptions();
  }
}
