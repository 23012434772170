import { StyleSheetCfg } from '../../interface';

const WHITE_COLORS = {
  100: '#000',
  95: '#0D0D0D',
  85: '#262626',
  65: '#595959',
  45: '#8C8C8C',
  25: '#BFBFBF',
  15: '#D9D9D9',
  6: '#F0F0F0',
};

const BLACK_COLORS = {
  100: '#FFFFFF',
  95: '#F2F2F2',
  85: '#D9D9D9',
  65: '#A6A6A6',
  45: '#737373',
  25: '#404040',
  15: '#262626',
  6: '#0F0F0F',
};

const QUALITATIVE_10 = [
  '#5B8FF9',
  '#5AD8A6',
  '#5D7092',
  '#F6BD16',
  '#E86452',
  '#6DC8EC',
  '#945FB9',
  '#FF9845',
  '#1E9493',
  '#FF99C3',
];

const QUALITATIVE_20 = [
  '#5B8FF9',
  '#CDDDFD',
  '#5AD8A6',
  '#CDF3E4',
  '#5D7092',
  '#CED4DE',
  '#F6BD16',
  '#FCEBB9',
  '#E86452',
  '#F8D0CB',
  '#6DC8EC',
  '#D3EEF9',
  '#945FB9',
  '#DECFEA',
  '#FF9845',
  '#FFE0C7',
  '#1E9493',
  '#BBDEDE',
  '#FF99C3',
  '#FFE0ED',
];

/** åè²é¡ºåºè²æ¿ */
const SINGLE_SEQUENCE = [
  '#B8E1FF',
  '#9AC5FF',
  '#7DAAFF',
  '#5B8FF9',
  '#3D76DD',
  '#085EC0',
  '#0047A5',
  '#00318A',
  '#001D70',
];

export const createDarkStyleSheet = (cfg: StyleSheetCfg = {}) => {
  const {
    backgroundColor = '#141414',
    subColor = 'rgba(255,255,255,0.05)',
    paletteQualitative10 = QUALITATIVE_10,
    paletteQualitative20 = QUALITATIVE_20,
    paletteSemanticRed = '#F4664A',
    paletteSemanticGreen = '#30BF78',
    paletteSemanticYellow = '#FAAD14',
    paletteSequence = SINGLE_SEQUENCE,
    fontFamily = `"Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji"`,
  } = cfg;
  const { brandColor = paletteQualitative10[0] } = cfg;

  return {
    /** å¾è¡¨èæ¯è² */
    backgroundColor,
    /** ä¸»é¢è² */
    brandColor,
    /** å¾è¡¨è¾å©è² */
    subColor,
    /** åç±»è²æ¿ 1ï¼å¨æ°æ®éå°äºç­äº 10 æ¶ä½¿ç¨ */
    paletteQualitative10,
    /** åç±»è²æ¿ 2ï¼å¨æ°æ®éå¤§äº 10 æ¶ä½¿ç¨ */
    paletteQualitative20,
    /** è¯­ä¹è² */
    paletteSemanticRed,
    /** è¯­ä¹è² */
    paletteSemanticGreen,
    /** è¯­ä¹è² */
    paletteSemanticYellow,
    /** (åè²)é¡ºåºè²æ¿ */
    paletteSequence,
    /** å­ä½ */
    fontFamily,

    // -------------------- åæ è½´ --------------------
    /** åæ è½´çº¿é¢è² */
    axisLineBorderColor: BLACK_COLORS[25],
    /** åæ è½´çº¿ç²ç» */
    axisLineBorder: 1,
    /** åæ è½´çº¿ lineDash è®¾ç½® */
    axisLineDash: null,

    /** åæ è½´æ é¢é¢è² */
    axisTitleTextFillColor: BLACK_COLORS[65],
    /** åæ è½´æ é¢ææ¬å­ä½å¤§å° */
    axisTitleTextFontSize: 12,
    /** åæ è½´æ é¢ææ¬è¡é« */
    axisTitleTextLineHeight: 12,
    /** åæ è½´æ é¢ææ¬å­ä½ç²ç» */
    axisTitleTextFontWeight: 'normal',
    /** åæ è½´æ é¢è·ç¦»åæ è½´ææ¬çé´è· */
    axisTitleSpacing: 12,

    /** åæ è½´å»åº¦çº¿é¢è² */
    axisTickLineBorderColor: BLACK_COLORS[25],
    /** åæ è½´å»åº¦çº¿é¿åº¦ */
    axisTickLineLength: 4,
    /** åæ è½´å»åº¦çº¿ç²ç» */
    axisTickLineBorder: 1,

    /** åæ è½´æ¬¡å»åº¦çº¿é¢è² */
    axisSubTickLineBorderColor: BLACK_COLORS[15],
    /** åæ è½´æ¬¡å»åº¦çº¿é¿åº¦ */
    axisSubTickLineLength: 2,
    /** åæ è½´æ¬¡å»åº¦çº¿ç²ç» */
    axisSubTickLineBorder: 1,

    /** åæ è½´å»åº¦ææ¬é¢è² */
    axisLabelFillColor: BLACK_COLORS[45],
    /** åæ è½´å»åº¦ææ¬å­ä½å¤§å° */
    axisLabelFontSize: 12,
    /** åæ è½´å»åº¦ææ¬è¡é« */
    axisLabelLineHeight: 12,
    /** åæ è½´å»åº¦ææ¬å­ä½ç²ç» */
    axisLabelFontWeight: 'normal',
    /** åæ è½´å»åº¦ææ¬è·ç¦»åæ è½´çº¿çé´è· */
    axisLabelOffset: 8,

    /** åæ è½´ç½æ ¼çº¿é¢è² */
    axisGridBorderColor: BLACK_COLORS[15],
    /** åæ è½´ç½æ ¼çº¿ç²ç» */
    axisGridBorder: 1,
    /** åæ è½´ç½æ ¼çº¿èçº¿è®¾ç½® */
    axisGridLineDash: null,

    // -------------------- å¾ä¾ --------------------
    /** å¾ä¾æ é¢é¢è² */
    legendTitleTextFillColor: BLACK_COLORS[45],
    /** å¾ä¾æ é¢ææ¬å­ä½å¤§å° */
    legendTitleTextFontSize: 12,
    /** å¾ä¾æ é¢ææ¬è¡é« */
    legendTitleTextLineHeight: 21,
    /** å¾ä¾æ é¢ææ¬å­ä½ç²ç» */
    legendTitleTextFontWeight: 'normal',

    /** å¾ä¾ marker é¢è² */
    legendMarkerColor: QUALITATIVE_10[0],
    /** å¾ä¾ marker è·ç¦»å¾ä¾ææ¬çé´è· */
    legendMarkerSpacing: 8,
    /** å¾ä¾ marker é»è®¤åå¾å¤§å° */
    legendMarkerSize: 4,
    /** å¾ä¾ 'circle' marker åå¾ */
    legendCircleMarkerSize: 4,
    /** å¾ä¾ 'square' marker åå¾ */
    legendSquareMarkerSize: 4,
    /** å¾ä¾ 'line' marker åå¾ */
    legendLineMarkerSize: 5,

    /** å¾ä¾é¡¹ææ¬é¢è² */
    legendItemNameFillColor: BLACK_COLORS[65],
    /** å¾ä¾é¡¹ææ¬å­ä½å¤§å° */
    legendItemNameFontSize: 12,
    /** å¾ä¾é¡¹ææ¬è¡é« */
    legendItemNameLineHeight: 12,
    /** å¾ä¾é¡¹ç²ç» */
    legendItemNameFontWeight: 'normal',
    /** å¾ä¾é¡¹ä¹é´çæ°´å¹³é´è· */
    legendItemSpacing: 24,
    /** å¾ä¾é¡¹åç´æ¹åçé´é */
    legendItemMarginBottom: 12,
    /** å¾ä¾ä¸å¾è¡¨ç»å¾åºåçåç§»è·ç¦»  */
    legendSpacing: 16,
    /** å¾ä¾ä¸å¾è¡¨ç»å¾åºåçåç§»è·ç¦»  */
    legendPadding: [8, 8, 8, 8],
    /** æ°´å¹³å¸å±çå¾ä¾ä¸ç»å¾åºååç§»è·ç¦» */
    legendHorizontalPadding: [8, 0, 8, 0],
    /** åç´å¸å±çå¾ä¾ä¸ç»å¾åºååç§»è·ç¦» */
    legendVerticalPadding: [0, 8, 0, 8],

    // å¾ä¾åé¡µå¨
    /** å¾ä¾åé¡µå¨ marker å¤§å° */
    legendPageNavigatorMarkerSize: 12,
    /** å¾ä¾åé¡µå¨ marker å¡«åè² */
    legendPageNavigatorMarkerInactiveFillColor: BLACK_COLORS[45],
    /** å¾ä¾åé¡µå¨ marker å¡«åè²éæåº¦ */
    legendPageNavigatorMarkerInactiveFillOpacity: 0.45,
    /** å¾ä¾åé¡µå¨ marker æ¿æ´»ç¶æå¡«åè² */
    legendPageNavigatorMarkerFillColor: BLACK_COLORS[45],
    /** å¾ä¾åé¡µå¨ marker æ¿æ´»ç¶æå¡«åè²éæåº¦ */
    legendPageNavigatorMarkerFillOpacity: 1,
    /** å¾ä¾åé¡µå¨ææ¬é¢è² */
    legendPageNavigatorTextFillColor: BLACK_COLORS[65],
    /** å¾ä¾åé¡µå¨ææ¬å­ä½å¤§å° */
    legendPageNavigatorTextFontSize: 12,

    /** è¿ç»­å¾ä¾æ»åå¡«åè² */
    sliderRailFillColor: BLACK_COLORS[15],
    /** è¿ç»­å¾ä¾æ»åè¾¹æ¡ç²ç» */
    sliderRailBorder: 0,
    /** è¿ç»­å¾ä¾æ»åè¾¹æ¡é¢è² */
    sliderRailBorderColor: null,
    /** è¿ç»­å¾ä¾æ»åå®½åº¦ */
    sliderRailWidth: 100,
    /** è¿ç»­å¾ä¾æ»åé«åº¦ */
    sliderRailHeight: 12,

    /** è¿ç»­å¾ä¾ææ¬é¢è² */
    sliderLabelTextFillColor: BLACK_COLORS[45],
    /** è¿ç»­å¾ä¾ææ¬å­ä½å¤§å° */
    sliderLabelTextFontSize: 12,
    /** è¿ç»­å¾ä¾ææ¬è¡é« */
    sliderLabelTextLineHeight: 12,
    /** è¿ç»­å¾ä¾ææ¬å­ä½ç²ç» */
    sliderLabelTextFontWeight: 'normal',

    /** è¿ç»­å¾ä¾æ»åé¢è² */
    sliderHandlerFillColor: WHITE_COLORS[6],
    /** è¿ç»­å¾ä¾æ»åå®½åº¦ */
    sliderHandlerWidth: 10,
    /** è¿ç»­å¾ä¾æ»åé«åº¦ */
    sliderHandlerHeight: 14,
    /** è¿ç»­å¾ä¾æ»åè¾¹æ¡ç²ç» */
    sliderHandlerBorder: 1,
    /** è¿ç»­å¾ä¾æ»åè¾¹æ¡é¢è² */
    sliderHandlerBorderColor: WHITE_COLORS[25],

    // -------------------- Annotationï¼å¾å½¢æ æ³¨ --------------------
    /** arc å¾å½¢æ æ³¨æè¾¹é¢è² */
    annotationArcBorderColor: BLACK_COLORS[15],
    /** arc å¾å½¢æ æ³¨ç²ç» */
    annotationArcBorder: 1,

    /** line å¾å½¢æ æ³¨é¢è² */
    annotationLineBorderColor: BLACK_COLORS[25],
    /** line å¾å½¢æ æ³¨ç²ç» */
    annotationLineBorder: 1,
    /** lube å¾å½¢æ æ³¨çèçº¿é´é */
    annotationLineDash: null,

    /** text å¾å½¢æ æ³¨ææ¬é¢è² */
    annotationTextFillColor: BLACK_COLORS[65],
    /** text å¾å½¢æ æ³¨ææ¬å­ä½å¤§å° */
    annotationTextFontSize: 12,
    /** text å¾å½¢æ æ³¨ææ¬è¡é« */
    annotationTextLineHeight: 12,
    /** text å¾å½¢æ æ³¨ææ¬å­ä½ç²ç» */
    annotationTextFontWeight: 'normal',
    /** text å¾å½¢æ æ³¨ææ¬è¾¹æ¡é¢è² */
    annotationTextBorderColor: null,
    /** text å¾å½¢æ æ³¨ææ¬è¾¹æ¡ç²ç» */
    annotationTextBorder: 0,

    /** region å¾å½¢æ æ³¨å¡«åé¢è² */
    annotationRegionFillColor: BLACK_COLORS[100],
    /** region å¾å½¢æ æ³¨å¡«åé¢è²éæè² */
    annotationRegionFillOpacity: 0.06,
    /** region å¾å½¢æ æ³¨æè¾¹ç²ç» */
    annotationRegionBorder: 0,
    /** region å¾å½¢æ æ³¨æè¾¹é¢è² */
    annotationRegionBorderColor: null,

    /** dataMarker å¾å½¢æ æ³¨çº¿çé¿åº¦ */
    annotationDataMarkerLineLength: 16,

    // -------------------- Tooltip --------------------
    /** tooltip crosshairs è¾å©çº¿é¢è² */
    tooltipCrosshairsBorderColor: BLACK_COLORS[25],
    /** tooltip crosshairs è¾å©çº¿ç²ç» */
    tooltipCrosshairsBorder: 1,
    /** tooltip crosshairs è¾å©çº¿èçº¿é´é */
    tooltipCrosshairsLineDash: null,

    /** tooltip åå®¹æ¡èæ¯è² */
    tooltipContainerFillColor: '#1f1f1f',
    tooltipContainerFillOpacity: 0.95,
    /** tooltip åå®¹æ¡é´å½± */
    tooltipContainerShadow: '0px 2px 4px rgba(0,0,0,.5)',
    /** tooltip åå®¹æ¡åè§ */
    tooltipContainerBorderRadius: 3,

    /** tooltip ææ¬é¢è² */
    tooltipTextFillColor: BLACK_COLORS[65],
    /** tooltip ææ¬å­ä½å¤§å° */
    tooltipTextFontSize: 12,
    /** tooltip ææ¬è¡é« */
    tooltipTextLineHeight: 12,
    /** tooltip ææ¬å­ä½ç²ç» */
    tooltipTextFontWeight: 'bold',

    // -------------------- Geometry labels --------------------
    /** Geometry label ææ¬é¢è² */
    labelFillColor: BLACK_COLORS[65],
    labelFillColorDark: '#2c3542',
    labelFillColorLight: '#ffffff',
    /** Geometry label ææ¬å­ä½å¤§å° */
    labelFontSize: 12,
    /** Geometry label ææ¬è¡é« */
    labelLineHeight: 12,
    /** Geometry label ææ¬å­ä½ç²ç» */
    labelFontWeight: 'normal',
    /** Geometry label ææ¬æè¾¹é¢è² */
    labelBorderColor: null,
    /** Geometry label ææ¬æè¾¹ç²ç» */
    labelBorder: 0,

    /** Geometry innerLabel ææ¬é¢è² */
    innerLabelFillColor: WHITE_COLORS[100],
    /** Geometry innerLabel ææ¬å­ä½å¤§å° */
    innerLabelFontSize: 12,
    /** Geometry innerLabel ææ¬è¡é« */
    innerLabelLineHeight: 12,
    /** Geometry innerLabel ææ¬å­ä½ç²ç» */
    innerLabelFontWeight: 'normal',
    /** Geometry innerLabel ææ¬æè¾¹é¢è² */
    innerLabelBorderColor: null,
    /** Geometry innerLabel ææ¬æè¾¹ç²ç» */
    innerLabelBorder: 0,

    /** Geometry label ææ¬é¢è² */
    overflowLabelFillColor: BLACK_COLORS[65],
    overflowLabelFillColorDark: '#2c3542',
    overflowLabelFillColorLight: '#ffffff',
    /** Geometry label ææ¬å­ä½å¤§å° */
    overflowLabelFontSize: 12,
    /** Geometry label ææ¬è¡é« */
    overflowLabelLineHeight: 12,
    /** Geometry label ææ¬å­ä½ç²ç» */
    overflowLabelFontWeight: 'normal',
    /** Geometry label ææ¬æè¾¹é¢è² */
    overflowLabelBorderColor: WHITE_COLORS[100],
    /** Geometry label ææ¬æè¾¹ç²ç» */
    overflowLabelBorder: 1,

    /** Geometry label ææ¬è¿æ¥çº¿ç²ç» */
    labelLineBorder: 1,
    /** Geometry label ææ¬è¿æ¥çº¿é¢è² */
    labelLineBorderColor: BLACK_COLORS[25],

    // -------------------- Slider ç»ä»¶æ ·å¼--------------------
    /** slider æ»éé«åº¦ */
    cSliderRailHieght: 16,
    /** slider æ»éèæ¯è² */
    cSliderBackgroundFillColor: '#416180',
    /** slider æ»éèæ¯è²éæåº¦ */
    cSliderBackgroundFillOpacity: 0.05,
    /** slider æ»éåæ¯è² */
    cSliderForegroundFillColor: '#5B8FF9',
    /** slider æ»éåæ¯è²éæåº¦ */
    cSliderForegroundFillOpacity: 0.15,
    // slider handlerStyle æææ ·å¼
    /** slider ææé«åº¦ */
    cSliderHandlerHeight: 24,
    /** Slider ææå®½åº¦ */
    cSliderHandlerWidth: 10,
    /** Slider ææèæ¯è² */
    cSliderHandlerFillColor: '#F7F7F7',
    /** Slider ææèæ¯è²éæåº¦ */
    cSliderHandlerFillOpacity: 1,
    /** Slider ææé«äº®èæ¯è² */
    cSliderHandlerHighlightFillColor: '#FFF',
    /** Slider ææè¾¹æ¡è² */
    cSliderHandlerBorderColor: '#BFBFBF',
    /** Slider ææè¾¹æ¡ç²ç» */
    cSliderHandlerBorder: 1,
    /** Slider ææè¾¹æ¡åè§ */
    cSliderHandlerBorderRadius: 2,
    // slider textStyle å­ä½æ ç­¾æ ·å¼
    /** Slider å­ä½æ ç­¾é¢è² */
    cSliderTextFillColor: '#fff',
    /** Slider å­ä½æ ç­¾éæåº¦ */
    cSliderTextFillOpacity: 0.45,
    /** Slider å­ä½æ ç­¾å¤§å° */
    cSliderTextFontSize: 12,
    /** Slider å­ä½æ ç­¾è¡é« */
    cSliderTextLineHeight: 12,
    /** Slider å­ä½æ ç­¾å­é */
    cSliderTextFontWeight: 'normal',
    /** Slider å­ä½æ ç­¾æè¾¹è² */
    cSliderTextBorderColor: null,
    /** Slider å­ä½æ ç­¾æè¾¹ç²ç» */
    cSliderTextBorder: 0,

    // -------------------- Scrollbar ç»ä»¶æ ·å¼--------------------
    /** æ»å¨æ¡ æ»éå¡«åè² */
    scrollbarTrackFillColor: 'rgba(255,255,255,0.65)',
    /** æ»å¨æ¡ æ»åå¡«åè² */
    scrollbarThumbFillColor: 'rgba(0,0,0,0.35)',
    /** æ»å¨æ¡ æ»åé«äº®å¡«åè² */
    scrollbarThumbHighlightFillColor: 'rgba(0,0,0,0.45)',

    // -------------------- Geometry å¾å½¢æ ·å¼--------------------
    /** ç¹å¾å¡«åé¢è² */
    pointFillColor: QUALITATIVE_10[0],
    /** ç¹å¾å¡«åé¢è²éæåº¦ */
    pointFillOpacity: 0.95,
    /** ç¹å¾å¤§å° */
    pointSize: 4,
    /** ç¹å¾æè¾¹ç²ç» */
    pointBorder: 1,
    /** ç¹å¾æè¾¹é¢è² */
    pointBorderColor: WHITE_COLORS[100],
    /** ç¹å¾æè¾¹éæåº¦ */
    pointBorderOpacity: 1,

    /** ç¹å¾ active ç¶æä¸æè¾¹é¢è² */
    pointActiveBorderColor: BLACK_COLORS[100],

    /** ç¹å¾ selected ç¶æä¸æè¾¹ç²ç» */
    pointSelectedBorder: 2,
    /** ç¹å¾ selected ç¶æä¸æè¾¹é¢è² */
    pointSelectedBorderColor: BLACK_COLORS[100],

    /** ç¹å¾ inactive ç¶æä¸å¡«åé¢è²éæåº¦ */
    pointInactiveFillOpacity: 0.3,
    /** ç¹å¾ inactive ç¶æä¸æè¾¹éæåº¦ */
    pointInactiveBorderOpacity: 0.3,

    /** ç©ºå¿ç¹å¾å¤§å° */
    hollowPointSize: 4,
    /** ç©ºå¿ç¹å¾æè¾¹ç²ç» */
    hollowPointBorder: 1,
    /** ç©ºå¿ç¹å¾æè¾¹é¢è² */
    hollowPointBorderColor: QUALITATIVE_10[0],
    /** ç©ºå¿ç¹å¾æè¾¹éæåº¦ */
    hollowPointBorderOpacity: 0.95,
    hollowPointFillColor: WHITE_COLORS[100],

    /** ç©ºå¿ç¹å¾ active ç¶æä¸æè¾¹ç²ç» */
    hollowPointActiveBorder: 1,
    /** ç©ºå¿ç¹å¾ active ç¶æä¸æè¾¹é¢è² */
    hollowPointActiveBorderColor: BLACK_COLORS[100],
    /** ç©ºå¿ç¹å¾ active ç¶æä¸æè¾¹éæåº¦ */
    hollowPointActiveBorderOpacity: 1,

    /** ç©ºå¿ç¹å¾ selected ç¶æä¸æè¾¹ç²ç» */
    hollowPointSelectedBorder: 2,
    /** ç©ºå¿ç¹å¾ selected ç¶æä¸æè¾¹é¢è² */
    hollowPointSelectedBorderColor: BLACK_COLORS[100],
    /** ç©ºå¿ç¹å¾ selected ç¶æä¸æè¾¹éæåº¦ */
    hollowPointSelectedBorderOpacity: 1,

    /** ç©ºå¿ç¹å¾ inactive ç¶æä¸æè¾¹éæåº¦ */
    hollowPointInactiveBorderOpacity: 0.3,

    /** çº¿å¾ç²ç» */
    lineBorder: 2,
    /** çº¿å¾é¢è² */
    lineBorderColor: QUALITATIVE_10[0],
    /** çº¿å¾éæåº¦ */
    lineBorderOpacity: 1,

    /** çº¿å¾ Active ç¶æä¸ç²ç» */
    lineActiveBorder: 3,

    /** çº¿å¾ selected ç¶æä¸ç²ç» */
    lineSelectedBorder: 3,

    /** çº¿å¾ inactive ç¶æä¸éæåº¦ */
    lineInactiveBorderOpacity: 0.3,

    /** area å¡«åé¢è² */
    areaFillColor: QUALITATIVE_10[0],
    /** area å¡«åéæåº¦ */
    areaFillOpacity: 0.25,

    /** area å¨ active ç¶æä¸çå¡«åéæåº¦ */
    areaActiveFillColor: QUALITATIVE_10[0],
    areaActiveFillOpacity: 0.5,

    /** area å¨ selected ç¶æä¸çå¡«åéæåº¦ */
    areaSelectedFillColor: QUALITATIVE_10[0],
    areaSelectedFillOpacity: 0.5,

    /** area inactive ç¶æä¸å¡«åéæåº¦ */
    areaInactiveFillOpacity: 0.3,

    /** hollowArea é¢è² */
    hollowAreaBorderColor: QUALITATIVE_10[0],
    /** hollowArea è¾¹æ¡ç²ç» */
    hollowAreaBorder: 2,
    /** hollowArea è¾¹æ¡éæåº¦ */
    hollowAreaBorderOpacity: 1,

    /** hollowArea active ç¶æä¸çè¾¹æ¡ç²ç» */
    hollowAreaActiveBorder: 3,
    hollowAreaActiveBorderColor: BLACK_COLORS[100],

    /** hollowArea selected ç¶æä¸çè¾¹æ¡ç²ç» */
    hollowAreaSelectedBorder: 3,
    hollowAreaSelectedBorderColor: BLACK_COLORS[100],

    /** hollowArea inactive ç¶æä¸çè¾¹æ¡éæåº¦ */
    hollowAreaInactiveBorderOpacity: 0.3,

    /** interval å¡«åé¢è² */
    intervalFillColor: QUALITATIVE_10[0],
    /** interval å¡«åéæåº¦ */
    intervalFillOpacity: 0.95,

    /** interval active ç¶æä¸è¾¹æ¡ç²ç» */
    intervalActiveBorder: 1,
    /** interval active ç¶æä¸è¾¹æ¡é¢è² */
    intervalActiveBorderColor: BLACK_COLORS[100],
    intervalActiveBorderOpacity: 1,

    /** interval selected ç¶æä¸è¾¹æ¡ç²ç» */
    intervalSelectedBorder: 2,
    /** interval selected ç¶æä¸è¾¹æ¡é¢è² */
    intervalSelectedBorderColor: BLACK_COLORS[100],
    /** interval selected ç¶æä¸è¾¹æ¡éæåº¦ */
    intervalSelectedBorderOpacity: 1,

    /** interval inactive ç¶æä¸è¾¹æ¡éæåº¦ */
    intervalInactiveBorderOpacity: 0.3,
    /** interval inactive ç¶æä¸å¡«åéæåº¦ */
    intervalInactiveFillOpacity: 0.3,

    /** interval è¾¹æ¡ç²ç» */
    hollowIntervalBorder: 2,
    /** hollowInterval è¾¹æ¡é¢è² */
    hollowIntervalBorderColor: QUALITATIVE_10[0],
    /** hollowInterval è¾¹æ¡éæåº¦ */
    hollowIntervalBorderOpacity: 1,
    hollowIntervalFillColor: WHITE_COLORS[100],

    /** hollowInterval active ç¶æä¸è¾¹æ¡ç²ç» */
    hollowIntervalActiveBorder: 2,
    /** hollowInterval active ç¶æä¸è¾¹æ¡é¢è² */
    hollowIntervalActiveBorderColor: BLACK_COLORS[100],

    /** hollowInterval selected ç¶æä¸è¾¹æ¡ç²ç» */
    hollowIntervalSelectedBorder: 3,
    /** hollowInterval selected ç¶æä¸è¾¹æ¡é¢è² */
    hollowIntervalSelectedBorderColor: BLACK_COLORS[100],
    /** hollowInterval selected ç¶æä¸è¾¹æ¡éæåº¦ */
    hollowIntervalSelectedBorderOpacity: 1,

    /** hollowInterval inactive ç¶æä¸è¾¹æ¡éæåº¦ */
    hollowIntervalInactiveBorderOpacity: 0.3,
  };
};

export const antvDark = createDarkStyleSheet();
