import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constant';
import { ChordOptions } from './types';

export type { ChordOptions };

/**
 *  å¼¦å¾ Chord
 */
export class Chord extends Plot<ChordOptions> {
  /**
   * è·å é¢ç§¯å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<ChordOptions> {
    return DEFAULT_OPTIONS;
  }
  /** å¾è¡¨ç±»å */
  public type: string = 'chord';

  protected getDefaultOptions() {
    return Chord.getDefaultOptions();
  }

  /**
   * è·åééå¨
   */
  protected getSchemaAdaptor(): Adaptor<ChordOptions> {
    return adaptor;
  }
}
