import { View } from '../chart';
import { LooseObject } from '../interface';

export type InteractionConstructor = new (view: View, cfg: LooseObject) => Interaction;

/**
 * äº¤äºçåºç±»ã
 */
export default class Interaction {
  /** view æè chart */
  protected view: View;
  /** éç½®é¡¹ */
  protected cfg: LooseObject;

  constructor(view: View, cfg: LooseObject) {
    this.view = view;
    this.cfg = cfg;
  }

  /**
   * åå§åã
   */
  public init() {
    this.initEvents();
  }

  /**
   * ç»å®äºä»¶
   */
  protected initEvents() {}

  /**
   * éæ¯äºä»¶
   */
  protected clearEvents() {}

  /**
   * éæ¯ã
   */
  public destroy() {
    this.clearEvents();
  }
}
