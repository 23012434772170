/**
 * view ä¸­ç¼å­ scale çç±»
 */
import { deepMix, each, get, isNumber, last } from '@antv/util';
import { Scale, Coordinate } from '../../dependents';
import { Data, LooseObject, ScaleOption, ViewCfg } from '../../interface';
import { createScaleByField, syncScale, getDefaultCategoryScaleRange } from '../../util/scale';

/** @ignore */
interface ScaleMeta {
  readonly key: string;
  readonly scale: Scale;
  scaleDef: ScaleOption;
  syncKey?: string;
}

/** @ignore */
export class ScalePool {
  /** ææç scales */
  private scales = new Map<string, ScaleMeta>();
  /** éè¦åæ­¥ç scale åç»ï¼ key: scaleKeyArray */
  private syncScales = new Map<string, string[]>();

  /**
   * åå»º scale
   * @param field
   * @param data
   * @param scaleDef
   * @param key
   */
  public createScale(field: string, data: Data, scaleDef: ScaleOption, key: string): Scale {
    let finalScaleDef = scaleDef;

    const cacheScaleMeta = this.getScaleMeta(key);
    if (data.length === 0 && cacheScaleMeta) {
      // å¨æ´æ°è¿ç¨ä¸­æ°æ®åä¸ºç©ºï¼åæ¶ key å¯¹åºç scale å·²å­å¨åä¿æ scale åç±»å
      const cacheScale = cacheScaleMeta.scale;
      const cacheScaleDef: LooseObject = {
        type: cacheScale.type,
      };
      if (cacheScale.isCategory) {
        // å¦ææ¯åç±»ç±»åï¼ä¿æ values
        cacheScaleDef.values = cacheScale.values;
      }
      finalScaleDef = deepMix(cacheScaleDef, cacheScaleMeta.scaleDef, scaleDef);
    }

    const scale = createScaleByField(field, data, finalScaleDef);

    // ç¼å­èµ·æ¥
    this.cacheScale(scale, scaleDef, key);

    return scale;
  }

  /**
   * åæ­¥ scale
   */
  public sync(coordinate: Coordinate, theme: ViewCfg['theme']) {
    // å¯¹äº syncScales ä¸­æ¯ä¸ä¸ª syncKey ä¸é¢ç scale æ°ç»è¿è¡åæ­¥å¤ç
    this.syncScales.forEach((scaleKeys: string[], syncKey: string) => {
      // min, max, values, ranges
      let min = Number.MAX_SAFE_INTEGER;
      let max = Number.MIN_SAFE_INTEGER;
      const values = [];

      // 1. éåæ±å¾æå¤§æå°å¼ï¼values ç­
      each(scaleKeys, (key: string) => {
        const scale = this.getScale(key);

        max = isNumber(scale.max) ? Math.max(max, scale.max) : max;
        min = isNumber(scale.min) ? Math.min(min, scale.min) : min;

        // å»é
        each(scale.values, (v: any) => {
          if (!values.includes(v)) {
            values.push(v);
          }
        });
      });

      // 2. åæ­¥
      each(scaleKeys, (key: string) => {
        const scale = this.getScale(key);

        if (scale.isContinuous) {
          scale.change({
            min,
            max,
            values,
          });
        } else if (scale.isCategory) {
          let range = scale.range;
          const cacheScaleMeta = this.getScaleMeta(key);

          // å­å¨ value å¼ï¼ä¸ç¨æ·æ²¡æéç½® range éç½® to fix https://github.com/antvis/G2/issues/2996
          if (values && !get(cacheScaleMeta, ['scaleDef', 'range'])) {
            // æ´æ° range
            range = getDefaultCategoryScaleRange(
              deepMix({}, scale, {
                values,
              }),
              coordinate,
              theme
            );
          }
          scale.change({
            values,
            range,
          });
        }
      });
    });
  }

  /**
   * ç¼å­ä¸ä¸ª scale
   * @param scale
   * @param scaleDef
   * @param key
   */
  private cacheScale(scale: Scale, scaleDef: ScaleOption, key: string) {
    // 1. ç¼å­å° scales

    let sm = this.getScaleMeta(key);
    // å­å¨åæ´æ°ï¼åæ¶æ£æµç±»åæ¯å¦ä¸è´
    if (sm && sm.scale.type === scale.type) {
      syncScale(sm.scale, scale);
      sm.scaleDef = scaleDef;
      // æ´æ° scaleDef
    } else {
      sm = {
        key,
        scale,
        scaleDef,
      };

      this.scales.set(key, sm);
    }

    // 2. ç¼å­å° syncScalesï¼æé  Record<sync, string[]> æ°æ®ç»æ
    const syncKey = this.getSyncKey(sm);
    sm.syncKey = syncKey; // è®¾ç½® sync åæ­¥ç key

    // å ä¸ºå­å¨æ´æ° scale æºå¶ï¼æä»¥å¨ç¼å­ä¹åï¼åä»å syncScales ä¸­å»é¤ sync çç¼å­å¼ç¨
    this.removeFromSyncScales(key);

    // å­å¨ sync æ è®°æè¿è¡ sync
    if (syncKey) {
      // ä¸å­å¨è¿ä¸ª syncKeyï¼ååå»ºä¸ä¸ªç©ºæ°ç»
      let scaleKeys = this.syncScales.get(syncKey);
      if (!scaleKeys) {
        scaleKeys = [];
        this.syncScales.set(syncKey, scaleKeys);
      }
      scaleKeys.push(key);
    }
  }

  /**
   * éè¿ key è·å scale
   * @param key
   */
  public getScale(key: string): Scale {
    let scaleMeta = this.getScaleMeta(key);
    if (!scaleMeta) {
      const field = last(key.split('-'));
      const scaleKeys = this.syncScales.get(field);
      if (scaleKeys && scaleKeys.length) {
        scaleMeta = this.getScaleMeta(scaleKeys[0]);
      }
    }
    return scaleMeta && scaleMeta.scale;
  }

  /**
   * å¨ view éæ¯çæ¶åï¼å é¤ scale å®ä¾ï¼é²æ­¢åå­æ³é²
   * @param key
   */
  public deleteScale(key: string) {
    const scaleMeta = this.getScaleMeta(key);
    if (scaleMeta) {
      const { syncKey } = scaleMeta;

      const scaleKeys = this.syncScales.get(syncKey);

      // ç§»é¤åæ­¥çå³ç³»
      if (scaleKeys && scaleKeys.length) {
        const idx = scaleKeys.indexOf(key);

        if (idx !== -1) {
          scaleKeys.splice(idx, 1);
        }
      }
    }

    // å é¤ scale å®ä¾
    this.scales.delete(key);
  }

  /**
   * æ¸ç©º
   */
  public clear() {
    this.scales.clear();
    this.syncScales.clear();
  }

  /**
   * å é¤ sync scale å¼ç¨
   * @param key
   */
  private removeFromSyncScales(key: string) {
    this.syncScales.forEach((scaleKeys: string[], syncKey: string) => {
      const idx = scaleKeys.indexOf(key);

      if (idx !== -1) {
        scaleKeys.splice(idx, 1);

        // å é¤ç©ºæ°ç»å¼
        if (scaleKeys.length === 0) {
          this.syncScales.delete(syncKey);
        }

        return false; // è·³åºå¾ªç¯
      }
    });
  }

  /**
   * get sync key
   * @param sm
   */
  private getSyncKey(sm: ScaleMeta): string {
    const { scale, scaleDef } = sm;
    const { field } = scale;
    const sync = get(scaleDef, ['sync']);

    // å¦æ sync = trueï¼åç´æ¥ä½¿ç¨å­æ®µåä½ä¸º syncKey
    return sync === true ? field : sync === false ? undefined : sync;
  }

  /**
   * éè¿ key è·å scale
   * @param key
   */
  private getScaleMeta(key: string): ScaleMeta {
    return this.scales.get(key);
  }
}
