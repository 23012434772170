import ElementSingleState from './single-state';

/**
 * @ignore
 * åéç Action
 */
class ElementSingleSelected extends ElementSingleState {
  protected stateName: string = 'selected';
  /**
   * éä¸­
   */
  public selected() {
    this.setState();
  }
}

export default ElementSingleSelected;
