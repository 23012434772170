import { each } from '@antv/util';
import { ComponentOption } from '../../interface';
import View from '../view';

/** Component controller class type define */
export type ControllerCtor<O = any> = new (view: View) => Controller<O>;

/**
 * Component Controller è§èéè¦å®ä¹çåºç±»
 * 1. è§èç option è¾å¥
 * 2. ç»ä¸çä¿¡æ¯è·å API
 * 3. æç¡®å®ä¹çç»ä»¶äºä»¶ï¼åç§°ãæ°æ®ï¼
 */
export abstract class Controller<O = unknown> {
  /** æ¯å¦å¯è§ */
  public visible: boolean = true;
  protected view: View;
  /** option éç½®ï¼ä¸åç»ä»¶æèªå·±ä¸åçéç½®ç»æ */
  protected option: O;
  /** ææç component */
  protected components: ComponentOption[] = [];

  constructor(view: View) {
    this.view = view;
  }

  public abstract get name(): string;

  /**
   * init the component
   */
  public abstract init();

  /**
   * render the components
   */
  public abstract render();

  /**
   * update the components
   */
  // public abstract update();

  /**
   * do layout
   */
  public abstract layout();

  /**
   * ç»ä»¶çæ´æ°é»è¾
   *  - æ ¹æ®å­æ®µä¸ºæ è¯ï¼ä¸ºæ¯ä¸ä¸ªç»ä»¶çæä¸ä¸ª idï¼æ¾å° option ä¸­
   *  - æ´æ°çæ¶åæç§ id å»å diffï¼ç¶åå¯¹åçåå¤ç
   *  - åå»ºå¢å ç
   *  - æ´æ°å·²æç
   *  - éæ¯å é¤ç
   */
  public abstract update();

  /**
   * clear
   * @param includeOption æ¯å¦æ¸ç©º option éç½®é¡¹ï¼used in annotationï¼
   */
  public clear(includeOption?: boolean) {
    // destroy all components
    each(this.components, (co: ComponentOption) => {
      co.component.destroy();
    });

    // clear all component instance
    this.components = [];
  }

  /**
   * destroy the component
   */
  public destroy() {
    this.clear();
  }

  /**
   * get all components
   * @returns components array
   */
  public getComponents(): ComponentOption[] {
    return this.components;
  }

  /**
   * change visibility of component
   * @param visible
   */
  public changeVisible(visible: boolean) {
    if (this.visible === visible) {
      return;
    }
    this.components.forEach((co: ComponentOption) => {
      if (visible) {
        co.component.show();
      } else {
        co.component.hide();
      }
    });
    this.visible = visible;
  }
}
