import { isArray } from '@antv/util';
import { PathCommand } from '../../../dependents';
import { Point, RangePoint, ShapeVertices } from '../../../interface';
import { getSplinePath } from './path';

function isValueEmpty(value) {
  if (value) {
    return false;
  }
  return value === null || value === undefined || isNaN(value);
}

function isYNil(point: Point[] | RangePoint) {
  if (isArray(point)) {
    // ç¹æ®å¤ç area çå³é®ç¹æ°æ®ï¼å¶å³é®ç¹ç»æä¸º [{x: 0, y: 1}, {x: 0, y: 2}]
    return isValueEmpty(point[1].y);
  }
  const value = point.y;
  return isArray(value) ? isValueEmpty(value[0]) : isValueEmpty(value);
}

/**
 * @ignore
 * åå²æ°æ®ï¼ç¨äºå¤çå¨ä¸ç»ç¹æ°æ®ä¸­ï¼y å¯¹åºçæ°å¼å­å¨ null/undefined/NaN çæåµ
 * åºç¨äºæçº¿å¾ãåºåå¾ä»¥åè·¯å¾å¾
 *
 * ```typescript
 * // return [[{x: 1, y: 2}, {x: 3, y: 3}]]
 * getPathPoints([{x: 1, y: 2}, {x: 2, y: null}, {x: 3, y: 3}], true);
 * // return [[{x: 1, y: 2}], [{x: 3, y: 3}]]
 * getPathPoints([{x: 1, y: 2}, {x: 2, y: null}, {x: 3, y: 3}], false);
 * // return [[[{ x: 1, y: 10 }, { x: 2, y: 2 }], [{ x: 9, y: 34 }, { x: 1, y: 1 }]]]
 * getPathPoints([
 *   [{ x: 1, y: 10 }, { x: 2, y: 2 }],
 *   [{ x: 4, y: 2 }, { x: 8, y: NaN }],
 *   [{ x: 9, y: 34 }, { x: 1, y: 1 }],
 * ], true);
 * ```
 *
 * @param points è¦è¿è¡å¤çç¹éå
 * @param connectNulls æ¯å¦è¿æ¥ç©ºå¼æ°æ®
 * @param showSinglePoint æ¯å¦å±ç¤ºå­¤ç«ç¹
 * @returns è¿åå¤çåçç¹éå
 */
export function getPathPoints(points: ShapeVertices, connectNulls: boolean = false, showSinglePoint: boolean = true) {
  if (!points.length || (points.length === 1 && !showSinglePoint)) {
    // ç©ºæèåªæä¸ä¸ªç¹å¹¶éç½®ä¸å±ç¤ºæ¶
    return [];
  }

  if (connectNulls) {
    // å³ y å¼ä¸ºç©ºçåºæ¯
    const filtered = [];
    for (let i = 0, len = points.length; i < len; i++) {
      const point = points[i];
      if (!isYNil(point)) {
        filtered.push(point);
      }
    }
    return [filtered];
  }

  const result = [];
  let tmp = [];
  for (let i = 0, len = points.length; i < len; i++) {
    const point = points[i];
    if (isYNil(point)) {
      if (tmp.length) {
        if (!(tmp.length === 1 && !showSinglePoint)) {
          // å¦æåæ®µæ°æ®åªæä¸ä¸ªå­æ®µå¹¶ä¸ä¸éè¦å±ç¤ºæ¶åä¸å å¥
          result.push(tmp);
        }
        tmp = [];
      }
    } else {
      tmp.push(point);
    }
  }

  if (tmp.length) {
    result.push(tmp);
  }
  return result;
}

/**
 * è·åå°æç´å¾çè¾¹ç path
 * @param points
 * @returns
 */
export function getViolinPath(points: ShapeVertices): PathCommand[] {
  const path = [];
  for (let i = 0; i < points.length; i++) {
    const point = points[i] as Point;
    if (point) {
      const action = i === 0 ? 'M' : 'L';
      path.push([action, point.x, point.y]);
    }
  }
  const first = points[0] as Point;
  if (first) {
    path.push(['L', first.x, first.y]);
    path.push(['z']);
  }
  return path;
}

/**
 * è·åå°æç´å¾ å¹³æ»çè¾¹ç path
 * @param points
 * @returns
 */
export function getSmoothViolinPath(points: ShapeVertices): PathCommand[] {
  const half = points.length / 2;
  const leftPoints = [];
  const rightPoints = [];
  for (let i = 0; i < points.length; i++) {
    if (i < half) {
      leftPoints.push(points[i]);
    } else {
      rightPoints.push(points[i]);
    }
  }
  const leftPath = getSplinePath(leftPoints, false);
  const rightPath = getSplinePath(rightPoints, false);
  if (rightPoints.length) {
    leftPath.push(['L', rightPoints[0].x, rightPoints[0].y]);
  }
  rightPath.shift();
  const path = leftPath.concat(rightPath);
  if (leftPoints.length) {
    path.push(['L', leftPoints[0].x, leftPoints[0].y]);
  }
  path.push(['z']);
  return path;
}
