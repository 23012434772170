import ListState from './list-state';

/**
 * å¾ä¾é¡¹åæ¶å¾éç Action
 * @ignore
 */
class ListUnchecked extends ListState {
  protected stateName: string = 'unchecked';
  /**
   * åæ¶å¾é
   */
  public unchecked() {
    this.setState();
  }
}

export default ListUnchecked;
