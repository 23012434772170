import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { ProgressOptions } from './types';
import { adaptor } from './adaptor';
import { DEFAULT_OPTIONS } from './constants';
import { getProgressData } from './utils';

export type { ProgressOptions };

export class Progress extends Plot<ProgressOptions> {
  /**
   * è·å ä»ªè¡¨ç é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<ProgressOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'process';

  /**
   * æ´æ°æ°æ®
   * @param percent
   */
  public changeData(percent: number) {
    this.updateOption({ percent });
    this.chart.changeData(getProgressData(percent));
  }

  protected getDefaultOptions() {
    return Progress.getDefaultOptions();
  }

  /**
   * è·å è¿åº¦å¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<ProgressOptions> {
    return adaptor;
  }
}
