import { each } from '@antv/util';
import { Element } from '@antv/g2';
import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { StateName, StateCondition, StateObject } from '../..';
import { getAllElementsRecursively } from '../../utils';
import { FunnelOptions } from './types';
import { adaptor } from './adaptor';
import {
  DEFAULT_OPTIONS,
  FUNNEL_CONVERSATION as FUNNEL_CONVERSATION_FIELD,
  FUNNEL_PERCENT,
  FUNNEL_TOTAL_PERCENT,
} from './constant';

export type { FunnelOptions };

export { FUNNEL_CONVERSATION_FIELD };

export class Funnel extends Plot<FunnelOptions> {
  /** å¾è¡¨ç±»å */
  public type: string = 'funnel';

  static getDefaultOptions(): Partial<FunnelOptions> {
    return DEFAULT_OPTIONS;
  }

  // åé¨åé
  /** æ¼æ è½¬åç å­æ®µ */
  static CONVERSATION_FIELD = FUNNEL_CONVERSATION_FIELD;
  /** æ¼æ ç¾åæ¯ å­æ®µ */
  static PERCENT_FIELD = FUNNEL_PERCENT;
  /** æ¼æ æ»è½¬æ¢çç¾åæ¯ å­æ®µ */
  static TOTAL_PERCENT_FIELD = FUNNEL_TOTAL_PERCENT;

  /**
   * è·å æ¼æå¾ é»è®¤éç½®é¡¹
   */
  protected getDefaultOptions(): Partial<FunnelOptions> {
    // ç±äºä¸åæ¼æå¾ defaultOption æé¨åé»è¾ä¸åï¼æ­¤å¤ä»å¤ç core.getDefaultOptions è¦çèå´ï¼funnel ç defaulOption ä¸ºä¸åæ£é»è¾ç»ä¸åå° adaptor ç defaultOption ä¸­
    return Funnel.getDefaultOptions();
  }

  /**
   * è·å æ¼æå¾ çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<FunnelOptions> {
    return adaptor;
  }

  /**
   * è®¾ç½®ç¶æ
   * @param type ç¶æç±»åï¼æ¯æ 'active' | 'inactive' | 'selected' ä¸ç§
   * @param conditions æ¡ä»¶ï¼æ¯ææ°ç»
   * @param status æ¯å¦æ¿æ´»ï¼é»è®¤ true
   */
  public setState(type: StateName, condition: StateCondition, status: boolean = true) {
    const elements = getAllElementsRecursively(this.chart);

    each(elements, (ele: Element) => {
      if (condition(ele.getData())) {
        ele.setState(type, status);
      }
    });
  }

  /**
   * è·åç¶æ
   */
  public getStates(): StateObject[] {
    const elements = getAllElementsRecursively(this.chart);

    const stateObjects: StateObject[] = [];
    each(elements, (element: Element) => {
      const data = element.getData();
      const states = element.getStates();
      each(states, (state) => {
        stateObjects.push({ data, state, geometry: element.geometry, element });
      });
    });

    return stateObjects;
  }
}
