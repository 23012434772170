import { IG } from '../dependents';

const ENGINES: Record<string, IG> = {};

/**
 * éè¿åå­è·åæ¸²æ engine
 * @param name æ¸²æå¼æåå­
 * @returns G engine
 */
export function getEngine(name: string): IG {
  const G = ENGINES[name];

  if (!G) {
    throw new Error(`G engine '${name}' is not exist, please register it at first.`);
  }

  return G;
}

/**
 * æ³¨åæ¸²æå¼æ
 * @param name
 * @param engine
 */
export function registerEngine(name: string, engine: IG) {
  ENGINES[name] = engine;
}
