import { IGroup, IShape } from '../../dependents';
import { GAnimateCfg } from '../../interface';
import { AnimateExtraCfg } from '../interface';

import { doScaleAnimate } from './util';

/**
 * @ignore
 * å¥åºå¨ç»
 * x æ¹åççé¿
 * @param element æ§è¡å¨ç»çåç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function growInX(element: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const { coordinate, minYPoint } = cfg;
  doScaleAnimate(element, animateCfg, coordinate, minYPoint, 'x');
}

/**
 * @ignore
 * å¥åºå¨ç»
 * y è½´æ¹åä¸ççé¿
 * @param element æ§è¡å¨ç»çåç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function growInY(element: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const { coordinate, minYPoint } = cfg;
  doScaleAnimate(element, animateCfg, coordinate, minYPoint, 'y');
}

/**
 * @ignore
 * å¥åº
 * ä¸­å¿ç¹çååå¨ççé¿å¨ç»
 * @param element æ§è¡å¨ç»çåç´ 
 * @param animateCfg å¨ç»éç½®
 * @param cfg é¢å¤ä¿¡æ¯
 */
export function growInXY(element: IShape | IGroup, animateCfg: GAnimateCfg, cfg: AnimateExtraCfg) {
  const { coordinate, minYPoint } = cfg;
  doScaleAnimate(element, animateCfg, coordinate, minYPoint, 'xy');
}
