import { deepMix, each, get, isArray, isFunction, isNil, isNumber, isString, isUndefined } from '@antv/util';

import { FIELD_ORIGIN } from '../../constant';
import { Scale } from '../../dependents';
import { Datum, LabelOption, MappingDatum, Point } from '../../interface';
import { LabelCfg, LabelItem, LabelPointCfg, TextAlign } from './interface';

import { getDefaultAnimateCfg } from '../../animate';
import { getPolygonCentroid } from '../../util/graphics';

import Labels from '../../component/labels';
import Geometry from '../base';
import Element from '../element';

export type GeometryLabelConstructor = new (cfg: any) => GeometryLabel;

function avg(arr: number[]) {
  let sum = 0;
  each(arr, (value: number) => {
    sum += value;
  });
  return sum / arr.length;
}

/**
 * Geometry Label åºç±»ï¼ç¨äºçæ Geometry ä¸ææ label çéç½®é¡¹ä¿¡æ¯
 */
export default class GeometryLabel {
  /** geometry å®ä¾ */
  public readonly geometry: Geometry;
  public labelsRenderer: Labels;
  /** é»è®¤çå¸å± */
  public defaultLayout: string;

  constructor(geometry: Geometry) {
    this.geometry = geometry;
  }

  public getLabelItems(mapppingArray: MappingDatum[]): LabelItem[] {
    const items = [];
    const labelCfgs = this.getLabelCfgs(mapppingArray);
    // è·å label ç¸å³ç xï¼y çå¼ï¼è·åå·ä½ç x, yï¼é²æ­¢å­å¨æ°ç»
    each(mapppingArray, (mappingData: MappingDatum, index: number) => {
      const labelCfg = labelCfgs[index];
      if (!labelCfg || isNil(mappingData.x) || isNil(mappingData.y)) {
        items.push(null);
        return;
      }

      const labelContent = !isArray(labelCfg.content) ? [labelCfg.content] : labelCfg.content;
      labelCfg.content = labelContent;
      const total = labelContent.length;
      each(labelContent, (content, subIndex) => {
        if (isNil(content) || content === '') {
          items.push(null);
          return;
        }

        const item = {
          ...labelCfg,
          ...this.getLabelPoint(labelCfg, mappingData, subIndex),
        };
        if (!item.textAlign) {
          item.textAlign = this.getLabelAlign(item, subIndex, total);
        }

        if (item.offset <= 0) {
          item.labelLine = null;
        }

        items.push(item);
      });
    });
    return items;
  }

  public render(mapppingArray: MappingDatum[], isUpdate: boolean = false) {
    const labelItems = this.getLabelItems(mapppingArray);
    const labelsRenderer = this.getLabelsRenderer();
    const shapes = this.getGeometryShapes();
    // æ¸²æææ¬
    labelsRenderer.render(labelItems, shapes, isUpdate);
  }

  public clear() {
    const labelsRenderer = this.labelsRenderer;
    if (labelsRenderer) {
      labelsRenderer.clear();
    }
  }

  public destroy() {
    const labelsRenderer = this.labelsRenderer;
    if (labelsRenderer) {
      labelsRenderer.destroy();
    }
    this.labelsRenderer = null;
  }

  // geometry æ´æ°ä¹åï¼å¯¹åºç Coordinate ä¹ä¼æ´æ°ï¼ä¸ºäºè·åå°ææ°é²ç Coordinateï¼æä½¿ç¨æ¹æ³è·å
  public getCoordinate() {
    return this.geometry.coordinate;
  }

  /**
   * è·å label çé»è®¤éç½®
   */
  protected getDefaultLabelCfg(offset?: number, position?: string) {
    const geometry = this.geometry;
    const { type, theme } = geometry;

    if (
      type === 'polygon' ||
      (type === 'interval' && position === 'middle') ||
      (offset < 0 && !['line', 'point', 'path'].includes(type))
    ) {
      // polygon æè (interval ä¸ middle) æè offset å°äº 0 æ¶ï¼ææ¬å±ç¤ºå¨å¾å½¢åé¨ï¼å°å¶é¢è²è®¾ç½®ä¸º ç½è²
      return get(theme, 'innerLabels', {});
    }

    return get(theme, 'labels', {});
  }

  /**
   * è·åå½å label çæç»éç½®
   * @param labelCfg
   */
  protected getThemedLabelCfg(labelCfg: LabelCfg) {
    const geometry = this.geometry;
    const defaultLabelCfg = this.getDefaultLabelCfg();
    const { type, theme } = geometry;
    let themedLabelCfg;

    if (type === 'polygon' || (labelCfg.offset < 0 && !['line', 'point', 'path'].includes(type))) {
      // polygon æè offset å°äº 0 æ¶ï¼ææ¬å±ç¤ºå¨å¾å½¢åé¨ï¼å°å¶é¢è²è®¾ç½®ä¸º ç½è²
      themedLabelCfg = deepMix({}, defaultLabelCfg, theme.innerLabels, labelCfg);
    } else {
      themedLabelCfg = deepMix({}, defaultLabelCfg, theme.labels, labelCfg);
    }

    return themedLabelCfg;
  }

  /**
   * è®¾ç½® label ä½ç½®
   * @param labelPointCfg
   * @param mappingData
   * @param index
   * @param position
   */
  protected setLabelPosition(
    labelPointCfg: LabelPointCfg,
    mappingData: MappingDatum,
    index: number,
    position: string
  ) {}

  /**
   * @desc è·å label offset
   */
  protected getLabelOffset(offset: number | string): number {
    const coordinate = this.getCoordinate();
    const vector = this.getOffsetVector(offset);
    return coordinate.isTransposed ? vector[0] : vector[1];
  }

  /**
   * è·åæ¯ä¸ª label çåç§»é (ç¢é)
   * @param labelCfg
   * @param index
   * @param total
   * @return {Point} offsetPoint
   */
  protected getLabelOffsetPoint(labelCfg: LabelCfg, index: number, total: number): Point {
    const offset = labelCfg.offset;
    const coordinate = this.getCoordinate();
    const transposed = coordinate.isTransposed;
    const dim = transposed ? 'x' : 'y';
    const factor = transposed ? 1 : -1; // y æ¹åä¸è¶å¤§ï¼åç´ çåæ è¶å°ï¼æä»¥transposedæ¶å°ç³»æ°åæ
    const offsetPoint = {
      x: 0,
      y: 0,
    };
    if (index > 0 || total === 1) {
      // å¤æ­æ¯å¦å°äº0
      offsetPoint[dim] = offset * factor;
    } else {
      offsetPoint[dim] = offset * factor * -1;
    }
    return offsetPoint;
  }

  /**
   * è·åæ¯ä¸ª label çä½ç½®
   * @param labelCfg
   * @param mappingData
   * @param index
   * @returns label point
   */
  protected getLabelPoint(labelCfg: LabelCfg, mappingData: MappingDatum, index: number): LabelPointCfg {
    const coordinate = this.getCoordinate();
    const total = labelCfg.content.length;

    function getDimValue(value: number | number[], idx: number, isAvg = false) {
      let v = value;
      if (isArray(v)) {
        if (labelCfg.content.length === 1) {
          if (isAvg) {
            v = avg(v);
          } else {
            // å¦æä»ä¸ä¸ª labelï¼å¤ä¸ª y, åæåä¸ä¸ª y
            if (v.length <= 2) {
              v = v[(value as number[]).length - 1];
            } else {
              v = avg(v);
            }
          }
        } else {
          v = v[idx];
        }
      }
      return v;
    }

    const label = {
      content: labelCfg.content[index],
      x: 0,
      y: 0,
      start: { x: 0, y: 0 },
      color: '#fff',
    };
    const shape = isArray(mappingData.shape) ? mappingData.shape[0] : mappingData.shape;
    const isFunnel = shape === 'funnel' || shape === 'pyramid';

    // å¤è¾¹å½¢åºæ¯ï¼å¤ç¨äºå°å¾
    if (this.geometry.type === 'polygon') {
      const centroid = getPolygonCentroid(mappingData.x, mappingData.y);
      label.x = centroid[0];
      label.y = centroid[1];
    } else if (this.geometry.type === 'interval' && !isFunnel) {
      // å¯¹ç´æ¹å¾çlabel X æ¹åçä½ç½®å±ä¸­
      label.x = getDimValue(mappingData.x, index, true);
      label.y = getDimValue(mappingData.y, index);
    } else {
      label.x = getDimValue(mappingData.x, index);
      label.y = getDimValue(mappingData.y, index);
    }

    // å¤çæ¼æå¾ææ¬ä½ç½®
    if (isFunnel) {
      const nextPoints = get(mappingData, 'nextPoints');
      const points = get(mappingData, 'points');
      if (nextPoints) {
        // éæ¼æå¾åºé¨
        const point1 = coordinate.convert(points[1] as Point);
        const point2 = coordinate.convert(nextPoints[1] as Point);
        label.x = (point1.x + point2.x) / 2;
        label.y = (point1.y + point2.y) / 2;
      } else if (shape === 'pyramid') {
        const point1 = coordinate.convert(points[1] as Point);
        const point2 = coordinate.convert(points[2] as Point);
        label.x = (point1.x + point2.x) / 2;
        label.y = (point1.y + point2.y) / 2;
      }
    }

    if (labelCfg.position) {
      // å¦æ label æ¯æ position å±æ§
      this.setLabelPosition(label, mappingData, index, labelCfg.position);
    }
    const offsetPoint = this.getLabelOffsetPoint(labelCfg, index, total);
    label.start = { x: label.x, y: label.y };
    label.x += offsetPoint.x;
    label.y += offsetPoint.y;
    label.color = mappingData.color;
    return label;
  }

  /**
   * è·åææ¬çå¯¹é½æ¹å¼
   * @param item
   * @param index
   * @param total
   * @returns
   */
  protected getLabelAlign(item: LabelItem, index: number, total: number): TextAlign {
    let align: TextAlign = 'center';
    const coordinate = this.getCoordinate();
    if (coordinate.isTransposed) {
      const offset = item.offset;
      if (offset < 0) {
        align = 'right';
      } else if (offset === 0) {
        align = 'center';
      } else {
        align = 'left';
      }
      if (total > 1 && index === 0) {
        if (align === 'right') {
          align = 'left';
        } else if (align === 'left') {
          align = 'right';
        }
      }
    }
    return align;
  }

  /**
   * è·åæ¯ä¸ä¸ª label çå¯ä¸ id
   * @param mappingData label å¯¹åºçå¾å½¢çç»å¶æ°æ®
   */
  protected getLabelId(mappingData: MappingDatum) {
    const geometry = this.geometry;
    const type = geometry.type;
    const xScale = geometry.getXScale();
    const yScale = geometry.getYScale();
    const origin = mappingData[FIELD_ORIGIN]; // åå§æ°æ®

    let labelId = geometry.getElementId(mappingData);
    if (type === 'line' || type === 'area') {
      // æçº¿å¾ä»¥ååºåå¾ï¼ä¸æ¡çº¿ä¼å¯¹åºä¸ç»æ°æ®ï¼å³å¤ä¸ª labelsï¼ä¸ºäºåºåè¿äº labelsï¼éè¦å¨ line id çåæä¸å ä¸ x å­æ®µå¼
      labelId += ` ${origin[xScale.field]}`;
    } else if (type === 'path') {
      // path è·¯å¾å¾ï¼æ åºï¼æå¯è½å­å¨ç¸å x ä¸å y çæåµï¼éè¦éè¿ x y æ¥ç¡®å®å¯ä¸ id
      labelId += ` ${origin[xScale.field]}-${origin[yScale.field]}`;
    }

    return labelId;
  }

  // è·å labels ç»ä»¶
  private getLabelsRenderer() {
    const { labelsContainer, labelOption, canvasRegion, animateOption } = this.geometry;
    const coordinate = this.geometry.coordinate;

    let labelsRenderer = this.labelsRenderer;
    if (!labelsRenderer) {
      labelsRenderer = new Labels({
        container: labelsContainer,
        layout: get(labelOption, ['cfg', 'layout'], {
          type: this.defaultLayout,
        }),
      });
      this.labelsRenderer = labelsRenderer;
    }
    labelsRenderer.region = canvasRegion;
    // è®¾ç½®å¨ç»éç½®ï¼å¦æ geometry çå¨ç»å³é­äºï¼é£ä¹ label çå¨ç»ä¹ä¼å³é­
    labelsRenderer.animate = animateOption ? getDefaultAnimateCfg('label', coordinate) : false;

    return labelsRenderer;
  }

  private getLabelCfgs(mapppingArray: MappingDatum[]): LabelCfg[] {
    const geometry = this.geometry;
    const { labelOption, scales, coordinate } = geometry;
    const { fields, callback, cfg } = labelOption as LabelOption;
    const labelScales = fields.map((field: string) => {
      return scales[field];
    });

    const labelCfgs: LabelCfg[] = [];
    each(mapppingArray, (mappingData: MappingDatum, index: number) => {
      const origin = mappingData[FIELD_ORIGIN]; // åå§æ°æ®
      const originText = this.getLabelText(origin, labelScales);
      let callbackCfg;
      if (callback) {
        // å½åæ¶éç½®äº callback å cfg æ¶ï¼ä»¥ callback ä¸ºå
        const originValues = fields.map((field: string) => origin[field]);
        callbackCfg = callback(...originValues);
        if (isNil(callbackCfg)) {
          labelCfgs.push(null);
          return;
        }
      }

      let labelCfg = {
        id: this.getLabelId(mappingData), // è¿è¡ ID æ è®°
        elementId: this.geometry.getElementId(mappingData), // label å¯¹åº Element ç ID
        data: origin, // å­å¨åå§æ°æ®
        mappingData, // å­å¨æ å°åçæ°æ®,
        coordinate, // åæ ç³»
        ...cfg,
        ...callbackCfg,
      };

      if (isFunction(labelCfg.position)) {
        labelCfg.position = labelCfg.position(origin, mappingData, index);
      }

      const offset = this.getLabelOffset(labelCfg.offset || 0);
      // defaultCfg éè¦å¤æ­ innerLabels & labels
      const defaultLabelCfg = this.getDefaultLabelCfg(offset, labelCfg.position);
      // labelCfg priority: defaultCfg < cfg < callbackCfg
      labelCfg = deepMix({}, defaultLabelCfg, labelCfg);
      // è·åæç»ç offset
      labelCfg.offset = this.getLabelOffset(labelCfg.offset || 0);

      const content = labelCfg.content;
      if (isFunction(content)) {
        labelCfg.content = content(origin, mappingData, index);
      } else if (isUndefined(content)) {
        // ç¨æ·æªéç½® contentï¼åé»è®¤ä¸ºæ å°çç¬¬ä¸ä¸ªå­æ®µçå¼
        labelCfg.content = originText[0];
      }

      labelCfgs.push(labelCfg);
    });

    return labelCfgs;
  }

  private getLabelText(origin: Datum, scales: Scale[]) {
    const labelTexts = [];
    each(scales, (scale: Scale) => {
      let value = origin[scale.field];
      if (isArray(value)) {
        value = value.map((subVal) => {
          return scale.getText(subVal);
        });
      } else {
        value = scale.getText(value);
      }

      if (isNil(value) || value === '') {
        labelTexts.push(null);
      } else {
        labelTexts.push(value);
      }
    });
    return labelTexts;
  }

  private getOffsetVector(offset: number | string = 0) {
    const coordinate = this.getCoordinate();
    let actualOffset = 0;
    if (isNumber(offset)) {
      actualOffset = offset;
    }
    // å¦æ x,y ç¿»è½¬ï¼ååç§» xï¼å¦ååç§» y
    return coordinate.isTransposed ? coordinate.applyMatrix(actualOffset, 0) : coordinate.applyMatrix(0, actualOffset);
  }

  private getGeometryShapes() {
    const geometry = this.geometry;
    const shapes = {};
    each(geometry.elementsMap, (element: Element, id: string) => {
      shapes[id] = element.shape;
    });
    // å ä¸ºæå¯è½ shape è¿å¨è¿è¡å¨ç»ï¼å¯¼è´ shape.getBBox() è·åå°çå¼ä¸æ¯æç»æï¼æä»¥éè¦ä» offscreenGroup è·å
    each(geometry.getOffscreenGroup().getChildren(), (child) => {
      const id = geometry.getElementId(child.get('origin').mappingData);
      shapes[id] = child;
    });

    return shapes;
  }
}
