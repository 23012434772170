import { each, pick } from '@antv/util';
import { BBox, IGroup, IShape } from '../../../dependents';
import { getCoordinateBBox } from '../../../util/coordinate';
import { getEllipsisText } from '../../../util/text';
import { translate } from '../../../util/transform';
import { LabelItem } from '../interface';

/** limitInPlot layout çå¯ééç½® */
export interface LimitInPlotLayoutCfg {
  /** éè¦éå¶åªäºæ¹åä¸ä¸è½è¶è¿ Plot èå´ï¼é»è®¤åä¸ªæ¹åä¸é½éå¶ */
  direction?: ('top' | 'right' | 'bottom' | 'left')[];
  /** å¯ä»¥åè®¸ç margin */
  margin?: number;
  /** è¶è¿éå¶åçå¨ä½ï¼é»è®¤ translate ç§»å¨ä½ç½®; ellipsis å¯¹ text è¿è¡çç¥å±ç¤º */
  action?: 'hide' | 'translate' | 'ellipsis';
}

/**
 * @ignore
 * å° label éå¶å¨ Plot èå´åï¼å°è¶åº Plot èå´ç label å¯éæ©è¿è¡éèæèç§»å¨ä½ç½®
 * @param labels
 * @param cfg
 */
export function limitInPlot(
  items: LabelItem[],
  labels: IGroup[],
  shapes: IShape[] | IGroup[],
  region: BBox,
  cfg?: LimitInPlotLayoutCfg
) {
  if (labels.length <= 0) {
    return;
  }
  const direction = cfg?.direction || ['top', 'right', 'bottom', 'left'];
  const action = cfg?.action || 'translate';
  const margin = cfg?.margin || 0;
  const coordinate = labels[0].get('coordinate');
  if (!coordinate) {
    return;
  }
  const {
    minX: regionMinX,
    minY: regionMinY,
    maxX: regionMaxX,
    maxY: regionMaxY,
  } = getCoordinateBBox(coordinate, margin);

  each(labels, (label: IGroup) => {
    const { minX, minY, maxX, maxY, x, y, width, height } = label.getCanvasBBox();

    let finalX = x;
    let finalY = y;
    if (direction.indexOf('left') >= 0 && (minX < regionMinX || maxX < regionMinX)) {
      // è¶åºå·¦ä¾§
      finalX = regionMinX;
    }
    if (direction.indexOf('top') >= 0 && (minY < regionMinY || maxY < regionMinY)) {
      // è¶åºé¡¶é¨
      finalY = regionMinY;
    }

    if (direction.indexOf('right') >= 0) {
      if (minX > regionMaxX) {
        // æ´ä½è¶åºå³ä¾§
        finalX = regionMaxX - width;
      } else if (maxX > regionMaxX) {
        // è¶åºå³ä¾§
        finalX = finalX - (maxX - regionMaxX);
      }
    }

    if (direction.indexOf('bottom') >= 0) {
      if (minY > regionMaxY) {
        // æ´ä½è¶åºåºé¨
        finalY = regionMaxY - height;
      } else if (maxY > regionMaxY) {
        // è¶åºåºé¨
        finalY = finalY - (maxY - regionMaxY);
      }
    }

    if (finalX !== x || finalY !== y) {
      const translateX = finalX - x;
      if (action === 'translate') {
        translate(label, translateX, finalY - y);
      } else if (action === 'ellipsis') {
        const textShapes = label.findAll((shape) => shape.get('type') === 'text');
        textShapes.forEach((textShape) => {
          const style = pick(textShape.attr(), ['fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'fontVariant']);
          const textBox = textShape.getCanvasBBox();
          const text = getEllipsisText(textShape.attr('text'), textBox.width - Math.abs(translateX), style);
          textShape.attr('text', text);
        });
      } else {
        label.hide();
      }
    }
  });
}
