import { Params } from '../../core/adaptor';
import { tooltip, interaction, animation, theme, scale, annotation, legend } from '../../adaptor/common';
import { area, point, line } from '../../adaptor/geometries';
import { findGeometry, flow, transformLabel, deepAssign } from '../../utils';
import { RadarOptions } from './types';

/**
 * geometry éç½®å¤ç
 * @param params
 */
function geometry(params: Params<RadarOptions>): Params<RadarOptions> {
  const { chart, options } = params;
  const { data, lineStyle, color, point: pointOptions, area: areaOptions } = options;

  chart.data(data);

  // é·è¾¾å¾ ä¸» geometry
  const primary = deepAssign({}, params, {
    options: {
      line: {
        style: lineStyle,
        color,
      },
      point: pointOptions
        ? {
            color,
            ...pointOptions,
          }
        : pointOptions,
      area: areaOptions
        ? {
            color,
            ...areaOptions,
          }
        : areaOptions,
      // label ä¸ä¼ éç»åä¸ª geometry adaptorï¼ç± label adaptor å¤ç
      label: undefined,
    },
  });
  // å¯ Geometry
  const second = deepAssign({}, primary, {
    options: {
      tooltip: false,
    },
  });
  // ä¼åä½¿ç¨ point.state, å¶æ¬¡åä¸»åç´ ç state ç¶ææ ·å¼éç½®
  const pointState = pointOptions?.state || options.state;
  const pointParams = deepAssign({}, primary, { options: { tooltip: false, state: pointState } });

  line(primary);
  point(pointParams);
  area(second);

  return params;
}

/**
 * meta éç½®
 * @param params
 */
function meta(params: Params<RadarOptions>): Params<RadarOptions> {
  const { options } = params;
  const { xAxis, yAxis, xField, yField } = options;

  return flow(
    scale({
      [xField]: xAxis,
      [yField]: yAxis,
    })
  )(params);
}

/**
 * coord éç½®
 * @param params
 */
function coord(params: Params<RadarOptions>): Params<RadarOptions> {
  const { chart, options } = params;
  const { radius, startAngle, endAngle } = options;

  chart.coordinate('polar', {
    radius,
    startAngle,
    endAngle,
  });
  return params;
}

/**
 * axis éç½®
 * @param params
 */
function axis(params: Params<RadarOptions>): Params<RadarOptions> {
  const { chart, options } = params;
  const { xField, xAxis, yField, yAxis } = options;

  chart.axis(xField, xAxis);
  chart.axis(yField, yAxis);

  return params;
}

/**
 * label éç½®
 * @param params
 */
function label(params: Params<RadarOptions>): Params<RadarOptions> {
  const { chart, options } = params;
  const { label, yField } = options;

  const geometry = findGeometry(chart, 'line');

  if (!label) {
    geometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    geometry.label({
      fields: [yField],
      callback,
      cfg: transformLabel(cfg),
    });
  }

  return params;
}

/**
 * é·è¾¾å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<RadarOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow(geometry, meta, theme, coord, axis, legend, tooltip, label, interaction, animation, annotation())(params);
}
