import { deepMix, get, isObject, size, clamp, isNil, noop, throttle, isEmpty, valuesOfKey } from '@antv/util';
import { COMPONENT_TYPE, DIRECTION, LAYER, VIEW_LIFE_CIRCLE } from '../../constant';
import { IGroup, Slider as SliderComponent } from '../../dependents';
import { ComponentOption, Datum, Padding } from '../../interface';
import { BBox } from '../../util/bbox';
import { directionToPosition } from '../../util/direction';
import { isBetween } from '../../util/helper';
import { Writeable } from '../../util/types';
import View from '../view';
import { Controller } from './base';
import { SliderOption, SliderCfg } from '../../interface';

/**
 * @ignore
 * slider Controller
 */
export default class Slider extends Controller<SliderOption> {
  private slider: ComponentOption;
  private container: IGroup;

  private width: number;
  private start: number;
  private end: number;

  private onChangeFn: (evt: {}) => void = noop;

  constructor(view: View) {
    super(view);

    this.container = this.view.getLayer(LAYER.FORE).addGroup();
    this.onChangeFn = throttle(this.onValueChange, 20, {
      leading: true,
    }) as (evt: {}) => void;

    this.width = 0;
    this.view.on(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, this.resetMeasure);
    this.view.on(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_SIZE, this.resetMeasure);
  }

  get name(): string {
    return 'slider';
  }

  public destroy() {
    super.destroy();
    this.view.off(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_DATA, this.resetMeasure);
    this.view.off(VIEW_LIFE_CIRCLE.BEFORE_CHANGE_SIZE, this.resetMeasure);
  }

  /**
   * åå§å
   */
  public init() {}

  /**
   * æ¸²æ
   */
  public render() {
    this.option = this.view.getOptions().slider;
    const { start, end } = this.getSliderCfg();
    if (isNil(this.start)) {
      this.start = start;
      this.end = end;
    }

    const { data: viewData } = this.view.getOptions();
    if (this.option && !isEmpty(viewData)) {
      if (this.slider) {
        // exist, update
        this.slider = this.updateSlider();
      } else {
        // not exist, create
        this.slider = this.createSlider();
        // çå¬äºä»¶ï¼ç»å®äº¤äº
        this.slider.component.on('sliderchange', this.onChangeFn);
      }
    } else {
      if (this.slider) {
        // exist, destroy
        this.slider.component.destroy();
        this.slider = undefined;
      } else {
        // do nothing
      }
    }
  }

  /**
   * å¸å±
   */
  public layout() {
    if (this.option && !this.width) {
      this.measureSlider();
      setTimeout(() => {
        // åå§ç¶æä¸ç view æ°æ®è¿æ»¤
        if (!this.view.destroyed) {
          this.changeViewData(this.start, this.end);
        }
      }, 0);
    }
    if (this.slider) {
      const width = this.view.coordinateBBox.width;
      // è·åç»ä»¶ç layout bbox
      const padding: Padding = this.slider.component.get('padding') as Padding;
      const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding;
      const bboxObject = this.slider.component.getLayoutBBox();
      const bbox = new BBox(bboxObject.x, bboxObject.y, Math.min(bboxObject.width, width), bboxObject.height).expand(
        padding
      );
      const { minText, maxText } = this.getMinMaxText(this.start, this.end);

      const [x1, y1] = directionToPosition(this.view.viewBBox, bbox, DIRECTION.BOTTOM);
      const [x2, y2] = directionToPosition(this.view.coordinateBBox, bbox, DIRECTION.BOTTOM);

      // é»è®¤æ¾å¨ bottom
      this.slider.component.update({
        ...this.getSliderCfg(),
        x: x2 + paddingLeft,
        y: y1 + paddingTop,
        width: this.width,
        start: this.start,
        end: this.end,
        minText,
        maxText,
      });

      this.view.viewBBox = this.view.viewBBox.cut(bbox, DIRECTION.BOTTOM);
    }
  }

  /**
   * æ´æ°
   */
  public update() {
    // é»è¾å render ä¿æä¸è´
    this.render();
  }

  /**
   * åå»º slider ç»ä»¶
   */
  private createSlider(): ComponentOption {
    const cfg: any = this.getSliderCfg();
    // æ·»å  slider ç»ä»¶
    const component = new SliderComponent({
      container: this.container,
      ...cfg,
    });

    component.init();

    return {
      component,
      layer: LAYER.FORE,
      direction: DIRECTION.BOTTOM,
      type: COMPONENT_TYPE.SLIDER,
    };
  }

  /**
   * æ´æ°éç½®
   */
  private updateSlider() {
    let cfg = this.getSliderCfg();
    if (this.width) {
      const { minText, maxText } = this.getMinMaxText(this.start, this.end);
      cfg = { ...cfg, width: this.width, start: this.start, end: this.end, minText, maxText };
    }

    this.slider.component.update(cfg);

    return this.slider;
  }

  /**
   * è¿è¡æµéæä½
   */
  private measureSlider() {
    const { width } = this.getSliderCfg();

    this.width = width;
  }

  /**
   * æ¸é¤æµé
   */
  private resetMeasure = () => {
    this.clear();
  };

  /**
   * çæ slider éç½®
   */
  private getSliderCfg() {
    let cfg: Writeable<SliderCfg> & { x: number; y: number; width: number; minText: string; maxText: string } = {
      height: 16,
      start: 0,
      end: 1,
      minText: '',
      maxText: '',
      x: 0,
      y: 0,
      width: this.view.coordinateBBox.width,
    };
    if (isObject(this.option)) {
      // ç¨æ·éç½®çæ°æ®ï¼ä¼åçº§æ´é«
      const trendCfg = {
        data: this.getData(),
        ...get(this.option, 'trendCfg', {}),
      };

      // å ä¸ºææ ·å¼ï¼æä»¥æ·±å±è¦ç
      cfg = deepMix({}, cfg, this.getThemeOptions(), this.option);

      // trendCfg å ä¸ºææ°æ®æ°ç»ï¼æä»¥ä½¿ç¨æµæ¿æ¢
      cfg = { ...cfg, trendCfg };
    }

    cfg.start = clamp(Math.min(isNil(cfg.start) ? 0 : cfg.start, isNil(cfg.end) ? 1 : cfg.end), 0, 1);
    cfg.end = clamp(Math.max(isNil(cfg.start) ? 0 : cfg.start, isNil(cfg.end) ? 1 : cfg.end), 0, 1);

    return cfg;
  }

  /**
   * ä» view ä¸­è·åæ°æ®ï¼ç¼©ç¥è½´ä½¿ç¨å¨éçæ°æ®
   */
  private getData(): number[] {
    const data = this.view.getOptions().data;
    const [yScale] = this.view.getYScales();
    const groupScales = this.view.getGroupScales();
    if (groupScales.length) {
      const { field, ticks } = groupScales[0];
      return data.reduce((pre, cur) => {
        if (cur[field] === ticks[0]) {
          pre.push(cur[yScale.field] as number);
        }
        return pre;
      }, []) as number[];
    }

    return data.map((datum) => datum[yScale.field] || 0);
  }

  /**
   * è·å slider çä¸»é¢éç½®
   */
  private getThemeOptions() {
    const theme = this.view.getTheme();
    return get(theme, ['components', 'slider', 'common'], {});
  }

  /**
   * æ»åæ»å¨çæ¶ååºå
   * @param v
   */
  private onValueChange = (v: any) => {
    const [min, max] = v;

    this.start = min;
    this.end = max;

    this.changeViewData(min, max);
  };

  /**
   * æ ¹æ® start/end åå½åæ°æ®è®¡ç®åºå½åç minText/maxText
   * @param min
   * @param max
   */
  private getMinMaxText(min: number, max: number) {
    const data = this.view.getOptions().data;
    const xScale = this.view.getXScale();
    const isHorizontal = true;
    let values = valuesOfKey(data, xScale.field);

    // å¦ææ¯ xScale æ°å¼ç±»åï¼åè¿è¡æåº
    if (xScale.isLinear) {
      values = values.sort();
    }

    const xValues = isHorizontal ? values : values.reverse();
    const dataSize = size(data);

    if (!xScale || !dataSize) {
      return {}; // fix: éè¦å¼å®¹ï¼å¦åè°ç¨æ¹ç´æ¥åå¼ä¼æ¥é
    }

    const xTickCount = size(xValues);

    const minIndex = Math.floor(min * (xTickCount - 1));
    const maxIndex = Math.floor(max * (xTickCount - 1));

    let minText = get(xValues, [minIndex]);
    let maxText = get(xValues, [maxIndex]);

    const formatter = this.getSliderCfg().formatter as SliderCfg['formatter'];
    if (formatter) {
      minText = formatter(minText, data[minIndex], minIndex);
      maxText = formatter(maxText, data[maxIndex], maxIndex);
    }

    return {
      minText,
      maxText,
    };
  }

  /**
   * æ´æ° view è¿æ»¤æ°æ®
   * @param min
   * @param max
   */
  private changeViewData(min: number, max: number) {
    const data = this.view.getOptions().data;
    const xScale = this.view.getXScale();
    const dataSize = size(data);
    if (!xScale || !dataSize) {
      return;
    }
    const isHorizontal = true;
    const values = valuesOfKey(data, xScale.field);

    // å¦ææ¯ xScale æ°å¼ç±»åï¼åè¿è¡æåº
    const xScaleValues = this.view.getXScale().isLinear ? values.sort((a, b) => Number(a) - Number(b)) : values;

    const xValues = isHorizontal ? xScaleValues : xScaleValues.reverse();
    const xTickCount = size(xValues);

    const minIndex = Math.floor(min * (xTickCount - 1));
    const maxIndex = Math.floor(max * (xTickCount - 1));

    // å¢å  x è½´çè¿æ»¤å¨
    this.view.filter(xScale.field, (value: any, datum: Datum) => {
      const idx: number = xValues.indexOf(value);
      return idx > -1 ? isBetween(idx, minIndex, maxIndex) : true;
    });
    this.view.render(true);
  }

  /**
   * è¦åç¶ç±»æ¹æ³
   */
  public getComponents() {
    return this.slider ? [this.slider] : [];
  }

  /**
   * è¦çç¶ç±»
   */
  public clear() {
    if (this.slider) {
      this.slider.component.destroy();
      this.slider = undefined;
    }
    this.width = 0;
    this.start = undefined;
    this.end = undefined;
  }
}
