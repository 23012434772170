export default {
  base: {
    remove: 'ì­ì ',
    cancel: 'ì·¨ì',
    confirm: 'ê²°ì ',
    inert: 'ì½ì',
    width: 'ëë¹',
    height: 'ëì´'
  },
  controls: {
    clear: 'ì½íì¸ ì§ì°ê¸°',
    undo: 'ì·¨ì',
    redo: 'ë¤ìíê¸°',
    fontSize: 'ê¸ìí¬ê¸°',
    lineHeight: 'íëì´',
    letterSpacing: 'ë¨ì´ê°ê²©',
    textIndent: 'ë¨ë½ë¤ì¬ì°ê¸°',
    increaseIndent: 'ë¤ì¬ì°ê¸°ëë¦¬ê¸°',
    decreaseIndent: 'ë¤ì¬ì°ê¸°ì¤ì´ê¸°',
    border: 'êµ­ê²½',
    color: 'ìì',
    textColor: 'íì¤í¸ìì',
    backgroundColor: 'ë°°ê²½ìì',
    tempColors: 'ììì',
    bold: 'êµµê²',
    italic: 'ê¸°ì¸ìê¼´',
    underline: 'ë°ì¤',
    strikeThrough: 'ì·¨ìì ',
    fontFamily: 'ê¸ê¼´',
    textAlign: 'íì¤í¸ì ë ¬',
    alignLeft: 'ì¼ìª½',
    alignCenter: 'ì¤ì¬',
    alignRight: 'ì¤ë¥¸ìª½',
    alignJustify: 'ììª½ë',
    floatLeft: 'ë ë¤ëê¸°',
    floatRight: 'ì¤ë¥¸ìª½ë¶ë',
    superScript: 'ìì²¨ì',
    subScript: 'ì²¨ì',
    removeStyles: 'ì¤íì¼ì§ì°ê¸°',
    headings: 'ì ëª©',
    header: 'ì ëª©',
    normal: 'ì¬ëì',
    orderedList: 'ììê°ì§ì ëëª©ë¡',
    unorderedList: 'ì ë ¬ëì§ììëª©ë¡',
    blockQuote: 'ì°¸ê³ ë¬¸í',
    code: 'ì½ë',
    link: 'ë§í¬',
    unlink: 'ë§í¬ì­ì ',
    hr: 'ìíì ',
    media: 'ë¯¸ëì´',
    mediaLibirary: 'ë¯¸ëì´ë¼ì´ë¸ë¬ë¦¬',
    emoji: 'ììíí',
    fullscreen: 'ì ì²´íë©´',
    exitFullscreen: 'ì ì²´íë©´ì¢ë£',
  },
  linkEditor: {
    textInputPlaceHolder: 'ë§í¬íì¤í¸ìë ¥',
    linkInputPlaceHolder: 'ë§í¬ì£¼ììë ¥',
    inputWithEnterPlaceHolder: 'ë§í¬ì£¼ììë ¥.',
    openInNewWindow: 'ìì°½ì´ê¸°',
    removeLink: 'ë§í¬ì­ì '
  },
  audioPlayer: {
    title: 'ì¤ëì¤íì¼ì¬ì'
  },
  videoPlayer: {
    title: 'ë¹ëì¤íì¼ì¬ì',
    embedTitle: 'ìë² ëëë¯¸ëì´'
  },
  media: {
    image: 'ì´ë¯¸ì§',
    video: 'ë¹ëì¤',
    audio: 'ì¤ëì¤',
    embed: 'ìë² ëëë¯¸ëì´'
  }
}