import { isNumber } from '@antv/util';
/**
 * è½¬åççè®¡ç®æ¹å¼
 * @param prev
 * @param next
 */
export function conversionTagFormatter(prev: number, next: number): string {
  if (!isNumber(prev) || !isNumber(next)) {
    return '-';
  }

  if (prev === next) {
    return '100%';
  }
  if (prev === 0) {
    return 'â';
  }
  if (next === 0) {
    return '-â';
  }

  return `${((100 * next) / prev).toFixed(2)}%`;
}
