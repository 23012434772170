import { reduce, isNumber } from '@antv/util';

/**
 * è·å¾ä¸­ä½æ°
 * @param array
 */
export function getMedian(array: number[]) {
  const arr = [...array];
  // åæåº
  arr.sort((a: number, b: number) => {
    return a - b;
  });

  const len = arr.length;

  // median
  // 0
  if (len === 0) {
    return 0;
  }

  // å¥æ°
  if (len % 2 === 1) {
    return arr[(len - 1) / 2];
  }

  // å¶æ°
  return (arr[len / 2] + arr[len / 2 - 1]) / 2;
}

/**
 * è·å¾å¹³åå¼
 * @param array
 */
export function getMean(array: number[]) {
  const sum = reduce(
    array,
    (r: number, num: number) => {
      return (r += isNaN(num) || !isNumber(num) ? 0 : num);
    },
    0
  );

  return array.length === 0 ? 0 : sum / array.length;
}
