import { Plot } from '../../core/plot';
import { Adaptor } from '../../core/adaptor';
import { BulletOptions } from './types';
import { adaptor, meta } from './adaptor';
import { transformData } from './utils';
import { DEFAULT_OPTIONS } from './constant';

export type { BulletOptions };

export class Bullet extends Plot<BulletOptions> {
  /**
   * è·å å­å¼¹å¾ é»è®¤éç½®é¡¹
   * ä¾å¤é¨ä½¿ç¨
   */
  static getDefaultOptions(): Partial<BulletOptions> {
    return DEFAULT_OPTIONS;
  }

  /** å¾è¡¨ç±»å */
  public type: string = 'bullet';

  public changeData(data) {
    this.updateOption({ data });
    const { min, max, ds } = transformData(this.options);
    // å¤çscale
    meta({ options: this.options, ext: { data: { min, max } }, chart: this.chart });
    this.chart.changeData(ds);
  }

  /**
   * è·åå­å¼¹å¾çééå¨
   */
  protected getSchemaAdaptor(): Adaptor<BulletOptions> {
    return adaptor;
  }

  /**
   * è·å å­å¼¹å¾ é»è®¤éç½®
   */
  protected getDefaultOptions() {
    return Bullet.getDefaultOptions();
  }
}
