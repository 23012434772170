import { VennOptions } from './types';

// ä¸äºå­æ®µå¸¸éå®ä¹ï¼éè¦å¨ææ¡£ååç¥ç¨æ·
export const ID_FIELD = 'id';
export const PATH_FIELD = 'path';

/**
 * é¦æ©å¾ é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS: Partial<VennOptions> = {
  appendPadding: [10, 0, 20, 0],
  blendMode: 'multiply',
  tooltip: {
    showTitle: false,
    showMarkers: false,
    fields: ['id', 'size'],
    formatter: (datum) => {
      return { name: datum.id, value: datum.size };
    },
  },
  legend: { position: 'top-left' },
  label: {
    style: {
      textAlign: 'center',
      fill: '#fff',
    },
  },
  // é»è®¤ä¸å¼å¯ å¾ä¾ç­éäº¤äº
  interactions: [{ type: 'legend-filter', enable: false }],
  state: {
    active: {
      style: {
        stroke: '#000',
      },
    },
    selected: {
      style: {
        stroke: '#000',
        lineWidth: 2,
      },
    },
    inactive: {
      style: {
        fillOpacity: 0.3,
        strokeOpacity: 0.3,
      },
    },
  },
  // é¦æ©å¾çé»è®¤åç½®æ³¨åçäº¤äº
  defaultInteractions: ['tooltip', 'venn-legend-active'],
};
