import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';

/**
 * æ£ç¹å¾ é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
  size: 4,
  tooltip: {
    showTitle: false,
    showMarkers: false,
    showCrosshairs: true,
    crosshairs: {
      type: 'xy',
    },
  },
});
