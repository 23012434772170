export default {
  base: {
    remove: 'KaldÄ±r',
    cancel: 'Ä°ptal',
    confirm: 'Onayla',
    inert: 'Ekle',
    width: 'GeniÅlik',
    height: 'YÃ¼kseklik'
  },
  controls: {
    clear: 'Temizle',
    undo: 'Geri al',
    redo: 'Ä°leri al',
    fontSize: 'YazÄ± boyutu',
    color: 'Renk',
    textColor: 'YazÄ± rengi',
    tempColors: 'GeÃ§ici renkler',
    backgroundColor: 'Arkaplan',
    bold: 'KalÄ±n',
    lineHeight:'SatÄ±r yÃ¼ksekliÄi',
    letterSpacing:'Harf aralÄ±ÄÄ±',
    textIndent:'Ãentik aralÄ±ÄÄ±',
    increaseIndent: 'ÃentiÄi geniÅlet',
    decreaseIndent: 'ÃentiÄi daralt',
    italic: 'EÄik',
    underline: 'AltÄ± Ã§izili',
    strikeThrough: 'ÃstÃ¼ Ã§izili',
    fontFamily: 'YazÄ± tipi',
    textAlign: 'Metin Hizalama',
    alignLeft: 'Sola hizala',
    alignCenter: 'Ortaya hizala',
    alignRight: 'SaÄa hizala',
    alignJustify: 'Her iki tarafa hizala',
    floatLeft: 'Sola yatÄ±r',
    floatRight: 'SaÄa yatÄ±r',
    superScript: 'Ana kod',
    subScript: 'Alt kod',
    removeStyles: 'Stilleri kaldÄ±r',
    headings: 'BaÅlÄ±klar',
    header: 'BaÅlÄ±k',
    normal: 'Normal',
    orderedList: 'SÄ±ralÄ± liste',
    unorderedList: 'SÄ±rasÄ±z liste',
    blockQuote: 'AlÄ±ntÄ±',
    code: 'Kod',
    link: 'BaÄlantÄ±',
    unlink: 'BaÄlantÄ±yÄ± kaldÄ±r',
    hr: 'Yatay Ã§izgi',
    media: 'Medya',
    mediaLibirary: 'KÃ¼tÃ¼phane',
    emoji: 'Ä°fade',
    fullscreen: 'Tam ekran',
    exitFullscreen: 'Tam ekrandan Ã§Ä±k',
  },
  linkEditor: {
    textInputPlaceHolder: 'BaÄlantÄ± metnini girin',
    linkInputPlaceHolder: 'BaÄlantÄ± URL\' si girin',
    inputWithEnterPlaceHolder: 'BaÄlantÄ± URL\'si girin ve Enter\' a basÄ±n',
    openInNewWindow: 'Yeni pencerede aÃ§',
    removeLink: 'BaÄlantÄ±yÄ± kaldÄ±r'
  },
  audioPlayer: {
    title: 'Ses Ã§al'
  },
  videoPlayer: {
    title: 'GÃ¶rÃ¼ntÃ¼ oynat',
    embedTitle: 'GÃ¶rÃ¼ntÃ¼yÃ¼ gÃ¶m'
  },
  media: {
    image: 'Resim',
    video: 'GÃ¶rÃ¼ntÃ¼',
    audio: 'Ses',
    embed: 'GÃ¶mÃ¼lÃ¼ nesne'
  }
}
