import { Geometry } from '@antv/g2';
import { each, omit } from '@antv/util';
import { tooltip, slider, interaction, animation, theme, annotation, limitInPlot, pattern } from '../../adaptor/common';
import { findGeometry } from '../../utils';
import { Params } from '../../core/adaptor';
import { area, point, line } from '../../adaptor/geometries';
import { flow, transformLabel, deepAssign } from '../../utils';
import { getDataWhetherPecentage } from '../../utils/transform/percent';
import { Datum } from '../../types';
import { meta, legend, axis } from '../line/adaptor';
import { AreaOptions } from './types';

export { meta };

/**
 * geometry å¤ç
 * @param params
 */
function geometry(params: Params<AreaOptions>): Params<AreaOptions> {
  const { chart, options } = params;
  const {
    data,
    areaStyle,
    color,
    point: pointMapping,
    line: lineMapping,
    isPercent,
    xField,
    yField,
    tooltip,
    seriesField,
    startOnZero,
  } = options;
  const pointState = pointMapping?.state;

  const chartData = getDataWhetherPecentage(data, yField, xField, yField, isPercent);
  chart.data(chartData);
  // ç¾åæ¯å ç§¯å¾ï¼é»è®¤ä¼ç»ä¸ä¸ª % æ ¼å¼åé»è¾, ç¨æ·å¯èªå®ä¹
  const tooltipOptions = isPercent
    ? {
        formatter: (datum: Datum) => ({
          name: datum[seriesField] || datum[xField],
          value: (Number(datum[yField]) * 100).toFixed(2) + '%',
        }),
        ...tooltip,
      }
    : tooltip;
  const primary = deepAssign({}, params, {
    options: {
      area: { color, style: areaStyle },
      point: pointMapping && {
        color,
        ...pointMapping,
      },
      tooltip: tooltipOptions,
      // label ä¸ä¼ éç»åä¸ª geometry adaptorï¼ç± label adaptor å¤ç
      label: undefined,
      args: {
        startOnZero,
      },
    },
  });
  // çº¿é»è®¤ 2px (æçº¿ä¸è½å¤ç¨é¢ç§¯å¾ç stateï¼å ä¸º fill å stroke ä¸å¹é)
  const lineParams = {
    chart,
    options: deepAssign({ line: { size: 2 } }, omit(options as any, ['state']), {
      // é¢è²ä¿æä¸è´ï¼å ä¸ºå¦æé¢è²ä¸ä¸è´ï¼ä¼å¯¼è´ tooltip ä¸­åç´ éå¤ã
      // å¦æå­å¨ï¼æè®¾ç½®ï¼å¦åä¸ºç©º
      line: lineMapping && {
        color,
        ...lineMapping,
      },
      sizeField: seriesField,
      state: lineMapping?.state,
      tooltip: false,
      // label ä¸ä¼ éç»åä¸ª geometry adaptorï¼ç± label adaptor å¤ç
      label: undefined,
      args: {
        startOnZero,
      },
    }),
  };
  const pointParams = deepAssign({}, primary, { options: { tooltip: false, state: pointState } });

  // area geometry å¤ç
  area(primary);
  line(lineParams);
  point(pointParams);

  return params;
}

/**
 * æ°æ®æ ç­¾
 * @param params
 */
function label(params: Params<AreaOptions>): Params<AreaOptions> {
  const { chart, options } = params;
  const { label, yField } = options;

  const areaGeometry = findGeometry(chart, 'area');

  // label ä¸º false, ç©º åä¸æ¾ç¤º label
  if (!label) {
    areaGeometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    areaGeometry.label({
      fields: [yField],
      callback,
      cfg: {
        layout: [
          { type: 'limit-in-plot' },
          { type: 'path-adjust-position' },
          { type: 'point-adjust-position' },
          { type: 'limit-in-plot', cfg: { action: 'hide' } },
        ],
        ...transformLabel(cfg),
      },
    });
  }

  return params;
}

/**
 * å¤ç adjust
 * @param params
 */
function adjust(params: Params<AreaOptions>): Params<AreaOptions> {
  const { chart, options } = params;
  const { isStack, isPercent, seriesField } = options;
  if ((isPercent || isStack) && seriesField) {
    each(chart.geometries, (g: Geometry) => {
      g.adjust('stack');
    });
  }

  return params;
}

/**
 * æçº¿å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<AreaOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow(
    theme,
    pattern('areaStyle'),
    geometry,
    meta,
    adjust,
    axis,
    legend,
    tooltip,
    label,
    slider,
    annotation(),
    interaction,
    animation,
    limitInPlot
  )(params);
}
