import { Geometry, getTheme, ShapeAttrs } from '@antv/g2';
import { isNil, isObject } from '@antv/util';
import { Params } from '../../core/adaptor';
import { deepAssign } from '../../utils';
import { getTooltipMapping } from '../../utils/tooltip';
import { GeometryOptions, MappingOptions, geometry } from './base';

export interface IntervalGeometryOptions extends GeometryOptions {
  /** x è½´å­æ®µ */
  readonly xField: string;
  /** y è½´å­æ®µ */
  readonly yField: string;
  /** æåå­æ®µï¼å¨åç»æ±ç¶å¾ä¸å groupFieldãcolorFieldï¼å¨å ç§¯æ±ç¶å¾ä¸å stackFieldãcolorField  */
  readonly seriesField?: string;
  /** æ¯å¦åç»æ±å½¢å¾ */
  readonly isGroup?: boolean;
  /** æ¯å¦å ç§¯æ±ç¶å¾ */
  readonly isStack?: boolean;
  /** æ±ç¶å¾å®½åº¦å æ¯ [0-1] */
  readonly widthRatio?: number;
  /** åç»é´æ±å­ä¹é´çç»é´é´è·(åç´ çº§)ï¼ä»å¯¹åç»æ±ç¶å¾éç¨ */
  readonly intervalPadding?: number;
  /** åç»ä¸­æ±å­ä¹é´çé´è· [0-1]ï¼ä»å¯¹åç»æ±ç¶å¾éç¨ */
  readonly marginRatio?: number;
  /** åç»ä¸­æ±å­ä¹é´çç»åé´è·(åç´ çº§)ï¼ä»å¯¹åç»æ±ç¶å¾éç¨ */
  readonly dodgePadding?: number;
  /** æ±ç¶å¾æå°å®½åº¦ï¼åç´ ï¼ */
  readonly minColumnWidth?: number;
  /** æ±ç¶å¾æå¤§å®½åº¦ï¼åç´ ï¼ */
  readonly maxColumnWidth?: number;
  /** æ±å­çèæ¯æ ·å¼è®¾ç½® */
  readonly columnBackground?: { style: ShapeAttrs };
  /** æ±å­è§è§éééç½®ï¼å« colorãshapeãsizeãstyleãtooltipï¼ */
  readonly interval?: MappingOptions;
  /** åç»å­æ®µï¼ä¼åçº§é«äº seriesField , isGroup: true æ¶ä¼æ ¹æ® groupField è¿è¡åç»ã*/
  readonly groupField?: string;
}

/**
 * æ±å½¢å¾å¶ä»ç adaptor
 * @param params
 */
function otherAdaptor<O extends IntervalGeometryOptions>(params: Params<O>): Params<O> {
  const { chart, options, ext } = params;
  const { seriesField, isGroup, isStack, marginRatio, widthRatio, groupField, theme } = options;

  /**
   * adjust
   */
  const adjust = [];
  if (seriesField) {
    // group
    if (isGroup) {
      adjust.push({
        type: 'dodge',
        dodgeBy: groupField || seriesField,
        marginRatio,
      });
    }
    // stack
    if (isStack) {
      adjust.push({
        type: 'stack',
        marginRatio,
      });
    }
  }

  if (adjust.length && ext?.geometry) {
    const g = ext?.geometry as Geometry;
    g.adjust(adjust);
  }

  // widthRatio
  if (!isNil(widthRatio)) {
    chart.theme(
      deepAssign({}, isObject(theme) ? theme : getTheme(theme), {
        // columWidthRatio éç½®è¦ç theme ä¸­çéç½®
        columnWidthRatio: widthRatio,
      })
    );
  }

  return params;
}

export function interval<O extends IntervalGeometryOptions>(params: Params<O>): Params<O> {
  const { options } = params;
  const {
    xField,
    yField,
    interval,
    seriesField,
    tooltip,
    minColumnWidth,
    maxColumnWidth,
    columnBackground,
    dodgePadding,
    intervalPadding,
  } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField, seriesField]);

  // ä¿éä¸å®è¦å­å¨ interval æ å°
  const { ext } = interval
    ? geometry(
        deepAssign({}, params, {
          options: {
            type: 'interval',
            colorField: seriesField,
            tooltipFields: fields,
            mapping: {
              tooltip: formatter,
              ...interval,
            },
            args: { dodgePadding, intervalPadding, minColumnWidth, maxColumnWidth, background: columnBackground },
          },
        })
      )
    : params;

  return otherAdaptor({
    ...params,
    ext,
  });
}
