import { flatten, isString, valuesOfKey, isNil } from '@antv/util';
import { getXDimensionLength } from '../../util/coordinate';

// å·²ç»æåºåçæ°æ®æ¥æ¾è·ç¦»æå°ç
function findMinDistance(arr, scale) {
  const count = arr.length;
  let sourceArr = arr;
  if (isString(sourceArr[0])) {
    // æ¥æç±»åç values ç»å¸¸ä¸ææ¬ç±»åï¼æä»¥éè¦è½¬æ¢ä¸ä¸
    sourceArr = arr.map((v: string) => {
      return scale.translate(v);
    });
  }
  let distance = sourceArr[1] - sourceArr[0];
  for (let i = 2; i < count; i++) {
    const tmp = sourceArr[i] - sourceArr[i - 1];
    if (distance > tmp) {
      distance = tmp;
    }
  }
  return distance;
}

function getDodgeCount(dataArray, dodgeBy) {
  if (dodgeBy) {
    const mergeData = flatten(dataArray);
    const values = valuesOfKey(mergeData, dodgeBy);
    return values.length;
  }

  return dataArray.length;
}

/** @ignore */
export function getDefaultSize(geometry): number {
  const theme = geometry.theme;
  const coordinate = geometry.coordinate;
  const xScale = geometry.getXScale();
  const xValues = xScale.values;
  const dataArray = geometry.beforeMappingData;
  let count: number = xValues.length;
  const xDimensionLength = getXDimensionLength(geometry.coordinate);
  // è·åæ±å®½ç¸å³éç½®é¡¹
  const { intervalPadding, dodgePadding } = geometry;
  // å¼å®¹themeéç½®
  const maxColumnWidth = geometry.maxColumnWidth || theme.maxColumnWidth;
  const minColumnWidth = geometry.minColumnWidth || theme.minColumnWidth;
  const columnWidthRatio = geometry.columnWidthRatio || theme.columnWidthRatio;
  const multiplePieWidthRatio = geometry.multiplePieWidthRatio || theme.multiplePieWidthRatio;
  const roseWidthRatio = geometry.roseWidthRatio || theme.roseWidthRatio;

  // çº¿æ§æåµä¸countå¼
  if (xScale.isLinear && xValues.length > 1) {
    // Linear ç±»åç¨æ·æå¯è½è®¾ç½®äº min, max èå´æä»¥éè¦æ ¹æ®æ°æ®æå°åºé´è®¡ç® count
    xValues.sort();
    const interval = findMinDistance(xValues, xScale);
    count = (xScale.max - xScale.min) / interval;
    if (xValues.length > count) {
      count = xValues.length;
    }
  }

  const range = xScale.range;
  let normalizedSize = 1 / count;
  let wr = 1;
  if (coordinate.isPolar) {
    // æåæ åºæ¯
    if (coordinate.isTransposed && count > 1) {
      // æåæ ä¸å¤å±ç¯å¾
      wr = multiplePieWidthRatio;
    } else {
      wr = roseWidthRatio;
    }
  } else {
    // éæåæ åºæ¯
    if (xScale.isLinear) {
      normalizedSize *= range[1] - range[0];
    }
    wr = columnWidthRatio;
  }

  // åºç¡æ±ç¶å¾
  if (!isNil(intervalPadding) && intervalPadding >= 0) {
    // éç½®ç»é´è·æåµ
    const normalizedIntervalPadding = intervalPadding / xDimensionLength;
    normalizedSize = (1 - (count - 1) * normalizedIntervalPadding) / count;
  } else {
    // é»è®¤æåµ
    normalizedSize *= wr;
  }
  // åç»æ±ç¶å¾
  if (geometry.getAdjust('dodge')) {
    const dodgeAdjust = geometry.getAdjust('dodge');
    const dodgeBy = dodgeAdjust.dodgeBy;
    const dodgeCount = getDodgeCount(dataArray, dodgeBy);
    if (!isNil(dodgePadding) && dodgePadding >= 0) {
      // ä»éç½®ç»åé´è·æåµ
      const normalizedDodgePadding = dodgePadding / xDimensionLength;
      normalizedSize = (normalizedSize - normalizedDodgePadding * (dodgeCount - 1)) / dodgeCount;
    } else if (!isNil(intervalPadding) && intervalPadding >= 0) {
      // è®¾ç½®ç»é´è·ä½æªè®¾ç½®ç»åé´è·æåµï¼é¿åç»é´è·è¿å°å¯¼è´å¾å½¢éå ï¼éä¹ä»¥wr
      normalizedSize *= wr;
      normalizedSize = normalizedSize / dodgeCount;
    } else {
      // ç»é´è·åç»åé´è·åæªéç½®
      normalizedSize = normalizedSize / dodgeCount;
    }
    normalizedSize = normalizedSize >= 0 ? normalizedSize : 0;
  }

  // æå¤§åæå°éå¶
  if (!isNil(maxColumnWidth) && maxColumnWidth >= 0) {
    const normalizedMaxColumnWidth = maxColumnWidth / xDimensionLength;
    if (normalizedSize > normalizedMaxColumnWidth) {
      normalizedSize = normalizedMaxColumnWidth;
    }
  }

  // minColumnWidthå¯è½è®¾ç½®ä¸º0
  if (!isNil(minColumnWidth) && minColumnWidth >= 0) {
    const normalizedMinColumnWidth = minColumnWidth / xDimensionLength;
    if (normalizedSize < normalizedMinColumnWidth) {
      normalizedSize = normalizedMinColumnWidth;
    }
  }

  return normalizedSize;
}
