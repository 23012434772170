import { reduce } from '@antv/util';
/**
 * ç®åçæ¨¡æ¿å¼æï¼ä½¿ç¨æ¹å¼å¦ä¸ï¼ç©ºæ ¼èªå¨å¿½ç¥ï¼ï¼
 * template('hello, {name}', { name: 'AntV' }); // hello, AntV
 * @param string
 * @param options
 */
export function template(source: string, data?: object): string {
  if (!data) {
    return source;
  }
  return reduce(
    // @ts-ignore
    data,
    (r: string, v: string, k: string) => r.replace(new RegExp(`{\\s*${k}\\s*}`, 'g'), v),
    source
  );
}
