export const DEFAULT_COLOR = ['#FAAD14', '#E8EDF3'];

/**
 * é»è®¤éç½®é¡¹
 */
export const DEFAULT_OPTIONS = {
  percent: 0.2,
  color: DEFAULT_COLOR,
  animation: {},
};
