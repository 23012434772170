/* G2 çä¸ä¸ªå£³å­ï¼ä¸åå« Geometryï¼ç±å¼åèèªå·±å®ä¹åå¼å¥ */

export const VERSION = '4.1.46';

// æ ¸å¿åºç±»å¯¼åº
export { Chart, View, Event } from './chart'; // Chart, View ç±»
export { Controller as ComponentController } from './chart/controller/base'; // G2 ç»ä»¶åºç±»
export { default as TooltipController } from './chart/controller/tooltip'; // G2 tooltip ç»ä»¶åºç±»
export { default as Geometry } from './geometry/base'; // Geometry åºç±»
export { default as Element } from './geometry/element'; // Element ç±»
export { default as GeometryLabel } from './geometry/label/base'; // Geometry Label åºç±»
export { Interaction, Action } from './interaction'; // Interaction, Action åºç±»
export { Facet } from './facet'; // Facet åºç±»
export { default as InteractionAction } from './interaction/action/base'; // Interaction Action åºç±»

// æ³¨å ComponentController
export { registerComponentController } from './chart';

// æ³¨å Geometry
export { registerGeometry } from './chart';

// æ³¨å Geometry Shape
export { registerShape, registerShapeFactory, getShapeFactory } from './geometry/shape/base';

// æ³¨å Geometry label ä»¥å Geometry Label å¸å±å½æ°
export {
  registerGeometryLabel,
  registerGeometryLabelLayout,
  getGeometryLabel,
  getGeometryLabelLayout,
} from './geometry/label';

// æ³¨å interaction
export { getInteraction, registerInteraction, registerAction, getActionClass } from './interaction';

// æ³¨å facet
export { getFacet, registerFacet } from './facet';

// æ³¨åä¸»é¢
export { getTheme, registerTheme } from './theme';

// G engine ç®¡çç¸å³
export { registerEngine, getEngine } from './engine';

// æ³¨åå¨ç»å½æ°
export { registerAnimation, getAnimation } from './animate/animation';

export { LAYER, DIRECTION } from './constant';

// å ä¸º typescript é¨åçæ¬ä¸æ¯æ export * as è¯­æ³ã
import * as Types from './interface';
export type { Types };

export { IGroup, ShapeAttrs, Coordinate, Scale, ScaleConfig } from './dependents';

// ä¸äºå·¥å·æ¹æ³å¯¼åº
import { getMappingValue } from './util/attr';
import { getLegendItems } from './util/legend';
import { getAngle, getSectorPath, polarToCartesian } from './util/graphics';
import { rotate, transform, translate, zoom } from './util/transform';
import { getTooltipItems } from './util/tooltip';
import { getDelegationObject } from './interaction/action/util';
import { getPathPoints } from './geometry/shape/util/get-path-points';
import { getPath } from './geometry/shape/line';

export const Util = {
  getLegendItems,
  translate,
  rotate,
  zoom,
  transform,
  getAngle,
  getSectorPath,
  polarToCartesian,
  getDelegationObject,
  getTooltipItems,
  getMappingValue,
  // shape çä¸äºæä½æ¹æ³
  getPath,
  getPathPoints,
};
