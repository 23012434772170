import { map, reduce, maxBy, isArray, get } from '@antv/util';
import { Types } from '@antv/g2';
import { flow } from '../../../utils';
import { Params } from '../../../core/adaptor';
import { FUNNEL_PERCENT, FUNNEL_CONVERSATION, FUNNEL_TOTAL_PERCENT, PLOYGON_X, PLOYGON_Y } from '../constant';
import { geometry as baseGeometry } from '../../../adaptor/geometries/base';
import { getTooltipMapping } from '../../../utils/tooltip';
import { Datum, Data } from '../../../types/common';
import { FunnelOptions } from '../types';
import { conversionTagComponent } from './common';

/**
 * å¨æé«åº¦æ¼æå¾
 * @param params
 * éæ±: æ¯ä¸ªæ¼æé¡¹çé«åº¦æ ¹æ® yfield ç­æ¯çæãæ¼æä¸ä¸å®½åº¦æ¯ä¸º2ï¼å³æçä¸º 2ã
 * å®ç°æ¹å¼: ä½¿ç¨ g2 å¤è¾¹å½¢ï¼data -> ç¹åæ  -> ç»å¶
 * ä»¥æ¼æåºé¨ä¸­å¿ç¹ä¸ºåæ è½´åç¹ï¼æ¼æå¨ -0.5 <= x <= 0.5, 0 <= y <= 1 çæ­£æ¹å½¢ä¸­ç»å¶
 * åè®¡ç®ç¬¬ä¸è±¡éçç¹, ç¬¬äºè±¡éçç¹å³ä¸ºéå x è½´ååã
 * ç¬¬ä¸è±¡éå±éè®¡ç® data.length + 1 ä¸ªç¹ï¼å¨ y = 4x - 1 ä¸ãé¦å°¾åå«æ¯[0.5, 1], [0.25, 0]ãæ ¹æ® data è®¡ç®åº y å¼ï¼ä»èå¾å° y å¼
 */

/**
 * å¤çæ°æ®
 * @param params
 */
function field(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { chart, options } = params;
  const { data = [], yField } = options;

  // è®¡ç®åæ°æ®é¡¹æå é«åº¦
  const sum = reduce(
    data,
    (total, item) => {
      return total + (item[yField] || 0);
    },
    0
  );

  const max = maxBy(data, yField)[yField];

  const formatData = map(data, (row, index) => {
    // å¨å­åä¸ªç¹ xï¼y åæ ï¼æ¹åä¸ºé¡ºæ¶éï¼å³ [å·¦ä¸, å³ä¸ï¼å³ä¸ï¼å·¦ä¸]
    const x = [];
    const y = [];

    row[FUNNEL_TOTAL_PERCENT] = (row[yField] || 0) / sum;

    // è·åå·¦ä¸è§ï¼å³ä¸è§åæ 
    if (index) {
      const preItemX = data[index - 1][PLOYGON_X];
      const preItemY = data[index - 1][PLOYGON_Y];
      x[0] = preItemX[3];
      y[0] = preItemY[3];
      x[1] = preItemX[2];
      y[1] = preItemY[2];
    } else {
      x[0] = -0.5;
      y[0] = 1;
      x[1] = 0.5;
      y[1] = 1;
    }

    // è·åå³ä¸è§åæ 
    y[2] = y[1] - row[FUNNEL_TOTAL_PERCENT];
    x[2] = (y[2] + 1) / 4;
    y[3] = y[2];
    x[3] = -x[2];

    // èµå¼
    row[PLOYGON_X] = x;
    row[PLOYGON_Y] = y;
    row[FUNNEL_PERCENT] = (row[yField] || 0) / max;
    row[FUNNEL_CONVERSATION] = [get(data, [index - 1, yField]), row[yField]];
    return row;
  });

  chart.data(formatData);

  return params;
}

/**
 * geometryå¤ç
 * @param params
 */
function geometry(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { chart, options } = params;
  const { xField, yField, color, tooltip, label, funnelStyle, state } = options;

  const { fields, formatter } = getTooltipMapping(tooltip, [xField, yField]);
  // ç»å¶æ¼æå¾
  baseGeometry({
    chart,
    options: {
      type: 'polygon',
      xField: PLOYGON_X,
      yField: PLOYGON_Y,
      colorField: xField,
      tooltipFields: isArray(fields) && fields.concat([FUNNEL_PERCENT, FUNNEL_CONVERSATION]),
      label,
      state,
      mapping: {
        tooltip: formatter,
        color,
        style: funnelStyle,
      },
    },
  });
  return params;
}

/**
 * è½¬ç½®å¤ç
 * @param params
 */
function transpose(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const { chart, options } = params;
  const { isTransposed } = options;
  chart.coordinate({
    type: 'rect',
    actions: isTransposed ? [['transpose'], ['reflect', 'x']] : [],
  });
  return params;
}

/**
 * è½¬åçç»ä»¶
 * @param params
 */
function conversionTag(params: Params<FunnelOptions>): Params<FunnelOptions> {
  const getLineCoordinate = (
    datum: Datum,
    datumIndex: number,
    data: Data,
    initLineOption: Record<string, any>
  ): Types.LineOption => {
    return {
      ...initLineOption,
      start: [datum[PLOYGON_X][1], datum[PLOYGON_Y][1]],
      end: [datum[PLOYGON_X][1] + 0.05, datum[PLOYGON_Y][1]],
    };
  };

  conversionTagComponent(getLineCoordinate)(params);

  return params;
}

/**
 * å¨æé«åº¦æ¼æ
 * @param chart
 * @param options
 */
export function dynamicHeightFunnel(params: Params<FunnelOptions>) {
  return flow(field, geometry, transpose, conversionTag)(params);
}
