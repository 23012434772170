import { deepMix, each, every, get, isNil, isNumber } from '@antv/util';
import { LAYER } from '../constant';
import { IGroup } from '../dependents';
import { AxisCfg, Condition, Datum, FacetCfg, FacetData, FacetDataFilter, Region } from '../interface';

import View from '../chart/view';
import { getAxisOption } from '../util/axis';

/**
 * facet åºç±»
 *  - å®ä¹çå½å¨æï¼æ¹ä¾¿èªå®ä¹ facet
 *  - æä¾åºç¡ççå½æµç¨æ¹æ³
 *
 * çå½å¨æï¼
 *
 * åå§å init
 * 1. åå§åå®¹å¨
 * 2. æ°æ®åé¢ï¼çæåé¢å¸å±ä¿¡æ¯
 *
 * æ¸²æé¶æ®µ render
 * 1. view åå»º
 * 2. title
 * 3. axis
 *
 * æ¸é¤é¶æ®µ clear
 * 1. æ¸é¤ view
 *
 * éæ¯é¶æ®µ destroy
 * 1. clear
 * 2. æ¸é¤äºä»¶
 * 3. æ¸é¤ group
 */
export abstract class Facet<C extends FacetCfg<FacetData> = FacetCfg<FacetData>, F extends FacetData = FacetData> {
  /** åé¢æå¨ç view */
  public view: View;
  /** åé¢å®¹å¨ */
  public container: IGroup;
  /** æ¯å¦éæ¯ */
  public destroyed: boolean = false;

  /** åé¢çéç½®é¡¹ */
  protected cfg: C;
  /** åé¢ä¹åçææåé¢æ°æ®ç»æ */
  protected facets: F[] = [];

  constructor(view: View, cfg: C) {
    this.view = view;
    this.cfg = deepMix({}, this.getDefaultCfg(), cfg);
  }

  /**
   * åå§åè¿ç¨
   */
  public init() {
    // åå§åå®¹å¨
    if (!this.container) {
      this.container = this.createContainer();
    }

    // çæåé¢å¸å±ä¿¡æ¯
    const data = this.view.getData();
    this.facets = this.generateFacets(data);
  }

  /**
   * æ¸²æåé¢ï¼ç±ä¸å± view è°ç¨ãåæ¬ï¼
   *  - åé¢ view
   *  - è½´
   *  - title
   *
   *  å­ç±»å¯ä»¥å¤åï¼æ·»å ä¸äºå¶ä»ç»ä»¶ï¼æ¯å¦æ»å¨æ¡ç­
   */
  public render() {
    this.renderViews();
  }

  /**
   * æ´æ° facet
   */
  public update() {
    // å¶å®ä¸ç¨åä»»ä½äºæï¼å ä¸º facet æç»çæç View å Geometry é½å¨ç¶ view çæ´æ°ä¸­å¤çäº
  }

  /**
   * æ¸ç©ºï¼clear ä¹åå¦æè¿éè¦ä½¿ç¨ï¼éè¦éæ°è°ç¨ init åå§åè¿ç¨
   * ä¸è¬å¨æ°æ®æåæ´çæ¶åè°ç¨ï¼éæ°è¿è¡æ°æ®çåé¢é»è¾
   */
  public clear() {
    this.clearFacetViews();
  }

  /**
   * éæ¯
   */
  public destroy() {
    this.clear();

    if (this.container) {
      this.container.remove(true);
      this.container = undefined;
    }

    this.destroyed = true;
    this.view = undefined;
    this.facets = [];
  }

  /**
   * æ ¹æ® facet çæ viewï¼å¯ä»¥ç»ä¸å±èªå®ä¹ä½¿ç¨
   * @param facet
   */
  protected facetToView(facet: F): View {
    const { region, data, padding = this.cfg.padding } = facet;

    const view = this.view.createView({
      region,
      padding,
    });

    // è®¾ç½®åé¢çæ°æ®
    view.data(data || []);
    facet.view = view;

    // åç½®é©å­
    this.beforeEachView(view, facet);

    const { eachView } = this.cfg;
    if (eachView) {
      eachView(view, facet);
    }

    // åç½®é©å­
    this.afterEachView(view, facet);

    return view;
  }

  // åå»ºå®¹å¨
  private createContainer(): IGroup {
    const foregroundGroup = this.view.getLayer(LAYER.FORE);
    return foregroundGroup.addGroup();
  }

  /**
   * åå§å view
   */
  private renderViews() {
    this.createFacetViews();
  }

  /**
   * åå»º åé¢ view
   */
  private createFacetViews(): View[] {
    // ä½¿ç¨åé¢æ°æ® åå»ºåé¢ view
    return this.facets.map((facet): View => {
      return this.facetToView(facet);
    });
  }

  /**
   * ä» view ä¸­æ¸é¤ facetView
   */
  private clearFacetViews() {
    // ä» view ä¸­ç§»é¤åé¢ view
    each(this.facets, (facet) => {
      if (facet.view) {
        this.view.removeView(facet.view);
        facet.view = undefined;
      }
    });
  }

  /**
   * è§£æ spacing
   */
  private parseSpacing() {
    /**
     * @example
     *
     * // ä»ä½¿ç¨ç¾åæ¯æåç´ å¼
     * // æ¨ªåé´éä¸º 10%ï¼çºµåé´éä¸º 10%
     * ['10%', '10%']
     * // æ¨ªåé´éä¸º 10pxï¼çºµåé´éä¸º 10px
     * [10, 10]
     *
     * // åæ¶ä½¿ç¨ç¾åæ¯ååç´ å¼
     * ['10%', 10]
     * // æ¨ªåé´éä¸º 10%ï¼çºµåé´éä¸º 10px
     */
    const { width, height } = this.view.viewBBox;
    const { spacing } = this.cfg;
    return spacing.map((s: number, idx: number) => {
      if (isNumber(s)) return s / (idx === 0 ? width : height);
      else return parseFloat(s) / 100;
    });
  }

  // å¶ä»ä¸äºæä¾ç»å­ç±»ä½¿ç¨çæ¹æ³

  /**
   * è·åè¿ä¸ªå­æ®µå¯¹åºçææå¼ï¼æ°ç»
   * @protected
   * @param data æ°æ®
   * @param field å­æ®µå
   * @return å­æ®µå¯¹åºçå¼
   */
  protected getFieldValues(data: Datum[], field: string): string[] {
    const rst = [];
    const cache: Record<string, boolean> = {};

    // å»éãå»é¤ Nil å¼
    each(data, (d: Datum) => {
      const value = d[field];
      if (!isNil(value) && !cache[value]) {
        rst.push(value);
        cache[value] = true;
      }
    });

    return rst;
  }

  /**
   * è·å¾æ¯ä¸ªåé¢ç regionï¼å¹³ååºå
   * @param rows row æ»æ°
   * @param cols col æ»æ°
   * @param xIndex x æ¹å index
   * @param yIndex y æ¹å index
   */
  protected getRegion(rows: number, cols: number, xIndex: number, yIndex: number): Region {
    const [xSpacing, ySpacing] = this.parseSpacing();
    // æ¯ä¸¤ä¸ªåé¢åºåæ¨ªåé´éxSPacing, çºµåé´éySpacing
    // æ¯ä¸ªåé¢åºåçæ¨ªçºµå æ¯
    /**
     * ratio * num + spacing * (num - 1) = 1
     * => ratio = (1 - (spacing * (num - 1))) / num
     *          = (1 + spacing) / num - spacing
     *
     * num å¯¹åº cols/rows
     * spacing å¯¹åº xSpacing/ySpacing
     */
    const xRatio = (1 + xSpacing) / (cols === 0 ? 1 : cols) - xSpacing;
    const yRatio = (1 + ySpacing) / (rows === 0 ? 1 : rows) - ySpacing;

    // å¾å°ç¬¬ index ä¸ªåé¢åºåç¾åæ¯ä½ç½®
    const start = {
      x: (xRatio + xSpacing) * xIndex,
      y: (yRatio + ySpacing) * yIndex,
    };
    const end = {
      x: start.x + xRatio,
      y: start.y + yRatio,
    };
    return { start, end };
  }

  protected getDefaultCfg() {
    return {
      eachView: undefined,
      showTitle: true,
      spacing: [0, 0],
      padding: 10,
      fields: [],
    };
  }

  /**
   * é»è®¤ç title æ ·å¼ï¼å ä¸ºæçåé¢æ¯ titleï¼æçåé¢éç½®æ¯ columnTitleãrowTitle
   */
  protected getDefaultTitleCfg() {
    // @ts-ignore
    const fontFamily = this.view.getTheme().fontFamily;
    return {
      style: {
        fontSize: 14,
        fill: '#666',
        fontFamily,
      },
    };
  }

  /**
   * å¤ç axis çé»è®¤éç½®
   * @param view
   * @param facet
   */
  protected processAxis(view: View, facet: F) {
    const options = view.getOptions();

    const coordinateOption = options.coordinate;
    const geometries = view.geometries;

    const coordinateType = get(coordinateOption, 'type', 'rect');

    if (coordinateType === 'rect' && geometries.length) {
      if (isNil(options.axes)) {
        // @ts-ignore
        options.axes = {};
      }
      const axes = options.axes;

      const [x, y] = geometries[0].getXYFields();

      const xOption = getAxisOption(axes, x);
      const yOption = getAxisOption(axes, y);

      if (xOption !== false) {
        options.axes[x] = this.getXAxisOption(x, axes, xOption, facet);
      }

      if (yOption !== false) {
        options.axes[y] = this.getYAxisOption(y, axes, yOption, facet);
      }
    }
  }

  /**
   * è·ååé¢æ°æ®
   * @param conditions
   */
  protected getFacetDataFilter(conditions: Condition[]): FacetDataFilter {
    return (datum: Datum) => {
      // è¿æ»¤åºå¨é¨æ»¡è¶³æ¡ä»¶çæ°æ®
      return every(conditions, (condition) => {
        const { field, value } = condition;

        if (!isNil(value) && field) {
          return datum[field] === value;
        }
        return true;
      });
    };
  }

  /**
   * @override å¼å§å¤ç eachView
   * @param view
   * @param facet
   */
  protected abstract beforeEachView(view: View, facet: F);

  /**
   * @override å¤ç eachView ä¹å
   * @param view
   * @param facet
   */
  protected abstract afterEachView(view: View, facet: F);

  /**
   * @override çæåé¢æ°æ®ï¼åå«å¸å±
   * @param data
   */
  protected abstract generateFacets(data: Datum[]): F[];

  /**
   * è·å x è½´çéç½®
   * @param x
   * @param axes
   * @param option
   * @param facet
   */
  protected abstract getXAxisOption(x: string, axes: any, option: AxisCfg, facet: F): object;

  /**
   * è·å y è½´çéç½®
   * @param y
   * @param axes
   * @param option
   * @param facet
   */
  protected abstract getYAxisOption(y: string, axes: any, option: AxisCfg, facet: F): object;
}
