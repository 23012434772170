import { each } from '@antv/util';
import { View } from '../../../chart';
import Element from '../../../geometry/element/';
import { getElements } from '../util';

const STATUS_UNACTIVE = 'inactive';
const STATUS_ACTIVE = 'active';

/**
 * @ignore
 * æ¸ç highlight ææ
 * @param view View æè Chart
 */
export function clearHighlight(view: View) {
  const elements = getElements(view);
  each(elements, (el) => {
    if (el.hasState(STATUS_ACTIVE)) {
      el.setState(STATUS_ACTIVE, false);
    }
    if (el.hasState(STATUS_UNACTIVE)) {
      el.setState(STATUS_UNACTIVE, false);
    }
  });
}

type MatchCallback = (el: Element) => boolean;

/**
 * @ignore
 * è®¾ç½®å¤ä¸ªåç´ ç highlight
 * @param elements åç´ éå
 * @param callback è®¾ç½®åè°å½æ°
 * @param enable è®¾ç½®æèåæ¶
 */
export function setHighlightBy(elements: Element[], callback: MatchCallback, enable: boolean) {
  each(elements, (el) => {
    // éè¦å¤ç active å unactive çäºæ¥
    if (callback(el)) {
      if (el.hasState(STATUS_UNACTIVE)) {
        el.setState(STATUS_UNACTIVE, false);
      }
      el.setState(STATUS_ACTIVE, enable);
    } else {
      if (el.hasState(STATUS_ACTIVE)) {
        el.setState(STATUS_ACTIVE, false);
      }
      el.setState(STATUS_UNACTIVE, enable);
    }
  });
}
