import { View } from '@antv/g2';
import { get, keys } from '@antv/util';
import { Params } from '../../core/adaptor';
import {
  tooltip,
  interaction as commonInteraction,
  animation as commonAnimation,
  theme as commonTheme,
  limitInPlot as commonLimitInPlot,
  scale,
} from '../../adaptor/common';
import { interval } from '../../adaptor/geometries';
import { flow, findViewById, findGeometry, transformLabel, deepAssign } from '../../utils';
import { BidirectionalBarOptions } from './types';
import { FIRST_AXES_VIEW, SECOND_AXES_VIEW, SERIES_FIELD_KEY } from './constant';
import { isHorizontal, transformData } from './utils';

/**
 * geometry å¤ç
 * @param params
 */
function geometry(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart, options } = params;
  const { data, xField, yField, color, barStyle, widthRatio, legend, layout } = options;

  // å¤çæ°æ®
  const groupData: any[] = transformData(xField, yField, SERIES_FIELD_KEY, data, isHorizontal(layout));
  // å¨åå»ºå­ view æ§è¡åä¸è¡ï¼éè¦å¨åé¢å¤ç legend
  if (legend) {
    chart.legend(SERIES_FIELD_KEY, legend);
  } else if (legend === false) {
    chart.legend(false);
  }
  // åå»º view
  let firstView: View;
  let secondView: View;
  const [firstViewData, secondViewData] = groupData;

  // æ¨ªå
  if (isHorizontal(layout)) {
    firstView = chart.createView({
      region: {
        start: { x: 0, y: 0 },
        end: { x: 0.5, y: 1 },
      },
      id: FIRST_AXES_VIEW,
    });

    firstView.coordinate().transpose().reflect('x');

    secondView = chart.createView({
      region: {
        start: { x: 0.5, y: 0 },
        end: { x: 1, y: 1 },
      },
      id: SECOND_AXES_VIEW,
    });
    secondView.coordinate().transpose();

    // @è¯´æ: æµè¯åç°ï¼æ¨ªåå ä¸ºè½´çåè½¬ï¼éè¦æ°æ®ä¹åè½¬ï¼ä¸ç¶ä¼å¾å½¢æ¸²ææ¯åç(ç¿»è½¬æä½è¿å¥å° transform ä¸­å¤ç)
    firstView.data(firstViewData);
    secondView.data(secondViewData);
  } else {
    // çºµå
    firstView = chart.createView({
      region: {
        start: { x: 0, y: 0 },
        end: { x: 1, y: 0.5 },
      },
      id: FIRST_AXES_VIEW,
    });
    secondView = chart.createView({
      region: {
        start: { x: 0, y: 0.5 },
        end: { x: 1, y: 1 },
      },
      id: SECOND_AXES_VIEW,
    });
    secondView.coordinate().reflect('y');

    firstView.data(firstViewData);
    secondView.data(secondViewData);
  }
  const left = deepAssign({}, params, {
    chart: firstView,
    options: {
      widthRatio,
      xField,
      yField: yField[0],
      seriesField: SERIES_FIELD_KEY,
      interval: {
        color,
        style: barStyle,
      },
    },
  });
  interval(left);

  const right = deepAssign({}, params, {
    chart: secondView,
    options: {
      xField,
      yField: yField[1],
      seriesField: SERIES_FIELD_KEY,
      widthRatio,
      interval: {
        color,
        style: barStyle,
      },
    },
  });

  interval(right);

  return params;
}

/**
 * meta éç½®
 * - å¯¹ç§°æ¡å½¢å¾å¯¹æ°æ®è¿è¡äºå¤çï¼éè¿ SERIES_FIELD_KEY æ¥å¯¹ä¸¤æ¡ yField æ°æ®è¿è¡åç±»
 * @param params
 */
function meta(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { options, chart } = params;
  const { xAxis, yAxis, xField, yField } = options;
  const firstView = findViewById(chart, FIRST_AXES_VIEW);
  const secondView = findViewById(chart, SECOND_AXES_VIEW);

  const aliasMap = {};
  keys(options?.meta || {}).map((metaKey) => {
    if (get(options?.meta, [metaKey, 'alias'])) {
      aliasMap[metaKey] = options.meta[metaKey].alias;
    }
  });

  chart.scale({
    [SERIES_FIELD_KEY]: {
      sync: true,
      formatter: (v) => {
        return get(aliasMap, v, v);
      },
    },
  });

  scale({
    [xField]: xAxis,
    [yField[0]]: yAxis[yField[0]],
  })(deepAssign({}, params, { chart: firstView }));

  scale({
    [xField]: xAxis,
    [yField[1]]: yAxis[yField[1]],
  })(deepAssign({}, params, { chart: secondView }));

  return params;
}

/**
 * axis éç½®
 * @param params
 */
function axis(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart, options } = params;
  const { xAxis, yAxis, xField, yField, layout } = options;

  const firstView = findViewById(chart, FIRST_AXES_VIEW);
  const secondView = findViewById(chart, SECOND_AXES_VIEW);
  // ç¬¬äºä¸ª view axis å§ç»éè; æ³¨æ bottom çæ¶åï¼åªéè labelï¼å¶ä»å±ç¨éç½®
  // @ts-ignore
  if (xAxis?.position === 'bottom') {
    // fixme ç´æ¥è®¾ç½® label: null ä¼å¯¼è´ tickLine æ æ³æ¾ç¤º
    secondView.axis(xField, { ...xAxis, label: { formatter: () => '' } });
  } else {
    secondView.axis(xField, false);
  }

  // ä¸º false åæ¯ä¸æ¾ç¤º firstView è½´
  if (xAxis === false) {
    firstView.axis(xField, false);
  } else {
    firstView.axis(xField, {
      // ä¸åå¸å± firstView çåæ è½´æ¾ç¤ºä½ç½®
      position: isHorizontal(layout) ? 'top' : 'bottom',
      ...xAxis,
    });
  }

  if (yAxis === false) {
    firstView.axis(yField[0], false);
    secondView.axis(yField[1], false);
  } else {
    firstView.axis(yField[0], yAxis[yField[0]]);
    secondView.axis(yField[1], yAxis[yField[1]]);
  }
  /**
   *  è¿ä¸ªæ³¨å¥ï¼ä¸»è¦æ¯å¨syncViewPaddingæ¶åæ¿å°ç¸å¯¹åºçéç½®ï¼å¸å±åè½´çä½ç½®
   *  TODO ä¹åå¸æ g2 View å¯¹è±¡å¯ä»¥å¼æ¾ setter å¯ä»¥è®¾ç½®ä¸äºéè¦çä¸è¥¿
   */

  //@ts-ignore
  chart.__axisPosition = {
    position: firstView.getOptions().axes[xField].position,
    layout,
  };
  return params;
}

/**
 * interaction éç½®
 * @param params
 */
export function interaction(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart } = params;

  commonInteraction(deepAssign({}, params, { chart: findViewById(chart, FIRST_AXES_VIEW) }));
  commonInteraction(deepAssign({}, params, { chart: findViewById(chart, SECOND_AXES_VIEW) }));

  return params;
}

/**
 * limitInPlot
 * @param params
 */
export function limitInPlot(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart, options } = params;
  const { yField, yAxis } = options;

  commonLimitInPlot(
    deepAssign({}, params, {
      chart: findViewById(chart, FIRST_AXES_VIEW),
      options: {
        yAxis: yAxis[yField[0]],
      },
    })
  );

  commonLimitInPlot(
    deepAssign({}, params, {
      chart: findViewById(chart, SECOND_AXES_VIEW),
      options: {
        yAxis: yAxis[yField[1]],
      },
    })
  );

  return params;
}

/**
 * theme
 * @param params
 */
export function theme(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart } = params;

  commonTheme(deepAssign({}, params, { chart: findViewById(chart, FIRST_AXES_VIEW) }));
  commonTheme(deepAssign({}, params, { chart: findViewById(chart, SECOND_AXES_VIEW) }));

  return params;
}

/**
 * animation
 * @param params
 */
export function animation(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart } = params;

  commonAnimation(deepAssign({}, params, { chart: findViewById(chart, FIRST_AXES_VIEW) }));
  commonAnimation(deepAssign({}, params, { chart: findViewById(chart, SECOND_AXES_VIEW) }));

  return params;
}

/**
 * label éç½® (1. è®¾ç½® offset åç§»éé»è®¤å¼ 2. leftView åç§»ééè¦ *= -1)
 * @param params
 */
function label(params: Params<BidirectionalBarOptions>): Params<BidirectionalBarOptions> {
  const { chart, options } = params;
  const { label, yField, layout } = options;

  const firstView = findViewById(chart, FIRST_AXES_VIEW);
  const secondView = findViewById(chart, SECOND_AXES_VIEW);
  const leftGeometry = findGeometry(firstView, 'interval');
  const rightGeometry = findGeometry(secondView, 'interval');

  if (!label) {
    leftGeometry.label(false);
    rightGeometry.label(false);
  } else {
    const { callback, ...cfg } = label;
    /** ---- è®¾ç½®é»è®¤éç½® ---- */
    // é»è®¤å±ä¸­
    if (!cfg.position) {
      cfg.position = 'middle';
    }
    if (cfg.offset === undefined) {
      cfg.offset = 2;
    }

    /** ---- leftView label è®¾ç½® ---- */
    const leftLabelCfg = { ...cfg };
    if (isHorizontal(layout)) {
      // è®¾ç½® textAlign é»è®¤å¼
      const textAlign = leftLabelCfg.style?.textAlign || (cfg.position === 'middle' ? 'center' : 'left');
      cfg.style = deepAssign({}, cfg.style, { textAlign });
      const textAlignMap = { left: 'right', right: 'left', center: 'center' };
      leftLabelCfg.style = deepAssign({}, leftLabelCfg.style, { textAlign: textAlignMap[textAlign] });
    } else {
      const positionMap = { top: 'bottom', bottom: 'top', middle: 'middle' };
      if (typeof cfg.position === 'string') {
        cfg.position = positionMap[cfg.position];
      } else if (typeof cfg.position === 'function') {
        cfg.position = (...args) => positionMap[(cfg.position as Function).apply(this, args)];
      }
      // è®¾ç½® textBaseline é»è®¤å¼
      const textBaseline = leftLabelCfg.style?.textBaseline || 'bottom';
      leftLabelCfg.style = deepAssign({}, leftLabelCfg.style, { textBaseline });
      const textBaselineMap = { top: 'bottom', bottom: 'top', middle: 'middle' };
      cfg.style = deepAssign({}, cfg.style, { textBaseline: textBaselineMap[textBaseline] });
    }

    leftGeometry.label({
      fields: [yField[0]],
      callback,
      cfg: transformLabel(leftLabelCfg),
    });
    rightGeometry.label({
      fields: [yField[1]],
      callback,
      cfg: transformLabel(cfg),
    });
  }

  return params;
}

/**
 * å¯¹ç§°æ¡å½¢å¾ééå¨
 * @param chart
 * @param options
 */
export function adaptor(params: Params<BidirectionalBarOptions>) {
  // flow çæ¹å¼å¤çææçéç½®å° G2 API
  return flow(geometry, meta, axis, limitInPlot, theme, label, tooltip, interaction, animation)(params);
}
