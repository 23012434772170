import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';
import { ViolinOptions } from './types';

export const X_FIELD = 'x';
export const VIOLIN_Y_FIELD = 'violinY';
export const VIOLIN_SIZE_FIELD = 'violinSize';
export const MIN_MAX_FIELD = 'minMax';
export const QUANTILE_FIELD = 'quantile';
export const MEDIAN_FIELD = 'median';

export const VIOLIN_VIEW_ID = 'violin_view';
export const MIN_MAX_VIEW_ID = 'min_max_view';
export const QUANTILE_VIEW_ID = 'quantile_view';
export const MEDIAN_VIEW_ID = 'median_view';

export const DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
  // å¤ view ç»æï¼ä¸å®è¦è®¾ç½® view padding åæ­¥
  syncViewPadding: true,
  // é»è®¤æ ¸å½æ°
  kde: {
    type: 'triangular',
    sampleSize: 32,
    width: 3,
  },

  // é»è®¤å°æç´è½®å»æ ·å¼
  violinStyle: {
    lineWidth: 1,
    fillOpacity: 0.3,
    strokeOpacity: 0.75,
  },
  // åæ è½´
  xAxis: {
    grid: {
      line: null,
    },
    tickLine: {
      alignTick: false,
    },
  },
  yAxis: {
    grid: {
      line: {
        style: {
          lineWidth: 0.5,
          lineDash: [4, 4],
        },
      },
    },
  },
  // å¾ä¾
  legend: {
    position: 'top-left',
  },
  // Tooltip
  tooltip: {
    showMarkers: false,
  },
  // é»è®¤åºåäº¤äº
  // interactions: [{ type: 'active-region' }],
} as Partial<ViolinOptions>);
